import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { valueLimits } from "../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { paymentLinkSchema } from "../../../../schemas/payment";
import {
  errorToast,
  handleError,
  infoToast,
  successToast,
  validateFloatNum,
  validateIntNum,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const PaymentLinkModal = ({ show, setShow, orderData, setOrderData }: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      order_id: orderData?.order_id,
      mobile: values?.mobile,
      patient_payable: values.amount.toString(),
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/resend-payment-link`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Link generated successfully");
        setShow(false);
        //update
        setOrderData(result?.data?.response);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={() => setShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShow(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
            Payment Link
          </Typography>
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {orderData?.patient_payable_details?.advance_payment?.link ? (
          <Stack mb={3} direction="row" alignItems="center" gap={1}>
            <Typography sx={{ p: 0 }} variant="body2">
              Existing:{" "}
              <Link
                target="_blank"
                href={orderData.patient_payable_details.advance_payment.link}
              >
                {orderData.patient_payable_details.advance_payment.link}
              </Link>{" "}
              of{" "}
              <strong>
                Rs.
                {
                  orderData.patient_payable_details.advance_payment
                    .payable_amount
                }
              </strong>
            </Typography>
            <CopyToClipboard
              text={orderData.patient_payable_details.advance_payment.link}
              onCopy={() => infoToast("Copied to clipboard")}
            >
              <Tooltip title="Copy to clipboard">
                <IconButton size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Stack>
        ) : null}
        <Formik
          initialValues={{
            mobile: orderData?.customer_details?.mobile,
            amount: Number(orderData?.patient_payable_amount || 0).toFixed(2),
          }}
          onSubmit={(values: any, actions: any) => {
            setLoading(true);
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
          validationSchema={paymentLinkSchema}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Stack>
                  <TextField
                    fullWidth
                    id="amount"
                    label="Amount"
                    size="small"
                    value={values?.amount || ""}
                    sx={{ mt: 1.5 }}
                    onChange={(e: any) =>
                      setFieldValue(
                        "amount",
                        validateFloatNum(e.target.value, valueLimits.MRP)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.amount && touched.amount ? errors.amount : ""
                    }
                    error={errors.amount && touched.amount ? true : false}
                  />
                  <TextField
                    fullWidth
                    id="mobile"
                    label="Mobile"
                    size="small"
                    value={values?.mobile || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "mobile",
                        validateIntNum(e.target.value, valueLimits.MOBILE)
                      )
                    }
                    sx={{ mt: 1.5 }}
                    onBlur={handleBlur}
                    helperText={
                      errors.mobile && touched.mobile ? errors.mobile : ""
                    }
                    error={errors.mobile && touched.mobile ? true : false}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Stack>
                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    disabled={isSubmitting}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                  >
                    Generate Link
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentLinkModal;
