import React from "react";
import { CardMedia, ImageList, ImageListItem, Typography } from "@mui/material";

interface IPrescriptionDetailsProp {
  prescription_links: Array<string>;
  component_label?: string;
}

const PrescriptionData = ({
  prescription_links,
  component_label,
}: IPrescriptionDetailsProp) => {
  return (
    <>
      {prescription_links?.length > 0 ? (
        <>
          <Typography variant="body2" sx={{ mt: 2 }}>
            {component_label}:
          </Typography>
          <ImageList sx={{ width: "100%" }} cols={3}>
            {prescription_links?.map((item: string) => (
              <ImageListItem key={item}>
                <CardMedia
                  sx={{
                    maxHeight: "180px",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  alt="prescription"
                  onClick={() => window.open(item)}
                  src={item}
                  component="img"
                  onError={(e: any) => {
                    e.target.src =
                      "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </>
      ) : (
        <Typography variant="body2" sx={{ mt: 2 }}>
          No Prescription Found
        </Typography>
      )}
    </>
  );
};

export default PrescriptionData;
