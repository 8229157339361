import store from "./store/store";
import {
  setAllOrderData,
  UpdateNotifications,
} from "./store/actions/useActions";
import { instance } from "./utils/api";
import { errorToast, successToast, transformAllOrder } from "./utils/common";
import { API_VERSION } from "./enums/common.enum";
import { toast } from "react-toastify";
import { format } from "date-fns";

const channel = new BroadcastChannel("sw-data");
channel.addEventListener("message", async (event) => {
  let data = event.data.msg;

  //update notifications in redux
  const allNotifications = store.getState()?.notifications?.data
    ? store.getState().notifications.data
    : [];

  store.dispatch(
    UpdateNotifications([
      {
        title: data.title,
        body: data.options.body,
        order_id: data.options.data[0].order_id,
        notif_type: data.options.data[0].notification_type,
        created_at: format(new Date(), "dd-MMM-yyyy, hh:mm:ss a"),
      },
      ...allNotifications,
    ])
  );
  //update redux end

  //update local storage
  localStorage.setItem(
    "user_notif",
    JSON.stringify([
      {
        title: data.title,
        body: data.options.body,
        order_id:
          data.options.data[0].order_id || data.options.data[0].report_id || "",
        notif_type: data.options.data[0].notification_type,
        created_at: format(new Date(), "dd-MMM-yyyy, hh:mm:ss a"),
      },
      ...allNotifications,
    ])
  );
  //update local storage end
  let orderData = data.options.data == null ? [] : data.options.data;

  const soundButton = document.getElementById("notification-play");
  soundButton?.click();

  if (orderData[0]?.notification_type === "app_issues") {
    if (window.location.href?.includes("app-issues")) {
      window.location.reload();
    }
    return;
  }

  let alertShown = false;

  await orderData.every(async (orderId, index) => {
    let error = false;

    try {
      const response = await instance.get(
        `${API_VERSION.V1}/orders/${orderId["order_id"]}`
      );
      if (response.data.status === "success") {
        // console.log('response from server', response.data);
        let order = response.data.response;
        if (order.order_partner.toLowerCase() === "dunzo") {
          // store.dispatch(updateDunzoOrders([order]));
        } else {
          //update
          const ordersToManage = store.getState()?.order?.allOrderData;
          if (
            ordersToManage &&
            ordersToManage[0]?.order_type === order.order_type
          ) {
            store.dispatch(
              setAllOrderData(
                transformAllOrder(ordersToManage, orderData?.order_id, order)
              )
            );
          }
        }
      } else {
        error = true;
      }
    } catch (err) {
      error = true;
    }

    if (error === true) {
      if (alertShown === false) {
        toast.dismiss();
        errorToast(
          "There has been an update in orders, please reload to sync with server."
        );
        alertShown = true;
      }
    } else if (index === orderData.length - 1) {
      if (alertShown === false) {
        toast.dismiss();
        successToast("Synced successfully!");
      }
    }
  });
});

const serviceChannel = new BroadcastChannel("sw-service");
serviceChannel.addEventListener("message", (event) => {
  let data = event.data.msg;

  let endpoint = data.endpoint;

  // console.log('received new endpoint', endpoint);

  // making the call to update
  let user = JSON.parse(localStorage.getItem("user"));
  let payload = {
    user_id: user.user_id,
    service: "order-dashboard",
    subscription_id: JSON.stringify(endpoint),
  };

  instance
    .post(`${API_VERSION.V1}/notifications/web-push/subscribe`, payload)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
});
