import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { commonTableOptions } from "../../../constants";
import { API_VERSION } from "../../../enums/common.enum";
import { amountConverter, handleError } from "../../../utils/common";

const WeeklyMetrics = () => {
  const [metricData, setMetricData] = useState<any>(null);
  const [partnerList, setPartnerList] = useState<string[]>([]);
  const [weekList, setWeekList] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<any>(null);
  const [cols, setCols] = useState<any>([]);
  const [filterBy, setFilterBy] = useState("total");
  const [allData, setAllData] = useState<any>(null);

  const getMetricsData = async () => {
    try {
      setLoading(true);
      let payload = {
        metric_type: "weekly_partner_metric",
      };
      const response = await fetch(
        `${config.URL}${API_VERSION.V2}/analytics/ops`,
        {
          method: "POST",
          body: JSON.stringify(payload),
        }
      );
      const result = await response.json();
      setAllData(result.response);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allData) {
      if (filterBy === "total") {
        setMetricData(allData.created_orders);
      } else if (filterBy === "fulfilled") {
        setMetricData(allData.delivered_orders);
      } else if (filterBy === "cancelled") {
        setMetricData(allData.cancelled_orders);
      } else {
        setMetricData(allData.processing_orders);
      }
    }
  }, [allData, filterBy]);

  useEffect(() => {
    if (metricData) {
      setPartnerList([
        ...(new Set(metricData.map((item: any) => item.partner_name)) as any),
      ]);
      setWeekList([
        ...(new Set(metricData.map((item: any) => item.week_count)) as any),
      ]);
    }
  }, [metricData]);

  useEffect(() => {
    if (partnerList.length > 0) {
      const finalData: any = partnerList.map((x) => {
        const singlePartnerData = metricData?.filter(
          (p: { partner_name: string }) => p.partner_name === x
        );
        const weekData = weekList?.map((w) => {
          return {
            ["week" + w]: {
              order_count:
                singlePartnerData?.find((d: any) => d.week_count === w)
                  ?.order_count || 0,
              order_sum:
                singlePartnerData?.find((d: any) => d.week_count === w)
                  ?.order_sum || 0,
            },
          };
        });

        //prepare final struc required:patner name and obj from above list
        let listObj = {
          partner_name: x,
        };
        weekData?.map(
          (wData) =>
            (listObj = {
              ...listObj,
              ...wData,
            })
        );
        return listObj;
      });
      setTableData(finalData);
      //update table cols
      let additionalCols = weekList?.map((m: any, ind: number) => {
        return {
          weekNum: m,
          name: `week${m}.order_count`,
          label: `Week ${m + 1}`,
          options: {
            sortDirection:
              ind === weekList.length - 1 ? ("asc" as any) : ("none" as any),
            searchable: false,
            customBodyRender: (value: any, tableMeta: any) => {
              const partnerName = tableMeta?.rowData[0];

              return (
                <Tooltip
                  title={
                    <Box>
                      {[
                        { key: "created_orders", label: "Total" },
                        { key: "cancelled_orders", label: "Cancelled" },
                        { key: "delivered_orders", label: "Fulfilled" },
                        { key: "processing_orders", label: "In-Process" },
                      ].map((b) => {
                        return (
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "12px !important" }}
                            pt={0.5}
                          >
                            {b.label}:&nbsp;
                            <strong>
                              {allData[b.key]
                                .filter(
                                  (x: any) => x.partner_name === partnerName
                                )
                                .find((y: any) => y.week_count === m)
                                ?.order_count || 0}
                            </strong>{" "}
                            , Value:&nbsp;
                            <strong>
                              &#8377;
                              {amountConverter(
                                allData[b.key]
                                  .filter(
                                    (x: any) => x.partner_name === partnerName
                                  )
                                  .find((y: any) => y.week_count === m)
                                  ?.order_sum
                              ) || 0}
                            </strong>
                          </Typography>
                        );
                      })}
                    </Box>
                  }
                >
                  <Typography variant="body2">{value}</Typography>
                </Tooltip>
              );
            },
          },
        };
      });
      setCols([
        ...[
          {
            name: "partner_name",
            label: "Partner Name",
            options: {
              sort: false,
            },
          },
        ],
        ...sortBy(additionalCols, ["weekNum"]),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerList]);

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Partner Name",
    sort: true,
    filter: false,
  };

  return (
    <>
      <Box textAlign="right" mb={3}>
        <FormControl sx={{ minWidth: "80px" }} size="small">
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            sx={{ textTransform: "capitalize" }}
            labelId="status-label"
            name="status"
            id="status"
            label="Status"
            value={filterBy}
            onChange={(e) => setFilterBy(e.target.value)}
          >
            {["total", "cancelled", "fulfilled", "in-process"].map(
              (x: string, i: number) => {
                return (
                  <MenuItem
                    sx={{ textTransform: "capitalize" }}
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
        }}
      >
        {loading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {tableData ? (
              <MUIDataTable
                title=""
                data={tableData}
                columns={cols}
                options={options}
              />
            ) : null}
          </>
        )}
      </Box>
    </>
  );
};

export default WeeklyMetrics;
