import * as yup from "yup";

export const activateUserSchema = yup.object().shape({
  emailUsername: yup.string().required("Email ID is required"),
  password: yup
    .string()
    .min(6, "should be of minimum 6 characters length")
    .max(15, "cannot exceed 15 characters length")
    .required("Temporary Password is required"),
  new_password: yup
    .string()
    .min(6, "should be of minimum 6 characters length")
    .max(100, "cannot exceed 100 characters length")
    .required("New Password is required"),
});
