import React, { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { errorToast, handleError, validateIntNum } from "../../utils/common";
import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { valueLimits } from "../../constants";
import { LoadingButton } from "@mui/lab";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";

const UserProfile = () => {
  const [userPhone, setUserPhone] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const getUserProfileDetail = async () => {
    if (!userPhone) {
      errorToast("Please enter whatsapp number to proceed");
      return;
    }
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/opd/order-service/user/profile?whatsapp_number=${userPhone}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (Object.keys(result.data.response).length === 0) {
          setUserData(null);
          errorToast(result.data.message);
        } else {
          setUserData(result.data.response);
        }
      } else {
        setUserData(null);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <Stack mt={2} direction="row" justifyContent="center">
        <WhatsAppIcon color="success" />
        <Typography variant="body1" sx={{ pl: 2 }}>
          Whatsapp Number
        </Typography>
      </Stack>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <TextField
          size="small"
          value={userPhone || ""}
          onChange={(e) =>
            setUserPhone(validateIntNum(e.target.value, valueLimits.MOBILE))
          }
          sx={{
            mt: 1.5,
            mr: { xs: 0, md: 1 },
            maxWidth: "100%",
            width: "320px",
          }}
          helperText={
            userPhone && userPhone?.length !== 10
              ? "Whatsapp number should be 10-digit"
              : ""
          }
          error={userPhone && userPhone?.length !== 10 ? true : false}
          inputProps={{
            maxLength: 10,
          }}
        />
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          disabled={userPhone && userPhone?.length !== 10}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          onClick={() => getUserProfileDetail()}
          sx={{ mt: 2 }}
        >
          Fetch Details
        </LoadingButton>
      </Stack>
      {userData && (
        <Box mb={2} sx={{ mx: { xs: "10px", md: "50px" } }}>
          <Box display="flex" mt={10} flexWrap="wrap">
            <Box flex="1 0 50%" textAlign="left">
              <h2
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  fontSize: "24px",
                }}
              >
                Personal Details
              </h2>
              {userData.full_name && (
                <Typography color="#4a5568">
                  Name: {userData.full_name}
                </Typography>
              )}

              {userData.corporate_email_id && (
                <Typography color="#4a5568">
                  Email: {userData.corporate_email_id}
                </Typography>
              )}

              {userData.whatsapp_number && (
                <Typography color="#4a5568">
                  Whatsapp Number: {userData.whatsapp_number}
                </Typography>
              )}

              {userData.balance && (
                <Typography color="#4a5568">
                  Balance: <u>{userData.balance}</u>
                </Typography>
              )}
            </Box>
            <Box flex="1 0 50%" textAlign="left">
              <h2
                style={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  fontSize: "24px",
                }}
              >
                Other Details
              </h2>

              {userData.corporate.name && (
                <Typography color="#4a5568">
                  Corporate Name: {userData.corporate.name}
                </Typography>
              )}

              {userData.onboarding_partner &&
                userData.onboarding_partner.name && (
                  <Typography color="#4a5568">
                    Onboarding Partner Name: {userData.onboarding_partner.name}
                  </Typography>
                )}
            </Box>
          </Box>

          <Typography variant="h3" my={3}>
            Last 10 Transactions
          </Typography>

          {userData.last_transactions.map((item: any, index: number) => {
            return (
              <Box
                display="flex"
                mt={3}
                p={3}
                key={index}
                sx={{
                  boxShadow: "1px 1px 3px rgba(0,0,0,0.3)",
                  borderRadius: "8px",
                }}
              >
                <Box flex="1 0 50%" textAlign="left">
                  <Typography variant="body2">
                    <span style={{ color: "#4a5568" }}>Txn Amount:</span>{" "}
                    <span
                      style={
                        item?.transaction_type === "debit"
                          ? { color: "red" }
                          : { color: "green" }
                      }
                    >
                      {item?.amount}
                      {item?.transaction_type === "debit" ? "-" : "+"}
                    </span>
                  </Typography>

                  {item?.transaction_id && (
                    <Typography color="#4a5568" variant="body2">
                      Txn Id: {item.transaction_id}
                    </Typography>
                  )}

                  {item?.created_at && (
                    <Typography color="#4a5568" variant="body2">
                      Date: {item.created_at.split(".")[0]}
                    </Typography>
                  )}
                </Box>
                <Box
                  flex="1 0 50%"
                  textAlign="left"
                  borderLeft="2px solid #e7e7e7"
                  pl={2}
                >
                  {item?.payment_details && (
                    <>
                      {item.payment_details.bank_reference_number && (
                        <Typography color="#4a5568" variant="body2">
                          Bank Ref. No.:{" "}
                          {item.payment_details.bank_reference_number}
                        </Typography>
                      )}
                      {item.payment_details.payment_upi_id && (
                        <Typography color="#4a5568" variant="body2">
                          Payment UPI: {item.payment_details.payment_upi_id}
                        </Typography>
                      )}
                    </>
                  )}
                  {item?.transaction_type === "debit" && (
                    <>
                      <Typography color="#4a5568" variant="body2">
                        Paid to:{" "}
                        {item.provider_details?.provider_name ||
                          item.provider_details?.provider_account_name}
                      </Typography>

                      {item?.bill_details && item?.bill_details?.status && (
                        <Typography color="#4a5568" variant="body2">
                          Invoice Status:{" "}
                          <span
                            style={
                              item.bill_details?.status === "pending"
                                ? { color: "red" }
                                : { color: "#4a5568" }
                            }
                          >
                            {item.bill_details?.status}
                          </span>
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </ErrorBoundary>
  );
};

export default UserProfile;
