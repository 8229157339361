import {
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { commonTableOptions } from "../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { instance } from "../../../../utils/api";
import {
  errorToast,
  getMuiTheme,
  handleError,
  successToast,
} from "../../../../utils/common";
import ErrorIcon from "@mui/icons-material/Error";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import CustomDialogTitle from "../../../../components/Common/customDialogTitle";
import { LoadingButton } from "@mui/lab";

const SuspiciousActionModal = ({
  showSuspiciousActionModal,
  setShowSuspiciousActionModal,
  data,
  callbackFn,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [remarks, setRemarks] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState("fraud");

  const handleSuspiciousTxn = async () => {
    try {
      if (window.confirm("Are you sure?")) {
        let payload: any = {
          transaction_id: data?.transaction_id,
          status: selectedStatus === "fraud" ? "rejected" : "approved",
          transaction_description: remarks,
        };
        setLoading(true);
        const result = await privateInstance.post(
          `${API_VERSION.V1}/payments/flagged-transaction`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          callbackFn(); //update list
          successToast(`Transaction Updated Successfully`);
          setShowSuspiciousActionModal(false);
        } else {
          errorToast(result.data.message);
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={showSuspiciousActionModal}
        onClose={() => setShowSuspiciousActionModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowSuspiciousActionModal(false)}
        >
          Suspicious Transaction
        </CustomDialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <FormControlLabel
                value="fraud"
                control={<Radio />}
                label="Fraud"
              />
              <FormControlLabel
                value="notfraud"
                control={<Radio />}
                label="Not Fraud"
              />
            </RadioGroup>
          </FormControl>

          {selectedStatus === "fraud" ? (
            <Typography sx={{ color: "#718096" }} pt={1} variant="body2">
              Blacklisting UPI: {data?.customer_vpa}
            </Typography>
          ) : null}

          <TextField
            id="remarks"
            label="Remarks"
            multiline
            rows={3}
            variant="outlined"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            fullWidth
            sx={{ mt: 1.5 }}
            InputLabelProps={{
              required: true,
            }}
          />

          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={4}
            gap={2}
          >
            <LoadingButton
              size="medium"
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              disabled={!remarks?.trim()}
              onClick={() => handleSuspiciousTxn()}
            >
              Submit
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const SuspiciousTransactions = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [showSuspiciousActionModal, setShowSuspiciousActionModal] =
    useState(false);

  const getData = async () => {
    let payload = {
      metric_type: "suspicious_transactions",
    };
    setLoading(true);
    try {
      const result = await instance.post(
        `${API_VERSION.V2}/analytics/digital-payments/overview`,
        payload
      );
      if (result.data?.status === API_RESULT.SUCCESS) {
        setData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cols = [
    {
      name: "transaction_id",
      label: "Transaction ID",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Stack gap={0.5} direction="row" alignItems="center">
              <Typography>{value}</Typography>
              <Tooltip
                title={
                  data?.find((x: any) => x.transaction_id === value)
                    .fraud_reason
                }
              >
                <ErrorIcon color="error" fontSize="small" />
              </Tooltip>
            </Stack>
          );
        },
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        searchable: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Chip
              size="small"
              label={value}
              sx={{
                backgroundColor:
                  value === "success"
                    ? "#C6F6D5"
                    : value === "failed"
                    ? "#C53030"
                    : "#FBD38D",
                padding: "6px",
                color: value === "failed" ? "#fff" : "#000",
              }}
            />
          );
        },
      },
    },
    {
      name: "bank_reference",
      label: "Bank Ref/UTR",
      options: { sort: false, filter: false },
    },
    {
      name: "terminal_id",
      label: "Terminal ID",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "pharmacy_name",
      label: "Pharmacy Name",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: { sort: false, filter: false },
    },
    {
      name: "customer_vpa",
      label: "Customer UPI",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "created_at_date",
      label: "Date",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={80} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "terminal_id",
      label: "Action",
      options: {
        download: false,
        sort: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack
              onClick={() => {
                setSelected(data?.find((x: any) => x.terminal_id === value));
                setShowSuspiciousActionModal(true);
              }}
              direction="row"
              alignItems="center"
              sx={{
                cursor: "pointer",
              }}
            >
              <Typography color="primary" sx={{ pl: 0.5 }} variant="body2">
                Update
              </Typography>
            </Stack>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "All",
    sort: true,
  };
  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable title="" data={data} columns={cols} options={options} />
      </ThemeProvider>
      {showSuspiciousActionModal ? (
        <SuspiciousActionModal
          showSuspiciousActionModal={showSuspiciousActionModal}
          setShowSuspiciousActionModal={setShowSuspiciousActionModal}
          data={selected}
          callbackFn={() => getData()}
        />
      ) : null}
    </div>
  );
};

export default SuspiciousTransactions;
