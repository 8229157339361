import { Box, TextField } from "@mui/material";
import React from "react";

const BankDetailsView = ({
  values,
  setFieldValue,
  handleBlur,
  touched,
  errors,
  mode,
}: any) => {
  return (
    <Box>
      <TextField
        fullWidth
        id="account_name"
        label="Account Name"
        size="small"
        value={values?.account_name}
        onChange={(e) => setFieldValue("account_name", e.target.value)}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        onBlur={handleBlur}
        helperText={
          errors.account_name && touched.account_name ? errors.account_name : ""
        }
        error={errors.account_name && touched.account_name ? true : false}
        InputLabelProps={{
          required: true,
        }}
      />
      <TextField
        fullWidth
        id="account_no"
        label="Account No"
        size="small"
        value={values?.account_no}
        onChange={(e) => setFieldValue("account_no", e.target.value)}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        onBlur={handleBlur}
        helperText={
          errors.account_no && touched.account_no ? errors.account_no : ""
        }
        error={errors.account_no && touched.account_no ? true : false}
        InputLabelProps={{
          required: true,
        }}
      />
      <TextField
        fullWidth
        id="bank_name"
        label="Bank Name"
        size="small"
        value={values?.bank_name}
        onChange={(e) => setFieldValue("bank_name", e.target.value)}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        onBlur={handleBlur}
        helperText={
          errors.bank_name && touched.bank_name ? errors.bank_name : ""
        }
        error={errors.bank_name && touched.bank_name ? true : false}
        InputLabelProps={{
          required: true,
        }}
      />
      <TextField
        fullWidth
        id="branch_name"
        label="Branch Name"
        size="small"
        value={values?.branch_name}
        onChange={(e) => setFieldValue("branch_name", e.target.value)}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        onBlur={handleBlur}
        helperText={
          mode === "edit" && errors.branch_name && touched.branch_name
            ? errors.branch_name
            : ""
        }
        error={
          mode === "edit" && errors.branch_name && touched.branch_name
            ? true
            : false
        }
        InputLabelProps={{
          required: mode === "edit" ? true : false,
        }}
      />
      <TextField
        fullWidth
        id="ifsc_code"
        label="IFSC Code"
        size="small"
        value={values?.ifsc_code}
        onChange={(e) => setFieldValue("ifsc_code", e.target.value)}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        onBlur={handleBlur}
        helperText={
          errors.ifsc_code && touched.ifsc_code ? errors.ifsc_code : ""
        }
        error={errors.ifsc_code && touched.ifsc_code ? true : false}
        InputLabelProps={{
          required: true,
        }}
      />
    </Box>
  );
};

export default BankDetailsView;
