import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  getMuiTheme,
  handleError,
  parseAndFormateDate,
  textCapitalize,
} from "../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData, setSearchText } from "../../store/actions/useActions";
import PaymentButton from "../../components/ManageOrder/GroupOrders/Payment/paymentButton";
import AddIcon from "@mui/icons-material/Add";
import CreateDunzoOrderModal from "./createDunzoOrderModal";
import DunzoOrderDetailModal from "./dunzoOrderDetailModal";
import KnowlarityCall from "../../components/Common/knowlarityCall";
import { commonTableOptions } from "../../constants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { format } from "date-fns";
import { useIconStyles } from "../../components/Helpers/iconStyles";
import OrderBadges from "../../components/ManageOrder/orderBadges";
import { ThemeProvider } from "@mui/styles";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import OrderByPartnerId from "../../components/ManageOrder/orderByPartnerId";

const Dunzo = () => {
  const [loading, setLoading] = useState(false);
  const [activePartnerOrderId, setActivePartnerOrderId] = useState(null);
  const [createDunzoOrderModal, setCreateDunzoOrderModal] = useState(false);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [dunzoProviders, setDunzoProviders] = useState<any>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loadingByDate, setLoadingByDate] = useState(false);
  const iconClasses = useIconStyles();

  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const getDunzoOrders = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/all/dunzo?ver=v3&order_type=medicines`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        dispatch(setAllOrderData(result.data.response));
      }
    } catch (err) {
      dispatch(setAllOrderData([]));
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  const getDunzoProviders = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/dunzo/providers`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setDunzoProviders(
          result.data.response?.filter((x: any) => x.status !== "disabled")
        );
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getPaymentStatus = (
    paymentStatus: string,
    paymentCreditStatus: string | null
  ) => {
    let result: string | null = "";

    if (paymentStatus === "paid") {
      result = paymentStatus;
    } else if (paymentCreditStatus == null) {
      result = "pending";
    } else {
      result = paymentCreditStatus;
    }
    return result;
  };

  const dunzoColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "merchant_details.provider_name",
      label: "PROVIDER NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "merchant_details.mobile",
      label: "MERCHANT MOBILE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "ORDER ID",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_status",
      label: "ORDER STATUS",
      options: {
        searchable: false,
      },
    },
    {
      name: "payment_credit_status",
      label: "PAYMENT CREDIT STATUS",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "invoice_details.payment_status",
      label: "PAYMENT STATUS",
      options: {
        searchable: false,
      },
    },
    {
      name: "created_by",
      label: "OPERATIONS",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "express_delivery",
      label: "EXPRESS DELIVERY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          <OrderByPartnerId />
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any, dataIndex: number) => {
      return (
        <CustomRowComponent key={data[0]} rowData={data} index={dataIndex} />
      );
    },
    searchPlaceholder: "Dunzo Order ID/Provider Name",
    onSearchChange: (val: string) => {
      dispatch(setSearchText(val));
    },
  };

  useEffect(() => {
    getDunzoOrders();
    getDunzoProviders();
    return () => {
      dispatch(setAllOrderData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrdersByDate = async () => {
    setLoadingByDate(true);
    try {
      const result = await privateInstance.get(
        `${
          API_VERSION.V1
        }/orders/all/dunzo?ver=v3&order_type=medicines&start_date=${format(
          startDate as any,
          "dd/MM/yyyy"
        )}&end_date=${format(endDate as any, "dd/MM/yyyy")}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        dispatch(setAllOrderData(result.data.response));
      }
    } catch (err) {
      dispatch(setAllOrderData([]));
      handleError(err);
    } finally {
      setLoadingByDate(false);
    }
  };

  function CustomRowComponent({ rowData, index }: any) {
    const [
      partner_order_id,
      created_at,
      provider_name,
      provider_mobile,
      order_id,
      order_status,
      payment_credit_status,
      payment_status,
      created_by,
      express_delivery,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body2"
                onClick={() => {
                  setActivePartnerOrderId(partner_order_id);
                  setShowOrderDetail(true);
                }}
                sx={{ cursor: "pointer" }}
                color="primary"
              >
                {partner_order_id}
              </Typography>
              <OrderBadges
                priority=""
                order_status={order_status}
                partner_order_id={partner_order_id}
                express_delivery={express_delivery}
              />
            </Stack>
            <Typography variant="body2" color="#718096">
              {parseAndFormateDate(created_at)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2">{provider_name}</Typography>
            {provider_mobile && (
              <KnowlarityCall
                order={{
                  order_id: order_id,
                  mobile: provider_mobile,
                }}
                showNumber={false}
              />
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {textCapitalize(order_status?.split("_")?.join(" "))}
          </Typography>
        </TableCell>
        <TableCell>
          <>
            {payment_status === null ? (
              ""
            ) : (
              <Typography variant="body2">
                {textCapitalize(
                  getPaymentStatus(payment_status, payment_credit_status)
                )}
              </Typography>
            )}
          </>
        </TableCell>
        <TableCell>
          <Typography variant="body2">Created by: {created_by}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row" justifyContent="center" alignItems="center">
            {payment_credit_status &&
            payment_credit_status?.toLowerCase() !==
              PAYMENT_CREDIT_STATUS.NA ? (
              <>
                {payment_credit_status === PAYMENT_CREDIT_STATUS.PENDING && (
                  <Tooltip title="Edit">
                    <EditIcon
                      className={iconClasses.borderedIcon}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setActivePartnerOrderId(partner_order_id);
                        setCreateDunzoOrderModal(true);
                      }}
                    />
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip title="Edit">
                <EditIcon
                  className={iconClasses.borderedIcon}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setActivePartnerOrderId(partner_order_id);
                    setCreateDunzoOrderModal(true);
                  }}
                />
              </Tooltip>
            )}
            <PaymentButton
              activePartner="dunzo"
              activePartnerOrderId={partner_order_id}
              activeOrderId={order_id}
              index={index}
            />
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="end"
          sx={{ mb: { xs: 1.5, md: 0 } }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(newValue: Date | null) => {
                setStartDate(newValue);
              }}
              maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  name="start_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(newValue: Date | null) => {
                if (newValue && startDate) {
                  if (newValue < startDate) {
                    errorToast("End date should be after the start date");
                    return;
                  }
                }
                setEndDate(newValue);
              }}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5, mx: { xs: 0, md: 1.5 } }}
                  name="end_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <LoadingButton
            sx={{ minWidth: "180px", mt: 1.5 }}
            size="medium"
            color="primary"
            loading={loadingByDate}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            variant="contained"
            type="button"
            onClick={fetchOrdersByDate}
            disabled={!startDate || !endDate}
          >
            Fetch Orders
          </LoadingButton>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setCreateDunzoOrderModal(!createDunzoOrderModal)}
        >
          Create Dunzo Order
        </Button>
      </Box>
      <Box sx={{ mt: { xs: 6.5, md: 7.5 } }}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            data={ordersToManage}
            columns={dunzoColumns}
            options={options}
          />
        </ThemeProvider>
      </Box>
      {createDunzoOrderModal && (
        <CreateDunzoOrderModal
          createDunzoOrderModal={createDunzoOrderModal}
          setCreateDunzoOrderModal={setCreateDunzoOrderModal}
          activePartnerOrderId={activePartnerOrderId}
          setActivePartnerOrderId={setActivePartnerOrderId}
          dunzoProviders={dunzoProviders}
          getDunzoOrders={getDunzoOrders}
        />
      )}
      {showOrderDetail && (
        <DunzoOrderDetailModal
          showOrderDetail={showOrderDetail}
          setShowOrderDetail={setShowOrderDetail}
          activePartnerOrderId={activePartnerOrderId}
          setActivePartnerOrderId={setActivePartnerOrderId}
        />
      )}
    </ErrorBoundary>
  );
};

export default Dunzo;
