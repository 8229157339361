import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  getReasons,
  handleError,
  successToast,
} from "../../../utils/common";

const ReasonModal = ({
  setParentModal,
  actionTaken,
  orderData,
  actionType,
  getWhitelistRequests,
}: any) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async () => {
    const payload = {
      order_id: orderData?.order_id,
      upi_id: orderData?.upi_id,
      name: orderData?.name,
      status: actionType === "activate" ? "active" : "inactive",
      reason: reason,
      formatted_address: orderData?.formatted_address,
    };

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/upi-whitelisting`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(
          `Request ${
            actionType === "activate" ? "accepted" : "rejected"
          } successfully`
        );
        setParentModal(false);
        //update
        getWhitelistRequests();
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {actionTaken && (
        <Dialog
          open={actionTaken}
          onClose={() => setParentModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setParentModal(false)}
          >
            <Typography variant="h4">
              Reason for {actionType === "activate" ? "accepting" : "rejecting"}{" "}
              the request:
            </Typography>
          </CustomDialogTitle>
          <DialogContent>
            <Stack alignItems="center">
              <FormControl fullWidth size="small" sx={{ mt: 1.5 }}>
                <InputLabel id="reason-label">Reason</InputLabel>
                <Select
                  labelId="reason-label"
                  name="reason"
                  id="reason"
                  label="Reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                >
                  {getReasons(actionType)?.map((x) => (
                    <MenuItem key={x} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={loading}
                disabled={!reason}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                onClick={() => handleSubmit()}
                sx={{ mt: 3 }}
              >
                Submit
              </LoadingButton>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const ActionOnRequestModal = ({
  actionOnRequestModal,
  setActionOnRequestModal,
  orderData,
  actionType,
  getWhitelistRequests,
}: any) => {
  const [actionTaken, setActionTaken] = useState(false);
  return (
    <Dialog
      open={actionOnRequestModal}
      onClose={() => setActionOnRequestModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setActionOnRequestModal(false)}
      ></CustomDialogTitle>
      <DialogContent>
        <Typography variant="h5" mt={3}>
          Are you sure you want to{" "}
          {actionType === "activate" ? "accept" : "reject"} this request?
        </Typography>
        <Stack direction="row" justifyContent="center" mt={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setActionOnRequestModal(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setActionTaken(true)}
            sx={{ ml: 1 }}
          >
            Yes
          </Button>
        </Stack>
        <ReasonModal
          setParentModal={setActionOnRequestModal}
          actionTaken={actionTaken}
          orderData={orderData}
          actionType={actionType}
          getWhitelistRequests={getWhitelistRequests}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ActionOnRequestModal;
