import { Autocomplete, TextField } from "@mui/material";
import { sortBy } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

interface IPartnersACProps {
  editor: string | null;
  setEditor?: any;
}

const additionalEditorsList = [
  "jai.srivastava@medpay.in",
  "sneha.jasiwal@medpay.in",
];

const EditorsAutocomplete = (props: IPartnersACProps) => {
  const { editor, setEditor } = props;
  const storedEditors = useSelector((state: any) => state.editors.editorsData);
  let allEditors: Array<string> = [...additionalEditorsList];
  storedEditors?.forEach((editor: any) => allEditors.push(editor.user_id));
  allEditors = sortBy([...(new Set(allEditors) as any)]);
  return (
    <Autocomplete
      ListboxProps={{ style: { maxHeight: "14rem" } }}
      fullWidth
      size="small"
      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
      value={editor}
      onChange={(e, val) => setEditor(val)}
      id="editor"
      options={allEditors}
      renderInput={(params) => (
        <TextField name="editor" label="Editor" {...params} />
      )}
      //fix autocomplete warning
      // isOptionEqualToValue={(option: any, value: any) =>
      //   option.user_id === value.user_id
      // }
    />
  );
};

export default EditorsAutocomplete;
