import { LoadingButton } from "@mui/lab";
import {
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FindUpiPhone from "../../../components/ManageOrder/GroupOrders/Common/findUpiByPhone";
import FindUpiQR from "../../../components/ManageOrder/GroupOrders/Common/findUpiByQR";
import VerifyUpi from "../../../components/ManageOrder/GroupOrders/Common/verifyUpi";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { errorToast, handleError, successToast } from "../../../utils/common";

function WhitelistTab() {
  const [whitelistType, setWhitelistType] = useState("qr_code");
  const [qrData, setQrData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [upiPhone, setUpiPhone] = useState("");
  const [upiPlatform, setUpiPlatform] = useState("");

  const privateInstance = useAxiosInstance();

  const handleSubmit = async (actionType: string) => {
    let payload = {
      upi_id: qrData?.upi_id,
      name: qrData?.account_holder_name,
      status: actionType,
    };

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/upi-whitelisting`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(result.data.message);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ textAlign: "center", mt: 1.5, p: { xs: 2, md: 5 } }}>
      <FormControl sx={{ mb: 1.5 }}>
        <RadioGroup
          id="whitelist_type"
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(e) => {
            setWhitelistType(e.target.value);
            setQrData({
              ...qrData,
              upi_id: "",
              account_holder_name: "",
            });
          }}
          defaultValue="qr_code"
        >
          <FormControlLabel
            value="qr_code"
            control={<Radio />}
            label="QR Code"
          />
          <FormControlLabel value="upi_id" control={<Radio />} label="UPI ID" />
          <FormControlLabel
            value="upi_number"
            control={<Radio />}
            label="UPI Number"
          />
        </RadioGroup>
      </FormControl>
      {whitelistType === "qr_code" && (
        <Stack alignItems="center" mt={3}>
          <Stack direction={{ xs: "column", md: "row" }}>
            <Typography variant="h6" sx={{ mr: 3, mb: { xs: 2, md: 0 } }}>
              Upload QR Code:
            </Typography>
            <FindUpiQR
              qrData={qrData}
              setQrData={setQrData}
              setFieldValue={undefined}
            />
          </Stack>
        </Stack>
      )}
      {whitelistType === "upi_id" && (
        <Stack alignItems="center" mt={1.5}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" sx={{ mr: 3 }}>
              Enter UPI ID:
            </Typography>
            <VerifyUpi upiData={qrData} setUpiData={setQrData} />
          </Stack>
        </Stack>
      )}
      {whitelistType === "upi_number" && (
        <Stack alignItems="center" mt={1.5}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" sx={{ mr: 3 }}>
              Enter UPI NO.:
            </Typography>
            <FindUpiPhone
              upiSearchData={qrData}
              upiPhone={upiPhone}
              upiPlatform={upiPlatform}
              setUpiPhone={setUpiPhone}
              setUpiPlatform={setUpiPlatform}
              setUpiSearchData={setQrData}
            />
          </Stack>
        </Stack>
      )}
      {qrData?.account_holder_name && (
        <Stack alignItems="center" mt={3}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="h6"
              sx={{ mr: 3, width: { xs: "100px", md: "160px" } }}
            >
              UPI ID:
            </Typography>
            <TextField
              fullWidth
              id="upi_id"
              label="UPI ID"
              size="small"
              value={qrData?.upi_id || ""}
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              disabled
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="h6"
              sx={{ mr: 3, width: { xs: "100px", md: "160px" } }}
            >
              UPI Name:
            </Typography>
            <TextField
              fullWidth
              id="upi_name"
              label="UPI Name"
              size="small"
              value={qrData?.account_holder_name || ""}
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              disabled
            />
          </Stack>
        </Stack>
      )}
      <Stack direction="row" justifyContent="center">
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          loading={loading}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          disabled={!qrData?.account_holder_name}
          sx={{ mt: 3 }}
          onClick={() => handleSubmit("active")}
        >
          Whitelist
        </LoadingButton>
        {whitelistType === "upi_id" && (
          <LoadingButton
            type="button"
            color="error"
            variant="contained"
            loading={loading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            disabled={!qrData?.account_holder_name}
            sx={{ mt: 3, ml: 1 }}
            onClick={() => handleSubmit("inactive")}
          >
            Deactivate
          </LoadingButton>
        )}
      </Stack>
    </Card>
  );
}

export default WhitelistTab;
