import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../utils/common";
import CustomDialogTitle from "../Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../store/actions/useActions";
import MedPayLogo from "../../assets/images/medpay.png";
import { PartnerIcon } from "../../assets/icons";

export const typeOfNotes = ["Internal use", "Partner", "Tech Support"];

interface noteData {
  sender: string;
  content: string;
  created_at_timestamp: number;
  created_by: string;
  status: string;
  visibility: string[];
  order_id: string;
  note_id: string;
  type: string;
}

const NotesModal = ({
  notesModal,
  setNotesModal,
  orderId,
  readOnly = false,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [noteFor, setNoteFor] = useState("internal use");
  const [notesData, setNotesData] = useState<any>([]);

  const user: AuthenticatedUser = useUserInfo();

  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  React.useEffect(() => {
    getNotesByOrderId();
    return () => {
      setNotesModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotesByOrderId = async () => {
    setLoadingNotes(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/notes?order_id=${orderId}&user_id=${user?.user_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setNotesData(result.data.response.notes);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingNotes(false);
    }
  };

  const handleSubmit = async () => {
    let payload: any = {
      content: noteContent,
    };

    if (noteFor === "partner") {
      payload = { ...payload, visibility: "partner" };
    }

    if (noteFor === "tech support") {
      payload = { ...payload, type: "techsupport" };
      if (
        !window.confirm("This will raise a Tech Support ticket, are you sure?")
      ) {
        return false;
      }
    }
    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/notes?order_id=${orderId}`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Notes updated successfully");
        setNotesModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={notesModal}
      onClose={() => setNotesModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={notesData.length > 0 ? "md" : "sm"}
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setNotesModal(false)}
      >
        Notes
      </CustomDialogTitle>
      <DialogContent>
        <Box
          className="hideScrollBar"
          sx={{ maxHeight: "438px", overflowY: "scroll" }}
        >
          {loadingNotes ? (
            <Stack direction="row" justifyContent="center">
              <CircularProgress size={20} />
            </Stack>
          ) : notesData && notesData.length > 0 ? (
            notesData.map((note: noteData, index: number) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  justifyContent={
                    note.sender === "medpay" ? "flex-end" : "flex-start"
                  }
                  alignItems="center"
                >
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      {note.sender === "partner" && (
                        <PartnerIcon text={note.created_by?.split("@")[0]} />
                      )}
                      <Box
                        ml={note.sender === "partner" ? 1 : 0}
                        mr={note.sender === "medpay" ? 1 : 0}
                        mt={1}
                        sx={{ backgroundColor: "#eeeeee", borderRadius: 3 }}
                        px={2}
                        py={1}
                      >
                        <Typography variant="body2">{note?.content}</Typography>
                        <Typography
                          sx={{ fontSize: "12px !important" }}
                          color="#718096"
                          textAlign={
                            note.sender === "medpay" ? "right" : "left"
                          }
                        >
                          {new Date(
                            Number(note.created_at_timestamp * 1000)
                          ).toLocaleString()}{" "}
                          -{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {note.created_by?.split("@")[0]}
                          </span>
                        </Typography>
                      </Box>
                      {note.sender === "medpay" && (
                        <Tooltip
                          title={`For: ${
                            note.visibility?.includes("partner")
                              ? "Partner"
                              : "Internal"
                          }`}
                        >
                          <img
                            src={MedPayLogo}
                            alt="medpay"
                            style={{ height: "22px", width: "22px" }}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              );
            })
          ) : readOnly ? (
            <Typography>No data found</Typography>
          ) : null}
        </Box>
        {readOnly ? null : (
          <>
            {user && user?.partner === "medpay" && (
              <FormControl fullWidth size="small" sx={{ mt: 4 }}>
                <InputLabel id="share-with-label">Share With</InputLabel>
                <Select
                  labelId="share-with-label"
                  name="share-with"
                  id="share-with"
                  label="Share With"
                  value={noteFor}
                  onChange={(e) => setNoteFor(e.target.value)}
                >
                  {typeOfNotes.map((x: string) => {
                    return (
                      <MenuItem key={x?.toLowerCase()} value={x?.toLowerCase()}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth sx={{ mt: 1.5 }}>
              <TextField
                id="outlined-textarea"
                label="Message"
                placeholder="Order is leaving early!"
                multiline
                rows={4}
                variant="outlined"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
              />
            </FormControl>
          </>
        )}
      </DialogContent>
      {readOnly ? null : (
        <Box display="flex" flexDirection="row" justifyContent="center" mb={3}>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={loading}
            disabled={!noteContent || noteContent.trim() === ""}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={handleSubmit}
            sx={{ mr: 2 }}
          >
            Submit
          </LoadingButton>
        </Box>
      )}
    </Dialog>
  );
};

export default NotesModal;
