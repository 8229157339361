import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../utils/common";

interface OrderAuditsProps {
  oldAuditLogs: Array<string>;
  orderId: string;
}

interface AuditData {
  action: string;
  user: string;
  time: string;
  order_status: string;
  remarks: string;
}

const OrderAudits = ({ oldAuditLogs, orderId }: OrderAuditsProps) => {
  const [loading, setLoading] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const privateInstance = useAxiosInstance();

  const getOrderAudits = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/audit-logs?order_id=${orderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setAuditData(result.data.response.audit_logs);
      } else {
        setAuditData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderAudits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* new audits */}
          {auditData && auditData.length > 0 ? (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Order Status</TableCell>
                    <TableCell>Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditData.map((row: AuditData, index: number) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.action}</TableCell>
                      <TableCell>{row.user}</TableCell>
                      <TableCell>{row.time}</TableCell>
                      <TableCell>{row.order_status}</TableCell>
                      <TableCell>{row.remarks}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              {/* fallback */}
              {oldAuditLogs && oldAuditLogs.length > 0 ? (
                oldAuditLogs.map((item: string, i: number) => (
                  <Typography key={i} variant="body1" gutterBottom>
                    {i + 1}. {item}
                  </Typography>
                ))
              ) : (
                <Typography variant="body2">No Record Found.</Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderAudits;
