import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { format, parse } from "date-fns";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import { ORDER_STATUS } from "../../../../enums/order.enum";
import {
  API_RESULT,
  API_VERSION,
  USER_ROLE,
} from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import useUserInfo from "../../../../hooks/useUserInfo";
import BookLogisticsModal from "./bookLogisticsModal";
import { LogisticDetailView } from "../../../../pages/Logistics/logisticsDetailModal";
import LogisticCancelModal from "../../../../pages/Logistics/logisticCancelModal";
import LogisticSupportModal from "../../../../pages/Logistics/logisticSupportModal";
import { logisticsPartners } from "../../../../constants";

const medicineDeliveryTimeSlots = [
  "9AM - 12PM",
  "12PM - 3PM",
  "3PM - 6PM",
  "6PM - 9PM",
  "9PM - 11PM",
];

const OutForDeliveryModal = ({
  outForDeliveryModal,
  setOutForDeliveryModal,
  activeOrderId,
  activePartner,
  activePartnerOrderId,
}: any) => {
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [showSelfDeliveryView, setShowSelfDeliveryView] = useState(false);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [filteredDeliverySlots, setFilteredDeliverySlots] = useState<string[]>([
    ...medicineDeliveryTimeSlots,
  ]);
  const [showBookLogisticsModal, setShowBookLogisticsModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const [showLogisticsDetailView, setShowLogisticsDetailView] = useState(false);
  const [showLogisticCancelModal, setShowLogisticCancelModal] = useState(false);
  const [showLogisticSupportModal, setShowLogisticSupportModal] =
    useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const userPermissions = useSelector((state: any) => state.user.permissions);

  const hasPermission = (key: string) => {
    return userPermissions?.some((x: any) => x.permission_id === key);
  };

  const packagePickedAt = () => {
    let pickedAt = format(
      parse(
        orderData?.package_picked_at?.split(".")[0],
        "dd/MM/yyyy HH:mm:ss",
        new Date()
      ),
      "dd-MM-yyyy, h:mm aa"
    );
    return parse(pickedAt, "dd-MM-yyyy, h:mm aa", new Date());
  };

  const getOrderByPartnerOrderId = async () => {
    setLoadingData(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${activePartner}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
        const orderResponse = result.data.response;
        //show self delivery view
        if (
          orderResponse?.merchant_details?.process_mode?.delivery_otp === "y"
        ) {
          setShowSelfDeliveryView(true);
        }
        //show logistics detail view
        if (orderResponse.logistics) {
          setShowLogisticsDetailView(true);
        }
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getOrderByPartnerOrderId();

    return () => {
      setOrderData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderData?.merchant_details?.delivery_slot) {
      const allotedDeliverySlot = orderData?.merchant_details?.delivery_slot;
      const deliverySlotDate = allotedDeliverySlot?.split(", ")[0];
      const deliverySlotDatePieces = deliverySlotDate?.split("-");
      setSelectedDate(
        new Date(
          deliverySlotDatePieces[2],
          deliverySlotDatePieces[1] - 1,
          deliverySlotDatePieces[0]
        )
      );

      const deliverySlotHour = allotedDeliverySlot
        ?.split(", ")[1]
        ?.split(" ")[0];

      if (deliverySlotHour === "9AM") {
        setSelectedTime(medicineDeliveryTimeSlots[0]);
      } else if (deliverySlotHour === "12PM") {
        setSelectedTime(medicineDeliveryTimeSlots[1]);
      } else if (deliverySlotHour === "3PM") {
        setSelectedTime(medicineDeliveryTimeSlots[2]);
      } else if (deliverySlotHour === "6PM") {
        setSelectedTime(medicineDeliveryTimeSlots[3]);
      } else if (deliverySlotHour === "9PM") {
        setSelectedTime(medicineDeliveryTimeSlots[4]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    const currentDate = new Date().getDate();
    const currentHour = new Date().getHours();

    if (selectedDate.getDate() === currentDate) {
      if (currentHour < 12) {
        setFilteredDeliverySlots([...medicineDeliveryTimeSlots]);
      }
      if (currentHour >= 12 && currentHour < 15) {
        setFilteredDeliverySlots([...medicineDeliveryTimeSlots].slice(1));
      }
      if (currentHour >= 15 && currentHour < 18) {
        setFilteredDeliverySlots([...medicineDeliveryTimeSlots].slice(2));
      }
      if (currentHour >= 18 && currentHour < 21) {
        setFilteredDeliverySlots([...medicineDeliveryTimeSlots].slice(3));
      }
      if (currentHour >= 21 && currentHour < 23) {
        setFilteredDeliverySlots([...medicineDeliveryTimeSlots].slice(4));
      }
      if (currentHour >= 23) {
        setFilteredDeliverySlots([]);
      }
    } else {
      setFilteredDeliverySlots([...medicineDeliveryTimeSlots]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    setSelectedTime(filteredDeliverySlots[0]);
  }, [filteredDeliverySlots]);

  const handleSubmit = async () => {
    let payload = {
      delivery_slot: `${format(selectedDate, "dd-MM-yyyy")}, ${selectedTime}`,
      location_id: orderData?.merchant_details?.location_id,
      order_id: activeOrderId,
      type: "delivery-slot-update",
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (orderData?.package_picked_at) {
          successToast("Time slot updated successfully");
          setOutForDeliveryModal(false);
          setLoading(false);
          //update
          dispatch(
            setAllOrderData(
              transformAllOrder(
                ordersToManage,
                payload.order_id,
                result.data.response
              )
            )
          );
        } else {
          outForDeliveryCallback();
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const outForDeliveryCallback = async () => {
    let payload = {
      delivery_slot: `${format(selectedDate, "dd-MM-yyyy")}, ${selectedTime}`,
      order_id: activeOrderId,
      order_status: ORDER_STATUS.PACKAGE_PICKED,
      type: "package-picked-update",
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(result.data.message);
        setOutForDeliveryModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
      setOutForDeliveryModal(false);
    }
  };

  return (
    <Dialog
      open={outForDeliveryModal}
      onClose={() => setOutForDeliveryModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="custom-dialog-title"
        onClose={() => setOutForDeliveryModal(false)}
      >
        {showLogisticsDetailView
          ? `Logistics - ${orderData?.order_partner} | ${orderData?.partner_order_id}`
          : "Out for Delivery"}
      </CustomDialogTitle>
      <DialogContent>
        {loadingData ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            {showLogisticsDetailView ? (
              <>
                {orderData?.logistics?.status === "cancelled" ? (
                  <Alert severity="info">
                    <span>
                      Logistics request with task-id:{" "}
                      <b>{orderData?.logistics?.task_id}</b> was cancelled for
                      partner "{orderData?.logistics?.delivery_partner}"
                    </span>{" "}
                    |&nbsp;
                    <span
                      style={{
                        color: "#0058ff",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowBookLogisticsModal(true);
                      }}
                    >
                      Book Again
                    </span>
                  </Alert>
                ) : null}
                <LogisticDetailView orderLogisticsData={orderData.logistics} />
                {orderData?.logistics?.status === "cancelled" ? (
                  <>
                    {user?.role === USER_ROLE.SUPER_ADMIN ||
                    user?.role === USER_ROLE.ADMIN ||
                    user?.package_picked_updation === "enabled" ? (
                      <Box display="flex" alignItems="center">
                        <Typography mt={2}>
                          This logistics request was cancelled,
                        </Typography>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={() => setShowLogisticsDetailView(false)}
                          sx={{ mt: 2, ml: 2 }}
                        >
                          Process Manually
                        </Button>
                      </Box>
                    ) : null}
                  </>
                ) : (
                  <Stack
                    mt={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {user?.role === USER_ROLE.SUPER_ADMIN ||
                    user?.role === USER_ROLE.ADMIN ||
                    user?.package_picked_updation === "enabled" ? (
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => setShowLogisticsDetailView(false)}
                        sx={{ mr: 2 }}
                      >
                        Process Manually
                      </Button>
                    ) : null}
                    {logisticsPartners.find(
                      (x) => x.value === orderData?.logistics?.delivery_partner
                    )?.support ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setSelectedOrderId(orderData?.order_id);
                          setShowLogisticSupportModal(true);
                        }}
                      >
                        Support
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setSelectedOrderId(orderData?.order_id);
                        setShowLogisticCancelModal(true);
                      }}
                      sx={{ ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                )}
              </>
            ) : (
              <>
                {showSelfDeliveryView ? (
                  <Box textAlign="center">
                    <Typography variant="body1">
                      Pharmacy opted for self delivery, Please wait until
                      pharmacy mark it out for delivery. Please wait or write an
                      email on <b>techsupport@medpay.in</b>.
                    </Typography>
                    {(user?.role === USER_ROLE.SUPER_ADMIN ||
                      user?.role === USER_ROLE.ADMIN ||
                      user?.package_picked_updation === "enabled") && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowSelfDeliveryView(false)}
                        sx={{ mt: 2 }}
                      >
                        Process Manually
                      </Button>
                    )}
                    {orderData?.logistics ? null : (
                      <>
                        {hasPermission("logistics") ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowBookLogisticsModal(true)}
                            sx={{ mt: 2, ml: 2 }}
                          >
                            Book Logistics
                          </Button>
                        ) : null}
                      </>
                    )}
                  </Box>
                ) : (
                  <>
                    <Typography variant="h6" sx={{ ml: -0.5 }}>
                      Shall deliver at:
                    </Typography>
                    <Stack
                      direction="column"
                      alignItems="center"
                      sx={{ mt: 3 }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label={
                            orderData?.merchant_details?.delivery_slot
                              ? "Revised Date"
                              : "Date"
                          }
                          inputFormat="dd-MM-yyyy"
                          value={selectedDate}
                          onChange={(newValue: any) => {
                            setSelectedDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          minDate={
                            orderData?.package_picked_at
                              ? packagePickedAt()
                              : new Date()
                          }
                        />
                      </LocalizationProvider>
                      <ToggleButtonGroup
                        color="primary"
                        value={selectedTime}
                        exclusive
                        onChange={(
                          event: React.MouseEvent<HTMLElement>,
                          newVal: string
                        ) => {
                          setSelectedTime(newVal);
                        }}
                        aria-label="delivery-time"
                        sx={{ my: 3 }}
                      >
                        {filteredDeliverySlots.map((x) => {
                          return (
                            <ToggleButton
                              key={x}
                              value={x}
                              aria-label="left aligned"
                            >
                              {x}
                            </ToggleButton>
                          );
                        })}
                      </ToggleButtonGroup>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        {orderData?.order_status ===
                        ORDER_STATUS.PAYMENT_COLLECTED ? (
                          <Typography
                            variant="body2"
                            sx={{ opacity: 0.85, mb: 2 }}
                          >
                            Note: This will disable OTP to the customer
                          </Typography>
                        ) : null}
                        <Box>
                          <LoadingButton
                            color="primary"
                            variant="contained"
                            loading={loading}
                            disabled={!selectedDate || !selectedTime}
                            loadingIndicator={
                              <CircularProgress
                                size={20}
                                sx={{ color: "#fff" }}
                              />
                            }
                            onClick={handleSubmit}
                          >
                            Submit
                          </LoadingButton>
                          {orderData && orderData.logistics ? null : (
                            <>
                              {hasPermission("logistics") ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    setShowBookLogisticsModal(true)
                                  }
                                  sx={{ ml: 2 }}
                                >
                                  Book Logistics
                                </Button>
                              ) : null}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  </>
                )}
              </>
            )}
          </>
        )}
        {showLogisticCancelModal && (
          <LogisticCancelModal
            showLogisticCancelModal={showLogisticCancelModal}
            setShowLogisticCancelModal={setShowLogisticCancelModal}
            activeOrderId={selectedOrderId}
            updateFn={getOrderByPartnerOrderId}
          />
        )}
        {showLogisticSupportModal && (
          <LogisticSupportModal
            showLogisticSupportModal={showLogisticSupportModal}
            setShowLogisticSupportModal={setShowLogisticSupportModal}
            activeOrderId={selectedOrderId}
            supportHistory={[]}
            updateFn={getOrderByPartnerOrderId}
          />
        )}
        {showBookLogisticsModal ? (
          <BookLogisticsModal
            activePartner={orderData?.order_partner}
            activePartnerOrderId={orderData?.partner_order_id}
            showBookLogisticsModal={showBookLogisticsModal}
            setShowBookLogisticsModal={setShowBookLogisticsModal}
            setParentModal={setOutForDeliveryModal}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default OutForDeliveryModal;
