export const mfrData = [
  "10 Drug Mart",
  "15Herbs Organic Labs Pvt Ltd",
  "1Mile Healthcare",
  "1UP LifeCare Pvt Ltd",
  "3A Pharmaceuticals",
  "3B Elbrit Life Sciences Pvt Ltd",
  "3C Health Solution Pvt Ltd",
  "3C Pharmaceuticals Pvt Ltd",
  "3D Healthcare",
  "3M India Ltd",
  "4Care Lifescience Pvt Ltd",
  "4Gen Pharmaceuticals",
  "4in1 Infotech",
  "4life Pharmaceuticals Pvt Ltd",
  "6ipain Healthcare Pvt Ltd",
  "6th Skin Pharmaceuticals Pvt Ltd",
  "911 Dermatology Pvt Ltd",
  "A B Enterprises",
  "A B Pharmaceuticals Pvt Ltd",
  "A D Pharmaceuticals",
  "A G Industries",
  "A Menarini India Pvt Ltd",
  "A N Pharmacia",
  "A N Pharmacia Laboratories Pvt Ltd",
  "A N Pharmacurticals Pvt Ltd",
  "A One Cosmetics Products",
  "A Star Marketing Pvt Ltd",
  "A To Z Pharmaceuticals Pvt Ltd",
  "A V Gupta & Co Pvt Ltd",
  "A V Molecules Pvt Ltd",
  "A V Pharmaceuticals",
  "A4 Pharma Pvt Ltd",
  "AAA Pharma Trade Pvt Ltd",
  "Aabtac Lifecare Pvt Ltd",
  "Aadi Health Care",
  "Aadi Medicos Lab Pvt Ltd",
  "Aadvik Foods & Products Pvt Ltd",
  "Aady Health Care",
  "Aaeka Pharmaceuticals",
  "Aagam Life Sciences Pvt Ltd",
  "Aak Healthcare",
  "Aakaar Medical Technologies Pvt Ltd",
  "Aalborg Genetica Pvt Ltd",
  "Aaltramed Healthcare Ltd",
  "Aamorb Pharmaceuticals Pvt Ltd",
  "Aan Pharma Pvt Ltd",
  "Aanav Healthcare",
  "Aanika Pharma",
  "Aaphia Healthcare",
  "Aapthi Pharma",
  "Aar Biosys",
  "AAR ESS Remedies Pvt Ltd",
  "Aar Vee Pharma Pvt Ltd",
  "Aara Life Sciences (I) Pvt Ltd",
  "Aarav Pharmaceuticals",
  "Aarcin Pharmaceutical LLP",
  "Aareen Healthcare Pvt Ltd",
  "Aarin Labs",
  "Aarkios Health Pvt Ltd",
  "Aarma Laboratories",
  "Aarmac Labs",
  "Aarmed Formulation Pvt Ltd",
  "Aarna Care Pharma",
  "Aarnix Healthsciences Pvt Ltd",
  "Aarohi Pharmaceuticals Ltd",
  "Aaron Pharmaceuticals Pvt Ltd",
  "Aarotax Pharmaceutical Pvt Ltd",
  "Aarpik Pharmaceuticals Pvt Ltd",
  "Aarsh Remedies Pvt Ltd",
  "Aarshaveda Wellness Pvt Ltd",
  "Aartha Pharmaceuticals Pvt Ltd",
  "Aarti Health Care Pvt Ltd",
  "Aarti Life Science",
  "Aarushi Healthcare Pvt Ltd",
  "Aarux Caduceus",
  "Aarux Pharmaceuticals Pvt Ltd",
  "Aarvi Drugs",
  "Aas Pharma",
  "Aaschem Life Care Pvt Ltd",
  "Aaspada Enterprises Pvt Ltd",
  "Aastha Lifecure Science",
  "Aaura Care Pharmaceutical Pvt Ltd",
  "Aavhanzr Pharmaceutical Pvt Ltd",
  "Aavn Pharmaceuticals (OPC) Pvt Ltd",
  "Aax Global",
  "Aaztrazivixa Lifesciences",
  "AB Allcare Biotech",
  "Abacus Pharmaceutical Pvt Ltd",
  "Abaris Healthcare",
  "Abatis Holistic",
  "Abaxis Labs Pvt Ltd",
  "Abbares Pharmaceuticals Pvt Ltd",
  "Abbey Edge India Pvt Ltd",
  "Abbey Health Care Pvt Ltd",
  "Abbivin Pharmaceuticals Pvt Ltd",
  "Abbizon Lifesciences Pvt Ltd",
  "Abbott Healthcare Pvt Ltd",
  "Abbott India Ltd",
  "Abbott Lab Ltd",
  "Abdach Healthcare Pvt Ltd",
  "Abeal Pharma",
  "Abeers Gramudyog",
  "Abenava Medi Pharma Pvt Ltd",
  "Abendis Lifesciences",
  "Aberdure Pharma",
  "Abetar Healthcare",
  "Abettor Life Sciences Pvt Ltd",
  "Abhigyaan Labs Pvt Ltd",
  "Abhimantrit GuruPrasadam Ayurveda Herbal Foods Pvt Ltd",
  "Abhyuday Pharmaceuticals Pvt Ltd",
  "Abia Pharmaceuticals Pvt Ltd",
  "Abiba Pharmacia Pvt Ltd",
  "Abigail Care Pharmaceutical",
  "Abis Exports (I) Pvt Ltd",
  "Abivo Derma",
  "Abiz Pharma",
  "ABK Imports Pvt Ltd",
  "ABL Lifecare Pvt Ltd",
  "Ablaze Life Science",
  "Ablus Pharma",
  "ABN Drugs",
  "Abnart pharma",
  "Abode Pharmaceuticals Pvt Ltd",
  "Abony Healthcare Ltd",
  "Abrente Lifesciences Pvt Ltd",
  "Abrik Remedies Pvt Ltd",
  "Abron Healthcare",
  "Abronic Healthcare",
  "ABS Pharmaceutical Company",
  "ABS Remedies Pvt Ltd",
  "Abseil Pharmaceticals Pvt Ltd",
  "Absolute Medicare Solutions Pvt Ltd",
  "ABT Ltd",
  "Abyon Pharmaceuticals",
  "Abyss Pharma Pvt Ltd",
  "Acambis (india) Pharmaceuticals Pvt Ltd",
  "Acceleration Organics Pvt Ltd",
  "Accend Pharma Pvt Ltd",
  "Accendo Pharmaceutical",
  "Accent Pharma & Diagnostics",
  "Accentus Health Care",
  "Access Life Science",
  "Accilex Diacardo",
  "Accilex Nutricorp",
  "Acclimate Life Sciences",
  "Accord Pharmaceuticals",
  "Accordis India",
  "Accosts Remedies Pvt Ltd",
  "Accott Healthcare",
  "Accra Pac (India) Pvt Ltd",
  "Accretion Pharmaceuticals",
  "Accrue Pharmaceuticals Pvt Ltd",
  "Accuffy Lifesciences",
  "Accuminn Labs",
  "Accurate Pharmaceuticals",
  "Accure Pharma Pvt Ltd",
  "Accuren BioPharma",
  "Accurex Biomedical Pvt Ltd",
  "Ace Incorporation",
  "Ace Life Sciences",
  "Ace Pharma",
  "Ace Remedies",
  "Acekinetics Healthcare Pvt Ltd",
  "Acel Pharma Pvt Ltd",
  "Acela Healthcare Pvt Ltd",
  "Aceon Pharma",
  "Acerus Pharma",
  "Aceso Pharma",
  "Achates Healthcare",
  "Achievaa Pharmaceutical Ind",
  "Achillea Life Sciences Pvt Ltd",
  "Achilles Healthcare Pvt Ltd",
  "Achutyam Pvt Ltd",
  "Acichem Laboratories",
  "Acidus Ojas Pvt Ltd",
  "Acinom Healthcare",
  "Acinonyx Pharmaceutical Pvt Ltd",
  "Acinta Pharmaceuticals Pvt Ltd",
  "Aclaris Therapeutics Inc ",
  "Acme Generics LLP",
  "Acme Lifesciences",
  "Acme Pharmaceutical",
  "Acmedix Pharma LLP",
  "Acon Laboratories Inc",
  "Aconwell Pharma Pvt Ltd",
  "Acquicare Pharma Pvt Ltd",
  "Acron Pharmaceuticals",
  "Across Laboratories Pvt Ltd",
  "ACS Pharmaceuticals",
  "Actawin Lifesciences Pvt Ltd",
  "Actiff Pharmaceuticals Pvt Ltd",
  "Activa Lifesciences",
  "Active Healthcare",
  "Acto Pharma Pvt Ltd",
  "Actus Health Care",
  "Acugen Lifesciences Pvt Ltd",
  "Acuition Pharmaceutical",
  "Aculife Healthcare Pvt Ltd",
  "Acumen Pharmaceuticals Pvt Ltd",
  "Acventa Research Labs Pvt Ltd",
  "Adalbert Healthcare Pvt Ltd",
  "Adalia Pharmaceuticals LLP",
  "Adamant Pharma",
  "Adbio Organics Pvt Ltd",
  "Adcco Ltd",
  "Adchem Biotech",
  "Adcock Ingram Healthcare Pvt Ltd",
  "Add Labs Biotech",
  "Add Veda Lifecare Pvt Ltd",
  "Addii Biotech Pvt Ltd",
  "Addissun Life Science Pvt Ltd",
  "Addmarc Lifesciences",
  "Addsum Lifesciences",
  "Adeesh Agrofoods",
  "Adegen Pharma Pvt Ltd",
  "Adeka India Pvt Ltd",
  "Adelina Lifesciences",
  "Adelmo Healthcare",
  "Adelson Pharma Pvt Ltd",
  "Aden Healthcare",
  "Adenscot Healthcare",
  "Adept Biocare",
  "Adesan Pharma",
  "Adham Pharmaceuticals",
  "Adi Healthcare Solutions LLP",
  "Adico Healtcare",
  "Adicure Pharma Pvt Ltd",
  "Adikan Healthcare Pvt Ltd",
  "Adikem Healthcare Pvt Ltd",
  "Adinex Healthcare",
  "Adips Laboratories Ltd",
  "Adirex Pharmaceuticals",
  "Adisan Healthcare Pvt ltd",
  "Adison Laboratories",
  "Adison Pharmaceuticals",
  "Adisys Healthcare",
  "Adithri Pharma",
  "Aditri Marketing Pvt Ltd",
  "Aditya Biologicals Pvt Ltd",
  "Aditya Birla Fashion & Retail Ltd",
  "Aditya Infotech Ltd",
  "Aditya Pharmaceuticals",
  "Adivis Pharma Pvt Ltd",
  "Adiza Healthcare",
  "Adjener Pharmaceuticals Pvt Ltd",
  "Adley Formulations",
  "Adlib Biologicals",
  "Admac Pharma Ltd",
  "Admak Pharmaceutical Pvt Ltd",
  "Admit Pharmaceuticals Pvt Ltd",
  "ADN Life Sciences",
  "ADN Pharmaceuticals Pvt Ltd",
  "Adney Healthcare Pvt Ltd",
  "Adnova Healthcare Pvt Ltd",
  "Adoc Pharma",
  "Adokia Nutratech",
  "Adon Pharmaceuticals Pvt Ltd",
  "Adonis Healthcare",
  "Adonis Laboratories Pvt Ltd",
  "Adonis Phytoceuticals Pvt Ltd",
  "Adore Healthcare",
  "Adorfem Cheminnova Life Sciences",
  "Adreama Biotech Pvt Ltd",
  "Adrena Healthcare Pvt Ltd",
  "Adret Retail Pvt Ltd(Kapiva)",
  "Adro Drugs",
  "Adroit Biomed Ltd",
  "Adroit Lifescience Pvt Ltd",
  "Adroit Pharmaceuticals Pvt Ltd",
  "Ads Lifesciences Pvt Ltd",
  "Adsila Organcis Pvt Ltd",
  "Adsteriti Pharmaceutical Pvt Ltd",
  "Adthera Consumer Brands Pvt Ltd",
  "Advaith Life Sciences Pvt Ltd",
  "Advamed Pharma",
  "Advance Concept Pharmaceuticals Pvt Ltd",
  "Advance Nutratech",
  "Advance Pharma",
  "Advance Revive",
  "Advent Life Sciences LLP",
  "Advent Organics Pvt Ltd",
  "Adventia Innopharma Pvt Ltd",
  "Adventure Life Sciences Pvt Ltd",
  "Adventus Laboratories India Pvt Ltd",
  "Advik Laboratories Ltd",
  "Advira Technomanagement Pvt Ltd",
  "Advok Pharmacia Pvt Ltd",
  "Adwel Green Root Organic Pvt  Ltd",
  "Adwel Green Root Organic Pvt 聽Ltd",
  "Adwin Pharma",
  "ADZO Lifesciences Pvt Ltd",
  "Aece Ventures",
  "Aeclat Lifesciences Pvt Ltd",
  "Aegis Pharmaceuticals",
  "Aegis Prescriptions Pvt Ltd",
  "Aegisco Remedies Pvt Ltd",
  "Aegle Healthcare",
  "Aeka Herbal Cosmetics Pvt Ltd",
  "Aelida Healthcare",
  "Aelius Lifesciences Llp",
  "Aelivs Healthcare Pvt Ltd",
  "Aelix Healthcare",
  "Aenor Pharmaceuticals Pvt Ltd",
  "Aeobury Healthcare Pvt Ltd",
  "Aeomeds Healthcare Pvt Ltd",
  "Aeon Formulation Pvt Ltd",
  "Aeon Pharma Pvt Ltd",
  "Aeon Remedies",
  "Aequitas Healthcare Pvt Ltd",
  "Aeran Lab India Pvt Ltd",
  "Aerangis Pharmaceuticals Pvt Ltd",
  "Aeras Pharma",
  "Aerc India",
  "Aereon Specialities Pvt Ltd",
  "Aeretia Healthcare Pvt Ltd",
  "Aerochem Neutron",
  "Aeron Healthcare",
  "Aeron Remedies",
  "Aeronutrix Sports Products Pvt Ltd",
  "Aerozest Life Science",
  "Aeryn Lifesciences Pvt Ltd",
  "Aesmira Lifesciences Pvt Ltd",
  "Aespire Formulations Pvt Ltd",
  "Aesthetic Nutrition",
  "Aesthetic Solutions Pvt Ltd",
  "Aeston Life Sciences",
  "Aethicz Biolife",
  "Aexiom Pharmaceuticals",
  "AFD Labs (P) Ltd",
  "Afferent Wearable Technology",
  "Affiance Drugs and Formulations",
  "Afficacy Biotek",
  "Affinity Life Sciences Pvt Ltd",
  "Affirm Healthcare",
  "Affix Pharmaceuticals Pvt Ltd",
  "Affluential Pharmaceuticals Pvt Ltd",
  "Affy Pharma Pvt Ltd",
  "Afive Pharma",
  "Afxia Franke Pvt Ltd",
  "Agaaz Ophthalmics",
  "Agaram Medical Services Pvt Ltd",
  "Agastya Research Health Products",
  "Agatsa Software Pvt Ltd",
  "Age Biotech",
  "Agenext Biopharma",
  "Agex Laboratories Pvt Ltd",
  "Aggrowin Pharma",
  "Agicure Life Science Pvt Ltd",
  "AGIO Pharmaceuticals Ltd",
  "Aglowkem Pharmaceuticals",
  "Aglowmed Drugs Pvt Ltd",
  "Aglowmed Ltd",
  "AGM Biotec",
  "Agn Healthcare",
  "Agnes Life Sciences",
  "Agoc Care Pvt Ltd",
  "Agonist Healthcare",
  "Agrawal Drugs Pvt Ltd",
  "Agrawal Pharmaceuticals",
  "Agresco Pharma",
  "Agrim Pharmaceuticals",
  "Agron Remedies Pvt Ltd",
  "Agrosaf Pharmaceuticals",
  "Ags Pharmaceuticals Pvt Ltd",
  "Agshov Pharmaceuticals Pvt Ltd",
  "Ahaan Healthcare Pvt Ltd",
  "Ahagoal Healthcare Pvt Ltd",
  "Ahana Food & Beverage Company",
  "Ahead of Time Pvt Ltd",
  "Ahen Pharmaceuticals",
  "Ahlcon Parenterals India Ltd",
  "Ai Health Care LLP",
  "Aice Health Care Pvt Ltd",
  "Aice Healthcare",
  "Aicom Biomedics Pharma Pvt Ltd",
  "Aidsoul Asia Pharmaceutical Pvt Ltd",
  "Aidwell Lifesciences Pvt Ltd",
  "Aigis Biotec",
  "Aileron Life Sciences Pvt Ltd",
  "Aim Four Life Line Pvt Ltd",
  "Aimcad Biotech Pvt Ltd",
  "Aimco Pharmaceuticals",
  "Aimil Pharmaceuticals India Ltd",
  "Aimmax Pharma",
  "Aingo Pharma Pvt Ltd",
  "Airas Healthcare Pvt Ltd",
  "Airier Pharmaceitical Pvt Ltd",
  "Aishika Pharma",
  "Aishwarya Healthcare",
  "Aishwarya Healthcare And Akums Drugs & Pharmaceuticals Ltd",
  "Aislin Formulation Pvt Ltd",
  "Aisure Pharmaceuticals Pvt Ltd",
  "Aitne Lifesciences",
  "AIWA Pharmaceuticals",
  "Aiwo Ltd",
  "Aiwon Life Sciences",
  "Ajala Pharmaceuticals",
  "Ajanta Pharma Ltd",
  "Ajanta Pharma Ltd(Zillion)",
  "Ajanta Pharma LtdAlmironaureu",
  "Ajapa Pharma Pvt Ltd",
  "Ajuras Pharmaceutical Pvt Ltd",
  "AK Marketing",
  "Akash Pharma",
  "Akcent Healthcare",
  "Akesiss Pharma (Lazo)",
  "Akesiss Pharma Pvt Ltd",
  "Akiez Healthcare",
  "Akio Pharmaceutical Pvt Ltd",
  "Akishtha Biothech",
  "Akme Biotec",
  "Akmed Biotech",
  "Akned Healthcare Pvt Ltd",
  "Aknil Biotech",
  "Akognos Life Sciences",
  "Akon Pharmaceuticals",
  "Akops Healthshine Pvt Ltd",
  "Akpash Pharmaceuticals Pvt Ltd",
  "Akrovis Pharmaceuticals",
  "AKS Life Global Pvt Ltd",
  "Aksh Pharmaceuticals India Ltd",
  "Aksh Remedies",
  "Akshar Molecules",
  "Akshat Enterprises",
  "Akshita Remedies",
  "Aksigen Hospital Care",
  "Aktimas Bio Pharmaceuticals Pvt Ltd",
  "Aktis Pharma India Pvt Ltd",
  "Akul Pharma",
  "Akumentis Healthcare Ltd",
  "Akums Drugs Pharmaceuticals",
  "Akunez Biotech",
  "Akuom Biotech Pvt Ltd",
  "Akylo Healthcare Pvt Ltd",
  "Alacris Healthcare Pvt Ltd",
  "Alanmark Healthcare Pvt Ltd",
  "Alar Laboratories Ltd",
  "Alar Laboratories Pvt Ltd",
  "Alardius Healthcare",
  "Alaric Healthcare Pvt Ltd",
  "Alarius Healthcare",
  "Alarsin Pharmaceuticals",
  "Alaster Healthcare",
  "Alathea Biotec Pvt Ltd",
  "Alaxia Healthcare",
  "Alayza Life Sciences",
  "Alba Healthcare Pvt Ltd",
  "Albagen Lifesciences Pvt Ltd",
  "Albatross Healthcare Pvt Ltd",
  "Albatross Pharmaceuticals",
  "Albatross Skimed Pvt Ltd",
  "Albeit Pharmaceuticals Pvt Ltd",
  "Albero Pharma Pvt Ltd",
  "Albert David Ltd",
  "Albia Biocare",
  "Albin Healthcare",
  "Albinis Biotech Pvt Ltd",
  "Albion Lifecare Pvt Ltd",
  "Albiorix Pharmaceuticals Pvt Ltd",
  "Albris Healthcare And Biotech Pvt Ltd",
  "Albus Healthcare Pvt Ltd",
  "Alcale Healthcare",
  "Alcare Laboratories Pvt Ltd",
  "Alcea Biotech Pvt Ltd",
  "Alchem Phytoceuticals Ltd",
  "Alchemist Chemsynth Pharma Pvt Ltd",
  "Alchemist Life Science Ltd",
  "Alcia Pharma",
  "Alco Formulation",
  "Alcon Laboratories",
  "Alcon Labs Pvt Ltd",
  "Alcott Healthcare Pvt Ltd",
  "Alcure Pharmaceutical Laboratories India Pvt Ltd",
  "Alcuria Pharma Pvt Ltd",
  "Aldan Healthcare Pvt Ltd",
  "Alde Medi Impex Ltd",
  "Alder Biochem Pvt Ltd",
  "Aldoc Pharmaceuticals",
  "Aldrin Pharma Pvt Ltd",
  "Alegic Pharmaceuticals Pvt Ltd",
  "Aleixo Pharma",
  "Alembic Pharma Pvt Ltd",
  "Alemcure Healthcare Pvt Ltd",
  "Alencare Pharma",
  "Alencik Pharmaceuticals Pvt Ltd",
  "Alencure Biotech Pvt Ltd",
  "Alentica Pharmaceuticals Pvt Ltd",
  "Alentra Healthcare",
  "Alenvision Pharma Pvt Ltd",
  "Alercon Eyecare",
  "Alerick pharmaceuticals",
  "Alessa Healthcare",
  "Alester Pharma Pvt Ltd",
  "Aletheia Medicare Pvt Ltd",
  "Aletron Pharmaceuticals",
  "Alex Pen Remedies",
  "Alexa Life Sciences",
  "Alexa Medicare Pvt Ltd",
  "Alexa Organics Pvt Ltd",
  "Alexcyl Healthcare Pvt Ltd",
  "Alexin Bio-Sciences Pvt Ltd",
  "Alexis Lifesciences LLP",
  "Alezon Remedies",
  "Alfa Igra Inc",
  "Alfa Omega Healthcare",
  "Alfaz Lifestyle Solutions",
  "Algen Healthcare Ltd",
  "Algen Remedies Pvt Ltd",
  "Algerish pharma",
  "Alican Pharmaceutical Pvt Ltd",
  "Alicanto Drugs Pvt Ltd",
  "Alice Healthcare Pvt Ltd",
  "Alicia Healthcare",
  "Alicon Pharmaceutical Pvt Ltd",
  "Alidac Corza",
  "Alidac Genetics & Pharmaceuticals",
  "Alienist Pharmaceutical Pvt Ltd",
  "Alife Healthcare",
  "Alika Healthcare Pvt Ltd",
  "Alio Lifesciences",
  "Alish Life Saving Impex Pvt Ltd",
  "Alishan Biotec Pvt Ltd",
  "Alisier Drugs Pvt Ltd",
  "Ality Healthcare",
  "Alive Healthcare",
  "Alive Pharmaceutical Pvt Ltd",
  "Alixar Healthcare Pvt Ltd",
  "Alixia Pharma",
  "Alixinox Pharmaceuticals",
  "Alka Pharma",
  "Alkem Laboratories Ltd",
  "Alkon Formulation Pvt Ltd",
  "Alkrut Pharmaceuticals LLP",
  "Alkyd Pharmaceuticals Pvt Ltd",
  "All Grace Pharma",
  "All Indian Origin Chemists & Distributors Ltd",
  "Allan Biotech",
  "Allan Healthcare",
  "Allay Pharma",
  "Allbrids Lifescience Pvt Ltd",
  "Allcure Lifesciences",
  "Allegan Lifecare Pvt Ltd",
  "Allegiant Biotech",
  "Allen Dale Biosciences",
  "Allen Health Care Co Ltd",
  "Allenberry Healthcare",
  "Allenburys Pharma Ltd",
  "Allencia Biosciences",
  "Allenge India",
  "Allensia Pharmaceuticals Pvt Ltd",
  "Allentis Pharmaceuticals Pvt Ltd",
  "Allergan India Ltd",
  "Allergan India Pvt Ltd",
  "Alles Gueti Pharmaceuticals",
  "Allet Lifesciences",
  "Alletia Remedies Pvt Ltd",
  "Alleviate Pharmaceuticals Pvt Ltd",
  "Alleviate Therapeutics",
  "Allex Medical Systems",
  "Allfam Pharmaceuticals Pvt Ltd",
  "Allgems Healthcare",
  "Alliaance Biotech",
  "Alliance Healthcare P Ltd",
  "Alliance Remedies",
  "Alliance Trade",
  "Allied Chemicals & Pharmaceuticals Pvt Ltd",
  "Allien Pharmaceuticals",
  "Allies Pharma",
  "Alligate Healthcare",
  "Allin Exporters",
  "Allis Life Sciences",
  "Allites life Sciences Pvt Ltd",
  "Allito Biocare",
  "AllKind Healthcare",
  "Allminds Labs Pvt Ltd",
  "Alloes Pharmaceuticals",
  "Alloes Pharmacueticals",
  "Allons Pharma",
  "Allpa India Medicines Pvt Ltd",
  "ALLRX Pharmaceuticals Pvt Ltd",
  "Alltecure Healthcare",
  "Alltime Nutrition Pvt Ltd",
  "Allure Remedies Pvt Ltd",
  "Alluvium Health Care Pvt Ltd",
  "Allwell Formulations",
  "Allycure Lifesciences Pvt Ltd",
  "Allysia Lifesciences Pvt Ltd",
  "Almatica Pharmaceuticals Pvt Ltd",
  "Almed Drugs Pvt Ltd",
  "Almet Corporation Ltd",
  "Alna Biotech Pvt Ltd",
  "Alniche Life Sciences Pvt Ltd",
  "Alnourish Healthy Foods Pvt Ltd",
  "Alobiote Healthcare",
  "Aloe Veda Personal Care",
  "Alonsoa Lifesciences",
  "Alonza Healthcare Pvt Ltd",
  "Alopa Herbal Healthcare Pvt Ltd",
  "Alpa Laboratories Ltd",
  "Alpha and Omega Therapeutics Pvt Ltd",
  "Alpha Aromatic Pvt Ltd",
  "Alpha Digital Health Pvt Ltd",
  "Alpha Drugs & Pharmaceuticals",
  "Alpha Health Care",
  "Alpha Healthcare",
  "Alpha Lab",
  "Alpha Mortal",
  "Alpha Pharmaceuticals",
  "Alphard Pharmaceuticals Pvt Ltd",
  "Alphonso Pharmacia",
  "Alpic Biotech Ltd",
  "Alpic Life Sciences",
  "Alpic Remedies Ltd",
  "Alpine Lifescience Pvt Ltd",
  "Alpinia Biopharma Pvt Ltd",
  "Alports Healthcare Pvt Ltd",
  "Alpure Healthcare",
  "Alred Health Care Ltd",
  "Alrgee 180 Tablet 10 No'S",
  "Alric Bio Medics",
  "Alrom Pharmaceuticals",
  "Alrosa Pharmaceuticals Pvt Ltd",
  "Alson Pharma Pvt Ltd",
  "Alsun Pharma",
  "Alswamitra Botanicals LLP",
  "Altak Pharmaceuticals Pvt Ltd",
  "Altar Healthcare Pvt Ltd",
  "Altar Pharmaceuticals Pvt Ltd",
  "Altavista Life Sciences Pvt Ltd",
  "Altcare Pharmaceuticals Pvt Ltd",
  "Altena Healthcare",
  "Alter Ego Pharmaceuticals",
  "Alteus Biogenics Pvt Ltd",
  "Alteus Pharma",
  "Altin Healthcare",
  "Altius Life Sciences",
  "Altius Pharmaceuticals Pvt Ltd",
  "Altiuz Pharma Care",
  "Alto Healthcare Pvt Ltd",
  "Altocare Lifesciences Pvt Ltd",
  "Alton Biosciences Pvt Ltd",
  "Altova Healthcare",
  "Altrawell Biotech Pvt Ltd",
  "Altruist Pharmacia Pvt Ltd",
  "Alvance Pharma",
  "Alveera Healthcare",
  "Alvid Biocare",
  "Alvid-Indo Corporation",
  "Alvigor Lifecare Llp",
  "Alvio Pharma",
  "Alvista Biosciences Pvt Ltd",
  "Alvizia Healthcare Pvt Ltd",
  "Alvoncare Pharma",
  "Alvonics Lifesciences Pvt Ltd",
  "Alwin Wilcare Pharmaceuticals",
  "Alymed Healthcare Pvt Ltd",
  "Alys Lifevision",
  "Alysis Pharma",
  "Alzer Pharma",
  "Alzeta Pharma Corp",
  "AMA Herbal Laboratories Pvt Ltd",
  "Amagen India Life Sciences",
  "Aman Healthcare",
  "Amanta Healthcare Ltd",
  "Amar Overseas",
  "Amare Cor Pharmaceuticals",
  "Amaterasu Lifesciences LLP",
  "Amaze Lifestyle Products Pvt Ltd",
  "Amazeal Life Science Pvt Ltd",
  "Amazeen Pharmaceuticals Pvt Ltd",
  "Amazing India",
  "Amazing Reaserch Laboratories Ltd",
  "Amazone Pharmaceuticals Pvt Ltd",
  "Ambe Phytoextracts Pvt Ltd",
  "Amber Laboratories Pvt Ltd",
  "Ambic Ayurchem Ltd",
  "Ambic Ayurved India Pvt Ltd",
  "Ambience Pharma",
  "Ambiens Pharmaceuticals Pvt Ltd",
  "Ambient Healthcare Pvt Ltd",
  "Ambient Pharmaceuticals Pvt Ltd",
  "Ambit Bio Medix",
  "Ambit Health Care Pvt Ltd",
  "Ambition Pharma Pvt Ltd",
  "Ambitions drugs",
  "Ambitious Pharmaceutical Pvt Ltd",
  "Ambitree India Pvt Ltd",
  "Ambitus Lifesciences",
  "Amble Biotech Pvt Ltd",
  "Ambreyo Lifesciences",
  "Ambrezzia Pharmaceuticals",
  "Ambriona Cacoa Blends Pvt Ltd",
  "Ambrocia Life Sciences",
  "Ambromide Pharma Pvt Ltd",
  "Ambrons pharma Pvt Ltd",
  "Ambrosia Drugs Pvt Ltd",
  "Ambrosia Pharma",
  "Ambrosia Pharmaceuticals",
  "Ambroza Lifecare LLP",
  "Amcare Pharmaceuticals",
  "Amdis Healthsciences Pvt Ltd",
  "Amedure Pharmaceuticals",
  "Amee Pharma",
  "Ameha Healthcare",
  "Amelio Pharmaceuticals",
  "Amenarini India",
  "American Biocare",
  "American Remedies Ltd",
  "Amerigen Lifesciences LLP",
  "Ameya Pharmaceuticals",
  "Amezia Pharmaceutical Pvt Ltd",
  "Ami Polymer Pvt Ltd",
  "Amicable Pharmaceutical Pvt Ltd",
  "Amicures Research Pvt Ltd",
  "Amideep Pharmaceuticals",
  "Amimax Pharma",
  "Aminov Healthcare Pvt Ltd",
  "Aminus Remedies",
  "Amista Labs Pvt Ltd",
  "Amity Health Care Pvt Ltd",
  "Amnean Therapeutics Pvt Ltd",
  "Amnicure Healthcare Pvt Ltd",
  "Amogh Pharmaceuticals Pvt Ltd",
  "Amoke Capsules India Pvt Ltd",
  "Amon Biotech",
  "Amor Pharmaceutical Pvt Ltd",
  "Amore Health Essentials Pvt Ltd",
  "Amox Pharmaceuticals Pvt Ltd",
  "Ampho Healthcare",
  "Ampira Biotechnics Pvt Ltd",
  "Ampiron Pharmaceuticals",
  "Amplec Healthcare Pvt Ltd",
  "AMPM Laboratory Pvt Ltd",
  "Ampra Pharmaceuticals",
  "AMPS Biotech Pvt Ltd",
  "Ampus Life Sciences Ltd",
  "Amra Remedies",
  "Amray Healthcare",
  "Amrit Remedies Pvt Ltd",
  "Amritha Naturmeds Herbal",
  "Amro Pharma Pvt Ltd",
  "Amron Healthcare Coporation",
  "Amrona Medical Pvt Ltd",
  "Amronco Lifesciences Ltd",
  "Amrutanjan Health Care Ltd",
  "Amsure Healthcare",
  "Amugen Healthcare Pvt Ltd",
  "Amulya Labs",
  "Amvia Biotech",
  "Amway India Enterprises Pvt Ltd",
  "Amwell Pharmaceuticals Pvt Ltd",
  "Amwill Health Care",
  "Amwill Healthcare",
  "Amwill Healthcare Pvt Ltd",
  "Amwin Pharmaceuticals",
  "Amy Lifesciences",
  "Amyra Healthcare Pvt Ltd",
  "Amyteez Healthcare Pvt Ltd",
  "Amytree Pharmaceuticals",
  "Amzenex Healthcare Pvt Ltd",
  "Amzor Healthcare",
  "AN Pharmaceuticals Pvt Ltd",
  "Anaadi Global Co ",
  "Anabiosis Medisciences Ltd",
  "Anabolic Remedies Pvt Ltd",
  "Anahatt Aushadh Pvt Ltd",
  "Analeptic Manufacturing India",
  "Anamiva Life Sciences",
  "AnandAshram Ayurved Pvt Ltd",
  "Anant Pharmaceuticals Pvt Ltd",
  "Anavia Life Sciences Pvt Ltd",
  "Anax Lifescience",
  "Anax Pharma Pvt Ltd",
  "Anaya Healthcare",
  "Anbu Pharmaceuticals",
  "Ancalima Lifesciences Ltd",
  "Anchal Lifescience Pvt Ltd",
  "Anchor Consumer Products Pvt Ltd",
  "Anchor Pharma Pvt Ltd",
  "Ancient Life Sciences",
  "Andee Lifesciences",
  "Andic Lifesciences Pvt Ltd",
  "Andosa Lab India Pvt Ltd",
  "Andro Lifecare Pharma",
  "Andromeda Pharmaceutical Pvt Ltd",
  "Anduron Remedials Pvt Ltd",
  "Andwin Pharma Pvt Ltd",
  "Anevay Pharmaceuticals Pvt Ltd",
  "Anexas Pharmaceuticals",
  "ANG Lifesciences India Ltd",
  "Angeas Healthcare Ltd",
  "Angel Biotech",
  "Angel Laboratories Pvt Ltd",
  "AngiaRx Lifescience",
  "Angiolife Healthcare Pvt Ltd",
  "Anglica Healthcare",
  "Anglo French Drugs & Inds Ltd",
  "Anglo French Drugs & Industries Ltd",
  "Anglo-French Drugs & Industries Ltd",
  "Anhox Healthcare Pvt Ltd",
  "Ani Healthcare Pvt Ltd",
  "Anies Healthcare",
  "Anikem Laboratories",
  "Aniksan Healthcare Pvt Ltd",
  "Anista Healthcare",
  "Anisum Lifesciences",
  "Anita International",
  "Anita’s Aromatic Solutions",
  "Anitek Lifecare",
  "Anjali Mukerjee Health Total",
  "Anju Pharmaceuticals",
  "Ank Labs",
  "Ankaa Pharmaceutical",
  "Ankom Laboratories",
  "Ankran Biotech Pvt Ltd",
  "Ankyl Earth Pharmaceuticals Pvt Ltd",
  "Anley Healthcare",
  "Anmol Healthcare",
  "Annie Pharmaceuticals Pvt Ltd",
  "Annodyne Drugs",
  "Annona Life Science",
  "Annoxy Healthcare Pvt Ltd",
  "Anoco Pharmaceuticals India Pvt Ltd",
  "Anogen Pharma Pvt Ltd",
  "Anondita Health Care",
  "Anormed Pharma",
  "Anris Healthcare India Pvt Ltd",
  "Anrose Pharma Ltd",
  "Ansel Healthcare",
  "Ansh Pharmaceutical Pvt Ltd",
  "Anster Pharmaceuticals Pvt Ltd",
  "ANT Pharmaceuticals Pvt Ltd",
  "Antares Pharmaceuticals Pvt Ltd",
  "Antek Drugs Pvt Ltd",
  "Antex Pharma",
  "Anthem Biopharma",
  "Anthem Biopharma Pvt Ltd",
  "Anthem Cellutions(I)Pvt Ltd",
  "Anthiea Labs Pvt Ltd",
  "Anthrall Health Solutions",
  "Anthus Pharmaceuticals Pvt Ltd",
  "Antigen Healthcare P Ltd",
  "Antik Biotech",
  "Antila Lifesciences Pvt Ltd",
  "Antilia Pharma Pvt Ltd",
  "Antiseptic Pharmaceuticals",
  "Antler Life Sciences Pvt Ltd",
  "Antrix Life Technologies & Life Sciences Pvt Ltd",
  "Anuveda Life Sciences Pvt Ltd",
  "Anveto Pharmaceuticals",
  "Anvi Pharmaceuticals",
  "Anvicure Drugs",
  "Anvik Biotech",
  "Anvil Biotech",
  "Anvit Ayurveda",
  "Anze Biotech",
  "Aoibhinn Pharma LLP",
  "AP Medicare",
  "Apa Pharmaceuticals",
  "Apace Life Sciences Pvt Ltd",
  "Apco Medicare India Pvt Ltd",
  "Apco Pharma Ltd",
  "Apcon Remedies",
  "Apcos Naturals Pvt Ltd",
  "Apella Healthcare",
  "Apellon Biotech",
  "Apex Diagnostic And Imaging Pvt Ltd",
  "Apex Formulations Pvt Ltd",
  "Apex Lab Pvt Ltd",
  "Apex Laboratories Pvt Ltd",
  "APG Online & Retail Co ",
  "Apical Biologicals",
  "Apical Healthcare Pvt Ltd",
  "Apichem Healthcare",
  "Apids Pharmaceuticals",
  "Apikos Pharma",
  "Apios Lifesciences Pvt Ltd",
  "Apkavit Lifesciences",
  "Aplomb Pharma Pvt Ltd",
  "Aplonis Healthcare",
  "APOLLO HEALTHCO Ltd",
  "Apollo Hospitals Enterprises Ltd",
  "Apoorva Drugs & Pharmaceuticals",
  "Apostle Remedies",
  "Apotek Pharma Pvt Ltd",
  "Apotex Lifesciences",
  "Apotex Pharmachem India Pvt Ltd",
  "Apotheca Pharmaceuticals Pvt Ltd",
  "Apothera Laboratories (OPC) Pvt Ltd",
  "Appasamy Ocular Device Pvt Ltd",
  "Appena Life Pvt Ltd",
  "Apple Biomedics Ltd",
  "Apple Biotech",
  "Apple Laboratories Pvt Ltd",
  "Apple Lifescience",
  "Apple Pharmaceuticals",
  "Apple Therapeutics Pvt Ltd",
  "Applied Communication & Controls",
  "Apprima Pharma",
  "Approach Healthcare Pvt Ltd",
  "Aprazer Healthcare Pvt Ltd",
  "Aprica HealthCare Pvt Ltd",
  "Aprica Pharmaceuticals Pvt Ltd",
  "Apricot Labs Pvt Ltd",
  "Aprikose Pharmaceuticals Pvt Ltd",
  "Aprique Pharmaceuticals",
  "Aps Biotech Pvt Ltd",
  "Apsolabs Pvt Ltd",
  "APT Cure Laboratories",
  "Aptroshine Pharmaceuticals",
  "Aptus Pharma Pvt Ltd",
  "Apus Life Pvt Ltd",
  "Apytas Healthcare",
  "Aqcor Drug",
  "Aqmax Biotech Pvt Ltd",
  "Aqua Fina Injecta Pvt Ltd",
  "Aqua Labs",
  "Aqua Vitoe Laboratories",
  "Aquadry Pharma Pvt Ltd",
  "AquaFeasts India",
  "Aqualife Pharmaceuticals",
  "Aquarius Health Care Pvt Ltd",
  "Aquascot Healthcare Pvt Ltd",
  "Aquila Labs",
  "Aquila Pharmaceutical LLP",
  "Aquina Pharma",
  "Aquinnova Pharmaceuticals Pvt Ltd",
  "Aqunova Pharma Pvt Ltd",
  "Aquver Pharma Pvt Ltd",
  "Ar - Ex Laboratories Pvt Ltd",
  "Aradhia Pharmaceuticals India Pvt Ltd",
  "Arakind Pharma",
  "Aralson Pharma",
  "Aramark Pharma",
  "Aranox Pharma Pvt Ltd",
  "Aravali Pharmacals",
  "Aravika Healthcare Pvt Ltd",
  "Arbit Pharmaceutical",
  "Arboreal Bioinnovations Pvt Ltd",
  "Arbour Biotec",
  "Arbour Pharmaceuticals Pvt Ltd",
  "Arbro Pharmaceuticals Pvt Ltd",
  "Arbutus Healthcare Pvt Ltd",
  "Arcatron Mobility Pvt Ltd",
  "Arcetic Life Sciences Pvt Ltd",
  "Arche Medichem Pvt Ltd",
  "Arches Pharmaceuticals",
  "Archicare Ltd",
  "Archies Life Science",
  "Archis Healthcare",
  "Archmed Biotech Pvt Ltd",
  "Arco Lifesciences",
  "Arco Pharmaceuticals",
  "Arcure Life Care",
  "Ardas Remedies",
  "Ardent Life Sciences Pvt Ltd",
  "Ardez Pharma",
  "Ardor Drugs Pvt Ltd",
  "Arechar Healthcare",
  "Areena Healthcare",
  "Arehk Healthcare",
  "Areian Biological Pvt Ltd",
  "Arelang Naturals Pvt Ltd",
  "Arene Lifesciences Ltd",
  "Aretaeus Pharmaceuticals",
  "Ar-Ex Laboratories Pvt Ltd",
  "Arex Laboratories Pvt Ltd",
  "Argen Lifesciences",
  "Argil Life Science",
  "Argos Healthcare (P) Ltd",
  "Args India Pharma Pvt Ltd",
  "Argus CMPO",
  "Aria Lifesciences Pvt Ltd",
  "Ariaan Pharma",
  "Ariane Biotech",
  "Aricure Pharmaceuticals",
  "Aries Drugs Pvt Ltd",
  "Arihant Enterprises",
  "Arihant Medicare Pvt Ltd",
  "Arihant Pharma",
  "Arihant Remedies Pvt Ltd",
  "Arika Healthcare",
  "Arinna Lifescience Pvt Ltd",
  "Ario Pharma Pvt Ltd",
  "Arion Healthcare",
  "Arise Pharmaceuticals",
  "Arisha Pharmaceuticals OPC Pvt Ltd",
  "Arishtha pharmaceuticals",
  "Ariskem Pharmaceuticals Pvt Ltd",
  "Arist Health Care",
  "Arist Healthcare",
  "Arista Life Sciences LLP",
  "Aristek Pharma Pvt Ltd",
  "Aristo Pharma Ltd(Genetica)",
  "Aristo Pharma P L (Og)",
  "Aristo Pharmaceutical Pvt Ltd",
  "Aristo Pharmaceuticals Ltd",
  "Aristo Pharmaceuticals LtdLtd",
  "Aristo Pharmaceuticals P (Log)",
  "Aristo Pharmaceuticals P L",
  "Aristo Pharmaceuticals P L (Mf)",
  "Aristo Pharmaceuticals P L (OG)",
  "Aristo Pharmaceuticals P L (Tf)",
  "Aristo Pharmaceuticals P L(Mf 2)",
  "Aristo Pharmaceuticals P L(Mf)",
  "Aristo Pharmaceuticals P L(Og)",
  "Aristo Pharmaceuticals P L(Tf)",
  "Aristo Pharmaceuticals P Ltd",
  "Aristo Pharmaceuticals P Ltd(Mf)",
  "Aristo Pharmaceuticals Pvt Ltd",
  "Aristo Pharmaceuticals Pvt Ltd(OG)",
  "Aristo Pharmaceuticals Pvt Ltd(Tf)",
  "Aristopharma Ltd",
  "Ariwell Lifesciences",
  "Arjan Biotech",
  "Ark Life Sciences Pvt Ltd",
  "Ark Shine Pharma",
  "Arka Vital Science Pvt Ltd",
  "Arkamed Pharmaa",
  "Arkas Pharma Pvt Ltd",
  "Arkle Healthcare Pvt Ltd",
  "Arkray Inc",
  "Arkson Pharmaceuticals",
  "Arlak Biotech Pvt Ltd",
  "Arlyn Lifesciences Pvt Ltd",
  "Armaf Luxury India Pvt Ltd",
  "Armastus Healthcare Pvt Ltd",
  "Armour Remedies India Ltd",
  "Arnee Life Science",
  "Arnigeno Life Sciences Pvt Ltd",
  "Arnon Healthcare LLP",
  "Arnvis Pharma Pvt Ltd",
  "Arobic Remedies Pvt Ltd",
  "Aroma Healthcare",
  "Aroma Remedies",
  "Aromed Pharmaceuticals",
  "Aronation Lifecare Pvt Ltd",
  "Aronex Life Sciences Pvt Ltd",
  "Aronex Lifesciences Pvt Ltd",
  "Arora Pharmaceuticals Pvt Ltd",
  "Arosa Biotech Pvt Ltd",
  "Arovea Lifesciences Pvt Ltd",
  "Arowana Healthcare",
  "ARP Pharma",
  "Arpsy Life Science Pvt Ltd",
  "Arretta Pharmaceuticals Pvt Ltd",
  "Arrient Healthcare Pvt Ltd",
  "Arrier Biotech",
  "Arrow Pharma",
  "Arrowin Pharmaceuticals",
  "Arsa Life Care Pvt Ltd",
  "Arsn Pharmaceuticals Pvt Ltd",
  "Arsun Health Care Pvt Ltd",
  "Artel Laboratories",
  "Artemis Healthcare",
  "Artemisia Healthcare",
  "Artichem Laboratories",
  "Articon Labs Pvt Ltd",
  "Artim's Pharma Pvt Ltd",
  "Artura Pharmaceuticals Pvt Ltd",
  "Artus Pharma Pvt Ltd",
  "Arun Enterprises",
  "Arunabh Pharma",
  "Arvantis Pharmaceuticals",
  "Arvincare Pharma",
  "Arvind Laboratories Pvt Ltd",
  "Arwana Pharmaceuticals",
  "Arya Ayurvedic Pharmaceutical",
  "Arya Lifesciences Pvt Ltd",
  "Arya Medisolution Pvt Ltd",
  "Arya Vaidya Sala Kottakkal",
  "Aryan Biological Corporation",
  "Aryan Biotech Pvt Ltd",
  "Aryan Formulations",
  "Aryan Pharmaceuticals",
  "Aryanveda Cosmeceuticals",
  "Aryasai Global Products LLP",
  "AS Lifesciences",
  "AS Pharmaceutical (India) Pvt Ltd",
  "Asa Healthcare Enterprises",
  "Asahi Biosan Health care",
  "ASAP ",
  "Asarva Lifescience Pvt Ltd",
  "Asbestos Drugs & Chemicals Pvt Ltd",
  "Ascensia Diabetes Care India Pvt Ltd",
  "Ascent Corporations",
  "Ascent Meditech Ltd",
  "Ascent Pharma",
  "Ascent Therapeuticals",
  "Ascentra Healthcare Pvt Ltd",
  "Asclepius Pharmaceuticals Pvt Ltd",
  "Asear Healthcare Pvt Ltd",
  "Aseric Pharma",
  "Asgard Labs Pvt Ltd",
  "Asgen Pharmaceuticals Pvt Ltd",
  "Ash Healthcare",
  "Ashdee Health Care (OPC) Pvt Ltd",
  "Ashes Life Sciences",
  "Ashley Pharmatech Pvt Ltd",
  "Ashlife Healthcare",
  "Ashok Enterprises",
  "Ashok Pharmaceuticals",
  "Ashtang Health Care Pvt Ltd",
  "Ashtanga Ayurvedics",
  "Ashtavaidyan Thaikkattu Mooss Vaidyaratnam Pvt Ltd",
  "Ashvik Pharmaceuticals",
  "Ashwa Ayur Herb",
  "Ashwa Healthcare",
  "Asiakem Pharmaceuticals Pvt Ltd",
  "Asian Handloom Products",
  "Asian Life Sciences",
  "Asian Pharma",
  "Asian Pharmaceuticals Pvt Ltd",
  "Asiatic Pharmaceuticals",
  "Asiltus Biopharma",
  "Asklepios Remedies Pvt Ltd",
  "Aslitus Biopharma",
  "Asmed Healthcare",
  "Asoj Soft Caps Pvt Ltd",
  "Asolear Pharma Pvt Ltd",
  "Aspen Pharma",
  "Aspen Pharmaceuticals",
  "Aspen Remedies",
  "Asperia Lifesciences",
  "Asphodel Healthcare",
  "Aspida Pharma Pvt Ltd",
  "Aspirar Sphere Pvt Ltd",
  "Aspire Healthcare",
  "Aspire Lifesciences",
  "Aspire Pharma",
  "Aspire Remedies",
  "Aspiris Healthcare",
  "Aspiriss Healthcare",
  "Aspiro Natural",
  "Aspo Healthcare",
  "Asprant Life Science Pvt Ltd",
  "Asrawell Lifecare",
  "Assail Biotech",
  "Assam Chemical & Pharmaceutical Pvt Ltd",
  "Assembly Fasteners, Inc ",
  "Assentus Biogenics Pvt Ltd",
  "Assiduous Life Sciences Pvt Ltd",
  "Assign Drug Industries Pvt Ltd",
  "Associated Biopharma",
  "Associated Biotech",
  "Assure Lifescience Pvt Ltd",
  "Assure Pharma",
  "Asta Visioncare Pvt Ltd",
  "Astaberry Biosciences India Pvt Ltd",
  "Astacon Pharmaceutical Pvt Ltd",
  "Astakind Biotech Pvt Ltd",
  "Astam Health Care Pvt Ltd",
  "Astaris lifesciences Pvt Ltd",
  "Astech Pharma Pvt Ltd",
  "Astellas Ireland Co Ltd",
  "Astellas Pharma Inc",
  "Astemax Biotech Pvt Ltd",
  "Aster Formulations Pvt Ltd",
  "Aster Life Science",
  "Aster Medipharm Pvt Ltd",
  "Aster Pharma",
  "Aster Zenith Bioscience LLP",
  "Asterisk Laboratories India Pvt Ltd",
  "Asterism Pharmaceuticals Pvt Ltd",
  "Asteroid Pharma",
  "Asterveda Healthcare Pvt Ltd",
  "Astley Life Sciecnce Pvt Ltd",
  "Astley Life Sciences Pvt Ltd",
  "Astonea Labs Pvt Ltd",
  "Astopic Lifescience",
  "Astorion Pharmaceuticals Pvt Ltd",
  "Astra Eureka Pharmaceuticals",
  "Astra Healthcare Pvt Ltd",
  "Astra IDL MAX",
  "Astra Labs",
  "Astra Unique Prohealth Pvt Ltd",
  "Astra Zeneca",
  "Astra Zeneca Pharma India Ltd",
  "Astra Zeneca Pharma Ltd",
  "Astraazin Pharmaceuticals Pvt Ltd",
  "Astracure Lifesciences Pvt Ltd",
  "Astraea Life Sciences Pvt Ltd",
  "Astragenesis Healthcare Pvt Ltd",
  "Astranova Biotech",
  "Astrantia Remedies Pvt Ltd",
  "Astrapia Remedies Pvt Ltd",
  "Astrazeneca Pharma India Ltd",
  "Astrel Genome Ltd",
  "Astrella Pharmaceutical Pvt Ltd",
  "Astreos Healthsol Pvt Ltd",
  "Astria Biotech Pvt Ltd",
  "Astrica Biomedics Pvt Ltd",
  "Astro Biogenics LLP",
  "Astrochem Laboratories",
  "Astrodin Life Science Pvt Ltd",
  "Astrogamy Pharma Pvt Ltd",
  "Astron Healthcare",
  "Astronia Life Sciences",
  "Astronix Healthcare Pvt Ltd",
  "Astrophel Pharmaceuticals",
  "Astrova Pharmaceuticals Pvt Ltd",
  "Astrum Genetica",
  "Astrum Healthcare Pvt Ltd",
  "Astrum Spice Products and Exporters",
  "ASV Laboratories India Pvt Ltd",
  "Asvins Lifecare",
  "Asvp Pharma Health Industries Pvt Ltd",
  "Aswins Pharmaceuticals",
  "Atavism Health Specialities Pvt Ltd",
  "Atemoy Remedies",
  "Atgen Care Pvt Ltd",
  "Atharv Healthcare",
  "Athena life Sciences",
  "Athena Medicare",
  "Athens Labs Ltd",
  "Athens Lifescience",
  "Athens Wellness",
  "Athiko Life Sciences",
  "Athlon Mediventure Pvt Ltd",
  "Atisa life Sciences Pvt Ltd",
  "Atishya Biotech",
  "Atiya Herbs",
  "Atlanta Biotec",
  "Atlanta Healthcare",
  "Atlantic Pharma Ltd",
  "Atlantics Pharmaceuticals",
  "Atlantis Formulations Pvt Ltd",
  "Atlantis Skin Care Inc",
  "Atlas Laboratories Pvt Ltd",
  "Atlina Life Sciences (OPC) Pvt Ltd",
  "Atlina Life Sciences OPC Pvt Ltd",
  "Aton Biotech",
  "Aton Remedies Llp",
  "Atopic Laboratories",
  "Ator Healthcare Pvt Ltd",
  "Atos Lifesciences Pvt Ltd",
  "Atra Pharmaceuticals Ltd",
  "Atral Cyrus Lifesciences",
  "Atrey Pharmaceuticals",
  "Atria Life Science Ltd",
  "Atrica Pharmaceuticals India Pvt Ltd",
  "Atrimed Pharmaceuticals P Ltd",
  "Atrs Life Pharma",
  "Atsun Life Sciences",
  "Atyad Life Sciences Pvt Ltd",
  "Auarice Pharmaceuticals Pvt Ltd",
  "Aubade Healthcare Pvt Ltd",
  "Auctus Labs Pvt Ltd",
  "Auftritt Healthcare",
  "Auftritt Healthcare Pvt Ltd",
  "Auggmin Lifesciences",
  "Augment Remedies",
  "Augmentor Lifesciences Pvt Ltd",
  "Auk Lifecare Pvt Ltd",
  "Auqual Pharmaceuticals Pvt Ltd",
  "Aura Nutraceuticals Ltd",
  "Aura Pharmaceuticals",
  "Aurafia Pharmaceuticals",
  "Auraine Botanicals Pvt Ltd",
  "Auralife Pharmaceuticals Pvt Ltd",
  "Aureate Healthcare Pvt Ltd",
  "Aurel Biolife",
  "Auriga Labs",
  "Aurinia Lifesciences Pvt Ltd",
  "Aurio Pharma Laboratories Pvt Ltd",
  "Auris Biosciences Pvt Ltd",
  "Aurista Pharma Pvt Ltd",
  "Aurobindo Pharma Ltd",
  "Aurochem Laboratories India Pvt Ltd",
  "Auroma Lifescience Pvt Ltd",
  "Auromentis Pharmaceuticals",
  "Aurora Nutraceuticals Pvt Ltd",
  "Auroswift Pharma",
  "Aurthot Surgicals (OPC) Pvt Ltd",
  "Aurum Gold Life Science",
  "Aurum Life Science Pvt Ltd",
  "Aurz Pharmaceuticals Pvt Ltd",
  "Aush Pharma LLP",
  "Aushadh Biotech Pvt Ltd",
  "Aushadhi Wellness Pvt Ltd",
  "Auskincare Formulation Pvt Ltd",
  "Ausler Snovy Pharma",
  "Ausmed Healthcare",
  "Auspharma Pvt Ltd",
  "Auspi Medicaments",
  "Aussee Oats India Ltd",
  "Aust Ind Labs",
  "Auster Pharma Lifesciences Ltd",
  "Austex Pharma",
  "Austin Health Care ",
  "Austin Pharmaceuticals Ltd",
  "Austokem Pharma",
  "Austrak Pvt Ltd",
  "Austrazen Bio Pharmaceuticals Pvt Ltd",
  "Austrex Healthcare",
  "Austro Labs",
  "Austro Labs Ltd",
  "Ausum Healthcare",
  "Authentic Biotech",
  "Autus Lifesciences Pvt Ltd",
  "AUUR Lifescience Pvt Ltd",
  "Auxesia Life Sciences Pvt Ltd",
  "Auxesis Pharmaceuticals",
  "Auxiliare Healthcare Pvt Ltd",
  "Auxter Biomedic",
  "Auzalus Lifesciences",
  "Auztus Laboratories Pvt Ltd",
  "AV Grow Healthcare",
  "Ava International Pvt Ltd",
  "Avail Healthcare",
  "Aval Pharma",
  "Avalanche Pharmaceuticals",
  "Avallac Pharmaceutical LLP",
  "Avaltus Pharmaceutical Pvt Ltd",
  "Avance Phytotherapies Pvt Ltd",
  "Avancer Labs Pvt Ltd",
  "Avant Bio Sciences LLP",
  "Avant Food & Beverage Pvt Ltd",
  "Avayama Pharmacare Pvt Ltd",
  "Avecia Healthcare",
  "Aveda Ayur",
  "Avel Life Sciences",
  "Avenida Healthcare",
  "Avensis Pharmaceuticals Pvt Ltd",
  "Aventis Pharma Ltd",
  "Aventura Healthcare Pvt Ltd",
  "Aventure Pharma",
  "Avenue Healthcare India Pvt Ltd",
  "Avenue Life Sciences Pvt Ltd",
  "Aveon Healthcare & Pharma Pvt Ltd",
  "Aver Pharmaceuticals Pvt Ltd",
  "Averah Pharma Pvt Ltd",
  "Averdynm Herbals Pvt Ltd",
  "Aveta Pharmaceuticals Pvt Ltd",
  "Avgrow Healthcare",
  "Avian Wellness",
  "Aviance Healthcare Pvt Ltd",
  "Aviator Life Science Pvt Ltd",
  "Avicenna Life Sciences",
  "Avico Healthcare",
  "Avigenix Innovations Pvt Ltd",
  "Avighna Medicare Pvt Ltd",
  "Avilius Neutracare",
  "Avillion Biogenics",
  "Avillions Laboratories Pvt Ltd",
  "Avin Healthcare Pvt Ltd",
  "Avin Pharma",
  "Avin Pharmaceuticals",
  "Avinash Health Products P Ltd",
  "Avinash Health Products Pvt Ltd",
  "Avion Healthcare Pvt Ltd",
  "Aviotic Healthcare Pvt Ltd",
  "Avirav Sciences Pvt Ltd",
  "Avis Healthcare",
  "Avis Labs Pvt Ltd",
  "Avis Lifecare Pvt Ltd",
  "Avita Laboratories Pvt Ltd",
  "Avitec Pharma",
  "AVL Green Pharmaceuticals Pvt Ltd",
  "AVN Footsteps",
  "Avni Healthcare",
  "Avni Pharmaceuticals Pvt Ltd",
  "Avocado Lifestyle Pvt Ltd",
  "Avoden Pvt Ltd",
  "Avogadro Lifesciences Pvt Ltd",
  "Avolife Pharmaceutical Pvt Ltd",
  "Avonic Life Sciences",
  "Avoniks Healthcare Pvt Ltd",
  "Avriva Skintech Pvt Ltd",
  "Avrohn Pharma I Ltd",
  "Avron Laboratories",
  "Avron Pharma Pvt Ltd",
  "Avsan Pharmaceuticals Pvt Ltd",
  "Avtix Health (India) Pvt Ltd",
  "Avunu Life Sciences",
  "Avyukt Pharmaceuticals",
  "Awakesoul Pharmaceutical Pvt Ltd",
  "Axa Life Sciences",
  "AXA Parenterals Ltd",
  "Axeltis Healthcare Pvt Ltd",
  "Axenic Healthcare",
  "Axennic Pharmaceuticals Pvt Ltd",
  "Axhilth Remedies Pvt Ltd",
  "Axico Healthcare",
  "Axico Healthcare Pvt Ltd",
  "Axilgrow Healthcare",
  "Axio Biosolutions Pvt Ltd",
  "Axiom Ayurveda",
  "Axiom Laboratories",
  "Axiom Therapeutics Pvt Ltd",
  "Axiommax Oncology Pvt Ltd",
  "Axion Laboratories Pvt Ltd",
  "Axis Formulation",
  "Axis Labs Pvt Ltd",
  "Axis Life Science Pvt Ltd",
  "Axnture Healthcare",
  "Axodin Pharmaceuticals Pvt Ltd",
  "Axon Drugs Pvt Ltd",
  "Axonav Healthcare",
  "Axonav Pharma Pvt Ltd",
  "Axonics Biotech",
  "Axonwave Pharmaceuticals Pvt Ltd",
  "Axter Pharmaceuticals Pvt Ltd",
  "Axton Pharmaceutical Pvt Ltd",
  "Axyzen Life Sciences",
  "Ayansh Pharmaceuticals",
  "Ayati Pharma Pvt Ltd",
  "Aylen Pharmaceutical Pvt Ltd",
  "Ayns Pharma",
  "Aytas Pharmaceutical Pvt Ltd",
  "Ayumed Pharma Pvt Ltd",
  "Ayurlife Remedies Pvt Ltd",
  "Ayurwin Pharmaceutical Pvt Ltd",
  "Ayusea Healthcare Pvt Ltd",
  "Ayush Pharmaceuticals",
  "Ayushmaan Pharmaceuticals",
  "Azalea Biotec Pvt Ltd",
  "Azaris Pharma",
  "Azcad Pharmaceuticals Pvt Ltd",
  "Azilliane Healthcare",
  "Azine Healthcare Pvt Ltd",
  "Azista Industries Pvt Ltd",
  "Azius Pharma Pvt Ltd",
  "Aziva Laboratories Pvt Ltd",
  "Azkka Pharmaceuticals Pvt Ltd",
  "Azmash Health Care Pvt Ltd",
  "Azmed Healthcare",
  "Azott Pharmaceuticals Pvt Ltd",
  "Azphar Remedies",
  "Aztec Lifescience Pvt Ltd",
  "Azure Pharmaceuticals",
  "Azurite Bio Science Pvt Ltd",
  "Azurite Therapeutics Pvt Ltd",
  "Azveston Healthcare Pvt Ltd",
  "B Braun Medical Pvt Ltd",
  "B D Pharmaceutical Works Pvt Ltd",
  "B G Biotech",
  "B J Healthcare",
  "B L Liefscience",
  "B L Pharma Ltd",
  "B M Medico Pvt Ltd",
  "B P P Life Sciences",
  "B Q U Pharma Pvt Ltd",
  "B R S Enterprises",
  "B V Bio Corp Pvt Ltd",
  "Babuline Pharma",
  "Baby & Mom Retail Pvt Ltd",
  "Baby Safety Inc",
  "Bacans Biotech Pvt Ltd",
  "Bacchus Pharma",
  "Backpack International Pvt Ltd",
  "Bactolac Formulations Pvt Ltd",
  "Badrivas Biotech Pvt Ltd",
  "Bafna Pharmaceutical Ltd",
  "Bageo Pharmaceuticals Pvt Ltd",
  "Bagmi Remedies Pvt Ltd",
  "Bajaj Consumer Care Ltd",
  "Bajaj Formulations",
  "Bajaj Pharmaceutical",
  "Bajo Foods Pvt Ltd",
  "Baksun Healthcare Pvt Ltd",
  "Bal Pharma Ltd",
  "Balaark Pharmaceuticals Pvt Ltd",
  "Balaji Biotech",
  "Balaji Organic Dairy Products",
  "Balanced Foods Pvt Ltd",
  "Balimed Lifecare Pvt Ltd",
  "Balint pharmaceuticals",
  "Baljiwan Medicine Pvt Ltd",
  "Balsam Pharma Pvt Ltd",
  "Balsons Pharmaceutical",
  "Baltic Pharmaceuticals",
  "BAMPS Health Care",
  "Ban Labs",
  "Banaraswale Healthcare Pvt Ltd",
  "Bandu Pharmaceuticals Pvt Ltd",
  "Banga International",
  "Bangalore Antibiotics & Biologicals Pvt Ltd",
  "Bangalore Homoeo Pharmacy",
  "Bansi Gir Gauveda",
  "Banson Pharmaceuticals Pvt Ltd",
  "Bantom Laboratories Pvt Ltd",
  "Banureet Labs Pvt Ltd",
  "Bare Wellness LLP",
  "Barley Lentil Vitamins Pvt Ltd",
  "Barlis Lifesciences Pvt Ltd",
  "Baroda Industries Ltd",
  "Baroda Pharma Pvt Ltd",
  "Baropharn Formulations",
  "Baroque Pharmaceuticals Pvt Ltd",
  "Barter & Marcin Pharma",
  "Baryton Pharma",
  "Base Pharmaceuticals Pvt Ltd",
  "Basicare Pharmaceuticals",
  "Basil Impex",
  "Basil Pharma",
  "Batch Med LLP",
  "Bausch & Lomb Eyecare Pvt Ltd",
  "Bausch & Lomb Inc",
  "Bausch and Lomb India Pvt Ltd",
  "Bavium Pharmaceuticals Pvt Ltd",
  "Baxalta Biosciences India Pvt Ltd",
  "Baxil Pharma Pvt Ltd",
  "Baxium health Science",
  "Baxium Healthcare",
  "Baxova Labs (P) Ltd",
  "Baxter India Pvt Ltd",
  "Baxter Pharmaceuticals India Pvt Ltd",
  "Baxton Pharmacia",
  "Baxy Pharmaceutical",
  "Bayard Pharmaceuticals Pvt Ltd",
  "Bayberry Naturals",
  "Bayberry Pharmaceuticals Pvt Ltd",
  "Bayer India Ltd",
  "Bayer Pharma Pvt Ltd",
  "Bayer Pharmaceutical Pvt Ltd",
  "Bayer Pharmaceuticals Pvt Ltd",
  "Bayer Zydus Pharma Pvt Ltd",
  "Bayle's Pharmaceutical Pvt Ltd",
  "Baylor Pharmaceutical Pvt Ltd",
  "Bayu Healthcare Pvt Ltd",
  "BB Remedies Pvt Ltd",
  "BCR Life Scicenses Pvt Ltd",
  "Bcure & Care Pharmaceuticals Pvt Ltd",
  "BDH Industries Ltd",
  "BDR Pharmaceuticals Internationals Private",
  "BDR Pharmaceuticals Internationals Pvt",
  "Beams Remedies",
  "BEASTEAD LIFESCIENCES",
  "Beats Pharmaceuticals",
  "Beatum Healthcare Pvt Ltd",
  "Beatum IT Solutions Pvt Ltd",
  "Beatus Healthcare Pvt Ltd",
  "Beaumont Healthcare Pvt Ltd",
  "Beckcem Drug International Pvt Ltd",
  "Beckley Healthcare",
  "Beekay Pharmaceuticals",
  "Beepharma Healthcare (Opc) Pvt Ltd",
  "Beetle Healthcare Pvt Ltd",
  "Befam Pharmaceuticals Pvt Ltd",
  "Befit Pharma Pvt Ltd",
  "Beiersdorf AG",
  "Beiersdorf India Ltd",
  "Being Care Pharmaceuticals",
  "Bekim Lifesciences Llp",
  "Believe Cosmetics Pvt Ltd",
  "Believe International Ltd",
  "Beline Pharmaceuticals Pvt Ltd",
  "Bell Pharma Pvt Ltd",
  "Bella Premier Happy HygieneCare Pvt Ltd",
  "Bella Skin Care Sciences",
  "Bellatrix Pharmaceuticals",
  "Bellavita Healthcare Pvt Ltd",
  "Bellissa Pharmaceuticals",
  "Bellissa Pharmaceuticals Pvt L",
  "Bellissa Pharmaceuticals Pvt Ltd",
  "Bellus Biotech",
  "Belly Fit Food & Beverage Pvt Ltd",
  "Belmed Healthcare Pvt Ltd",
  "Beloorbayir Biotech Ltd",
  "Belvin Life Sciences Pvt Ltd",
  "Bema Cosmetici",
  "Bemaxx Pharmaceuticals Pvt Ltd",
  "Ben Hadded Pharma Pvt Ltd",
  "Ben Pharmaceuticals",
  "Benatural Health Care Pvt Ltd",
  "Benchmarks Communication",
  "Bencure Life Sciences Pvt Ltd",
  "Bendic Healthcare Pvt Ltd",
  "Benedic Pharmaceutical Pvt Ltd",
  "Benedis Lifesciences Pvt Ltd",
  "Beneficence Life sciences",
  "Bengal Antibiotics",
  "Bengal Chemicals & Pharmaceuticals Ltd",
  "Bengal Immunity Ltd",
  "Bengal Lifesciences",
  "Benique Pharmaceuticals",
  "Benison Biomed",
  "Benito Pharmaceuticals Pvt Ltd",
  "Benmed Pharmaceuticals",
  "Bennet Mypher Pharmaceuticals LLP",
  "Bennet Pharmaceuticals Ltd",
  "Bennevolance Life Science",
  "Benscott Pharma Pvt Ltd",
  "Benthos Biosciences Pvt Ltd",
  "Bentley & Remington Pvt Ltd",
  "Benz Pharma",
  "Berbrick Healthcare Pvt Ltd",
  "Bergen (Alkem Laboratories Ltd)",
  "Bergen Healthcare",
  "Berlincleve Pharmaceuticals Llp",
  "Berry & Herbs Pharma Pvt Ltd",
  "Besins Healthcare India Pvt Ltd",
  "Best Biotech",
  "Best Products & Solutions",
  "Bestead Lifesciences Pvt Ltd",
  "Bestel Laboratories Pvt Ltd",
  "Besto Medicare",
  "BestoChem Formulations India Ltd",
  "BeSure Healthcare Pvt Ltd",
  "Beta Drugs Ltd",
  "Betamax Remedies",
  "Betlif Pharmaceutical Pvt Ltd",
  "Betlife Formulations Pvt Ltd",
  "Beulah Biomedics Ltd",
  "Bevilin Pharma Pvt Ltd",
  "Bevit Pharmaceuticals Ltd",
  "Bewell Labs Pvt Ltd",
  "Bey Bee",
  "Bhagwati Solutions",
  "Bhairavi Surgicals and Distributors",
  "Bhandaree Consumer Products Pvt Ltd",
  "Bhandari Homoeopathic Laboratories",
  "Bhandari Labs",
  "Bhano Enterprises",
  "Bharat Antibiotics Inc",
  "Bharat Biotech",
  "Bharat Biotech International Ltd",
  "Bharat Enterprises",
  "Bharat International Pet Foods",
  "Bharat Parenterals Ltd",
  "Bharat Serums & Vaccines Ltd",
  "Bharat Surgical Industries",
  "Bhardwaj International Pvt Ltd",
  "Bhargava Phytolab",
  "Bharti Life Sciences",
  "Bhatia Store",
  "Bhavishya Pharmaceuticals Pvt Ltd",
  "Bhawar Sales Corporation",
  "BHC Lab Pvt Ltd",
  "BHPI Bharat",
  "Bhumi Pharmaceuticals",
  "Bhumija Lifesciences",
  "Bhushan Pharmaceuticals Pvt Ltd",
  "Bibo Pharma",
  "Bicon Healthcare",
  "Bi-Cure Remedies",
  "Big Biotec Surgical Pvt Ltd",
  "Big Dream Pharma Pvt Ltd",
  "Big Laboratories Pvt Ltd",
  "Big Muscles Nutrition",
  "BigBrother Nutra Care Pvt Ltd",
  "Bigflex life sciences Pvt Ltd",
  "BigPhi Technologies",
  "Biidhiivatt Pharma Pvt Ltd",
  "Bikram Biotech",
  "Bilasp pharma",
  "Bilberry Pharmaceutical Pvt Ltd",
  "Bilcare Ltd",
  "Bill Cure Pharma Pvt Ltd",
  "Bills Biotech Pvt Ltd",
  "Bilmeds Pharmaceuticals(Opc)Pvt Ltd",
  "Bindlysh Biotec Ltd",
  "BinduMadhav Pharma Pvt Ltd",
  "Bini Laboratories Pvt Ltd",
  "Binola Pharma LLP",
  "Binova Healthcare Pvt Ltd",
  "Bio Ace Pharmaceuticals",
  "Bio Bodyfuelz Ltd",
  "Bio Concept Formulation",
  "Bio Drugs Laboratories Pvt Ltd",
  "BIO Ethicals Pharma Ltd",
  "Bio Formulation",
  "Bio Genetica",
  "Bio Heal Remedies",
  "Bio Health Pharma Pvt Ltd",
  "Bio Home Pharmacy",
  "Bio Human Resources",
  "Bio John Healthcare Pvt Ltd",
  "Bio Laboratories",
  "Bio Leafage Wellness Pvt Ltd",
  "Bio Lifesciences",
  "Bio Marilyn Pharmaceuticals Pvt Ltd",
  "Bio Medica Laboratories Pvt Ltd",
  "Bio Medicx Readifine Health Care",
  "Bio Nova Pharmaceuticals Pvt Ltd",
  "Bio Nutrients India Pvt Ltd",
  "Bio Optimum Healthcare Pvt Ltd",
  "Bio Organics Pvt Ltd",
  "Bio Oxy Pharma",
  "Bio Pharma Laboratories",
  "Bio Phoenix Formulations",
  "Bio Sars Pharma Pvt Ltd",
  "Bio Schutz Pharmaceuticals Pvt Ltd",
  "Bio Sciences Pharmakon",
  "Bio Swizz Pharmaceuticals",
  "Bio Veda Action Research Co",
  "Bio Vitamins",
  "Bio Warriors Pharmaceutical L",
  "Bio Warriors Pharmaceutical Ltd",
  "Bio Warriors Pharmaceuticals Pvt Ltd",
  "Bioalcon Pharmaceuticals",
  "Bioaltus Pharmaceuticals P Ltd",
  "Bioaltus Pharmaceuticals Pvt Ltd",
  "Bioas Medico Pvt Ltd",
  "Bioasia Lifesciences Pvt Ltd",
  "Bioassure Healthcare Pvt Ltd",
  "Biobrick Pharma",
  "Biocare Electronics Co Ltd",
  "Biocare Remedies",
  "Biocare Research India Pvt Ltd",
  "Biocast Pharma",
  "Biocell Pharmaceuticals Pvt Ltd",
  "Biocellular Life Science Pvt Ltd",
  "Biocent Scientific India Pvt Ltd",
  "Bioceuticals Ltd",
  "Bioceutics Inc",
  "Biochem Pharmaceutical Inds",
  "Biochem Pharmaceutical Industries",
  "Biochem Pharmaceutical Industries Ltd",
  "Biochem Pharmaceuticals (Direct)",
  "Biochemix Health Care Pvt Ltd",
  "Biocin Genetics & Pharma",
  "Biocin Healthcare",
  "Biociss Healthcare",
  "Bioclix Remedies",
  "Biocon Ltd",
  "Biocordis Pharmaceuticals Pvt Ltd",
  "Biocore Pharmaceuticals",
  "Biocorp Life Sciences Pvt Ltd",
  "Biocrross Chem LLP",
  "Biocruz Pharmaceuticals Pvt Ltd",
  "Biocube Pharma",
  "Biocura Life sciences Pvt Ltd",
  "Biocute Life Care",
  "Biocyte Organics Pvt Ltd",
  "Biocyte Pharmaceuticals Pvt Ltd",
  "Biodeal Pharmaceuticals Pvt Ltd",
  "BioDerm Laboratories",
  "Bioderma Laboratories",
  "Bioderma Solutions",
  "Biodiscovery Lifesciences Pvt Ltd",
  "Biodizire Pharma Pvt Ltd",
  "Biodyne Remedies Pvt Ltd",
  "Bioelite Lifesciences Pvt Ltd",
  "Bio-Engineered Supplements and Nutrition Inc",
  "Bioethics Lifesciences Pvt Ltd",
  "Biofelixer Healthcare",
  "Biofic Pharma Pvt Ltd",
  "Biofield Pharma",
  "Biofin Lifesciences",
  "Biofina Pharmaceuticals",
  "Biofloris Pharmaceuticals Pvt Ltd",
  "Biofootprints Healthcare Pvt Ltd",
  "Bioford Remedies Pvt Ltd",
  "Biofrank Pharmaceuticals",
  "Biofusion Pharmaceuticals",
  "Biogem Healthcare",
  "Biogen Idec Biotech India Pvt Ltd",
  "Biogen Pharmaceuticals",
  "Biogenesis Corp",
  "Biogenetic Pharmaceuticals",
  "Biogenetics Health Care (India) Pvt Ltd",
  "BioGeniccs Pharmaceuticals",
  "Biogenius Labs",
  "Biogenomics Ltd",
  "Biogensis Biotechnic",
  "BioGold Pharmaceutical Pvt Ltd",
  "Bioguard Organics Ltd",
  "Biogyn Pharmaceuticals",
  "Bioheal Lifesciences Pvt Ltd",
  "Biohexa Life Sciences",
  "Biohexol Lifesciences",
  "Bio-India Pharma Pvt Ltd",
  "Bioiris Pharmaceuticals Pvt Ltd",
  "Bioiwis Pharma",
  "Biokind Healthcare Pvt Ltd",
  "Biokindle Lifesciences Pvt Ltd",
  "Biolex Pharmaceutical Pvt Ltd",
  "Biolife Healthcare",
  "Bioline Diagnostics LLP",
  "Biolog Pharma Pvt Ltd",
  "Biologic Psychotropics India Pvt Ltd",
  "Biological E Ltd",
  "Bioluke Pharmaceuticals Pvt Ltd",
  "Bioman Pharmaceuticals",
  "Biomarks Drugs India Pvt Ltd",
  "BioMatrix Healthcare Pvt Ltd",
  "Biomatrix Research Laboratories Pvt Ltd",
  "Biomax Biotechnics Pvt Ltd",
  "Biomax Laboratories",
  "Biomax Life Sciences Ltd",
  "Biomed Pharmaceuticals",
  "Bio-Med Pvt Ltd",
  "Biomedica International",
  "Biomedica Life Science",
  "Biomedicare (India) Pvt Ltd",
  "Biomedix Siomond Pharma",
  "Biometrix Pharma",
  "Biomexon Pharmaceutical Pvt Ltd",
  "Biomi Lifesciences Pvt Ltd",
  "Biomics Pharmaceuticals Pvt Ltd",
  "Biomiicron Pharmaceuticals",
  "Biomirra Biogenics Pvt Ltd",
  "Biommune Pharma Technologies LLP",
  "Biomorph Lifesciences",
  "Bion Pharma",
  "Bion Therapeutics India Pvt Ltd",
  "Bion Therapeutics Pvt Ltd",
  "Bioncare Pharma",
  "Bioneeva Herbs",
  "Bionet India Pvt Ltd",
  "Bioneutics Marketing",
  "Bionexa Organic",
  "Bionext Pharma Pvt Ltd",
  "Bionics Remedies",
  "Bionova Lifesciences",
  "Bionovics Pharmaceuticals Pvt Ltd",
  "Bionucleus Pharmaceuticals Pvt Ltd",
  "BionyX Pharma",
  "Biophar Lifesciences Pvt Ltd",
  "Biopharm Laboratories",
  "Biopharmacieaa Pvt Ltd",
  "Biophilia Research Labs",
  "Bioplasma Immunological Research Pvt Ltd",
  "Bioplasmid Healthcare",
  "Bioplus Heathcare",
  "Biopolis Life Sciences Pvt Ltd",
  "Bioptik Technology Inc",
  "Bioqem Pharma",
  "Bioquest Pharmaceuticals Pvt Ltd",
  "Bioquik Pharma",
  "Biorange Biologicals Pvt Ltd",
  "Biorex Healthcare Pvt Ltd",
  "Biorhythms Health Care",
  "Biorika Healthcare",
  "Bioris Pharmaceuticals",
  "Bios Lab",
  "Bios Labs Pvt Ltd",
  "Bios Lifesciences Pvt Ltd",
  "Biosafe Formulations",
  "Biosafe Pharmaceuticals Pvt Ltd",
  "Biosam Life Science Pvt Ltd",
  "Biosans Lifecare",
  "Biosciences Pharmakon",
  "Biosearch Organics",
  "Biosena Lifescience",
  "Bioshine Healthcare Pvt Ltd",
  "Biosiri Lifesciences",
  "Biosmith Laboratories",
  "Biosoft Lifesciences (OPC) Pvt Ltd",
  "Biosortia Healthcare Pvt Ltd",
  "Biospace Lifesciences Pvt Ltd",
  "Biospan Pharma Pvt Ltd",
  "Biospark Pharmaceuticals And Laboratories Pvt Ltd",
  "Bioss Healthcare",
  "Biostan Indian Pharmaceuticals",
  "Biostella Pharma (opc) Pvt Ltd",
  "Biostem pharma Pvt Ltd",
  "Biostrass Health Sciences Pvt Ltd",
  "Biostreak Pharmaceuticals",
  "Biosup Healthcare Pvt Ltd",
  "Bio-Swiss Pharma",
  "Biosync Pharmaceuticals Pvt Ltd",
  "Biosynergy Lifecare (P) Ltd",
  "Biosys Medisciences",
  "Biotabs Life Sciences Pvt Ltd",
  "Biotan Lab",
  "Biotan Laboratories India Ltd",
  "Biotanic Lifesciences",
  "Biotanic Pharmaceuticals",
  "Biotas Health Care Pvt Ltd",
  "Biotavia Labs Pvt Ltd",
  "Biotech Ltd",
  "Biotech Opthalmics Pvt Ltd",
  "Biotech Pharma",
  "Biotel Laboratories Pvt Ltd",
  "Biotevia Life Sciences",
  "Biotic Healthcare",
  "Biotical Pharmaceuticals Pvt Ltd",
  "Biotics Lab",
  "Biotics Lab Life Services Pvt Ltd",
  "Biotiik Healthcare",
  "Biotony Healthcare Pvt Ltd",
  "Biotrans Pharmaceuticals Pvt Ltd",
  "Biotrendz Medicaments",
  "Biotrex Nutraceuticals",
  "Biotric Healthcare Pvt Ltd",
  "Biotron Pharma",
  "Bio-Trust Pharmaceuticals",
  "Biovalence Skin Care",
  "Biovatic Lifescience",
  "Biovec Lifesciences",
  "Bioved Life Sciences",
  "Biovencer Healthcare Pvt Ltd",
  "Biovencine Criticure",
  "Biovenice Criticure",
  "Biovensia Pharmaceutical",
  "Bioversal Remedies Pvt Ltd",
  "Biovestha Healthcare",
  "Biovia Life Sciences",
  "Bioville Farms Pvt Ltd",
  "Biovion Pharma Pvt Ltd",
  "Biovision Healthcare",
  "Biovista Lifesciences",
  "Biovitamins (Bangalore Bio)",
  "Biovitamins Pvt Ltd",
  "Bioviz Technologies Pvt Ltd",
  "Biovzn Pharmaceuticals",
  "Biowell Life Sciences",
  "Bio-Well Pharmaceuticals Pvt Ltd",
  "Biowest Pharmaceuticals Pvt Ltd",
  "Biowin Healthcare",
  "Biowin Remedies",
  "Bioworld Pharma",
  "BioX Performance Nutrition",
  "Bioxis Healthcare India",
  "Bioxtreme Pharma",
  "Biozec Healthcare",
  "Biozen Health Products Pvt Ltd",
  "Biozen pharmaceuticals",
  "Biozen Remedies Pvt Ltd",
  "Bio-Zenesis Healthcare",
  "Biozenesis Healthcare",
  "Biozinik Healthcare Pvt Ltd",
  "Biozoc INC",
  "Bipha Drug Laboratories",
  "Bipi Lifesciences Pvt Ltd",
  "Bipin Drug House",
  "Birla Healthcare Ayurveda Pvt Ltd",
  "Birotis life science Pvt ltd",
  "Birz Pharmaceuticals",
  "Bisacodyl 5 Mg",
  "Bisani Pharmaceutics",
  "Bishahari Chemical Works Pvt Ltd",
  "Bisleri International Pvt Ltd",
  "Bison Biotec Pvt Ltd",
  "Biswas Good Health Co",
  "Bitco Pharmaceutical",
  "BJ Lifesciences",
  "Bjain Pharmaceuticals Pvt Ltd",
  "BJM Pharmaceuticals",
  "Bkyula Biotech",
  "Black Dot Innovation Pvt Ltd",
  "Black Pepper Marketing Pvt Ltd",
  "Blackmores India Pvt Ltd",
  "Blaine Pharmaceuticals Pvt Ltd",
  "Blair Remedies Pvt Ltd",
  "Blayarson Remedies",
  "Blaze Pharma",
  "Blaze Remedies",
  "Blesslife healthcare",
  "Blessway Care Pvt Ltd",
  "Blesswin Medicare",
  "Blinknet Health Care Pvt Ltd",
  "Blismed Pharmaceuticals Pvt Ltd",
  "Bliss Chocolates india Pvt Ltd",
  "Bliss Gvs Pharma Ltd",
  "Bliss Life Pharmaceuticals",
  "Bliss Lifesciences",
  "Blissful Food Products Pvt Ltd",
  "Blisson Medica Pvt Ltd",
  "Blisson Mediplus Pvt Ltd",
  "Blossom Pharmaceuticals Pvt Ltd",
  "Blossoms Biologicals",
  "Blubell Pharma",
  "Blucrab Pharma Pvt Ltd",
  "Blue Chem Lifesciences",
  "Blue Cross Laboratories Ltd",
  "Blue Eye Pharmaceuticals",
  "Blue Planet Laboratories Pvt Ltd",
  "Blue Star Laboratories Pvt Ltd",
  "Blue XL Pharma",
  "Blueday Living Pvt Ltd",
  "Bluemea Therapeutics",
  "Bluemed Pharma Pvt Ltd",
  "Bluemink Health Care Pvt Ltd",
  "Bluepipes Healthcare Pvt Ltd",
  "Blume Pharma",
  "Blupin Technologies Pvt Ltd",
  "BMC Medical Co Ltd",
  "BMP Life Sciences",
  "BMS India Pvt Ltd",
  "BMW Pharmaco India Pvt Ltd",
  "BNK Health Care",
  "Body Cupid Pvt Ltd",
  "Body First",
  "Bodycare Pharmaceuticals",
  "Boehringer Ingelheim",
  "Boehringer Ingelheim (I) P Ltd",
  "Boehringer Ingelheim India Pvt Ltd",
  "Boehringer Mannheim India Ltd",
  "Boeing Pharma Pvt Ltd",
  "Boffin Biotech Pvt Ltd",
  "Boffo Pharmaceutical Pvt Ltd",
  "Bogs India (P) Ltd",
  "Boiron Laboratories Pvt Ltd",
  "Bolivian Healthcare",
  "Bombay Keshar Company",
  "Bombay Tablet Mfg Co Pvt Ltd",
  "Bon Heur Pharmaceuticals",
  "BonAyu Life sciences",
  "Bondane Pharma",
  "Bonitas Life Science Pvt Ltd",
  "BonneTerre Foods Pvt Ltd",
  "Bonsai Pharma",
  "Bonum Medelae Pvt Ltd",
  "Bonus Salus Pharmaceuticals",
  "Boon & Bastion Formulations",
  "Boots Lifesciences Ltd",
  "Borges India Pvt Ltd",
  "Born Bright Products Pvt Ltd",
  "Borntowin Health Care",
  "Borrix Healthcare",
  "Bosner Lifesciences Pvt Ltd",
  "Bountiful Lifesciences Pvt Ltd",
  "Bourgeon Foods LLP",
  "Bowmen Pharmaceuticals",
  "Boxseat Ventures Pvt Ltd",
  "Boyaka Healthcare Pvt Ltd",
  "Boyeger Biotech Pvt Ltd",
  "BPD Biotech",
  "BPI Sports Inc",
  "BPL Medical Technologies Pvt Ltd",
  "BPlus NG Pharmaceuticals",
  "BPRL Pvt Ltd",
  "Bracewell Pharmaceuticals",
  "Brahamputra Research Pvt Ltd",
  "Brain and Health Pharmaceuticals",
  "Brainwave Healthcare Pvt Ltd",
  "Brance Biotech",
  "Brand Leaf",
  "Brandoz Pharmaceuticals",
  "Brands Bucket",
  "Branko Pharmaceuticals",
  "Branolia Chemical Works",
  "Brave Heart Healthcare",
  "Brawn Laboratories Ltd",
  "BRD MediLabs",
  "BreatheEasy Consultants Pvt Ltd",
  "Breathwell Medicare India Pvt Ltd",
  "Breeson's Life Sciences",
  "Breeze Healthcare Pvt Ltd",
  "Brennon Drugs Pvt Ltd",
  "Breton Healthcare",
  "Brett Life Sciences Pvt Ltd",
  "Brewa Healthcare Pvt Ltd",
  "Brewcent Pvt Ltd",
  "Bricent Healthcare Pvt Ltd",
  "Brigade Pharmaceuticales Pvt Ltd",
  "Brigade Pharmaceuticals Pvt Ltd",
  "Bright Days Lab",
  "Bright Health Care",
  "Bright Pharma",
  "Brightsandz Clean Technology",
  "Brihans Natural Products Ltd",
  "Brilliant Lifesciences Pvt Ltd",
  "Brinsley's Footwear Pvt Ltd",
  "Brinton Pharmaceuticals Ltd",
  "Brinton Pharmaceuticals Ltd(Falcons)",
  "Brinton Pharmaceuticals Ltd(Hawks)",
  "Brinton Pharmaceuticals Pvt Ltd",
  "Brio Bliss Life Science Pvt Ltd",
  "Brio Wellness",
  "Brionic Healthcare Pvt Ltd",
  "Brionova Pharmaceutical Pvt Ltd",
  "Briscon Nutrients",
  "Briscon Remedies",
  "Brisderma Dermatologique Ny",
  "Brisk Pharmaceuticals Pvt Ltd",
  "Briskinn Solutions",
  "Briskon Laboratories",
  "Bristol Myers Squibb",
  "British Biologicals",
  "British Life Sciences",
  "British Phytonutrients",
  "Brittco Pharma Care",
  "Brivan Biotech Pvt Ltd",
  "Brivon Pharmatec Pvt Ltd",
  "Briyosis Soft Caps pvt Ltd",
  "Broad Biotech",
  "Broadbill Medicines India Pvt Ltd",
  "Broadspectrum Remedies Pvt Ltd",
  "Brochem Health Care Pvt Ltd",
  "Brock Beauty",
  "Brook Parker",
  "Brooks Laboratories Ltd",
  "Brooks Pharmaceuticals",
  "Bros Enterprises",
  "Brostin Seizz Biocare",
  "Brothers Pharma Pvt Ltd",
  "Browives And Pharmer Laboratories",
  "Brown & Burk Pharmaceuticals Ltd",
  "Brukem Life Care",
  "Brunson Healthcare Pvt Ltd",
  "Brussels Laboratories Pvt Ltd",
  "Bruventis Healthcare Pvt Ltd",
  "Bruze Lab Pvt Ltd",
  "Brydo Healthcare",
  "Bryogen Pharmaceuticals Pvt Ltd",
  "Bryos Pharmaceutical Pvt Ltd",
  "Bryson Pharmaceutical Llp",
  "Brystlekone Pharma",
  "Brzee Mediscience",
  "BSA Pharma Inc",
  "Bsn Medical Pvt Ltd",
  "Bsp Pharmaceuticals",
  "Bsure Pharmaceuticals Pvt Ltd",
  "BT Aushadhalaya",
  "B-Tex Ointment Mfg Co",
  "BTM Lifesciences",
  "Bubbles Natural",
  "Buddy's Medicare Pvt Ltd",
  "Budhani Pharma Pvt Ltd",
  "Budwhitetea Pvt Ltd",
  "BUPA Pharma",
  "Buren Laboratories Ltd",
  "Burgeon Health Series Pvt Ltd",
  "Burgeon Pharmaceuticals Pvt Ltd",
  "Burnet Pharmaceuticals P Ltd",
  "Burt's Bees",
  "Busan Pharmaceuticals",
  "Bushra Biotech Pvt Ltd",
  "Bustal Pharmaceutical",
  "Butson Bioscience",
  "Butterfly Ayurveda",
  "Buy Happy Marketing LLP",
  "BVG Life Sciences Ltd",
  "Bwell International",
  "Byby Healthcare",
  "ByGrandma Foods",
  "C & B Pharma",
  "C & C Pharmaceutical",
  "C B",
  "C F L Pharma Ltd",
  "C H O",
  "C J Clothing Co",
  "C M R Life Sciences",
  "C&K Lifecare Pvt Ltd",
  "Cablin Healthcare Pvt Ltd",
  "Cachet Pharmaceuticals Pvt Ltd",
  "Cad Pharma Inc",
  "Cadbless Life Care",
  "Caddes Life Sciences",
  "Caddiline Exim Pvt Ltd",
  "Caddix Healthcare",
  "Cadell Healthcare",
  "Cadell Healthcare Pvt Ltd",
  "Cadex Laboratories",
  "Cadila Healthcare Ltd",
  "Cadila Healthcare Pvt Ltd",
  "Cadila Pharma Ltd",
  "Cadila Pharma Ltd(A)",
  "Cadila Pharma Ltd(Irm)",
  "Cadila Pharma Ltd(Le Sante)",
  "Cadila Pharmaceuticals Ltd",
  "Cadman Healthcare",
  "Cadmus Clinical Laboratories",
  "Cadomax Healthcare Pvt Ltd",
  "Cadomed Pharmaceuticals Pvt Lt",
  "Cadomed Pharmaceuticals Pvt Ltd",
  "Cadwell Pharma Pvt Ltd",
  "Cagrus Biopharma",
  "Caldern Pharmaceuticals Ltd",
  "Calen Biotech",
  "Calgill Pharma Pvt Ltd",
  "Calibar Pharmaceuticals",
  "Calibre Pharmaceutical",
  "California Baby",
  "Caligen Labs Pvt Ltd",
  "Calix Health Care",
  "CaloFoods LLP",
  "Calren Care Lifesciences Pvt Ltd",
  "Calyx Biotech",
  "Calyx Chemicals and Pharmaceuticals Ltd",
  "Calyx Medicare",
  "CAM Neuro Pharma Pvt Ltd",
  "Cambior Pharmaceuticals",
  "Cambist Biotech Pvt Ltd",
  "Camillus Healthcare",
  "Caminho Healthcare Pvt Ltd",
  "Camlin Ltd",
  "Camlin Pharma",
  "Camrut Pharma",
  "Canbro Healthcare",
  "Candela Healthcare",
  "Candle Pharmaceuticals",
  "Candor Biotech Ltd",
  "Candor Medicare Pharmaceuticals",
  "Candrin Pharma Pvt Ltd",
  "Candure Medica Pvt Ltd",
  "Canipla Care",
  "Canixa Life Sciences Private",
  "Canixa Life Sciences Pvt",
  "Canixa Lifesciences Pvt Ltd",
  "Canixa Lifesciences Pvt Ltd(Health Care",
  "Canixa Lifesciences Pvt Ltd(Lifescience",
  "Cannote Healthcare",
  "Canon Biotech",
  "Canopus Wet Wipes Pvt Ltd",
  "Canopy Healthcare Pvt Ltd",
  "Canqor Pharma Pvt Ltd",
  "Canris Pharma India Pvt Ltd",
  "Cansas Life Care",
  "Cantry Medico Pvt Ltd",
  "Canvarzys Care Pvt Ltd",
  "Canvarzys Healthcare Ltd",
  "Canvas Biotech",
  "Canvas Life Sciences",
  "Canvas Scientific",
  "Canviko Healthcare Pvt Ltd",
  "Capella Pharmaceutical Pvt Ltd",
  "Caphap Pharmaceuticals",
  "Capital Pharma",
  "Capital Service Centre",
  "Capitis Healthcare Pvt Ltd",
  "Caplet India Pvt Ltd",
  "Capod Pharmaceuticals",
  "Cappeline Pharmaceuticals Pvt Ltd",
  "Capri Pharmaceuticals",
  "Caprice Healthcare Pvt Ltd",
  "Capricorn Biotech",
  "Capro Labs Exports India Pvt Ltd",
  "Capskii Pharmaceuticals Pvt Ltd",
  "Capsoft Healthcare Pvt Ltd",
  "Capsules India",
  "Capsutab Drugs Pvt Ltd",
  "Captab Biotec",
  "Capto Therapeutics",
  "Caravan Evolved Craft Pvt Ltd",
  "Caraway Pharma Pvt Ltd",
  "Cardea Biotech",
  "Cardeon Formulations Pvt Ltd",
  "Cardia Life Care Pvt Ltd",
  "Cardiatic Care",
  "Cardicure Pharmaceutical Pvt Ltd",
  "Cardimind Pharmaceuticals",
  "Cardinex Health Care Pvt Ltd",
  "Cardio Renal Metabolic Healthcare Pvt Ltd",
  "Care Benzorganics Pvt Ltd",
  "Care Biochemicals Pvt Ltd",
  "Care Box",
  "Care Clix Pvt Ltd",
  "Care Fad Pvt Ltd",
  "Care Formulation Labs Pvt Ltd",
  "Care Formulations",
  "Care Formulations Labs Pvt Ltd",
  "Care Group Sight Solution Pvt Ltd",
  "Care Labs",
  "Care Novus Pvt Ltd",
  "Care Organics Ltd",
  "Care Pharma India Ltd",
  "Care Pharmaceuticals",
  "Care Plus Pharma",
  "Care Pride Pharma",
  "Care Us Pharmaceutical Pvt Ltd",
  "Careability Healthcare LLP",
  "Carecone Herbal Pvt Ltd",
  "Carecroft Medic Pvt Ltd",
  "Carefit Jadex Pvt Ltd",
  "Caregenex Healthcare Pvt Ltd",
  "CAREMED PHARMA",
  "Carenix Bio Pharma Pvt Ltd",
  "Careon Pharmaceuticals",
  "Caresbridge Pharmaceuticals",
  "Carevale Pharmaceuticals",
  "Carevision Pharmaceutical Pvt Ltd",
  "Care-Well Healthcare",
  "Carewell Healthcare",
  "Carexia Healthcare LLP",
  "Carexia Healthcare Pvt Ltd",
  "Carezone Healthcare",
  "Carin Life",
  "Caring Lifesciences Pvt Ltd",
  "Carion Healthcare Pvt Ltd",
  "Caris Labs LLP",
  "Carise Pharmaceuticals Pvt Ltd",
  "Carl Zeiss India (Bangalore) Pvt Ltd",
  "Carlton Dermatology",
  "Carmel Organics Pvt Ltd",
  "Carmentis Health Care",
  "Carmine County Pvt Ltd",
  "Carminol Laboratories Pvt Ltd",
  "Carnation Pharmtech",
  "Carol Remedies",
  "Carolene Therapeutics Pvt Ltd",
  "Carrel Pharmaceuticals",
  "Carron Biotech",
  "Cartel Laboratories",
  "Caryota Labs India Pvt Ltd",
  "Caryota Life Science",
  "Caryota Life Sciences India Pvt Ltd",
  "CAS Weighing India Pvt Ltd",
  "Casca Remedies Pvt Ltd",
  "Cascade India Pharmaceuticals",
  "Casid Pharma",
  "Caslimed Healthcare",
  "Casomed Pharmaceuticals",
  "Cassel Research Laboratories Pvt Ltd",
  "Cassopeia Pharmaceuticals Pvt Ltd",
  "Castor Biotech",
  "Cataliq Laboratories Pvt Ltd",
  "Catholicon Pharmaceuticals Pvt Ltd",
  "Catnip Pharma Pvt Ltd",
  "Caudex Lifecare",
  "Cause & Cure Pvt Ltd",
  "Causeway Pharma",
  "Cavalry Laboratories (P) Ltd",
  "Caventis Pharma Pvt Ltd",
  "Caventus Healthcare Pvt Ltd",
  "Caviar Dermacare",
  "Cavin Pharmaceuticals Pvt Ltd",
  "Cavinkare Pvt Ltd",
  "CB Enterprise",
  "CceMax Healthcare",
  "Ccigmaa Lifestyle Pvt Ltd",
  "CE Biotec Pvt Ltd",
  "CE-Biotec Pvt Ltd",
  "Ce-Chem Pharmaceuticals Pvt Ltd",
  "Cedna Biotech Pvt Ltd",
  "Cee Bee Laboratories",
  "Ceecure Lifescinences",
  "Ceecure Pharma Pvt Ltd",
  "Celagenex Research (india) Pvt Ltd",
  "Celagenex Research Pvt Ltd",
  "Celaxis Biotech",
  "Celcure Pharmaceuticals",
  "Celebrity Biopharma Ltd",
  "Celechem Pharmaceuticals",
  "Celecon Pharmaceutical Pvt Ltd",
  "Celera Healthcare Pvt Ltd",
  "Celera Pharma Pvt Ltd",
  "Celesten Bioparmaceuticals Pvt Ltd",
  "Cell Biotic",
  "Cell Cross Pharmaceuticals Pvt Ltd",
  "Cell Cure Pharmaceuticals Llp",
  "Cell Salve Pharmaceuticals",
  "Cellgen Biopharma",
  "Cellic Healthcare Pvt Ltd",
  "Cellrox Life Sciences",
  "Celon Laboratories Ltd",
  "Celoris Pharmaceuticals Pvt Ltd",
  "Celosia Pharmaceutical Pvt Ltd",
  "Celsius Healthcare Pvt Ltd",
  "CEM Instruments India",
  "Cenesys Care India Pvt Ltd",
  "Cenofi Healthcare Pvt Ltd",
  "Centaur Laboratories Pvt Ltd",
  "Centaur Pharma P Ltd(Athena)",
  "Centaur Pharma P Ltd(Neuro)",
  "Centaur Pharmaceuticals P Ltd",
  "Centaur Pharmaceuticals Pvt Ltd",
  "Centercon Pharmaceutical Pvt Ltd",
  "Centurion Remedies Pvt Ltd",
  "Century Drugs Pvt Ltd",
  "Century Life Science",
  "Cephalic Healthcare Pvt Ltd",
  "Cephalon Healthcare",
  "Cera Biologicals India Pvt Ltd",
  "Ceras Pharmaceutical",
  "Cerin Pharma",
  "Cerovene Healthcare Pvt Ltd",
  "Cerspin Healthcare Pvt Ltd",
  "Cestlavie pharma",
  "Ceutica and Chemie Healthcare Pvt Ltd",
  "Cezane Remedies Pvt Ltd",
  "Cfl Pharmaceuticals Ltd",
  "CFPL Healthcare",
  "CGN Lifesciences Pvt Ltd",
  "Chakraa Formulations",
  "Champs Health Care",
  "Chandra Bhagat Pharma Pvt Ltd",
  "Charak Pharma Pvt Ltd",
  "Charan Laboratories",
  "Charan Laboratories Pvt Ltd",
  "Charitra Pharmaceuticals Pvt Ltd",
  "Charmi Impex",
  "Chaturbhuj Pharma",
  "Chau Foods",
  "CHD Lifescience Pvt Ltd",
  "Chef Garden Inc",
  "Chem + Med Pharmaceuticals",
  "Chem Biotech Healthcare",
  "Chem Pharma Pvt Ltd",
  "Chemco Plastic Industries Pvt Ltd",
  "Chemcure Biotech",
  "Chemech Laboratories Ltd",
  "Chemical Resources",
  "Chemida Labs Pvt Ltd",
  "Cheminnova Life Sciences",
  "Chemo Biological",
  "Chemo Healthcare Pvt Ltd",
  "Chemo India",
  "Chemo Remedies",
  "Chemonix India Ltd",
  "Chemopack Trading Corporation",
  "Chemosyn Ltd",
  "Chemross Lifesciences",
  "Cherrymed Pharmaceuticals",
  "Cheryl Laboratories Pvt Ltd",
  "Cheryl's Cosmeceuticals Pvt Ltd",
  "Chetak Mediplast Industries",
  "Chethana Pharmaceuticals",
  "Chevron Pharma Pvt Ltd",
  "Chicco Baby Products",
  "Chicky Pharma Pvt Ltd",
  "Chimak Health Care",
  "Chinar Therapeutics Pvt &Ltd",
  "Chiron Behring Vaccines Pvt Ltd",
  "Chiron Panacea Vaccines Pvt Ltd",
  "Chiron Pharmaceuticals Pvt Ltd",
  "Chiros Pharma",
  "Chlorosoul Enterprises",
  "ChoiceMMed India",
  "Cholayil Pvt Ltd",
  "Chondrion Lifesciences Pvt Ltd",
  "Chordius pharmaceuticals Ltd",
  "Choroid Laboratories",
  "CHS Formulations",
  "Ciaga Pvt Ltd",
  "Cian Health Care Pvt Ltd",
  "Ciba Geigy Drugs Pvt Ltd",
  "Cibeles Pharmaceutical Pvt Ltd",
  "Cielo E-commerce Consultant",
  "Ciens Laboratories",
  "Cieo Remedie",
  "Cijor Biosciences Pvt Ltd",
  "Cimera Skin Care",
  "CIMS Pharmaceuticals LLP",
  "Cinerea Biotech Pvt Ltd",
  "Cipla Health Ltd",
  "Cipla Ltd",
  "Cira Pharma",
  "Circadian Pharma",
  "Cirdi India Ltd",
  "Ciron Drugs & Pharmaceuticals Pvt Ltd",
  "Cista Medicorp",
  "Citadel Fine Pharma Pvt Ltd",
  "Citadel Fine Pharmaceutical Pvt",
  "Citaderm Pharma Pvt Ltd",
  "Citizen Laboratories Pvt Ltd",
  "Citizen Life Science Pvt Ltd",
  "Citizen Remedies",
  "Citizen Remedies Pvt Ltd",
  "Cito Remedies Pvt Ltd",
  "Citric Labs",
  "Citrus Pharma",
  "Civansh Life Sciences",
  "Ckn-Bio Remedies Pvt Ltd",
  "Ckris Laboratories Pvt Ltd",
  "CL Reddy Health Care",
  "Clabert Lifesciences Pvt Ltd",
  "Clamed Healthcare Pvt Ltd",
  "Clancare Lifesciences",
  "Clanthis Lifesciences Pvt Ltd",
  "Claret Life Sciences Llp",
  "Clariant Pharmaceuticals Pvt Ltd",
  "Clarianz Pharmaceuticals Pvt Ltd",
  "Claribel Pharmaceuticals Pvt Ltd",
  "Claris Lifesciences Ltd",
  "Clarisel Healthcare Pvt Ltd",
  "Clarisse Pharmaceuticus",
  "Clarix Healthcare",
  "Claro Pharmaceuticals Pvt Ltd",
  "Classic Healthcare Labs",
  "Classique International Inc ",
  "CleanLife Products",
  "Clearprise Technologies Pvt Ltd",
  "Clementia Pharmaceuticals Pvt Ltd",
  "Clensta International Pvt Ltd",
  "Cleveland Pharmaceuticals India Pvt Ltd",
  "Clide International",
  "Clinic Dermatech",
  "Clipwin Pharmaceuticals",
  "Clitus Pharmaceuticals",
  "Clock Remedies Pharma Pvt Ltd",
  "Clouds M Pharmaz Pvt Ltd",
  "Clover Health Care Pharma",
  "Clox Pharma Pvt Ltd",
  "Clyde Biotech P Ltd",
  "Clyde Pharmaceuticals Pvt Ltd",
  "Cmg Biotech Pvt Ltd",
  "CMG Pharmaceuticals",
  "CMR Life Science Pvt Ltd",
  "CMYK Health Products Pvt Ltd",
  "Cndell Healtheare",
  "Cnidus Pharma Pvt Ltd",
  "Cnscure India Pvt Ltd",
  "CNX Healthcare Pvt Ltd",
  "Coastal Biomedics",
  "Coastal Healthcare",
  "Coatings and Coatings India Pvt Ltd",
  "Cobra Labs",
  "Cocosath Health Products",
  "Coddle Medichem",
  "Coderite Technologies Pvt Ltd",
  "Codon Pharma Pvt Ltd",
  "Cogentrix Pharma",
  "Cognitio Care",
  "Cognitive Life Sciences",
  "Cognitive Life Sciences Ltd",
  "Cognitus Life Sciences Pvt Ltd",
  "Cogniwell Pharmaceuticals Pvt Ltd",
  "Cognyzon Pharmaceuticals",
  "Cohiba Pharmaceuticals Pvt Ltd",
  "Cohrem Pharmaceutical Pvt Ltd",
  "Colard Healthcare",
  "Coles Pharmaceuticals Pvt Ltd",
  "Colgate-Palmolive Company",
  "Colgen Pharma",
  "Colinz Laboratories Ltd",
  "Collateral Medical Pvt Ltd",
  "Coloplast Corporation",
  "ColorJet India Ltd",
  "Colors Life Sciences",
  "Colosiem Health Care Pvt Ltd",
  "Columbus Biotech",
  "Comed Chemical Pvt Ltd",
  "Comed Chemicals Ltd",
  "Comed Chemicals Pvt Ltd",
  "Comex Pharma",
  "Commonwealth Pharmaceuticals",
  "Conatus Healthcare Pvt Ltd",
  "Concept Biosciences India Pvt Ltd",
  "Concept Pharmaceuticals Ltd",
  "Conceptive Farmacia Pvt Ltd",
  "Concertina Pharma Pvt Ltd",
  "Conch Healthcare Pvt Ltd",
  "Concord Biotech Ltd",
  "Concord Drugs Ltd",
  "Concord Medipharma Pvt Ltd",
  "Concordia Healthcare Pvt Ltd",
  "Concur Pharmaceuticals Pvt Ltd",
  "Condor Healthcare Pvt Ltd",
  "Coniak Lifesciences",
  "Conical Pharmaceuticals",
  "Connect Life Pharma Pvt Ltd",
  "Connedit Business Solutions Pvt Ltd",
  "Connote Healthcare",
  "Conscaff India",
  "Consent Pharmaceuticals Pvt Ltd",
  "Consern Pharma Ltd",
  "Conset Pharma Pvt Ltd",
  "Console Industries",
  "Constaeffective Pharma Pvt Ltd",
  "Constant Remedies Pvt Ltd",
  "Consul Nature Elements Global Pvt Ltd",
  "Consumer Marketing India Pvt Ltd",
  "Contacare Opthalmic Pvt Ltd",
  "Contec Medical Systems Co Ltd",
  "Converge Biotech",
  "Convina Research Laboratory",
  "Convoy Remedies",
  "Cooper Pharma Ltd",
  "Copi Medicare",
  "Coptis Healthcare Pvt Ltd",
  "Coral Biotech",
  "Coral Healthcare Pvt Ltd",
  "Coral Laboratories Ltd",
  "Coral Pharmaceuticals P Ltd",
  "Corazon Pharma Pvt Ltd",
  "Corcus Healthcare Pvt Ltd",
  "Cordial Lifesciences Pvt Ltd",
  "Cordy Herb Biotech Pvt Ltd",
  "CORE & PURE",
  "Core Gestra Pvt Ltd",
  "Core Nutrilife LLP",
  "Core Pharmaceuticals Ltd",
  "Corem Pharma Pvt Ltd",
  "Corival Lifesciences Pvt Ltd",
  "Corneal Care Life Sciences",
  "Cornel Pharmaceuticals Pvt Ltd",
  "Corolla Pharmaceutical Pvt Ltd",
  "Corona Health Care",
  "Corona Remedies Pvt Ltd",
  "Corona Remedies Pvt Ltd¬†",
  "Corporsano Mediaid",
  "Corpus Callosum",
  "Corpus Lifesciences Pvt Ltd",
  "Cortex Medipharma Pvt Ltd",
  "Cortina Laboratories Pvt Ltd",
  "Corvin Pharmaceuticals",
  "Cosderma Pharmaceuticals Pvt Ltd",
  "Cosmas Research Lab Ltd",
  "Cosme Biotech Pvt Ltd",
  "Cosme Healthcare",
  "Cosmederma Remedies",
  "Cosmic Life Sciences",
  "Cosmic Nutracos Solutions Pvt Ltd",
  "Cosmo Life Saving Drugs Pvt Ltd",
  "Cosmoderm India",
  "Cosmogen India",
  "Cosmopack Pvt Ltd",
  "Cosmotech India",
  "Cospex Pharmaceuticals Pvt Ltd",
  "Cosset Pharmaceuticals Pvt Ltd",
  "Costa Rich Pharmaceuticals",
  "Cosway Biosciences",
  "Country Farms",
  "Country Life, LLC",
  "Covaxl Pharma",
  "Coventic Pharma Pvt Ltd",
  "Coxswain Healthcare",
  "CPL Biologicals Pvt Ltd",
  "Cradel Pharmaceuticals Pvt Ltd",
  "Cradle Life Science Pvt Ltd",
  "Crassula Pharmaceuticals Pvt Ltd",
  "Cratus Life Care",
  "Craveon Lifesciences Pvt Ltd",
  "Craza Lifesciences Pvt Ltd",
  "Creando Associates Pvt Ltd",
  "Creative Remedies Pvt Ltd",
  "Credence Healthcare",
  "Credence Pharmaceuticals Ltd",
  "Creogenic Pharma",
  "Crepis Pharma",
  "Crescent Formulations Pvt Ltd",
  "Crescent Therapeutics Ltd",
  "Crescenzia Tradetech Pvt Ltd",
  "Cresco Management Pvt Ltd",
  "Cresha Lifesciences",
  "Cresol Pharmaceuticals Pvt Ltd",
  "Cressa Pharmaceuticals Pvt Ltd",
  "Crest Pharma Pvt Ltd",
  "Crestige Lifecare",
  "Crestmed Pharmaceuticals Pvt Ltd",
  "Creyon Pharmaceuticals",
  "Crimson Lab Pvt Ltd",
  "Crimson Pharmaceuticals LLP",
  "Crinova Healthcare",
  "Crinova Healthcare Pvt Ltd",
  "Criticare Laboratories Pvt Ltd",
  "Crizon Healthcare Pvt Ltd",
  "CRMS Pharmaceuticals Pvt Ltd",
  "Croford Pharma Pvt Ltd",
  "Croire Healthcare Pvt Ltd",
  "Croise Pharma",
  "Crompton Pharma Pvt Ltd",
  "Cromson Pharma",
  "Cronical Pharma Pvt Ltd",
  "Cronus Biotech Ltd",
  "Cross Berry Pharma",
  "Crossed Fingers Organics Pvt Ltd",
  "Crossford Healthcare",
  "Crownmed Biotech",
  "Cruentus Pharmaceutical Pvt Ltd",
  "Cruise Pharmacia Pvt Ltd",
  "Crusaders India Pvt Ltd",
  "Crystal Tomato Store",
  "Crystal Variety Remedies Pvt Ltd",
  "Cryzer Formulation",
  "Cryzer Pharma",
  "Csbs Lifescience",
  "Csc Healthcare & Wellness Services Ltd",
  "Csi Enterprised Pvt Ltd",
  "Csr Life Sciences Pvt Ltd",
  "Ctofort Pharmaceuticals India Pvt Ltd",
  "CU-Med Pharmaceuticals",
  "Cube Healthcare",
  "Cuber Lifesciences",
  "Cubic Lifesciences Pvt Ltd",
  "Cubit Healthcare",
  "Cubiwell Lifesciences Pvt Ltd",
  "Cucard Pharma",
  "CUD Medicare",
  "Cuddle Up Diet Products Pvt Ltd",
  "Cue Labs",
  "Culmen Healthcare",
  "Cultivator Natural Products Pvt Ltd",
  "Cupid Ltd",
  "Cura Ayurvedic & Unani Ltd",
  "Cura Foods Pvt Ltd",
  "Curacasa Pharmaceuticals Pvt Ltd",
  "Curador Healthcare Pvt Ltd",
  "Curae Health",
  "Curagen Biological Pharmaceuticals",
  "Curasia Medilabs",
  "Curasis Lifesciences Pvt Ltd",
  "Curatas Pharmaceuticals LLP",
  "Curatio Healthcare India Pvt Ltd",
  "Curatio Healthcare Pvt Ltd",
  "Curavax Pharmaceuticals Pvt Ltd",
  "Curax Pharma",
  "Curaxis Healthcare",
  "Curb N Care Pharmaceuticals Pvt Ltd",
  "Cure and Care Biotech Pvt Ltd",
  "Cure Kelvin",
  "Cure N Cure Pharmaceutical",
  "Cure Quick Pharmaceuticals",
  "Cure Tech Skincare",
  "Cure World Pharmaceutical",
  "Curefuture Healthcare Pvt Ltd",
  "Curega Healthcare Pvt Ltd",
  "Curehealth Pharmaceuticals Pvt Ltd",
  "Cureill Pharma",
  "Cureill Pharma Pvt Ltd",
  "Cureka Healthcare Pvt Ltd",
  "Curelab Healthcare",
  "Curelife Pharma Pvt Ltd",
  "Curelife Pharmaceuticals",
  "Curelogic Pharma",
  "Curemart Pharma Pvt Ltd",
  "Curemediways Pharma Llp",
  "Curentis Biotech Pvt Ltd",
  "Curenus Pharma LLP",
  "Curest Multivitamins",
  "Curetech Formulations (P) Ltd",
  "Curetic Biotechs Pvt Ltd",
  "Cureton Biotech",
  "Curetool Pharmaceuticals",
  "Curever Pharma Pvt Ltd",
  "Cureways Remedies",
  "Curewell Drugs and Pharmaceuticals Pvt Ltd",
  "Curewin Hylico Pharma Pvt Ltd",
  "Curewin Pharmaceuticals Ltd",
  "Curex Laboratories Pvt Ltd",
  "Curex Pharma Pvt Ltd",
  "Curezip Pharma Pvt Ltd",
  "Curio Lifestyle Company",
  "Curious Biotech",
  "Curis Lifecare",
  "Curiton Lifesciences Pvt Ltd",
  "Curivo Healthcare",
  "Curlyfe Biosciences Pvt Ltd",
  "Curo Prime Pharmax Pvt Ltd",
  "Curogen Healthcare",
  "Curolife Biochem Pvt Ltd",
  "Curosis Healthcare Pvt Ltd",
  "Custodian Pharma Pvt Ltd",
  "Cutem Pharma Pvt Ltd",
  "Cutera Healthcare Pvt Ltd",
  "Cutigen Incoporation",
  "Cutik Medicare Pvt Ltd",
  "Cutinova Healthcare Llp",
  "Cutis Biologicals",
  "Cutis Biotech",
  "Cutis Dermacare",
  "Cu-v-kar Genetic Medicine Pvt Ltd",
  "Cuzco Pharma Pvt Ltd",
  "CV Pharma Ltd",
  "CVR Biotech Pvt Ltd",
  "Cybele Bio sciences Pvt Ltd",
  "Cycus Healthcare Pvt Ltd",
  "Cydia Healthcare pvt ltd",
  "Cygnor Healthcare",
  "Cygnus Pharmaceuticals Pvt Ltd",
  "Cynak Healthcare",
  "Cyno Pharmaceuticals Ltd",
  "Cynosure Life Care",
  "Cynosure Organics Pvt Ltd",
  "Cynthus Biotech Pvt Ltd",
  "Cyper Drugs & Pharmaceuticals Pvt Ltd",
  "Cyptus Healthcare Pvt Ltd",
  "Cyril Pharmaceuticals",
  "Cyrus Remedies",
  "Cytech Pharmaceuticals Pvt Ltd",
  "Cytocare Formulations Pvt Ltd",
  "Cytochem Health Care India Pvt Ltd",
  "Cytochrome Life Sciences Pvt Ltd",
  "Cytogen Pharmaceuticals India Pvt Ltd",
  "Cytopro Consumer Goods Pvt Ltd",
  "Cytoz Pharmaceuticals",
  "D A V ",
  "D B T",
  "D C M Laboratories",
  "D Cross Pharmaceuticals Pvt Ltd",
  "D D N`utritions India",
  "D D Pharmaceuticals",
  "D F O ",
  "D J Laboratories Pvt Ltd",
  "D K Livkon Healthcare Pvt Ltd",
  "D M Care Biotech (P) Ltd",
  "D R Johns Lab Pharma Pvt Ltd",
  "D S Lab",
  "Daaso Pharmaceuticals Pvt Ltd",
  "Dabur India Ltd",
  "Dabur India Ltd(Pharma)",
  "Dabur Pharmaceuticals Ltd",
  "Daddyz Pharmaceuticals",
  "Daeiou Pharmaceutical Pvt Ltd",
  "Daemon Pharma Pvt Ltd",
  "Daffodil Drugs Pvt Ltd",
  "Daffohils Laboratories Pvt Ltd",
  "Daffoworth Pharmaceutical Pvt Ltd",
  "Dagon Pharmaceuticals Pvt Ltd",
  "Dahlia Pharmaceutical Pvt Ltd",
  "Daikon Pharmaceuticals Pvt Ltd",
  "Daiwa Pharmaceuticals Pvt Ltd",
  "Daiwik Lifesciences",
  "Dakgaur Healthcare",
  "Daksh Healthcare",
  "Daksh Pharma Pvt Ltd",
  "Dakshinamurti Pharma Pvt Ltd",
  "Dakshyn Foods Pvt Ltd",
  "Dal Pharma",
  "Dalcon Drugs Pvt Ltd",
  "Dales Laboratories Pvt Ltd",
  "Dales Pharmaceuticals Ltd",
  "Dallas Pharmaceuticals and Formulations Pvt Ltd",
  "Dalmia Bharat Sugar And Industries Ltd",
  "Dalmia Healthcare",
  "Dalton Laboratories Pvt Ltd",
  "Dame Health",
  "Damrin Pharmaceuticals Pvt Ltd",
  "Damsa Pharma",
  "Dan Healthcare Pvt Ltd",
  "Dan Laboratories",
  "Dana Pharmaceuticals Pvt Ltd",
  "Danavish Healthcare",
  "Daniel Lifescience",
  "Daniel Pasteur",
  "Danish Healthcare",
  "Daniyal Pharma Pvt Ltd",
  "Danlion Pharmaceuticals Pvt Ltd",
  "Dano Vaccines & Biologicals Pvt Ltd",
  "Dansan Medichem",
  "Danta Herbs Pvt Ltd",
  "Daphne Pharmaceuticals Pvt Ltd",
  "Daris Biocare",
  "Darius- Healers Bioscience Pvt Ltd",
  "Darjuv9 Enterprises Pvt Ltd",
  "Dark Forest",
  "Darmaan Pharma",
  "Darwin Formulations Pvt Ltd",
  "Darwin Healthcare",
  "Darzine Remedies Pvt Ltd",
  "Das Foodtech Pvt Ltd",
  "Dashak Pharmaceuticals",
  "Datla Laboratories",
  "Datt Mediproducts Pvt Ltd",
  "Datti Nutraceuticals Pvt Ltd",
  "DAV Pharmacy",
  "Dava Mandi Pharmacy Pvt Ltd",
  "Davaindia Generic Pharmacy",
  "Davemax Pharma",
  "Davidsons Pharmaceuticals",
  "Davis Medilabs",
  "Davis Morgan Labs",
  "Dawakhana Tibbiya College",
  "Dawn Life Sciences",
  "Dawn Pharmaceuticals Pvt Ltd",
  "Dawson Healthcare Pvt Ltd",
  "Daxcott Pharma",
  "Daxia Healthcare",
  "Daxin Pharmaceutical Pvt Ltd",
  "Daxon Healthcare Pvt Ltd",
  "Day Meddy Pharma",
  "Dayal Pharmaceuticals",
  "Daybigen Fashion and Personal Care Pvt Ltd",
  "Dayitva OPC Pvt Ltd",
  "Daylon Healthcare",
  "Dazroz Pharmaceuticals Pvt Ltd",
  "Dazzle Healthcare",
  "Dazzle Medicare",
  "Dazzle Pharma Pvt Ltd",
  "DCG Tech Ltd",
  "DCI Pharmaceuticals Pvt Ltd",
  "DCM Pharma Pvt Ltd",
  "D-Cure Pharmaceuticals Pvt Ltd",
  "DD Pharmaceuticals Pvt Ltd",
  "Dd Remedies Pvt Ltd",
  "Ddpl International Pvt Ltd",
  "De Merilion Formulation Pvt Ltd",
  "Dea-della Life Sciences Pvt Ltd",
  "Dealcliq Technology Pvt Ltd",
  "Deca Healthcare",
  "Deccan Healthcare",
  "Decent Healthcare",
  "Decipher Labs Ltd",
  "Decisive Pharma",
  "Deckmount Electronics Pvt Ltd",
  "De-Cross Labs (India) Pvt Ltd",
  "Dee Gradibus Life Care Pvt Ltd",
  "Dee India Herbals",
  "Dee Pharma Ltd",
  "Deekay Lifesciences",
  "Deemark Health Care Pvt Ltd",
  "Deep Ayurveda",
  "Deep Health Care",
  "Deep Healthcare Products",
  "Deep Pharmaceutical",
  "Deepanshi India",
  "Deepar Pharmaceuticals Pvt Ltd",
  "Deepkamal Health Services Pvt Ltd",
  "Deeroz Healthcare",
  "Deevans Life Care Pvt Ltd",
  "Deevens Pharma",
  "Defi Healthcare Pvt Ltd",
  "Definer Pharmaceuticals Pvt Ltd",
  "Dehlvi Naturals",
  "Dehlvi Remedies",
  "Deiform Pharmacia",
  "Deify Pharma",
  "Dekner Health Care",
  "Delarc Pharmaceuticals Pvt Ltd",
  "Delavie Healthcare Pvt Ltd",
  "Delbruk Lifescience",
  "Delcure Life Sciences",
  "Deleton Life Sciences Pvt Ltd",
  "Delha Pharmaceticals Pvt Ltd",
  "Delhi Nutraceuticals Pvt Ltd",
  "Delight Biopharma Pvt Ltd",
  "Delight Medica Pvt Ltd",
  "Delizia Pharmaceutical Pvt Ltd",
  "Delizo Foods",
  "Dellwich Healthcare LLP",
  "Delon Lifescience LLP",
  "Delphard Healthcare Pvt Ltd",
  "Delphi Pharma Pvt Ltd",
  "Delphide Lifecare",
  "Delroy Pharma",
  "Deltamed Remedies",
  "Deltas Pharma",
  "Deltis Pharma Pvt Ltd",
  "Delvin Formulations Pvt Ltd",
  "Delvin Pharmaceuticals Pvt Ltd",
  "Delwis Healthcare Pvt Ltd",
  "Demax Biotech Pvt Ltd",
  "Demorbus India Pvt Ltd",
  "Denchmed Pharma",
  "Denglow Pharmaceuticals Pvt Ltd",
  "Denis Chem Lab Ltd",
  "Denizen Pharmaceuticals India (P) Ltd",
  "Denk Indier Llp",
  "Denmarc Remedies",
  "Densa Pharmaceuticals Pvt Ltd",
  "Denzai Innovations & Therapeutics Pvt Ltd",
  "DEO Pharmaceuticals Pvt Ltd",
  "Deogratias Paranteral",
  "Deon Healthcare",
  "Depl Labotatories",
  "Depone Organics Pvt Ltd",
  "Depri Pharmaceuticals",
  "Depsons Healthcare",
  "Depsons Pharma",
  "Der-Joint Healthcare Pvt Ltd",
  "Derma Concepts",
  "Derma Edge Pharma Llp",
  "Derma Next Pharma",
  "Derma Prime",
  "Dermaceutics India Pvt Ltd",
  "Dermacia Healthcare",
  "Dermacure Pharmaceuticals Pvt Ltd",
  "Dermaglaze Pharma Pvt Ltd",
  "Dermajoint India",
  "Dermakare Pharmaceuticals Pvt Ltd",
  "Dermaland Pvt Ltd",
  "Dermaprime Pvt Ltd",
  "Dermarex HealthCare India Pvt Ltd",
  "Dermasense Pharmaceuticals",
  "Dermatologic Cosmetic Laboratories",
  "Dermatopics Health Care",
  "Dermawin Pharmaceuticals",
  "Dermawiz Laboratories Pvt Ltd",
  "Dermazone Pharmaceuticals Pvt Ltd",
  "Dermcure's Pharma",
  "Dermia Conticare Pvt Ltd",
  "Dermo Care Laboratories",
  "DERMOCARE LAB (GUJ) PVT LTD",
  "Dermocare Laboratories",
  "Dermonova Pharma Pvt Ltd",
  "Dermont Pharmaceuticals Pvt Ltd",
  "Derris Pharmaceutical Llp",
  "Deserve Life Sciences",
  "Deshbhakt Pharma",
  "Deshrakshak Aushdhalaya Ltd",
  "Desi Village Agro Foods",
  "Desmark Formulations Pvt Ltd",
  "Desons Therapeutics",
  "Desta Lifescience",
  "Destin Laboratories Pvt Ltd",
  "Desun Pharmaceuticals",
  "DeTox Herbals",
  "Detox Remedies",
  "Deuel Labs India",
  "Deux Care Pharma",
  "Dev Bhoomi Pharmaceuticals",
  "Dev Pharmacy Pvt Ltd",
  "DEVA Nutrition LLC",
  "Devak Formulations",
  "Devann Pharmaceuticals",
  "Devburg Lifesciences Pvt Ltd",
  "Devdutt Exports",
  "Deve Herbes",
  "Devi Formulation Pvt Ltd",
  "Devi Pharma",
  "Devilbiss Healthcare",
  "Devison Pharmaceuticals",
  "Devlin Healthcare",
  "Devoir Lifesciences Pvt Ltd",
  "Devotion Healthcare Pvt Ltd",
  "Devyam Medicare Pvt Ltd",
  "Dew Drops Lab",
  "Dew Pharma",
  "Dewallace Pharmaceuticals",
  "Dewave Life Sciences Pvt Ltd",
  "Dewcare Concept Pvt Ltd",
  "Dewlife Pharmaceuticals Pvt Ltd",
  "Dewmedic Healthcare Pvt Ltd",
  "Dexon Biotech Pvt Ltd",
  "Dexter Labratories",
  "Dexterous Medicare Pvt Ltd",
  "Deys Medical",
  "Dey'S Medical Stores Ltd",
  "Deys Medical Stores Ltd",
  "DGA Synergy LLP",
  "DH Kritical Care",
  "Dhampure Speciality Sugars Ltd",
  "Dhamus Pharma",
  "Dhanantri Products",
  "Dhanvantari Medicine & Equipments Pvt Ltd",
  "Dhanwantari Distributors Pvt Ltd",
  "Dhanwantari Pharmaceutical",
  "Dhara Formulation Pvt Ltd",
  "Dharam Lifesciences",
  "Dharam Tara Life Sciences",
  "Dharishah Ayurveda",
  "Dharmani Health & Fitness Co ",
  "Dharmanis International",
  "Dhathri Ayurveda Pvt Ltd",
  "Dhyana Pharma",
  "Dia One",
  "Diabocare Pharmaceuticals",
  "Diacardus Pharmacy Pvt Ltd",
  "Diagno Cure Pharmaceuticals",
  "Dial Pharmaceuticals Pvt Ltd",
  "Diamond Drugs Pvt Ltd",
  "Diamond Life sciences",
  "Diancecht Pharmaceuticals Pvt Ltd",
  "Diasha Lifesciences",
  "Dicore Pharmaceuticals Pvt Ltd",
  "Diet Delite Pvt Ltd",
  "Diet Fibre 365 Food Products Pvt Ltd",
  "Dietofy Solutions",
  "Different Medicament",
  "Dighvi Hi-Tech Pharma Pvt Ltd",
  "Digilent Pharmaceuticals Pvt Ltd",
  "Digital Business Ventures",
  "Digital Vision",
  "Digmedi Pvt Ltd",
  "Diicor Healthcare",
  "Diligence Pharma",
  "Dilip Surgicals",
  "Dindayal Ayurved Bhawan",
  "Dindayal Industries Ltd",
  "Diocard Pharmaceuticals Pvt Ltd",
  "Dion Pharmaceuticals",
  "Dios Lifesciences Pvt Ltd",
  "Diosma Lifesciences Pvt Ltd",
  "Dipan Herbal Pharmacy",
  "Dipitr Technologies",
  "Disan Pharma",
  "Discovery Mankind",
  "Discovery Nutrition",
  "Disecure Pharma",
  "Disinfecto Chemical Industries Pvt Ltd",
  "Distinct Lifecare",
  "Diues Biotech",
  "Diva Health Care",
  "Divees Biotech",
  "Diverse Health Services",
  "Diversey India Hygiene Pvt Ltd",
  "Divine Aroma Energy Pvt Ltd",
  "Divine Foods",
  "Divine Healthcare",
  "Divine Lifecare Pvt Ltd",
  "Divine Remedies Pvt Ltd",
  "Divine Savior Pvt Ltd",
  "Divinity Healthcare",
  "Divisa Herbal Care Pvt Ltd",
  "Divyy Research Clinic",
  "DIY Food Labs Pvt Ltd",
  "Diya Health Care Pvt Ltd",
  "Dizan Lifesciences",
  "DJ Biotech Pvt Ltd",
  "DK Group Companies",
  "DKC Agrotech Pvt Ltd",
  "DKT India Ltd",
  "D'light Lifescience Pvt Ltd",
  "DM Pharma",
  "DMK India Healthcare",
  "DN Therapeutics Pvt Ltd",
  "DNA Labs",
  "Docdoz Pharma Pvt Ltd",
  "Docheal Pharma Pvt Ltd",
  "Docrix Healthcare Pvt Ltd",
  "Doctor Beliram & Sons",
  "Doctor Delight Pharmaceutical Pvt Ltd",
  "Doctor Dose",
  "Doctor Pharma",
  "Doctor Wonder Pvt Ltd",
  "Doctors Choice Health Care India Pvt Ltd",
  "Doctors Leaf Pharmaceuticals Pvt Ltd",
  "Doctors Life Sciences India Ltd",
  "Doctorsure Pharmaceuticals Pvt Ltd",
  "Doctor’s Best Inc",
  "Doctov Pharmaceutical (OPC) Pvt Ltd",
  "Doctris Lifesciences",
  "Docture Pharmaceuticals",
  "Docventures pharmaceutical Pvt Ltd",
  "Docwin Healthcare",
  "Docxis Lifesciences Pvt Ltd",
  "Doer Life Sciences",
  "Doeshealth Systems Pvt Ltd",
  "Doff Biotech Pvt Ltd",
  "Dokcare Life Sciences",
  "Doliosis Homoeo Pharma Pvt Ltd",
  "Dollar Company (Pvt)Ltd",
  "Dollzy International",
  "Dolphar Life Care Pvt Ltd",
  "Dolphin Laboratories Ltd",
  "Dolphins Healthcare",
  "Dolvis Bio Pharma Pvt Ltd",
  "Domagk Pharma",
  "Domagk Smith Labs Pvt Ltd",
  "Domain Healthcare",
  "Domainion Lab Ltd",
  "Doms Pharmaceuticals",
  "Dong Valley Creations Pvt Ltd",
  "Donnel Healthcare Pvt Ltd",
  "Donum Healthcare Pvt Ltd",
  "Dorland Durgs And Pharmaceuticals Pvt Ltd",
  "Dorphyll Healthcare",
  "Dorris Pharmaceutical Pvt Ltd",
  "Doschemen Pharmaceuticals",
  "Dose16 Medicare",
  "Dostar Pharmaceuticals",
  "Dot & Key Wellness Pvt Ltd",
  "DOT Pharma",
  "Dote Health Care",
  "Douglas Pharmaceuticals Pvt Ltd",
  "Doux Healthcare Pvt Ltd",
  "Dowell Pharmaceutical",
  "Doxis Laboratories",
  "DR Acuvea Pharmaceutical Pvt Ltd",
  "Dr Alexander Lab Pvt Ltd",
  "Dr Alfred",
  "Dr Alson Laboratories Pvt Ltd",
  "Dr Amgenic",
  "Dr Arogya",
  "Dr Arolkar's",
  "Dr Arthritis",
  "Dr Arthritis - Joints Health",
  "DR ARVIND LIFE SCIENCE PVT LTD",
  "Dr Arvind Lifescience Pvt Ltd",
  "Dr Asclepius",
  "Dr Ashok",
  "Dr Asma",
  "Dr Axico",
  "DR Bakhariya Lifesciences",
  "Dr Basu's",
  "Dr Batra Positive Health Products Ltd",
  "Dr Batra's",
  "Dr Berg",
  "DR Best pharmaceuticals Pvt Ltd",
  "Dr Bhargava",
  "Dr Bhargav鈥檚",
  "Dr Biswas",
  "Dr Bond Healthcare Pvt Ltd",
  "Dr Boricke",
  "Dr Brinsley",
  "Dr Brown's",
  "Dr Care",
  "Dr Charcoal",
  "Dr Choice",
  "Dr Chopra",
  "Dr Chopra Pharmacls",
  "Dr Cross",
  "Dr Cure Pharmaceuticals",
  "Dr Cure Pharmaceuticals India Pvt Ltd",
  "Dr D Pharma",
  "Dr Dassan's",
  "Dr Dawakhana Pharma LLP",
  "Dr Day Pharma",
  "Dr Dee Ess Labs",
  "Dr DeFelice Health Care",
  "Dr Dengue",
  "Dr Derma Expert Skin Care & Cure",
  "Dr Diaz",
  "Dr DM Medicare & Research Pvt Ltd",
  "Dr Dongankars",
  "Dr Dudu",
  "Dr Edwin Lab",
  "Dr Edwin Medilabs Pvt Ltd",
  "Dr Ethix",
  "Dr Ethix's",
  "Dr Ethix's Product & Services Pvt Ltd",
  "Dr Expert",
  "Dr Flow",
  "Dr Foot",
  "Dr G",
  "Dr Gene",
  "Dr Goel's",
  "Dr GoodHealth",
  "Dr Gowda's Healthcare Pvt Ltd",
  "Dr Green",
  "Dr Grover Eye Hospital",
  "Dr H ",
  "Dr Jaikaran",
  "Dr Jhon's Lab",
  "DR JOHN LAB PHARMA PVT Ltd",
  "Dr Johns",
  "Dr John'S Lab Pvt Ltd",
  "Dr Johns Laboratories Pvt Ltd",
  "Dr JPG",
  "Dr JRK",
  "Dr JRK‘s Research and Pharmaceuticals Pvt Ltd",
  "Dr J's",
  "Dr Juneja's",
  "Dr Korpet",
  "Dr Kumar's",
  "Dr Kumars Pharmaceuticals",
  "Dr Kure",
  "Dr Kushwah's",
  "DR LBR Medi Care & Research Pvt Ltd",
  "Dr Lee & Morris Pharma Pvt Ltd",
  "Dr Lormans",
  "Dr Lormans Homoeopathic Associates Pvt Ltd",
  "Dr Madhu",
  "Dr Majumder",
  "Dr Mani Pharmaceuticals Pvt Ltd",
  "Dr MED",
  "Dr Mercola",
  "Dr Moni Pharma Remedies and Solutions",
  "Dr Moni Pharmaceuticals Pvt Ltd",
  "Dr Morepen",
  "Dr Morepen Laboratories Ltd",
  "Dr Morepen Ltd",
  "Dr Nagwani",
  "Dr Nagwani Medfoods Pvt Ltd",
  "Dr Nature",
  "Dr Nova",
  "Dr Nutra",
  "Dr Odin",
  "Dr Ortho",
  "Dr Oxyn",
  "Dr Ozone",
  "Dr P ",
  "Dr Palep Medical Research Foundation",
  "Dr Palep's",
  "Dr Palep’s Medical Research Foundation Pvt Ltd",
  "Dr Papa",
  "Dr Parth",
  "Dr Patel's",
  "Dr Path",
  "Dr Patkar's",
  "Dr Patkar's Healthcare india",
  "Dr Patro's Research Laboratories (OPC) Pvt Ltd",
  "Dr Pax",
  "Dr Pearl Pharmaceuticals",
  "Dr Physio",
  "Dr Piles",
  "Dr Polley's",
  "Dr Raj",
  "Dr Raj Homoeo Pharmacy",
  "Dr Rashel",
  "Dr Reckeweg",
  "Dr Reckeweg & Co",
  "Dr Reddy's",
  "Dr Reddy's Lab",
  "Dr Reddy'S Lab Ltd",
  "Dr Reddys Lab Ltd( Wockhardt)",
  "Dr Reddy'S Lab Ltd(Derma)",
  "Dr Reddys Lab Ltd(Derma)",
  "Dr Reddy'S Lab Ltd(Frnchis)",
  "Dr Reddys Lab Ltd(Futura)",
  "Dr Reddy'S Lab Ltd(Natura)",
  "Dr Reddy'S Lab Ltd(Recura )",
  "Dr Reddy's Lab Ltd(Recura)",
  "Dr Reddy'S Lab Ltd(Spl)",
  "Dr Reddys Lab Ltd(Ventura)",
  "Dr Reddys Lab Ltd(Wockhardt)",
  "Dr Reddy'S Lab Ltd(Xenura)",
  "Dr Reddy's Laboratories Ltd",
  "Dr Reddys Laboratories Ltd",
  "Dr Reddys Laboratories Ltd(Derma)",
  "Dr Reddys Laboratories Ltd(Natura)",
  "Dr Reddy'S Laboratories Ltd(Wockhardt)",
  "Dr Reddys Laboratories Ltd(Xenura)",
  "Dr Relief",
  "Dr Revmax Healthcare",
  "Dr Rhazes",
  "Dr Romas",
  "Dr S C Deb Homeo Research Laboratory Pvt Ltd",
  "Dr S C Deb's",
  "Dr Samita",
  "Dr Sanaya",
  "Dr Sarkar's",
  "Dr Satnam's",
  "Dr Sayani's Health Care Pvt Ltd",
  "Dr Schuessler's",
  "Dr Seibert",
  "Dr Shahs Panchakarma Ayurveda",
  "Dr Shashik's",
  "Dr Sheth's",
  "Dr Shugar",
  "Dr Slim",
  "Dr Smita",
  "DR Supraz Lab Pvt Ltd",
  "Dr Suris Life Sciences Pvt Ltd",
  "Dr Sutra",
  "Dr Talat's",
  "Dr Thangs",
  "Dr Thapar's",
  "Dr Tobias",
  "Dr Trust",
  "Dr Vaidya's",
  "Dr Varma's",
  "Dr Veda's",
  "Dr VedNutra",
  "Dr Vegan",
  "Dr Virginia",
  "Dr Wellmans",
  "Dr Wellmans Homoeopathic Laboratory India Pvt Ltd",
  "Dr Willmar",
  "Dr Willmar Schwabe Gmbh & Co",
  "Dr Willmar Schwabe India Pvt Ltd",
  "Dr Yes's",
  "Drakensberg Bio Lifesciences",
  "Drakt Pharmaceutical Pvt Ltd",
  "Drams Healthcare",
  "Drati Pharmaceuticals",
  "Drati Pharmaceuticals Pvt Ltd",
  "Dravya Healthcare",
  "DRB Foods Pvt Ltd",
  "Dream India Pharmaceuticals",
  "Dreamax Healthcare Pvt Ltd",
  "DrHealthLabs Pharma LLP",
  "Drigbhyam Pharmaceutical Pvt Ltd",
  "Drish Lifesciences Ltd",
  "DrMajesty Healthcare",
  "Drmashine Research Laboratory",
  "DRS Alexia Pharma Pvt Ltd",
  "DRS Pharma",
  "Drug For Life India Pvt Ltd",
  "Drugmed Pharma",
  "Drugs Laboratories",
  "Drugswell Pvt Ltd",
  "Drukst Biotech Pvt Ltd",
  "Drumas Pharmaceuticals Pvt Ltd",
  "Druto Laboratories",
  "Druv Wellness Pvt Ltd",
  "Dry Fruit Hub",
  "Dryen Pharmaceuticals Pvt Ltd",
  "DS Health Care",
  "DS Laboratories",
  "DS Pharma",
  "DSV Healthcare",
  "DualShield Pvt Ltd",
  "Duckbill Drugs Pvt Ltd",
  "Ducwin Pharmaceuticals Pvt Ltd",
  "Dufran India Pvt Ltd",
  "Duken Pharmaceuticals",
  "Dukes Lab",
  "Dulcey Healthcare Pvt Ltd",
  "Dulcis Life Sciences",
  "Dunamed Pharmaceuticals (P) Ltd",
  "Dupen Laboratories Pvt Ltd",
  "Duraent Biologicals Ltd",
  "Duram Pharmachem Pvt Ltd",
  "Duramed Lifesciences Pvt Ltd",
  "Durembic Pharma",
  "Durg Medicare",
  "Durga Ji Life Sciences Pvt Ltd",
  "Durga Pharma",
  "Durpha Lifesciences",
  "Dutch Remedies",
  "Dutech Remedies Pvt Ltd",
  "Dvm Life Sciences Pvt Ltd",
  "Dwarkesh Pharmaceuticals Pvt Ltd",
  "DWD Pharmaceuticals",
  "DWD Pharmaceuticals Ltd",
  "Dweps Pharmaceuticals Pvt Ltd",
  "Dwivsonchem Life Science",
  "Dyausmed Healthcare Pvt Ltd",
  "Dycine Pharmaceuticals Ltd",
  "Dycott Healthcare",
  "Dy-Mach Pharma",
  "Dymatize Enterprises",
  "Dynamark Life Sciences Pvt Ltd",
  "Dynamed Pharmaceuticals Pvt Ltd",
  "Dynamic Laboratories Pvt Ltd",
  "Dynamic Lifecare Pvt Ltd",
  "Dynamic Orthopaedics Pvt Ltd",
  "Dynamic Techno Medicals Pvt Ltd",
  "Dynor Pharmaceutical Pvt Ltd",
  "Dyochem Laboratories",
  "Dysnec Pharma",
  "Dyso Healthcare Pvt Ltd",
  "Dyson Pharma",
  "E G Kantawalla Pvt Ltd",
  "E G Pharma Pvt Ltd",
  "E Health Care",
  "E I D Parry (India) Ltd",
  "E Merck India Ltd",
  "e-derma Pharma India Pvt Ltd",
  "Eagle Ayurvedic Pharmacy",
  "Eagle-Eye Healthcare Pvt Ltd",
  "Ealpharm Life Sciences Pvt Ltd",
  "Eamon Drugs Pvt Ltd",
  "Earl Pharmaceuticals Pvt Ltd",
  "Earth Biotech",
  "Earth Health Care",
  "Earthe Essentials",
  "Earthill Healthcare Pvt Ltd",
  "Earthmed Pharmaceutical Pvt Ltd",
  "Earth's Soul Ayurveda Pvt Ltd",
  "Earthy Boon",
  "Earum Pharma",
  "East African",
  "East African (India) Overseas",
  "East African India Overseas",
  "East African India Remedies Pvt Ltd",
  "East India Chemical Works Pvt Ltd",
  "East India Pharma Works Ltd",
  "East India Pharmaceutical Works Ltd",
  "East India Pharmaceuticals Works Ltd",
  "East West Pharma",
  "East West Tea Company, LLC",
  "Eastcoast Healthcare",
  "Easteria Pharma",
  "Eastern Drug",
  "Eastern Healthcare",
  "Easy Care",
  "Eberhart Life Sciences",
  "Ebonics Healthcare",
  "Ebx Healthcare Llp",
  "Echelon Biotech",
  "Echo Laboratories",
  "Eckhart Healthcare",
  "Eclipse Solaire",
  "Eclipser Pharmaceuticals",
  "Eco Med Remedies Pvt Ltd",
  "Eco Pharmacure Healthcare Pvt Ltd",
  "Ecolife Products Pvt Ltd",
  "Ecomac Pharma LLP",
  "Econ Healthcare Pvt Ltd",
  "Ecotrail Personal Care Pvt Ltd",
  "Ecron Lifesciences",
  "Ecstasy Biotech Pvt Ltd",
  "Ecstasy Healthcare Pvt Ltd",
  "Ecure Pharma",
  "Edallia Pharmaceuticals Pvt Ltd",
  "Edan Instruments",
  "Eddy Pharmaceuticals",
  "Edel Werk Biopharma Pvt Ltd",
  "Edeline Remedies Pvt Ltd",
  "Edelweiss Lifecare Pvt Ltd",
  "Edelwix Pharma",
  "Eden Healthcare Pvt Ltd",
  "Eden Pharmaceuticals Ltd",
  "Edence Life Sciences Pvt Ltd",
  "Edenic Healthcare",
  "EDG Pharmaceuticals (India) Ltd",
  "Edifice Healthcare",
  "Edifice Laboratories",
  "Edifice Life Sciences Pvt Ltd",
  "Edinburgh Pharmaceuticals",
  "Edison Organics Pharmaceuticals",
  "Edmund Healthcare",
  "Ednita Healthcare",
  "Edoc Life Sciences Pvt Ltd",
  "Edolf Healthcare Pvt Ltd",
  "Edrant Pharmaceuticals",
  "Edric Pharmaceuticals",
  "Edsel Pharma",
  "Edura Pharmaceuticals Pvt Ltd",
  "Edward Remedies Pvt Ltd",
  "Edward Young Labs",
  "Edwin Healthcare & Ayurveda",
  "Edymax Lifesciences Pvt Ltd",
  "Efedra Pharmaceuticals Pvt Ltd",
  "Effect Biotech Inc",
  "Efficace Pharmaceuticals Pvt Ltd",
  "Efficus Life Sciences And Pharmaceuticals Pvt Ltd",
  "Effitious Healthcare Pvt Ltd",
  "Effmed Healthcare",
  "Effra Lifesciences",
  "Efiia Lifesciences Pvt Ltd",
  "Efrosine Healthcare Pvt Ltd",
  "EG Lifecare Services Pvt Ltd",
  "Egbert Pharma Pvt Ltd",
  "Egis Healthcare",
  "Egzeon Pharmaceuticals Pvt Ltd",
  "Eha Healthcare Pvt Ltd",
  "Eider Pharmaceuticals",
  "Eigen Pharmaceuticals Pvt Ltd",
  "Einoxy Lifesciences",
  "Eins Pharmaceuticals",
  "Einzig Pharmaceutical Pvt Ltd",
  "Eirene Pharmaceuticals",
  "Eisai Pharmaceticals Ind Ltd",
  "Eisai Pharmaceuticals India Pvt Ltd",
  "Eisen Pharmaceutical Co Pvt Ltd",
  "Ekana Health Products LLP",
  "Ekansh Pharma",
  "Ekard Life Sciences Pvt Ltd",
  "Ekasar Lifescience",
  "Ekesha Lifesciences Pvt Ltd",
  "Ekmg Pharma Pvt Ltd",
  "Ektek Pharma",
  "El Shaddai Biologicals Ltd",
  "EL V Life Sciences",
  "Elan Pharma India Pvt Ltd",
  "Elance Health care Pvt Ltd",
  "Elancer Pharmaceuticals",
  "Elanor Surgicals",
  "Elantic Biotech",
  "Elaptus India",
  "Elara Pharmaceuticals",
  "Elben Biotec Pvt Ltd",
  "Elben Life Sciences",
  "Elbrit Life Sciences",
  "Elbrit Life Sciences Pvt Ltd",
  "Elbrit Lifesciences Pvt Ltd",
  "Elchemis Pharma",
  "Elcliff Formulations Pvt Ltd",
  "Elcon Drugs & Formulations Ltd",
  "Elcure Pharmaceuticals",
  "Elder Laboratories Ltd",
  "Elder Life Science",
  "Elder Pharma Ltd(Group B)",
  "Elder Pharmaceuticals Ltd",
  "Elder Pharmaceuticals Ltd(Group A)",
  "Elder Projects Ltd",
  "El-Dorado Bio-Tech Pvt Ltd",
  "Eldritch Pharmaceuticals Pvt Ltd",
  "Eldroga Life Sciences Pvt Ltd",
  "Eldwin Pharmaceuticals",
  "Eleadora Pharma",
  "Elect-Us Life Sciences",
  "Elegant Cosmed Pvt Ltd",
  "Elegant Drugs Pvt Ltd",
  "Elegant Pharma",
  "Elegant Remedies(India) Pvt Ltd",
  "Elegare Beauty Pvt Ltd",
  "Element International",
  "Element Pharmaceuticals Pvt Ltd",
  "Elemental Tradelinks",
  "Elements Wellness",
  "Elemnt Lifestyle LLP",
  "Elera Pharma",
  "Eleveton Pharma",
  "Elfin Pharma Pvt Ltd",
  "Elgoog Health Series",
  "Eli Lilly & Co (India) Pvt Ltd",
  "Eli Lilly and Company India Pvt Ltd",
  "Elicad Pharmacueticals Pvt Ltd",
  "Elikem Pharmaceuticals Pvt Ltd",
  "Elimed Healthcare Pvt Ltd",
  "Elina Lifesciences",
  "Elinor Pharmaceuticals Pvt Ltd",
  "Elio Biocare Lifesciences Pvt Ltd",
  "Elio Pharmaceutials Pvt Ltd",
  "Elion Health Care Ltd",
  "Eliph Nutrition Pvt Ltd",
  "Elis Pharmaceuticals India Pvt Ltd",
  "Elisa Biotech Pvt Ltd",
  "Elite Enterprise",
  "Elite Labs USA",
  "Elite Pharma Pvt Ltd",
  "Elivia Life Sciences Pvt Ltd",
  "Elixia Life Sciences",
  "Elixir Formulations Pvt Ltd",
  "Elixir Healthcare Services",
  "Elixir Life Care Pvt Ltd",
  "Elixir Pharma",
  "Elixir Pharmacon",
  "Elixir Remedies Pvt Ltd",
  "Elkos Healthcare Pvt Ltd",
  "Ellanjey Lifesciences",
  "Ella's Kitchen",
  "Ellederma Pharma Pvt Ltd",
  "Elliot Biotech Pvt Ltd",
  "Elliott Labs",
  "Ellora Pharma Pvt Ltd",
  "Elma Bio Care Pvt Ltd",
  "Elmac Remedies Pvt Ltd",
  "Elmex Drugs & Healthcare Pvt Ltd",
  "Elnova Pharma",
  "Elon Pharmaceuticals Pvt Ltd",
  "Elpida Healthcare Pvt Ltd",
  "Elrinon Healthcare Pvt Ltd",
  "ELS Academy",
  "Elsker lifescience Pvt Ltd",
  "Eltis Organics",
  "Eltropix Pharmaceuticals",
  "Elured Labs Pvt Ltd",
  "Elvis Labs Pvt Ltd",
  "Elwick Lifesciences Pvt Ltd",
  "Elwiss Biotech Pvt Ltd",
  "Elysian Beautification Pvt Ltd",
  "Elysium Pharmaceuticals Ltd",
  "Emami Frank Ross Ltd",
  "Emami Frankross Ltd",
  "Emami Ltd",
  "Emar HealthCare",
  "Embark Lifescience Pvt Ltd",
  "Embiotic Laboratories Pvt Ltd",
  "Emcee Pharmaceuticals Pvt Ltd",
  "Emco Biotech",
  "Emcure Pharma Ltd",
  "Emcure Pharma Ltd(Criante)",
  "Emcure Pharma Ltd(Nucron)",
  "Emcure Pharma Ltd(Shweiz)",
  "Emcure Pharma Ltd(Spl)",
  "EMCURE PHARMA Ltd(VERTEX)",
  "Emcure Pharma Ltd(Zemcure)",
  "Emcure Pharmaceuticals Ltd",
  "Emdok Pharmaceuticals",
  "Emdrix Pharmaceuticals Pvt Ltd",
  "Emenox Healthcare",
  "Emerald Pharma Pvt Ltd",
  "Emerge Pharma Pvt Ltd",
  "Emerging Organics LLP",
  "Emerlion Pharma",
  "EMF Healthcare",
  "Emflux Motors Pvt Ltd",
  "Emgen Healthcare",
  "Emil Pharmaceuticals Industries Pvt Ltd",
  "Eminence Research & Laboratories Pvt Ltd",
  "Eminent Healthcare",
  "Emkay Sales Corporation",
  "Emmbros Overseas",
  "Emmy Pharmaceuticals Pvt Ltd",
  "Emphaatic Pharmaceuticals Pvt Ltd",
  "Emphasis Pharma Pvt Ltd",
  "Empiai Pharmaceuticals Pvt Ltd",
  "Empire Drugs",
  "Empower Life Sciences",
  "Empyreal Lifecare",
  "Emsense BioSciences Pvt Ltd",
  "Emson Medichem Pvt Ltd",
  "Emtreat Pharmaceuticals Pvt Ltd",
  "Enactis Healthcare Pvt Ltd",
  "Enamour Lifecare ",
  "Encore Healthcare Pvt Ltd",
  "Encore Pharmaceuticals Inc ",
  "Encube Ethicals Pvt Ltd",
  "Encyclo Healthcare",
  "Endcure Pharma Pvt Ltd",
  "Endear Life Science Pvt Ltd",
  "Endemic Healthcare Pvt Ltd",
  "Endive Pharma Pvt Ltd",
  "Endocard India Pvt Ltd",
  "Endocare India",
  "Endocrine Pharma Pvt Ltd",
  "Endocura Pharma Pvt Ltd",
  "Endolabs Ltd",
  "Endoven Pharmaceutical",
  "Endow Business System Pvt Ltd",
  "Endurable Pharmaceuticals Pvt Ltd",
  "Endurance Healthcare Ltd",
  "Enduring Body Care LLP",
  "Enegix Healthcare",
  "Energize Pharmaceuticals (P) Ltd",
  "Enferma Remedies Pvt Ltd",
  "Enine Remedies Pvt Ltd",
  "Enitio Health Care Pvt Ltd",
  "Enivid lifesciences",
  "Enkay Brand Distribution Pvt Ltd",
  "Enliven Health & Beauty",
  "Ennobles Pharma Pvt Ltd",
  "Enovus Healthcare Pvt Ltd",
  "Enphamed Biotech Pvt Ltd",
  "Enrich Hair & Skin Solutions Pvt Ltd",
  "Enrich Health Care",
  "Enrich Life Sciences Pvt Ltd",
  "Enrico Pharmaceuticals",
  "Entero Healthcare Solutions Pvt Ltd",
  "Entice Cosmetics",
  "Entice Pharmaceuticals",
  "Entod (G-Teck)",
  "Entod Pharmaceuticals Ltd",
  "Entod Pharmaceuticals Pvt Ltd",
  "Entro Labs Pvt Ltd",
  "Entrust Healthcare Pvt Ltd",
  "Envian Life Sciences Pvt Ltd",
  "Envicon Pharmaceuticals",
  "Enzem Pharmaceuticals Pvt Ltd",
  "Enzo Biopharma Ltd",
  "Eoan Pharma",
  "Eos Pharmaceuticals India Ltd",
  "Ephesian Lifesciences India Pvt Ltd",
  "Epicraft Pharma",
  "Epione Pharma Pvt Ltd",
  "Epitome Life Sciences",
  "Epsilon Biotech",
  "Epsylon Tech",
  "Eqqus Pharmaceuticals Pvt Ltd",
  "Equinox Overseas Pvt Ltd",
  "Equvila Healthcare Pvt Ltd",
  "Era Labs Healthcare Pvt Ltd",
  "Era Pharmaceuticals",
  "Era Pharmaceuticals Pvt Ltd",
  "Eraas International",
  "Ergon Healthcare",
  "Ergos Life Sciences",
  "Erich Lifesciences Pvt Ltd",
  "Ericsun Life Science Pvt Ltd",
  "Ericure Remedies Pvt Ltd",
  "Eridanus Healthcare",
  "Eright Healthcare",
  "Erika Remedies",
  "Erikson Pharmaceuticals Pvt Ltd",
  "Erina Eco Craft Pvt Ltd",
  "Erinome Healthcare Pvt Ltd",
  "Erinyle Pharma",
  "Erinyle Pharma Pvt Ltd",
  "Eris 2",
  "Eris Life Sciences",
  "Eris Life Sciences (Adura)",
  "Eris Life Sciences (Altiza)",
  "Eris Life Sciences (Eterna)",
  "Eris Life Sciences (Pharma)",
  "Eris Life Sciences Ltd",
  "Eris Life Sciences Pvt Ltd",
  "Eris Life Sciences(Nikkos)",
  "Eris Lifescience",
  "Eris Lifescience (Theraputics)",
  "Eris Lifescience(Therapeutics)",
  "Eris Lifesciences",
  "Eris Lifesciences (Adura)",
  "Eris Lifesciences (Eterna)",
  "Eris Lifesciences (Pharma)",
  "Eris Lifesciences (Therapeutics)",
  "Eris Lifesciences Ltd",
  "Eris Lifesciences Pharma",
  "Eris Lifesciences Pvt Ltd",
  "Eris Oaknet Healthcare Pvt Ltd",
  "Erivie Life Sciences Pvt Ltd",
  "Erma Life Sciences",
  "Ernst Pharmacia",
  "Eros Pharmaceuticals",
  "Erotas Pharmaceuticals Pvt Ltd",
  "Erringen Healthcare LLP",
  "Ersa Lifesciences",
  "Erva Healthcare Pvt Ltd",
  "Eryx Biotech",
  "Erzek Pharmaceuticals Pvt Ltd",
  "Esar Marketing",
  "Escare Formulations Pvt Ltd",
  "Escrowmed Pharmaceuticals",
  "Eshan Pharmaceuticals Pvt Ltd",
  "Eshbilords Pharmaceuticals",
  "Eskag Pharma Pvt Ltd",
  "Eskon Pharma",
  "Eskos Pharma",
  "Esma Formulations",
  "Esmatrix Life Sciences Pvt Ltd",
  "Espee Formulation Pvt Ltd",
  "Esperer Bioresearch Pvt Ltd",
  "Esperer Onco Nutrition Pvt Ltd",
  "E-Spin Nanotech Pvt Ltd",
  "Espirit Lifecare",
  "Esprit Lifescience",
  "Esquire Drug House",
  "Ess Emm Pharma Pvt Ltd",
  "Ess Pee Enterprises",
  "Essar Formulations",
  "Essatto Life Sciences India Pvt Ltd",
  "Essens Wellness Technologies Pvt Ltd",
  "Essential Life Sciences Pvt Ltd",
  "Essential Pharmaceuticals",
  "Essentially Yours Ltd",
  "Essenzaa Lifescience Ltd",
  "Essity Hygiene and Health AB",
  "Essity India Pvt Ltd",
  "Essjohn Pharma",
  "Esskay Bee Pharma",
  "Essone Global",
  "Essweil Pharma",
  "Esteem Pharmaceuticals",
  "Estela Pharma",
  "Ester Formulations",
  "Esteve Pharma Pvt Ltd",
  "Estocott Pharma",
  "Estragen Pharma",
  "Estrellas Life Sciences Pvt Ltd",
  "Estro Pharmaceuticals",
  "Esvy Life Sciences",
  "Eswar Therapeutics Pvt Ltd",
  "Eternal Veda",
  "Ethiall Remedies",
  "Ethicare Pharma",
  "Ethicare Remedies",
  "Ethics Health Care Pvt Ltd",
  "Ethics Health Care PvtLtd",
  "Ethics Lifecare",
  "Ethilexhealth Care Guj Ltd",
  "Ethinext Pharma",
  "Ethix Health Care",
  "Ethix Healthcare",
  "Ethix Industries Inc",
  "Ethnic Biotech Pvt Ltd",
  "Ethos Lifesciences",
  "Ethyrial Pharmaceuticals",
  "EU Genia Biocare International",
  "Eucare Pharmaceuticals Pvt Ltd",
  "Eucrasia Pharma India",
  "Eudoxia Xene Pharmaceutical Pvt Ltd",
  "Eugenics Medicare Pvt Ltd",
  "Eugenics Pharma Pvt Ltd",
  "Eujen Life Sciences",
  "Eumed Healthcare",
  "Eumedia Pharma",
  "Eumedica Pharmaceuticals",
  "Eunika Therapeutics Pvt Ltd",
  "Euphony Healthcare",
  "Euphoria Healthcare Pvt Ltd",
  "Euphoric Pharmaceuticals Pvt Ltd",
  "Euploid Pharmaceuticals Pvt Ltd",
  "Euporie Pharmaceuticals",
  "Eurasia Naturals",
  "Eureka Labs Ltd",
  "Eureka Pharma",
  "Eurip life sciences",
  "Euro Alliance Pvt Ltd",
  "Euro Biogenics",
  "Euro Biotech",
  "Euro Health & Biosearch Pvt Ltd",
  "Euro Organics",
  "EuroInd Lab",
  "Eurolife Healthcare Pvt Ltd",
  "Euromed Laboratories",
  "Euronova Pharmaceuticals",
  "Europa Healthcare Pvt Ltd",
  "Eurozen Systems Pvt Ltd",
  "Eurrosia Life Sciences",
  "Eusalus Life Science Pvt Ltd",
  "Eustoma Laboratories Pvt Ltd",
  "Eutheon Life Sciences Pvt Ltd",
  "EV Com",
  "Eva Biotech Pvt Ltd",
  "Eva Pharmaceuticals",
  "Evaevo Lifescience Pvt Ltd",
  "Evagrace Pharma",
  "Evalin Healthcare",
  "Evangel Labs Pvt Ltd",
  "Evangel Pharmaceuticals Pvt Ltd",
  "Evans Healthcare",
  "Evans Pharma",
  "Evantas Biotech Pvt Ltd",
  "Evantis Lifesciences Pvt Ltd",
  "Evarex Pharmaceuticals Pvt Ltd",
  "Evarite Healthcare",
  "Evasteo pharmaceuticals",
  "Evax Healthcare Pvt Ltd",
  "Evaxo Pharma",
  "Evdoxia Lifesciences Pvt Ltd",
  "Eveksia Pharma Pvt Ltd",
  "Events Pharma",
  "Eventus Biotech",
  "Evepure Pharmaceuticals",
  "Evercure Pharmaceuticals",
  "Everen Healthcare",
  "Everest Laboratories",
  "Everidis Health Sciences",
  "Evermore Ventures",
  "Evershine Lifesciences Pvt Ltd",
  "Evervital Lifesciences",
  "Everwell Pharma Pvt Ltd",
  "Everycom Electronics",
  "Everyday Deals",
  "Everyday Ethical Global Ltd",
  "Everzen Healthcare",
  "Eveson Pharma",
  "Evexia Life Sciences",
  "Evexia Nutritions Pvt Ltd",
  "Evinz Drugs Pvt Ltd",
  "Evirun Pharmaceuticals",
  "Evita Healthcare Pvt Ltd",
  "Evitalife Pharma Pvt Ltd",
  "Eviza Biotech Pvt Ltd",
  "Evlyn Laboratories",
  "Evok Lifesciences Pvt Ltd",
  "Evolan Pharmaceuticals Pvt Ltd",
  "Evolution Sports Nutrition",
  "Evolve Snacks",
  "Evonext Healthcare",
  "Exa Mendy Lab Pvt Ltd",
  "Excare Laboratories",
  "Excel Combines",
  "Excel Pharma",
  "Excel Retail",
  "Excel Waves Labs Pvt Ltd",
  "Excella Life Sciences Pvt Ltd",
  "Excellamed Laboratories Pvt Ltd",
  "Exelife Biotech Pvt Ltd",
  "Exeltis India",
  "Exemed Pharmaceuticals",
  "Exerfit Wellness",
  "Exicare Lifesciences Pvt Ltd",
  "Exide Healthcare Pharmaceuticals Pvt Ltd",
  "Exito Pharma",
  "Exizen Lifesciences LLP",
  "Exmed Health Care",
  "Exmo Biogenics Pvt Ltd",
  "Exon Pharmaceuticals",
  "Exoplanet Pharmtech Pvt Ltd",
  "Exotic Laboratories Pvt Ltd",
  "Exotica Worldwide Healtcare Pvt Ltd",
  "Expressions Arts & Crafts Pvt Ltd",
  "Exquisite Pharma",
  "Exsan Healthcare",
  "Exsiva Pharma Pvt Ltd",
  "Extant Pharmaceutical",
  "Extensive Healthcare",
  "Extimus Healthcare Pvt Ltd",
  "Eye Care Apple Pvt Ltd",
  "Eyekare Kilitch Ltd",
  "Eyekenz Healthcare Pvt Ltd",
  "Eyereach Meds Llp",
  "Eyeris Vision Care Pvt Ltd",
  "Eysys Pharmaceutical",
  "Ezylab Healthcare Pvt Ltd",
  "Ezzah Healthcare Pvt Ltd",
  "F C Remedies",
  "Fab Critical Care",
  "Fabino Life Sciences Ltd",
  "Fade Out",
  "Fair Ford Pharmaceuticals",
  "Fairdeal Formulations (India) Pvt Ltd",
  "Faith Pharmaceuticals Ltd",
  "Faj Pharmaceuticals Pvt Ltd",
  "Famcare India Pvt Ltd",
  "Family Care Rehabilitation",
  "Familycare Consumer Pvt Ltd",
  "Fammedi Healthcare Llp",
  "Fantabulous Pharma",
  "Far West Pharma",
  "Farganic Agro Life Science Pvt Ltd",
  "Farger Pvt Ltd",
  "Farlex Pharmaceuticals Pvt Ltd",
  "Farm Honey",
  "Farma Hub",
  "Farmako Biotech",
  "Fascin Pharmaceuticals",
  "Fast cure Pharma",
  "Fastace Innovations Pvt Ltd",
  "Faveo Pharmaceuticals",
  "Favnox Pharmaceuticals Pvt Ltd",
  "Fawn Incorporation",
  "FDC Ltd",
  "Fdc Ltd(Proxima)",
  "FDC Ltd(Select)",
  "Fdc Ltd(Spectra)",
  "Fealth Life Care Pvt Ltd",
  "Feather Global",
  "Fedes Medicos Pvt Ltd",
  "Fedley Healthcare Pvt Ltd",
  "Feel Cure Pharmaceuticals Pvt Ltd",
  "Felisha Cosmetics Pvt Ltd",
  "Felix Health Park",
  "Fem Care Pharma Ltd",
  "Femgrace Formulations",
  "Femura Pharma",
  "Femura Pharmaceuticals",
  "Fenclay Lifesciences",
  "Fender Formulations Pvt Ltd",
  "Fenestra Pharmaceuticals",
  "Feodra Dermatology Laboratories",
  "Feravix Lifesciences",
  "Fermentis Life Sciences Pvt Ltd",
  "Fern Biotech",
  "Fernweh Agro",
  "Feron Healthcare Pvt Ltd",
  "Ferring Pharmaceuticals",
  "Ferring Pharmaceuticals P Ltd",
  "Ferring Pharmaceuticals Pvt Ltd",
  "Fertilesure Pharma",
  "Fertin India Pvt Ltd",
  "Fervent Life Sciences Pvt Ltd",
  "Fettle Works LLP",
  "Feyn Foods (OPC) Pvt Ltd",
  "Fhm Pharma Pvt Ltd",
  "Fiale Pharmaceuticals",
  "Fibovil Pharmaceuticals Pvt Ltd",
  "Fibrica Healthcare Pvt Ltd",
  "Ficus Life Sciences Pvt Ltd",
  "Fidalgo Laboratories Pvt Ltd",
  "Fidelis Healthcare Pvt Ltd",
  "Fidelity Lifesciences Pvt Ltd",
  "Fides Pharma",
  "Fidus Healthcare",
  "Fifth Sense Naturals Pvt Ltd",
  "Figomed Health Care Pvt Ltd",
  "Figtree Foods Pvt Ltd",
  "Filant (India) Healthcare",
  "Fillips Pharma",
  "Fine Bio-Science Pvt Ltd",
  "Fine Formulations",
  "Fine Kind Drugs & Pharmaceuticals",
  "Fine Morning Pharma",
  "Finecure Pharmaceuticals Ltd",
  "Finer Pharmaceuticals Pvt Ltd",
  "Finesse Pharmaceuticals",
  "Finex Healthcare Pvt Ltd",
  "Finn Cosmeceuticals",
  "Finnmed Biotech Pvt Ltd",
  "Finola Life Science Pvt Ltd",
  "FinOrion Pharma India Pvt Ltd",
  "FirmRoots Pvt Ltd",
  "First Care India Pvt Ltd",
  "First Care Pharma Ltd",
  "First Horizon Pharmaceuticals",
  "First People Wellness Pvt Ltd",
  "Fischer Bio Science Pvt Ltd",
  "Fishman Healthcare",
  "Fitbit India Pvt Ltd",
  "Fitnes Standard",
  "Fitshit Health Solutions Pvt Ltd",
  "Fitwel Pharmaceuticals Pvt Ltd",
  "Fiwire Systems",
  "Fixderma India Pvt Ltd",
  "Fizikem Life Sciences",
  "Fiziks Life Science",
  "Flagship Biotech International",
  "Flairmed Pharma",
  "Flamingo Pharmaceuticals",
  "Flamingo Pharmaceuticals Ltd",
  "Flanca Life Sciences",
  "Flaring Formulations Pvt Ltd",
  "Flaron Healthcare Pvt Ltd",
  "Flaxelle medicare pvt Ltd",
  "Flaxwin Lifesciences Pvt Ltd",
  "Flora & Pharma",
  "Flora and Fauna Remedies",
  "Flora Biotech",
  "Floracia Healthcare Pvt Ltd",
  "Floreat Medica Pvt Ltd",
  "Florentius Healthcare Pvt Ltd",
  "Florian Lifesciences Pvt Ltd",
  "Floris Skincare",
  "Flourish Pharma",
  "Fluense Pharmaceutical",
  "Flybird Incorporation",
  "Flysure Healthcare Pvt Ltd",
  "Flyzil Biotech Pvt Ltd",
  "Focaz Medipharma",
  "Focaz Pharma",
  "Focus Combine Marketing Pvt Ltd",
  "Focus Healthcare",
  "Foi Pharmaceuticals Pvt Ltd",
  "Folarix Pharmaceutical",
  "Foncer Pharma Pvt Ltd",
  "Foods Odyssey LLP",
  "Foral Healthcare",
  "Force India Pharma",
  "Forcemedo Pharmaceuticals",
  "Foregen Healthcare Ltd",
  "Forein Life Sciences Pvt Ltd",
  "Foremost Marketing Pvt Ltd",
  "Foresta Organics",
  "Forgo Pharmaceuticals Pvt Ltd",
  "Forman Medics Pvt Ltd",
  "Formosus Pharmaceuticals",
  "Forrit Lifecare",
  "Forschung India",
  "Forsis Healthcare",
  "Forthright Pharmaceuticals Pvt Ltd",
  "Fortune Labs",
  "Fortune Life Sciences Pvt Ltd",
  "Fortunez Global",
  "Fossil Remedies",
  "Foster Healthcare Pvt Ltd",
  "Fostril India Pvt Ltd",
  "Fou Forts",
  "Fountil Life Sciences Pvt Ltd",
  "Four Seasons Pharmaceuticals Pvt Ltd",
  "Fourrts India Laboratories Pvt Ltd",
  "Fourrts India Labs Pvt Ltd",
  "Fourrts Lab P Ltd(Speciality)",
  "Fourrts Lab Pvt Lab",
  "Fourrts Lab Pvt Ltd",
  "Fourtrax Biozone",
  "Fovea Healthcare",
  "Fovera Orthodesign Pvt Ltd",
  "Fox & Wolf Health Care",
  "Fox Marketing LLP",
  "Fraink Formulations",
  "Franch Global Health Care Llp",
  "Franco India Ph P L (Diabetix)",
  "Franco India Pharma Pvt Ltd",
  "Franco Indian",
  "Franco Indian Pharmaceuticals PvT Ltd",
  "Franco-India Pharma Pvt Ltd",
  "Franco-India Pharmaceuticals Ltd",
  "Franco-India Pharmaceuticals Pvt Ltd",
  "Franco-Indian Pharmaceuticals Ltd",
  "Franco-Indian Pharmaceuticals P Ltd",
  "Franco-Indian Pharmaceuticals Pvt Ltd",
  "Frank Biotic Remedies (India) Ltd",
  "Frank Medilink",
  "Frankfurt Pharma Pvt Ltd",
  "Franklin Laboratories India Pvt Ltd",
  "Franklin Remedies",
  "Fredun Pharmaceuticals Ltd",
  "Freedom Healthcare Pvt Ltd",
  "Freesias Pharma Pvt Ltd",
  "French Care Formulations",
  "French Formulations",
  "Frenserene Health",
  "Fresenius Kabi Ind Ltd",
  "Fresenius Kabi India Pvt Ltd",
  "Fresh Biotech",
  "Fresh Focus Consumer Products Pvt Ltd",
  "Freyn Pharmaceuticals Pvt Ltd",
  "Friends Pharma",
  "Friends Speciality Medicine Pvt Ltd",
  "Frimline Pvt Ltd",
  "Frisco Hill Pharmacia",
  "Friska Nutraceuticals Pvt Ltd",
  "Frogg International",
  "Frontline Remedies",
  "Fruitaco India",
  "FSD Care Pharma Pvt Ltd",
  "Fucom Lifesciences Pvt Ltd",
  "Fugo Life Science Pvt Ltd",
  "Fuhrer Pharmaceuticals Pvt Ltd",
  "Fujin Kami Pharmaceutico",
  "Fulfil Healthcare Pvt Ltd",
  "Fulford India Ltd",
  "Fullife Healthcare Pvt Ltd",
  "Fundoze LLP",
  "Furbo Pharma",
  "Furies Healthcare Pvt Ltd",
  "Fusion Health Care (P) Ltd",
  "Fusion Health Care(P) Ltd",
  "Fusion Healthcare Pvt Ltd",
  "Futura Surgicare Pvt Ltd",
  "Future Consumer Ltd",
  "Future Dermacare Pvt Ltd",
  "Future Farms LLP",
  "Future India Chemicals Pvt Ltd",
  "Future Life Sciences",
  "Future Pharma Pvt Ltd",
  "Fysiq Pharmaceuticals",
  "Fytokem Formulations",
  "Fytoko Lifescience Pvt Ltd",
  "Fyzomax Pharmaceuticals Pvt Ltd",
  "G & G Pharmacy",
  "G B H C",
  "G C Chemie Pharmie Ltd",
  "G D Pharmaceuticals Pvt Ltd",
  "G G Nutritions",
  "G M ",
  "G M H Laboratories",
  "G Nine Pharmaceuticals",
  "G S Pharmaceuticals Pvt Ltd",
  "G Surgiwear Ltd",
  "G1 Pharma",
  "G7 Biopharmaceutical Pvt Ltd",
  "Gaarnis Pharma Pvt Ltd",
  "Gaba Care Pvt Ltd",
  "Gaba Healthcare LLP",
  "Gadin Pharmaceuticals Pvt Ltd",
  "Gaenr Biotec",
  "Gagnant Healthcare",
  "Gaiety Life",
  "Galacus Healthcare Pvt Ltd",
  "Galanthus Pharma Pvt Ltd",
  "Galaxus Pharmaceuticals",
  "Galaxy Biotech",
  "Galcare Pharmaceutical Pvt Ltd",
  "Galcare Pharmaceuticals Pvt Ltd",
  "GALDERMA CETAPHIL DIVISON",
  "Galderma India Pvt Ltd",
  "Galencia Health Care Pvt Ltd",
  "Galenic Pharmaceuticals Pvt Ltd",
  "Galenica Ltd",
  "Galenicals India Pvt Ltd",
  "Galenus Pharma Pvt Ltd",
  "Galgoties Pharmaceutical Pvt Ltd",
  "Gallberry Life Sciences Pvt Ltd",
  "Galpha Laboratories Ltd",
  "Galveston Healthcare Pvt Ltd",
  "Gama Medi Wave Pvt Ltd",
  "Gama Mediwave Pvt Ltd",
  "Gamete Pharma Pvt Ltd",
  "Ganesh Healthcare",
  "Gangotri Herbals",
  "Ganik Pharma",
  "Ganpati Herbal Care Pvt Ltd",
  "Gardenia Health Solutions",
  "Garg Surgicals",
  "Garlico Herbal Pvt Ltd",
  "Garmin India Pvt Ltd",
  "Garnet Pharmaceutical",
  "Garveish Herbaceuticals Pvt Ltd",
  "Garwyn Remedies",
  "Gary Pharmaceuticals Pvt Ltd",
  "Gastomach Pharmaceuticals Pvt Ltd",
  "Gastroenterology Life Sciences",
  "Gat Pharmaceutical Pvt Ltd",
  "GAT Sport",
  "Gates Byby Pharma",
  "Gates India",
  "Gates India Pvt Ltd",
  "Gateway Remedies",
  "Gatle Healthcare Ltd",
  "Gatlen Biotech",
  "Gaumukh Pharmaceuticals",
  "Gaurish Pharmaceuticals",
  "Gaurja Bioremedies",
  "Gautam Pharma",
  "Gavins Pharma Pvt Ltd",
  "Gavisun Healthcare",
  "Gayathri Formulations",
  "Gaylon Health Care Pvt Ltd",
  "Gayzel Pharmaceuticals Pvt Ltd",
  "GBK Healthcare",
  "GC India Dental Pvt Ltd",
  "GE Healthcare Inc",
  "Gealaster Therapeutics",
  "Gear Merchandise",
  "Geblon Pharmaceuticals",
  "Gedzrlvin Pharmaceuticals Pvt Ltd",
  "Geek Retail Pvt Ltd",
  "Geemed Healthcare",
  "Gelmek Healthcare",
  "Gelnova Laboratories (India) Pvt Ltd",
  "Geluk Pharma",
  "Gem Pharmaceuticals Pvt Ltd",
  "Gemed Lifesciences Pvt Ltd",
  "Gemini Medicare Pvt Ltd",
  "Gemith Pharma Pvt Ltd",
  "Gen-Biotec Pvt Ltd",
  "Genblue Pharma",
  "Gencare Therapeutics India Pvt Ltd",
  "Gencure Pharmaceuticals",
  "Geneaid Pharmaceuticals",
  "Genecia Healthcare",
  "Geneda Pharma",
  "Genees Pharmaceutical Pvt Ltd",
  "Geneka Biotek",
  "Genera Hindustan Inc",
  "Genera Labs",
  "General Medicine Therapeutics Pvt Ltd",
  "General Mills India Pvt Ltd",
  "General Pharmaceuticals Pvt Ltd",
  "Generic Galpharma",
  "Generica India Ltd",
  "Generix Lifesciences Pvt Ltd",
  "Generous Mediwaves Pvt Ltd",
  "Genescience Lifecare I Pvt Ltd",
  "Geneser Pharmaceuticals Pvt Ltd",
  "Genesia Pharma",
  "Genesis Biotech Inc",
  "Genesis Remedies Pvt Ltd",
  "Genetek Lifesciences Pvt Ltd",
  "Genetiac Pharma",
  "Genetic Lifesciences",
  "Genetic Pharma",
  "Genetrix Life Sciences",
  "Geneva Biocare",
  "Genex Hygiene",
  "Genexwell Biotech",
  "Genezen Healthcare",
  "Genfo Bioceuticals Pvt Ltd",
  "Genial Lifecare",
  "Genial Medicaments",
  "Geniaux Healthcare",
  "Geniemed Pvt Ltd",
  "Genip Pharmaceuticals Pvt Ltd",
  "Genista Healthcare",
  "Genitech NSAN Pharmaceutical Pvt Ltd",
  "Genix Lifescience Pvt Ltd",
  "Genix Pharma Ltd",
  "Gennext Life Sciences",
  "Gennova Biopharmaceuticals Ltd",
  "Geno Pharmaceutical Ltd",
  "Geno Pharmaceuticals Ltd",
  "Genocone Pharmaceuticals Pvt Ltd",
  "Genome Therapeutics Corporation",
  "Genomelabsbio Pvt Ltd",
  "Genostic Pharma Pvt Ltd",
  "Genova Lifecare Agency Pvt Ltd",
  "Genova Lifesciences",
  "Genozed Formulations",
  "Genpax Pharma",
  "Genspec Lifesciences",
  "Gentech Healthcare Pvt Ltd",
  "Gentronix Lifecare Pvt Ltd",
  "Genuine Biolife Science Pvt Ltd",
  "Genusia Life Sciences",
  "Genuwin Lifesciences",
  "Genwell Health Solutions",
  "Genx Pharma",
  "Genx Pharma Ltd",
  "Geo Fresh Organic",
  "Geo Pharma Pvt Ltd",
  "Geoherb Health Care",
  "Geolife Sciences",
  "Geomed Healthcare",
  "GeoNettle Pharma Pvt Ltd",
  "German Homoeo Care and Cure Pvt Ltd",
  "German Remedies",
  "German Remedies Ltd",
  "German Remedies Pharmaceuticals Pvt Ltd",
  "Germex Laboratories",
  "Germisol Unitech Chemicals Pvt Ltd",
  "Gerrysun Pharmaceuticals Pvt Ltd",
  "Getron Pharmaceuticals",
  "Gettin Lifesciences Pvt Ltd",
  "Getwell Oncology Pvt Ltd",
  "Getwell Pharma (I) Pvt Ltd",
  "Getwell Pharmaceutical Pvt Ltd",
  "GH Vision Care Life Science",
  "Ghrelin Pharmaceuticals Pvt Ltd",
  "Gifert Biosciences Pvt Ltd",
  "Gilead Sciences",
  "Gilpod Healthcare",
  "Ginni Filaments Ltd",
  "Ginpax Healthcare",
  "Ginsburg Drugs Formulation Pvt Ltd",
  "Giosun Healthcare Pvt Ltd",
  "Gipla Healthcare",
  "Giramsan Pharmaceuticals Pvt Ltd",
  "Girgla Pharmaceuticals Pvt Ltd",
  "GIRI BROTHERS",
  "Giriraj Enterprise",
  "Giriraj Healthcare",
  "Girnar Relators Pvt Ltd",
  "Girveda LLP",
  "GK Medicos",
  "GKD'S Consumer Care",
  "Glacia Life Care",
  "Glad Drugs & Pharma",
  "Gladcare Formulations Pvt Ltd",
  "Gladden Laboratories",
  "Gladier Biogenesis",
  "Gladstone Pharma India Pvt Ltd",
  "Glamcure Lifesciences Pvt Ltd",
  "Glamson Healthcare",
  "Glanbia Performance Nutrition India Pvt Ltd",
  "Gland Pharma Ltd",
  "Glantis Pharmaceutical",
  "Glanto Pharmaceuticals",
  "Glanwind Healthcare",
  "Glanz Health Care Pvt Ltd",
  "Glareo Labs Pvt Ltd",
  "Glarizonto Pharma",
  "Glasco Pharmaceuticals Pvt Ltd",
  "Glasier Wellness Inc",
  "Glasston Pharmaceutical",
  "Glatt Pharmaceuticals Pvt Ltd",
  "Glaxianmark Healthcare",
  "Glaxo India Ltd",
  "Glaxo Simthkline Pharmaceuticals Ltd",
  "Glaxo Smithkline Consum Hc P L",
  "Glaxo Smithkline Consumer Pvt Ltd",
  "Glaxo Smithkline Ph L",
  "Glaxo Smithkline Ph L (New)",
  "Glaxo Smithkline Ph L (Team 1)",
  "Glaxo Smithkline Ph L (Team 2)",
  "Glaxo Smithkline Ph L (Team 3)",
  "Glaxo Smithkline Ph L (Team 4)",
  "Glaxo Smithkline Ph L (Team 6)",
  "Glaxo Smithkline Ph Ltd",
  "Glaxo Smithkline Pharma Ltd",
  "Glaxo SmithKline Pharmaceuticals Ltd",
  "Glaxo Smithkline Pharmaceuticals Ltd(Team 1)",
  "Glaxosmithkline Asia Pvt Ltd",
  "Glaxosmithkline Consum Hc P L",
  "GlaxoSmithKline Consumer Healthcare",
  "Glaxosmithkline Pharma",
  "Glaxosmithkline Pharmaceuticals Ltd",
  "Glaztar Lifesciences Pvt Ltd",
  "Glebit Laboratories",
  "Gleck Pharma (opc) Pvt Ltd",
  "Glector Global Enterprises Pvt Ltd",
  "Glee Pharma Pvt Ltd",
  "Glenmark Pharma Ltd",
  "Glenmark Pharma Ltd(Critica)",
  "Glenmark Pharma Ltd(Gracwell)",
  "Glenmark Pharma Ltd(Healtheon)",
  "Glenmark Pharma Ltd(Integr)Spl",
  "Glenmark Pharma Ltd(Spl)",
  "Glenmark Pharma Ltd(Spl-1)",
  "Glenmark Pharma Ltd(Zoltan)",
  "Glenmark Pharmaceutical Ltd",
  "Glenmark Pharmaceuticals Ltd",
  "Glenmark Pharmaceuticals Ltd(Otc)",
  "Glenmark Pharmaceuticals Ltd(Spl 1)",
  "Glenon Healthcare",
  "Glensmith Labs Pvt Ltd",
  "Glentis Pharma Pvt Ltd",
  "Glenvox Biotech",
  "Glenwell Pharma",
  "Glenwin Healthcare Pvt Ltd",
  "Glenwood Biotech Pvt Ltd",
  "Glexia Pharma Pvt Ltd",
  "Glial Life Science LLP",
  "Glide International Pvt Ltd",
  "Glide Pharma",
  "Glitter Lifescience Pvt Ltd",
  "Glitz Pharma",
  "Glivita Pharma Pvt Ltd",
  "Global Brand Resources Pvt Ltd",
  "Global Care",
  "Global Consumer Products Pvt Ltd",
  "Global Corporation",
  "Global Dent Aids Pvt Ltd",
  "Global Generics UnLtd",
  "Global Health Care Inc",
  "Global Healthfit Retail India",
  "Global India Enterprises",
  "Global Medicare Inc",
  "Global Medicare Pharma",
  "Global Medisciences Ltd",
  "Global Trade Links",
  "Global Viso Trading Pvt Ltd",
  "Globalreef International",
  "Globate Pharma",
  "Globela Pharma Pvt Ltd",
  "Globetus Therapeutics Pvt Ltd",
  "Globin Pharmaceuticals Pvt Ltd",
  "Globus Labs",
  "Globus Labs Pvt Ltd",
  "Globus Life Sciences",
  "Globus Remedies Ltd",
  "Glomat Pharmaceuticals",
  "Glomphy Pharma",
  "Glories Pharmaceuticals Pvt Ltd",
  "Glorior Biotech Pvt Ltd",
  "Glorious Biotech",
  "Glory Heathcare Pvt Ltd",
  "Gloss Pharmaceuticals Pvt Ltd",
  "Glosun Pharma Pvt Ltd",
  "Gloucester Pharma",
  "Glounce Pharmaceuticals",
  "Glovel Healthcare Pvt Ltd",
  "Glow Vision Pharmaceuticals",
  "Glowcures Life Sciences",
  "Glowderma Laboratories Pvt Ltd",
  "Glowderma Labs Pvt Ltd",
  "Glowrid Biotech",
  "Glowsun India Labs Pvt Ltd",
  "Gloxinia Lifesciences",
  "GLS Pharma Ltd",
  "Gluconate Health Ltd",
  "Glue Biotech",
  "Glutape India Pvt Ltd",
  "Glyco Remedies",
  "Glydex Pharmaceutical",
  "Glydus Remedies Pvt Ltd",
  "Glynis Pharmaceutical Pvt Ltd",
  "GMD Pharmaceuticals",
  "Gmed Labs",
  "GMH Pharmaceuticals Pvt Ltd",
  "Gmm Human Care Pvt Ltd",
  "GMN Healthcare Pvt Ltd",
  "Gms Biomax Medicare Pvt Ltd",
  "Gnext Lab Pvt Ltd",
  "GNF Ethicals",
  "G-Nine Formulations Pvt Ltd",
  "Gnosis Pharmaceuticals Pvt Ltd",
  "Gnova Biotech",
  "Go Green Life Healthcare",
  "Go overseas Trading Corporation",
  "GO-Ish Remedies Ltd",
  "Goa Nutritions",
  "Gobido Healthcare Pvt Ltd",
  "Gobind Ram Kahan Chand",
  "Godase Healthcare",
  "Goddres Pharmaceuticals Pvt Ltd",
  "Godetia Healthcare",
  "Godfrey Biotech",
  "Godfrey Healthcare Pvt Ltd",
  "Godiva Bioadvances Pvt Ltd",
  "Godjay Laboratories Pvt Ltd",
  "Godrams Lifeline",
  "Godrej Consumer Products Ltd",
  "God's Own Store",
  "Godwill Healthcare Marketing",
  "Goel (Vet ) Pharma Pvt Ltd",
  "Gogenic Naturals Pvt Ltd",
  "Gohil Ayurved Pvt Ltd",
  "Gold Line",
  "Gold Shield Health Care Pvt Ltd",
  "Gold Star Remedies",
  "Golden Aurum Pharmaceuticals LLP",
  "Golden Lady International Pvt Ltd",
  "Golden Pharmaceuticals India Pvt Ltd",
  "Golden Square Lab Pvt Ltd",
  "Golden Tips Tea Pvt Ltd",
  "Goldenacacia Herrbals Pvt Ltd",
  "Goldenlad Pharmaceutical Pvt Ltd",
  "Goldline Pharmaceuticals Ltd",
  "Golgi Usa Research Laboratories LLP",
  "Gomez Healthcare",
  "Gonan Pharma",
  "Good Brands For A Healthy Life Pvt Ltd",
  "Good Food Retail Pvt Ltd",
  "Good Fortune Pharmaceuticals",
  "Good Hames Lifesciences",
  "Good Luck Pharmaceuticals",
  "Goodcare Pharma Pvt Ltd",
  "Goodlife Consumer Pvt Ltd",
  "Goodluck Pharma",
  "Goodman Gilman's Life Sciences Pvt Ltd",
  "Goodman pharmaceuticals",
  "Goodwill International",
  "Goody India",
  "Goofy Tails Pvt Ltd",
  "Gooseberry Pharmaceuticals Pvt Ltd",
  "Gopal Lifesciences",
  "GOQii Technologies Pvt Ltd",
  "Gordon Pharma",
  "Gorgeous Pharma Pvt Ltd",
  "Goshudh Nutrition Research Centre",
  "Gospel Labs India Pvt Ltd",
  "Govati Health",
  "GPA Exim Pvt Ltd",
  "GPA Teafloor Pvt Ltd",
  "Gplife Healthcare Pvt Ltd",
  "GPS Pharma Pvt Ltd",
  "GR Healthcare Laboratories",
  "Grace Care Pharma",
  "Grace Derma Healthcare Pvt Ltd",
  "Grace Drugs & Pharmaceuticals",
  "Graceq Life Sciences Pvt Ltd",
  "Gracevera Pharma",
  "Gracia Life Science India Pvt Ltd",
  "Graciera Pharmacetuicals LLP",
  "Gracious Biotech",
  "Gracious Pharmaceuticals",
  "Gracure Pharmaceuticals Ltd",
  "GRAF Laboratories Pvt Ltd",
  "Grafeio Healthcare Pvt Ltd",
  "Grampus Labs",
  "Grandcure Healthcare Pvt Ltd",
  "Grandeur India Health Care",
  "Granmed Pharma",
  "Grantham Lifesciences",
  "Granvalor Pharmaceuticals Pvt Ltd",
  "Grapple Life Sciences Pvt Ltd",
  "Grasim Industries Ltd",
  "Grasita Healthcare Pvt Ltd",
  "Gravion Life Sciences",
  "Grawish Pharma Pvt Ltd",
  "Gray Anon Formulations Pvt Ltd",
  "Gray's Pharma Pvt Ltd",
  "Great Lifesciences Pvt Ltd",
  "Greathealth Trexim Pvt Ltd",
  "Grecian Pvt Ltd",
  "Greek Pharma",
  "Greemer Biotics Pvt Ltd",
  "Green Bract Lifesciences",
  "Green Cross Health Innovation",
  "Green Cross Remedies",
  "Green Herbs Ayurved Home",
  "Green House Export",
  "Green Lab Healthcare",
  "Green Pharma",
  "Green Valley Cider Pvt Ltd",
  "Greenco Biologicals Pvt Ltd",
  "Greeniche Healthcare",
  "Greeniche Herbal Products Pvt Ltd",
  "Greenlab Healthcare Pvt Ltd",
  "Greenray Pharmaceuticals",
  "Greentek Heathcare",
  "Greenwell Specialty Healthcare Pvt Ltd",
  "Greevaa Life Sciences Pvt Ltd",
  "Grefith Labs",
  "Grefith Life Sciences Pvt Ltd",
  "Grevis Pharmaceuticals Pvt Ltd",
  "Grey Mantra Solutions",
  "Greyland Pharma Pvt Ltd",
  "Greystar Pharma",
  "Grievers Remedies",
  "Griffin Biogenesis Pvt Ltd",
  "Grifols India Healthcare Pvt Ltd",
  "Grin Health",
  "Gripil Pharmaceutical Pvt Ltd",
  "Grivety Lifecare LLP",
  "Gromax Healthcare Pvt Ltd",
  "Groom India Salon & Spa Pvt Ltd",
  "Ground Based Nutrition",
  "Group Pharmaceuticals Ltd",
  "Grow Next Healthcare Pvt Ltd",
  "Growell Vision",
  "Growish Health Care Pvt Ltd",
  "Growmax Medicare Pvt Ltd",
  "Growmed Mkt India Pvt Ltd",
  "Grownbury Pharmaceuticals Pvt Ltd",
  "GRS Laboratories Pvt Ltd",
  "Grubheal Care",
  "Gruentzig Pharmaceuticals",
  "Gryff Pharmaceuticals Pvt Ltd",
  "Gryphon Pharma",
  "Gsa Bio Pharma Pvt Ltd",
  "GSL Pharmaceuticals Pvt Ltd",
  "GSS Lifescinces",
  "Gss Pharma Pvt Ltd",
  "Gsv Pharmaceuticals Pvt Ltd",
  "Guapha Pharmaceuticals",
  "Guard Ever Life Sciences",
  "Guardian Healthcare Services Pvt Ltd",
  "Guduchi The Ayurvedism",
  "Gudway Lifescience Pvt Ltd",
  "Guerison MS Inc ",
  "Gufic Bioscience Ltd",
  "Gufic Critic Care",
  "Guillain Pharma Pvt Ltd",
  "Guiltfree Industries Ltd",
  "Gujarat Liqui Pharmacaps Pvt Ltd",
  "Gujarat Pharma Lab Pvt Ltd",
  "Gujarat Terce Laboratories Ltd",
  "Gulshan Polyols Ltd",
  "Gupta Ayurvedic Pharmacy Pvt Ltd",
  "Gupta Herbals",
  "Gupta Oxygen Pvt Ltd",
  "Gurgrace Pharmaceuticals",
  "Gurmail Brothers",
  "Gurnex Biotech",
  "Gurshyam Formulations",
  "Guru Pharmacy",
  "Guruchem Laboratories Pvt Ltd",
  "Guten Healthcare Pvt Ltd",
  "Gutford Pharmaceuticals",
  "GW Impex Pvt Ltd",
  "G-well Healthcare Pvt Ltd",
  "Gwenn Healthcare",
  "Gynetics Medical Products",
  "Gynofem Heathcare Pvt Ltd",
  "Gynosurge India",
  "Gynoveda Femtech Pvt Ltd",
  "H & Care Incorp",
  "H & H Pharma",
  "H & H Pharma (Dermatology)",
  "H & H Pharma Pharma Llp",
  "H & I Critical Care",
  "H H Pharmaceuticals",
  "H I ",
  "H L Healthcare Pvt Ltd",
  "H Q Lamps Manufacturing Co Pvt Ltd",
  "H&B Wellness",
  "H&C Distributors Pvt Ltd",
  "H&H- Vitality",
  "H&R Beverages Inc ",
  "H2H India Biotech Pvt Ltd",
  "H2H Innovations Pvt Ltd",
  "Haaldane Laboratories Pvt Ltd",
  "HAB Pharma",
  "Hab Pharmaceuticals and Research Ltd",
  "Habitare Pharma",
  "Hacks & Slacks Healthcare",
  "Haeal Pharma",
  "Hagnous Bioceuiticals Pvt Ltd",
  "Hahnemann Laboratories, Inc",
  "Hahnemann Pharmaceuticals Pvt Ltd",
  "Hahnemann Scientific Laboratory India Pvt Ltd",
  "Hahnemann'S Jac Olivol Group of Product Llp",
  "Haiden Technology Pvt Ltd",
  "Haines Biotech Pvt Ltd",
  "Hakeem Baqai's Medicare (P) Ltd",
  "Halberd Pharmaceuticals",
  "Halcyon Drugs",
  "Halden Healthcare & Research Pvt Ltd",
  "Hale Corporation Pvt Ltd",
  "Haledew Remedies",
  "HaleMed Pvt Ltd",
  "Halewood Laboratories Pvt Ltd",
  "Half Dome Internet Pvt Ltd",
  "Hallmark Chemicals",
  "Hallmark Formulations Pharmaceuticals",
  "Halonix Technologies Pvt Ltd",
  "Hamax Pharmaceuticals",
  "Hamdard Laboratories India",
  "Hamiast Global Pvt Ltd",
  "Hamps India Pvt Ltd",
  "Hamswell Lifecare",
  "Han Hindustan Medicare",
  "Hanburys Health Care Pvt Ltd",
  "Hands Pharmaceuticals",
  "Hands Up Integrated Business Services",
  "Hanna Pharmaceutical Supply Co Inc",
  "Hansh Pharmaceuticals",
  "Hanskri Healthcare",
  "Hansula Pharma Pvt Ltd",
  "Hanuchem Laboratories",
  "Happily Unmarried Marketing Pvt Ltd",
  "Happy Karma",
  "HappyLab Solutions Pvt Ltd",
  "Happytizers Pvt Ltd",
  "Hapro Homeo Chem Pvt Ltd",
  "Harasha Pharma Pvt Ltd",
  "Harbanshram Bhagwandas Ayurvedic Sansthan Pvt Ltd",
  "Harbgen Naturals Pvt Ltd",
  "Hard Eight Nutrition LLC",
  "Hardmed Healthcare",
  "Hariom Polypacks Ltd",
  "Harison Remedies",
  "Harisons life Science",
  "Harisons Venture Pvt Ltd",
  "Hariwin Pharma",
  "Harka Pharmaceutical Pvt Ltd",
  "Harleys Healthcare Pvt Ltd",
  "Harmony Lifescience Pvt Ltd",
  "Harmony Lifesciences",
  "Harnik Health Care Product",
  "Harriet Healthcare",
  "Harrison Healthcare",
  "Harshika Pharma",
  "Harson Laboratories",
  "Harsoria Healthcare Pvt Ltd",
  "Harvest Group",
  "Harvey Laboratories Pvt Ltd",
  "Harvey Life Sciences",
  "Haryana Formulations Pvt Ltd",
  "Hashmi Dawakhana",
  "Haustus Biotech Pvt Ltd",
  "Hauz Pharma Pvt Ltd",
  "Havex Remedies Pvt Ltd",
  "Hawaban Harde",
  "Hawitt Pharmaceuticals Pvt Ltd",
  "Hayman Healthcare Pvt Ltd",
  "Hayymed Healthcare Pvt Ltd",
  "Hazecure Pharma Pvt Ltd",
  "HBC Dermiza Healthcare Pvt Ltd",
  "Hbc Lifescience Pvt Ltd",
  "Hbc Lifesciences Ltd",
  "HBC Lifesciences Pvt Ltd",
  "HCI Healthcare",
  "HCP Wellness LLP",
  "HD Life Pharma",
  "HD Medical",
  "HD Pharmaceuticals",
  "HDA Lifesciences Pvt Ltd",
  "Headway Pharma",
  "Heal All Pharmaceuticals (P) Ltd",
  "Heal Dose",
  "Heal India Laboratories",
  "Heal India Pharmaceuticals LLP",
  "Healers Lab",
  "Healers Nutraceuticals Pvt Ltd",
  "Healers Pharmaceuticals",
  "Healing Hands & Herbs Pvt Ltd",
  "Healing India Life Sciences",
  "Healing Pharma India Pvt Ltd",
  "Healing Touch Pharmaceuticals Pvt Ltd",
  "Healium Pharmaceuticals",
  "Healkraft Pharma India Pvt Ltd",
  "HealnHealthy Ecommerce",
  "Healsure Lifesciences Pvt Ltd",
  "Health Amour",
  "Health Ayurveda",
  "Health Berry Lifesciences Pvt Ltd",
  "Health Biomed Pharma",
  "Health Biotech Ltd",
  "Health Care Formulation Ltd",
  "Health Care Formulations Pvt Ltd",
  "Health Care Pharmaceuticals",
  "Health First",
  "Health Guard India Pvt Ltd",
  "Health Healing Pharmaceuticals Pvt Ltd",
  "Health India",
  "Health N U Therapeutics Pvt Ltd",
  "Health Nutrition & Fitness Co ",
  "Health Plan",
  "Health Plus - Inde",
  "Health Point",
  "Health Product Pvt Ltd",
  "Healthaid Ltd",
  "Healthbest Pvt Ltd",
  "Healthbridge Lifescience Pvt Ltd",
  "Healthcare Lifesciences",
  "Healtheon Life Sciences",
  "Healthgate Pvt Ltd",
  "Healthgenie India Pvt Ltd",
  "Healthica Pharmaceuticals",
  "Healthium Medtech Pvt Ltd",
  "Healthkey Lifescience Pvt Ltd",
  "Healthkind Labs Pvt Ltd",
  "Healthkind Pharma Pvt Ltd",
  "Healthlon Pharmaceuticals",
  "Healthmate Pharmaceuticals Pvt Ltd",
  "Healthon Life Sciences Pvt Ltd",
  "Healthon Lifesciences Pvt Ltd",
  "Healthon Pharmaceuticals Pvt Ltd",
  "Healthridge Pharmaceuticals Pvt Ltd",
  "Healthserch Pharma LLP",
  "HealthShine India Pvt Ltd",
  "Healthstart Tech and Advisory Pvt Ltd",
  "Healthtree Pharma (India) Pvt Ltd",
  "Healthtree Pharma India Pvt Ltd",
  "Healthtronik Pharmaceuticals",
  "Healthwell Healthcare",
  "Healthy Floyd Pharmaceuticals",
  "Healthy Foods Enterprises",
  "Healthy Hark",
  "Healthy India Marketing Pvt Ltd",
  "Healthy India Pharmaceuticals",
  "Healthy Karma Foods Pvt Ltd",
  "Healthy Life Medicare",
  "Healthy Life Pharma Pvt Ltd",
  "Healthy Munch",
  "Healthy Naturals",
  "Healthy Origins",
  "Heal-thy Self Pharmaceuticals LLP",
  "HealthyHey Foods LLP",
  "Healthyways Pharmaceuticals pvt Ltd",
  "Healthzone Organic Foods Pvt Ltd",
  "Healwell International Ltd",
  "Heartsring Medpharma Pvt Ltd",
  "Hebert Biotech",
  "Hector Healthcare Pvt Ltd",
  "Hecure Health Care Pvt Ltd",
  "Hedva Pharmaceuticals",
  "Heera Ayurvedic Research Foundation",
  "Hegde & Hegde Pharmaceutica Llp",
  "Hegde and Hegde Pharmaceutical LLP",
  "Hegde&Hegde Pharmaceutica Llp",
  "Heine Optotechnik",
  "Heinz India Pvt Ltd",
  "Helax Health Care",
  "Helax Healthcare Pvt Ltd",
  "Helbek Pharma",
  "Helen Pharmaceuticals Pvt Ltd",
  "Helia Pharmaceuticals",
  "Helianthus Lifesciences Pvt Ltd",
  "Helico International",
  "Helios Lifestyle Pvt Ltd",
  "Helios Pharmaceuticals",
  "Helisinki Pharmaceuticals Pvt Ltd",
  "Helix India",
  "Hellix Healthcare Pvt Ltd",
  "Hello Ten Brands Pvt Ltd",
  "Helplab Healthcare Pvt Ltd",
  "Hema Laboratories",
  "Hemas Consumer Brands India Pvt Ltd",
  "Hemera Life Sciences Pvt Ltd",
  "Hemmispheres Pharma Pvt Ltd",
  "Henin Lukinz Pvt Ltd",
  "Henna Industries Pvt Ltd",
  "Henotic Pharma",
  "Henry Pharmaceuticals",
  "Heptagon Lifesciences Pvt Ltd",
  "Heramb Healthcare",
  "Herba Neuva Impex Pvt Ltd",
  "Herbal Canada",
  "Herbal Health Fitness Solutions",
  "Herbal Max Care",
  "Herbal Strategi",
  "Herbal Swadeshi Bhandar",
  "Herbal Trends",
  "Herbco Pvt Ltd",
  "Herbific (P) Ltd",
  "Herbo India",
  "Herbotics Research Inc",
  "Herboveda India Pvt Ltd",
  "Herbs Nutriproducts Pvt Ltd",
  "Herbx Enterprises",
  "Hering Pharma",
  "Hermas Biotech Pvt Ltd",
  "Hermes Therapeutics Pvt Ltd",
  "Hermitus Health Care",
  "Hesa Pharmaceuticals",
  "Hesh Herbals Prvate Ltd",
  "Heska Pharma",
  "Hesthetic Life Pvt Ltd",
  "Hetero Drugs Ltd",
  "Hetero Healthcare Ltd",
  "Hetero Healthcare Ltd(Genx)",
  "Hetero Healthcare Ltd(Lyka)",
  "Hetero Laboratories Ltd",
  "Hetero Labs Ltd",
  "Heveren Healthcare Pvt Ltd",
  "Hevert-Arzneimittel GmbH & Co KG (Made in Germany)",
  "Hex Pharmaceuticals",
  "Hexa Medical Pvt Ltd",
  "Hexadox Pharmaceuticals Pvt Ltd",
  "Hexagon Lifesciences Pvt Ltd",
  "Hexagon Nutrition Pvt Ltd",
  "Hexal Pharmaceuticals",
  "Hexine Pharmaceuticals",
  "Hextar Pharmaceuticals Pvt Ltd",
  "Heyday Care LLP ",
  "HFA Formulations Pvt Ltd",
  "Hfa Pharma Ethics Pvt Ltd",
  "HFC Prestige Manufacturing",
  "HHT Pharma Pvt Ltd",
  "Hi Alt Expert India",
  "Hi Life Products Pvt Ltd",
  "Hi Tech Pharmaceuticals Pvt Ltd",
  "Hiakan International",
  "Hibiscus Pharmaceuticals",
  "Hicare Pharma",
  "Hicks Thermometers India Ltd",
  "Hi-Cure Biotech",
  "Hicxica Formulations Pvt Ltd",
  "High Street Essentials Pvt Ltd",
  "Highsky Medicare Pvt Ltd",
  "HiGlance Laboratories",
  "Hilbert Healthcare",
  "Hilfen Pharmaceuticals Pvt Ltd",
  "Hillrock Biotech Pvt Ltd",
  "Hilt Brands India Pvt Ltd",
  "Hilton Hyman Healthcare",
  "Him Pharmaceuticals Pvt Ltd",
  "Himalaya Drug Company",
  "Himalaya Meditek Pvt Ltd",
  "Himalaya Pure Herbs Div",
  "Himani Herbal & Cosmetics",
  "Himanshu Pharmaceuticals Pvt Ltd",
  "Himbuds Vatika Pvt Ltd",
  "Himeros Pharmaceuticals Pvt Ltd",
  "Himerus Medical Solution Llp",
  "Himoksh Pharma",
  "Himsagar Laboratories Ltd",
  "Hind Bioscience",
  "Hind Pharma",
  "Hindayur Life Sciences Pvt Ltd",
  "Hindrishi Ayurveda",
  "Hindu Lines",
  "Hindus Pharma Pvt Ltd",
  "Hindustan Antibiotics Ltd",
  "Hindustan Ayurveda",
  "Hindustan Biotech",
  "Hindustan Health Care",
  "Hindustan Laboratories",
  "Hindustan Latex Ltd",
  "Hindustan Medicare",
  "Hindustan Medicines(P)Ltd",
  "Hindustan Pharmaceuticals",
  "Hindustan Syringes & Medical Devices Ltd",
  "Hindustan Unilever Ltd",
  "Hindveda Pvt Ltd",
  "Hinglaj Laboratories",
  "Hinikam Drugs & Pharmaceuticals",
  "Hinus Pharmaceutical",
  "Hippo Labs Pvt Ltd",
  "Hippocrat Biotech Pvt Ltd",
  "Hippolyta Impex Pvt Ltd",
  "Hiral Labs Ltd",
  "His Eyeness Ophthalmics Pvt Ltd",
  "Hi-Tec Laboratories Pvt Ltd",
  "Hitzelberger Life Sciences Pvt Ltd",
  "Hiya Lifesciences Pvt Ltd",
  "HK Medicals",
  "HLD Pharmaceuticals",
  "HLL Lifecare Ltd",
  "Hmb Commercial Pvt Ltd",
  "HMC Sale",
  "HMK Pharma Pvt Ltd",
  "HMV Herbals",
  "Hn Cure Pharma Pvt Ltd",
  "Hnf Care Pvt Ltd",
  "Hochest Biotech India",
  "HofB- House of Beauty",
  "Holiness Healthcare Pvt Ltd",
  "Holista Health Care",
  "Holistic Enterprises",
  "Holland & Barrett Retail Ltd",
  "Holly Organics Pvt Ltd",
  "Hollyhock Health Care Pvt Ltd",
  "Hollyleaf Life Care",
  "Holy Evolution Pharma",
  "Holy Lifesciences",
  "Holy Vedaa Ayurveda",
  "Holyhind Pharma",
  "Holymed Pharma",
  "Home Care Remedies",
  "Home Medix India Pvt Ltd",
  "Home of Spices",
  "Home Remedies Pvt Ltd",
  "Homeonest India Pvt Ltd",
  "Homoeo Laboratories",
  "Homogreen Pharmaceutical",
  "Honasa Consumer Pvt Ltd",
  "Honeywell Safety Products",
  "Honolive Pharma LLP",
  "Hootone Remedies",
  "Hope Life Sciences",
  "Hopeprime Pharmaceuticals Pvt Ltd",
  "Hopkins Health care India Pvt Ltd",
  "Horean Healthcare Pvt Ltd",
  "Horeca Soaps",
  "Horizon Bioceuticals Pvt Ltd",
  "Hos & Ins",
  "Hospimax Healthcare Pvt Ltd",
  "Hosta Labs",
  "Hostrin Life Sciences",
  "House of Pharma",
  "Household Pharmaceuticals",
  "Houston Scientific",
  "Howay Pharma",
  "Hower Pharma Pvt Ltd",
  "HP Pharmaceuticals Pvt Ltd",
  "HRD Supra Healthcare",
  "Hrikan Biotech Pvt Ltd",
  "Hrim Labs",
  "Hrsg Pharmaceutical Pvt Ltd",
  "HS Aldem Healthcare",
  "Hsekar Pharma (opc) pvt ltd",
  "Huadiz Pharmaceuticals",
  "Hublore Healthcare Pvt Ltd",
  "Hubris Pharmaceuticals Pvt Ltd",
  "Hufort Health Pvt Ltd",
  "Human Antibiotic Pharmaceuticals Pvt Ltd",
  "Human Bio Organics Pvt Ltd",
  "Human Biolife India Pvt Ltd",
  "Human Biological Institute",
  "Human Biotech Pvt Ltd",
  "Human Care Remedies",
  "Human Lifesciences",
  "Human Pharmacia Pvt Ltd",
  "Human Rise Pharma Pvt Ltd",
  "Human Vigor Pvt Ltd",
  "Humane Care Pharmaceuticals Pvt Ltd",
  "Humanwell Pharm",
  "Humanwell Pharmaceuticals",
  "Humbing Ethicals",
  "Humed Laboratories",
  "Hummed Healthcare",
  "HVM Network Pvt Ltd",
  "HW Wellness Solutions Pvt Ltd",
  "HWLC Pharmaceuticals Pvt Ltd",
  "Hy Tech Pharma",
  "Hybrand Pharmaceuticals",
  "Hychem Laboratories Pvt Ltd",
  "Hycorp Pvt Ltd",
  "Hyderabad Antibiotics",
  "Hydnora Medicare Pvt Ltd",
  "Hydrate Lifescience",
  "Hyeto Pharmaceuticals Pvt Ltd",
  "Hygeia Labs Pvt Ltd",
  "Hygeia Pharmaceuticals",
  "Hygen Healthcare Pvt Ltd",
  "Hygieia Biogenics Pvt Ltd",
  "Hygiene Product",
  "Hygienic Research Institute Pvt Ltd",
  "Hy-Gro Chemicals Pharmatek Pvt Ltd",
  "Hymax Healthcare Pvt Ltd",
  "Hypill Pharmaceuticals Pvt Ltd",
  "I Care Medi",
  "I I F A Health Care",
  "I2cure Pvt Ltd",
  "Iaomai Therapeutics",
  "Iasis Care",
  "Iatric Pharmaceuticals",
  "Iatros Pharmaceuticals Pvt Ltd",
  "Iberia Skin Brands India Pvt Ltd",
  "Icarus Healthcare Pvt Ltd",
  "Iceberg Healthcare Pvt Ltd",
  "Ichor Biologics Pvt Ltd",
  "ICI Healthcare Pvt Ltd",
  "ICI Pharmaceuticals",
  "Icines Pharmaceutical Pvt Ltd",
  "Icon Life Science",
  "Icon Life Sciences",
  "Icon Lifescience",
  "Icon Medisciences Pvt Ltd",
  "Icon Pharma & Surgicals Pvt Ltd",
  "Icpa Health Products Ltd",
  "Ideal Healthcare Industries",
  "Ideal Healthcare Pvt Ltd",
  "Ideal Life Sciences Pvt Ltd",
  "Ideal Pharmaceuticals Pvt Ltd",
  "Idem Healthcare Pvt Ltd",
  "IDFS Services Pvt Ltd",
  "Idol Biotech",
  "Ifimed Pharmaceuticals",
  "Ifimed Pharmaceuticals Pvt Ltd",
  "Ignited India Pharmaceuticals Pvt Ltd",
  "Igniva Marketing Pvt Ltd",
  "Ignyte Pharma",
  "Ignyx Pharmaceuticals",
  "IGP Mediventures Pvt Ltd",
  "IGP Medventures Pvt Ltd",
  "Ihealth Labs",
  "Ihtin Jaar Dermacare",
  "Iiris Natural Sciences Pvt Ltd",
  "Ikaria Wellness Pvt Ltd",
  "Ikon Pharmachem Pvt Ltd",
  "Ikon Remedies Pvt Ltd",
  "Ikvans Pharma",
  "Ildong Pharmaceutical Pvt Ltd",
  "Ilex Biologicals Pvt Ltd",
  "Ilmic Health Care Pvt Ltd",
  "IM Healthcare Pvt Ltd",
  "I'M Naturals",
  "iLife Retail Pvt Ltd",
  "Illcure Formulation Pvt Ltd",
  "Illumin Pharma",
  "Imagine Marketing Pvt Ltd",
  "Imaging Products (India) Pvt Ltd",
  "Imbue Naturals",
  "IMC Pvt Ltd",
  "Imed Healthcare",
  "Imhotef Pharmaceuticals Pvt Ltd",
  "Imiana House Pvt Ltd",
  "Immence Life Sciences Pvt Ltd",
  "Immortal Biotech Pvt Ltd",
  "Immortal Healthcare",
  "Immuna Life Sciences",
  "Immune Biotech Pvt Ltd",
  "Immunecare Lifesciences Pvt Ltd",
  "Immunescience Industries Pvt Ltd",
  "Impact Healthcare Pvt Ltd",
  "Impegno Pharmaceuticals Pvt Ltd",
  "Imperious Pharmaceutical Pvt Ltd",
  "Impetus Pharma",
  "Impex Technologies",
  "Impileo Lifescience",
  "Impromed Healthcare",
  "Improva Herbal Products",
  "Impulse Pharma Pvt Ltd",
  "In Med Therapeutics",
  "Inamad Pharma",
  "Inargo Medichem",
  "Inavars Biologicals Inc",
  "Incarnate Pharmachem Pvt Ltd",
  "Incepta Pharma",
  "Incipe Pharmceuticals",
  "Incuity Pharma Pvt Ltd",
  "Incus Pharmaceuticals Pvt Ltd",
  "Ind Biosciences",
  "Ind Blues Pharma",
  "Ind Swift Laboratories Ltd",
  "Indamed Pharmaceuticals Pvt Ltd",
  "Indchemie Health Spe Pvt Ltd",
  "Indchemie Health Specialities Pvt Ltd",
  "Indchemie Lifescience",
  "Indent Designs",
  "Indi Pharma",
  "Indi Pharma Pvt Ltd",
  "India Gelatine & Chemicals Ltd",
  "India Remedies",
  "Indiabulls pharmaceutical ltd",
  "Indiabulls Pharmaceuticals",
  "Indian Biologicals",
  "Indian Drug Distributors Pvt Ltd",
  "Indian Drugs & Pharmaceuticals Ltd",
  "Indian Herbal Valley",
  "Indian Herbs Specialities Pvt Ltd",
  "Indian Immunologicals Ltd",
  "Indian Laminates and Materials",
  "Indian Medicament",
  "Indian National Drugs Co PVT LTD",
  "Indian Pharmaceuticals",
  "Indian Remedies",
  "Indian Spice Oil Industries",
  "Indiana Ophthalmics",
  "Indiana Opthalmics",
  "Indica Biolife Sciences",
  "Indica Laboratories Pvt Ltd",
  "Indico Pharmaceuticals",
  "Indico Remidies Ltd",
  "Indicon Healthcare Pvt Ltd",
  "Indigo Pharmatech",
  "Indillina Pharmaceuticals Ltd",
  "Indinon Pharma",
  "Indisun Healthcare Pvt Ltd",
  "Indiyaa Distribution Network LLP",
  "Indizen Pharmaceutical",
  "Indkus Biotech India",
  "Indmag remedies Pvt Ltd",
  "Indmark Biotech Pvt Ltd",
  "Indo Asian Pharmaceuticals",
  "Indo Best Pharmaceuticals Pvt Ltd",
  "Indo Bioactive Labs Pvt Ltd",
  "Indo French Laboratories Ltd",
  "Indo Medix",
  "Indo Pacific Life Sciences",
  "Indo Remedies Ltd",
  "Indo Spectrum Pharmaceuticals",
  "Indo Swiss Remedies Pvt Ltd",
  "Indo-Asia Pharma Care",
  "Indochemie Health Specialities Pvt Ltd",
  "Indoclone Labs Pvt Ltd",
  "Indoco Remedies Ltd",
  "Indoco Remedies Ltd(Spade)",
  "Indoco Remedies Ltd(Surge)",
  "Indoco Remedies Ltd(Warren)",
  "Indoglobe Ventures Pvt Ltd",
  "Indolands Pharma Pvt Ltd",
  "Indomedix Dakshin Laboratories",
  "Indophin Remedies Pvt Ltd",
  "Indorbit Pharmaceuticals Pvt Ltd",
  "Indo-Reh Pharmaceuticals",
  "Indo-Safe Pharmaceuticals",
  "Indoscot Healthcare LLP",
  "Indospectrum Pharmaceuticals Pvt Ltd",
  "Indosurge Biotech",
  "IndoSurgicals Pvt Ltd",
  "Indosys Pharma",
  "Indsmile Pharmaceuticals",
  "Indu Drugs Pvt Ltd",
  "Indu Pharma Pvt Ltd",
  "Inducare Pharma Pvt Ltd",
  "Indus Cosmeceuticals Pvt Ltd",
  "Indus Healthcare",
  "Indus Life Sciences Pvt Ltd",
  "Indus Meditech Pvt Ltd",
  "Indus Pharma Pvt Ltd",
  "Indusken Pharma Pvt Ltd",
  "Inex Medicaments Pvt Ltd",
  "Infa Pharmaceuticals",
  "Infallible Pharma Pvt Ltd",
  "Infar India Ltd",
  "Infiga Healthcare",
  "Infinator Pvt Ltd",
  "Infinictum Lifesciences Pvt Ltd",
  "Infinite Formulations",
  "Infinite Online Shopping Pvt Ltd",
  "Infinitecare Pharmaceuticals Pvt Ltd",
  "Infinity Mediquip India",
  "Infino Healthcare Ltd",
  "Infivis Life Care",
  "Influx Healthcare",
  "Infochem Pharma Pvt Ltd",
  "Infocus Remedies",
  "Infomed Impex India",
  "Infra Pharmaceuticals Pvt Ltd",
  "Infugen Pharma Pvt Ltd",
  "Infutec Healthcare Ltd",
  "Inga Laboratories Pvt Ltd",
  "Ingenious Life Sciences Pvt Ltd",
  "Ingram Biosciences Pvt Ltd",
  "INI Pharma Pvt Ltd",
  "Inimag Laboratories",
  "Inizia Healthcare Pvt Ltd",
  "Inja Wellness",
  "Injays Pharmaceuticals",
  "Injecto Capta Pvt Ltd",
  "Inlife Pharma Pvt Ltd",
  "Inmed Foodlifesciences Pvt Ltd",
  "Inmed Therapeutics India",
  "Inmito Meditech Pvt Ltd",
  "Innate Biotech Pvt Ltd",
  "Inner Armour",
  "Innings Pharmaceuticals Pvt Ltd",
  "Innocuous Pharmaceuticals Pvt Ltd",
  "Innokon Laboratories Pvt Ltd",
  "Innosearch Biotech",
  "Innova Formulations Pvt Ltd",
  "Innova Life Sciences Pvt Ltd",
  "Innovate Confectionery",
  "Innovative Healthcare",
  "Innovative Life Sciences Pvt Ltd",
  "Innovative Linen Co Pvt Ltd",
  "Innovative Oral Care Solutions Pvt Ltd",
  "Innovative Pharma",
  "Innovative Pharmaceuticals",
  "Innovative Remedies",
  "Innovax Pharmaceuticals",
  "Innovcare Life Sciences",
  "Innovcare Life Sciences (Altius)",
  "Innovcare Life Sciences (Fortius)",
  "Innovcare Lifesciences Pvt Ltd",
  "Innoveda Biological Solutions Pvt Ltd",
  "Innovexa Healthcare Pvt Ltd",
  "Innovexia Life Sciences Pvt Ltd",
  "Innoview Pharma",
  "Innovision Healthcare Pvt Ltd",
  "Innovital Healthcare Pvt Ltd",
  "Innovius Life Drugs",
  "Innovwell Pharmaceuticals Pvt Ltd",
  "Innox Pharmaceuticals",
  "Inolife Healthcare",
  "Inova Captab",
  "Inovin Pharmaceuticals Pvt Ltd",
  "Inox Health Pvt Ltd",
  "Inqor Pharmaceuticals Pvt Ltd",
  "Inrean Pharmaceuticals",
  "Inrus Pharma Pvt Ltd",
  "Insead Lifesciences Pvt Ltd",
  "Inshaan Healthcare Pvt Ltd",
  "Insight Eyecare Pvt Ltd",
  "Insight Food Pvt Ltd",
  "Insight Labz",
  "Insinius Pharmaceuticals",
  "Insta Care Lifescience",
  "Instacure Healthcare Pvt Ltd",
  "Instant Remedies Pvt Ltd",
  "Instanz Life Sciences Pvt Ltd",
  "Instaray Pharmaceuticals",
  "Instyle Retails Inc",
  "Insula Pharmaceuticals Pvt Ltd",
  "Insutik pharmaceuticals Pvt Ltd",
  "Insys Pharmaceuticals Pvt Ltd",
  "Insyte Health Care",
  "Intag Remedies",
  "Intas Lab",
  "Intas Lab (Altima Div) (Spl)",
  "Intas Lab (Altima Division)",
  "Intas Lab (Pharma Division)",
  "Intas Lab (Suprima Division)",
  "Intas Pharma Ltd",
  "Intas Pharma Ltd(Alecta)",
  "Intas Pharma Ltd(Aquila)",
  "Intas Pharma Ltd(Arron)",
  "Intas Pharma Ltd(Astera)",
  "Intas Pharma Ltd(Ayokka)",
  "Intas Pharma Ltd(Sybella)",
  "Intas Pharma Ltd(Vector)",
  "Intas Pharma Ltd(Xenith)",
  "Intas Pharmaceutical Ltd",
  "Intas Pharmaceuticals Ltd",
  "Intas Pharmaceuticals Ltd(Alecta)",
  "Intas Pharmaceuticals Ltd(Arron)",
  "Intas Pharmaceuticals Ltd(Generic)",
  "Intas Pharmaceuticals Ltd(Generics)",
  "Intas Pharmaceuticals LtdGx",
  "Integra Life Sciences Pvt Ltd",
  "Integrace (P) Ltd",
  "Integrace Pvt Ltd",
  "Integral Lifesciences",
  "Integrated Laboratories Pvt Ltd",
  "Integrin Health Care",
  "Integrity Enterprises",
  "Intel Pharmaceuticals",
  "Intellect Lifescience",
  "Intergrow Foods & Beverages Pvt Ltd",
  "Intermed Pharma Pvt Ltd",
  "International Pet Food Company Ltd",
  "International Protein",
  "Interphar Healthcare Pvt Ltd",
  "Intigus Pharmaceutical Pvt Ltd",
  "Intra Labs India Pvt Ltd",
  "Intra Life",
  "Intracin Pharmaceuticals Pvt Ltd",
  "Intrawin Healthcare",
  "Introgen Healthcare Pvt Ltd",
  "Intsia Pharma Pvt Ltd",
  "Inuen Healthcare Pvt Ltd",
  "Invasion Pharmaceuticals Pvt Ltd",
  "Invent Biocare Pvt Ltd",
  "Invent Biotech",
  "Inventiva Labs Pvt Ltd",
  "Inventz Life Sciences Pvt Ltd",
  "Invictus Lifesciences",
  "Invida India Pvt Ltd",
  "Invig Healthcare Pvt Ltd",
  "Inviscus Lifescience LLP",
  "Invision Health Care",
  "Invision Medi Sciences Pvt Ltd",
  "Invomate Lifesciences Pvt Ltd",
  "Invomed Cotab Pvt Ltd",
  "Inzpera Healthsciences Ltd",
  "Ion Heathcare Pvt Ltd",
  "Ionaire Biotech",
  "Ionic MSM Ltd",
  "Ionis Lifesciences Pvt Ltd",
  "Ipc Healthcare Pvt Ltd",
  "Ipca (Activa)",
  "Ipca (Bionova)",
  "Ipca Health Product Pvt Ltd",
  "Ipca Lab Ltd",
  "Ipca Lab Ltd(C V )",
  "Ipca Lab Ltd(Hycare)",
  "Ipca Lab Ltd(INNOVA)",
  "Ipca Lab Ltd(Uro Science)",
  "Ipca Laboratories",
  "Ipca Laboratories Ltd",
  "Ipca Laboratories Ltd(Innova)",
  "Ipca Laboratories Pvt Ltd",
  "Ipca Pharma India",
  "Ipcon Labs Pvt Ltd",
  "IPI India Pvt Ltd",
  "Ipsa Labs Pvt Ltd",
  "IQ Med Healthcare Pvt Ltd",
  "Iressia Life Sciences Pvt Ltd",
  "Iretus Biotech Pvt Ltd",
  "Iris Pharmaceuticals Pvt Ltd",
  "Iriser Pharmaceuticals",
  "Iriska Pharmaceuticals Pvt Ltd",
  "Irizer Laboratories India Pvt Ltd",
  "IRx pharmaceuticals Pvt Ltd",
  "Irza Life Science Pvt Ltd",
  "Isakura Healthcare Pvt Ltd",
  "Iscon Life Sciences",
  "Isens Biosensors India Pvt Ltd",
  "Isha Agro Developers Pvt Ltd",
  "Isha Surgical",
  "Ishaanav Nutraceuticals Pvt Ltd",
  "Ishjas Pharma Pvt Ltd",
  "Ishwar Enterprises",
  "Isika Pharma Inc",
  "Isis Healthcare Pvt Ltd",
  "Isis Pharmaceuticals",
  "ISISPHARMA Dermatologie",
  "Isonic Pharmaceuticals Pvt Ltd",
  "Isotope Pharma Pvt Ltd",
  "Isrea Life Sciences",
  "Issaac Cosmocare",
  "iStoreDirect Trading LLP",
  "Iswiss Nutrition",
  "Itaara Foodtech Pvt Ltd",
  "ITC ltd",
  "Itelic Labs",
  "Iuvenis Healthcare Pvt Ltd",
  "Iva Healthcare Pvt Ltd",
  "Ivaan pharmaceuticals",
  "Iveon Laboratories Ltd",
  "Ives Drugs Pvt Ltd",
  "Ivy Healthcare",
  "Iwin Care",
  "Iwynn Pharma Pvt Ltd",
  "Ixontics Biosciences Pvt Ltd",
  "Iynx Lifesciences Pvt Ltd",
  "Iyurved Consumer Pvt Ltd",
  "Izen Pharmaceuticals Pvt Ltd",
  "Izng Pharmaceuticals",
  "Izuk Impex",
  "J Altius Healthcare",
  "J and J Dechane Pvt Ltd",
  "J B Chemicals & Pharmaceuticals Ltd",
  "J B Chemicals And Pharmaceuticals",
  "J B Chemicals and Pharmaceuticals Ltd",
  "J B D Life Sciences Pvt Ltd",
  "J B Lifesciences",
  "J B Remedies Pvt Ltd",
  "J C P International Pvt Ltd",
  "J J Enterprise",
  "J K Biotech",
  "J K Drugs & Pharmaceutical Pvt Ltd",
  "J K Pharma",
  "J L Morison India Ltd",
  "J M BIOCEUTICALS",
  "J M PHARMA PVT LTD",
  "J N Healthcare",
  "J P Biotech",
  "J p Lifesciences Pvt Ltd",
  "J R S Oryx Pharmaceuticals Pvt Ltd",
  "Jabs Biotech Pvt Ltd",
  "Jackson Laboratories Pvt Ltd",
  "Jacsims Pharmaceuticals",
  "Jagatjit Industries Ltd",
  "Jagdale Industries Ltd",
  "Jagdale Industries Pvt Ltd",
  "Jagrit & Jayant Dang Industries Pvt Ltd",
  "Jagsam Pharma",
  "Jagsonpal Pharmaceuticals Ltd",
  "Jahnavi Pharma",
  "Jai Ingredients Pvt Ltd",
  "Jai Rishi Ayurveda",
  "Jain Lifesciences Pvt Ltd",
  "Jain Pharmaceuticals",
  "Jain Tristars LLP",
  "Jainik Life Sciences",
  "Jainson Biotech (India) Pvt Ltd",
  "Jainti Pharamaceuticals Pvt Ltd",
  "Jaival Phrmaceuticals",
  "Jaiwik Biotech",
  "Jakstar Pharma",
  "Jalks Pharmaceutical Pvt Ltd",
  "Jam International",
  "Jamedex Pharma",
  "Jamna Herbal Research Ltd",
  "Jamrex Biotech Pvt Ltd",
  "Jamson Care India",
  "Janitra Medicorp Pvt Ltd",
  "Janjaes Pharmaceutical Pvt Ltd",
  "Janssen Pharmaceuticals",
  "Janta Dardmand Dawakhana",
  "Jantec Pharma",
  "Janus Biotech",
  "Janus Life Sciences Pvt Ltd",
  "Jaqson Healthcare Pvt Ltd",
  "Jaress Hygiene Care Pvt Ltd",
  "Jark Pharma Pvt Ltd",
  "Jarrow Formulas Inc",
  "Jarson Pharmaceuticals",
  "Jarun Pharmaceuticals",
  "Jarun Pharmacuticals Pvt Ltd",
  "Jasbal Pharma",
  "Jasco Labs Pvt Ltd",
  "Jasish Healthcare Llp",
  "Jaslynn Healthcare",
  "Jasmine Healthcare India Pvt Ltd",
  "Jasper Remedies Pvt Ltd",
  "Jassons Formulations",
  "Javeline Pharmaceuticals",
  "Javmed Healthcare",
  "Jawa Pharmaceuticals Pvt Ltd",
  "Jaxrane Remedies",
  "Jay Ell Healthcare Pvt Ltd",
  "Jayamuthu Surgicals",
  "Jaycure Pharmaceuticals",
  "Jayden Healthcare Pvt Ltd",
  "Jaypurkar Pharmaceutical (OPC) Pvt Ltd",
  "Jayson Pharmaceuticals Ltd",
  "Jaywin Remedies Pvt Ltd",
  "JB Chemicals & Pharmaceuticals Ltd",
  "JBChemicals & Pharmaceuticals Ltd",
  "Jbchemicals & Pharmaceuticals Ltd(Generics)",
  "JBD Life Sciences Pvt Ltd",
  "JBK Sun Pharma Pvt Ltd",
  "JBPS Pharmaceutical",
  "JC Lifecare Pvt Ltd",
  "Jchem Pharmaceuticals",
  "JD Pharma",
  "Jeen Healthcare",
  "Jeen Lifesciences Pvt Ltd",
  "Jeena Sikho Lifecare Ltd",
  "Jeevan Organics",
  "Jeevan Pharma",
  "Jeevan Sanjeevani Herbs Pvt Ltd",
  "Jeevecam Lifescience Pvt Ltd",
  "Jeewan Jyoti Pharmacy Lvt Ltd",
  "Jemon Pharmaceutical",
  "Jemster Healthcare Pvt Ltd",
  "Jenburkt Pharmaceuticals Ltd",
  "Jenfer Biosciences Pvt Ltd",
  "Jenome Biophar Pvt Ltd",
  "Jeotrix Healthcare",
  "Jes Pharmacia Pvt Ltd",
  "Jessuns Pharma",
  "Jetcy Life Science",
  "Jhanil Healthcare Pvt Ltd",
  "Jian Pharmaceuticals",
  "Jibok Pharmaceuticals",
  "Jimsun Life Sciences Pvt Ltd",
  "Jindal biotech Pvt Ltd",
  "Jindal Naturecare Ltd",
  "Jio Life Global Pvt Ltd",
  "Jiomed Healthcare Pvt Ltd",
  "Jishika Pharmaceuticals Pvt Ltd",
  "Jiva Ayurvedic Pharmacy Ltd",
  "Jivana Max Healthcare",
  "Jivika Laboratories",
  "Jivo Wellness Pvt Ltd",
  "Jiwadaya Healthcare Pvt Ltd",
  "Jixon Pharmaceuticals Pvt Ltd",
  "Jiyan Food Ingredients",
  "JJSR Health Care",
  "JJW & Brothers Pvt Ltd",
  "JK Biochem Healthcare Pvt Ltd",
  "Jk India Healthcare Pvt Ltd",
  "JK Pharmaceuticals",
  "JLS Pharma Pvt Ltd",
  "JM Healthcare Pvt Ltd",
  "JM Laboratories",
  "JMD Medico",
  "JMS Healthcare Pvt Ltd",
  "JMS Medicon Pvt Ltd",
  "JMS Singapore Pte Ltd",
  "JNTL Consumer Health (India) Pvt Ltd",
  "Jocund India Ltd",
  "Jodiac Pharmaceuticals",
  "John Biotech Pvt Ltd",
  "John Walter Labs",
  "Johnlee Pharmaceuticals",
  "Johnlee Pharmaceuticals Pvt Ltd",
  "Johnson & Johnson",
  "Johnson & Johnson (Ethnor Ltd)",
  "Johnson & Johnson (F M C G)",
  "Johnson & Johnson (Pharma)",
  "Johnson & Johnson (Spl)",
  "Johnson & Johnson Ltd",
  "Johnson & Smith Co",
  "Jointcare Pharmaceuticals Pvt Ltd",
  "Jois Genetica",
  "Jollen Medicare Pvt Ltd",
  "Jolly Healthcare",
  "Jolly Pharma",
  "Jonas Pharmaceuticals Pvt Ltd",
  "Joseph Leslie & Company LLP",
  "Joseph Lister Pharmaceuticals (P) Ltd",
  "Josh Healthcare",
  "Joslin Pharma India Pvt Ltd",
  "Jovees Herbal Care",
  "Jovimag Lifesciences",
  "Joy Healthcare Pvt Ltd",
  "Joy Life Health Care",
  "Joyce Healthcare Pvt Ltd",
  "Jpee Drugs",
  "Jpee Healthcare",
  "Jrb Lifesciences Pvt Ltd",
  "JRT Organics",
  "J's Laboratories Pvt Ltd",
  "JSG Innotech Pvt Ltd",
  "JSR Pharma Pvt Ltd",
  "Jsv Healthcare Pvt Ltd",
  "Jubilant Generics Ltd",
  "Jubilant Life Sciences",
  "Jubilant Lifesciences",
  "Jubilation Bio Science",
  "Juggat Pharma",
  "Juggat Pharma Ltd",
  "Juhi Life Science Herbal",
  "Juncus Life Sciences Pvt Ltd",
  "Junifer Pharmaceuticals",
  "Jupiter Biolabs Pvt Ltd",
  "Jupiter Formulations Pvt Ltd",
  "Jupiter Lifesciences Pvt Ltd",
  "Jupiter Pharmaceutical Ltd",
  "Jupiven Pharmaceuticals Pvt Ltd",
  "Jus Amazin Foods and Beverages Pvt Ltd",
  "Just Pharma",
  "Juvenor Pharmaceuticals",
  "Juvetica Lifesciences",
  "JV Foodworks Pvt Ltd",
  "JV Healthcare",
  "JVD Pharma Pvt Ltd",
  "JVG Healthcare",
  "JVG Pharmaceuticals",
  "JVJ Pharmaceuticals Pvt Ltd",
  "Jwell Pharmaceuticals Pvt Ltd",
  "Jydem Bio Pharma Pvt Ltd",
  "Jyorana International Pvt Ltd",
  "Jyoti Healthcare Pvt Ltd",
  "Jysmed Pvt Ltd",
  "K K-Liv",
  "K K-Zyme",
  "K P ",
  "K P Namboodiris",
  "K S Surgical Pvt Ltd",
  "Kaahan Ayurveda",
  "Kaalinga Pharmaceuticals",
  "Kaansla Pharmaceuticals Pvt Ltd",
  "Kaarya Naturals Pvt Ltd",
  "Kaashvi Enterprises",
  "Kabir Life Sciences",
  "Kabru Global",
  "Kabson Medicare",
  "Kacchela Medex Pvt Ltd",
  "Kach Biotech",
  "Kachhela Medex Pvt Ltd",
  "Kaden Health care Pvt Ltd",
  "Kadion Pharma",
  "Kaged Muscle",
  "Kahira Biotech",
  "Kaigreene Supplements",
  "Kailas Jeevan Ayurved",
  "Kailash Ayurveda Pvt Ltd",
  "Kaimax Eye Care",
  "Kainopharm Ltd",
  "Kainos Curatives Pvt Ltd",
  "KAINOSIS PHARMACEUTICALS",
  "Kainovative Pvt Ltd",
  "Kaipro Healthcare Pvt Ltd",
  "Kairali Ayurvedic Products Pvt Ltd",
  "Kaiser Drugs Pvt Ltd",
  "Kaiser Pharmaceuticals",
  "Kaithy Pharma",
  "Kaizen Drugs Pvt Ltd",
  "Kaizen Pharmaceuticals",
  "Kaizen Pharmaceuticals Pvt Ltd",
  "Kaizen Research Labs India Pvt Ltd",
  "kalal & Saiyed Pharma Pvt Ltd",
  "Kalan Pharmaceuticals",
  "Kalart Creations",
  "Kalash Pharmaceuticals",
  "Kaleon Laboratories",
  "Kalinda Healthcare Solutions",
  "Kalmia Healthcare",
  "Kalor Trading Company",
  "Kalp Pharma",
  "Kalpamrit Ayurved Pvt Ltd",
  "Kalpataru Formulations Pvt Ltd",
  "Kalwin Biomedica",
  "Kamadgiri Fashion Ltd",
  "Kamakshi Drugs & Pharmaceuticals",
  "Kamal Healthcare Products (P) Ltd",
  "Kamatri Corporation Pvt Ltd",
  "Kamcure Biotech",
  "Kamdhenu Agencies",
  "Kamdhenu Ayurvedic Pharmacy",
  "Kamdhenu Laboratories",
  "Kameo Pharma Products Pvt Ltd",
  "Kampri Lifescience Pvt Ltd",
  "Kamron Laboratories Ltd",
  "Kamryl Biolife Pvt Ltd",
  "Kanad Labs",
  "Kanam Latex Industries Pvt Ltd",
  "Kanav Enterprises",
  "Kanfa Supply Solutions",
  "Kanish Biotech",
  "Kanraxy Pharma",
  "Kansas Labs",
  "Kanstride Biolabs",
  "Kanvas Biotech Ltd",
  "Kanvista Drugs Pvt Ltd",
  "Kanwal Foods and Spices Pvt Ltd",
  "Kaor Trading Company",
  "Kaparthi Life Sciences",
  "Kapco International Ltd",
  "Kapeetus Medicorp",
  "Kapil Plastic Mould",
  "Kapila Health Care",
  "Kappris Drug Pvt Ltd",
  "Kaps Three Sciences Pvt Ltd",
  "Kaps3 Life Sciences",
  "Kaptab Pharmaceuticals",
  "Kapuan Healthcare",
  "Kapy Pharmaceuticals",
  "Kar Pharmaceuticals",
  "Karaa Healthcare",
  "Karamveer Ayurveda",
  "Kardian Pharma India Pvt Ltd",
  "Kardic Kare",
  "Kare Health Specialities Pvt Ltd",
  "Kargsin Healthcare Pvt Ltd",
  "Kariakali Amman Foods",
  "Karisca Healthcare Pvt Ltd",
  "Karkhana Zinda Tilismath",
  "Karlin Pharmaceuticals & Exports Pvt Ltd",
  "Karma Healthcare",
  "Karma Medical Products Co Ltd",
  "Karnani Pharmaceuticals Pvt Ltd",
  "Karnataka Antibiotic & Pharma Ltd",
  "Karnataka Antibiotics & Pharmaceuticals Ltd",
  "Karsan Healthcare",
  "kartilaze Care Ltd",
  "Karuup Pharma",
  "Kasen Pharma Products Pvt Ltd",
  "Kashmik Formulation",
  "Kashmir Exotics",
  "Kashvatha Ventures Pvt Ltd",
  "Kashyap Industries",
  "Kasnav Pharma",
  "Kaspar Pharma",
  "Katg Therapeutics Pvt Ltd",
  "Kathkin Lifesciences Pvt Ltd",
  "Katzung Pharmaceuticals Pvt Ltd",
  "Kaul Botaniq",
  "Kauris Care Pvt Ltd",
  "Kaushcure Pharmaceuticals Pvt Ltd",
  "Kausikh Therapeutics Pvt Ltd",
  "Kaust Pharma Pvt Ltd",
  "Kautilya Remedies Pvt Ltd",
  "Kav Laboratories",
  "Kava Drug",
  "Kavach 9",
  "Kavach 9 Pharma & Research Pvt Ltd",
  "Kavi Exports",
  "KAVIT Soap Industries",
  "Kavita Pharma Chem",
  "Kaviva Life Sciences",
  "Kavya Agency",
  "Kavya HealthCare",
  "Kavys Pharma",
  "Kaya Ltd",
  "Kaydee Biotech Pvt Ltd",
  "Kayess Healthcare",
  "Kaymed Pharmaceuticals Pvt Ltd",
  "Kayra Innopharm Pvt Ltd",
  "Kay's Biotech",
  "Kaytent Industries",
  "KayThree HealthCare Pvt Ltd",
  "Kaytross Health Care",
  "Kayush Laboratories Pvt Ltd",
  "Kazima Cosmetics",
  "Kazima Perfumers",
  "KC Laboratories",
  "KCI Medical India Pvt Ltd",
  "KCM Laboratories",
  "KCN Life Pvt Ltd",
  "Kd Chem Pharma",
  "KE Healthcare Pvt Ltd",
  "Kedarnath Healthcare pvt Ltd",
  "Kedia Pharma",
  "Kedna Pharmaceuticals",
  "Kee Pharma",
  "Keen Healthcare Pvt Ltd",
  "Keeo Pharma",
  "Keesan Pharma",
  "Kehr Surgical Pvt Ltd",
  "Keimed Ltd",
  "Kejriwal Bee Care India Pvt Ltd",
  "Kellogg India Pvt Ltd",
  "Kelly Pharmaceuticals Pvt Ltd",
  "Kelvin Pharmaceutical Laboratories Pvt Ltd",
  "Kelvin Remedies",
  "Kembris Pharmaceuticals",
  "Ken Biotec",
  "Kenbrio Pharmaceutials Pvt Ltd",
  "Kendall Healthcare Pvt Ltd",
  "Kenmed Pharma Pvt Ltd",
  "Kenn Pharmaceuticals Pvt Ltd",
  "Kenriz Care",
  "Kenrox Healthcare Pvt Ltd",
  "Kentossa Pharmaceuticals",
  "Kentreck Laboratories Pvt Ltd",
  "Kenvish Healthcare",
  "Kenzar Pharma World",
  "Keona Life Sciences Pvt Ltd",
  "Kepler Health Care",
  "Kepler Healthcare Pvt Ltd",
  "Kepran Healthcare",
  "Kepsee Lab",
  "Kerala Ayurveda Ltd",
  "Kerala Naturals",
  "Keris Pharma",
  "Keron Lifesciences Pvt Ltd",
  "Kerwin Formulations",
  "Keshav Healthcare",
  "Kesrox Pharmaceuticals",
  "Kestrel Lifesciences",
  "Ketowash Lotion 100 Ml",
  "Keval Pharma",
  "Keventer Global Pvt Ltd",
  "Keventis Healthcare",
  "Kevin Healthcare",
  "Kevin Pharma Pvt Ltd",
  "Kevlar Healthcare",
  "Key West Medicines Pvt Ltd",
  "Keya Pharmawin",
  "Keya Seths Cosmetic",
  "Keyford Healthcare",
  "KFK Healthcare LLP",
  "Khadi Ark",
  "Khadi Bharat Healthcare",
  "Khadi Herbal",
  "Khadi Mauri Herbal",
  "Khadi Naturals",
  "Khadi Organique",
  "Khadi Pure Gramodyog",
  "Kham Pharma",
  "Khandelwal Laboratories Pvt Ltd",
  "Khandelwal Laboratories(P) Ltd",
  "Khandelwal Laboratories(P) Ltd(Special)",
  "Khevo Pharmaceuticals Pvt Ltd",
  "Khlorophylls Biotech Pvt Ltd",
  "Khun Prema",
  "Khushal Pharmaceuticals",
  "Khushi Agro Pvt Ltd",
  "Kia Lifesciences Pvt Ltd",
  "Kia Pharmaceuticals Pvt Ltd",
  "Kiama Lifesciences",
  "Kiansons Formulation Pvt Ltd",
  "Kiefer Pharmaceuticals",
  "Kifa Healthcare Pvt Ltd",
  "Kim Lab",
  "Kimirica Hunter International",
  "Kindcare Pharmaceutical",
  "Kind's Pharma",
  "Kindspirit Pharmaceutical",
  "Kinedex Healthcare Pvt Ltd",
  "Kinegic Pharmaceuticals Pvt Ltd",
  "Kineses Laboratories",
  "Kinesis Biocare",
  "Kinesis Pharmaceuticals Pvt Ltd",
  "Kinetic Lifescience (OPC) Pvt Ltd",
  "Kinetsu Therapeutics Pvt Ltd",
  "Kingdom of Good Food LLP",
  "Kingscure Medicare",
  "Kingsmed Pharma",
  "Kinjal Biotech Pvt Ltd",
  "Kinson Pharma",
  "Kiosence Health Care Pvt Ltd",
  "Kiosence Healthcare Pvt Ltd",
  "Kioxy Healthcare",
  "Kipss Health Pharma",
  "Kiraas Pharma (India) Pvt Ltd",
  "Kiraas Pharma India Pvt Ltd",
  "Kiran Pharmaceutical",
  "Kirit Ayurveda",
  "Kishri Pharma Pvt Ltd",
  "Kism Textile Pvt Ltd",
  "Kisoc Healthcare (OPC) Pvt Ltd",
  "Kitnix Pvt Ltd",
  "Kiva HealthCare",
  "Kivi Labs Ltd",
  "Kivonyx Healthcare",
  "Kiya Organic Essentials",
  "Kj Laboratories Pvt Ltd",
  "Klar Eye India Pvt Ltd",
  "Klar Sehen Pvt Ltd",
  "Klarvoyant Biogenics Pvt Ltd",
  "Klingen Life Sciences Pvt Ltd",
  "Klintoz Pharmaceuticals Pvt Ltd",
  "KLM Laboratories Pvt Ltd",
  "Klokter Life Sciences",
  "Kloster Pharmaceuticals",
  "KLR ",
  "Kmcure Pharmaceuticals Pvt Ltd",
  "KMS Health Center Pvt Ltd",
  "Knight Medicare Pvt Ltd",
  "Knight Pharmaceuticals Pvt Ltd",
  "Kniss Laboratories Pvt Ltd",
  "Knockworld Pharma",
  "Knoll Healthcare Pvt Ltd",
  "Knoll Pharmaceuticals Ltd",
  "Knox Life Sciences Pvt Ltd",
  "Koester Pharmaceuticals Pvt Ltd",
  "Koindo Trading Pvt Ltd",
  "Kolaz Biotech Pvt Ltd",
  "Kolson India Healthcare Pvt Ltd",
  "Kom-med Labrotories Pvt Ltd",
  "Konark Biochem",
  "Konark Lifesciences Pvt Ltd",
  "Konkow Lab",
  "Konniseri Pharmaceuticals Pvt Ltd",
  "Kontest Chemicals Ltd",
  "Konverge Healthcare Pvt Ltd",
  "Kopler Healthcare Pvt Ltd",
  "Kopran Ltd",
  "Koshika Wellness Pvt Ltd",
  "Kosmed Formulations",
  "Kosmica Dermacare",
  "Kosmochem Pvt Ltd",
  "Kotak Life Sciences",
  "Kotish Biotech Pvt Ltd",
  "Kotiya Pharma",
  "Kou Kan Pharmaceutical Pvt Ltd",
  "Koye Pharmaceuticals Pvt ltd",
  "KPH Cosmos Pvt Ltd",
  "Kpital Life Science",
  "KPL Life Science Pvt Ltd",
  "KPSA2 Lifesciences Pvt Ltd",
  "Kramer Pharmaceuticals Pvt Ltd",
  "Krasiv Lifesciences India Pvt Ltd",
  "Krayon Lifesciences India Pvt Ltd",
  "KRD Exports Pvt Ltd",
  "Krebz Healthcare Pvt Ltd",
  "Kreios Laboratries Pvt Ltd",
  "Kreit Pharma Pvt Ltd",
  "Kremoint Pharma Pvt Ltd",
  "Krezlin Pharma",
  "Kribiz Healthcare Pharmacy",
  "Krinon Remedies Pvt Ltd",
  "Krios Pharma",
  "Kript Pharmaceuticals Pvt Ltd",
  "Kris Pharma",
  "Krish Care Remedys Pvt ltd",
  "Krish Medicare",
  "Krishbaj India Pharmaceuticals Pvt Ltd",
  "Krishbaj Laboratories Pvt Ltd",
  "Krishgir Pharmaceuticals Pvt Ltd",
  "Krishlar Pharmaceuticals",
  "Krishna Digital",
  "Krishna Food Industries",
  "Krishna Life Sciences",
  "Krishna Pharma",
  "Krishna Surgical Specialities Pvt Ltd",
  "Krishna's Herbal & Ayurveda",
  "Kriska Genesis Ingredients Pvt Ltd",
  "Kriso Pharma",
  "Krista Life Science",
  "Krista Life Sciences",
  "Kriti Lifesciences",
  "Kritikos Care",
  "Kriven Health Solutions",
  "KRM Ayurveda Pvt Ltd",
  "Kronicure Biosciences Pvt Ltd",
  "Kronos Healthcare",
  "Krontec Healthcare",
  "KRP Pharma",
  "Kruger Nutra Med Pvt Ltd",
  "Kruger Pharmaceuticals Pvt Ltd",
  "Krupa Naturals",
  "Kryptochem Medicament Pvt Ltd",
  "Kryptomed Formulations Pvt Ltd",
  "Krypton Global Health",
  "Krypton Pharmaceuticals",
  "Kryptosis Pharma Llp",
  "KS Labs",
  "KSB Nutritional Supplement",
  "Kshipra Health Solutions",
  "Kuawat Personal Care",
  "Kudos Laboratories India Ltd",
  "Kuemen Labs Pvt Ltd",
  "Kuhu Creations",
  "Kumar Lifesciences",
  "Kumayun Drugs Pvt Ltd",
  "Kunnath Pharmaceuticals",
  "Kurenova Pharmaceutical",
  "Kuresys Labs",
  "Kurian Abraham Pvt Ltd",
  "Kurit Pharma Pvt Ltd",
  "Kurizon Healthcare Pvt Ltd",
  "Kusum Healthcare Pvt Ltd",
  "Kutis Healthcare Pvt Ltd",
  "Kvezal Healthcare Ltd",
  "Kvific Pharmaceutical Pvt Ltd",
  "Kwik Healthcare",
  "KWOT Healthcare Pvt Ltd",
  "Kwykewl Pharma Pvt Ltd",
  "Kylon Remedies Pvt Ltd",
  "Kylsans Drugs & Chemicals P Ltd",
  "Kyna Pharmaceuticals",
  "Kyore Nutritions",
  "Kyorin Co Ltd",
  "Kyusep Healthcare Pvt Ltd",
  "L G Pharmaceuticals",
  "L K Medicare",
  "L R Healthcare Pvt Ltd",
  "L S Herbals(India)",
  "L T & L Pharma Pvt Ltd",
  "L T T",
  "L V Life Sciences",
  "L W E Care Pharmaceuticals",
  "L&M Pharmaceutical Pvt Ltd",
  "La Elegancia International LLP",
  "LA Grande Pvt Ltd",
  "La Herbal (india)",
  "La Med India",
  "La Nutraceuticals",
  "LA Oister Pharma Pvt Ltd",
  "LA Organo",
  "La Penser Life Sciences",
  "La Pharmaceuticals",
  "La Piel Biotech Pvt Ltd",
  "La Pristine Bioceuticals",
  "La Pristine Bioceuticals Pvt Ltd",
  "La Renon -Gen",
  "La Renon Health Care Pvt Ltd",
  "La Renon Health Care Pvt Ltd(Special)",
  "La Renon Healthcare Pvt Ltd",
  "La Revas Pharma Pvt Ltd",
  "La Roche Posay",
  "La Swiss Chocolat",
  "La Vis Medlins",
  "Laafon Galaxy Pharmaceuticals",
  "Labcorp India Pvt Ltd",
  "Labex K K International",
  "Labinduss Ltd",
  "Labios Pharmaceutical Pvt Ltd",
  "Laborate (Generic Division)",
  "Laborate Pharmaceuticals India Ltd",
  "Labrada Nutrition",
  "Lacip Healthcare",
  "Laclin Pharma Pvt Ltd",
  "Laclin Pharmaceuticals",
  "Lacqure Healthcare",
  "Lactonova Nutripharm Pvt Ltd",
  "Laenor Pharmaceuticals",
  "Lafiya Biotech",
  "Lahar Pharmaceuticals",
  "Lahmu Pharma",
  "Lairis Healthcare Pvt Ltd",
  "Laissez Faire Pharmaceuticals Pvt Ltd",
  "Lakarez Pharmaceuticals Pvt Ltd",
  "Lakavish Nutratech Pvt Ltd",
  "Lakeland Industries",
  "Lakshmi Traders",
  "Lakshya Life Sciences",
  "Lakshya Pharma Pvt Ltd",
  "Lakshya Remedies Pvt Ltd",
  "Lakssha Pharmaceuticals",
  "Laksun Lifesciences",
  "Lama Pharmaceuticals",
  "LaManeraa Retail Pvt Ltd",
  "La-med Healthcare Pvt Ltd",
  "Lamet Pharma Pvt Ltd",
  "Lamiaceae Healthcare",
  "Lamicon Pharma Pvt Ltd",
  "Lamium Pharmaceuticals",
  "Lan Pharmachem",
  "Lanark Laboratories Pvt Ltd",
  "Lance Biotech",
  "Lancer Health Care Pvt Ltd",
  "Lancer Pharma",
  "Lancer Pharmaceuticals Pvt Ltd",
  "Lancer Therapeutics",
  "Lanchestor Biotech",
  "Lap Farmacia Ltd",
  "Laplant Beverages Pvt Ltd",
  "Lapo Pharma",
  "Largos Healthcare Pvt Ltd",
  "Larion Life Sciences Pvt Ltd",
  "Lariox Biogenics Pvt Ltd",
  "Larizona Pharmaceutical Pvt Ltd",
  "Lark Laboratories Ltd",
  "Larsan Healthcare",
  "Larsen Formulations Pvt Ltd",
  "Last Forest Enterprise Pvt Ltd",
  "Latika Biotech Pvt Ltd",
  "Latin Labs Pvt Ltd",
  "Latitudes Pharmaceuticals Pvt Ltd",
  "Latmad Pharmaceuticals",
  "Laura Life Sciences",
  "Laurence Healthcare Pvt Ltd",
  "Laures Pharmaceuticals Pvt Ltd",
  "Laurus Labs Ltd",
  "Lavanir Life Sciences",
  "Lavanya Biotech",
  "Laven Pharma India Pvt Ltd",
  "Lavender Pharma",
  "Lavie Lifesciences",
  "Lavincita Lifesciences Pvt Ltd",
  "Lavish Biotech",
  "Laviska Nutrition",
  "Lavue Pharmaceuticals",
  "Laxian Healthcare",
  "Laxmi Industries",
  "Laxter Pharmaceutical Pvt Ltd",
  "Lazarus Healthcare",
  "LCB Pharma",
  "LDD Bioscience Pvt Ltd",
  "Le Corps Pharma",
  "Le Delicio Foods",
  "Le Phlox Healthcare Pvt Ltd",
  "Le Tyche Pharmaceuticals Pvt Ltd",
  "Lead Care International",
  "Lead Star Pharmaceuticals",
  "Leadlife Formulations",
  "Leads Pharma Pvt Ltd",
  "Leaf Roots Organic",
  "Leben Laboratories Pvt Ltd",
  "Leben Life Sciences Pvt Ltd",
  "Lebon Pharmaceuticals",
  "Lebone Healthcare Pvt Ltd",
  "Lecent Healthcare Pvt Ltd",
  "Lecord Pharmaceuticals",
  "Lee Benz Life Science",
  "Lee Chem Biotech Pvt Ltd",
  "Lee Morten Life Sciences Pvt Ltd",
  "Lee Pharma Ltd",
  "Leeford Health Care Ltd",
  "Leeford Healthcare Ltd",
  "Leeford Healthcare Ltd(Generics)",
  "Leegaze Pharmaceuticals Pvt Ltd",
  "Leegenic Pharmaceuticals",
  "LeeMed Pharmaceuticals",
  "Leenate Pharma Pvt Ltd",
  "Leenix Pharmaceuticals",
  "Leewell Pharmaceuticals Pvt Ltd",
  "Lefiable Healthcare Pvt Ltd",
  "Lefrizent Pharmaceutical",
  "Legen Healthcare",
  "Legend Advanced Biomedicinals",
  "Legend Pharma Technologies",
  "Legend Pharmaceutical",
  "Legrand Pharmaceuticals",
  "Leitalus Biotech Pvt Ltd",
  "Lekar Pharma Ltd",
  "Leksel Pharmaceuticals",
  "Lemark Remedies",
  "Lembda Healthcare",
  "Lembos Pharmaceuticals LLP",
  "Lemford Biotech Pvt Ltd",
  "Lenimen Biotech",
  "Lenit Pharmaceuticals",
  "Lenvitz Medical Solutions Pvt Ltd",
  "Leo Formulations Pvt Ltd",
  "Leo Life Science Pvt Ltd",
  "Leo Pharmaceuticals",
  "Leo Star Healthcare Pvt Ltd",
  "Leogard Pharmaceuticals Pvt Ltd",
  "Leogen Biotics Pvt Ltd",
  "Leogenic Healthcare Pvt Ltd",
  "Leopold Healthcare LLP",
  "Lepidus Pharma India Pvt Ltd",
  "Lerasa Pharmaceuticals Pvt Ltd",
  "Lesanto Laboratories",
  "Lesstrol K",
  "Letouch Pharma",
  "Leucine Rich Bio Pvt Ltd",
  "Leut Healthcare Pvt Ltd",
  "Levanza Food and Herbals",
  "Levanza Pharma Pvt Ltd",
  "Levenr Pharma",
  "Levent Biotech Pvt Ltd",
  "Levin Health Care",
  "Levitas Pharmaceuticals Pvt Ltd",
  "Lewis Bioserch Pharma Pvt Ltd",
  "Lexa Labs",
  "Lexaro Healthcare",
  "Lexica Drugs & Formulations Pvt Ltd",
  "Lexicon Biotech India Ltd",
  "Lexiphar Healthcare",
  "Lexus Organics",
  "Lezaa Biotech",
  "Lezire Healthcare Pvt Ltd",
  "Lezivia Life Sciences",
  "LFC Pvt Ltd",
  "LG Lifesciences",
  "Lia Life Sciences Pvt Ltd",
  "Liaise Pharmaceuticals Pvt Ltd",
  "Libra Drugs India",
  "Libramed Pharmaceuticals Pvt Ltd",
  "Lichem Pharmaceuticals",
  "Lico life sciences",
  "Life Care Biotec",
  "Life Care Pharmaceuticals Pvt Ltd",
  "Life Care Pvt Ltd",
  "Life Extension",
  "Life Extension Healthcare",
  "Life Gain Pharma",
  "Life Guard Pharma",
  "Life Line Biotech Ltd",
  "Life Line Pharma",
  "Life Medicare & Biotech Pvt Ltd",
  "Life Organics Bpl",
  "Life Pharma",
  "Life Pharmaceuticals Pvt Ltd",
  "Life Science Bio-Tech India Pvt Ltd",
  "Life Science Pharmaceuticals Pvt Ltd",
  "Life Sciences Inc",
  "Life Style Foods Pvt Ltd",
  "Life Vision Medicare Pvt Ltd",
  "Lifeaura Pvt Ltd",
  "LifeCare Formulation Pvt Ltd",
  "Lifecare Innovations Pvt Ltd",
  "Lifecare Neuro Products Ltd",
  "Lifecare Pharma Pvt td",
  "Lifecatch Pharma",
  "Lifecom Pharmaceuticals Pvt Ltd",
  "Lifecred Healthcare Pvt Ltd",
  "Lifegrace Pharmaceuticals Pvt Ltd",
  "Lifejoy Healthcare",
  "Lifekyor Pharmaceuticals",
  "Lifeline Antidotes Pvt Ltd",
  "Lifeline Biotech Ltd",
  "Lifeline Formulations Pvt Ltd",
  "Lifeline Health Care",
  "Lifeline Remedies India Pvt Ltd",
  "Lifelink Pharma",
  "Lifelong Online Retail Pvt Ltd",
  "Lifeon Paediatrics Ltd",
  "Lifeon Pharmaceuticals",
  "Lifepure Labs Pvt Ltd",
  "Lifesavers Remedies",
  "Lifescan Medical Devices India Pvt Ltd",
  "Lifeserve Med And Care Pvt Ltd",
  "Lifestar Pharma Pvt Ltd",
  "Lifestyle Bio Sciences Dermacare",
  "Lifestyle Studio",
  "LifeSurge BioSciences Pvt Ltd",
  "Lifevision Healthcare",
  "Lifezen Healthcare Pvt Ltd",
  "Lifezest Pharmaceutical Pvt Ltd",
  "Lift Life Biotech Pvt Ltd",
  "Liger Life Links (OPC) Pvt Ltd",
  "Light of Life Pharmaceuticals",
  "Likameda Pharmaceuticals Pvt Ltd",
  "Likesol Pharma Pvt Ltd",
  "Lilania Medicorp India Pvt Ltd",
  "Limbson Pharmaceuticals LLP",
  "Limcon Healthcare",
  "Limra Pharmaceuticals",
  "Linchpin Biosciences Pvt Ltd",
  "Lincoln Pharmaceuticals Ltd",
  "Linctus Healthcare",
  "Linctus Pharma Pvt Ltd",
  "Liner Pharma",
  "Linest Pharma LLP",
  "Linex Pharmaceuticals",
  "Linnet Life Sciences",
  "Linnium Pharma Pvt Ltd",
  "Linus Life Sciences Pvt Ltd",
  "Linux Laboratories",
  "Linux Laboratories Pvt Ltd",
  "Lioncrown India",
  "Liosun Pharmaceuticals India Pvt Ltd",
  "Lippincotts Pharmaceuticals",
  "Lippon Pharma Pvt Ltd",
  "Lipur Pharmaceuticals Pvt Ltd",
  "Liras Pharmaceutical LLP",
  "Lister Life Sciences",
  "Lister Surgical Co",
  "Litaka Pharmaceuticals Ltd",
  "Litstick International",
  "Little Greave Pharmaceutical Pvt Ltd",
  "Liv Bio Pharma",
  "Liva Health Care Ltd",
  "Liva Healthcare Ltd",
  "Live Pharma",
  "Live Well Inc ",
  "Livealth Biopharma Pvt Ltd",
  "Livebasil Overseas Pvt Ltd",
  "Liveon Healthcare Pvt Ltd",
  "Livewort Labs Pvt Ltd",
  "Livia Healthcare LLP",
  "Livia Life Sciences",
  "Lividus Pharmaceuticals Pvt Ltd",
  "Livinguard Technologies Pvt Ltd",
  "LivistaLife Healthcare India",
  "Livlong Nutraceuticals Ltd",
  "Livmed Pharmaceutical Pvt Ltd",
  "Livo Universal",
  "Livon Pharma",
  "Livvel Wellness Solutions Pvt Ltd",
  "Liza Life Sciences",
  "Lloyd Healthcare Pvt Ltd",
  "Lloyod Healthcare Pvt Ltd",
  "Lmn Trix Pharma Pvt Ltd",
  "LMR Pharma Pvt Ltd",
  "Lnr Pharma",
  "Loban Pharmaceuticals Pvt Ltd",
  "Loco Healthcare Pvt Ltd",
  "Locus Pharma Pvt Ltd",
  "Loee Bobvn",
  "Log 9 Materials",
  "Logimed Pharma Pvt Ltd",
  "Login Pharma Pub Pvt Ltd",
  "Logos Pharma",
  "Lohmann and Rauscher India",
  "Lok Beta Pharmaceuticals Pvt Ltd",
  "Loki Inc ",
  "Loquat Healthcare Ltd",
  "Lorance Pharma",
  "Lord's Homoeopathic Laboratory Pvt Ltd",
  "L'Oreal India Pvt Ltd",
  "LOreal India Pvt Ltd",
  "Loremchem Pharmaceutical",
  "Lotus Herbals Color Cosmetics",
  "Lotus Life Sciences",
  "Louies Life Sciences",
  "Louis and Clark Pharmaceutical",
  "Louis Matrix Gmbh",
  "Lovis Laboratories Pvt Ltd",
  "Lowkal Healthcare Pvt Ltd",
  "LP Support",
  "LSD Phrmaceutical",
  "Lucan Pharmaceuticals Pvt Ltd",
  "Lucent Biotech Ltd",
  "Lucent Pharma",
  "LuceVida Pharmaceuticals Pvt Ltd",
  "Lucid Pharmaceuticals",
  "Lucida Pharmaceuticals Pvt Ltd",
  "Lucidus Pharmaceuticals Pvt Ltd",
  "Lucifer and Hesper India Pvt Ltd",
  "Luckys Pharma",
  "Luco Pharmaceuticals",
  "Lucrose Pharma Pvt Ltd",
  "Luhar Pharma",
  "Lumaire Labs Pvt Ltd",
  "Lunas Remedies",
  "Lundbeck India Pvt Ltd",
  "Lupichem Healthcare",
  "Lupin (Maxter)",
  "Lupin (Mind Vision)",
  "Lupin Lab Ltd",
  "Lupin Laboratories Ltd",
  "Lupin Laboratories Ltd(Spl)",
  "Lupin Ltd",
  "Lupin Pharma",
  "Lupin Pharma (Endeavour)",
  "Lupin Pharma (Pharma)",
  "Lupin Pharma (Pinnacle Cvn)",
  "Lupin Pharma (Pinnacle Diabet)",
  "Lupin Pharma (Pinnacle)",
  "Lupin Pharma (Senior 1)",
  "Lupin Pharma Ltd",
  "Lupitas Pharmaceuticals Pvt Ltd",
  "Lupix Laboratories",
  "Lurex Healthcare Pvt Ltd",
  "Luscious derma",
  "Lusian Pharmaceuticals Pvt Ltd",
  "Lustre Pharmaceuticals Pvt Ltd",
  "Luxica Pharma Inc ",
  "Luxor Nano Technology Pvt Ltd",
  "Luxurious Ayurvedic Gold",
  "Luzan Pharma",
  "Luziac Life Sciences",
  "Lwe Care Pharmaceuticals Pvt Ltd",
  "Lxir Medilabs Pvt Ltd",
  "Lxmani Pharmaceuticales Pvt Ltd",
  "Lxora Life Sciences",
  "Lyceum Life Sciences Pvt Ltd",
  "Lycus Pharma Pvt Ltd",
  "Lyf Healthcare",
  "Lyk Pharmaceuticals Pvt Ltd",
  "Lyka Hetero Healthcare Ltd",
  "Lyka Labs",
  "Lym Nutrition Pvt Ltd",
  "Lynaz Pharma",
  "Lynderma Pharmaceuticals",
  "Lynova Pharmaceuticals",
  "Lypho Med Remedies Pvt Ltd",
  "Lyra Healthcare",
  "Lyra Laboratories Pvt Ltd",
  "Lyric Pharmaceuticals Pvt Ltd",
  "Lyrid Healthcare Pvt Ltd",
  "Lyrik Pharma",
  "Lyrus Life Sciences Pvt Ltd",
  "Lyssa Healthcare Pvt Ltd",
  "Lystra Pharma",
  "Lyza Laboratories India Pvt Ltd",
  "M & B Laboratories",
  "M 2 Life Science",
  "M A S Pharmaceuticals Pvt Ltd",
  "M And B Labs",
  "M D Homoeo Lab Pvt Ltd",
  "M D Pharmaceuticals Pvt Ltd",
  "M F B Herbal Dawakhana",
  "M Frank Pharmaceuticals",
  "M G Foods",
  "M G Pharmaceutical",
  "M K G Formulation",
  "M K Industry",
  "M K Pharma",
  "M K V International",
  "M M A F Pharma Pvt Ltd",
  "M M Labs",
  "M M Pharma",
  "M M S Enterprises",
  "M R",
  "M R S Pharmaceuticals",
  "M S ",
  "M S Pharma",
  "M U Amrelia",
  "M/s Chemkart",
  "M/S Greyfawn Healthcare",
  "M/S Pmhr Services Pvt Ltd",
  "M/S Shakti Oils & Fats",
  "M/S Tara Herbal Pharmaceutical",
  "M/S Vinayak Enterprises",
  "M2H Pharmaceuticals",
  "Ma Lara Healthcare Pvt Ltd",
  "Maa Enterprise",
  "Maa Formulations Pvt Ltd",
  "Maaah Pharmaceuticals Pvt Ltd",
  "Maan Pharmaceuticals Ltd",
  "Maans Products",
  "Maarvis Biotech Pvt Ltd",
  "Maaxmus Life Sciences Pvt Ltd",
  "Mabril Healthcare Pvt Ltd",
  "Mac Laboratories Ltd",
  "MAC Medilife Pvt Ltd",
  "Mac Millon Pharmaceuticals Pvt Ltd",
  "Mac Organics",
  "Mac Remedies Pvt Ltd",
  "MAC Vision",
  "Macaria Cosmetics Pvt Ltd",
  "Macdev Pharma",
  "Macduff Pharma P Ltd",
  "Machen Pharma Ltd",
  "Mackenzie Pharmaceuticals Pvt Ltd",
  "Maclan Pharmaceuticals Pvt Ltd",
  "Macleods Pharma P Ltd",
  "Macleods Pharma P Ltd(Acuphar)",
  "Macleods Pharma P Ltd(Cv)",
  "Macleods Pharma P Ltd(Macphar)",
  "Macleods Pharma P Ltd(Procare)",
  "Macleods Pharma P Ltd(Tb Care)",
  "Macleods Pharmaceutical",
  "Macleods Pharmaceuticals",
  "Macleods Pharmaceuticals Ltd",
  "Macleods Pharmaceuticals P Ltd",
  "Macleods Pharmaceuticals P Ltd(Procare)",
  "Macleods Pharmaceuticals Pvt Ltd",
  "Maclife Pharmaceutical Pvt Ltd",
  "Macmay Pharmaceuticals Pvt Ltd",
  "Mac-Ris Pharma",
  "Macro Labs Pvt Ltd",
  "Macro Pharmaceuticals",
  "Macrolife Naturals",
  "Macromed Pharmaceuticals Pvt Ltd",
  "Macron Biotech Pvt Ltd",
  "Macsis Life Sciences",
  "Macsur Pharmaa (India) Pvt Ltd",
  "Macsys LifeSciences",
  "Macter Pharma",
  "Mactif Pharma",
  "Macto Mediscience Pvt Ltd",
  "Macved Pharmaceuticals",
  "Macway Biotech Pvt Ltd",
  "Macwell Pharmaceuticals",
  "Macwin Pharma",
  "Madbris Life Sciences Pvt Ltd",
  "Made-inn Healthcare Pvt Ltd",
  "Madhav Biotech Pvt Ltd",
  "Madhav Pharma",
  "Madhav Traders",
  "Madhu Jayanti International Pvt Ltd",
  "Madhuram Ayurveda",
  "Madhusudan Health Care",
  "Madlyn Biotech",
  "Madoc Pharmaceuticals Pvt Ltd",
  "Madon Life",
  "Madras Pharma",
  "Madren Healthcare LLP",
  "Maerr Healthcare",
  "Maestro Healthcare Pvt Ltd",
  "Magbro Healthcare Pvt Ltd",
  "Magique Lifestyle LLP",
  "Magma Allianz Laboratories Ltd",
  "Magna Formulations",
  "Magna Marketing",
  "Magnachem Pharmaceuticals Pvt Ltd",
  "Magnate Multi Products Venture Pvt Ltd",
  "Magnet Labs Pvt Ltd",
  "Magnet Labs Pvt Ltd(Mankind)",
  "Magnifur Life Sciences",
  "Magnovia Pharmaceutical Pvt Ltd",
  "Magnum Health and Safety Pvt Ltd",
  "Magnum Laboratories Pvt Ltd",
  "Magnum Pharmaceuticals",
  "Magnus Biotech Pvt Ltd",
  "Magnus Pharma Pvt Ltd",
  "Magnus Vaidoherba",
  "Magpie Labs Pvt Ltd",
  "Magus Medicare",
  "Mahadev Life Sciences",
  "Mahadev Pharmaceuticals",
  "Mahaja Pharmacare Pvt Ltd",
  "Maharishi Ayurveda Products Pvt Ltd",
  "Mahaved Healthcare",
  "Mahawat Healthcare Pvt Ltd",
  "Mahendra Labs Pvt Ltd",
  "Maheshwari Ayurved Pvt Ltd",
  "Maheshwari Food",
  "Maheshwari Pharmaceuticals Ltd",
  "Mahhaguru Navgrah Pvt Ltd",
  "Mahima Trends",
  "Maiden Pharmaceutical Ltd",
  "Maitri Healthcare Pvt Ltd",
  "Majestic Healthcare",
  "Major Biosystem Copr ",
  "Mak Pharmaceuticals",
  "Mak Well Healthcare Opc Pvt Ltd",
  "Makcur Laboratories Ltd",
  "Make Life Pharmaceutical",
  "Make Sun Lifescience",
  "Makeasy Global LLP",
  "Makeown Pharma Pvt Ltd",
  "Makers Laboratories Ltd",
  "Makeway Formulations Pvt Ltd",
  "Makewell Pharmaceutical Company",
  "Makmaul Labocare",
  "Maknaam Chemicals",
  "Maksal Pharmaceuticals (P) Ltd",
  "Makshi Healthcare",
  "Maksun Biotech Pvt Ltd",
  "Malachite Gold Laboratories Pvt Ltd",
  "Malar Healthcare",
  "Malcom Pharmaceuticals",
  "Malens Pharmaceuticals Pvt Ltd",
  "Mali Farms",
  "Malladi Drugs & Pharmaceuticals Ltd",
  "Malliot Pharmaceutical",
  "Malody Healthcare Ltd",
  "Malook Pharmaceuticals Pvt Ltd",
  "Malu Global Consumer Products Pvt Ltd",
  "Malvern Pharma",
  "Mamta Pharmaceuticals",
  "Man Serve Pharma",
  "Mana Medical",
  "Manas Pharmaceuticals Pvt Ltd",
  "Mancare Health Pvt Ltd",
  "Mancare Labs Pvt Ltd",
  "Mancare Remedies Pvt Ltd",
  "Manchanda Enterprise",
  "Mancop Remedies",
  "Mancure Drugs Pvt Ltd",
  "Mand Health Solution",
  "Mandar Pharmaceuticals Pvt Ltd",
  "Mandevis Pharma",
  "Mandlive Healthcare Pvt Ltd",
  "Maneesh Pharmaceuticals Ltd",
  "Manfriday Lifesciences Pvt Ltd",
  "Manig Pharma",
  "Maniph Life Sciences",
  "Manish Pharma Lab",
  "Manisha International Pvt Ltd",
  "Manith Healthcare Pvt Ltd",
  "Mankastu Impex Pvt Ltd",
  "Mankind Pharma Ltd",
  "Mankind Pharma P L (Curis Mankind)",
  "Mankind Pharma P L (Life Mankind)",
  "Mankind Pharma P L (Nobelis Mankind)",
  "Mankind Pharma P L(Discovery)",
  "Mankind Pharma P Ltd",
  "Mankind Pharma P Ltd( Future)",
  "Mankind Pharma P Ltd(3D Mankind)",
  "Mankind Pharma P Ltd(Aspiris Mankind)",
  "Mankind Pharma P Ltd(Curis Mankind)",
  "Mankind Pharma P Ltd(Discovery)",
  "Mankind Pharma P Ltd(Dsk Mankind)",
  "Mankind Pharma P Ltd(Future)",
  "Mankind Pharma P Ltd(Gravitas)",
  "Mankind Pharma P Ltd(Life Mankind)",
  "Mankind Pharma P Ltd(Lifestar)",
  "Mankind Pharma P Ltd(Magnet)",
  "Mankind Pharma P Ltd(Nobelis Mankind)",
  "Mankind Pharma Pvt Ltd",
  "Mankind Pharma Pvt Ltd(3D Mankind)",
  "Mankind Pharma Pvt Ltd(Curis Mankind)",
  "Mankind Pharma Pvt Ltd(Discovery)",
  "Mankind Pharma Pvt Ltd(Future)",
  "Mankind Pharma Pvt Ltd(Life Mankind)",
  "Mankind Pharma Pvt Ltd(Nobelis Mankind)",
  "Mankind Pharma Pvt Ltd(Otc)",
  "Mankind Pharma Pvt Ltd(Pharma)",
  "Mankind Pharmaceuticals Ltd",
  "Mankind Pharmaceuticals Pvt Ltd",
  "Mankind Pharmaceuticals Pvt Ltd(Dsk Mankind)",
  "Manlac Pharma",
  "Mano Pharma Pvt Ltd",
  "Mano Pharmaceuticals Pvt Ltd",
  "Manojava Lifescience Pvt Ltd",
  "Mansa Medi Impex",
  "Mansin Pharma",
  "Mansu Biotech Pvt Ltd",
  "Manthan Pharma Pvt Ltd",
  "Mantra Herbal",
  "Mantri Pharma",
  "Manuka Health",
  "Manum Healthcare Pvt Ltd",
  "Map Pharmaceuticals Pvt Ltd",
  "Mapex Labs Pvt Ltd",
  "Maple Biogenesis Pvt Ltd",
  "Mapra Laboratories Pvt Ltd",
  "Mapthon Pharmaceuticals Pvt Ltd",
  "Marc Laboratories Pvt Ltd",
  "Marc Pharma",
  "Marck Biosciences Ltd",
  "Marclay Laboratories Pvt Ltd",
  "Marcus Pharma",
  "Mareena Pharma",
  "Marico Ltd",
  "Marigold Remedies",
  "Marine Lifescience",
  "Marion Biotech",
  "Marisol Pharmaceuticals Pvt Ltd",
  "Mark (India) Cosmed Pvt Ltd",
  "Mark India",
  "Mark Remedies Pvt Ltd",
  "Markecho Pharmaceuticals Pvt Ltd",
  "Marma Ayurveda Pvt Ltd",
  "Marquess Genetica Pharmaceuticals Pvt Ltd",
  "Mars Aventis Lifecare",
  "Mars International India Pvt Ltd",
  "Mars Life Sciences Ltd",
  "Mars Remedies Pvt Ltd",
  "Mars Therapeutics & Chemicals Ltd",
  "Marsai Pharma Pvt Ltd",
  "Marsha Pharma Pvt Ltd",
  "Martand Life Care Pvt Ltd",
  "Martel Hammer Pharmaceuticals Pvt Ltd",
  "Martia Laboratories",
  "Martin & Brown Biosciences",
  "Martin & Harris Pvt Ltd",
  "Martine Healthcare",
  "Martinet Pharma Pvt Ltd",
  "Martyn Nikk Pharma Pvt Ltd",
  "Marwar Enterprises",
  "Mas Healthcare Pvt Ltd",
  "Mas Life Science",
  "Masaba Healthseries",
  "Masaya Pharmaceuticals Pvt Ltd",
  "Mascon Healthcare Pvt Ltd",
  "Mascot Biotech",
  "Mascot Biotech (India) Pvt Ltd",
  "Mascot Health Series P L",
  "Mascot Health Series Pvt Ltd",
  "Mask Life Sciences",
  "Masmed Medical Device",
  "Masolin Herbal Pvt Ltd",
  "Massive pharma Pvt Ltd",
  "Masson Healthcare Pvt Ltd",
  "Masstige Pharma Pvt Ltd",
  "Master Doctor",
  "Mastermind Pharma",
  "Masvik Pharmaceuticals Pvt Ltd",
  "Mateo Lifesciences",
  "Mateo Lifesciences Pvt Ltd",
  "Matha Surgical Pvt Ltd",
  "Mathis Pharma",
  "Matias Healthcare Pvt Ltd",
  "Matilda Pharmaceuticals (india) Pvt Ltd",
  "Matins Pharma",
  "Matirock Lifescience",
  "Matish Healthcare",
  "Matrix Cellular (International) Services Ltd",
  "Matrix Healthcare",
  "Matrix Nutrition",
  "Matrix Pharma Chem",
  "Matrix Surgical Industries",
  "Matrixx Formulation",
  "Matrubhumi Chemical (opc) Pvt Ltd",
  "Matteo Healthcare Pvt Ltd",
  "Mattox Healthcare Pvt Ltd",
  "Maulik Remedies",
  "Mauline India Pvt Ltd",
  "Maverick Pharma Pvt Ltd",
  "Mavgit Pvt Ltd",
  "Mavin Pharmaceuticals",
  "Mavis Lifesciences",
  "Maviva Wellcare Pvt Ltd",
  "Max Ayurveda",
  "Max India Ltd",
  "Max Life Care Pvt Ltd",
  "Max Life Science",
  "Max Medikit Labs Pvt Ltd",
  "Max Muscle Sports Nutrition",
  "Max Nodaz Life Sciences",
  "Max pharma",
  "Maxamus Pharma Pvt Ltd",
  "Maxbro Health Care Pvt Ltd",
  "Maxbro Healthcare Pvt Ltd",
  "Maxcare Lifesciences",
  "Maxcare Pharmaceutical Pvt Ltd",
  "Maxcure Nutravedics Ltd",
  "Maxcure Pharmaceuticals",
  "Maxell Pharma Pvt Ltd",
  "Maxford Healthcare",
  "Maxford Labs Pvt Ltd",
  "Maxheal Pharmaceuticals (India) Ltd",
  "Maxichem Healthcare Pvt Ltd",
  "Maximaa Proyurveda",
  "Maximax Healthcare",
  "Maximon Pharma Pvt Ltd",
  "Maxinique Solution LLP",
  "Maxis Healthcare",
  "Maxis Life Sciences",
  "Maxlive Pharmaceuticals Pvt Ltd",
  "Maxmed Health Care Pvt Ltd",
  "Maxmed Lifesciences Pvt Ltd",
  "Maxmin Pharma",
  "Maxmo Pharmaceuticals",
  "Maxnova Healthcare",
  "Maxo Chem Ltd",
  "Maxolus Pharmaceuticals",
  "MaxQure Labs",
  "MaxRelief Pharmaceuticals",
  "Maxtan Healthcare Ltd",
  "Maxter Medical",
  "Maxton Life Sciences",
  "Maxx and Nad Care Pvt Ltd",
  "Maxx Farmacia (India) Lip",
  "Maxx Farmacia (India) LLP",
  "Maxx Farmacia India LLP",
  "Maxxlife Formulation",
  "Maxzimaa Pharmaceuticals Pvt Ltd",
  "May and Baker Pharmaceuticals Ltd",
  "Maya Drugs & Antibiotics",
  "Mayflower India",
  "MayGriss Healthcare Pvt Ltd",
  "Mayo Remedies",
  "Maypharm Lifesciences",
  "Mayu Health Care",
  "Mayu Healthcare",
  "Mayur Laboratories Pvt Ltd",
  "Mazin Pharma",
  "MB Plastic Industries",
  "MB2 Medicare",
  "Mba Pharmaceuticals Pvt Ltd",
  "Mbco Lifesciences Pvt Ltd",
  "Mblaze Pharmaceuticals",
  "Mbp Pharmaceuticals Pvt Ltd",
  "MBS Formulation",
  "MBWorld Exim Pvt Ltd",
  "Mc Medibio Life Science Pvt Ltd",
  "MC Stan Lifesciences",
  "Mcastro Pharma",
  "Mcbrex Lifesciences",
  "Mccoy Laboratories",
  "Mclain Laboratories",
  "Mclaine Pharmaceutical",
  "Mcmewa Pharma",
  "Mcneil & Argus Pharmaceuticals Ltd",
  "McNeil Nutritionals LLC",
  "Mcnroe Consumer Products Pvt Ltd",
  "McRiyavin Bio-Research",
  "Mcronus Lifescience Pvt Ltd",
  "McW Healthcare",
  "MDC Pharmaceuticals Pvt Ltd",
  "MDF Instruments",
  "Me n Moms Pvt Ltd",
  "mea ame",
  "Mead Johnson & Company",
  "Meb Pharma",
  "Mebio Labs Pvt Ltd",
  "Mebita Pharmaceuticals Pvt Ltd",
  "Mebo Pharmaceutical",
  "Mecado Healthcare Pvt Ltd",
  "Mecartus Health Care Pvt Ltd",
  "Mech Pharmaceuticals",
  "Meckley Life Sciences Pvt Ltd",
  "Meconus Healthcare Pvt Ltd",
  "Mecoson Labs Pvt Ltd",
  "Mecure Pharma",
  "Med Express",
  "Med Lex Pharmaceutical",
  "Med Manor (Pedia)",
  "Med Manor Organics Pvt Ltd",
  "Med Revive Biotec LLP",
  "Medarmi Biophar Pvt Ltd",
  "Medaure Health Care",
  "Medayo Healthcare",
  "Medbaxy Healthcare Pvt Ltd",
  "Medbeat Healthcare",
  "Med-Biologicals Pvt Ltd",
  "Medbionic Healthcare",
  "Medbiosquid Pharmaceuticals Pvt Ltd",
  "Medburg Medical Products Pvt Ltd",
  "Medcalifornia Scientific Pvt Ltd",
  "Medchem International Ltd",
  "Medclear Healthcare Pvt Ltd",
  "Medcliff Pharmaceuticals Pvt Ltd",
  "Medcoliv LifeSciences Pvt Ltd",
  "Medconic Healthcare",
  "Medcorpus Pharmaceuticals",
  "Medcover Pharmaceuticals (OPC) Pvt Ltd",
  "Medcover Pharmaceuticals OPC Pvt Ltd",
  "Medcure Organics Pvt Ltd",
  "Medcure Pharma",
  "Meddox Formulations",
  "Meddox Pharma Pvt Ltd",
  "Meddroid Pharmaceuticals Pvt Ltd",
  "Medease Healthcare Pvt Ltd",
  "Meded Inc",
  "Medela India Pvt Ltd",
  "Medencia Lifesciences",
  "Medens Pharmaceutical Pvt Ltd",
  "Medequip Healthcare Solution LLP",
  "Medethics Pharmaceuticals Pvt Ltd",
  "Medevac Lifesciences Pvt Ltd",
  "Medevolution Devices Pvt Ltd",
  "Medex India Pvt Ltd",
  "Medexia Healthcare",
  "Medfence Labs",
  "Medflex Healthcare Pvt Ltd",
  "Medflix Pharma Pvt Ltd",
  "Medflow Biocare",
  "Medflux Pharmaceuticals Pvt Ltd",
  "Medford Pharma",
  "Medford Pharmaceuticals",
  "Medg Pharma",
  "Medgen Drugs And Laboratories Pvt Ltd",
  "Medgenix Pharma India Pvt Ltd",
  "Medgenova Pharma Pvt Ltd",
  "Medglobe Biotek Pvt Ltd",
  "Medglobe Lifesciences Pvt Ltd",
  "Medgrow Health Care",
  "MedHeal Pharmaceuticals",
  "Medhexa Pharma",
  "Medi Biotech",
  "Medi Biotech Pvt Ltd",
  "Medi Cartel",
  "Medi Dot In Pharma",
  "Medi Globe",
  "Medi Heal Pvt Ltd",
  "Medi Karma",
  "Medi Marathon Pharma Pvt Ltd",
  "Medi Organics",
  "Medi Products Pvt Ltd",
  "Medi Surge Impex",
  "Mediaim Pharmaceutical",
  "Medial Pharma Ltd",
  "Mediarch Healthcare Pvt Ltd",
  "Mediarka Lifesciences Pvt Ltd",
  "Mediart Life Sciences Pvt Ltd",
  "Mediart Lifesciences Pvt Ltd",
  "Mediassure Healthcare Pvt Ltd",
  "Medibest Pharma Pvt Ltd",
  "Medibless Pharma Pvt Ltd",
  "Mediblink Pharma",
  "Mediblis Healthcare Pvt Ltd",
  "Medibluence Pharmaceuticals LLP",
  "Mediboon Pharma Pvt Ltd",
  "Mediborn Pharmaceuticals",
  "Medic Biologicals And Pharmaceuticals Pvt Ltd",
  "Medic Forge Healthcare Pvt Ltd",
  "Medic Pharma",
  "Medicaid Labs LLP",
  "Medical Science And Therapy Farma",
  "Medicaman Biotech Ltd",
  "Medicamen Biotech Ltd",
  "Medicamen Lifesciences Pvt Ltd",
  "Medicamen Organics Ltd",
  "MedicaMento Pvt Ltd",
  "Medicant Lifesciences Pvt Ltd",
  "Medicare Pharma",
  "Medicare Remedies Pvt Ltd",
  "Medicasa Pharmaceutical",
  "Medicate Life Sciences",
  "Medichem (India) Pvt Ltd",
  "Medichem Pharmceuticals Ltd",
  "Medicil Healthcare",
  "Medicine & Kure",
  "Medicinum Healthcare Pvt Ltd",
  "Medicious Lab",
  "Medicis Lifesciences Pvt Ltd",
  "Medicita Pharma Pvt Ltd",
  "Mediclave India Healthcare",
  "Mediclone Health Care Pvt Ltd",
  "Mediclove Biotech Pvt Ltd",
  "Medico Healthcare",
  "Medico Lab",
  "Medico Life Science Pvt Ltd",
  "Medicom International Eyetech Pvt Ltd",
  "Medicon Health Care Pvt Ltd",
  "Medicot Lifesciences",
  "Medicowin Remedies (P) Ltd",
  "Medicraft Group",
  "Medicron Life Sciences Pvt Ltd",
  "Medicross Pharmaceuticals",
  "Medics Lifecare",
  "Medicsy Life Science Pvt Ltd",
  "Medicure Life Sciences India Pvt Ltd",
  "Medicure Lifesciences India Pvt Ltd",
  "Medicus Life Sciences",
  "Medicus Pharma",
  "Medicyte Pharmaceuticals Pvt Ltd",
  "Medidawa Technologies Pvt Ltd",
  "Mediday Healthcare Pvt Ltd",
  "Medieos Lifesciences LLP",
  "Mediez Pharma",
  "Medifaith Biotech",
  "Medifame Biotech",
  "Mediff Pharma",
  "Medifinity Healthcare Pvt Ltd",
  "Medifit Pharmaceuticals",
  "Medifizer Pharmaceuticals Pvt Ltd",
  "Mediforce Pharma",
  "Medifuel Pharma",
  "Mediganza Healthcare",
  "Medigen Life Sciences",
  "Medigenesis Pharma Pvt Ltd",
  "Mediglaxo Pharma",
  "Medi-Globe Corporation ",
  "Medigram Healthcare",
  "Medigreen Pharmaceuticals",
  "Medihands Pharmaceuticals",
  "Mediheal Remedies",
  "Medi-Health Biotech",
  "Medihealth Biotech Pvt Ltd",
  "Medihealth Lifesciences Pvt Ltd",
  "Medihub Pharma",
  "Medikem Pharmaceuticals",
  "Medikind Lifesciences Pvt Ltd",
  "Medil Healthcare",
  "Medilab India",
  "Medilance Healthcare",
  "Medilane Labs",
  "Medilead Pharmaceuticals",
  "Medileb Speaciality Laboratories Pvt Ltd",
  "Medilente Pharma Pvt Ltd",
  "Medilewis Biotech",
  "Medilief Biosciences",
  "Medilife Health Science(P)Ltd",
  "Medilife Health Sciences Pvt Ltd",
  "Medilife Healthcare",
  "Mediliva Healthcare Pvt Ltd",
  "Medilloyd Medicament Pvt Ltd",
  "Medilords Pharmaceuticals",
  "Medimark Biotech",
  "Medimax Pharma",
  "Medimind Drugs and Chemicals",
  "Medin Pharma Pvt Ltd",
  "Medinix Pharmaceutical Pvt Ltd",
  "Medinn Belle Herbal Care Pvt Ltd",
  "Medinova Healthcare Inc ",
  "Medinova Labs",
  "Medinza Biotech",
  "Medion Biotech Pvt Ltd",
  "Medios Laboratories Pvt Ltd",
  "Medipha India Laboratories",
  "Mediphar Lifesciences Pvt Ltd",
  "Mediplus Pharma",
  "Medipol Pharmaceuticals India Pvt Ltd",
  "Medipolis Healthcare",
  "Mediprio Pharmaceuticals pvt Ltd",
  "Mediqas Pharmaceuticals",
  "Mediquest Inc ",
  "Medirase Pharma",
  "Medirays Biotech Pvt Ltd",
  "Medirica Lifescience",
  "Medirin Pharmaceuticals Pvt Ltd",
  "Medirose drug & pharma",
  "Medisa Medilife",
  "Medisafe Global Solutions",
  "Medisalve Lifesciences",
  "Mediscon Health Care Pvt Ltd",
  "Mediscot Pharmaceutical",
  "Medisearch Laboratories (Bombay) Pvt Ltd",
  "Medishri Healthcare",
  "Medisist Drugs & Surgicals",
  "Medisky Pharmaceuticals",
  "Medismith Pharma Lab",
  "Medisoft Pharma",
  "Medispan Ltd",
  "Medisra Pharma Pvt Ltd",
  "Medissist Pharma Pvt Ltd",
  "Mediste Pharmaceuticals Pvt Ltd",
  "Medistream Biotech Pvt Ltd",
  "Medisun Pharma",
  "Medisurf Pharma",
  "Medisurge Health Care Pvt Ltd",
  "Mediswiss India",
  "Mediswiss India Pvt Ltd",
  "Medisync Pharma",
  "Medisynth Chemicals Pvt Ltd",
  "Medisys Biotech Pvt Ltd",
  "Meditech Pharma",
  "Meditech Pharma World",
  "Meditek India",
  "Meditek Lifesceinces Pvt Ltd",
  "Meditek Lifesciences Pvt Ltd",
  "Meditia Lab Pvt Ltd",
  "Meditive Healthcare",
  "Meditouch Wellness",
  "Meditrex Pharma",
  "Meditrina Biotech Pvt Ltd",
  "Meditrust Laboratories Pvt Ltd",
  "Meditune Healthcare Pvt Ltd",
  "Mediup Healthcare Pvt Ltd",
  "Medius Biotech",
  "Mediva Lifecare",
  "Medivalens Labs Pvt Ltd",
  "Medivaxia Pharma",
  "Mediverge Healthcare Pvt Ltd",
  "Medivision Pharm",
  "Medivista Lifesciences Pvt Ltd",
  "Medivolks Pharma",
  "Mediwart Pharmaceuticals LLP",
  "Mediwave Life Sciences Pvt Ltd",
  "Mediwell Laboratories",
  "Mediwill Life Sciences",
  "Mediwin Laboratories",
  "Mediwin Pharmaceuticals",
  "Mediwings Laboratories Pvt Ltd",
  "Mediwon Research & Healthcare Corp",
  "Mediwood Healthcare Pvt Ltd",
  "Mediword Healthcare",
  "Mediworld Pharma Pvt Ltd",
  "Medix Biocare",
  "Medix Laboratories",
  "Medixcel Pharmaceuticals Pvt Ltd",
  "Mediyork Pharma Pvt Ltd",
  "Medizen Labs Pvt Ltd",
  "Medizen Therapeutics Industries",
  "Medizia Biotech",
  "Medizova Pharmaceuticals",
  "Medkul Pharmaceuticals",
  "Medleaf Pharmaceuticals",
  "Medler Pharmaceuticals",
  "Medlex Biotechnics Pvt Ltd",
  "Medley Laboratories",
  "Medley Pharmaceutical Pvt Ltd",
  "Medley Pharmaceuticals",
  "Medley Pharmaceuticals Ltd",
  "Medley Pharmaceuticals P Ltd",
  "Medley Pharmaceuticals Pvt Ltd",
  "Medlife Genetics",
  "Medlife International Pvt Ltd",
  "Medlife Sciences Pvt Ltd",
  "Medline Industries, Inc",
  "Medlist Pharma Pvt Ltd",
  "Medlius Pharma Pvt Ltd",
  "Medliva Lifesciences",
  "Medlive Pharma Pvt Ltd",
  "Medlive Products",
  "Medlock Healthcare Pvt Ltd",
  "Medlogix Pharma Pvt Ltd",
  "Medmate Life Science Pvt",
  "Medmax Pharma",
  "Medmerus Life Sciences",
  "Medmex Health Care",
  "Medmidas Pharmaceuticals",
  "Medmom Pharmaceuticals LLP",
  "Medmore Pharma Pvt Ltd",
  "Medmyndrz Pharma",
  "Medna Biotech Pvt Ltd",
  "Mednic Healthcare",
  "Mednich Pharmaceuticals",
  "Mednova Laboratories",
  "Mednox Health Care",
  "Medo Chem Lab Pvt Ltd",
  "Medoc Pharmaceuticals Pvt Ltd",
  "Medocean Pharmaceuticals",
  "Medocyte Pharmaceuticals",
  "Medofy Pharmaceutical",
  "Medok Life Sciences Pvt Ltd",
  "Medon Pharmaceuticals Pvt Ltd",
  "Medons India",
  "Medopharma Chennai",
  "MedoPills Pharma",
  "Medox Remedies",
  "Medoyse Nutraceutical",
  "Medoz Pharmaceutical Pvt Ltd",
  "Medphar Health Care",
  "Medplus Health Services Ltd",
  "Medpro Biotech",
  "Medpurple Lifesciences Pvt Ltd",
  "Medray Pharmaceuticals",
  "Medreich Lifecare Ltd",
  "Medrek Pharmaceuticals",
  "Medrex Pharma",
  "Medrexcare Pharma Pvt Ltd",
  "Medrhans Pharmaceuticals Pvt Ltd",
  "Medrica Pharmaceuticals Pvt Ltd",
  "Medrid Life Sciences",
  "Medriff Lifesciences",
  "Medright Biogenics Pvt Ltd",
  "Medrim Pharmaceuticals Pvt Ltd",
  "Medrix Labs Pvt Ltd",
  "Medrix Pharma Pvt Ltd",
  "Medrock Biotech",
  "Medroots Biopharma",
  "Medrose Pharma",
  "Meds Life Sciences",
  "Medsea Healthcare Pvt Ltd",
  "Medset Pharma",
  "Medshine Pharmaceutical",
  "Medsign Biotech Pvt Ltd",
  "Medsky Healthcare",
  "Medsmart Logistics Pvt Ltd",
  "Medsmile Pharmaceutical Pvt Ltd",
  "Medsmith Pharmaceuticals",
  "Medsol India Overseas Pvt Ltd",
  "MedSonic Pharmaceuticals",
  "Medsource Ozone Biomedicals Pvt Ltd",
  "Medstanz Lifesciences",
  "Medstellar Healthcare Pvt Ltd",
  "Medstry Biotech Pvt Ltd",
  "Medsure Healthcare Pvt Ltd",
  "Medsuvac Lifesciences Pvt Ltd",
  "Medswap Pharmaceuticals Pvt Ltd",
  "Medsyn Lab Biotech",
  "Medtas Healthcare",
  "Medtech Life Pvt Ltd",
  "Medterm Healthcare Pvt Ltd",
  "Medtex Healthcare",
  "Medtronix Healthcare",
  "Medulla Pharmaceuticals Pvt Ltd",
  "Medvihas Healthcare",
  "Medvik Pharmaceuticals Pvt Ltd",
  "Medville Healthcare",
  "Medvin Pharmaceuticals Pvt Ltd",
  "Medvision Pharmaco",
  "Medviz Healthcare",
  "Medvue Remedies Pvt Ltd",
  "Medwalk Pharmacon Pvt Ltd",
  "Medwell Pharmaceuticals",
  "Medwide Pharma",
  "Medwill Lifesciences",
  "Medwin Pharmatech",
  "Medwise Pharmaceuticals",
  "Medwock Pharmaceuticals Pvt Ltd",
  "Medxone Healthcare",
  "Medyesa Healthcare",
  "Medz Lifesciences Inida Pvt Ltd",
  "Medzer Pharma",
  "Medzest Life Sciences",
  "Medzo Healthcare",
  "Meethi Pharmaceuticals",
  "Mefro Organic Ltd",
  "Mefro Pharmaceuticals Pvt Ltd",
  "Mega Biosciences",
  "Mega Pharmaceuticals",
  "Megacorp Healthcare Pvt Ltd",
  "Megaderm Pvt Ltd",
  "Megamed Remedies",
  "Megavision Pharmaceuticals",
  "Megde Healthcare",
  "Megha Healthcare Pvt Ltd",
  "Meghdoot Gramodyog Sewa sansthan",
  "Meghmani Lifesciences",
  "Meghraj Pharmaceuticals",
  "Meglife Pharmaceuticals",
  "Megma Health Care Pvt Ltd",
  "Megmento Pharmaceutical Pvt Ltd",
  "Megnesia Neurocare",
  "Mehar Healthcare Corporation",
  "Mehar Remedies",
  "Meher Beverages",
  "Mehrith Lifesciences Pvt Ltd",
  "Mehrotra Consumer Products Pvt Ltd",
  "Mekasa Products Pvt Ltd",
  "Mekiro Pharmaceutical Pvt Ltd",
  "Meliorate Pharmaceutical Pvt Ltd",
  "Melody Healthcare Pvt Ltd",
  "Meltic Healthcare Pvt Ltd",
  "Melting Pot Concepts Pvt Ltd",
  "Membrane Pharma India Pvt Ltd",
  "Menarini India Pvt Ltd",
  "Menarini Raunaq Pharma Ltd",
  "Menarini Raunaq Pharms Ltd",
  "Men-Care Pharmaceuticals",
  "Mendcure Life Sciences Pvt Ltd",
  "Mendine Lifecare Products Pvt Ltd",
  "Mendine Pharmaceuticals Pvt Ltd",
  "Meneki Global Pvt Ltd",
  "Menida Healthcare",
  "Menidine Pharmaceuticals",
  "Menrik Biomerge Pvt Ltd",
  "Mensa Futura Life Sciences Pvt Ltd",
  "Mensdom Pharmaceutical Pvt Ltd",
  "Mensken Pharma Pvt Ltd",
  "Mentis Pharma Ltd",
  "Mentor Healthcare Pvt Ltd",
  "Mepfarma India Pvt Ltd",
  "Mepro Pharmaceuticals",
  "Mepromax Life Sciences Pvt Ltd",
  "Mepromax Lifesciences Pvt Ltd",
  "Meracus Pharmaceuticals",
  "Meraki Health",
  "Mercigo Healthcare",
  "Merck India Ltd",
  "Merck Ltd",
  "Merck Ltd(Multicare)",
  "Merck Ltd(P & G)",
  "Merck Ltd(P&G)",
  "Mercury Laboratories Ltd",
  "Merganzer Pharma Pvt Ltd",
  "Merhaki Foods and Nutrition Pvt Ltd",
  "Mericana Pharmaceuticals Pvt Ltd",
  "Meridian Enterprises Pvt Ltd",
  "Meridian Medicare Ltd",
  "Meridus Pharmaceuticals",
  "Meril Diagnostics Pvt Ltd",
  "Merind Ltd",
  "Meri-Odin Life sciences",
  "Merion Care",
  "Merit Organics Ltd",
  "Meritor Pharmaceuticals Pvt Ltd",
  "Meritorious Pharmaceuticals",
  "Meritus Life Care Pvt Ltd",
  "Merkury Arena Labs",
  "Merlin Pharma Pvt Ltd",
  "Merlion Lifescience Pvt Ltd",
  "Merlyn Biotech",
  "Merrel Health Pvt Ltd",
  "Merril Pharma Pvt Ltd",
  "Merynova Lifesciences India Pvt Ltd",
  "Mesco Laboratories Ltd",
  "Mesla Pharmaceuticals",
  "Mesmer Pharmaceuticals",
  "Mesova Pharmaceuticals",
  "Mestra Pharma Pvt Ltd",
  "Meta Pharma",
  "Metabolica Lifecare Pvt Ltd",
  "Metalis Lifesciences Pvt Ltd",
  "Metamorf Lifesciences",
  "Metcure Remedies Pvt Ltd",
  "Meticue Pharmaceuticals Pvt Ltd",
  "Metiers Pharmaceuticals Pvt Ltd",
  "Metis Pharma",
  "Metlar Formulations",
  "Metpharm Biotech Pvt Ltd",
  "Metrix Healthcare Pvt Ltd",
  "Metro Pharma Ltd",
  "Metron Industries",
  "Metropharm Wellness India Pvt Ltd",
  "Metta Life Sciences Pvt Ltd",
  "Mevani Pharmaceuticals Pvt Ltd",
  "Mewar Impex",
  "Mewell Biotech Pvt Ltd",
  "Mewell Medisciences",
  "Mex Lifecare Llp",
  "Mexato Pharmaceuticals Pvt Ltd",
  "Mexican Wave Pharma",
  "Mexon Healthcare Ltd",
  "Meyer Healthcare",
  "Meyer Organics P Ltd(Cellage)",
  "Meyer Organics Pvt Ltd",
  "Meyer Vitabiotics",
  "Mezorays Pharma",
  "Mezzone Healthcare",
  "Mezzone Pharma",
  "Mezzone Remedies Pvt Ltd",
  "Mgee Healthcare",
  "MGH Healthcare",
  "MGRM Medicare Ltd",
  "MHL Formulations",
  "MHS Pharmaceuticals Pvt Ltd",
  "Mibsons Pharmaceutical Pvt Ltd",
  "Mickle Pharma",
  "Mico Lab Ltd(Synapes)",
  "Micra Pharmaceuticals",
  "Micro 2 Mega Healthcare Pvt Ltd",
  "Micro and Nano Technologies",
  "Micro Lab Ltd",
  "Micro Lab Ltd(Micro Vision)",
  "Micro Lab Ltd(Synapes)",
  "Micro Laboratories Ltd",
  "Micro Laboratories Ltd(Bal Pharma)",
  "Micro Laboratories Ltd(Carsyon)",
  "Micro Laboratories Ltd(Synapes)",
  "Micro Labs Ltd",
  "Micro Labs Ltd(Bal Pharma)",
  "MICRO LABS Ltd(BROWN & BURK)",
  "Micro Labs Ltd(Cardicare)",
  "Micro Labs Ltd(Carsyon)",
  "Micro Labs Ltd(Dtf)",
  "Micro Labs Ltd(Eros)",
  "Micro Labs Ltd(Gratia Derma)",
  "Micro Labs Ltd(India)",
  "Micro Labs Ltd(Synapes)",
  "Micro Labs Ltd(Vascular)",
  "Micro Ltd",
  "Micro Nova Pharmaceuticals Ltd",
  "Micro Organics Pharmaceuticals India Pvt Ltd",
  "Micro Tone",
  "Micro Vision Pharma",
  "Microbiome Research Pvt Ltd",
  "Microgen Hygiene Pvt Ltd",
  "Microgene Diagnostic Systems (P) Ltd",
  "Micron Biotech",
  "Micron Medisciences Pvt Ltd",
  "Micronova Life Sciences",
  "Micropoint Biotechnologies Pvt Ltd",
  "Microtek New Technologies Pvt Ltd",
  "Microwin Labs Pvt Ltd",
  "Midas Biotek Pvt Ltd",
  "Midas Healthcare Ltd",
  "MidasCare Pharmaceuticals Pvt Ltd",
  "Midway Lifesciences",
  "MIG Healthcare",
  "Mighty Pharmaceuticals",
  "Miglior Pharmaceuticals India Pvt Ltd",
  "Migliore Life Sciences",
  "Mihika Pharmaceutical",
  "Mikail Pharma Pvt Ltd",
  "Mikayla Life Sciences Pvt Ltd",
  "Mil Laboratories Pvt Ltd",
  "MiLab LifeSciences",
  "Milagro Beauty",
  "Milard Life Sciences",
  "Mild Cares",
  "Milichem Laboratories",
  "Milimax Healthcare",
  "Milith Pharma",
  "Milkfood Ltd",
  "Millennial Healthtech Pvt Ltd",
  "Millennial Pharmaceuticals Pvt Ltd",
  "Millennium Herbal Care",
  "Millets Pharmaceuticals Pvt Ltd",
  "Milligram Laboratories",
  "Million Health Care",
  "Milminn Healthcare",
  "Milstein Pharma",
  "Miltop Exports",
  "Mimansha Herbals",
  "Mimmo Organics",
  "Minakshi Laboratories Pvt Ltd",
  "Mindneuro Pharma",
  "Mindrug Biologics Pvt Ltd",
  "Mindwave Healthcare Pvt Ltd",
  "Mindway Skincare",
  "Minerva Medica Pharmaceuticals",
  "Minerve Remedies Pvt Ltd",
  "Mini Pharmaceuticals",
  "Minii Life Sciences Pvt Ltd",
  "Minitoss Medicines",
  "Minktek Pvt Ltd",
  "Minol Pharmaceuticals",
  "Minot Pharma Pvt Ltd",
  "Minova Life Sciences Pvt Ltd",
  "Mint Life Sciences Pvt Ltd",
  "Mint Pharma Pvt Ltd",
  "Mint Veda",
  "Miotic Pharma",
  "Miotic Pharmaceuticals Pvt Ltd",
  "Mirabelle Cosmetics Pvt Ltd",
  "Miracalus Pharma Pvt Ltd",
  "Miracle Life Sciences",
  "Miracletree Life Science",
  "Mirah Belle Naturals",
  "Miraj Biotech",
  "Mirana Healthcare Pvt Ltd",
  "Miraqual Remedies LLP",
  "Mirasure Life Sciences Pvt Ltd",
  "Mirayaahs Health Care",
  "Misae Lifesciences Pvt Ltd",
  "Misha Biotech Pvt Ltd",
  "Mission Cure Pharma Pvt Ltd",
  "Mission Research Laboratories Pvt Ltd",
  "Misters In",
  "Mitis Biomedics Ltd",
  "Mitisha Pharma",
  "Mitoch Pharma Pvt Ltd",
  "Mitocon Biomed",
  "Mits Healthcare Pvt Ltd",
  "Mittal Remedies",
  "Miyashi Life Science",
  "Miza labs",
  "Mizukhi's Pharmaa",
  "MK Cosmetics",
  "MK Healthcare",
  "MKS International Trade Pvt Ltd",
  "MKV Pharma Pvt Ltd",
  "MLS Healthcare Pvt Ltd",
  "MLT Laboratories Pvt Ltd",
  "MM Traders",
  "Mmatrics Pharmaceuticals",
  "Mmc Health Care Pvt Ltd",
  "MMC Healthcare Ltd",
  "MNC Biotech Pvt Ltd",
  "MNC Healthcare Pvt Ltd",
  "MNM Remedies Pvt Ltd",
  "MNW Life Sciences",
  "Moderik Healthcare",
  "Modern Laboratories",
  "Modern Medisciences",
  "Modern Pharmaceuticals",
  "Modern Sales Corporation",
  "ModernaTX, Inc",
  "Modgal Pharmaceuticals Pvt Ltd",
  "ModHike Pvt Ltd",
  "Modi Lifecare Industries Ltd",
  "Modi Mundi Pharma Ltd",
  "Modi Mundi Pharma Pvt Ltd",
  "Modi Naturals Ltd",
  "Modron Healthcare",
  "Module Pharmaceutical Pvt Ltd",
  "Mofon Drugs",
  "Mogli Labs (India) Pvt Ltd",
  "Mogon Healthcare",
  "Mohammedia Products",
  "Mohar Organics Pvt Ltd",
  "Mohrish Pharmaceuticals",
  "Moises Lifesciences Pvt Ltd",
  "Moksha Lifecare Pvt Ltd",
  "Moksha Lifestyle",
  "Molekule India",
  "Molekule India (Cns)",
  "Molekule India Pvt Ltd",
  "Molnlycke Health Care India Pvt Ltd",
  "Moms Magical World LLP",
  "Monad Pharmaceuticals",
  "Monarch Pharmachem",
  "Monark Biocare Pvt Ltd",
  "Mondelez India Foods Ltd",
  "Mondove Biotech Pvt Ltd",
  "Moneriz Pharma",
  "Moneshka Beauty Solutions",
  "Moni Pharma",
  "Monichem Healthcare Pvt Ltd",
  "Monokem Labs",
  "Monopack Solutions LLP",
  "Monopharma Pvt Ltd",
  "Monopoly Company",
  "Monsut Chem Industries",
  "Montac Lifestyle",
  "Montage Chemicals Pvt Ltd",
  "Montage Laboratories Pvt Ltd",
  "Montana Remedies",
  "Monte Orleans Incorporation",
  "Montek Biopharma",
  "Moon Healthcare Pvt Ltd",
  "Moon Herbal Laboratories Pvt Ltd",
  "Moon Rock Pharmaceuticals Pvt Ltd",
  "Moraceae Pharmaceuticals Pvt Ltd",
  "Moral Nutraceuticals Pvt Ltd",
  "Moral Pharmaceuticals",
  "Morarka Organic Foods Ltd",
  "Morecare Pharmatec Pvt Ltd",
  "Moreish Pharmaceuticals Pvt Ltd",
  "Morell Heathcare",
  "Morepen Laboratories",
  "Morepen Laboratories Ltd",
  "Morepen Laboratories Ltd(Generic)",
  "Morfik Laboratory Pvt Ltd",
  "Morgen Healthcare",
  "Morningg Life Pharmaceuticals Pvt Ltd",
  "Morp Mediz Pharmaceuticals Pvt Ltd",
  "Morpheme Remedies",
  "Morphil Healthcare Pvt Ltd",
  "Morphil Life Sciences Pvt Ltd",
  "Morris Life Sciences Pvt Ltd",
  "Morstella Biotech",
  "Morus Lifesciences Pvt Ltd",
  "Morvin India Healthcare Pvt Ltd",
  "Morwell Pharmacia Pvt Ltd",
  "Mosaic Wellness Pvt Ltd",
  "Mosfet Healthcare Pvt Ltd",
  "Mother & Baby Care Inc",
  "Mother Dairy Pvt Ltd",
  "Mother Remedies",
  "Mother Sparsh Baby Care Pvt Ltd",
  "Mothers Kitchen LLP",
  "Mothers Man",
  "Mothersense Technologies Pvt Ltd",
  "Mothersoul Pvt Ltd",
  "MotherTree Nutra Pvt Ltd",
  "Motif Health Care",
  "Mount Mettur Pharmaceuticals Ltd",
  "MountainAge Global Wellness Company",
  "Mountainor Well Being Pvt Ltd",
  "Mountus Life Science",
  "Mouway Pharma",
  "Mova Pharmaceutical Pvt Ltd",
  "Mova Pharmaceuticals P Ltd",
  "Moxy Laboratories Pvt Ltd",
  "Moya Labs Pvt Ltd",
  "MP BioTech",
  "MPC Pharmaceuticals",
  "MPS Life Sciences Pvt Ltd",
  "Mr ",
  "MRG Biotech Pvt Ltd",
  "MRG Pharmaceuticals",
  "MRHM Pharma Pvt Ltd",
  "MRK Healthcare Pvt Ltd",
  "Mrl Pharma Opc Pvt Ltd",
  "Mrna Biotech Pvt Ltd",
  "Msb Pharma Pvt Ltd",
  "MSD Animal Health India",
  "MSD Pharmaceuticals Pvt Ltd",
  "Msea Pharma",
  "Msk Healthcare Pvt Ltd",
  "MSN Laboratories",
  "Msn Laboratories Ltd",
  "Msn Laboratories Pvt Ltd",
  "Mucinac Ab",
  "Mucos Pharma India Pvt Ltd",
  "Muffin Biotech",
  "Mughal Merchandise",
  "Muller Phipps",
  "Multani Pharmaceuticals Ltd",
  "Multicure Pharma Pvt Ltd",
  "Multilax Pharmaceuticals Pvt Ltd",
  "Multiphar Pharma Ltd",
  "Mundipharma (Bd) Pvt Ltd",
  "Murentia Pharmaceuticals",
  "MusclePharm Corporation",
  "Mushroomiyat LLP",
  "Mustcure Healthcare LLP",
  "Mustwyn Organics Inc",
  "MVD Pharmaceuticals Pvt Ltd",
  "My Health Life Sciences",
  "My Milestones Fashion Essentials Pvt Ltd",
  "My Pura Vida Wellness Pvt Ltd",
  "My Vitaa Health Care",
  "Mycure Pharma Pvt Ltd",
  "Mygaga Consumer Pvt Ltd",
  "Myhaz Pharma Pvt Ltd",
  "Myk Pharmaceuticals",
  "Mylab Discovery Solutions Pvt Ltd",
  "Mylan Pharmaceuticals Pvt Ltd",
  "Mylan Pharmaceuticals Pvt Ltd- A Viatris Company",
  "Mylin Biotech India Pvt Ltd",
  "Mynext Pharmaceutical Pvt Ltd",
  "Myol Pharmaceuticals",
  "Myopics Pharmaceuticals Pvt Ltd",
  "Mypher Pharmaceuticals LLP ",
  "MYPPE Wellness Service Pvt Ltd",
  "Myra Pharmaceuticals",
  "Myrane Healthcare Pvt Ltd",
  "Myren Life Science India Pvt Ltd",
  "Myriad Hues Healthcare",
  "Myrica Healthcare Pvt Ltd",
  "MySIM Therapeutics Pvt Ltd",
  "Mysticism Healthcare",
  "Mystico Rose Pharma",
  "Mython Biosciences Pvt Ltd",
  "Mythus Pharmaceuticals",
  "N b Life Science Pvt Ltd",
  "N K Ethical Laboratories",
  "N K Pharma Industries",
  "N Line Healthcare",
  "N Line Healthcare Pvt Ltd",
  "N P ",
  "N P Dutt & Son",
  "N S Pharmaceuticals",
  "Naatson Healthcare Pvt Ltd",
  "Naav Healthcare Pvt Ltd",
  "Nabapri Lifesciences Pvt Ltd",
  "Nabarun Life Sciences",
  "Nabiqasim Industries Pvt Ltd",
  "Nac International",
  "Nac Pharmaceuticals",
  "NACC Pharmaceutical Pvt Ltd",
  "Nadans Lifesciences",
  "Nagarjun Pharmaceuticals Pvt Ltd",
  "Nagarjuna Ayurvedic Centre",
  "Nageena Ayurvedic Pharmacy",
  "Nahren Lifesciences",
  "Naj Pharmaceuticals Pvt Ltd",
  "Nakpro Nutrition",
  "Nakularch Meditech Pvt Ltd",
  "Nalthan Lifesciences Pvt Ltd",
  "Naman India Herbal formulation",
  "Namhya Foods Pvt Ltd",
  "Nami Lifesciences Pvt Ltd",
  "Namigo Pharma Pvt Ltd",
  "Namo Gange Wellness Pvt Ltd",
  "Namo Remedies",
  "Nanak Laboratories Pvt Ltd",
  "Nano Biosciences",
  "Nano Life Care",
  "Nano Syn Organics Pvt Ltd",
  "Nanocea Biotec",
  "Nanoclean Global Pvt Ltd",
  "NanoGen HealthCare Pvt Ltd",
  "Nanophyto Pharmacy Pvt Ltd",
  "Nanos Medicare Pvt Ltd",
  "Nanotek Remedies Pvt Ltd",
  "Nanshe Remedies",
  "Nanz Med Science Pharma Ltd",
  "Naomi Laboratories Pvt Ltd",
  "Naos Lifescience",
  "Nap Systems",
  "Naprod Life Sciences",
  "Napsko Healthcare Pvt Ltd",
  "Narang Plastics Pvt Ltd",
  "Narankaa Pharma",
  "Narayan Lifesciences Pvt Ltd",
  "Narbada Ayurveda",
  "Narendra Marketing",
  "Narvs Pharma",
  "Nasa Pharmaceutical",
  "Nascens Enterprise Pvt Ltd",
  "Nascent Life Sciences",
  "Nascent Medicare Pvt Ltd",
  "Nashik Valley Cosmeceuticals Pvt Ltd",
  "Nasibdar International",
  "Nask Pharmaceuticals Pvt Ltd",
  "Nastech Pharma Pvt Ltd",
  "Natchem Pharma",
  "Natco Fine Pharmacals Pvt Ltd",
  "Natco Pharm Ltd",
  "Natco Pharma Ltd",
  "Natel Neutratec (India) Pvt Ltd",
  "National Chemical & Pharmaceuticals Works Pvt Ltd",
  "National Chemicals & Pharmaceuticals",
  "National Scientific Industries",
  "Natrecon Lifescience Pvt Ltd",
  "Natturel and Kool Pvt Ltd",
  "Natural Biodrugs Pvt Ltd",
  "Natural Factors",
  "Natural Healthcare",
  "Natural Indulgence LLP",
  "Natural Life Sciences",
  "Natural Vibes",
  "Naturaline Nutraceuticals Pvt Ltd",
  "Natural-Life Speciality Pvt Ltd",
  "Naturals & Consumatic",
  "Nature & Nurture Healthcare Pvt Ltd",
  "Nature & Time Formulations",
  "Nature Biotech",
  "Nature Essential Foods Pvt Ltd",
  "Nature Farms and Beyond",
  "Nature Green Pvt Ltd",
  "Nature Healthcare",
  "Nature Nuskha",
  "Nature Stock",
  "Naturedge Beverages Pvt Ltd",
  "Natureland Organics",
  "Naturell India Pvt Ltd",
  "Natures Aid Ltd",
  "Nature's Bounty",
  "Natures Velvet Lifecare",
  "Nature's Way Products LLC",
  "Nature’s Best",
  "Naturise Conumer Products Pvt Ltd",
  "Naturma Life LLP",
  "Naturon Healthcare Ltd",
  "Naulakha Industries",
  "Nav Jiwan Ayurvedic Co ",
  "Nava Healthcare Pvt Ltd",
  "Navashya Consumer Products Pvt Ltd",
  "Naviksha Healthcare Pvt Ltd",
  "Navil Laboratories Pvt Ltd",
  "Navjiwan Ayurvedic Company",
  "Navlakshya Pharma",
  "Navnidhi Life Science Pvt Ltd",
  "Navysun Pharma",
  "Naxpar Health Concepts Pvt Ltd",
  "Nayan Pharmaceuticals Ltd",
  "Nayan Vision",
  "Nayasa Life Science Pvt Ltd",
  "Nazer Enterprise",
  "NB Healthcare",
  "NBLS Pharma",
  "NCL Agro Foods",
  "Ncyte Pharma Pvt Ltd",
  "Nddm Pharmaceuticals",
  "NDMA Healthcare Pvt Ltd",
  "Nebula Orthosys",
  "Nebulae Pharma",
  "Nec Pharmaceuticals",
  "Necleous Pharmaceuticals",
  "Necon Healthcare",
  "Necon Pharmaceutical",
  "Nectamed Laboratories",
  "Nectar Biochem Pvt Ltd",
  "Nectar Biopharma Pvt Ltd",
  "Nectar Life Sciences Ltd(NLL)",
  "Nectar Pharma",
  "Nectar Remedies Ltd",
  "Nectarea Pvt Ltd",
  "Nectazen Pharmaceuticals",
  "Nector Foods Pvt Ltd",
  "Nector Pharmaceuticals",
  "Nectwork Foods Pvt Ltd",
  "Neelabs Healthcare Pvt Ltd",
  "Neelkanth Healthcare Pvt Ltd",
  "Neelkanth Medicare Pvt Ltd",
  "Neelkanth Packaging Solutions",
  "Neelkanth Pharmacy India Pvt Ltd",
  "Neema Sales LLP",
  "Neev Life Sciences",
  "Neiss Labs Pvt Ltd",
  "Nelon Pharmceuticals",
  "Nelson Pharma",
  "Nem Laboratories",
  "Nemed Healthtech",
  "Nemi Pharmaceuticles",
  "Nemus Pharmaceuticals Pvt Ltd",
  "Neo Biotech",
  "Neo Lifesciences",
  "Neo Pharma Pvt Ltd",
  "Neo Remedies",
  "Neo Sanjivani",
  "Neo Vision Healthcare",
  "Neobeat Healthcare Pvt Ltd",
  "Neocardiab care",
  "Neocell Science Pharmaceuticals Pvt Ltd",
  "Neocortex Life Sciences Pvt Ltd",
  "Neocross Biotech Pvt Ltd",
  "Neofalcon Lifesciences",
  "Neokem Labs Pvt Ltd",
  "Neokemy Pharmaceuticals",
  "Neol Pharma",
  "Neolina Pharmaceuticals",
  "Neoliva Formulations",
  "Neomed Health Care India Pvt Ltd",
  "Neomedix Healthcare India Pvt Ltd",
  "Neon Homoeopathy",
  "Neon Laboratories Ltd",
  "Neon Labs Ltd(Anaesthesia)",
  "Neon Nutraceuticals Pvt Ltd",
  "Neo-Neo Cardiab Care Division",
  "Neonova Healthcare",
  "Neo-Pharma Pvt Ltd",
  "Neoprakruti Pharmaceuticals Pvt Ltd",
  "Neorangic Healthcare Pvt Ltd",
  "Neos Healthcare",
  "NeoStrata Company, Inc",
  "NeoSun Biotech India Pvt Ltd",
  "Neosys Medicare",
  "Neova Biogene Pvt Ltd",
  "Neovae Biomedics Pvt Ltd",
  "Neovae Pharma",
  "Neovap Biopharmaceuticals Pvt Ltd",
  "Neovision Healthcare",
  "Nephrostar Healthcare Pvt Ltd",
  "Nephurocare Pharma Pvt Ltd",
  "Neptune Biotech Pvt Ltd",
  "Neptune Fairdeal Products Pvt Ltd",
  "Neptune Life Sciences Pvt Ltd",
  "Nesika Lifesciences Pvt Ltd",
  "Nest Pharma",
  "Nestle India Ltd",
  "Nestor Pharmaceuticals",
  "Netgen World",
  "Nethika Naturals Pvt Ltd",
  "Netmeds Health Pvt Ltd",
  "Netprime Pharma Pvt Ltd",
  "Netri Healthcare Pvt Ltd",
  "Netrix Pharma",
  "Netter Healthcare Pvt Ltd",
  "Nettle Pharmaceuticals",
  "Network Pharma",
  "Neuborn Life Science",
  "Neucure Lifesciences Pvt Ltd",
  "Neuhack Healthcare",
  "Neumec Healthcare Pvt Ltd",
  "Neumorgen Pharmaceuticals Pvt Ltd",
  "Neupsy Pharma Pvt Ltd",
  "Neuracle Lifesciences Pvt Ltd",
  "Neuralco Labs",
  "Neurevlon Health Care",
  "Neuro Lab India Pvt Ltd",
  "Neuro Life Science",
  "Neuro Metrix Pharmaceuticals",
  "Neuro Pharma GmbH",
  "Neurocon Inc",
  "Neurogenics Therapeutics",
  "Neurolife Pharmaceuticals Pvt Ltd",
  "Neuromentis Lifesciences",
  "Neuron Pharma Pvt Ltd",
  "Neuropex Pharmaceuticals Pvt Ltd",
  "Neutec Healthcare Pvt Ltd",
  "Neuten Healthcare",
  "Neutra lab",
  "Neutraciaa Lifesciences Pvt Ltd",
  "Neutron LifeSciences LLP",
  "Neuvera Wellness Ventures Pvt Ltd",
  "Neuvocare Pharmaceuticals",
  "New Age Biotech Pvt Ltd",
  "New Chapter",
  "New Gayzel Pharmaceuticals",
  "New Group Therapeutics",
  "New Horizons Healthcare Pvt Ltd",
  "New India Company",
  "New Life Global Healthcare",
  "New Life Laboratories Pvt Ltd",
  "New Life Pharmaceuticals Pvt Ltd",
  "New Medicon Pharma Labs Pvt Ltd",
  "New Search Life Sciences Pvt Ltd",
  "New Shama Laboratories Pvt Ltd",
  "New Times Pharmaceuticals",
  "Newcrest Pharmaceuticals",
  "Newgen Biotech Pvt Ltd",
  "Newgen Healthcare Pvt Ltd",
  "Newgen Pharma",
  "Newgendra Pharma Pvt Ltd",
  "Newgenics Pharma",
  "Newlands Pharma",
  "Newmedd Pharmaceuticals Pvt Ltd",
  "Newnik Lifecare Pvt Ltd",
  "Newtramax Healthcare",
  "Newvista Biocare Pvt Ltd",
  "Nex Pharma Pvt Ltd",
  "Nexa Healthcare Pvt Ltd",
  "Nexa Pharma",
  "Nexacare Lifesciences Pvt Ltd",
  "Nexbio Life Sciences",
  "Nexbon Lifesciences",
  "Nexcellence Lifesciences",
  "Nexchem Healthcare",
  "Nexeum Pharmaceuticals",
  "Nexgen Healthcare Pvt Ltd",
  "Nexgen Pharma",
  "Nexgen Rx Life Science Pvt Ltd",
  "Nexicon Pharmaceuticals",
  "Nexina Life Sciences Pvt Ltd",
  "Nexkem Pharmaceuticals Pvt Ltd",
  "Nexocare Biotech Pvt Ltd",
  "Nexokinde Healthcare Pvt Ltd",
  "Nexstar Healthcare Pvt Ltd",
  "Next Generation Deals Pvt Ltd",
  "Next Labs Pvt Ltd",
  "Next Vision Pharma",
  "Next Wave India",
  "Nextford Lifesciences",
  "Nextgen Health Care",
  "Nextgen Health Care Pvt Ltd",
  "Nextgen Healthcare",
  "Nextt Level Beauty",
  "Nexure Lifesciences Pvt Ltd",
  "Nexus Biotech",
  "Nexus India",
  "NG Healthcare Pvt Ltd",
  "NG Life Sciences Pvt Ltd",
  "NGS Biotech Pvt Ltd",
  "Nice Chemicals Pvt Ltd",
  "Nicholas Healthcare Ltd",
  "Nicholas Piramal India Ltd",
  "Nicson Pharma",
  "Nicure Lifesciences",
  "Nidhi Pharmaceutical",
  "Nidus Pharma Pvt Ltd",
  "Nigam Lifesciences",
  "Nigella Biotech",
  "Nigella Pharmaceuticals Pvt Ltd",
  "Nihaka Lifesciences",
  "Nihit Pharmaceuticals Pvt Ltd",
  "NIID Pharmaceuticals",
  "Niine Pvt Ltd",
  "NIIT Global",
  "Niksan Pharmaceuticals",
  "Nikshe Multiproducts Pvt Ltd",
  "Niktech Healthcare",
  "Nikvin Healthcare (India) Pvt Ltd",
  "Nilkanth Lifescience Pvt Ltd",
  "Nilrise Pharmaceuticals Pvt Ltd",
  "Nimble Pharma",
  "Nimbles Biotech Pvt Ltd",
  "Nimbus Health Care Pvt Ltd",
  "Nimsaz Pharma Pvt Ltd",
  "Nimvas Dermacare",
  "Nippon Healthcare LLP",
  "Nippon Seiyaku Pvt Ltd",
  "Nipro Medical (India) Pvt Ltd",
  "Nipro Medical India Pvt Ltd",
  "Niraj Industries Pvt Ltd",
  "Nirlife Healthcare",
  "Nirmalaya Pharmaceutical Pvt Ltd",
  "Nirmiti Pharmaceutical Pvt Ltd",
  "Nirogaya Pharmaceutical Pvt Ltd",
  "Nirpra Healthcare Pvt Ltd",
  "Nirukta Healthcare Pvt Ltd",
  "Nirvaanic Life Foods Pvt Ltd",
  "Nirvana Healthcare",
  "Nirvana India Pvt Ltd",
  "Nirvasa Healthcare Pvt Ltd",
  "Nirvonix Healthcare LLP",
  "Nirwana Herbal Healthcare",
  "Nisarga Healthcare Pvt Ltd",
  "Nisargalaya Herbals pvt ltd",
  "Nishikaa Garments International LLP",
  "Nitfit Remedies Pvt Ltd",
  "Nithyasha Healthcare Pvt Ltd",
  "Nitin Lifesciences Ltd",
  "Nitin Pharmaceuticals Pvt Ltd",
  "Nitro Cadineur",
  "Nitro Cure Sciences",
  "Nitro Organics",
  "Niva Nutrifoods LLP",
  "Nivabs Pharmaceutical Pvt Ltd",
  "Nivea India Pvt Ltd",
  "Nivedita Saboo Cares",
  "Nivia Lifescience",
  "Nivian Pharma LLP",
  "Nivulia Lifesciences Pvt Ltd",
  "Nivy Remedies",
  "Nixi Laboratories Pvt Ltd",
  "Niya Biotech",
  "Niyamba Pharmaceutical Pvt Ltd",
  "Nm Remedies Pvt Ltd",
  "NMF Healthcare Inc",
  "NN Remedies Pvt Ltd",
  "Nobel Hygiene Ltd",
  "Noble Bio Life Sciences Pvt Ltd",
  "Noble Fire Fighting Industries",
  "Noble Life Energy Pvt Ltd",
  "Nocnil Pharma",
  "Nodex Pharmaceuticals",
  "Nodysis Pharma Pvt Ltd",
  "Noel Pharma India Pvt Ltd",
  "Noir Pharmaceuticals Ltd",
  "Noor Pharmaceuticals",
  "Nootan Pharmaceuticals",
  "Nopex Healthcare",
  "Norden Lifesciences",
  "Nordic Formulations Pvt Ltd",
  "Nordic Naturals",
  "Nordic Pharmaceuticals Ltd",
  "Nordic Pharmaceuticals Pvt Ltd",
  "Noreva Healthcare",
  "Noreva Laboratoires",
  "Norgam Medicaments Pvt Ltd",
  "Norlis Life Sciences Pvt Ltd",
  "Norma D N D Products Pvt Ltd",
  "Normak Laboratories",
  "Norris Medicines Ltd",
  "Nortech Remedies",
  "North Corp Life Sciences",
  "Northwest Healthcare Pvt Ltd",
  "Norvick Lifesciences",
  "Norwest Pharmaceuticals Inc",
  "Norwin Lifesciences",
  "Noshey Healthcare",
  "Nosme Pharmaceuticals",
  "Nostalgia Medicare",
  "Nostrum Pharmaceuticals",
  "Notus Pharmaceuticals Pvt Ltd",
  "Nourier Lab",
  "Nourish Healthcare",
  "Nourish Herbs LLP",
  "Nourish Organic Foods Pvt Ltd",
  "Nouveau Medicament (P) Ltd",
  "Nouveau Medicament Pvt Ltd",
  "Nova Herbs",
  "Nova Indus Pharmaceuticals",
  "Nova Lifesciences Pvt Ltd",
  "Nova Scotia",
  "Nova Sud Pharmaceuticals Pvt Ltd",
  "Novacardus Pharmaceuticals",
  "Novachem Laboratories",
  "Novacross Pharmaceuticals",
  "Novaduo Pharma",
  "Novae Healthcare Pvt Ltd",
  "Novaegis India Pvt Ltd",
  "Novagen Pharmaceuticals",
  "Novah Pharmaceuticals",
  "Novakind Bioscience Pvt Ltd",
  "Novalab Healthcare Pvt Ltd",
  "Novalife Healthcare",
  "NovaMed Pharmaceuticals Pvt Ltd",
  "Novartis (Sandoz)",
  "Novartis (Spl)",
  "Novartis Healthcare Pvt Ltd",
  "Novartis India Ltd",
  "Novartis Pharma",
  "Novashine Pharmaceuticals",
  "Novawock Pharma",
  "Novazen Pharmaceuticals",
  "Novazing Pharma Pvt Ltd",
  "Noveco Research Lab",
  "Novel Nutrients Pvt Ltd",
  "Novell Biolabs Pvt Ltd",
  "Novell Pharma Pvt Ltd",
  "Novelsort Lifecare Pvt Ltd",
  "Novelty Healthshine Pvt Ltd",
  "Novique Life Sciences",
  "Novita Healthcare Pvt Ltd",
  "Novitas Remedies Pvt Ltd",
  "Novo India (Aheal)",
  "Novo Medi Sciences Pvt Ltd",
  "Novo Medix Pharmaceutical Pvt Ltd",
  "Novo Nordisk India Pvt Ltd",
  "Novo Nordisk Pharma India Ltd",
  "Novocin Pharmaceuticals",
  "Novogen Captab",
  "NovoLilly Pharmaceutical Pvt Ltd",
  "Novolix Pharmaceuticals",
  "Novopharm Formulations Pvt Ltd",
  "Novoscient Pharmaceuticals",
  "Novostar Pharmaceutical Pvt Ltd",
  "Novostart Life Science",
  "Novosys Life Sciences Pvt Ltd",
  "Novozac Pharma",
  "Novus Life Sciences Pvt Ltd",
  "Now Food",
  "Noxxon Pharmaceuticals Pvt Ltd",
  "NP IN",
  "NPIL Healthcare Pvt Ltd",
  "NPS Pharmaceuticals",
  "NR Chembiosciences Pvt Ltd",
  "NRD Enterprises",
  "Nri Vision Care India Ltd",
  "Nright Nutrition Pvt Ltd",
  "NSN Pharmacare Pvt Ltd",
  "NSV Lifecare Pvt Ltd",
  "Nuage Health Devices Pvt Ltd",
  "Nuage India",
  "Nuberg Pharmaceuticals Pvt Ltd",
  "Nubiotic Lifescience Pvt Ltd",
  "Nucare Lifesciences Pvt Ltd",
  "Nuchem Laboratories Pvt Ltd",
  "Nucidem Life Sciences Pvt Ltd",
  "Nucifera Lifesciences",
  "Nuclear Pharmaceuticals Pvt Ltd",
  "Nucleo Remedies",
  "Nucleosomecare Pharma Llp",
  "Nucleus Incorporation",
  "Nucleus Laboratories (India) Ltd",
  "Nucon Remedies Pvt Ltd",
  "Nucron Pharmaceuticals Pvt Ltd",
  "Nudrug Pharmaceutical Pvt Ltd",
  "Nuemrsn Pharma",
  "Nuevomed Organics",
  "Nuface Pharma",
  "Nugenra Pharma pvt ltd",
  "Nukind Healthcare Pvt Ltd",
  "Nulead Pharmaceuticals Pvt Ltd",
  "Nuliek Healthcare",
  "NuLife Pharmaceuticals",
  "Nulife Pharmaceuticals Ltd",
  "Numac Healthcare Pvt Ltd",
  "Numark laboratories",
  "Numark Ltd",
  "Numedix Life Sciences",
  "Numen Pharma Pvt Ltd",
  "Numera Life Sciences",
  "Numis Pharmaceuticals",
  "Nunchi Pharma Pvt Ltd",
  "Nuobest Pharmaceuticals Pvt Ltd",
  "Nuorik Healthcare",
  "Nupal Remedies",
  "Nuper therapeutics",
  "Nuray Lifesciences Pvt Ltd",
  "Nureca Ltd, India",
  "Nurture Aqua Technology Pvt Ltd",
  "Nurtureplus Biotech Pvt Ltd",
  "Nusearch Organic",
  "NuShield Pharma Pvt Ltd",
  "Nusmith Pharma Pvt Ltd",
  "Nutra Respiro",
  "Nutrabuff Nutraceutcials",
  "Nutracology Scientific Nutrition",
  "Nutraferon Pvt Ltd",
  "Nutrafy Wellness Pvt Ltd",
  "Nutragenix Healthcare Pvt Ltd",
  "Nutramed Pharmaceuticals Pvt Ltd",
  "Nutramedica Inc ",
  "Nutrashiled Healthcare",
  "Nutraswiss Pvt Ltd",
  "Nutratec Pharmaceuticals",
  "Nutravo Lifecare Pvt Ltd",
  "Nutree World Life Care Pvt Ltd",
  "Nutrex Research Inc",
  "Nutri Quest Pvt Ltd",
  "Nutri Synapzz",
  "Nutri Synapzz Therapeutis Pvt Ltd",
  "Nutriarc Wellness Pvt Ltd",
  "Nutriburst India Pvt Ltd",
  "Nutricia International Pvt Ltd",
  "Nutricore Biosciences Pvt Ltd",
  "Nutricorp Healthcare India Pvt Ltd",
  "Nutrifirm therapeutics",
  "Nutrigen Pharma",
  "Nutrigold India Pvt Ltd",
  "Nutrija Lifesciences",
  "Nutriley Healthcare",
  "Nutrilife India Pvt Ltd",
  "Nutrilis Healthcare Pvt Ltd",
  "Nutripure Foods Pvt Ltd",
  "Nutrisage Life Care Pvt Ltd",
  "Nutrisattva Foods Pvt Ltd",
  "Nutristrength Ltd",
  "Nutrition Dynamic Foods Llp",
  "Nutritionalab Pvt Ltd",
  "Nutrivative Foods Pvt Ltd",
  "Nutrix Health care Pvt Ltd",
  "Nutrizoe Nutriifoods Pvt Ltd",
  "Nutroactive Industries Pvt Ltd",
  "Nutron Pharma",
  "Nutrovally Nutrition LLP",
  "Nuvenius Life Science",
  "Nuvicare Pharmaceuticals Pvt Ltd",
  "Nuvigen Life Sciences Pvt Ltd",
  "Nuvilife Global LLP",
  "Nuvista Biocare",
  "Nuvomax Nutritionals Pvt Ltd",
  "Nuvox Healthcare",
  "Nuwill Biotech Pvt Ltd",
  "Nuzen Herbal Pvt Ltd",
  "Nuzen Pharmaceuticals",
  "NV Lifecare Pvt Ltd",
  "NV Pharmaceuticals",
  "NVK Pharma",
  "Nvron Life Science Ltd",
  "NXT Ecommerce Solutions India Pvt Ltd",
  "Nyassa Retail Pvt Ltd",
  "Nyctanthes Pharmaceuticals Pvt Ltd",
  "Nydux Pharma",
  "NYG Pharmaceuticals Pvt Ltd",
  "Nylo Med Pharmaceuticals",
  "NYX Pharmaceutical Pvt Ltd",
  "o b ",
  "OAK Vance Life Sciences Pvt Ltd",
  "Oaknet Healthcare Pvt Ltd",
  "Oaly Pharma",
  "Oarvi Pharma Pvt Ltd",
  "Oasis Laboratories Pvt Ltd",
  "Oasis Remedies",
  "Obat Medicare Pvt Ltd",
  "Oberlin Healthcare Pvt Ltd",
  "Obsurge Biotech Ltd",
  "Obtain Healthcare",
  "Obzone Pharmaceuticals Pvt Ltd",
  "Occuvea Pharmaceuticals Pvt Ltd",
  "Ocean Healthcare",
  "Ocean Pharmaceutical",
  "Oceanic Gold Global Pvt Ltd",
  "Ochoa Laboratories Ltd",
  "Octaglo Laboratories Pvt Ltd",
  "Octalife Pharmaceuticals Pvt Ltd",
  "Octamed Lifesciences Pvt Ltd",
  "Octane Biotech Pvt Ltd",
  "Octavia Labs Pvt Ltd",
  "Octavius Pharma Pvt Ltd",
  "Octavius Tea & Industries Ltd",
  "Octillions Pharmaceuticals",
  "Octis Healthcare Pvt Ltd",
  "Octob Lifesciences Pvt Ltd",
  "Octofeb Pharma Pvt Ltd",
  "Octtantis Nobel Labs Pvt Ltd",
  "Oculus Pharmaceuticals Pvt Ltd",
  "Ocuris Pharmaceuticals Pvt Ltd",
  "Oddiant Formulations",
  "Oddity Healthcare Pvt Ltd",
  "Oddway iCare Pvt Ltd",
  "Odin Healthcare Pvt Ltd",
  "Ogreeny Enterprise",
  "Oirpil Biotech",
  "Oishi Healthcare",
  "Ojal Pharmaceuticals Pvt Ltd",
  "Ojana Pharmaceutical Pvt Ltd",
  "Ojaskara Medicament Pvt Ltd",
  "Ojasveda Pvt Ltd",
  "OJB Herbals Pvt Ltd",
  "OK Enterprises",
  "Okasa Pharma",
  "Okinawa Pharma",
  "Okulus Drugs India",
  "Okyano Pharma",
  "Olamic Healthcare",
  "Olamic Pharma Pvt Ltd",
  "Olamiken Lifescience Pvt Ltd",
  "Olcare Laboratories",
  "Olcare Laboratories Pvt Ltd",
  "Oldgoa Oils & Foods Pvt Ltd",
  "Oldmed Healthcare Pvt Ltd",
  "Olena Health (OPC) Pvt Ltd",
  "Olens Healthcare Pvt Ltd",
  "Oli Pharma LLP",
  "Olive Softgel",
  "Olivia Pharma",
  "Olwen Lifesciences Pvt Ltd",
  "Olycare Life Sciences",
  "Olympia Industries Ltd",
  "Olympus Life Sciences",
  "Olympus Therapeutics Pvt Ltd",
  "Olzwell Healthcare Pvt Ltd",
  "Om Biomedic Pvt Ltd",
  "Om Biotec",
  "Om Hare Ram Healthcare Pvt Ltd",
  "OM Healthcare",
  "Omay Foods",
  "Omeg Healthcare Pvt Ltd",
  "Omega Biotech Ltd",
  "Omega Pharma",
  "Omega Pharmaceuticals Pvt Ltd",
  "Omega Remedies Pvt Ltd",
  "Omenta Pharma Pvt Ltd",
  "Omex Healthcare",
  "OMG Labs pvt ltd",
  "Omicron Pharma",
  "Omnax Pharmaceuticals Pvt Ltd",
  "Omni Lifesciences Pvt Ltd",
  "Omni Orthotechnics Pvt Ltd",
  "Omnica Laboratories",
  "Omnicure Medicaments Ltd",
  "Omnimol Meditechnics Pvt Ltd",
  "Omni-Protech Drugs Pvt Ltd",
  "Omron Healthcare India Pvt Ltd",
  "Omshakamberi Herbals Pvt Ltd",
  "Omved Lifestyle Pvt Ltd",
  "Onamoy Healthcare LLP",
  "Oncare Pharmaceuticals Pvt Ltd",
  "Oncobiotek Drugs Pvt Ltd",
  "Oncocare Scientifics Pvt Ltd",
  "One Cars",
  "One Dukan Pvt Ltd",
  "One Pharma Drugs & Pharmaceuticals Pvt Ltd",
  "One Stop Pharma Services",
  "oneDr Kid",
  "oneDr Woman",
  "Oneiro Pharmaceuticals",
  "Onelife Nutriscience Pvt Ltd",
  "Onerous Pharma",
  "Onestep Healthcare Pvt Ltd",
  "Oneus Pharma & Health Care LLP",
  "Onika Organics",
  "OnlineSurgicals com",
  "Onmask Life Sciences Pvt Ltd",
  "Onpharno Lifesciences Pvt Ltd",
  "Ontop Pharmaceutical",
  "Onxy Lifecare",
  "Ooath Healthcare Pvt Ltd",
  "Ooplore International",
  "Opal Healthcare",
  "Opal Labs Pvt Ltd",
  "Operon Biotech and Healthcare",
  "Opes Pharmaceuticals",
  "Ophelia Pharmaceutical",
  "Opinion Healthcare Pvt Ltd",
  "OPPO Medical Inc",
  "Opsis Care",
  "Opsis Care Lifesciences Pvt Ltd",
  "Opsiscare Lifesciences Pvt Ltd",
  "Opstean Healthcare Pvt Ltd",
  "Optho Life Sciences Pvt Ltd",
  "Optho Pharma Pvt Ltd",
  "Optho Remedies Pvt Ltd",
  "Optica Pharmaceuticals Pvt Ltd",
  "Opticarma India SMC Pvt Ltd",
  "Optiderma Skincare LLP",
  "Optima Healthcare",
  "Optimal Pharma tech",
  "Optimal Pharmaceuticals Pvt Ltd",
  "Optimum Healthcare",
  "Optimum Nutrition (On)",
  "Optimus Health Care",
  "Optimus Pharma Pvt Ltd",
  "Optimuz Medicos Pvt Ltd",
  "Optiva Pharma Pvt Ltd",
  "Optix Healthcare",
  "Optocred Pharmaceuticals",
  "Optrica Pharma Pvt Ltd",
  "Ora Pharmaceuticals",
  "Orache Health care Pvt Ltd",
  "Oracle Health Park Pvt Ltd",
  "Oracura Solutions Pvt Ltd",
  "Oraliv Pharmaceuticals",
  "Orama Life Sciences Pvt Ltd",
  "Orange Biotech Pvt Ltd",
  "Orange Healthcare Pvt Ltd",
  "Orange Organics Pharmaceuticals",
  "Orange Packets",
  "Oratio Lifescience Pvt Ltd",
  "Orazen Healthcare LLP",
  "Orazenom Lifesciences Pvt Ltd",
  "Orbit Biocare",
  "Orbit Lifescience Pvt Ltd",
  "Orcalo Lifesciences",
  "Orchid Chemicals & Pharmaceuticals Ltd",
  "Orchis Pharma",
  "Ordain Health Care Global P L",
  "Ordain Health Care Global Pvt Ltd",
  "Ordain Health Care Pvt Ltd",
  "Ordain Healthcare Pvt Ltd",
  "Oregon Healthcare Pvt Ltd",
  "Orenburg Healthcare Pvt Ltd",
  "Oreo Healthcare",
  "Orexia Lifesciences Pvt Ltd",
  "Organext Pharmaceuticals Pvt Ltd",
  "Organic Affaire",
  "Organic Dehydrated Foods Pvt Ltd",
  "Organic India",
  "Organic Kashmir Pvt Ltd",
  "Organic Laboratories",
  "Organic Labs Pvt Ltd",
  "Organic Nova Pharmaceuticals",
  "Organic Orchards Pvt Ltd",
  "Organic Wellness Products Pvt Ltd",
  "Organon (India) Ltd",
  "Organon India Ltd",
  "Orgera Herblines",
  "ORGRAN Health & Nutrition",
  "Orgus Healthcare",
  "Orgyn Laboratories Pvt Ltd",
  "Orian Pharmaceuticals",
  "Orichem Drugs & Pharmaceuticals Private",
  "Oriel Healthcare Pvt Ltd",
  "Orient Pharmaceuticals",
  "Oriental Chemical Works",
  "Oriental Pharma",
  "Origam Pharmocrats",
  "Origami Cellulo Pvt Ltd",
  "Origen Life Sciences Pvt Ltd",
  "Origin Health Care Pvt Ltd",
  "Origo Pharmaceuticals Pvt Ltd",
  "Orimax Healthcare Pvt Ltd",
  "Orion Biotech Pvt Ltd",
  "Orion Healthcare",
  "Orion Lifesciences",
  "Orison Pharma International",
  "Orison Pharmaceuticals",
  "Oriva Lifesciences Pvt Ltd",
  "Orley Laboratories Pvt Ltd",
  "Orlife Healthcare",
  "Ormeal Foods Pvt Ltd",
  "Orn Remedies Pvt Ltd",
  "Ornate Labs Pvt Ltd",
  "Ornic Healthcare",
  "Ornicon Healthcare",
  "Oro Pharmaceuticals Pvt Ltd",
  "Oron Healthcare Pvt Ltd",
  "Orosys Life Sciences",
  "Oroverse International Pvt Ltd",
  "Orphic Medicare Pvt Ltd",
  "Orphic Pharma (India) Ltd",
  "Orrin Pharma LLP",
  "Orris Pharmaceuticals",
  "Orsim Pharma",
  "Orsis Pharmaceuticals",
  "Orsova Healthcare",
  "Ortin Laboratories Ltd",
  "Orven Pharma",
  "Orville Biotek",
  "Orwin Biotech Pvt Ltd",
  "Oryzer Biotec Pvt Ltd",
  "Osasun Pharma",
  "Oscar Remedies Pvt Ltd",
  "Oscorp Labs",
  "Oscorp Remedies Pvt Ltd",
  "Osheeka Pharmaceuticals",
  "Oshid Pharmaceuticals Pvt Ltd",
  "Oshin Laboratories Pvt Ltd",
  "Oshin Pharmaceuticals Pvt Ltd",
  "Osho Laboratories (P) Ltd",
  "Osho Pharma Pvt Ltd",
  "Osiante Biotech",
  "Osiris Healthcare",
  "Osiyan Bioscience Pvt Ltd",
  "Osiyan Lifescience Pvt Ltd",
  "OSL Pharmaceuticals",
  "Osmed Formulations",
  "Osnat Pharmaceuticals",
  "Osper Formulations Pvt Ltd",
  "Osque Pharma Pvt Ltd",
  "OSR Pharmaceuticals Pvt Ltd",
  "Osren Healthcare Pvt Ltd",
  "Osseous Healthcare Pvt Ltd",
  "Osteo Life",
  "Oster Remedies",
  "Ostg Technology Pvt Ltd",
  "Ostra Unique Prohealth Pvt Ltd",
  "Ostrich Pharmaceuticals Pvt Ltd",
  "Osvel Pharma",
  "Oswald Pharmaceuticals",
  "Oswin Pharmaceuticals",
  "Otica Meditronix Co ",
  "Otik Biotec",
  "Otis Ventures Pvt Ltd",
  "Otrix Life Science LLP",
  "Otsira Genetica",
  "Otsuka Pharmaceutical India Pvt Ltd",
  "Otsuka Pharmaceuticals Pvt Ltd",
  "Oubari Pharma Pvt Ltd",
  "Overra Healthcare Pvt Ltd",
  "Overseas Healthcare Pvt Ltd",
  "Overseas Helth Care Pvt Ltd",
  "Overseas Pharma Pvt Ltd",
  "Oviat Pharmaceutical Pvt Ltd",
  "Owshadh Pharmaceuticals",
  "Oxagoan Pharmaceuticals",
  "Oxania Healthcare Pvt Ltd",
  "Oxford Pharmaceuticals Pvt Ltd",
  "Oxicare Pharma",
  "OxiClear Health & Safety",
  "Oximed Remedies",
  "Oxin Nutrition",
  "Oxirich Healthcare Pvt Ltd",
  "Oxpro Pharma Pvt Ltd",
  "Oxygen Drugs Laboratory Pvt Ltd",
  "Oxygen Pharma Care Pvt Ltd",
  "Oxygen Remedies Pvt Ltd",
  "Oxymeal Wellness LLp",
  "Oxymed Pharma",
  "Oxyon Pharmaceuticals Pvt Ltd",
  "Oxyriva Lifesciences Pvt Ltd",
  "Oyster Labs Ltd",
  "Oyster Pharma Pvt Ltd",
  "Ozenius Pharmaceuticals",
  "Oziel Pharmaceuticals Pvt Ltd",
  "Ozone Organics",
  "Ozone Pharma Ltd(Proton)",
  "Ozone Pharmaceuticals Ltd",
  "Ozrak LifeSciences",
  "P & B Pharmaceuticals Ltd",
  "P and P Dermaceuticals Pvt Ltd",
  "P B Enterprises",
  "P C Jain Textile Pvt Ltd",
  "P R G Pharma Pvt Ltd",
  "P R Innovations Pvt Ltd",
  "P4 Medicine Pvt Ltd",
  "Paarzon Pharmaceuticals",
  "Pace Bio Sciences",
  "Pace Pharmaceuticals",
  "Pacific Bioengineering Pvt Ltd",
  "Pacific Biotech",
  "Pacific Drugs & Chemicals",
  "Pacific India",
  "Pacific Meditech Pvt Ltd",
  "Pacific Pharmaceuticals Pvt Ltd",
  "Pacira Healthcare PVT LTD",
  "Pacitora Biotech",
  "Padam Shree Marketing",
  "Padmavati Chemicals",
  "Paedicon Biotech Pvt Ltd",
  "Pagnor Pharmaceutical Pvt Ltd",
  "Pagoda Pharmaceuticals",
  "Paithan Eco Foods Pvt Ltd",
  "Paksons Pharmaceuticals Pvt Ltd",
  "Palak Stark Pvt Ltd",
  "Palco Healthcare",
  "Pals Healthcare",
  "Palson Drug",
  "Palsons Derma",
  "Palsons Derma Pvt Ltd",
  "Palsons Drugs & Chemicals",
  "Palsons Drugs And Chemicals",
  "Palvin Pharmaceuticals",
  "Pam Biotech",
  "Pamasha Pharmaceuticals Pvt Ltd",
  "Pan Pharmaceuticals Ltd",
  "Panacea Biotec Ltd",
  "Panacea Biotec Ltd(Procare)",
  "Panacea Biotec Pharma Ltd",
  "Panax Matrix Research Laboratory",
  "Panbross Pharmaceuticals Pvt Ltd",
  "Pancare Pharmaceuticals Pvt Ltd",
  "Panch Prakash Pharmaceutical Pvt Ltd",
  "Panchakarma Herbs Pvt Ltd",
  "Panchpedh Pharma Pvt Ltd",
  "Pangea Pharma Ltd",
  "Panjon Pharma Ltd",
  "Pankajakasthuri Herbals India (P) Ltd",
  "Panm Labs India",
  "Panventric Pharma",
  "Panvik Pharmaceuticals",
  "Panzer Pharmaceuticals Pvt Ltd",
  "Panzin Healthcare Pvt Ltd",
  "Papillon Pharmaceutical Pvt Ltd",
  "Paradigm Healthcare",
  "Paradigm Healthcare Services",
  "Paradigm Pharmaceuticals Pvt Ltd",
  "Parag Milk Foods",
  "Paragon Pharmaceuticals",
  "Paramount Pharma Pvt Ltd",
  "Paramount Surgimed Ltd",
  "Parampara Ayurved",
  "Parangat Organics",
  "Paras Impex ",
  "Paras Naturals",
  "Paras Pharma",
  "Paras Pharmaceuticals Ltd",
  "Paras Products",
  "Paras Spices Pvt Ltd",
  "Parasol Laboratories",
  "Pardys Healthcare",
  "Parenteral Drugs India Ltd",
  "Parex Pharmaceuticals Pvt Ltd",
  "Paridhi Remedies",
  "Pariharsh Med Pharmaceuticals",
  "Parijat Lifescience Pvt Ltd",
  "Paripurna Healthcare",
  "Park Pharmaceuticals",
  "Park-Benz Laboratories",
  "Parkdes Pharma Pvt Ltd",
  "Parke Davis India Ltd",
  "Parker Robinson Pvt Ltd",
  "Parkin Healthcare Pvt Ltd",
  "Parnasa Mediworld Pvt Ltd",
  "Parodebi Impex LLP",
  "Parrish Biotek",
  "Parry Pharma Pvt Ltd",
  "Parsham Group",
  "Parsmed Healthcare Pvt Ltd",
  "Parth Remedies",
  "Parul Health Care Pvt Ltd",
  "Parx Life Science",
  "Pasco Pharmaceuticals",
  "Pasific Healthcare",
  "Passim Medica",
  "Passsion Indulge Pvt Ltd",
  "Pasteur Laboratories Pvt Ltd",
  "Patanjali Ayurved Ltd",
  "Patanjali Divya Pharmacy",
  "Patco Pharmaceuticals",
  "Patronage Pharma Pvt Ltd",
  "Patroncare Pvt Ltd",
  "Patson Laboratories Pvt Ltd",
  "Paviour Pharmaceuticals Pvt Ltd",
  "Pavlola India Laboratories",
  "Pax Healthcare",
  "PaxChem Ltd",
  "Pay Pharmaceuticals Pvt Ltd",
  "Pca Skin",
  "Pcd Pharma Franchise",
  "PCI Pharmaceuticals",
  "PCM Biotech",
  "PDC Healthcare",
  "Peakmed Lifecare Enterprise",
  "Pearl Lifecare Pvt Ltd",
  "Pearl Medilife Pvt Ltd",
  "Pearlshine Lifescience Pvt Ltd",
  "Peau Brillante",
  "Peaurega Naturals",
  "Pebble Pharmaceutical",
  "Pecoss Healthcare",
  "Pedison Pharma",
  "Peenak Pharma",
  "Peerage Pharma Pvt Ltd",
  "Pegasus Farmaco India Pvt Ltd",
  "Pekoe Tips Tea",
  "Pelzar Healthcare",
  "Penam Pharmaceuticals Pvt Ltd",
  "Penardo Biotech",
  "Pendulum Lifesciences Pvt Ltd",
  "Penlon India Pharmaceuticals",
  "Penryth Labs Pvt Ltd",
  "Pensia Biotech",
  "Penta Biotech Pvt Ltd",
  "Pentacare Ayurpharma",
  "Pentagon Labs Ltd",
  "Pentamark Organics",
  "Pep Technologies Pvt Ltd",
  "Pepper Health Pvt Ltd",
  "Perch Labs Ltd",
  "Percos India Pvt Ltd",
  "Perennial Lifesciences Pvt Ltd",
  "Perfect Remedies",
  "Pericles Pharma",
  "Periodic Healthcare",
  "Perk Pharmaceuticals Ltd",
  "Perma Healthcare",
  "Perpetual Pharmaceuticals",
  "Perron Pharmaceuticals Pvt Ltd",
  "Pet Care",
  "Pet Lovers",
  "Pet Treats Ltd",
  "Petal Herbs Ayurveda",
  "Petal Lifesciences",
  "Petrosolv India Ltd",
  "Pex-care Biotech",
  "Pferd Pharmaceutical Pvt Ltd",
  "Pficus De Med Pvt Ltd",
  "Pfimaxx Organics Pvt Ltd",
  "Pfimex International Ltd",
  "Pfiscar India Ltd",
  "Pfizer Ltd",
  "Pfizer Ltd(Spl)",
  "Pfleger Healthcare Pvt Ltd",
  "PGI Pharmaceuticals",
  "Pgunique Products Pvt Ltd",
  "Phaedrus Life Science Pvt Ltd",
  "Phalada Organic Consumer products Pvt Ltd",
  "Pharm Biotech",
  "Pharm Products Pvt Ltd",
  "Pharma Biological",
  "Pharma Chemico Laboratories",
  "Pharma Corp Inc Pvt Ltd",
  "Pharma Cure Pvt Ltd",
  "Pharma Drugs & Chemicals",
  "Pharma Fabrikon",
  "Pharma Generix",
  "Pharma Grid",
  "Pharma Impex Labs Pvt Ltd",
  "Pharma India",
  "Pharma Link Pvt Ltd",
  "Pharma One (India) Pvt Ltd",
  "Pharma Planet India",
  "Pharma Plus",
  "Pharma Science",
  "Pharma Tech India",
  "Pharma Vision",
  "Pharmaaffiliates Healthcare",
  "Pharmaceutical Institute of India Pvt Ltd",
  "Pharmacia Health Care Ltd",
  "Pharmacia India Pvt Ltd",
  "Pharmacon Biotech Pvt Ltd",
  "Pharmacon Gignos",
  "Pharmacon Healthcare",
  "Pharmacon Lifesciences Pvt Ltd",
  "Pharmacon Remedies Group",
  "Pharmacopius Drugs Pvt Ltd",
  "Pharmadeep Remedies",
  "Pharmadent Remedies Pvt Ltd",
  "Pharmafer Pvt Ltd",
  "Pharmaforce Lab",
  "Pharmagic Lifesciences",
  "Pharmakon H & B Care Pvt Ltd",
  "Pharmakon Lifesciences",
  "Pharmakraft Therapeutics Pvt Ltd",
  "Pharmalead Healthcare Pvt Ltd",
  "Pharmalink Biotech",
  "Pharma-Medico (India) Pvt Ltd",
  "Pharmanova India Drugs Pvt Ltd",
  "Pharmanova Specialities",
  "Pharmanova Specialties Pvt Ltd",
  "Pharmaque Lifescience",
  "Pharmasynth Formulations Ltd",
  "Pharmatak Opthalmics Pvt Ltd",
  "Pharmatec India Pvt Ltd",
  "Pharmatech Healthcare",
  "Pharmatech India",
  "Pharmatech Solutions Pvt Ltd",
  "Pharmavite LLC",
  "Pharmawin Labs",
  "Pharmaxia Health Care",
  "Pharmayu Remedies",
  "Pharmazen Biotech",
  "Pharmcure Health Care",
  "Pharmed Ltd",
  "Pharmed Ltd[Rigel]",
  "Pharmed Pvt Ltd",
  "Pharmentis Biotax",
  "Pharmexia Labs Pvt Ltd",
  "Pharmia Biogenesis Pvt Ltd",
  "Pharmison Valentes Pharma Pvt Ltd",
  "Pharmiza Drugs Pvt Ltd",
  "Pharmtak Ophtalmics India Pvt Ltd",
  "Pharmus Biotech",
  "Pharmwell Pharmaceuticals",
  "Pharos Pharmaceuticals",
  "Pharose Remedies Ltd",
  "Pharvax Biosciences",
  "Pharybin Pharmaceuticals",
  "Phaway Pharmaceuticals Pvt Ltd",
  "PHC Healthcare Pvt Ltd",
  "Phelix Pharma",
  "Phenomax Pharmaceuticals",
  "Philanto Healthcare",
  "Philips India Ltd",
  "Philips Respironics India",
  "Phlox Exim (OPC) Pvt Ltd",
  "Phoenix Biotech",
  "Phoenix Life Science Pvt Ltd",
  "Phoenix Pharmaceuticals",
  "Phoenix Remedies Pvt Ltd",
  "Phrenos Pharma Pvt Ltd",
  "Phycrus Remedies",
  "Physicron Healthcare Pvt Ltd",
  "Phyto Specialities Pvt Ltd",
  "Phytochem Healthcare Pharmaceuticals",
  "Picasso Healthcare",
  "Pictus Pharmaceutical Pvt Ltd",
  "Pidek Pharmaceuticals",
  "Pidolma Healthcare Pvt Ltd",
  "Piecan Pharma Pvt Ltd",
  "Pieco Pharma",
  "Pifer Pharmaceuticals Pvt Ltd",
  "Pigeon India",
  "Pigeon India Pvt Ltd",
  "Piggik Pharmaceuticals Ltd",
  "Pilco Pharma Pvt Ltd",
  "Pillix Healthcare",
  "Pills and Remedies Pvt Ltd",
  "Pills India Lifecare Solutions",
  "Pinamed Drugs Pvt Ltd",
  "Pinarc Life Sciences",
  "Pinenut Life Care India Pvt Ltd",
  "Pink Health",
  "Pinklabs Biosciences Pvt Ltd",
  "Pinnacle Bioceuticals",
  "Pinnacle Medicare Private",
  "Pinnacle Medicare Pvt",
  "Pinq Story Pvt Ltd",
  "Pinto Pharma",
  "Pioma Chemtech Inc",
  "Pioneer Homoeo Pharma Pvt Ltd",
  "Pioneer Hygiene Sales Pvt Ltd",
  "Pioneer Impex",
  "Pioneer Pharma",
  "Pious Bioteca",
  "Piramal Enterprises Ltd",
  "Piramal Health Ltd",
  "Piramal Healthcare",
  "Piramal Healthcare Ltd",
  "Piramal Nextgen Industries Pvt Ltd",
  "Piramal Pharma Ltd",
  "Piramal Phytocare Ltd",
  "Pisces Laboratories Pvt Ltd",
  "Pistacia Pharmaceuticals Pvt Ltd",
  "Pithy Healthcare LLP",
  "Piyush Pharma",
  "PJ Pharmaceuticals",
  "Pla Remedies",
  "Placid Healthcare Pvt Ltd",
  "Placker Life Sciences",
  "Plaksha Industries Pvt Ltd",
  "Planet Ayurveda",
  "Planet Herbs Lifesciences",
  "Planet Nutrition Pvt Ltd",
  "Planetary Herbals",
  "Planetstar Pharmaglobal (OPC) Pvt Ltd",
  "Plantas Innovations Pvt Ltd",
  "Plasma Healthcare Pvt Ltd",
  "Plasma Lifesciences",
  "PlasmaGen Biosciences Pvt Ltd",
  "Plasmid Healthcare Pvt Ltd",
  "Plasoron biotech",
  "Plasti Surge Industries Pvt Ltd",
  "Pleasant Life Care Pharmaceuticals",
  "Pleasant Pharmaceuticals Pvt Ltd",
  "Pleazure Pharma",
  "Plena Remedies",
  "Plenteous Pharmaceuticals Ltd",
  "Plenum Biotech",
  "Plenum Biotech Pvt Ltd",
  "Plenus Pharmaceuticals Pvt Ltd",
  "Plethico Pharmaceuticals Ltd",
  "PLG Pharmaceutical",
  "Plight Pharma Pvt Ltd",
  "Plorax Pharma Pvt Ltd",
  "Pluck One",
  "Plumax eBusiness Solutions Pvt Ltd",
  "Plus India",
  "Plus Pharma",
  "Plusplus Lifesciences LLP",
  "Pluto Organics",
  "Pluzine Healthcare",
  "Pmed Healthcare",
  "PMT Bio Products",
  "PMV Healthcare",
  "PMV Nutrient Products Pvt Ltd",
  "PNBio Lab Pvt Ltd",
  "Pneu Pharma",
  "Pocket Pharmacy",
  "POCT Pvt Ltd",
  "Polaire Pharmaceuticals",
  "Polard Healthcare",
  "Polestar Power Industries",
  "Polka Tots",
  "Pollen Healthcure Pvt Ltd",
  "Pollux Biosciences",
  "Polo Pharmaceuticals Pvt Ltd",
  "Ponoogun Healthcare Pvt Ltd",
  "Popular United Laboratories",
  "Population Health Services India",
  "Porsa Laboratories",
  "Portia Global",
  "Posilive Pharmaceuticals",
  "Positif Life sciences",
  "Positive Medicare Pvt Ltd",
  "Powell Laboratories Pvt Ltd",
  "Power Labs",
  "Power Pharma",
  "PPL Healthcare",
  "Ppp Pharmaceuticals",
  "PR Flexmake Pvt Ltd",
  "Prabhat Ayurvedic Pharmacy",
  "Prabhat Surgical Cotton Pvt Ltd",
  "Pracam Pharmaceuticals Pvt Ltd",
  "Pradeep Ad India Pvt Ltd",
  "Praesidio Pharmaceuticals Pvt Ltd",
  "Pragati Biocare Pvt Ltd",
  "Pragmatics Labs Pvt Ltd",
  "Pragna Herbal & Naturals Pvt Ltd",
  "Praise Pharma",
  "Prakash Biopharma",
  "Prakriti Ayurveda",
  "Prakriti Rasayanshala",
  "Prakruti Pure Herbs",
  "Prameya Herbals",
  "Pramukh Swami Pharma Ltd",
  "Pranacharya Greenshield",
  "Pranshi Pharmaceutical",
  "Pranshu Healthcare",
  "Pranshul Lifescience Pvt Ltd",
  "Pranyog Healthcare",
  "Prashil Life Sciences Pvt Ltd",
  "Prasu Healthstep Llp",
  "Pravek Kalp Pvt Ltd",
  "Praxis Health Care",
  "Praxom Ayurveda",
  "Pray Healthcare",
  "Prayas Pharmaceuticals",
  "Prayosha Expo Pvt Ltd",
  "Precept Pharma Ltd",
  "Precia Pharma",
  "Precise Biopharma Pvt Ltd",
  "Precise Formulation",
  "Precise Lifescience",
  "Precision Coatings Pvt Ltd",
  "Precision Pharmaceuticals Pvt Ltd",
  "Prector Lifesciences",
  "Predot Lifesciences",
  "PreEmptive Meds Pvt Ltd",
  "Preeti Pharma",
  "PreetiKaya Lifestyle Solutions Pvt Ltd",
  "Pregna International Ltd",
  "Pregnitive Technologies Pvt Ltd",
  "Prekem Pharmaceuticals Pvt Ltd",
  "Prem Henna Pvt Ltd",
  "Prem Industries",
  "Prem Pharmaceuticals Ltd",
  "Prem Pharmaceuticals Pvt Ltd",
  "Premedium Pharmaceuticals Pvt Ltd",
  "Premexa Lifesciences Pvt Ltd",
  "Premier Nutraceuticals Pvt Ltd",
  "Prerna Remedies",
  "Prescribb Life Sciences Pvt Ltd",
  "Prescription Medicines Pvt Ltd",
  "Prescripto Life Science",
  "Presens Healthcare",
  "Preside Pharmaceutical Pvt Ltd",
  "Presinus Pharmaceuticals Pvt Ltd",
  "Pretium Pharmaceuticals",
  "Pretty Petals Pvt Ltd",
  "Prevego Healthcare & Research Pvt Ltd",
  "Prevego Healthcare And Research Pvt Ltd",
  "Prevento Pharma",
  "Prevento Remedies Corporation",
  "Preyuda Pharmaceutical",
  "Prg Pharma Pvt Ltd",
  "Pride Healthcare",
  "Pride Lifecare",
  "Pride Pharma",
  "Prigonex Healthcare",
  "Prim Research Laboratories India Ltd",
  "Prima Nature Care",
  "PrimaForce Supplements",
  "Prime Life Science Pvt Ltd",
  "Prime Medica Promotion Pvt Ltd",
  "Prime Medical Inc",
  "Prime Meditek",
  "Prime Pharma",
  "Primecure Medicine Pvt Ltd",
  "Primed Pharma",
  "Primer Healthcare Pvt Ltd",
  "Primero Life sciences Pvt Ltd",
  "Primo International (India)",
  "Primus Pharmaceuticals",
  "Primus Remedies Ltd",
  "Primus Remedies Pvt Ltd",
  "Prina Healthcare Pvt Ltd",
  "Princeton Healthcare Pvt Ltd",
  "Priomed Life Sciences Pvt Ltd",
  "Prisham Biotech Pvt Ltd",
  "Prism Life Sciences Ltd",
  "Prisma Healthcare",
  "Pristine Bionics Pharmaceuticals Pvt Ltd",
  "Pristine Formulations",
  "Pristine Organics Pvt Ltd",
  "Pristine Pearl Pharma Pvt Ltd",
  "Prisure Medicare",
  "Pritus Healthcare",
  "Priya Life Science",
  "Priyanka Food",
  "Priyanka Pharma",
  "Prize Healthcare",
  "PRK Healthcare",
  "PRM Life Sciences Pvt Ltd",
  "PRM Pharmaceuticals Pvt Ltd",
  "Pro Health Pharmaceuticals",
  "Pro Medix Health",
  "Pro Nature Organic Food Pvt Ltd",
  "Pro Vitalis Life care",
  "Proceed Formulations",
  "Prochem Bioceuticals Pvt Ltd",
  "Prochem Pharmaceuticals Pvt Ltd",
  "Prochem Turnkey Projects Pvt Ltd",
  "Procter & Gamble Hygiene and Health Care Ltd",
  "Procter & Gamble India Ltd",
  "Proctor Organics P Ltd",
  "Proctus Lifecare LLP",
  "Procure Life Science Pvt Ltd",
  "Procure Medica",
  "Procyon Life Sciences Pvt Ltd",
  "Proexcel Healthcare Pvt Ltd",
  "Profess Health Products P Ltd",
  "Profex Pharma",
  "Proffin International",
  "Profic Organic Ltd",
  "Profound Life Sciences",
  "Progen Nutraceuticals Pvt Ltd",
  "Progeniture Laboratories Pvt Ltd",
  "Prognosis Healthcare",
  "Progressive Life Care",
  "Prohealth Lifesciences",
  "Prohealth Vitamins Pvt Ltd",
  "Prohub Pharma Pvt Ltd",
  "Proindia Healthcare",
  "Proindia Organics LLP",
  "Prokure Life Sciences",
  "Prolab Nutrition LLC",
  "Proliva Pharmaceuticals",
  "Promed Pharmaceuticals Pvt Ltd",
  "Promison Healthcare LLP",
  "Prompt Cure Pharm Pvt Ltd",
  "Prontocure Pharma Pvt Ltd",
  "Propel Healthcare",
  "Prophex Health Care Pvt Ltd",
  "Proqol Healthcare",
  "Proqol Healthcare Pvt Ltd",
  "Prorient Pharmaceuticals Pvt Ltd",
  "Prosaic Pharmaceuticals Pvt Ltd",
  "Prosem Healthcare Pvt Ltd",
  "Prosit Healthcare Pvt Ltd",
  "Prosper Channel Lifescience India Pvt Ltd",
  "Prospero Healthcare",
  "Protech Biosystems",
  "Protech Telelinks",
  "Protect Lifesciences",
  "Protectlife Healthcare Pvt Ltd",
  "Proteger India",
  "Proteger Life Sciences",
  "Protein Scoop",
  "Proteus Health Care Pvt Ltd",
  "Protexial Life Science Pvt Ltd",
  "Proton Remedies Pvt Ltd",
  "Prov Foods Pvt Ltd",
  "Provado Healthcare Pvt Ltd",
  "Provectus Life Sciences",
  "Proveda Herbal Pvt Ltd",
  "Proventis Pharmaceuticals P Lt",
  "Providian Nutraceuticals",
  "Provis Biolabs Pvt Ltd",
  "Prozo Distribution Pvt Ltd",
  "PRS Medicare Pvt Ltd",
  "PRST Homeo Corporation",
  "Prunus Pharmaceuticals & Chemicals Pvt Ltd",
  "PSI India Pvt Ltd",
  "PSR Pharma",
  "Psychocare Health Pvt Ltd",
  "Psychotropics India Ltd",
  "Psycogen Captab",
  "Psyne Healthcare Pvt Ltd",
  "Psyniq Mediscience",
  "PT Invent India Pvt Ltd",
  "PT Otto Pharmaceutical Industries",
  "PTS Diagnostics",
  "Pulin Pharmaceuticals Pvt Ltd",
  "Pulse Pharmaceuticals",
  "Pulse Pharmaceuticals Pvt Ltd",
  "Pulwin Pharma Pvt Ltd",
  "Puneet Laboratories Pvt Ltd",
  "Puniska Healthcare",
  "Punjab Formulations Ltd",
  "Pure All",
  "Pure and Cure Healthcare Pvt Ltd",
  "Pure And Cure Hlth Care",
  "Pure And Cure Hlth Care (P)Ltd",
  "Pure Derma",
  "Pure Disinfect Vapour",
  "Pure Health Products Pvt Ltd",
  "Pure Natural Products Pvt Ltd",
  "Pure Passion",
  "Pure Science Biotech",
  "Pure Snacks Pvt Ltd",
  "Purecon Lenses Pvt Ltd",
  "Purecure Ayurveda Pvt Ltd",
  "Purederma Healthcare Pvt Ltd",
  "Pureganic Health Care",
  "Purely Nature",
  "Puremed Biotech",
  "PureOnly HomeMade Products",
  "Pureplay Skin Sciences Pvt Ltd",
  "Purewin Labs Pvt Ltd",
  "Purexa Global Pvt Ltd",
  "Purnayu Bio Science Pvt Ltd",
  "Puroshell Consumer Products LLP",
  "Purple Panda Fashion Pvt Lmited",
  "Pushkar Pharmaceuticals Pvt Ltd",
  "Pycer Healthcare",
  "Pykon Healthcare",
  "Pyl Healthcare",
  "Pynart Biotech Pharmaceuticals Pvt Ltd",
  "Pyrocare Pharmaceuticals Pvt Ltd",
  "Q Check Speciality Care",
  "Q4Q Health Solutions India Pvt Ltd",
  "QAAF Healthcare International",
  "Qantas Biopharma Pvt Ltd",
  "Q-Check Pharmaceuticals",
  "Qcl Life Science Pvt Ltd",
  "Qgensun Healthcare",
  "Qleen India",
  "QNT(Quality Nutrition Technology)",
  "Q'RAS Pharmaceuticals",
  "Qrative Care",
  "Qren Lifesciences Pvt Ltd",
  "Qrich Life Sciences",
  "QRIT Life Sciences",
  "QRS India Pvt Ltd",
  "Quadrant Consumer Products LLP,India",
  "Quadriga Biotech Pvt Ltd",
  "Quads Biotech Healthcare Pvt Ltd",
  "Quaint Labs Food Products",
  "Qualchem Healthcare Pvt Ltd",
  "Qualimed Bioscience",
  "Qualinut Food Pvt Ltd",
  "Qualitron Bio Medica Pvt Ltd",
  "Quality Ayurveda",
  "Quality Bestochem Formulations Pvt Ltd",
  "Quality Innovations & Pharmaceuticals",
  "Quality Pharma Products Pvt Ltd",
  "Qual-T Pharma",
  "Qualtra Pharmaceuticals",
  "Qualways Pharmaceuticals Pvt Ltd",
  "Quantaas Enterprises",
  "Quantis Biotech India Pvt Ltd",
  "Quantum Formulations",
  "Quantum Naturals India Pvt Ltd",
  "Quantumlife Sciences Pvt Ltd",
  "Que Pharma Pvt Ltd",
  "Queen Pharmaceuticals Ltd",
  "Quepreon Biologicals Pvt Ltd",
  "Querencia Healthcare Pvt Ltd",
  "Quessentials Pvt Ltd",
  "Quest Pharmaceuticals",
  "Quiagen Life Sciences Pvt Ltd",
  "Quick Clean Pvt Ltd",
  "Quick Dry",
  "Quick Heal Life Sciences",
  "Quince Lifesciences Pvt Ltd",
  "Quintos Pharmaceuticals Pvt Ltd",
  "Quintus Lifesciences Pvt Ltd",
  "Quiver Pharma",
  "Quixotic Pharma Pvt Ltd",
  "Quora Pharmaceuticals",
  "Quortical SR Life Sciences",
  "Qurax Pharma",
  "Qurewell Health Science Pvt Ltd",
  "Quria Healthcare",
  "Quzen Biotek",
  "R ",
  "R B",
  "R B ",
  "R B Pharmaceuticals",
  "R B Remedies Pvt Ltd",
  "R M Retail India",
  "R R Biotech",
  "R Steps Pharmaceutical",
  "R T Pharma",
  "Raasa Ayurveda Pvt Ltd",
  "Raayancha Herbals",
  "Raayancha Marketing",
  "Raayn Drugs",
  "Rabnew Pharma",
  "Race Pharmaceuticals pvt ltd",
  "Rachil Remedies Pvt Ltd",
  "Rachit Medi Sciences",
  "Raclon Labs",
  "Racmose Pharmaceuticals Pvt Ltd",
  "Racoon Pharmaceutical",
  "Raddison Health Care Pvt Ltd",
  "Radesh Pharmaceuticals",
  "Radhe Medtech Pvt Ltd",
  "Radhikas Fine Teas and Whatnots",
  "Radian Pharma",
  "Radiant Healthcare Pvt Ltd",
  "Radiant Pharmaceuticals Ltd",
  "Radicals Pharma Ltd",
  "Radico Remedies",
  "Radicon Laboratories Ltd",
  "Radicool Pharmaceutical Pvt Ltd",
  "Radicura Pharma pvt ltd",
  "Radion Pharma",
  "Radissa Pharma",
  "Radisson Pharmaceuticals",
  "Radisun Lifesciences",
  "Radius Drug Pvt Ltd",
  "Radius Healthcare Pvt Ltd",
  "Radius Wellness Inc ",
  "Radix Biotech Pvt Ltd",
  "Radix Pharmaceuticals",
  "Radley Pharma",
  "Raffles Pharmaceuticals",
  "Rafflesia Life Sciences Pvt Ltd",
  "Rahat Herbal Indutries",
  "Raikar Pharmaceuticals Pvt Ltd",
  "Rain Remedies",
  "Rainbow Backward Linkages",
  "Rainbow Laboratories (india) Pvt Ltd",
  "Rains Healthcare LLP",
  "Raizel Healthcare",
  "Raj Bioceuticals",
  "Rajah Healthy Acres Pvt Ltd",
  "Rajaram Ayurved Bhavan",
  "Rajaram Consumer Care",
  "Rajasthan Aushadhalaya Pvt Ltd",
  "Rajat Care LLP",
  "Rajgurus Foods",
  "Rajnish Wellness Ltd",
  "Rakesh Pharmaceuticals",
  "Raklik Mediscience",
  "Raksh Pharmaceuticals",
  "Rakyan Beverages Pvt Ltd",
  "RAL Consumer Products Ltd",
  "Rallis India Ltd",
  "Ralmas Medicare Pvt Ltd",
  "Ralson Remedies Pvt Ltd",
  "Ralycos LLP",
  "Ram Lal Laboratories Pvt Ltd",
  "Rama Lifescience Pvt Ltd",
  "Rama Vision Ltd",
  "Raman & Weil Pvt Ltd",
  "Raman Weil Pvt Ltd",
  "Ramburg Pharma",
  "Rameshwar Sante Pvt Ltd",
  "Ramkes Life Science",
  "Ramkrishna Vidyut Ayurvedic Pharmacy",
  "Ram-Nath & Co Pvt Ltd",
  "Ramose Laboratories Pvt Ltd",
  "Rampton Healthcare",
  "Ramson Health Care",
  "Ramvey Healthcare",
  "Ramylabs Health Care",
  "Rana Herbals",
  "Ranbass Pharmaceuticals",
  "Ranbaxy Amphion",
  "Ranbaxy Laboratories (Sun Pharma)",
  "Ranbaxy Laboratories Ltd",
  "Ranbaxy Labs Ltd(Crosland)",
  "Rancaster Pharmatic",
  "Rangelo Rajasthan",
  "Ranikem Pharmaceuticls Pvt Ltd",
  "Ranmarc Labs",
  "Ranofy Biotech Pvt Ltd",
  "Raphael Pharmaceuticals Pvt Ltd",
  "Rapid Life Drugs & Healthcare",
  "Rapid Solutions (I) Pvt Ltd",
  "Rapidchem Healthcare",
  "Rapross Pharmaceuticals Pvt Ltd",
  "Raptakos Brett & Co Ltd",
  "Raptakos Brett & Company Limit",
  "Raptakos Brett & Company Ltd",
  "Raptam Healthcare",
  "Raptas Life Care Pvt Ltd",
  "Rasalila India",
  "Rasayan Vidhya",
  "Rasayanam Enterpises",
  "Rasco Life Sciences Pvt Ltd",
  "Rashika Medicare Pvt Ltd",
  "Raskam Healthcare Pvt Ltd",
  "Rasna Pvt Ltd",
  "Rass Health Care",
  "Rasual Pharma",
  "Ratan Ayurvedic Sansthan",
  "Ratangiri Innovations Pvt Ltd",
  "Ratchet Biotech Pvt Ltd",
  "Rathi Laboratories (Hindustan) Pvt Ltd",
  "Rattan Organic Foods Pvt Ltd",
  "Raulette Formulations",
  "Rauwolfia Pharma Pvt Ltd",
  "Ravenbhel Pharmaceuticals Pvt Ltd",
  "Ravenmac Pharmaceuticals Pvt Ltd",
  "Ravian Life Science Pvt Ltd",
  "Ravian Lifescience Pvt Ltd",
  "Ravian Pharmaceuticals Ltd",
  "Ravic Healthcare",
  "Ravid Healthcare Pvt Ltd",
  "Ravino Industries Pvt Ltd",
  "Ravinum Impex Pvt Ltd",
  "Raviolis Healthcare",
  "Ravya Cosmetics",
  "Rawlley Healthcare Pvt Ltd",
  "Rax Health Care Pvt Ltd",
  "Raxin Health Care",
  "Raxon Lifesciences Pvt Ltd",
  "Raxter Pharma",
  "Ray Remedies Pvt Ltd",
  "Raybros Medicaments Pvt Ltd",
  "Rayh Health Care",
  "Raymed Pharmaceuticals Ltd",
  "Raymedica Pharmaceuticals",
  "Raymond Consumer Care Pvt Ltd",
  "Raymond Pharmaceutical Pvt Ltd",
  "Raynox Healthcare Pvt Ltd",
  "Rayon Healthcare",
  "Rayon Medicare Pvt Ltd",
  "Rayon Pharmaceutical",
  "Rayon Pharmaceuticals Ltd",
  "Rayon Remedies",
  "Rayrise Healthcare",
  "Rays Culinary Delights Pvt Ltd",
  "Rays Healthcare",
  "Razekan Biolabs",
  "Razenta pharmaceuticals Pvt Ltd",
  "Razor Club",
  "Rbco Pharmaceuticals Pvt Ltd",
  "Rbs Pharmaceutical",
  "Re Lead Lifescience Pvt Ltd",
  "Real Care Life Sciences",
  "Realcade Lifesciences Pvt Ltd",
  "Realman Lifestyle Pvt Ltd",
  "Realmed Pharma",
  "Reantis Pharmaceuticals Pvt Ltd",
  "Re-Assert Pharma Pvt Ltd",
  "Reba Pharmaceuticals",
  "Rebanta Health Care (P) Ltd",
  "Rebel Mass Exports",
  "Recent Healthcare Ltd",
  "Rech Elist Pharma",
  "Reckitt Benckiser",
  "Reckitt Benckiser India Pvt Ltd",
  "Recnal Biomed",
  "Reco Drugs",
  "Recombigen Laboratories Pvt Ltd",
  "Recon Healthcare Ltd",
  "Recon Pharmaceutical Ltd",
  "Recoupe Pharma",
  "Recourse Health Care",
  "Rectrix Lifesciences",
  "Recure Pharma",
  "Recxia Formulations Pvt Ltd",
  "Red Bross Laboratories Pvt Ltd",
  "Red Cross Pharmaceutics Pvt Ltd",
  "Red Planet Pharmaceuticals",
  "Red Plus Pharma",
  "Redcliffe Hygiene Pvt Ltd",
  "Reddy Pharmaceuticals",
  "Redic Labs",
  "Redicine Laboratories Pvt Ltd",
  "Redisun Pharma",
  "Redix Life Care Pvt Ltd",
  "Redline Healthcare",
  "Redmax Pharma",
  "Redmed Medical Services",
  "Redosine Pharmaceutical Pvt Ltd",
  "Redplum Pvt Ltd",
  "Redress Pharma",
  "RedRoom Technology Pvt Ltd",
  "RedRose Laboratories",
  "Redson Laboratories Pvt Ltd",
  "Redvia Pharmaceutical",
  "Redyns Remedies",
  "Reeha Herbals",
  "Reekomed Healthcare and Surgical Products",
  "Refa-el lifesciences",
  "Reflaction Healthcare",
  "Regain Laboratories",
  "Regal Healthcare",
  "Regal Pharmaceuticals",
  "Regalia Pharmaceuticals (I) Pvt Ltd",
  "Regaliz India Ltd",
  "Regaliz Medicare Ltd",
  "Regaliz Medicare Pvt Ltd",
  "Regardia Pharmaceuticals",
  "Regenesis Meditech Pvt Ltd",
  "Regenix Drugs Ltd",
  "Regent Ajanta Biotech",
  "Regent Pharmaceuticals",
  "Regnent Shine Pvt Ltd",
  "Regoshin Healthcare Pvt Ltd",
  "Regulus Pharmaceuticals",
  "Reicuri Pvt Ltd",
  "Reign Pharma Pvt Ltd",
  "Rejli Healthcare Pvt Ltd",
  "Rejoice Healthcare",
  "Rejova Organic Healthcare Pvt Ltd",
  "Rejoym Biotech Pvt Ltd",
  "Rejunacia Healthcare Pvt Ltd",
  "Rejuva Healthcare",
  "Rejuven Pharma",
  "Rejuvnate Lifecare Pvt Ltd",
  "Rekin Pharma Pvt Ltd",
  "Rekindletech Pharmaceuticals Pvt Ltd",
  "Rekke Personal Care Pvt Ltd",
  "Reklen Pharma",
  "Reko Medikament",
  "Rekvina Laboratories Ltd",
  "Relacion Pharmaceuticals",
  "Relax Biotech Pvt Ltd",
  "Relax Pharma",
  "Releaf Lifesciences Pvt Ltd",
  "Relevance Nutraceuticals & Food Division",
  "Reliable Medicare Pvt Ltd",
  "Reliance Formulation Pvt Ltd",
  "Reliance Life Sciences",
  "Reliance Lifecare Pvt Ltd",
  "Relic Biotechnology Pvt Ltd",
  "Relicon Pharmaceutical Pvt Ltd",
  "Relics Pharmaceutical Pvt Ltd",
  "Relief Formulations Pvt Ltd",
  "Relief Pharma",
  "Relish Pharmaceuticals",
  "Rellicure Pharma",
  "Relphia Lifesciences",
  "Reltic Labs",
  "Reltsen Health Care",
  "Relward Pharma",
  "Rely on Pharmaceuticals",
  "Remalthea Pharmaceuticals Pvt Ltd",
  "Remandish Pharma Pvt Ltd",
  "Remantra Pharma",
  "Remark Healthcare",
  "Remed Health Care Pvt Ltd",
  "Remedial Healthcare",
  "Remedies Pharmaceuticals (India) Ltd",
  "Remedies Pharmaceuticals India Ltd",
  "Remedio Pharmacon",
  "Remedise Pharma LLP",
  "Remedium Healthcare Pvt Ltd",
  "Remedium Pharma Pvt Ltd",
  "Remedo Wellness Pvt Ltd",
  "Remedy Healthcare",
  "Remedy Life Sciences India Pvt Ltd",
  "Remeliv-D S ",
  "Remember India Medicos",
  "Remeris Pharma Pvt Ltd",
  "Remex Pharmaceuticals Ltd",
  "Remington Life Sciences LLP",
  "Remit Healthcare",
  "Remora Remedies Pvt Ltd",
  "Remph Lifesciences",
  "Rena Exports Pvt Ltd",
  "Renace Pharmaceuticals",
  "Renaissance Pharmaceuticals",
  "Renakart Life Sciences Pvt Ltd",
  "Renance Pharmaceuticals",
  "Renata Healthcare",
  "Renata Ltd",
  "Renaunt Pharma",
  "Renauxe Pharma India Pvt Ltd",
  "Rencord Life Sciences Pvt Ltd",
  "Rene Lifescience",
  "Renivo Healthcare Pvt Ltd",
  "Rennew Biotech",
  "Rennovs Healthcare",
  "Renocare Pharmaceuticals Pvt Ltd",
  "Renocis Pharmaceuticals Pvt Ltd",
  "Renorich Pharmaceuticals",
  "Renost Pharma",
  "Renova Healthcare",
  "Renova Life sciences Pvt Ltd",
  "Renova Nutrition",
  "Renova Pharmaceuticals",
  "Renovision Exports Pvt Ltd",
  "Renowed Life Sciences",
  "Rensan Drugs Pvt Ltd",
  "Renspur Health Care Pvt Ltd",
  "Renvic Lifesciences Pvt Ltd",
  "Repellers India LLP",
  "Repens Healthcare India Pvt Ltd",
  "Replica Remedies",
  "Reprasha Pharma",
  "Reps Pharmaceuticals",
  "Reqwell Pharma",
  "Rescuers Life Sciences Ltd",
  "Rescuetech Remedies",
  "Research Medicure Pvt Ltd",
  "Reserca Health Care Pvt Ltd",
  "Resilent Cosmeceuticals Ltd",
  "Resilient Cosmeceuticals Pvt Ltd",
  "Resilient Cosmecueticals Pvt Ltd",
  "Resilio Pharmaceuticals Pvt Ltd",
  "Resinno Biotech Pvt Ltd",
  "ResMed India Pvt Ltd",
  "Resolute Healthcare",
  "Resonance Laboratories Pvt Ltd",
  "Respionix Healthcare Pvt Ltd",
  "Respisure Pharma Pvt Ltd",
  "Restech Pharma Pvt Ltd",
  "Retec Formulations Pvt Ltd",
  "Reticine Pharmaids Ltd",
  "Retort Pharma Pvt Ltd",
  "Retra Life Science Pvt Ltd",
  "Retro Life Sciences",
  "Reuel Pharma",
  "Reunis Healthcare Pvt Ltd",
  "Revanex Pharmaceuticals Pvt Ltd",
  "Revenza Pharmaceuticals Pvt Ltd",
  "Reviera Overseas",
  "Revinto Life Science Pvt Ltd",
  "Revival Healthcare",
  "Revival Pharma",
  "Revive Healthcare",
  "Revive Pharma",
  "Revlon Pharma Pvt Ltd",
  "Revolution Pharmtech",
  "Revomed Pvt Ltd",
  "Rewine Pharmaceutical",
  "Rex (U&A) Remedies Pvt Ltd",
  "Rexcin Pharmaceuticals Pvt Ltd",
  "Rexion Heathcare Pvt Ltd",
  "Rexit Pharmaceutical",
  "Rexo Products",
  "Rexus Healthcare",
  "Reylink Pharmaceuticals",
  "Rezcrit Healthcare",
  "Rezichem Healthcare Pvt Ltd",
  "Rezicure Pharmaceuticals",
  "Rezstar Pharmaceutical Pvt Ltd",
  "Rezvacure Biotech",
  "RG Biocosmetic Pvt Ltd",
  "RG Healthworld",
  "RG Pharma Health Care",
  "RGB Healthcare Pvt Ltd",
  "Rgeno Pharmaceuticals Pvt Ltd",
  "Rgg Cosmetics Pvt Ltd",
  "RGI Meditech Pvt Ltd",
  "Rhea Retail Pvt Ltd",
  "Rhexia Healthcare Pvt Ltd",
  "Rhine Biogenics Pvt Ltd",
  "Rhinestone Pharma",
  "Rhino Research Products",
  "Rholence Pharmaceuticals",
  "Rhombus Pharma Pvt Ltd",
  "Rhone-Poulenc India Ltd",
  "Rhophic Health Care",
  "RHP Health Care Pvt Ltd",
  "Rhumasafe Pharmaceutical",
  "Rhydburg Pharmaceuticals Ltd",
  "Rhys Healthcare Pvt Ltd",
  "Rhythm Biotech Pvt Ltd",
  "Rhythm Medicare",
  "Rhythm Pharma India Pvt Ltd",
  "Rhythmed Pharma Pvt Ltd",
  "Ria Lifesciences Pvt Ltd",
  "Rian Healthcare",
  "Rianel Pharmaceuticals",
  "Riasma Lifesciences Pvt Ltd",
  "Riasmo Lifesciences",
  "Ricaptos Biocare Pvt Ltd",
  "Ricaria Support Services Pvt Ltd",
  "Rich Pharmaceuticals",
  "Richcure Pharmaceuticals Llp",
  "Richfaith Pharmaceuticals",
  "Richfield India Pharmaceuticals Pvt Ltd",
  "Richie Laboratories Ltd",
  "Richlyns Health Care",
  "Rickward Healthcare",
  "Riddhi Siddhi Enterprises",
  "Ridgecure Pharma",
  "Ridham Medicare",
  "Ridhi Sidhi Marketing",
  "Ridhima Biocare",
  "Ridill Healthcare Pvt Ltd",
  "Ridley Life Science Pvt Ltd",
  "Riffway International",
  "Rigil Biotech Pvt Ltd",
  "Rikaltis Healthcare",
  "Riki Therapeutics",
  "Rilacs Ayurveda",
  "Rimo Pharmaceuticals Pvt Ltd",
  "Rimoy Naturals Pvt Ltd",
  "Rindz Pharma",
  "Rinew GreenProduct Pvt Ltd",
  "Riobliss Life Sciences Pvt Ltd",
  "Riocure Pharmaceuticals Pvt Ltd",
  "Riomedica Healthcare Pvt Ltd",
  "Rion Lifesciences Pvt Ltd",
  "Riozen Pharmaceuticals",
  "Ripca Pharmaceutical Pvt Ltd",
  "Ripharma Laboratories Ltd",
  "Ripped Healthcare Pvt Ltd",
  "Riqfame Critical Care Pvt Ltd",
  "Risen Pharmaceuticals",
  "Rishab Healthcare Pvt Ltd",
  "Rishlen Healthcare Pvt Ltd",
  "Rising Life Science Pvt Ltd",
  "Rising Sun Aromas & Spirituals Pvt Ltd",
  "Rite Labs",
  "Ritelife Healthcare",
  "Ritelth Medipharma Pvt Ltd",
  "Ritestar Products Pvt Ltd",
  "Rithviks India",
  "Ritsan Healthcare Pvt Ltd",
  "Ritz Pharma",
  "Rivan Pharma",
  "Rivera Pharma",
  "Riverlife Research Technology Pvt Ltd",
  "Riverside Impex",
  "Rivinor Pharma",
  "Rivpra Formulation Pvt Ltd",
  "Riya Biocare Inc",
  "Riyadh Pharmaceutical",
  "Rizer Healthcare Pvt Ltd",
  "Rizpan Life Sciences Pvt Ltd",
  "RK Medicare Pvt Ltd",
  "RK Pharmaceuticals",
  "RKG Pharma",
  "RKM Health Care",
  "Rlv Lifesciences Pvt Ltd",
  "RMPL Pharma LLP",
  "Rmps Organics Pvt Ltd",
  "RND Laboratories Pvt Ltd",
  "Rnova Biologicals Pvt Ltd",
  "Robin Herbal Health Care Products",
  "Robin Pharmaceuticals Ltd",
  "Robins Remedies Pvt Ltd",
  "Robo Med Marketing Pvt Ltd",
  "Robust Pharmaceuticals",
  "Roche Diagnostics India Pvt Ltd",
  "Roche Products India Pvt Ltd",
  "Roche Products Pvt Ltd",
  "Rock Medicare",
  "Rockhopper Systems Pvt Ltd",
  "Rockmed Pharma Pvt Ltd",
  "Rockwood Healthcare",
  "Rods N Cones Pharmaceuticals Pvt Ltd",
  "Rog Pharmaceuticals Pvt Ltd",
  "Rogers biotech",
  "Rohini Chemicals Pvt Ltd",
  "Rohto Pharma (India) Pvt Ltd",
  "Rokkwinn Healthcare",
  "Rokmex Biocare Pvt Ltd",
  "Rollick Healthcare Pvt Ltd",
  "Rollins International Pvt Ltd",
  "Rollys Pharmaceuticals",
  "Rolsmed Pharma",
  "Roma Pharma Pvt Ltd",
  "Roman Healthcare Pvt Ltd",
  "Roman Lifesciences Pvt Ltd",
  "Roman Pharma",
  "Romark Health Care",
  "Romas Remedies",
  "Romson Scienifice &Surgicals",
  "Romsons Scientific & Surgical Pvt Ltd",
  "Romz Pharmaceuticals",
  "Ron Biogenix Pvt Ltd",
  "Ronak Laboratories",
  "Ronald Pharmaceuticals",
  "Ronam Healthcare Pvt Ltd",
  "Rondeyvoo Eurasia (OPC) Pvt Ltd",
  "Ronish Bioceuticals",
  "Ronnie Coleman",
  "Ronson Pharmaceutical",
  "Ronyd Healthcare Pvt Ltd",
  "Roosevelt Lifesciences Pvt Ltd",
  "Rooted Peepul",
  "Rooters Pharma Pvt Ltd",
  "Roots & Herbs",
  "Roots Life Sciences (India) Pvt Ltd",
  "Roots Medical",
  "Roots Pharma House Pvt Ltd",
  "Roottree Natures Pvt Ltd",
  "Ropoz Lifesciences Pvt Ltd",
  "Roris Pharmaceuticals Pvt Ltd",
  "Rosa Lifesciences",
  "Rosch Elmer Pharmaceuticals Pvt Ltd",
  "Rose Pharmaceuticals & Healthcare Pvt Ltd",
  "Rose Pharmaco Pvt Ltd",
  "Roseate Drugs",
  "Roseate Medicare",
  "Rosecure Pharma Pvt Ltd",
  "Rosefinch LifeSciences",
  "Roselabs Healthcare Pvt Ltd",
  "Rosemont Medicare Pvt Ltd",
  "Rosette Aeon Life Care",
  "Rosette Aeon Lifecare",
  "Rosette Pharmaceuticals",
  "Rosewood Healthcare",
  "Rosnet Pharma",
  "Rossmax International Ltd",
  "Rosswelt Biosciences",
  "Rostrumed Biotech Pvt Ltd",
  "Roucher Biosciences Pvt Ltd",
  "Roussel Laboratories Pvt Ltd",
  "Rouzel Pharma",
  "Rowan Bioceuticals Pvt Ltd",
  "Rowez Life Sciences Pvt Ltd",
  "Rowlinges Life Science",
  "Roxas Pharmaceuticals Pvt Ltd",
  "Roxters Pharma",
  "Royal Black Pearl",
  "Royal Canin",
  "Royal Dry Fruits Pvt Ltd",
  "Royal Herbal Land Pvt Ltd",
  "Royal Labs",
  "Royal Nature Land Pvt Ltd",
  "Royal Pharma",
  "Royal Research Lab Pvt Ltd",
  "Royal Sapphire Remedies",
  "Royal Scottish Remedies",
  "Rozer Pharmaceuticals Pvt Ltd",
  "Rozyon Biotech",
  "RP Healthcare Pvt Ltd",
  "RPG Life Sciences Ltd",
  "Rps Biotech Pvt Ltd",
  "RPVP Pharmaceuticals Pvt Ltd",
  "RR Life Sciences Pvt Ltd",
  "RR Pharmacia",
  "RRAC Healthcare Pvt Ltd",
  "RSG Herbal House",
  "RSG Nutrition And Health Care Pvt Ltd",
  "RSL Bioscience Pvt Ltd",
  "RSM Kilitch Pharma Pvt Ltd",
  "RSM Lifecare India Pvt Ltd",
  "RSM Multilink LLP",
  "RSP Nutrition",
  "RSR Healthcare",
  "RSV Health Solutions",
  "Ruan Life Sciences Pvt Ltd",
  "Rubicon Consumer Healthcare Pvt Ltd",
  "Rubicon Health Care Pvt Ltd",
  "Rubra Pharmaceuticals Ltd",
  "Rubrics Healthcare Pvt Ltd",
  "Rubsun Polymers LLP",
  "Ruby Enterprise",
  "Ruchi Soya Industries Ltd",
  "Rudiment Life Science Pharmaceutical Pvt Ltd",
  "Rudolf Basins Inc",
  "Rudolf Life Sciences Pvt Ltd",
  "Rudra Forever",
  "Rudraksha Pharmaceuticals",
  "Ruffles Pharma Pvt Ltd",
  "Rumik Lifescience Pvt Ltd",
  "Runyon Healthcare India Pvt Ltd",
  "Runyon Pharmaceuticals Pvt Ltd",
  "Rupas Biological Pvt Ltd",
  "Rupashree Health Care Pvt Ltd",
  "Rural Treasures Pvt Ltd",
  "Rusan Healthcare",
  "Rusan Pharma Ltd",
  "Rusi Remedies P Ltd",
  "Rusoma Laboratory",
  "Rut Pharma Pvt Ltd",
  "Ruth Biotech Pvt Ltd",
  "RUV Pharma LLP",
  "Rx Healthcare Ltd",
  "Rx Solution",
  "Rxhomeo Pvt Ltd",
  "Rybin Pharma",
  "Rycom Electron Co Ltd",
  "Rydan Pharmaceuticals Pvt Ltd",
  "Ryddx Pharmetry Pvt Ltd",
  "Ryland Healthcare",
  "Rylox Healthcare Pvt Ltd",
  "rynel clifton pharma pvt ltd",
  "Ryon Pharma",
  "Ryte Pharmacia",
  "Rytus Therapeutics Ltd",
  "Ryze Lifecare",
  "Ryzhil Lifesciences Pvt Ltd",
  "S & M Pharmacy",
  "S & S Healthcare",
  "S A Herbal Bioactives LLP",
  "S A Remedies",
  "S B J International",
  "S Bee Life Sciences",
  "S Celereun Pharmaceutical",
  "S G Phyto Pharma Pvt Ltd",
  "S H Pharmaceuticals Ltd",
  "S Kundu & Sons",
  "S M A Pharmaceuticals",
  "S P",
  "S P Pharma",
  "S P Pharmaceuticals",
  "S R Pharmaceuticals",
  "S S",
  "S S Axon",
  "S S M Pharmaceuticals",
  "S S Pharma Lifescience (P) Ltd",
  "S S Pharma Lifescience P Ltd",
  "S V Biovac Pharmaceuticals Pvt Ltd",
  "S&S Health Care",
  "S2 Biogenics Healthcare",
  "S9 Pharma",
  "SA Nutrition",
  "SA Remedies",
  "Saan Labs",
  "Saanvika Pharma",
  "Saaol Pharma Pvt Ltd",
  "Saar Biotech",
  "Sab Life Care Pvt Ltd",
  "Sabme Pharmaceuticals Pvt Ltd",
  "SABS Pharmaceutical Pvt Ltd",
  "SAC Pharmaceuticals",
  "Sacem Formulations",
  "Sache Wellness",
  "Sachio Pharma Bangalore Pvt Ltd",
  "Sadar Laboratories Pvt Ltd",
  "Sadok Pharma",
  "SAF Fermion Ltd",
  "Safe Life Care",
  "Safe Pharmaceuticals",
  "Safe Remedis Pharmaceuticals LLP",
  "Safe U Pharmaceuticals",
  "Safecon Lifesciences",
  "Safeguard Biotics Pvt Ltd",
  "Safeguard Lifesciences Pvt Ltd",
  "Safetech Formulation",
  "Safford Lifesciences",
  "Saffron Formulation Pvt Ltd",
  "Saffron Formulations Pvt Ltd",
  "Saffron Medicare Pvt Ltd",
  "Saffron Therapeutics Pvt Ltd",
  "Safron Pharmaceuticals Pvt Ltd",
  "SAG Health Science Pvt Ltd",
  "SAG Pharma Pvt Ltd",
  "Saga Laboratories",
  "Sagar Pharma",
  "Sagar Pharmaceuticals",
  "Sage Apothecary",
  "Sage Care",
  "Sage Herbals",
  "Sahaj Pharmaceuticals Pvt Ltd",
  "Sahajanand Life Sciences Pvt Ltd",
  "Saharsh Pharma",
  "Sahet India",
  "Sahnisons Pvt Ltd",
  "Sahyog Wellness",
  "Sai Healthcare",
  "Sai International",
  "Sai Lakshmi Medical Corporation",
  "Sai Life Sciences",
  "Sai Mirra Innopharm Pvt Ltd",
  "Sai Moreshwar Healthcare",
  "Sai Parenterals Ltd",
  "Sai Sarvaa Biotech Pvt Ltd",
  "Saibliss Healthcare Pvt Ltd",
  "Saiboon Lifecare Pvt Ltd",
  "Saichem Pharma",
  "Saifee Pharma",
  "Saify Health Care and Medi Devices India Pvt Ltd",
  "Saillon Pharma ",
  "Saimark Biotech Pvt Ltd",
  "Saimed Formulations",
  "Sain Medicaments Pvt Ltd",
  "Saint Pharmaceuticals",
  "Saintlife Mediscience Pvt Ltd",
  "Saipro Agrotech",
  "Saisan Health Care",
  "Saisir Global Technologies Pvt Ltd",
  "Saitech Medicare Pvt Ltd",
  "Saiva Healthcare",
  "Saivay Medilinks",
  "Saivis Healthcare Pvt Ltd",
  "Saiwon Healthcare(India) Pvt Ltd",
  "Saksham Pharmaceuticals",
  "Saksham Pharmaceuticals India Ltd",
  "Sakshit Healthcare",
  "Salas Pharmaceuticals",
  "Salasar Blessed Herbals",
  "Salene Biotech Pvt Ltd",
  "Saliva Lifesciences Pvt Ltd",
  "Salniz Healthcare",
  "Salo Orthotics",
  "Sals Remedies LLP",
  "Salsburg Pharmaceuticals Pvt Ltd",
  "Salsim Pharma Pvt Ltd",
  "Salter Housewares",
  "Saltire Remedies Pvt Ltd",
  "Salubres Formulations Pvt Ltd",
  "Salubria Pharmaventures Pvt Ltd",
  "Salubry Life Sciences",
  "Salud Care India Pvt Ltd",
  "Saludar Healthcare Pvt Ltd",
  "Salus Healthcare",
  "Salutaire Living",
  "Salvador Visiontech",
  "Salve Pharmaceuticals Pvt Ltd",
  "Salveo Life Sciences Ltd",
  "Salveo Lifecare",
  "Salves Life Sciences",
  "Salvia Healthcare",
  "Salvia Labs Pvt Ltd",
  "Salvo Healthcare Pvt Ltd",
  "Salvus Pharma",
  "Sama Biotech",
  "Samajeswara Pharma Pvt Ltd",
  "Samarth International",
  "Samarth Life Science Pvt Ltd",
  "Samarth Life Sciences Pvt Ltd",
  "Samarth Pharma Pvt Ltd",
  "Sambrik Medisciences Pvt Ltd",
  "Samco Pharma Works Pvt Ltd",
  "Samcure Drugs & Pharmaceuticals Pvt Ltd",
  "Samika Nutricare Pvt Ltd",
  "Samridhi Pharmacare",
  "Sams Pharma",
  "Samskruthy Pharma Pvt Ltd",
  "Samson Laboratories Pvt Ltd",
  "Samson Scientifics & Surgicals",
  "Samvee Pharmaceuticals Pvt Ltd",
  "Samyog Health Foods Pvt Ltd",
  "San Biomedics Pvt Ltd",
  "San Boston Life Sciences",
  "San Fegan Biotech Pvt Ltd",
  "San Houston",
  "SAN Nutrition",
  "Sanador Healthcare",
  "Sanat Products Ltd",
  "Sanatio Pharmaceuticals Pvt Ltd",
  "Sanative Remedies",
  "Sanatra Health Care",
  "Sanbioo Healthcare Pvt Ltd",
  "Sanbury Pharmaceuticals Pvt Ltd",
  "Sancheti Biolabs",
  "Sanctum Healthcare Pvt Ltd",
  "Sanctus Global Lifesciences Pvt Ltd",
  "Sandeep Organics Lab",
  "Sandhya Healthcare Remedy Impel Pvt Ltd",
  "Sandika Pharmaceuticals Pvt Ltd",
  "SandMartin Pharmaceuticals Pvt Ltd",
  "Sandook Sutras",
  "Sandor Orthopedics",
  "Sandoz Pvt Ltd",
  "Sandu Pharmaceuticals Pvt Ltd",
  "Sanes Pharmaceuticals",
  "Sanford Health",
  "Sangam Healthcare Products Ltd",
  "Sanghvi Beauty & Technologies Pvt Ltd",
  "Sangreal Healthcare",
  "Sangrose Laboratories Pvt Ltd",
  "Sanguine Remediesindia Pvt Ltd",
  "Sanify Healthcare Pvt Ltd",
  "Sanitex Pharmaceuticals",
  "Sanity Pharma",
  "Sanix Formulation Pvt Ltd",
  "Sanjeevani Ayushcare Pvt Ltd",
  "Sanjeevani Bio-Tech Pvt Ltd",
  "Sanjeevani Surgicals",
  "Sanjeevi Remedies",
  "Sanjivani Drugs Pvt Ltd",
  "Sanjivani Parenteral Ltd",
  "Sanjivini Biotech Pvt Ltd",
  "Sankalp Pharmaceuticals Pvt Ltd",
  "Sankalp Therapeutics",
  "Sanket Pharmaceuticals Pvt Ltd",
  "Sanko Biotech",
  "Sanlife Scince Llp",
  "Sanmai Pharma",
  "Sanmed Healthcare Pvt Ltd",
  "Sanmitra Life Care",
  "Sannap Healthcare",
  "Sannova Biotech Pvt Ltd",
  "Sano Health Care Products Pvt Ltd",
  "Sano Life Sciences Pvt Ltd",
  "Sanoff Labs",
  "Sanofi Aventis",
  "Sanofi India Ltd",
  "Sanofi India Ltd(Universal)",
  "Sanofi Synthelabo India Ltd",
  "Sanofi-Aventis Pakistan",
  "Sanogen Pharma Pvt Ltd",
  "Sanomed Health Care",
  "SanoreX Pharma",
  "Sanoti Laboratories",
  "Sanrai Retails",
  "Sanray Derma Pvt Ltd",
  "Sanrey Therapeutics",
  "Sanrose Nutrachem",
  "Sanshis Pharmaceuticals",
  "Sansill Formulations",
  "Sansill Pharma",
  "Sanstuti Pharmaceuticals Pvt Ltd",
  "Sansu Health Care",
  "Sansu Life Sciences",
  "Sansui Electronics Pvt Ltd",
  "Sant Kripa Pharmacy",
  "Santcroz Healthcare",
  "Sante Mernaud Pharceuticals Pvt Ltd",
  "Sante Vision Lab Pvt Ltd",
  "Santeforeva Healthcare Pvt Ltd",
  "Santen India Pvt Ltd",
  "Santiago Lifesciences Pvt Ltd",
  "Santis Pharmaceuticals Pvt Ltd",
  "Santo Formulations",
  "Santulan Ayurveda",
  "Sanus Life Sciences",
  "Sanvesh Biotech",
  "Sanvi Pharma",
  "Sanvi Pharmaceuticals",
  "Sanz Pharmaceuticals Pvt Ltd",
  "Sanzyme Ltd",
  "Sap Biotech (I) Pvt Ltd",
  "Sapat & Company (Bombay) Pvt Ltd",
  "Sapco Laboratories Pvt Ltd",
  "Saphnix Life Sciences",
  "Sapien Body",
  "Sapiens Labs",
  "Sapiens Labs Pvt Ltd",
  "Sapient Laboratories Pvt Ltd",
  "Sapinox Pharmaceuticals",
  "Sapson Pharma",
  "Saptrishi Herbals Pvt Ltd",
  "Sara Healthcare",
  "Sara Remedies",
  "Saraan Remedies",
  "Sarab Herbs",
  "Sarabhai Chemicals Ltd",
  "Sarabhai Piramal Pharmaceuticals Pvt Ltdi",
  "Sarada Homoeo Laboratory",
  "Saraya Mystair Hygiene",
  "Sarb Pharmaceuticals",
  "Sarcoma Remedies Pvt Ltd",
  "Sargas Life Sciences Pvt Ltd",
  "Sarian Healthcare",
  "Sarmain Pharmaceuticals",
  "Sarolak Health Care",
  "Sarp Pharmaceuticals",
  "Sarthak Biotech Pvt Ltd",
  "Sarthak Lifesciences",
  "Sarthi Life Sciences",
  "Sarv Tech Pvt Ltd",
  "Sarvagunaushdhi Pvt Ltd",
  "Sarvear Pharmaceuticals",
  "Sarveshvari Health Care",
  "Sarvit Life Sciences",
  "Sashwat Pharmaceuticals Pvt Ltd",
  "Sasvat Omnicare Pvt Ltd",
  "Sat Kartar Shopping Pvt Ltd",
  "Sat Pharma Pvt Ltd",
  "Sativa Life LLP",
  "Satiya Nutraceuticals Pvt Ltd",
  "Sattvic Medical Pvt Ltd",
  "Saturn Formulations Pvt Ltd",
  "Saturn Pharmaceuticals Pvt Ltd",
  "Satv Herbs and Foods",
  "Satva Biotech Pvt Ltd",
  "Satven And Mer",
  "Satvik Medisolutions",
  "Satvika Bio-Foods India Pvt Ltd",
  "Satya Kalindi Remedies Pvt Ltd",
  "Satya Nutraceuticals",
  "Satya Pharmaceuticals",
  "Satyam Lifesciences",
  "Sava Healthcare Ltd",
  "Sava India Personal Care Pvt Ltd",
  "Sava Medica Ltd",
  "Saveer Biotech Ltd",
  "Saveer MatrixNano Pvt Ltd",
  "Saven Remedies Pvt Ltd",
  "Saveo Healthtech Pvt Ltd",
  "Saver Pharmaceuticals",
  "Savesol Healthcare Pvt Ltd",
  "Savexa Pharma",
  "Savikem Lifecare Pvt Ltd",
  "Savin Pharma",
  "Savio Criticare",
  "Saviour Health Care",
  "Saviour Medisciences",
  "Saviour Speciality Drugs Pvt Ltd",
  "Savisure Healthcare",
  "savoy Biotech",
  "Savya Pharmaceuticals",
  "Sawraj Pharmaceutical",
  "Saykam Pharmaceuticals",
  "Sayoka Pharmaceutical (OPC) Pvt Ltd",
  "Sayona Medicare Pvt Ltd",
  "Sayora Pharma Pvt Ltd",
  "Sayre Therapeutics Pvt Ltd",
  "Saywell Lifesciences",
  "SB Lifesciences",
  "Sbeed Pharmaceuticals",
  "SBL Pvt Ltd",
  "SBM Pharmaceuticals",
  "SBP Biotech",
  "Sbp Healthcare Pvt Ltd",
  "SBS Biosciences",
  "SBS Biotech Ltd",
  "SBS Herbal Pvt Ltd",
  "SBS Life Sciences Pvt Ltd",
  "Scala Pharmaceuticals",
  "Sceolex Pharma Pvt Ltd",
  "SCG Healthcare Pvt Ltd",
  "Schelling Pharma Pvt Ltd",
  "Scholl Piramal India Pvt Ltd",
  "Schon Pharmaceuticals Ltd",
  "Schulke India Pvt Ltd",
  "Schwitz Biotech",
  "Scieniq Healthcare Pvt Ltd",
  "Sciential Pharmaceuticals",
  "Scientific Brain Nutraceutical Pvt Ltd",
  "Scientific Sport Nutrition",
  "Scienza Healthcare Pvt Ltd",
  "Scinticaa Pharma",
  "Scion Pharma Pvt Ltd",
  "Scitec Nutrition",
  "Scocia Labs",
  "Scope Pharma Pvt Ltd",
  "Scoria Pharmaceuticals Pvt Ltd",
  "Scorleon Pharma",
  "Scorlife Marketing",
  "Scormed Lifesciences",
  "Scorsis Laboratories",
  "Scortis Health Care",
  "Scortis Lab Pvt Ltd",
  "Scortis Pharma Ltd",
  "Scoshia Remedy Inc",
  "Scot Derma Pvt Ltd",
  "Scotmed Care Pvt Ltd",
  "Scott Edil Pharmacia Ltd",
  "Scotwin Healthcare",
  "Scudder Life Sciences Pvt Ltd",
  "Scutage Pharmaceuticals",
  "Scutonix Lifesciences Pvt Ltd",
  "Scynor Pharmaceuticals Pvt Ltd",
  "Scythian Healthcare",
  "SD Biosensor Healthcare Pvt Ltd",
  "Sea Life Care",
  "Sea Silver Healthcare Pvt Ltd",
  "Seachem Laboratories",
  "Seagull Laboratories (I) Pvt Ltd",
  "Seagull Pharmaceutical Pvt Ltd",
  "Seamac Formulations Pvt Ltd",
  "Seamark Bioorganic Pvt Ltd",
  "Search Foundation",
  "Search Orbis Pharmaceuticals",
  "Searle Labs Pvt Ltd",
  "Seashell Laboratories Pvt Ltd",
  "Seasons Healthcare Ltd",
  "Seavens Pharmaceuticals Pvt Ltd",
  "Sebakind Pvt Ltd",
  "Sebert Lifesciences",
  "Secular Organic",
  "Secure Healthcare",
  "Secure Healthcare India Pvt Ltd",
  "Secure Life Science India Pvt Ltd",
  "Secure Pharma Pvt Ltd",
  "Sedate Healthcare Pvt Ltd",
  "Sedge Bioceuticals Inc India",
  "Sedulus Remedies",
  "See Ever Healthcare Pvt Ltd",
  "Seeds Berry Enterprises",
  "Seeko Biotics",
  "Seema International",
  "Seema Pharmaceuticals",
  "Seepi Pharma Pvt Ltd",
  "Seer Biotech",
  "Seer Secrets",
  "Segment Care",
  "Segnema Pharma Pvt Ltd",
  "Sego Lily Healthcare Pvt Ltd",
  "Segos Biocare",
  "Seguro Lifesciences Pvt Ltd",
  "Sehat Pharma Pvt Ltd",
  "Sehwin Healthcare Pvt Ltd",
  "Seikomax Healthcare",
  "Seikomed Healthcare Pvt Ltd",
  "Sekhani Industries Pvt Ltd",
  "Seklla Healthcare",
  "Selag Biologicals LLP",
  "Selaginella Life Sciences",
  "Seldom Pharma Pvt Ltd",
  "Selexia Biotech Pvt Ltd",
  "Seluria Pharmaceuticals",
  "Selvyn Biotech",
  "Selway Lifesciences Pvt Ltd",
  "Semangat Healthcare Pvt Ltd",
  "Semantics Pharmaceuticals Pvt Ltd",
  "Semco Infratech Pvt Ltd",
  "Semiotic Pharmaceutical Pvt Ltd",
  "Semper Fidelis Healthcare Pvt Ltd",
  "Semsun Pharma Pvt Ltd",
  "Semuns Laboratories",
  "Senable Life Sciences Pvt Ltd",
  "Senate Laboratories",
  "Senberk Pharmaceuticals Pvt Ltd",
  "Senechio Pharma Pvt Ltd",
  "Senen Biotech",
  "Senepharm India Pvt Ltd",
  "Senera Essentials",
  "Senses Pharmaceuticals Ltd",
  "Sensidew Care Pvt Ltd",
  "Sensus Pharmaceuticals",
  "Sentinel Remedies",
  "Sentire Pharmaceutical India Pvt Ltd",
  "Sentiss Pharma",
  "Sentonssa Wellness Pvt Ltd",
  "Sentosa Geigy (India) Labs Ltd",
  "Sentro Pharma & Healthcare",
  "Senzan Pharmaceuticals",
  "Sepik Life Sciences",
  "Septalyst Lifesciences Pvt Ltd",
  "Seqmed Lifesciences Pvt Ltd",
  "Seraph Life Sciences Pvt Ltd",
  "Serbia Molecules Pvt Ltd",
  "Serdia Pharmaceuticals India L",
  "Serdia Pharmaceuticals India Ltd",
  "Serdia Pharmaceuticals India Pvt Ltd",
  "Serene Life Sciences Pvt Ltd",
  "Serene Lifeworld Pvt Ltd",
  "Serene Medicos",
  "Serento Dermacare Pvt Ltd",
  "Serin Formulations Pvt Ltd",
  "Sernea Enterprises",
  "Seron Pharma Pvt Ltd",
  "Serum Institute Of India",
  "Serum Institute Of India Ltd",
  "Serve Pharmaceuticals",
  "Serverz Biotech",
  "Servier India Pvt Ltd",
  "Servo Healthcare",
  "Servocare Lifesciences Pvt Ltd",
  "Serwin Pharmaceuticals",
  "Serwina Pharmaceuticals Pvt Ltd",
  "Sesa Care Pvt Ltd",
  "Sesderma India Pvt Ltd",
  "Sethi Rasayan Shala",
  "Setu Nutrition Pvt Ltd",
  "Setwel Industries",
  "Seva Sneh Wellness Solutions",
  "Sevam Healthcare",
  "Seven Hills Life Sciences",
  "Sevida Pharma",
  "Sewfa Naturals",
  "Sezja Pharmaceuticals",
  "SFT Technologies Pvt Ltd",
  "SG Health",
  "SG Lifecare Pvt Ltd",
  "SG Pharma",
  "Sgs Pharmaceuticals Pvt Ltd",
  "SH Healthcare Pvt Ltd",
  "SH Pharmaceuticals Pvt Ltd",
  "Shadye-Lynn Remedies",
  "Shail Formulation Pvt Ltd",
  "Shaimil Laboratories",
  "Shakam Lifesciences",
  "Shakambari Food Products",
  "Shaktam Health Care Product",
  "Shalaks Healthcare",
  "Shalaks Pharmaceutical P Ltd",
  "Shalina Laboratories Pvt Ltd",
  "Shalman Pharma Pvt Ltd",
  "Shamac Health Care Pvt Ltd",
  "Shamrock Chemicals",
  "Shamsri Pharma Pvt Ltd",
  "Shangrila Industries (P) Ltd",
  "Shankar Pharmacy",
  "Shankem Pharmaceuticals Pvt Ltd",
  "Shanker Herbal Care",
  "Shankhin Healthcare",
  "Shankus Acme Pharma Pvt Ltd",
  "Shanta Biotechnics Pvt Ltd",
  "Shantam Pharmaceuticals Pvt Ltd",
  "Shantha Biotech",
  "Shanty's Pillmate",
  "Shape Pharma Pvt Ltd",
  "Shapra Trading Pvt Ltd",
  "Sharangdhar Pharmaceuticals Pvt Ltd",
  "Shara's Dry Fruits",
  "Sharing Formulations Pvt Ltd",
  "Sharish Pharmaceutical Pvt Ltd",
  "Sharma G",
  "Sharon Bio-Medicine Ltd",
  "Sharp Foods The Choice",
  "Sharrets Nutritions LLP",
  "Shashi Natural and Organics Pvt Ltd",
  "Shashika Pharmacia",
  "Shashilife Healthcare Pvt Ltd",
  "Shashvat Healthcare",
  "Shasun Pharmaceuticals Ltd",
  "Shasva Health",
  "Shatayushi Healthcare Pvt Ltd",
  "Shawn & Co",
  "Shawna Pharmaceutical Pvt Ltd",
  "Sheetal Medicare Products Pvt Ltd",
  "Shef Biotech",
  "Sheldon Pharmaceuticals",
  "Shell Laboratories",
  "Shelon Pharmalabs Pvt Ltd",
  "Shelter Pharma Ltd",
  "Shelton Pharmaceuticals Pvt Ltd",
  "Shenzhen Hwatime Biological Medical Electronics",
  "Shenzhen Jumper Medical Equipment Co Ltd",
  "Sherim Squibss",
  "Sherring Life Sciences",
  "Sherrington Laboratories",
  "Shervotec Pharmaceuticals",
  "Sherwell Biotech",
  "Sherwyn Pharmaceuticals Pvt Ltd",
  "Shesha Naturals",
  "Sheth Brothers",
  "Shield & Care Pharmaceuticals Pvt Ltd",
  "Shield Health Care Pvt Ltd",
  "Shilar Pharmaceuticals",
  "Shilpa Medicare Ltd",
  "Shilpex Pharmysis",
  "Shimano Health Care Pvt Ltd",
  "Shimmer Pharma",
  "Shince Pharmaceuticals Pvt Ltd",
  "Shine Pharmaceuticals Ltd",
  "Shine Remedies",
  "Shinehealth Pharmaceuticals Pvt Ltd",
  "Shinemax Pharma",
  "Shinemax Pharma India Pvt Ltd",
  "Shinetech Remedies Pvt Ltd",
  "Shinmax Pharma",
  "Shinto Organics (P) Ltd",
  "Shinto Organics P Ltd",
  "Shinvan Pharmaceuticals & Chemicals",
  "Shiny Pharmaceuticals Pvt Ltd",
  "Ship Me Medicine LLP",
  "Shiron Pharmaceuticals",
  "Shirr Pharmaceuticals Pvt Ltd",
  "Shiv Shakti International",
  "Shiva Pharma",
  "Shivalik Ayurvedic Pharmaceuticals",
  "Shivalik Herbals",
  "Shivani Healthcare",
  "Shivansh Pharma",
  "Shivashakti Pharma",
  "Shivon Lifesciences Pvt Ltd",
  "Shivram Peshawari & Bros",
  "Shivtaraya Pharmaceuticals Pvt Ltd",
  "Shiwalic Formulations Pvt Ltd",
  "Shomed Remedies",
  "Shomika Pharmaceutical",
  "SHP Life Sciences Pvt Ltd",
  "Shree Baidyanath Ayurved Bhawan Pvt Ltd",
  "Shree Balaji Surgical Pvt Ltd",
  "Shree Dhanwantri Herbals",
  "Shree Dhootapapeshwar Ltd",
  "Shree Ganesh Pharmaceuticals",
  "Shree Ganesh Rubber & Chemicals Co",
  "Shree KrishnaKeshav Laboratories Ltd",
  "Shree Maruti Herbal",
  "Shree Pharmaceuticals",
  "Shree Raj Pharmaceuticals",
  "Shree RK Agrotech",
  "Shree Sharda Associates Pvt Ltd",
  "Shree Surya Masale Udhyog",
  "Shree Venkatesh International Ltd",
  "Shreeji Healthcare Impex",
  "Shreeji Sales",
  "Shreem Drugs Pvt Ltd",
  "Shreeyam Healthcare",
  "Shreshay Exim Pvt Ltd",
  "Shreshtha Formulations",
  "Shrestha Pharma Pvt Ltd",
  "Shrey Nutraceuticals & Herbals Pvt Ltd",
  "Shreya Life Sciences Pvt Ltd",
  "Shreya Lifesciences Pvt Ltd",
  "Shri Balaji Medicare",
  "Shri Ganga Pharmacy",
  "Shri Jai Durge International",
  "Shri Nath Pharmacy",
  "Shri Pharmaceuticals",
  "Shri Ram Ayurved Bhawan",
  "Shri Ram Enterprises",
  "Shri Sai Pharma",
  "Shri Sharma Ayurved Mandir",
  "Shri Vinayak Services",
  "Shridhara Lifesciences Pvt Ltd",
  "Shrimad Herbal & Ayurveda LLP",
  "Shrimath Ventures",
  "Shrine Pharma",
  "Shrine Remedies Pvt Ltd",
  "Shrinivas Gujarat Laboratories Pvt Ltd",
  "Shrion Pharmaceuticals",
  "Shripati Medicare LLP",
  "Shrishti Market Pvt Ltd",
  "Shriti Pharmaceutical Pvt Ltd",
  "Shrrishti Health Care Products Pvt Ltd",
  "Shubhansh Pharmaceuticals",
  "Shudhanta Herbal Products",
  "Shumael Enterprises",
  "Shure LifeCare Pvt Ltd",
  "Shuvangi Natural Products Pvt Ltd",
  "Sia Life Sciences",
  "Sia Pharma Pvt Ltd",
  "Siba Lifesciences",
  "Sibr Life Sciences Pvt Ltd",
  "Sicare Pharma Pvt Ltd",
  "Sickcure Pharmaceuticals Pvt Ltd",
  "Siddha Pharmaceuticals",
  "Siddhagiri Satvyk",
  "Siddhayu Healthcare Pvt Ltd",
  "Siddhichemo Pharmaceuticals",
  "Siddhidatri Multitrade Enterprisers",
  "Siddhik Herbs LLP",
  "Siddhivinayak Enterprises",
  "Sidel India Pvt Ltd",
  "Sidhbali Formulations",
  "Sidian Pharmaceuticals Pvt Ltd",
  "Sidmak Laboratories India Pvt Ltd",
  "Sidman Pharmacia",
  "Sidmed Remedies",
  "Sidna Healthcare Pvt Ltd",
  "Sidvin Pharma",
  "Sienna Formulations Pvt Ltd",
  "Sierra India Organics",
  "Sierra Pharmaceuticals Ltd",
  "Siesta Pharmaceuticals",
  "Sifam Healthcare Pvt Ltd",
  "Sifco Pharma",
  "Siglap Pharma",
  "Sigma Drugs India Pvt Ltd",
  "Sigma Laboratories Ltd",
  "Sigma Softgel Formulation",
  "Sigman Wellness",
  "Sigmarise Lifesciences LLP",
  "Sigmund Promedica",
  "Sigmus Biotech Pvt Ltd",
  "Signature Phytochemical Industries",
  "Signit Laboratories Pvt Ltd",
  "Signity Pharmaceuticals Pvt Ltd",
  "Signorah Healthcare",
  "Signova Pharma Pvt Ltd",
  "Signum Drug Company",
  "Silantro Pharma Pvt Ltd",
  "Silhos Pharma Ltd",
  "Silicon Pharma",
  "Siloam Pharmaceuticals Pvt Ltd",
  "Silver Line International",
  "Silver Line Laboratories Pvt Ltd",
  "Silvermaple Healthcare Services Pvt Ltd",
  "Silversky Laboratories",
  "Simaios Healthcare Pvt Ltd",
  "Simandhar Herbal Pvt Ltd",
  "Simanj Pharmaceuticals Pvt Ltd",
  "Simbhaoli Sugars Ltd",
  "Simcare Pharma",
  "Simenpark Healthcare Inc",
  "Similia Homoeo Laboratory",
  "Simon Healthcare",
  "Simpatico Healthcare",
  "Simpex Pharma Pvt Ltd",
  "Simpl Innovative Brands Pvt Ltd",
  "Simply Earth",
  "Simpson Brawn Pharmaceuticals",
  "Simran Marketing Solution",
  "Sinag Healthcare Pvt Ltd",
  "Sinclair IS Pharma",
  "Sincure Pharma",
  "Sindhusudha Ayurvedic Pharmaceuticals",
  "Sing Pharma Pvt Ltd",
  "Singh Styled",
  "Sinjha International Pvt Ltd",
  "Sinkona Healthcare",
  "Sinsan Pharmaceuticals",
  "Siochem Pharma Pvt Ltd",
  "Siomond Pharmaceuticals Pvt Ltd",
  "Sioux K Healthcare India Pvt Ltd",
  "Sipali Chemicals",
  "Sipmax Pharma",
  "Sipra Remedies",
  "Sira Healthcare",
  "Siramed Pharmaceuticals (opc) Pvt Ltd",
  "Sirius Healthcare",
  "Sirona Hygiene Pvt Ltd",
  "Siroy Lifesciences",
  "Siscon India Pvt Ltd",
  "Siscon Pharma",
  "Siskan Pharma",
  "Siskan Pharma Pvt Ltd",
  "Sisla Laboratories",
  "Sissma Life Sciences Pvt Ltd",
  "Sisu Biosciences Pvt Ltd",
  "Sitnez Biocare Pvt Ltd",
  "Sitrocs Zenetica India Pvt Ltd",
  "Siva Sri Retail",
  "Sivling Technologies Pvt Ltd",
  "Sixth Sense Pharmaceuticals",
  "Sixth Sense Retail Pvt Ltd",
  "Siyora Pharmaceuticals Pvt Ltd",
  "SJK Pharmaceutical Pvt Ltd",
  "Sk Laboratories Pvt Ltd",
  "Skama Biotech",
  "Skan Research Lab Pvt Ltd",
  "Skars Healthcare Pvt Ltd",
  "Skg Internationals",
  "Skin Dr London Pvt Ltd",
  "Skin First Cosmeceutical",
  "Skin Organics",
  "Skinnovate Therapeutics LLP",
  "Skino Pharmaceutical",
  "Skinocean Pharmaceuticals",
  "Skinska Pharmaceutica Pvt Ltd",
  "Skinteec LifeScience",
  "Skintra Lifesciences Pvt Ltd",
  "Skinwave India Pvt Limite",
  "Skiptuit Healthcare",
  "SKM Siddha and Ayurvedha Company (India) Pvt Ltd",
  "SKN Organics",
  "Skoda Pharma",
  "Skora Healthcare Pvt Ltd",
  "Skuds Pharmaceuticals",
  "Skvamps Pharma Pvt Ltd",
  "Sky Pharma",
  "SkyEc Drugs and Pharmaceuticals Pvt ltd",
  "Skyever Pharmaceuticals Pvt Ltd",
  "Skygain Nutricare Pvt Ltd",
  "Skygen Pharma",
  "Skyglow Pharmaceuticals Pvt Ltd",
  "Skylab Lifesciences",
  "Skylane Pharmaceuticals",
  "Skylark Pharmaceuticals Pvt Ltd",
  "Skylight Pharmaceuticals Pvt Ltd",
  "Skylla Pharmaceuticals",
  "Skymap Pharmaceutical Pvt Ltd",
  "Skymap Pharmaceuticals",
  "Skymax Laboratories Pvt Ltd",
  "Skymax Life Science Pvt Ltd",
  "Skynation Enterprises",
  "Skysun Life Sciences Pvt Ltd",
  "Skysun Lifesciences Pvt Ltd",
  "Skyways Healthcare Pvt Ltd",
  "Skywell Healthcare Pvt Ltd",
  "SL Healthcare Pvt Ltd",
  "Slaney Healthcare",
  "Slash Lifevision",
  "Slavic Pharma",
  "SLB Healthcare Pvt Ltd",
  "Sleepsia India Pvt Ltd",
  "Slon Pharmaceuticals Pvt Ltd",
  "Sloventis Pharma Pvt Ltd",
  "SM Healthcare",
  "SMA Nutrition",
  "Smaco Biocon",
  "Smariton Laboratories Pvt Ltd",
  "Smart Air Filters Pvt Ltd",
  "Smart Laboratories Pvt Ltd",
  "Smart Minds Healthcare Pvt Ltd",
  "Smartway Wellness",
  "Smayan Healthcare Pvt Ltd",
  "SMC Healthcare Pvt Ltd",
  "SMC Toiletries",
  "Smdia Lifesciences Pvt Ltd",
  "Smear India Healthcare Pvt Ltd",
  "Smilax Pharmaceuticals",
  "Smile Healthcare",
  "Smiles Healthcare",
  "Smiq Pharma",
  "Smith & Kenner Pharmaceuticals Pvt Ltd",
  "Smith & Nephew Pvt Ltd",
  "Smitha Braun Laboratories Pvt Ltd",
  "Smithcare Remedies",
  "Smiths Medical India Pvt Ltd",
  "Smithways Oncology Pvt Ltd",
  "SMJ Foods",
  "Smmartcure Pharma",
  "Smonkare Impex Pvt Ltd",
  "Smotec Pharmaceuticals",
  "Sms Life Care Pvt Ltd",
  "SMS Multitech India Pvt Ltd",
  "SN Herbals",
  "Snacko Delight Foods Pvt Ltd",
  "Snapback Pharmaceuticals Pvt Ltd",
  "Snego Pharmaceuticals Pvt Ltd",
  "Sneh Enterprises",
  "Sneh Pharma Pvt Ltd",
  "Snehal Pharma & Surgicals Pvt Ltd",
  "Snell's Therapeutics",
  "Snig Pharma Pvt Ltd",
  "Snig Pharmaceuticals Pvt Ltd",
  "Snomed Pharmaceutical Pvt Ltd",
  "Snotis Life Science",
  "Snowlan Epicure Pvt Ltd",
  "Snu Biocare",
  "Snyder Healthcare",
  "Sober Care Surgicals",
  "Sobhan Drugs",
  "Soch Foods LLP",
  "Sofi Healthcare Llp",
  "Soft Pharmaceuticals",
  "Softal Cosmetics Pvt Ltd",
  "Softdeal Pharmaceutical Pvt Ltd",
  "Softech Pharma",
  "Softsens Consumer Products Pvt Ltd",
  "Sofy Healthcare Pvt Ltd",
  "Sogo Tele Shopping",
  "Sohm India Pvt Ltd",
  "Soigner Medicament Pvt Ltd",
  "Soigner Pharma",
  "Soin Pharma",
  "Soinsvie Pharmacia Pvt Ltd",
  "Sois Formulations Pvt Ltd",
  "Sol Derma Pharmaceuiticals Pvt Ltd",
  "Sol luna",
  "Solace Biotech Ltd",
  "Solace Healthcare Pvt Ltd",
  "Solano HealthCare Pvt Ltd",
  "Solar Healthcare Pvt Ltd",
  "Solar Life Sciences Medicare Pvt Ltd",
  "Solarium Pharmaceuticals",
  "Solariz Healthcare Pvt Ltd",
  "Solaryam Life Care Pvt Ltd",
  "Solasta Lifesciences Pvt Ltd",
  "soleil International",
  "Soleon Lifesciences India Pvt Ltd",
  "Solera Lifesciences Pvt Ltd",
  "Soleus Pharmaceutical Pvt Ltd",
  "Soleus Pharmaceuticals Pvt Ltd",
  "Soley Pharmaceutical Pvt Ltd",
  "Solferino Lab Pvt Ltd",
  "Solidus Lifesciences Pvt Ltd",
  "Solis Pharma",
  "Solitaire Pharmacia Pvt Ltd",
  "Solkem Pharmaceutical",
  "Solmars Pharma",
  "Solomon Technologies Ltd",
  "Soltech Lifesciences",
  "Solumiks Piramal Ltd",
  "Solus Health Care",
  "Solutia Pharma LLP",
  "Solvate Laboratries Pvt Ltd",
  "Solveig Lifesciences",
  "Solvis Pharmaceuticals",
  "Solvmax Laboratories Pvt Ltd",
  "Solvy Remedies Pvt Ltd",
  "Solway Pharmaceuticals Pvt Ltd",
  "Solwin Lifesciences",
  "Somatec Pharmaceuticals Pvt Ltd",
  "Somatico Laboratories Pvt Ltd",
  "Somatico Pharmacal Pvt Ltd",
  "Somkee India Pvt Ltd",
  "Somnogen Pharmaceuticals Pvt Ltd",
  "Sonarisa Lifesciences LLP",
  "Sonika Life Sciences Ltd",
  "Sonixa Lifecare",
  "Sonning Life Science",
  "Sonshree Ved",
  "Soothe Healthcare Pvt Ltd",
  "Sorich Organics Pvt Ltd",
  "Sorinsun Healthcare",
  "SOS Nutrition",
  "Sotac Pharmaceuticals Pvt Ltd",
  "Soul Pharma",
  "Soulager Healthcare Pvt Ltd",
  "Source Dock Pvt Ltd",
  "Source Naturals",
  "Soursop Services",
  "South India Research Institute Pvt Ltd",
  "Southern Health Foods Pvt Ltd",
  "Southern Trading Company",
  "Southshourne Corporation India",
  "Souvenir Pharma",
  "Sovaam Pharmaceuticals",
  "Sovam Crop Science Pvt Ltd",
  "Sovan Biosciences Pvt Ltd",
  "Sovia Altis",
  "Sovicare Pharmaceuticals",
  "Sowilo India Pharmaceuticals Pvt Ltd",
  "Sozin Flora Pharma Ltd",
  "SP Accure Labs",
  "SP Biosciences",
  "SPA Biological Labs",
  "Spa Healthcare Pvt Ltd",
  "Spa Newtraceuticals Pvt Ltd",
  "Spaceage Multiproducts Pvt Ltd",
  "Spacilac Healthcare Pvt Ltd",
  "Spackle Biotech",
  "Spairowell Dermacare",
  "Span Pharmaceuticals Pvt Ltd",
  "Spancer Pharmaceuticals",
  "Spandel Enterprises Pvt Ltd",
  "Spanker Biotech",
  "Spansa Pharmaceuticals Pvt Ltd",
  "Spark Bless Pharma",
  "Spark Healthcare Pvt Ltd",
  "Spark Medicare Pvt Ltd",
  "Sparkle Life Sciences Pvt Ltd",
  "Sparkles Exim Pvt Ltd",
  "Sparlife Healthcare",
  "Sparsh Remedies Pvt Ltd",
  "Sparsha Pharma International Pvt Ltd",
  "Sparshmart Pharmaceuticals Pvt Ltd",
  "Sparta Nutrition",
  "Spc Healthcare Pvt Ltd",
  "Spdy Pharma Pvt Ltd",
  "Specia Remedies India Pvt Ltd",
  "Speciality Meditech Pvt Ltd",
  "Speciality Supermax",
  "Specian Healthcare Pvt Ltd",
  "Species Nutrition",
  "Spectra Lifesciences Pvt Ltd",
  "Spectra Therapeutics Pvt Ltd",
  "Spectracare Laboratories Pvt Ltd",
  "Spectrum Falcon",
  "Spectrum Group",
  "Spectrum Life Sciences Pvt Ltd",
  "Spectrum Pharmaceuticals",
  "Speedcare Pharmaceutical Pvt Ltd",
  "Spencer Pharmaceuticals Ltd",
  "Spenchem Pharmaceuticals Pvt Ltd",
  "Spencure Biotech Pvt Ltd",
  "Spero Medicare Pvt Ltd",
  "Spey Medical Pvt Ltd",
  "Sphere Pharmaceuticals Pvt Ltd",
  "Spic Pharmaceuticals",
  "Spica Lab",
  "Spica Remedies Pvt Ltd",
  "Spice Herbals",
  "SpiceJet Merchandise Pvt Ltd",
  "SpiceRich Seasonings Pvt Ltd",
  "Spine HealthCare Pvt Ltd",
  "Spinka Pharma",
  "Spire Pharma",
  "Spiritoso Healthcare",
  "Spiritual Life Sciences Pvt Ltd",
  "Spiritus Healthcare Pvt Ltd",
  "Spiroo Food Products",
  "Spitze Pharma",
  "Splendid Pharmaceuticals",
  "Splenz pharmaceuticals Pvt Ltd",
  "SPM Drugs Pvt Ltd",
  "Spoil Your Body",
  "Spores Lifesciences Inc",
  "Sporodik Pharmaceuticals",
  "Sportech Solutions",
  "Sports Nutrition Technology",
  "Sports One Nutrition",
  "Spot Labs Pvt Ltd",
  "Spranza Vita Pharmaceutical LLP",
  "Sprout Pharmaceuticals",
  "SproutLife Foods Pvt Ltd",
  "SPS Medipol Pvt Ltd",
  "SPV Laboratories Pvt Ltd",
  "Square Pharmaceuticals Ltd",
  "Square Sales India",
  "SR Biotech",
  "SR Medicare Pvt Ltd",
  "Srb Life Science",
  "Srdass Pharmaceuticals Pvt Ltd",
  "Sree Cvr Pharmaceuticals",
  "Sree Krishna Homeo Research & Lab (SKRL)",
  "SreeRang Biotech Pvt Ltd",
  "Sresan Pharmaceuticals",
  "SRG Organic Foods India Pvt Ltd",
  "Sri Biotech Pvt Ltd",
  "Sri Chamundeeswari Pharmacy",
  "Sri Herbasia Biotech",
  "Sri Jain Ayurvedic Pharmacy",
  "Sri Nature Ayur",
  "Sri Navjeewan Rasayanshala",
  "Sri Padma Lakshmi Surgicals",
  "Sri Sai Pharmaceuticals",
  "Srichand Enterprises",
  "Sricure Herbs (India) Pvt Ltd",
  "Srideep Pharma",
  "Srigan Anatto Care Pvt Ltd",
  "Srigen Labs",
  "Srigen Pharmaceuticals",
  "Sristi Pharmaceuticals",
  "Sriveda Sattva Pvt Ltd",
  "SRK Lifesciences & Research India Pvt Ltd",
  "SRV Exim",
  "SS Healthcare",
  "SS Lifevision Pvt Ltd",
  "SS Pharmaceuticals",
  "SSK Pharma",
  "SSM Pharmaceuticals Pvt Ltd",
  "SSR Biotech",
  "SSV Healthcare",
  "Ssv Pharmaceuticals",
  "St ",
  "St Botanica",
  "St Botanica Beauty Pvt Ltd",
  "St Georges Homoeopathy",
  "St George’s Homoeopathy",
  "ST Jude Pharma",
  "St Morrison",
  "ST Sharda Lifescience Pvt Ltd",
  "Stable Pharma",
  "Stabo Life Pvt Ltd",
  "Stacia Pharma Pvt Ltd",
  "Stadchem Of India",
  "Stadia Biotech",
  "Stadmed Pvt Ltd",
  "Stafford Laboratories Pvt Ltd",
  "Stallion Laboratories Pvt Ltd",
  "Stalwart Lifesciences Pvt Ltd",
  "Stalwart Nutritions",
  "Stalwart Remedies Pvt Ltd",
  "Stambh Healthcare Pvt Ltd",
  "STAMED PVT LTD",
  "Stance Biogenesis Pvt Ltd",
  "Standard and Safe Movements Pvt Ltd",
  "Standard Pharmaceuticals Ltd",
  "Standard Remedies Pvt Ltd",
  "Staneil Pharma",
  "Stanex Drugs & Chemicals Pvt Ltd",
  "Stanford Biotech Pvt Ltd",
  "Stanford Lab Pvt Ltd",
  "Stanford Laboratories",
  "Stanford Organics Pvt Ltd",
  "Stanley Healthcare Pvt Ltd",
  "Stanley Pharmaceuticals",
  "Stanmark Pharmaceuticals",
  "Stanpro Pharmaceutical Ltd",
  "Stanqualis Healthcare Pvt Ltd",
  "Stanrock Lifesciences",
  "Stanvac Prime Pvt Ltd",
  "Stanwell Lifesciences Pvt Ltd",
  "Star Biomed Pvt Ltd",
  "Star Industrial Corporation",
  "Star Pharmaceuticals Pvt Ltd",
  "Star Plus Pharmaceuticals",
  "Starcap Wellness Pvt Ltd",
  "Starex Pharmaceuticals",
  "Starford Healthcare Pvt Ltd",
  "Starlance Pharmaceuticals",
  "Starmed Health Care Pvt Ltd",
  "Starry Biotech",
  "Starsure Remedies",
  "Startell Bioceuticals",
  "Startos Healthcare Pvt Ltd",
  "Starus Pharmaceuticals",
  "Starzac Formulations",
  "Starzol Pharma",
  "Stash Pharmaceutical Pvt Ltd",
  "State Export Lab",
  "Stately Lifescience Pvt Ltd",
  "Stature Life Sciences",
  "Staunch Biotech",
  "Staunch Health Care Pvt Ltd",
  "Stayer Pharma Pvt Ltd",
  "Stayfit Enterprize Pvt Ltd",
  "Steadcare Lifesciences Pvt Ltd",
  "Steadfast Medishield Pvt Ltd",
  "Steadsfast Medishield Pvt Ltd",
  "Stedman Pharmaceuticals Pvt Ltd",
  "Stedman Pharmaceuticals Pvtltd",
  "Steelar Pharmaceuticals",
  "Steenbrass Pharmaceutical Pvt Ltd",
  "Steigen Pharmaceuticals Pvt Ltd",
  "Stelios Pharmaceuticals LLP",
  "Stellaire Biosciences Pvt Ltd",
  "Stellar Bio-Labs",
  "Stelon Biotech Pvt Ltd",
  "Stem Organic Pvt Ltd",
  "Stemgrow Pharmaceuticals Pvt Ltd",
  "Stenhill Labs",
  "Stenkem Molecules Pvt Ltd",
  "Stenroz Biotech",
  "Stensa Life Sciences",
  "Stenzer Healthcare",
  "Stepcure Pharma Pvt Ltd",
  "Steps and Smith Healthcure",
  "Stepway Formulations",
  "Sterfil Laboratories Pvt Ltd",
  "Steris Healthcare Pvt Ltd",
  "Sterish Healthcare Pvt Ltd",
  "Sterkem Pharma Pvt Ltd",
  "Sterling lab",
  "Sterling Medico",
  "Stermed India",
  "Steunend Healthcare",
  "Stevia World Agrotech Pvt Ltd",
  "Stiefel India Pvt Ltd",
  "Stoics Pharma",
  "Strassenburg Pharmaceuticals Ltd",
  "Strathspey Lab",
  "Strathspey Labs Pvt Ltd",
  "Streamline Beauty India Pvt Ltd",
  "Strebi Pharmaceuticals Pvt Ltd",
  "Strenuous Healthcare Pvt Ltd",
  "Stride Healthcare",
  "Strides (Aveto)",
  "Strides (Excedo)",
  "Strides Consumer Pvt Ltd",
  "Strides shasun Ltd",
  "Stridito Pharma",
  "Striker Pharma",
  "Strillion Healthcare",
  "Strimed Pharmaceuticals Pvt Ltd",
  "Strio Healthcare",
  "Strive Pharmaceuticals",
  "Striveo Health Care Pvt Ltd",
  "Striveo Healthcare Pvt Ltd",
  "Strivo Pharma Pvt Ltd",
  "Strivos Pharma",
  "Strong Man Ayur Pharma",
  "Studds Accessories",
  "Sturgen Healthcare",
  "Stuvet Pharmaceuticals",
  "Styla Pharmaceutical Pvt Ltd",
  "Stynus Pharmaceutical Pvt Ltd",
  "Styrax Pharma Pvt Ltd",
  "Styx Lifesciences Pvt Ltd",
  "Suave Healthcare Pvt Ltd",
  "Suave Pharmaceuticals",
  "Subhyog Pharmaceutical Pvt Ltd",
  "Sublime Therapeutics Pvt Ltd",
  "Subwell Lifesciences",
  "Succor Health Care Pvt Ltd",
  "Sucheetah Health Foods Pvt Ltd",
  "Suchi Industries Ltd",
  "Sudarshan Wellness Pvt Ltd",
  "Sudha Lifesciences Pvt Ltd",
  "Sudhakar's Ayur Lab India Pvt Ltd",
  "Sugandhi Snuff King Pvt Ltd",
  "Sugee's Pharma",
  "Sugen Pharma Pvt Ltd",
  "Suhal Cellulose LLP",
  "Sujo Pharma",
  "Sukhdarshan Pharmacy Pvt Ltd",
  "Sulzderma Pharmaceuticals Pvt Ltd",
  "Sumac Pharma Pvt Ltd",
  "Sumages Pharma Pvt Ltd",
  "Sumbiotic Pharmaceuticals Pvt Ltd",
  "Sumeg Pharma",
  "Sumi Lifecares Pvt Ltd",
  "Summit Nutritions India Pvt Ltd",
  "Sumo Life Care Pvt Ltd",
  "Sun Ayurveda",
  "Sun Biotic Labs",
  "Sun India Pharmacy Pvt Ltd",
  "Sun Life Sciences Pvt Ltd",
  "Sun Lifecare",
  "Sun Pharma",
  "Sun Pharma Ind-Ranbaxy",
  "Sun Pharma Laboratories Ltd",
  "Sun Pharmaceutical Industries Ltd",
  "Sun Planet Pharmaceutical",
  "Sunanda Meditech",
  "Sunarch Pharma Pvt Ltd",
  "Sunaxa Pharma",
  "Sunberry Lifesciences Pvt Ltd",
  "Suncare Formulations Pvt Ltd",
  "Suncor Pharma",
  "Suncruz Pharmaceuticals",
  "Suncure Lifescience Pvt Ltd",
  "Suncure Remedies",
  "Sundarban Ayurved Bhawan",
  "Sundeep Associates",
  "Sundyota Numandis Pharma P Ltd",
  "Sundyota Numandis Pharmaceuticals Pvt Ltd",
  "Sundyota Numandis Probioceuticals Pvt Ltd",
  "Sunfi Pharmaceutical Pvt Ltd",
  "Sunfine Healthcare",
  "Sunflower Phamaceuticals",
  "Sunfox Technologies Pvt Ltd",
  "Sunfra Biotech",
  "SunGlow Lifescience Pvt Ltd",
  "Sungrace Pharma Pvt Ltd",
  "Sunij Pharma Pvt Ltd",
  "Sunil Enterprises",
  "Sunlife Pharmaceuticals Ltd",
  "Sunlight Impex Pvt Ltd",
  "Sunloc Ayush",
  "Sunmarker Pharma",
  "Sunmax Pharma",
  "Sunmeck Pharma Pvt Ltd",
  "Sunmed Healthcare Pvt Ltd",
  "Sunniva Life Science",
  "Sunny Drug Pharma",
  "Sunny Industries Pvt Ltd",
  "Sunoxy Pharma Pvt Ltd",
  "Sunre Healthcare",
  "Sunrest Life Science",
  "Sunrise International Labs",
  "Sunrise Nutri Foods",
  "Sunrise Pharamceuticals",
  "SUNRISE PHARMACEUTICAL",
  "Sunrise Remedies Pvt Ltd",
  "Sunroxx Pharma",
  "Sunshine Pharmaceuticals",
  "Sunspeed Pharmaceuticals Pvt Ltd",
  "Sunvet Health Care",
  "Sunvij Drugs Pvt Ltd",
  "Sunways India Pvt Ltd",
  "Sunways Laboratories Pvt Ltd",
  "Sunwell Life sciences Pvt Ltd",
  "Sunwell Life Sciencess Pvt Ltd",
  "Sunwin Healthcare",
  "Suparshva Swabs",
  "Super Formulations Pvt Ltd",
  "Super Healthy",
  "Super Pharma",
  "Super Specialities Pharma",
  "SuperFit Lifestyle Asia LLP",
  "Superior Pharmaceuticals Pvt Ltd",
  "Superlative Healthcare",
  "Supermax Laboratories",
  "Supermax Personal Care Pvt Ltd",
  "Supernova Pharmaceuticals Pvt Ltd",
  "Superstar Laboratories Pvt Ltd",
  "Supp Nutrition Pvt Ltd",
  "Supracyn Pharma",
  "Supran Healthcare Ltd",
  "Supreem Pharmaceuticals Mysore Pvt Ltd",
  "Supreme Surgico",
  "Suraksha Pharma Pvt Ltd",
  "Sure For Cure Formulations India Pvt Ltd",
  "Sure Healthcare Pvt Ltd",
  "Surecare Pharma Pvt Ltd",
  "Surelife Remedies Pvt Ltd",
  "Suremed Lifecare Pvt Ltd",
  "Surewin Healthcare",
  "Surge Biotech Pvt Ltd",
  "Surge Pharmaceuticals Pvt Ltd",
  "Surgical Wala",
  "Surgicare Shoppie",
  "Surgix Healthcare Ltd",
  "Surien Pharmaceuticals",
  "Surien Pharmaceuticals Pvt Ltd",
  "Surjichem Herbs India",
  "Surmize Pharmacon",
  "Surprise Foods",
  "SURU International Pvt Ltd",
  "Surya Herbal Ltd",
  "Surya Pharmaceutical Ltd",
  "Sushrut Ayurved Industries",
  "Sushruta Pharma",
  "Sustainable Community Commerce Pvt Ltd",
  "Sutram Ayurveda Pvt Ltd",
  "Sutures India Pvt Ltd",
  "Suvi Pharma Pvt Ltd",
  "Suvik Hitek Pvt Ltd",
  "Suzan Pharma",
  "Suzen Pharmaceuticals Pvt Ltd",
  "Suzikem Drugs Pvt Ltd",
  "Svaa Life",
  "Svaasa Nutraceuticals & Organics Pvt Ltd",
  "Svap Pharmaceutical Pvt Ltd",
  "Svas Life Sciences Pvt Ltd",
  "Svastha Foods & Nutrition Pvt Ltd",
  "Sven Genetech Ltd",
  "Svenska Cellulosa Aktiebolaget",
  "SVI Healthcare Pvt Ltd",
  "Svizera (Inspira)",
  "Svizera Health Remedies",
  "Svizera Healthcare",
  "Svizera Healthcare (Manav Care)",
  "Svizera Healthcare Pvt Ltd",
  "Svizera Healthcare(Manav Care)",
  "SVM Incorporation",
  "SVR Healthcare",
  "SW Health care",
  "Swabhumi Drug & Pharmaceuticals India Ltd",
  "Swadeshi Ayurved",
  "Swadeshi Ayurved Pvt Ltd",
  "Swahaa Pharmaceuticals Pvt Ltd",
  "Swakalyan Health and Wellness Pvt Ltd",
  "Swakam Biotech Pvt Ltd",
  "Swanrose India Pvt Ltd",
  "Swapra Pharma Pvt Ltd",
  "Swaraj Formulations Pvt Ltd",
  "Swarajya India Enterprises",
  "Swarion Lifesciences Pvt Ltd",
  "Swariyanshi Foods Pvt Ltd",
  "Swarn Shree Tradecorp",
  "Swaroop Industry",
  "Swashi Pharmaceuticals",
  "Swast Aushadhi Seva Generic Medicine Store",
  "Swasthum Wellness Pvt Ltd",
  "Swastik Formulations Pvt Ltd",
  "Swastik Life Sciences",
  "Swastik Pharmaceuticals",
  "Swati Spentose Pvt Ltd",
  "Swayamacare Pvt Ltd",
  "Sweadish Lifesciences",
  "Swede India Healthcare",
  "Sweebon India Pvt Ltd",
  "Sweet Herb Medicinals",
  "Swift Medicare Pvt Ltd",
  "Swift Pharmaceuticals Pvt Ltd",
  "Swiss Biotech",
  "Swiss Garnier Genexiaa Science",
  "Swiss Garnier Life Sciences",
  "Swiss Parenterals Ltd",
  "Swiss Pharma",
  "Swiss Pharma Pvt Ltd",
  "Swisse Wellness",
  "Swisskem Healthcare",
  "Swizing Biosciences",
  "Swmabhan Commerce Pvt Ltd",
  "Sword and Shield Pharma Ltd",
  "Sword Pharmaceuticals Pvt Ltd",
  "Sycap Pharma",
  "Sycardian Drugs & Pharmaceuticals",
  "Sycon Healthcare Pvt Ltd",
  "Sycus Lifesciences Pvt Ltd",
  "Sydmen Lifesciences Pvt Ltd",
  "Sydney Life Science Pvt Ltd",
  "Syenergy Environics Ltd",
  "Sygnus Biotech",
  "Sykocure Lifesciences",
  "Sylis Technologies LLP",
  "Sylvan Ayurveda",
  "Symantec Healthcare Pvt Ltd",
  "Symbio Life Pharmaceuticals",
  "Symbion Lifescience",
  "Symbiosis Lab",
  "Symbiosis Life Sciences Ltd",
  "Symbiosis Pharmaceuticals P L",
  "Symbiosis Pharmaceuticals Pvt Ltd",
  "Symbiotec Pharmalab",
  "Symbiotic Drugs",
  "Symcox Research Lab P Ltd",
  "Symed Lifecare Pvt Ltd",
  "Symet Drugs Ltd",
  "Symic Pharmaceuticals",
  "Symlek Healthcare Pvt Ltd",
  "Sympar Life Sciences",
  "Syn Labs",
  "Synamed Pharmaceuticals (india) Pvt Ltd",
  "Synapes Life Sciences Pvt Ltd",
  "Synapsee Remedies Pvt Ltd",
  "Synarch Lifesciences Pvt Ltd",
  "Sync Pharmaceutical",
  "Synchem Lab",
  "Synchroz Pharmaceuticals Pvt Ltd",
  "Syncom Formulations I Ltd",
  "Syncom Healthcare Ltd",
  "Syncopa Laboratories Pvt Ltd",
  "Syndicate Life Sciences Pvt Ltd",
  "Syndicate Overseas",
  "Synergen Pharma India Pvt Ltd",
  "Synergy Diagnostics Pvt Ltd",
  "Synergy Pharma",
  "Synergy Pharma Formulations India Pvt Ltd",
  "Synergy Pharmaceuticals",
  "Synergy Vaccines LLP",
  "Synerheal Pharmaceuticals Pvt Ltd",
  "Synetic Healthcare",
  "Synex Global Services Llp",
  "Syngex Pharma Pvt Ltd",
  "Synim Remedies Pvt Ltd",
  "Synmedic Laboratories",
  "Synnove Pharmaceuticals Pvt Ltd",
  "Synod Pharmaceuticals Pvt Ltd",
  "Synodic Lifesciences Pvt Ltd",
  "Synokem Pharmaceuticals Ltd",
  "Synomed Healthcare",
  "Synonn Lifesciences Ltd",
  "Synonym Healthcare Pvt Ltd",
  "Synoptic Life Sciences",
  "Synovia Life Sciences Pvt Ltd",
  "Synovion Laboratories Pvt Ltd",
  "Synthergen Therapeutics Pvt Ltd",
  "Synthesis Healthcare",
  "Synthiko Formulations & Pharma Pvt Ltd",
  "Synthite Industries Ltd",
  "Syntho Pharmaceuticals Pvt Ltd",
  "Synthokind Pharmaceuticals Pvt Ltd",
  "Synthozenesis Healthcare Pvt Ltd",
  "Syntonic Lifesciences",
  "Syntro Health Care",
  "Synwell India Pharma Pvt Ltd",
  "Syqta Pharmaceuticals Pvt Ltd",
  "Syscom Organic World Pvt Ltd",
  "Syscutis Healthcare",
  "Syska Healthcare",
  "Syskem Pharmocrats",
  "Sysmed Exim Pvt Ltd",
  "Sysmed Laboratories Pvt Ltd",
  "Systacare Remedies",
  "Systemic Healthcare",
  "Systochem Laboratories Ltd",
  "Systole Remedies Pvt Ltd",
  "Systomed Pharmaceuticals Pvt Ltd",
  "Syston Biotech",
  "Systopic Laboratories Ltd",
  "Systopic Laboratories Pvt Ltd",
  "Systopic Pharma",
  "Syswin Pharma",
  "Syswin Pharmaceuticals Pvt Ltd",
  "Sytabz Meditech Pvt Ltd",
  "T S Life Sciences",
  "T T K Pharma Ltd",
  "T Walker's Pharmceuticals Pvt Ltd",
  "Tablet India Ltd",
  "Tablets India Ltd",
  "TABP Snacks and Beverages Pvt Ltd",
  "Tabq Therapeutics",
  "Tabs Lab Healthcare LLP",
  "Tabsule Pharmaceuticals",
  "Tahbert Healthcare Pvt Ltd",
  "Tahbert聽Healthcare Pvt Ltd",
  "Tahbert鑱紿ealthcare Pvt Ltd",
  "Taica Pharma",
  "Taiyo Group",
  "Taizi Pharma Pvt Ltd",
  "Taj Pharma India Ltd",
  "Takeda Pharmaceuticals India Pvt Ltd",
  "Taksa LIfe Sciences",
  "Taksh Healthcare Pvt Ltd",
  "Taksha Remedies Pvt Ltd",
  "Talent Healthcare",
  "Talent India",
  "Talent India ",
  "Talin Remedies",
  "Talohsty Medmark Pvt Ltd",
  "Talson Pharmaceuticals",
  "Talwar Pharma",
  "Tamis Pharma",
  "Tamman Titoe Pharma Pvt Ltd",
  "Tan Therapeuticss",
  "Tandem Life Sciences",
  "Tanik Healthcare Pvt Ltd",
  "Tanishq Lifecare",
  "Tanmed Pharma India Pvt Ltd",
  "Tanpal Nutraceuticals",
  "Tansukh Herbals Pvt Ltd",
  "Tansy Molequle",
  "Tanvi Fitness Pvt Ltd",
  "Tanvi Herbals",
  "Tanzer Lifecare Pvt Ltd",
  "Tara Biosciences Pvt Ltd",
  "Tara Lifescience Pvt Ltd",
  "Tara Nutricare Pvt Ltd",
  "Taramis Labs Pvt Ltd",
  "Target Dermacare Pvt Ltd",
  "Targof Pure Drugs Td",
  "Tarion Pharma",
  "Tarks Pharmaceuticals Pvt Ltd",
  "Tarrahlthistyl Pvt Ltd",
  "Tarsons Products Pvt Ltd",
  "Tartans Export Pvt Ltd",
  "Tarun Pharmaceuticals",
  "Tas Med India Pvt Ltd",
  "Taswin Pharma Pvt Ltd",
  "Tasyca Pharma Pvt Ltd",
  "Tata 1mg Healthcare Solutions Pvt Ltd",
  "Tata Chemicals Ltd",
  "Tata Consumer Products Ltd",
  "Tates Remedies",
  "Tatvartha Health",
  "Taurlib Pharmaceuticals Llp",
  "Taurus Laboratories Pvt Ltd",
  "Tavis Lifecare",
  "Taxila Technologies Pvt Ltd",
  "TBG pharma ltd",
  "Tbp-Tam-Bran Pharmaceuticals L",
  "TCI Star Health Products",
  "Teachers' Grace Scientific Ayurveda",
  "Teamonk Global Foods Pvt Ltd",
  "Teaxpress Pvt Ltd",
  "Teblik Drugs Pvt Ltd",
  "Tech Smart Clinics",
  "Techcroods Hygiene Pvt Ltd",
  "Technica Lab Pvt Ltd",
  "Technopharm Pvt Ltd",
  "Techyon Biotech Pvt Ltd",
  "Tecnex Pharma",
  "Tej Kalpa Pvt Ltd",
  "Tejosh Pharmacos",
  "Tek Medicare",
  "Tekas Pharma",
  "Teknovations Life Science",
  "Tel001 - Teleplex Medical Pvt Ltd",
  "Teleflex Medical Asia Pte Ltd",
  "Telic Pharma",
  "Telisto Pharmaceuticals Pvt Ltd",
  "Temple Wellness Ventures India Pvt Ltd",
  "Temwark Life Sciences Pvt Ltd",
  "Tender Skin Products Pvt Ltd",
  "Tenet Healthcare",
  "Tengee Lifescience",
  "Tenshi Lifecare Pvt Ltd",
  "Teq Pharmaceuticals Pvt Ltd",
  "Teqmed Pharma LLP",
  "Tergum Remedies Pvt Ltd",
  "Terma Medicare India Pvt Ltd",
  "Terra Pharma Pvt Ltd",
  "Terrace Pharmaceuticals",
  "Tesact Biocare",
  "Tesla Labs Pvt Ltd",
  "Teslamed Pharmaceuticals Pvt Ltd",
  "Tesni Pharmaceuticals",
  "Tessnibiomed Pvt Ltd",
  "Tested & Trusted Pharma Pvt Ltd",
  "Tetramed Biotek Pvt Ltd",
  "Tetrique Pharmaceuticals Pvt Ltd",
  "Tevos Pharmaceuticals",
  "Texas Biotech",
  "Texas Phamaceuticals Pvt Ltd",
  "Texas Pharmaceuticals",
  "Thakur Ayurvedic Pharmacy Pvt Ltd",
  "That's My Stuff",
  "The Aesthetic Sense",
  "The Beauty Business",
  "The Biogeniter Tessconix Healthcare",
  "The Body Avenue",
  "The Boots Company",
  "The Crimson Project",
  "The Divine Foods",
  "The Fij Group",
  "The Gerber Store",
  "The Great Herbal",
  "The Healthy Cravings Co",
  "The Healthy Cup",
  "The Himalaya Drug Co",
  "The Himalaya Drug Co (Pharma)",
  "The Himalaya Drug Company",
  "The House of Khadi Essentials",
  "The Indian Chai",
  "The Kirti Works",
  "The Madras Pharm",
  "The Mentholatum Company",
  "The Moms Co ",
  "The Paw Zone",
  "The Pharmed Research Lab Pvt Ltd",
  "The Protein Works",
  "The Sidhpur Sat-Isabgol Factory",
  "The Skin Store",
  "The Snack Company",
  "The South India Surgical Co",
  "The Unani & Co",
  "The Varma Pharmacy P Ltd",
  "The Vitamin Shoppe",
  "The West Indian Honey Co",
  "The White Willow",
  "The Yoga Man Lab",
  "Theia Health Care",
  "Theia Healthcare",
  "Theia Pharmco Pvt Ltd",
  "Themis Medicare Ltd",
  "Themis Pharmaceuticals Prop Chemosyn Ltd",
  "Theo Pharma Pvt Ltd",
  "Theogen Pvt Ltd",
  "Theon Pharmaceuticals Ltd",
  "Theramis pharmaceutical",
  "Therapeia Pharmaceuticals Pvt Ltd",
  "Therapeutic Pharmaceuticals",
  "Therapia Sanjeevani",
  "Therawin Formulations",
  "Therdose Pharma Pvt Ltd",
  "Theta Lab Pvt Ltd",
  "Thiest Biotec",
  "Thor Drugs Pvt Ltd",
  "Thorab Pharma & Research Laboratory",
  "Three Dots Lifescience",
  "Threos Healthcare Pvt Ltd",
  "Threpsi Solutions Pvt Ltd",
  "Thrift Pharmaceuticals",
  "Thrillx Herbs",
  "Thrive Tribe Wellness Solutions Pvt Ltd",
  "Thrivex Lifesciences Pvt Ltd",
  "Thurs Pharmaceuticals",
  "Thuyam Life Pvt Ltd",
  "Thyme Pharma Pvt Ltd",
  "Thymex Healthcare",
  "Thyone Health Care",
  "Ticoma Pharmacia",
  "Tidal Healthcare Pvt Ltd",
  "Tidal Laboratories Pvt Ltd",
  "Tigris Life Sciences",
  "Tikish Pharmaceuticals",
  "Tilvintion Pharma",
  "Time Laboratories",
  "Time Tested Drug Laboratory",
  "Times Biotech",
  "Times Drugs & Pharmaceuticals Pvt Ltd",
  "Times Healthcare",
  "Times Internet Ltd",
  "Timetech Formulations Ltd",
  "Timetech Pharma Pvt Ltd",
  "Timetex Mills",
  "Timon Tender Care Pharmaceuticals Pvt Ltd",
  "Tirupati Medicare Ltd",
  "Tirupati Pharmacy",
  "Tiruvision Medicare",
  "Tisanova Lifecare Inc",
  "Tiska Life Sciences",
  "Tissot Pharmaceutical Pvt Ltd",
  "Titan Bioscience Pvt Ltd",
  "Titan Healthcare P Ltd",
  "Titli Lifesciences Ltd",
  "Tivamed Biotech",
  "Tms India",
  "Tms India Pvt Ltd",
  "TNT Lifesciences",
  "TNW International Pvt Ltd",
  "To Be Healthy Foods Pvt Ltd",
  "Todkar Sanjeevani Nisargopchar Kendra",
  "Tong Garden Food Marketing (India) Pvt Ltd",
  "Tophill Pharma Ltd",
  "Topmed Pharmaceuticals Pvt Ltd",
  "Torainse Life Care Pvt Ltd",
  "Toran Biotech",
  "Torance Healthcare Pvt Ltd",
  "Torcium Biotech Pvt Ltd",
  "Torenia Health Care Pvt Ltd",
  "Torgem Pharmaceutical Pvt Ltd",
  "Torino Labs (P) Ltd",
  "Torion Labs",
  "Toronto Pharmaceuticals Pvt Ltd",
  "Torque Pharmaceuticals Pvt Ltd",
  "Torrent (Alfa)",
  "Torrent (Axon)",
  "Torrent (Azuca)",
  "Torrent (Mind)",
  "Torrent (Neuron)",
  "Torrent (Spl)",
  "Torrent Drug & Chemicals Ltd",
  "Torrent Laboratories Pvt Ltd",
  "Torrent Labs Ltd",
  "Torrent Pharma",
  "Torrent Pharma Ltd(Prima)",
  "Torrent Pharma Ltd(Psycan)",
  "Torrent Pharma Ltd(Vista)",
  "Torrent Pharmaceuticals",
  "Torrent Pharmaceuticals Ltd",
  "Torrent Pharmaceuticals Ltd(Psycan)",
  "Torrent Pharmaceuticals Ltd(Vista)",
  "Torres Lifesciences",
  "Torway Pharmacia Pvt Ltd",
  "TOSC International Pvt Ltd",
  "Toss-up Pharmaceuticals Pvt Ltd",
  "Total Healthcare",
  "Totem Biotech Pvt Ltd",
  "Tots and Moms",
  "Toubib Pharma Pvt Ltd",
  "Touch Pharma",
  "Touchwood Industries",
  "Toxiferol CM",
  "TPPL Pharma",
  "Trade Hub",
  "Tradmod Lifesciences Pvt Ltd",
  "Trans Life Sciences",
  "Trans Pharmaceuticals",
  "Transced Therapeutics",
  "Transcend Therapeutics Pvt Ltd",
  "Transcure Therapeutics Pvt Ltd",
  "Transformative Learning Solutions Pvt Ltd",
  "Transmind International",
  "Traumen Lifesciences Pvt Ltd",
  "Travancore Ayurveda",
  "Treatwell Biotech",
  "Treatwell Pharma",
  "Tregenesis Sciences Pvt Ltd",
  "Trends Pharma Pvt Ltd",
  "Trents Health Care",
  "Tres Care Pvt Ltd",
  "Tresco Lab",
  "Treta Healthcare",
  "Trexgen Pharmaceuticals Pvt Ltd",
  "Tri Medix Pharma",
  "Tri Mitsu Pharmaceuticals",
  "Tri Pharma",
  "Triaim Phrma Pvt Ltd",
  "Tribhawan Injectables",
  "Tribune Healthcare Pvt Ltd",
  "Tricept Life Sciences Pvt Ltd",
  "Tricorn Healthcare",
  "Tricos Dermatologics Pvt Ltd",
  "Tricoshine Hair Shampoo",
  "Trident Pharma",
  "Tridev Pharmaceutical",
  "Tridoss Lab Pvt Ltd",
  "Tridoss Laboratories Pvt Ltd",
  "Trigat Lifesciences Pvt Ltd",
  "Trigenesis Life Sciences Pvt Ltd",
  "Trigger Apparels Ltd",
  "Triglobal Bioscience Pvt Ltd",
  "Triglobal Lifesciences Pvt Ltd",
  "Triguni Herbal Research",
  "Triivik Life Sciences Pvt Ltd",
  "Trikay Pharmaceutical Inc",
  "Trikaya Marketing",
  "Triko Pharmaceuticals",
  "Trikona Pharmaceuticals Pvt Ltd",
  "Trikut Humanities Bio Science Pvt Ltd",
  "Trillest Healthcare",
  "Trimak Lifesciences",
  "Trimark Pharmaceuticals Pvt Ltd",
  "Trinity Neutraceuticals Ltd",
  "Trinity Pharmaceuticals",
  "Trio Biotech Pvt Ltd",
  "Trio Lifescience Pvt Ltd",
  "Trio Pharmaceuticals Pvt Ltd",
  "Trio Remedies Pvt Ltd",
  "Triohix Critical Care",
  "Triomed Health Care Pvt Ltd",
  "Trion Pharma India Llp",
  "Tripada Biotec Pvt Ltd",
  "Tripada Healthcare Pvt Ltd",
  "Tripada Lifecare Pvt Ltd",
  "Triple A Pharma",
  "Triple Play Technologies Pvt Ltd",
  "Tripoor Sciences",
  "Triquetrus Essentials Pvt Ltd",
  "Trireme Life Sciences Pvt Ltd",
  "Triton Health Care Pvt Ltd",
  "Triton Healthcare Pvt Ltd",
  "Triumph Pharmaceuticals Pvt Ltd",
  "Triumph Remedies Pvt Ltd",
  "Trivag Lifesciences Pvt Ltd",
  "Triveni Engineering & Industries Ltd",
  "Trividha Ayurveda",
  "Troika (Aura)",
  "Troika (Spectra Div)",
  "Troikaa Parenterals Pvt Ltd",
  "Troikaa Pharmaceuticals Ltd",
  "Troikaa Pharmaceuticals Pvt Ltd",
  "Troikk Cardiac Care Ltd",
  "Troks Pharmaceuticals",
  "Trombone Pharma Pvt Ltd",
  "Trophic Wellness Pvt Ltd",
  "Trost Medicare Pvt Ltd",
  "Trove Pharmaceuticals",
  "Trrust Health Care",
  "Trubeca Lifesciences",
  "Trucare Kidz India Pvt Ltd",
  "Truchas Pharmaceutical Pvt Ltd",
  "True Aussie",
  "True Care Biomedix",
  "True Choice Pharmaceuticals",
  "True Health",
  "Truecure Healthcare Pvt Ltd",
  "Truelight Life Sciences Pvt Ltd",
  "Truenorth Healthcare LLP",
  "Trueroots Impex Pvt Ltd",
  "Truett Pharmaceutical Pvt Ltd",
  "Truewella Healthcare Pvt Ltd",
  "Truezos Biotech",
  "Trugen Neurosciences Pvt Ltd",
  "Trugen Pharmaceuticals Pvt Ltd",
  "Trugreen Agribiotech India Pvt Ltd",
  "Truhealthy LLP",
  "Trulay Pharmaceuticals Pvt Ltd",
  "Trulip Pharma",
  "Trumac Health Care",
  "Trumac Healthcare",
  "Trumeda Healtcare Pvt Ltd",
  "Trumedis Pharmaceuticals",
  "Trunkman Pharmaceuticals Pvt Ltd",
  "Truradix Nutraceuticals Pvt Ltd",
  "Trust Pharma",
  "Trustcare Pharmaceuticals",
  "TruTribe Ventures Pvt Ltd",
  "Truweight Wellness Pvt Ltd",
  "Truwin Pharmaceuticals Pvt Ltd",
  "Truworth Healthcare",
  "Try Drugs Pharmaceuticals",
  "Tsar Health Pvt Ltd",
  "TTK Health Care Ltd",
  "TTK Healthcare Ltd",
  "Tuhi Lifesciences Pvt Ltd",
  "Tulip Biotech",
  "Tulip Group",
  "Tulip Lab Pvt Ltd",
  "Tulison Pharma",
  "Tulsi Medi Sciences",
  "Tunes Pharma",
  "Tunic Healthcare",
  "Turbo International",
  "Turio Pharmaceuticals Pvt Ltd",
  "Turtle Shell technologies Pvt Ltd",
  "Tushita Care",
  "Tusker Pharma Pvt Ltd",
  "Tuton Pharmaceuticals",
  "Tuttsan Pharma Pvt Ltd",
  "Tvam Healthcare (OPC) Pvt Ltd",
  "TVS Biotech",
  "Twasa Cosmetics",
  "TWB Lifesciences (OPC) Pvt Ltd",
  "Tweet India Pharmaceuticals Pvt Ltd",
  "TWF Technologies Pvt Ltd",
  "Twilight Mercantiles Ltd",
  "T-Winbrains India Pvt Ltd",
  "Twinlab Corporation",
  "Tychos Therapeutics Pvt Ltd",
  "Tycoon Pharmaceuticals Pvt Ltd",
  "Tykhe Healthcare India Pvt Ltd",
  "Tynor Orthotics Ltd",
  "Tynor Orthotics Pvt Ltd",
  "Tyramed Healthcare Pvt Ltd",
  "Tyrant Pharma Pvt Ltd",
  "Tyscon Pharmaceuticals",
  "Tysum Pharma",
  "Tyykem Pvt Ltd",
  "Tzana Pharmaceuticals Pvt Ltd",
  "U & V Cancure Pvt Ltd",
  "U R 10",
  "U S Biotech",
  "U S Pharma",
  "U V",
  "U V ",
  "U V Avo",
  "U V Dew",
  "U&V Health Care",
  "UAS Pharma",
  "Ubatlife Health Care",
  "UBIK Solutions Pvt Ltd",
  "Ubique Pharma Pvt Ltd",
  "Ubit Pharmaceuticals Pvt Ltd",
  "Ucardix Pharmaceuticals",
  "Ucb India Ltd",
  "UCB India Pvt Ltd",
  "Ucube Scientific Research Pvt Ltd",
  "Udai Pharmaceutical Pvt Ltd",
  "Uday Fashion",
  "Udik Pharmaceuticals Pvt Ltd",
  "UE Autotech I Pvt Ltd",
  "Uggapi Tech Solutions Pvt Ltd",
  "UHC Life Sciences",
  "UHUD Lifesciences (OPC) Pvt Ltd",
  "Uj Life Care (opc) Pvt Ltd",
  "Uko Pharmatech Pvt Ltd",
  "ULA Pharmaceuticals",
  "Ulberto Pharma Pvt Ltd",
  "Ulive Pharma",
  "Ulterius Pharmaceuticals Pvt Ltd",
  "Ultichem Pharmaceuticals Pvt Ltd",
  "Ultimate Healthcare",
  "Ultimate Nutrition Inc",
  "Ultra Bio Labs",
  "Ultra Biologicals",
  "Ultra Biotech Formulations",
  "Ultra Chiron Pharmaceuticals Pvt Ltd",
  "Ultra Drugs Pvt Ltd",
  "Ultra Health Care",
  "Ultra Wellness Pvt Ltd",
  "Ultrachem Pharmaceuticals",
  "Ultramark Healthcare Pvt Ltd",
  "Ultrascot Biotech Pvt Ltd",
  "Ultratech Pharmaceuticals",
  "Uma Ayurvedics Pvt Ltd",
  "Umano Healthcare Pvt Ltd",
  "Umbrella Biotech",
  "Umpl India",
  "Unexo Life Sciences Pvt Ltd",
  "Uni Labs",
  "Uni Labs India Pvt Ltd",
  "Uni Light Pharma",
  "Uni Pex",
  "Uni Sankyo Ltd",
  "Uniark Healthcare Pvt Ltd",
  "Unibic Foods India Pvt Ltd",
  "Unibiotech Formulations",
  "UniBiotics Pharmaceutical",
  "Unicare Remedies",
  "Unicharm India",
  "Unichem Lab Ltd(Uniserch/Card",
  "UNICHEM LABORATORIES Ltd",
  "Unico Consumers Products Pvt Ltd",
  "Unico Drugs",
  "Unicon Life Sciences",
  "Unicor Pharmaceuticals LLP",
  "Unicorn India",
  "Unicos Therapeutics",
  "Unicure India Pvt Ltd",
  "Unicure Remedies Pvt Ltd",
  "Uniedil Pharmaceuticals",
  "Unifab pharmaceuticals",
  "Unifaith Biotech (P) Ltd",
  "Unifarma Global Consumer Health Care Pvt Ltd",
  "UNIFIC Pharmaceuticals Pvt Ltd",
  "Unigrip Inc",
  "Unigroups Pharma",
  "Unigrow Pharmaceuticals",
  "Unihelix Biotech Pvt Ltd",
  "Unijoy Remedies Pvt Ltd",
  "Unijules Life Science Ltd",
  "Unika Pharmaceuticals Pvt Ltd",
  "Unikid Healthcare",
  "Unikind Pharma",
  "Unilead Pharmaceuticals Pvt Ltd",
  "Unilever India",
  "Unilever International",
  "Unilex Lifesciences Pvt Ltd",
  "Unilight Pharma",
  "Unilite Medicare Pvt Ltd",
  "Unimac Pharmaceuticals Pvt Ltd",
  "Unimarck Healthcare Ltd",
  "Unimarck Pharma",
  "Unimarck Pharma India Ltd",
  "Unimark Remedies Ltd",
  "Unimax Laboratories pvt Ltd",
  "Uni-Med India",
  "Uninor Biotech",
  "Uninova Lifesciences Pvt Ltd",
  "Union Drug",
  "Uniorange group",
  "UniOrange Life Care Pvt Ltd",
  "Unipark Biotech Pvt Ltd",
  "Unipath Healthcare",
  "Uni-Pex Pharmaceuticals Pvt Ltd",
  "Uniphar Biotech Pvt Ltd",
  "Uniphase India Pharmacueticals",
  "Unipower Pharmaceuticals Pvt Ltd",
  "Uniprixx Laboratories Pvt Ltd",
  "Uniprogen Biotech Pvt Ltd",
  "Unipure Biotech",
  "Uniqaya Lifestyle",
  "Uniqlife Biosciences Pvt Ltd",
  "Unique Biotech",
  "Unique Drugs Laboratories",
  "Unique Life Sciences (P) Ltd",
  "Unique Pharmaceuticals",
  "Unique Remedies & Pharmaceutical",
  "Unique Surgicals",
  "Uniqure Healthcare Pvt Ltd",
  "Unireach Healthcare Pvt Ltd",
  "Uniroyal Biotech",
  "Unisafe Technologies",
  "Unisage Green Science",
  "Unisan Pharmaceutical Pvt Ltd",
  "Uni-san Pharmaceuticals",
  "Unisarsh Pharmaceuticals Pvt Ltd",
  "Unisearch Laboratories India Pvt Ltd",
  "Unisharp Pharmaceuticals",
  "Unishine Health Care",
  "Unisolve Pharmaceuticals Pvt Ltd",
  "Unison Healthcare",
  "Unison Laboratories",
  "Unison Pharmaceuticals Pvt Ltd",
  "Unison Pharmacutical Ltd",
  "Unispeed Pharmaceuticals Pvt Ltd",
  "Unistar Biotech Ltd",
  "Unisun Organics Pvt Ltd",
  "Uniswen Medisciences",
  "Unital Pharmaceuticals Pvt Ltd",
  "Unitas Healthcare Pvt Ltd",
  "Unitech Health Care Pvt Ltd",
  "United Biotech Pvt Ltd",
  "United Biscuits Pvt Ltd",
  "United Foods",
  "United Formulations Pvt Ltd",
  "United Laboratories",
  "United Laboratories India Pvt Ltd",
  "United Lifecare Pvt Ltd",
  "United Ortho",
  "United Pharmaceuticals",
  "Unived Healthcare Products Pvt Ltd",
  "Univentis Medicare Ltd",
  "Universal Biogenics",
  "Universal Biotech Pvt Ltd",
  "Universal Corporation ltd",
  "Universal Drug House Pvt Ltd",
  "Universal Health Sciences",
  "Universal Impex",
  "Universal Lab",
  "Universal Life Sciences",
  "Universal Medicare",
  "Universal Medicare Pvt Ltd",
  "Universal Medikit",
  "Universal Micro Sciences",
  "Universal NutriScience",
  "Universal Nutrition",
  "Universal Pharma",
  "Universal Twin Labs",
  "Univictor Healthcare Pvt Ltd",
  "Univue Enterprises",
  "Uniwell Medicare Pvt Ltd",
  "Uniword Pharma",
  "Unix Biotech",
  "Unix Health Care",
  "Unixcel Pharmaceuticals Pvt Ltd",
  "Uniza Healthcare LLP",
  "Unizy Healthcare Pvt Ltd",
  "Unjha Ayurvedic Pharmacy",
  "UnLtdNutrition Pvt Ltd",
  "Upakarma Ayurveda",
  "Uprising Science Pvt Ltd",
  "UPS Healthcare",
  "Upsilon Pharmaceuticals",
  "Uptas Lifescience",
  "Uranus Laboratories",
  "Urban Essentials India Pvt Ltd",
  "Urban Exports Pvt Ltd",
  "Urenus Pharmaceuticals",
  "Urihk Pharmaceutical Pvt Ltd",
  "Uro Alliance",
  "Urochem Biotech",
  "Urostark Healtcare LLp",
  "URSA Pharm",
  "Urvija Pharmaceuticals",
  "USG Pharma",
  "USK Therapeutics Pvt Ltd",
  "USR Remedies",
  "USV Ltd",
  "USV Ltd(Condor)",
  "USV Ltd(Conquer)",
  "USV Ltd(Corvette)",
  "USV Ltd(Multi Spl)",
  "Usv Ltd(Pharma)",
  "UTH Beverage Factory Pvt Ltd",
  "UTH Healthcare",
  "UVA Pharmaceuticals",
  "Uvb Healthcare Pvt Ltd",
  "Uvisun Organic Pvt Ltd",
  "Uvkan Healthcare Pvt Ltd",
  "Uvtech Biosciences Pvt Ltd",
  "Uzen Pharma",
  "V Care Biotech",
  "V Care Pharma Pvt Ltd",
  "V H B Pharma Pvt Ltd",
  "V H Bhagat & Co ",
  "V L Pharma",
  "V Mac Pharma Pvt Ltd",
  "V R Med Healthcare",
  "V S Healthcare Ltd",
  "V S Medico",
  "V1 Enterprises",
  "Vaadi Herbals Pvt Ltd",
  "Vacuoles Pharmaceutical Pvt Ltd",
  "Vaddmaan Innovation LLP",
  "Vadira Ayuromedic Pvt Ltd",
  "Vahdam Teas (P) Ltd",
  "Vaidic Wellness Products",
  "Vaidrishi Laboratories",
  "Vaidya Revati Prasad",
  "Vaishali Pharma Ltd",
  "Vaishali Pharmaceuticals",
  "Vaishnavi Drugs Agency",
  "Vakker Pharmaceuticals Pvt Ltd",
  "Vakrangi Health Tech",
  "Vaks Pharma",
  "Vakula Health Care Pvt Ltd",
  "Valay India Pharma Pvt Ltd",
  "Valcret Lifesciences Pvt Ltd",
  "Valence Pharma",
  "Valency Healthcare",
  "Valens Biotech",
  "Valentino Fine Foods",
  "Valentis Pharma",
  "Valiant Healthcare Ltd",
  "Valiants Pharmaceuticals",
  "Valpra Biotech Pvt Ltd",
  "Valtin Health Care Pvt Ltd",
  "Value Formulations",
  "Vana Cosmeceutical & Action Research Pvt Ltd",
  "VanArc Organic Rituals",
  "Vance Health Pharmaceuticals Ltd",
  "Vancorp Pharmaceuticals Pvt Ltd",
  "Vandana Surgi Pharma Pvt Ltd",
  "Vanesa Care Pvt Ltd",
  "Vanesha Healthcare",
  "Vanguard Therapeutics Pvt Ltd",
  "Vanmart Pharmaceutical and Biotech Pvt Ltd",
  "Vanmed Pharmaceutical",
  "Vanmed Pharmaceuticals",
  "Vanmed Pharmaceuticals Pvt Ltd",
  "Vanprom Lifesciences Pvt Ltd",
  "Vanqor Pharmaceuticals",
  "Vanquish Pharmaceuticals",
  "Vanran Healthcare Pvt Ltd",
  "Vansan Pharmaceuticals Pvt Ltd",
  "Vansh Marketing",
  "Vanshita Lifecare",
  "Vanshyam Pharma",
  "Vantage Biotech Pvt Ltd",
  "VAP Medical Supplies",
  "Vaqure Remedies",
  "Varah Healthcare",
  "Varajs Biotecnica Pvt Ltd",
  "Vardeus Naturals Pvt Ltd",
  "Vardhaman Remedies Pvt Ltd",
  "Vardhman Biotech Pvt Ltd",
  "Vardhman Healthcare",
  "Varenyam Healthcare Pvt Ltd",
  "Varenyam International",
  "Vareynyam International",
  "Variety Pharmaceuticals Pvt Ltd",
  "Varima Pharma Pvt Ltd",
  "Varion Food Sciences Pvt Ltd",
  "Varmed Pharma Pvt Ltd",
  "Varn pharmaceuticals",
  "Varroc Lifesciences",
  "Varsoy Health Care",
  "Vartak Foods & Beverages Pvt Ltd",
  "Vartis RemediesLlp",
  "Vartrix Bioscience",
  "Varun Life Sciences Pvt Ltd",
  "Vasalius Lifescience Pvt Ltd",
  "Vasant Pharma",
  "Vashisht Homoeopathic Pharmaceuticals",
  "Vasolife Healthcare",
  "Vasphar India",
  "VASU Healthcare Pvt Ltd",
  "Vasu Organics Pvt Ltd",
  "Vasu Pharmaceuticals Pvt Ltd",
  "Vasuchem Lifesciences Pvt Lt",
  "Vatican Life Sciences Pvt Ltd",
  "Vats Biotech Pvt Ltd",
  "Vatsal Ayurvedic Products (P) Ltd",
  "Vavisto Biotech",
  "Vaxova Drugs Pvt Ltd",
  "Vaxter Pharmaceuticals",
  "VB Pharma",
  "Vbrace International",
  "VCare Pharcos",
  "Vcs Pharmaceuticals Pvt Ltd",
  "VDV Pharmaceuticals Pvt Ltd",
  "Veasley Pharmaceuticals Pvt Ltd",
  "Veblen Pharmaceutical Pvt Ltd",
  "Vectura Fertin Pharma Pvt Ltd",
  "Veda Biomedics India Ltd",
  "Veda Herbals, Crest Facility Management",
  "Veda5 Wellness",
  "Vedame Herbals",
  "Vedanta Newmendis Pharma",
  "Vedantika Herbals Pvt Ltd",
  "Vedapure Naturals Pvt Ltd",
  "Vedi Herbals Pvt Ltd",
  "Vedic Ayurveda",
  "Vedic Bio Labs Pvt Ltd",
  "Vedic Blends",
  "Vedic Mantra",
  "Vedic Upchar Pvt Ltd",
  "Vedical India Wellness LLP",
  "Vedicare Ayurveda Pvt Ltd",
  "Vedicayur Wellness India Pvt Ltd",
  "Vedinova Life Sciences",
  "Vedistry Pvt Ltd",
  "Vedu’s Laboratories Pvt Ltd",
  "Vedu鈥檚 Laboratories Pvt Ltd",
  "Vee Medi Biotech India Pvt Ltd",
  "Vee Remedies",
  "Veeba Food Services Pvt Ltd",
  "Veecube Healthcare Pvt Ltd",
  "Veejay Laboratories",
  "Veemon Healthcare",
  "Veeom Healthcare",
  "VeeQure Lifesciences",
  "Veewin Healthcare Pvt Ltd",
  "Veeyork Healthcare",
  "Vefa Ilac LLP",
  "Vega Laboratories Pvt Ltd",
  "Vega Pharma",
  "Vegan Originals Company",
  "Veincure Healthcare Pvt Ltd",
  "Vejovis Pharmacy (OPC) Pvt Ltd",
  "Velesila Pharmaceuticals Pvt Ltd",
  "Velicia India Pvt Ltd",
  "Veliko Pharmacls",
  "Velisca Healthcare Pvt Ltd",
  "Velite Pharmaceuticals",
  "Vellinton Healthcare",
  "Velliq Pharmaceuticals Pvt Ltd",
  "Velma Healthcare",
  "Velnex Bioceuticals",
  "Velnex Life Sciences Pvt Ltd",
  "Velnik India Ltd",
  "Velnourish Pharma Pvt Ltd",
  "Veloce Herbals Pvt Ltd",
  "Veloce Innovations LLP",
  "Velonik Lifesciences Pvt Ltd",
  "Veltra India Pvt Ltd",
  "Venbriss Healthcare",
  "Vencare Formulations Pvt Ltd",
  "Vencura Biotech Pvt Ltd",
  "Vendimia Foods Pvt Ltd",
  "Venera Pharma",
  "Venetia Laboratories",
  "Venistro Biotech",
  "Venix Healthcare",
  "Venmed Life Sciences Pvt Ltd",
  "Venova Pharmaceuticals Pvt Ltd",
  "Vensat Bio",
  "Vensys Pharma",
  "Ventura Biotech",
  "Ventura Pharmaceuticals",
  "Venture Healthcare Pharma Pvt Ltd",
  "Ventus Pharmaceuticals Pvt Ltd",
  "Venus Ayurveda",
  "Venus Remedies Ltd",
  "Venus Safety & Health Pvt Ltd",
  "Veoje Cosmetics",
  "Vera Pharma Ltd",
  "Verbiance Lifesciences Pvt Ltd",
  "Verdeus Naturals Pvt Ltd",
  "Verdure Pharma Pvt Ltd",
  "Vereor Healthcare Pvt Ltd",
  "Veridicus Health Care Pvt Ltd",
  "Veritaz Healthcare Ltd",
  "Verleihen Life Sciences Pvt Ltd",
  "Verlen Labs Pvt Ltd",
  "Verma Home",
  "Vermilion Healthcare Pvt Ltd",
  "Verna Health Care",
  "Vernal Life Science",
  "Vernoxy Pharma Pvt Ltd",
  "Verrmont Healthcare Pvt Ltd",
  "Versa Pharmaceuticals Pvt Ltd",
  "Versatil Pharmaceuticals Pvt Ltd",
  "Version 2 Healthcare India Pvt Ltd",
  "Vertebra Lifesciences Pvt Ltd",
  "Vertech Health Solutions India Pvt Ltd",
  "Vertex India Healthcare",
  "Vertex Pharmaceuticals",
  "Vertis Pharmaceuticals Pvt Ltd",
  "Verve Biosciences Pvt Ltd",
  "Verve Formulations Pvt Ltd",
  "Verve Healthcare Ltd",
  "Verve Human Care Laboratories",
  "Vervetic Healthcare Pvt Ltd",
  "Vesla Life Sciences Pvt Ltd",
  "Vesnik Molecules",
  "Vestal Healthcare",
  "Vestal Lifesciences Pvt Ltd",
  "Vestas Life Sciences",
  "Vesuvius Pharma Pvt Ltd",
  "Vetbiochem India Pvt Ltd",
  "Veterix Lifesciences Pvt Ltd",
  "Vetnosac Research India Pvt Ltd",
  "Vetoquinol India Animal Health Pvt Ltd",
  "Vexilla Healthcare",
  "VGR Bio Lab",
  "Vhb Life Sciences Inc",
  "VHB Lifesciences Inc",
  "VHC Pharmaceuticals",
  "Vhl Pharmaceuticals Pvt Ltd",
  "VHVL Inc",
  "Viakos Pharmaceuticals (Opc) Pvt Ltd",
  "Viaveta Healthcare Pvt Ltd",
  "Vibcare Pharma Pvt Ltd",
  "Vibdrugs Biosciences",
  "Vibgyor Vision Care",
  "Vibrant Healthcare Pvt Ltd",
  "Vibrant Life Sciences",
  "Vibrant Nutraceuticals",
  "Vibsan Pharmaceuticals",
  "Vibsun Pharmaceutical",
  "Vibzoe Pharmaceuticals India Pvt Ltd",
  "Vicco Laboratories",
  "Vichy Laboratories",
  "Victor Pharmaceutical",
  "Victoria Pharma",
  "Victro Pharma",
  "Vidakem Lifesciences Pvt Ltd",
  "Vidhata Enterprises",
  "Vidya Herbs Pvt Ltd",
  "Vie Foods Pvt Ltd",
  "Viento Healthcare",
  "View Laboratories",
  "Vigada Care Pvt Ltd",
  "Vigilant Life Sciences Pvt Ltd",
  "Vigor Wellness",
  "Vihan Herbal & Food Ingredients",
  "Viilbery Healthcare Pvt Ltd",
  "Vijay Surgical Corporation",
  "Vikamshi Fabrics Pvt Ltd",
  "Vikas Pharma",
  "Viking Pharma Opc Pvt Ltd",
  "Vikram Laboratories",
  "Vikraya Vulture Pvt Ltd",
  "Vilarsh Healthcare Pvt Ltd",
  "Vilbiotec Life Sciences",
  "Vilco Laboratories Pvt Ltd",
  "Vilead Healthcare",
  "Vilin Bio Med Ltd",
  "Ville De Cocoa Pvt Ltd",
  "Viltis Pharma Pvt Ltd",
  "Vimac Healthcare",
  "Vimnat Pharmaceuticals",
  "Vimson Derma",
  "Vin Arx Pharmaceuticals Pvt Ltd",
  "Vin Medsure India Pvt Ltd",
  "Vinayak Care Solution Pvt Ltd",
  "Vinca Lifesciences",
  "Vindas Chemical Industry Pvt Ltd",
  "Vindcare Lifesciences",
  "Vindeca Life Sciences",
  "Vinder Pharma",
  "Vinjoh Healthcare Pvt Ltd",
  "Vinnana Care LLP",
  "VINS BioProducts Ltd",
  "Vinsha Pharmaceuticals",
  "Vinshi Wellness",
  "Vinson pharma",
  "Vinson Traders",
  "Vintage Labs Pvt Ltd",
  "Vintas Pharmaceuticals Pvt Ltd",
  "Vintech Formulations",
  "Vintek Pharmaceuticals",
  "Vintochem Pharmaceuticals",
  "Violet Health Products India Pvt Ltd",
  "Violette Remedies Pvt Ltd",
  "VIP Industries Ltd",
  "Vippy Industries Ltd",
  "Viracare Pharma Pvt Ltd",
  "Virasat Ltd",
  "Virchow Biotech Pvt Ltd",
  "Virchow Healthcare Pvt Ltd",
  "Virgo Healthcare Pvt Ltd",
  "Viribus Healthcare",
  "Virtue Remedies",
  "Virtuous Healthcare Ltd",
  "Virumal Megacorp Healthcare Pvt Ltd",
  "Visage Beauty & Health Care Pvt Ltd",
  "Visage Lines Personal Care Pvt Ltd",
  "Viscot Surgicals Pvt Ltd",
  "Vish Lifecare Pvt Ltd",
  "Vishaura Healthcare Pvt Ltd",
  "Vishesh Healthcare Solutions Pvt Ltd",
  "Vishnu Formulations",
  "Vishwak Pharmaceuticals",
  "Vision Biolife Pvt Ltd",
  "Vision Biotech",
  "Vision Medicare Industries",
  "Vision Medilink",
  "Vision Parenteral Pvt Ltd",
  "Vision Pharma",
  "Vision Remedies",
  "Visionaari LLP",
  "Vissco Rehabilitation Aids Pvt Ltd",
  "Vista Furnishing Pvt Ltd",
  "Vista Health Care",
  "Vista Life Sciences Pvt Ltd",
  "Vistara Life Sciences Pvt Ltd",
  "Vistica Life Sciences",
  "Vistran Pharmaceuticals",
  "Viswaropha Nuttricare",
  "Viswasoorya Pharmaceuticals",
  "Vita Bona Lifesciences",
  "Vita Pharmaceuticals",
  "Vitabiotech Healthcare Pvt Ltd",
  "Vitabolik Pharma",
  "Vital Care Pvt Ltd",
  "Vital Formulations Ltd",
  "Vitalae Healthcare",
  "Vitality21 Biosciences Llp",
  "Vitalogy Healthcare",
  "Vitalware Pvt Ltd",
  "Vitalys Pharmaceuticals Pvt Ltd",
  "Vitane Pharmaceuticals Pvt Ltd",
  "Vitara Healthcare",
  "Vito Therapeutics Pvt Ltd",
  "Vitra Lifesciences",
  "Vitri Global Bioscience Pvt Ltd",
  "Vitromed Healthcare",
  "Vivaan Skincare",
  "VivaChek Laboratories Inc",
  "Vivagen Healthcare Pvt Ltd",
  "Vivan Inc",
  "Vivant Pharmaceuticals",
  "Vivex Pharmaceuticals Pvt Ltd",
  "Vivez Lifescience",
  "Vivid Biotek Pvt Ltd",
  "Vivid Labs Pvt Ltd",
  "Vivid Pharmaceuticals",
  "Vivifi Life Sciences",
  "Vivific Biosciences Pvt Ltd",
  "Vivimed Labs Pvt Ltd",
  "Viviscal Ltd",
  "Vivo Life Sciences Pvt Ltd",
  "Viwacure Healthcare Pvt Ltd",
  "Vix Pharma International",
  "Viziott Pharmaceuticals",
  "Vizyon Pharmaceuticals Pvt Ltd",
  "Vjolen Pharmaceutical",
  "Vjovis Health Care Pvt Ltd",
  "VK Enterprises",
  "Vkan healthcare",
  "VKare Bio Sciences Pvt Ltd",
  "Vkare Pharmaceutical Pvt Ltd",
  "V-Kind Health Care",
  "Vkyor Medix Pvt Ltd",
  "VL Care Pharma",
  "Vlado Sky Enterprise Pvt Ltd",
  "VLCC Online Services Pvt Ltd",
  "VLCC Personal Care Ltd",
  "VMedi-Care Pharma",
  "VMVS Nexgen Pharmarise India Pvt Ltd",
  "Vnox Pharmaceuticals Pvt Ltd",
  "Vns Pharma Pvt Ltd",
  "Voda Chemicals Pvt Ltd",
  "VOGUE PHARMA",
  "Vogue Pharmaceuticals Pvt Ltd",
  "Voiceover Pharmaceuticals Pvt Ltd",
  "Voithea Healthcare Pvt Ltd",
  "Voizmed Pharma Pvt Ltd",
  "Vokin Biotech Pvt Ltd",
  "Volaris Life Sciences Pvt Ltd",
  "Volatile Marketing",
  "Volentis Healthcare Pvt Ltd",
  "Volks Biotech",
  "Volksmed Healthcare Pvt Ltd",
  "Voll Sante Functional Foods & Nutraceuticals Pvt Ltd",
  "Voltedge Marketing Pvt Ltd",
  "Volterra Pharma",
  "Voluenta Pharmaceuticals Pvt Ltd",
  "Volus Pharma Pvt Ltd",
  "Von Remedies",
  "Vonara Bioceuticals",
  "Vonix Lifesciences",
  "Voopar Sciences Pvt Ltd",
  "Vostok & Wilcure Remedies",
  "Vostro Critical Care",
  "Votano Pharmaceuticals",
  "Votary Laboratories I Ltd",
  "Votizen Healthcare Pvt Ltd",
  "Vowcare Products Pvt Ltd",
  "Voxiva Pharmaceuticals",
  "Vpromote Health Pvt Ltd",
  "Vrd Pharmaceuticals",
  "Vrddhi Life Sciences",
  "Vrevive Medicure Pvt Ltd",
  "Vridam Healthcare Pvt Ltd",
  "Vrinda Life",
  "Vrindaam Organics Pvt Ltd",
  "Vrova Facets",
  "Vrovwen Biologics Pvt Ltd",
  "Vsaar Pharma Pvt Ltd",
  "Vsab Healthcare",
  "Vts Life Sciences",
  "Vulcan Laboratories Pvt Ltd",
  "Vvio Pharmaceuticals",
  "Vvyeth Pharmaceuticals Pvt Ltd",
  "Vyali International",
  "Vyapitus Specialities Pvt Ltd",
  "Vyas Pharmaceuticals",
  "Vybia Healthcare",
  "Vyden Lifesciences Pvt Ltd",
  "Vyom Coporation",
  "Vyom Lifecare Pvt Ltd",
  "Vyomax Lifesciences Pvt Ltd",
  "Vyonics Health Care Pvt Ltd",
  "Vypa Laboratories",
  "Vysali Pharmaceuticals Ltd",
  "Vytis Pharmaceuticals Pvt Ltd",
  "Vytrax Healthcare",
  "Wafia Herbals",
  "Wafture Healthcare Pvt Ltd",
  "Wagtail Biotech Pvt Ltd",
  "Wahl India Grooming Products Pvt Ltd",
  "Waksman Selman Pharmaceuticals Pvt Ltd",
  "Waksmed Lifescience Pvt Ltd",
  "Walberg Pharmaceuticals",
  "Waleria Essential Llp",
  "Wallace Pharmaceuticals Pvt Ltd",
  "Wallnutree Neurosciences India Pvt Ltd",
  "Wallrey Pharmaceuticals",
  "Walmark Meditech Pvt Ltd",
  "Walnut Life Sciences Pvt Ltd",
  "Walnut Lifesciences",
  "Walnut Pharma Pvt Ltd",
  "Walpar Healthcare",
  "Walron Healthcare Pvt Ltd",
  "Walter Bushnell",
  "Walter Bushnell Ltd",
  "Walton Healthcare Pvt Ltd",
  "Wanbury Ltd",
  "Wanbury Ltd(Cipla)",
  "Wancure Life Sciences",
  "Wander Ltd",
  "Wander Pvt Ltd",
  "Wannock Pharmaceuticals",
  "Wardex Pharmaceutical Pvt Ltd",
  "Warikind Pharmaceuticals Pvt Ltd",
  "Warner (India ) Pvt Ltd",
  "Warren Pharmaceuticals Ltd",
  "Waterley Pharmaceuticals Pvt Ltd",
  "Watran Pharmaceuticals Pvt Ltd",
  "Waves Bio-Tech Pvt Ltd",
  "Waylone Healthcare Pvt Ltd",
  "Waymed Lifecare Pvt Ltd",
  "Waymore Hygiene Products",
  "Wayonext Pharmaceuticals Pvt Ltd",
  "Waypham India Pvt Ltd",
  "We Naturals",
  "Wealth Care Pharmaceuticals",
  "Webcon Labs Pvt Ltd",
  "Webkraft Inc",
  "Wecare Formulations Pvt Ltd",
  "Weconnect Beauties",
  "Weefsel Pharma",
  "Wel N Innov Pharmaceuticals",
  "Welbe Life Sciences",
  "Welbeck Pharmaceuticals Pvt Ltd",
  "Welcare Pharma",
  "Welcare Pharma Pvt Ltd",
  "Welch Allyn",
  "Welco Lifecare Pvt Ltd",
  "Welcode pharmaceutical",
  "Welcott Lifesciences Pvt Ltd",
  "Welcron Biotech Pvt Ltd",
  "Welcure Pharma",
  "Welex Laboratories Pvt Ltd",
  "Welfeed Remedies Pvt Ltd",
  "Welfo Pharmaceuticals Pvt Ltd",
  "Welgenic Pharma",
  "Welior Healthcare Laboratories Pvt Ltd",
  "Welisynth Pharmaceuticals",
  "Welkin Biotek",
  "Welkin Boon Pharmaceuticals Co",
  "Welkind Pharma",
  "Wellbe Pharmaceuticals LLP",
  "Wellbecure Pharmaceuticals Pvt Ltd",
  "Wellbeing Pharmaceuticals Pvt Ltd",
  "Wellbird Pharma Pvt Ltd",
  "Wellcure Laboratories Pvt Ltd",
  "Wellcuring Lifesciences Pvt Ltd",
  "Wellencia Lifesciences",
  "Wellers Lifesciences Research Lab",
  "Wellesta Healthcare Pvt Ltd",
  "Wellford Pharmaceuticals Pvt Ltd",
  "Wellfort Healthcare LLP",
  "Wellgo Pharmaceuticals",
  "Wellhums Healthcare Pvt Ltd",
  "Wellicia Pharmaceuticals Pvt Ltd",
  "Wellix Healthcare",
  "Wellkind Life Science India Pvt Ltd",
  "Wellkind Pharma",
  "Wellmark Lifesciences Pvt Ltd",
  "Wellmed Pharma",
  "Wellna Organix",
  "Wellness Forever",
  "Wellness Health INC",
  "Wellness QED",
  "Wellness Remedies Pvt Ltd",
  "Wellness Spot Pvt Ltd",
  "WellOK Pharma",
  "Wellona Pharmaceutical Pvt Ltd",
  "Wells Biosciences",
  "Wellsa Pharmaceuticals Pvt Ltd",
  "Wellshark Pharmaceuticals Pvt Ltd",
  "Wellsyskure Pharmaceuticals",
  "Wellthy Life Marketing Pvt Ltd",
  "Welltrack Pharma",
  "Wellversed Health Pvt Ltd",
  "Wellvio Healthcare Pvt Ltd",
  "Wellwok Pharma",
  "WellWorth Life Sciences Pvt Ltd",
  "WellYou Biotech",
  "Welnex Bio",
  "Welspun India Ltd",
  "Welstar Lifescience Pvt Ltd",
  "Welt Biomed India Pvt Ltd",
  "Welwart pharmaceutical Pvt Ltd",
  "Wembrace Biopharma Pvt Ltd",
  "Wens Drugs India Pvt Ltd",
  "Werke Healthcare",
  "West-Coast Pharmaceutical Works Ltd",
  "Western Remedies",
  "Wet and Dry Personal Care Pvt Ltd",
  "Wevolve Consumer Pvt Ltd",
  "Wewell Biotech Pvt Ltd",
  "Wezen Chemicals Pvt Ltd",
  "Wheezal Homeo Pharma",
  "Whistler Pharmaceuticals Pvt Ltd",
  "White Clouds Labs",
  "White Lotus Health Care",
  "White Maple Pharmaceuticals Pvt Ltd",
  "White Mountain Collectives LLP",
  "White Square",
  "White&Trust Pharmaceuticals India Pvt Ltd",
  "Whitesky Pharma India Pvt Ltd",
  "Whiz Laboratories India Pvt Ltd",
  "Whole Earth Brands Pvt Ltd",
  "Wholeness Healthcare",
  "Wholesome Essentials",
  "Wholesome Habits Pvt Ltd",
  "Wholesome Medtech Pvt Ltd",
  "Wholsum Foods Pvt Ltd",
  "Whoviaan Healthcare Pvt Ltd",
  "Widcure Healthcare",
  "Wide Wings",
  "Widmed Pharmaceuticals",
  "Widmen Healthcare Pvt Ltd",
  "Wiesel Pharmaceuticals Pvt Ltd",
  "Wilburt Remedies Pvt Ltd",
  "Wild Child Enterprises Pvt Ltd",
  "Wiley Lifesciences Pvt Ltd",
  "Will Impex Pharmachem Pvt Ltd",
  "Willams Pharma Pvt Ltd",
  "Willcare Lifesciences",
  "Willingo Healthcare Ltd",
  "Willow Pharmaceuticals Pvt Ltd",
  "Willshine Healthcare Pvt Ltd",
  "Wilshire Pharmaceuticals Inc",
  "Wilshire Pharmaceuticals Pvt Ltd",
  "Wilymed Pharmaceuticals Pvt Ltd",
  "WIMWI Foods Pvt Ltd",
  "Win Health Pharma",
  "Win Medicare Ltd",
  "Win Medicare Pvt Ltd",
  "Win Pharmaceuticals (India) Pvt Ltd",
  "Win Trust Pharmaceuticals Ltd",
  "Winbaxy Laboratory",
  "Wincare Remedies",
  "Winch Biotec Pvt Ltd",
  "Windlas Biotech Ltd",
  "Wingreens Farms Pvt Ltd",
  "Wings Biotech Ltd",
  "Wings Pharma Pvt Ltd",
  "Winimed Biotech",
  "Wink & Nod",
  "Winmac Laboratories Ltd",
  "Winmark Healthcare Pvt Ltd",
  "Winmark Pharmaceuticals Pvt Ltd",
  "Winmed Lifesciences Pvt Ltd",
  "Win-Medicare Pvt Ltd",
  "Winnova Pharmaceuticals Healthcare",
  "Winself Healthcare Pvt Ltd",
  "Winsign Healthcare",
  "Winsmed Pharmaceuticals Pvt Ltd",
  "Winsome Bioceuticals",
  "Winsome Laboratories Pvt Ltd",
  "Winsome Medicare Pvt Ltd",
  "Winston Lifesciences Pvt Ltd",
  "Winston Pharmaceuticals Ltd",
  "Winsun Laboratories",
  "Winsun Life Sciences Pvt Ltd",
  "Winsys Lifescience",
  "Wintaas Pharma",
  "Wintech Pharmaceuticals",
  "Winx Medihaat Innovations Pvt Ltd",
  "Winystra Pharmaceuticals Pvt Ltd",
  "Wipro Enterprises (P) Ltd",
  "Wipro Enterprises Pvt Ltd",
  "Wiscon Pharmaceuticals Pvt Ltd",
  "Wisdom Exim Pvt Ltd",
  "Wisdom Pharma Pvt Ltd",
  "Wish Well Pharmaceuticals",
  "Wishbox Retail",
  "Wisro Bio-Care Pvt Ltd",
  "Wisteria Healthcare LLP",
  "WIT Pharmalink Pvt Ltd",
  "Within Nutrition",
  "Wits Biotech Pvt Ltd",
  "Wizad Biotech Pvt Ltd",
  "Woakes Pharma",
  "Wock Oliver Remedies",
  "Wockhardt Ltd",
  "Wockhardt Super Speciality Division",
  "Wohltat Lifesciences",
  "Wokindia Pharmaceuticals",
  "Woliston Healthcare",
  "Wolver biotech Pvt Ltd",
  "Wolwyn Exports P Ltd",
  "Women Planet Incorporation",
  "Women's India Personal Care Pvt Ltd",
  "Won Healthcare Pvt Ltd",
  "Wonder Care",
  "Wonder Drugs Pvt Ltd",
  "Wonder Healthcare",
  "Wonder Theraputics Pvt Ltd",
  "Wonderelement Enterprises",
  "Wonne International",
  "Wonset Healthcare Pvt Ltd",
  "Wood Cross Health Care",
  "Wood Rock Healthcare Pvt Ltd",
  "Woodavens Pharmacare Pvt Ltd",
  "WoodPecker's Coffee Trading House",
  "Woodrock Healthcare Pvt Ltd",
  "Worio Pharmaceutical Private",
  "Worio Pharmaceutical Pvt",
  "Woriox Pharma Pvt Ltd",
  "Workcell Solutions Pvt Ltd",
  "World Chem Corporation",
  "World Heal Pharmaceuticals Pvt Ltd",
  "World Healthcare Pharma",
  "World Wide Pharma",
  "Worth Medicines Pvt Ltd",
  "Worthmed Pharma",
  "WOVA Pharmaceuticals",
  "Woxter Pharma",
  "Wrenitt Lifesciences Pvt Ltd",
  "Wrest Healthcare Pvt Ltd",
  "Wrig Nanosystems Pvt Ltd",
  "Wudroz Pharma",
  "Wuerth India Pvt Ltd",
  "Wunderz Pharmaceuticals",
  "Wych Elm Laboratories Ltd",
  "Wyenne Davis Pharmaceuticals Pvt Ltd",
  "Wyeth Lederle Ltd",
  "Wyeth Pharmaceuticals Ltd",
  "Wynburg Pharmaceuticals Pvt Ltd",
  "Wynclark Pharmaceuticals Pvt Ltd",
  "Wysr Healthcare",
  "Wytro Mediez Pvt Ltd",
  "Wyzax Laboratories Pvt Ltd",
  "Xamax Ergonomics Pvt Ltd",
  "Xan Pharma Labs Pvt Ltd",
  "Xanocia Lifesciences",
  "Xavi Biotech Pvt Ltd",
  "Xcel Labs",
  "Xectus Healthcare",
  "Xellence Laboratories",
  "Xemex Life Sciences",
  "Xemex Pharmaceuticals Pvt Ltd",
  "Xena Coronus Health Care Pvt Ltd",
  "Xencare Lifesciences Pvt Ltd",
  "Xenial Pharma",
  "Xeno Pharmaceuticals Pvt Ltd",
  "Xenomics Pharmaceuticals Pvt Ltd",
  "Xenon Pharmaceuticals",
  "Xenotix Lifescience",
  "Xenovia Health Care Pvt Ltd",
  "Xesus Pharmaceuticals",
  "Xieon Life Sciences Pvt Ltd",
  "Ximing Labs Pvt Ltd",
  "Xion Pharmaceuticals Pvt Ltd",
  "Xlcea Pharmaceuticals Pvt Ltd",
  "Xlear Inc",
  "X-Med Medisciences",
  "Xovak Pharmtech",
  "XOXO Style",
  "Xperia Healthcare Pvt Ltd",
  "Xplore Lifestyle Solutions Pvt Ltd",
  "Xseed Pharma Pvt Ltd",
  "Xterra Nutrition",
  "Xtra Plus Pharmaceuticals",
  "Xtrek Life-science Pvt Ltd",
  "Xyata Life Sciences Pvt Ltd",
  "Xyiris Pharmaceuticals Pvt Ltd",
  "Xylex Healthcare Pvt Ltd",
  "Xylo Lifesciences",
  "Xylon Pharmaceuticals",
  "Xylose Pharma",
  "Xymera Bioscience Pvt Ltd",
  "Xyris Life Sciences Pvt Ltd",
  "Xyster Healthcare Pvt Ltd",
  "Y R G Enterprise",
  "Y T Enterprises (Gurukul Kangri Pharmacy)",
  "Yacca Pharmaceuticals Pvt Ltd",
  "Yaher Pharma",
  "Yahova Biotechnologies Pvt Ltd",
  "Yakun Marketing Pvt Ltd",
  "Yami Pharma",
  "Yanolite Pharma Pvt Ltd",
  "Yap Bioceuticals",
  "Yap Cosderm Laboratories Pvt Ltd",
  "Yaps N Belly Food Products Pvt Ltd",
  "Yash Care Lifesciences",
  "Yash Laboratories",
  "Yash Pharma Laboratories Pvt Ltd",
  "Yash Remedies",
  "Yashna Anand Green Energy Pvt Ltd",
  "Yashram Lifestyle Brands Pvt Ltd",
  "Yashtraa Healthcare",
  "Yathansh Biopharmaceuticals",
  "Yaxon BioCare Pvt Ltd",
  "Yaxon Vedacare",
  "Yaza Lifestyle",
  "Yazh Pharma",
  "Yazidi Pharma",
  "YBM Healthcare India Pvt Ltd",
  "Yeluri Formulations Pvt Ltd",
  "Yenergie Health Care Pvt Ltd",
  "Yenisowl Pharmaceuticals",
  "Yeoman Drugs",
  "Yes Pharma",
  "Yester Pharma Pvt Ltd",
  "Yew Biotech",
  "Yew Lifesciences",
  "Ygeiax Sciences Pvt Ltd",
  "YNB Your's Natural Buddy",
  "Yodley Life Sciences Pvt Ltd",
  "Yogi Ayurvedic Products Pvt Ltd",
  "Yogi Kripa Medi Chem Pvt Ltd",
  "Yogiraj Pharmaceuticals",
  "Young ly",
  "Yours Medicare Pvt Ltd",
  "Yovnam Pvt Ltd",
  "Ypsomed India",
  "Ytiliga Pvt Ltd",
  "Yts Pharmaceuticals",
  "Yu Medtron Healthcare Pvt Ltd",
  "Yug Pharmaceuticals",
  "Yugan International",
  "Yugen Pharmaceuticals",
  "Yukti Herbs",
  "Yumi Global",
  "Yummsy Food",
  "Yurekha Laboratories",
  "Yutika Natural Pvt Ltd",
  "Yuvan Global Corporation India Pvt Ltd",
  "Yuventis Pharmaceuticals",
  "Yuzderm Pharmaceuticals Pvt Ltd",
  "Z Plus Remedies",
  "Za1 Pharmaceuticals Pvt Ltd",
  "Zadine Rumbs Ltd",
  "Zainarco Labs Pvt Ltd",
  "Zainul Pharmaceutical Pvt Ltd",
  "Zaiva Lifesciences",
  "Zalman Life Sciences",
  "Zam Zam Halal Products Pvt Ltd",
  "Zandu Pharmaceutical Works Ltd",
  "Zaneka Healthcare Pvt Ltd",
  "Zanetaz Medicorp",
  "Zanjoe Formulation Pvt Ltd",
  "Zap Healthcare Pvt Ltd",
  "Zaq Healthcare",
  "Zareenova Pharma",
  "Zareta Biotec Pvt Ltd",
  "Zargan Healthcare",
  "Zaria Pharmaceuticals Pvt Ltd",
  "Zarp Ventures (OPC) Pvt Ltd",
  "Zatine Cosmocon Pharma Pvt Ltd",
  "Zatropha Pharma",
  "Zaurac Healthcare",
  "Zavik Drugs",
  "Zavion Therapeutics",
  "Zaysan Remedies",
  "ZDL(Zodiacal) Pharmaceutics Pvt Ltd",
  "Zeal Biotech Pvt td",
  "Zeal Cardios",
  "Zeal Laboratories Pvt Ltd",
  "Zealina Life Sciences",
  "Zealous Pharmaceuticals Pvt Ltd",
  "Zeaxa Healthcare Pvt Ltd",
  "Zebwell Healthcare Pvt Ltd",
  "Zechstein Life Sciences",
  "Zecon Pharmaceuticals Pvt Ltd",
  "Zed Pharmaceuticals",
  "Zedawn Formulations Pvt Ltd",
  "Zedawn Life Sciences Pvt Ltd",
  "Zedchem Pharma",
  "Zedip Formulations",
  "Zedley Health Care",
  "Zedlon Pharmaceuticals",
  "Zedna Pharmaceuticals",
  "Zedon Biotech Pvt Ltd",
  "Zedon Pharmaceuticals Pvt Ltd",
  "ZedRock Pharma",
  "Zedwell Pvt Ltd",
  "Zee Cure Pharmaceuticals Pvt Ltd",
  "Zee Laboratories",
  "Zee Laboratories Ltd",
  "Zee Lifecare Pvt Ltd",
  "Zeeford Life Sciences",
  "Zeekem Laboratories",
  "Zeelab Pharmacy Pvt Ltd",
  "Zeemat Pharmaceuticals Pvt Ltd",
  "Zegna Biotech",
  "Zelaino Pharmaceuticals Pvt Ltd",
  "Zelleven Healthcare",
  "Zelleven Healthcare Pvt Ltd",
  "Zelleven Pharma Pvt Ltd",
  "Zelnic Biotech Pvt Ltd",
  "Zemaica Healthcare",
  "Zemilon Biotech",
  "Zen Biotech Pvt Ltd",
  "Zen Labs India",
  "Zen Medica Pvt Ltd",
  "Zen Pharma Pvt Ltd",
  "Zenacia Life Sciences",
  "Zenacts Pharma",
  "Zenara Pharma",
  "Zenberg Pharmaceutical Pvt Ltd",
  "Zencorp Pharmaceutical Pvt Ltd",
  "Zencure Organics",
  "Zencure Sciences Pvt Ltd",
  "Zencus Pharma",
  "Zenell Sciences Pvt Ltd",
  "Zeneris Pharma Pvt Ltd",
  "Zenex International",
  "Zenexa Healthcare",
  "Zenfo Med Remedies Pvt Ltd",
  "Zenicure Labs",
  "Zenith Chemical Works Pvt Ltd",
  "Zenith Healthcare Ltd",
  "Zenith Resources & Marketing Enterprises",
  "Zenitiva Pharmaceuticals India Pvt Ltd",
  "Zenivia Lifesciences",
  "Zenix Remedies",
  "Zenixa Healthcare Pvt Ltd",
  "Zenizen Pharmaceuticals Pvt Ltd",
  "Zenkins Pharmaceutical Pvt Ltd",
  "Zenlabs Ethica Ltd",
  "Zenn Biotech",
  "Zennar Life Sciences",
  "Zenni Pharmaceutical Pvt Ltd",
  "Zeno Healthcare",
  "Zeno Lifecare",
  "Zenobic Lifesciences",
  "Zenobio Life Science",
  "Zenolia Life Science Pvt Ltd",
  "Zenomed Healthcare Pvt Ltd",
  "Zenon Healthcare Ltd",
  "Zenonz International",
  "Zenotis Healthcare",
  "Zenova Therapeutics Pvt Ltd",
  "Zenox Pharmaceuticals Pvt Ltd",
  "Zens Healthcare Pvt Ltd",
  "Zensar Health Care",
  "Zenska Life Sciences Pvt Ltd",
  "Zenstar Life Sciences",
  "Zentech Pharma",
  "Zentis Drugs Pvt Ltd",
  "Zentiva Healthcare Pvt Ltd",
  "Zentiva Pharmaceutical",
  "Zenvita Healthcare Pvt Ltd",
  "Zenvito Helth Care Pvt Ltd",
  "Zenvo Biotech LLP",
  "Zeochem Pharmaceuticals Pvt Ltd",
  "Zeolix Corporation",
  "Zeon Biotech",
  "Zeon LifeSciences Ltd",
  "Zeon Lifesciences Pvt Ltd",
  "Zeotec Life Science Pvt Ltd",
  "Zeotic Healthcare (OPC) Pvt Ltd",
  "Zeoveda Herbals",
  "Zephla Healthcare",
  "Zephon Life Sciences",
  "Zephyr Medicare Pvt Ltd",
  "Zepsilon Healthcare Pvt Ltd",
  "Zerdia Healthcare Pvt Ltd",
  "Zerg Pharma Pvt Ltd",
  "Zerich Pharma Pvt Ltd",
  "Zerico Lifesciences Pvt Ltd",
  "Zering Smith Lifesciences",
  "Zero Enthalpy Labs Pvt Ltd",
  "Zesstek Healthcare",
  "Zest Pharma",
  "Zestian Life Science",
  "Zestica Pharma",
  "Zestwin Lifesciences",
  "Zeta Life Sciences Pvt Ltd",
  "Zetaca Lifescience Pvt Ltd",
  "Zetek Pharmaceuticals Pvt Ltd",
  "Zetre Pharmaceutical India Pvt Ltd",
  "Zetty Pharmaceuticals",
  "Zeus Drugs Ltd",
  "Zeus Pharma",
  "Zeuson Medicines Pvt Ltd",
  "Zevion Healthcare Pvt Ltd",
  "Zexen Pharmaceuticals",
  "Zeytin Pharma Pvt Ltd",
  "Zeyyer Pharmaceuticals Pvt Ltd",
  "Zhen Heal Craft Pvt Ltd",
  "Zhenia Pharmaceuticals",
  "Zhenpi Life Sciences Pvt Ltd",
  "Zicad Life Care",
  "Zidwell Healthcare",
  "Ziel Pharmaceuticals",
  "Zifam Pinnacle Healthcare Pvt Ltd",
  "Zikia BioMeds and Pharmaceuticals Pvt Ltd",
  "Zim Laboratories Ltd",
  "Zingcare Biotech Pvt Ltd",
  "Zinnia Life Sciences",
  "Zinnia Wellness",
  "Zion Healthcare",
  "Zion Pharmaceuticals Pvt Ltd",
  "Ziotic Life Sciences",
  "Zirco Impex",
  "Zircon Pharmaceuticals Pvt Ltd",
  "Zis Labs Pharmaceuticals",
  "Zither Labs",
  "Zither Pharmaceutical Pvt Ltd",
  "Zivago Pharma Pvt Ltd",
  "Zivira Labs Pvt Ltd",
  "Ziyana Lifesciences Pvt Ltd",
  "Ziyon Healthcare",
  "Ziza Organics Pvt Ltd",
  "Zodak Healthcare",
  "Zodley Pharmaceuticals Pvt Ltd",
  "Zoecia Healthcare",
  "Zoemed Pharma",
  "Zoetic Ayurvedic Pvt Ltd",
  "Zoetic Formulations Pvt Ltd",
  "Zogenix Healthcare",
  "Zohra Products",
  "Zoic Lifesciences",
  "Zolife Healthcare Pvt Ltd",
  "Zomask Healthcare Pvt Ltd",
  "Zoobion Pharmaceuticals Pvt Ltd",
  "Zoom Healthcare",
  "Zoom Laboratories Pvt Ltd",
  "Zootax Biotec Inc ",
  "Zorent Pharma",
  "Zorex Pharma Pvt Ltd",
  "Zorion Drugs & Herbs Pvt Ltd",
  "Zorris Lifesciences",
  "Zota Health care Ltd",
  "Zota Pharmaceuticals Pvt Ltd",
  "Zoticus Pharmaceuticals",
  "Zovaitalia Healthcare Pvt Ltd",
  "Zovilon Healthcare Pvt Ltd",
  "Zoxen Pharmaceuticals",
  "Zoxvina Biotech Pvt Ltd",
  "Zoydec Pharmaceuticals",
  "ZRS Labs",
  "Zubectus Lifesciences",
  "Zubex Pharmaceuticals",
  "Zubit Lifecare",
  "Zuciya Lifesciences",
  "Zuiver Pharmaceuticals",
  "Zulesma LLP",
  "Zumanix Remedies Pvt Ltd",
  "Zumax Biocare",
  "Zumentes Healthcare",
  "Zunarsh Lifesciences Pvt Ltd",
  "Zunison Healthcare",
  "Zuno Pharmaceuticals",
  "Zurain Enterprises Pvt Ltd,",
  "Zurica International Pvt Ltd",
  "Zurinovo Healthcare Pvt Ltd",
  "Zuventus Health Care Ltd",
  "Zuvista Pharmaceucitcal",
  "Zuvius Life Sciences",
  "Zuxtar Medica Pvt Ltd",
  "ZVN Healthcare",
  "Zybax Pharmaceuticals",
  "Zycris Healthcare",
  "Zycuslife Healthcare Pvt Ltd",
  "Zydeka Pharma",
  "Zydica Healthcare",
  "Zydillac Biotech",
  "Zydillac Dermaceuticals",
  "Zydus Alidac",
  "Zydus Cadila",
  "Zydus Health Care",
  "Zydus Lifesciences Ltd",
  "Zydus Medica H Care Ltd(W/H)",
  "Zydus Neurosciences",
  "Zydus Pharma Ltd",
  "Zydus Recon Healthcare Ltd",
  "Zydus Wellness Product Ltd",
  "Zygal Pharmaceuticals Pvt Ltd",
  "Zylig Lifesciences",
  "Zylone Healthcare",
  "Zymes Bioscience Pvt Ltd",
  "Zynext Pharmaceuticals Pvt Ltd",
  "Zynor Pharmaceutical Pvt Ltd",
  "Zynoremedies Life Sciences",
  "Zynova Remedies",
  "Zynovia Lifecare",
  "Zyphar's Pharmaceuticals Pvt Ltd",
  "Zyris Derma Care (P) Ltd",
  "Zyris Derma Care P Ltd",
  "Zystus Nutraceuticals",
  "Zytek Pharmaceuticals",
  "Zytras Life Sciences",
  "Zyvieon Healthcare Pvt Ltd",
  "Zywie Pharmaceuticals Pvt Ltd",
  "Zywie Ventures Pvt Ltd",
];
