import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
} from "../../utils/common";

const LogisticsSingleView = ({ label, value }: any) => {
  return (
    <>
      {value ? (
        <Stack direction="row" mt={1}>
          <Typography variant="h6">{label}</Typography> &nbsp;:&emsp;
          <Typography> {value}</Typography>
        </Stack>
      ) : null}
    </>
  );
};

export const LogisticDetailView = ({ orderLogisticsData }: any) => {
  function getDropOtp() {
    let dropOtp = "";
    dropOtp = orderLogisticsData?.drop_otp;
    if (!dropOtp) {
      const dropLocationOrder: any =
        orderLogisticsData?.locations_order?.filter(
          (x: any) => x.type === "drop"
        )[0];
      dropOtp = dropLocationOrder?.otp ? dropLocationOrder.otp : null;
    }
    return dropOtp;
  }

  return (
    <Box mt={2}>
      {orderLogisticsData?.return_reason ? (
        <Alert sx={{ mb: 2 }} severity="info">
          This order is returned back to pharmacy due to:{" "}
          {orderLogisticsData.return_reason}
        </Alert>
      ) : null}
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <LogisticsSingleView
            label="Status"
            value={orderLogisticsData.status}
          />
          <LogisticsSingleView
            label="Status Updated At"
            value={parseAndFormateDate(orderLogisticsData.status_updated_at)}
          />
          <LogisticsSingleView
            label="Issue Raised"
            value={orderLogisticsData.issue_raised}
          />
          <LogisticsSingleView
            label="Cancelled By"
            value={orderLogisticsData.cancelled_by}
          />
          <LogisticsSingleView
            label="Cancelled At"
            value={parseAndFormateDate(orderLogisticsData.cancelled_at)}
          />
          <LogisticsSingleView
            label="Cancellation Reason"
            value={orderLogisticsData.cancelled_reason}
          />
          {orderLogisticsData?.tracking_url ? (
            <Stack direction="row" mt={1}>
              <Typography variant="h6">Tracking URL</Typography> &nbsp;:&emsp;
              <Link href={orderLogisticsData.tracking_url} target="_blank">
                {orderLogisticsData.tracking_url}
              </Link>
            </Stack>
          ) : (
            <Typography mt={1} variant="body2" sx={{ opacity: 0.8 }}>
              Tracking URL will be available after pickup complete
            </Typography>
          )}
          <LogisticsSingleView
            label="Price"
            value={
              orderLogisticsData.price ? "Rs. " + orderLogisticsData.price : ""
            }
          />
          <Box mt={1}>
            <Divider />
          </Box>
          <LogisticsSingleView
            label="Distance"
            value={
              orderLogisticsData.distance
                ? orderLogisticsData.distance + " km"
                : ""
            }
          />
          <LogisticsSingleView
            label="Total Time"
            value={
              orderLogisticsData.total_time
                ? orderLogisticsData.total_time + " min"
                : ""
            }
          />

          <LogisticsSingleView
            label="Drop Off"
            value={
              orderLogisticsData.eta?.dropoff
                ? orderLogisticsData.eta.dropoff + " min"
                : ""
            }
          />

          <Box mt={1}>
            <Divider />
          </Box>
          <LogisticsSingleView
            label="Estimated Price"
            value={
              orderLogisticsData.estimated_price
                ? "Rs. " + orderLogisticsData.estimated_price
                : ""
            }
          />
          <LogisticsSingleView
            label="Task ID"
            value={orderLogisticsData.task_id}
          />
          <LogisticsSingleView
            label="Created At"
            value={parseAndFormateDate(orderLogisticsData.created_at)}
          />
          <LogisticsSingleView
            label="Created By"
            value={orderLogisticsData.created_by}
          />
        </Box>
        <Stack>
          {orderLogisticsData?.runner ? (
            <>
              {Object.keys(orderLogisticsData.runner)?.length > 0 ? (
                <Typography variant="h6" mb={2}>
                  Runner Info
                </Typography>
              ) : null}
              {orderLogisticsData.runner.profile_pic_url ? (
                <img
                  src={orderLogisticsData.runner.profile_pic_url}
                  alt="runner"
                  style={{ width: "120px" }}
                />
              ) : null}
              {orderLogisticsData.runner.name ? (
                <Typography variant="body1">
                  Name: {orderLogisticsData.runner.name}
                </Typography>
              ) : null}
              {orderLogisticsData.runner.phone_number ? (
                <Typography variant="body2">
                  Phone: {orderLogisticsData.runner.phone_number}
                </Typography>
              ) : null}
            </>
          ) : null}
          {orderLogisticsData?.cod && Number(orderLogisticsData.cod) > 0 ? (
            <Chip
              label={`COD: Rs. ${orderLogisticsData.cod}`}
              sx={{ backgroundColor: "#c6f6d5", mt: 2 }}
            />
          ) : null}
          {getDropOtp() ? (
            <Chip
              label={`OTP: ${getDropOtp()}`}
              sx={{ backgroundColor: "#0058ff", color: "#fff", mt: 2 }}
            />
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );
};

const LogisticsDetailModal = ({
  activePartner,
  activePartnerOrderId,
  showLogisticsDetailModal,
  setShowLogisticsDetailModal,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [orderLogisticsData, setOrderLogisticsData] = useState<any>({});
  const privateInstance = useAxiosInstance();

  const getOrderByPartnerOrderId = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderLogisticsData(result.data.response.logistics || null);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderByPartnerOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={showLogisticsDetailModal}
      onClose={() => setShowLogisticsDetailModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowLogisticsDetailModal(false)}
      >
        Logistics - {activePartner} | {activePartnerOrderId}
      </CustomDialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <LogisticDetailView orderLogisticsData={orderLogisticsData} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LogisticsDetailModal;
