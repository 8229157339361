import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError, successToast } from "../../utils/common";
import PhoneIcon from "@mui/icons-material/Phone";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { useIconStyles } from "../Helpers/iconStyles";

interface IPropsKnowlarityCall {
  order: {
    order_id: string;
    mobile: string | number;
  };
  showNumber: boolean;
  renderSmallIcon?: boolean;
  service?: string;
}

const KnowlarityCall = ({
  order,
  showNumber,
  renderSmallIcon = false,
  service = "order-dashboard",
}: IPropsKnowlarityCall) => {
  const [callLoading, setCallLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const iconClasses = useIconStyles();

  const handleKnowlarityCall = async () => {
    let payload = {
      phone_number: order.mobile,
      agent_number: user.phone_number,
      created_by: user?.user_id,
      service: service,
      target_id: order.order_id,
      recipient_id: order.mobile,
    };

    setCallLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/notifications/call`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Call initiated successfully");
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setCallLoading(false);
    }
  };

  return (
    <div>
      {user?.partner?.toLowerCase() === "medpay" &&
        order?.mobile &&
        order?.mobile !== "NA" && (
          <>
            {callLoading ? (
              <CircularProgress color="primary" size={16} />
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ mx: renderSmallIcon ? 0 : 0.5 }}
              >
                <Tooltip
                  title="Click to initiate call"
                  aria-label="Call customer"
                >
                  <IconButton
                    sx={{ p: renderSmallIcon ? "2px" : "" }}
                    color="primary"
                    onClick={handleKnowlarityCall}
                  >
                    <PhoneIcon
                      className={
                        renderSmallIcon
                          ? iconClasses.borderedIconSmall
                          : iconClasses.borderedIcon
                      }
                    />
                  </IconButton>
                </Tooltip>
                {showNumber && (
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 0.5,
                      fontWeight: service === "merchant" ? "bold" : "normal",
                    }}
                  >
                    {order?.mobile}
                  </Typography>
                )}
              </Stack>
            )}
          </>
        )}
    </div>
  );
};

export default KnowlarityCall;
