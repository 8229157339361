import { CircularProgress, Tooltip, Typography } from "@mui/material";
import MUIDataTable, { FilterType, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { getMuiTheme, handleError } from "../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import EditAgentDataModal from "./editAgentDataModal";
import { commonTableOptions } from "../../constants";
import { useIconStyles } from "../../components/Helpers/iconStyles";
import { ThemeProvider } from "@mui/styles";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";

const AgentRoaster = () => {
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const [agentsData, setAgentsData] = useState([]);
  const [agentToEditIndex, setAgentToEditIndex] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [editAgentModal, setEditAgentModal] = useState(false);
  const iconClasses = useIconStyles();

  const getAgentsData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/agents?user_id=${user?.user_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setAgentsData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAgentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "agent_id",
      label: "AGENT ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "team",
      label: "TEAM",
      options: {
        searchable: false,
        filter: true,
        filterType: "dropdown" as FilterType,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Typography variant="body2" style={{ textTransform: "capitalize" }}>
              {value.join(", ")}
            </Typography>
          );
        },
      },
    },
    {
      name: "manager_id",
      label: "MANAGER ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "languages",
      label: "LANGUAGES",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Typography variant="body2" style={{ textTransform: "capitalize" }}>
              {value.join(", ")}
            </Typography>
          );
        },
      },
    },
    {
      name: "available",
      label: "AVAILABLE",
      options: {
        searchable: false,
        filter: true,
        filterType: "dropdown" as FilterType,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Typography variant="body2" style={{ textTransform: "uppercase" }}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "total_assigned",
      label: "ASSIGNED",
      options: {
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "total_inprocess",
      label: "PROCESSING",
      options: {
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "total_partner_awaiting",
      label: "PARTNER AWAITING",
      options: {
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "total_fulfilled",
      label: "FULFILLED",
      options: {
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "total_cancelled",
      label: "CANCELLED",
      options: {
        searchable: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Tooltip title="Edit">
              <EditIcon
                className={iconClasses.borderedIcon}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setAgentToEditIndex(tableMeta.rowIndex);
                  setEditAgentModal(true);
                }}
              />
            </Tooltip>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...(commonTableOptions as any),
    download: true,
    downloadOptions: {
      filename: "Agents_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    setRowProps: (row: any[], dataIndex: number, rowIndex: number) => {
      if (row[4]?.props?.children === "n") {
        return {
          style: { background: "#fed7d7" },
        };
      }
      return {};
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    searchPlaceholder: "Agent ID/Manager ID",
  };

  return (
    <ErrorBoundary>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title=""
          data={agentsData}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
      {editAgentModal && (
        <EditAgentDataModal
          editAgentModal={editAgentModal}
          setEditAgentModal={setEditAgentModal}
          agentData={agentsData[agentToEditIndex]}
          getAgentsData={getAgentsData}
        />
      )}
    </ErrorBoundary>
  );
};

export default AgentRoaster;
