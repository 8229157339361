import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError, successToast } from "../../utils/common";

const LogisticCancelModal = ({
  showLogisticCancelModal,
  setShowLogisticCancelModal,
  activeOrderId,
  activeDeliveryPartner,
  updateFn,
}: any) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleCancelLogistics = async () => {
    if (window.confirm("Are you sure?")) {
      let payload = {
        order_id: activeOrderId,
        delivery_partner: activeDeliveryPartner,
        cancellation_reason: reason,
      };

      setLoading(true);

      try {
        const result = await privateInstance.post(
          `${API_VERSION.V2}/logistics/cancel`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast(result.data.message);
          setShowLogisticCancelModal(false);
          updateFn();
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={showLogisticCancelModal}
      onClose={() => setShowLogisticCancelModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowLogisticCancelModal(false)}
      >
        {"Logistics - Cancel"}
      </CustomDialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <FormControl fullWidth sx={{ mt: 1.5 }}>
          <TextField
            id="outlined-textarea"
            label="Reason"
            placeholder="not interested!"
            multiline
            rows={4}
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            InputLabelProps={{
              required: true,
            }}
          />
        </FormControl>
        <Box textAlign="center" mt={3}>
          <LoadingButton
            color="error"
            variant="contained"
            loading={loading}
            disabled={!reason}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={() => handleCancelLogistics()}
          >
            Cancel
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LogisticCancelModal;
