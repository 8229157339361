import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import { ORDER_STATUS } from "../../../../enums/order.enum";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { Form, Formik } from "formik";
import { phlebotomistSchema } from "../../../../schemas/diagnostic";
import { LoadingButton } from "@mui/lab";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  trimIfOnlySpaces,
  validateIntNum,
} from "../../../../utils/common";
import { valueLimits } from "../../../../constants";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { useDispatch, useSelector } from "react-redux";

const PhlebotomistModal = ({
  order_status,
  order_id,
  delivery_person_mobile,
  delivery_person_name,
}: any) => {
  const [showPhleboModal, setShowPhleboModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      order_id: order_id,
      type: "delivery-details-update",
      delivery_details: {
        delivery_person_name: values.name,
        delivery_person_mobile: values.mobile,
      },
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Phloebo details updated successfully.");
        setShowPhleboModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Add phlebotomist number">
        <span>
          <IconButton
            color="primary"
            onClick={() => setShowPhleboModal(true)}
            disabled={
              order_status === ORDER_STATUS.SAMPLE_COLLECTED ||
              order_status === ORDER_STATUS.REPORTS_GENERATED
            }
          >
            <BloodtypeIcon />
          </IconButton>
        </span>
      </Tooltip>
      {showPhleboModal && (
        <Dialog
          open={showPhleboModal}
          onClose={() => setShowPhleboModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setShowPhleboModal(false)}
          >
            Phloebe Details
          </CustomDialogTitle>
          <DialogContent>
            <Formik
              initialValues={{
                name: delivery_person_name ? delivery_person_name : "",
                mobile: delivery_person_mobile ? delivery_person_mobile : "",
              }}
              onSubmit={(values: any, actions: any) => {
                setLoading(true);
                actions.setSubmitting(false);
                handleSubmit(values, actions);
              }}
              validationSchema={phlebotomistSchema}
            >
              {(props: any) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  isSubmitting,
                  setFieldValue,
                } = props;
                return (
                  <Form>
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        id="name"
                        label="Phloebe Name"
                        size="small"
                        value={values?.name || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onChange={(e: any) =>
                          setFieldValue(
                            "name",
                            trimIfOnlySpaces(e.target.value)
                          )
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.name && touched.name ? errors.name : ""
                        }
                        error={errors.name && touched.name ? true : false}
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        id="mobile"
                        label="Phloebe Mobile"
                        size="small"
                        value={values?.mobile || ""}
                        onChange={(e) =>
                          setFieldValue(
                            "mobile",
                            validateIntNum(e.target.value, valueLimits.MOBILE)
                          )
                        }
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onBlur={handleBlur}
                        helperText={
                          errors.mobile && touched.mobile ? errors.mobile : ""
                        }
                        error={errors.mobile && touched.mobile ? true : false}
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputLabelProps={{
                          required: true,
                        }}
                      />
                    </Stack>
                    <Box sx={{ textAlign: "center", mt: 3 }}>
                      <LoadingButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={loading}
                        disabled={isSubmitting}
                        loadingIndicator={
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        }
                      >
                        Submit
                      </LoadingButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PhlebotomistModal;
