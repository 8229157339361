import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import {
  customReplaceAll,
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
  handleError,
  textCapitalize,
} from "../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import SideStackedBarGraph from "../../Metrics/Charts/SideStackedBarGraph";

const ApiWiseMetrics = ({
  selectedMonth,
  selectedPartner,
  graphTitle,
  legendText,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [apisList, setApisList] = useState<string[]>([]);
  const [selectedApi, setSelectedApi] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const getMetricsData = async () => {
    let payload = {
      month: Number(selectedMonth),
      year: 2024,
      metric_type: "api_wise_metrics",
      order_type: "medicines",
      partner_id: selectedPartner,
    };
    setLoading(true);
    try {
      const result: any = await instance.post(
        `${config.URL}${API_VERSION.V2}/analytics/partner-apis`,
        payload
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setMetricData(result.data.response["api_wise_metrics"]);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedPartner]);

  useEffect(() => {
    if (metricData) {
      generateDataset(
        metricData.filter((x: any) => x.api_label === selectedApi)
      );
      //set filter options
      let opt: string[] = [];
      metricData.forEach((x: any) => opt.push(x.api_label));
      setApisList([...(new Set(opt) as any)]);
      setSelectedApi(opt[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  useEffect(() => {
    if (selectedApi && metricData) {
      generateDataset(
        metricData.filter((x: any) => x.api_label === selectedApi)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApi]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(
      new Date(new Date().getFullYear(), Number(selectedMonth) - 1, 1),
      new Date(new Date().getFullYear(), Number(selectedMonth), 0)
    );

    const totalCount: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(rawData, "date_label", el);

      let count: any = getCountByKey(filteredDataByLabel, "count");
      totalCount.push(count);
    });

    const datasets = [
      {
        label: legendText,
        data: totalCount,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box
      width="100%"
      sx={{
        padding: "20px",
        marginTop: "20px",
      }}
    >
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <>
          <Box textAlign="right">
            <Autocomplete
              disableClearable
              ListboxProps={{ style: { maxHeight: "14rem" } }}
              fullWidth
              size="small"
              sx={{ mb: 1.5 }}
              value={selectedApi}
              onChange={(e, val: any) => setSelectedApi(val)}
              id="select_api"
              options={apisList}
              renderInput={(params) => (
                <TextField name="select_api" label="Select API" {...params} />
              )}
              getOptionLabel={(option: any) =>
                String(textCapitalize(customReplaceAll(option, "-", " ")))
              }
            />
          </Box>
          <Box
            sx={{
              boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
              padding: "10px",
            }}
          >
            <Box textAlign="right">
              <Tooltip title={tooltipText}>
                <InfoIcon color="primary" fontSize="small" />
              </Tooltip>
            </Box>
            {genData && (
              <SideStackedBarGraph
                title={graphTitle}
                data={genData}
                dataLabelPos="end"
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ApiWiseMetrics;
