import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { allSidebarItems } from "../../constants";
import { sortBy } from "lodash";
import useUserPermissions from "../../hooks/useUserPermissions";

const useStyles = makeStyles({
  isActive: {
    color: "#fff",
    backgroundColor: "#0058ff",
  },
  darkColorMode: {
    color: "#fff",
    backgroundColor: "#121212",
  },
  isInactive: {
    color: "#333",
    backgroundColor: "#fff",
  },
  listIcon: {
    color: "inherit",
  },
  navLink: {
    display: "inherit",
    textDecoration: "none",
  },
});

const trimParams = (val: string) => {
  if (!val) return "";

  if (val.includes("?")) {
    return val.split("?")[0];
  }
  return val;
};

interface MenuLinkProps {
  redirectTo: string;
  Icon: React.ComponentType<SvgIconProps>;
  itemText: string;
  menus: Array<Object>;
  itemIndex: number;
}

const SidebarMenu = ({ open, handleMenuClick, openIndex }: any) => {
  const userPermissions = useUserPermissions();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const [menuItems, setMenuItems] = useState<any>([]);

  const NavItems: React.FC<MenuLinkProps> = ({
    redirectTo,
    Icon,
    itemText,
    menus,
    itemIndex,
  }) => {
    return (
      <>
        <Tooltip title={open ? "" : itemText} arrow placement="right">
          <ListItemButton
            key={itemIndex}
            sx={{
              height: 50,
              justifyContent: open ? "initial" : "center",
              px: open ? 2.5 : 0,
              //highlight active parent navitem and adjust padding left
              borderLeft: location.pathname
                ?.toLowerCase()
                .includes(itemText?.split(" ")?.join("-")?.toLowerCase())
                ? "6px solid #0058ff"
                : "6px solid transparent",
            }}
            onClick={handleMenuClick(itemIndex)}
          >
            <ListItem>
              <ListItemIcon
                className={classes.listIcon}
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Icon
                  style={{
                    color:
                      redirectTo &&
                      (location.pathname === trimParams(redirectTo) ||
                        location.pathname.includes(
                          String(trimParams(redirectTo))
                        ))
                        ? "#fff"
                        : theme.palette.mode === "dark"
                        ? "#fff"
                        : "#333",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={itemText} sx={{ opacity: open ? 1 : 0 }} />
              {open &&
                menus?.length > 0 &&
                (openIndex === itemIndex ? (
                  <ExpandLess sx={{ marginRight: -3 }} />
                ) : (
                  <ExpandMore sx={{ marginRight: -3 }} />
                ))}
            </ListItem>
          </ListItemButton>
        </Tooltip>
        <Collapse in={openIndex === itemIndex} timeout="auto" unmountOnExit>
          <>
            <List component="div" disablePadding>
              {menus?.map((item: any, i) => (
                <NavLink
                  key={i}
                  to={item.to}
                  className={
                    location.pathname === trimParams(item.to) ||
                    location.pathname.includes(String(trimParams(item.to)))
                      ? classes.isActive
                      : theme.palette.mode === "dark"
                      ? classes.darkColorMode
                      : classes.isInactive
                  }
                  style={{ display: "inherit", textDecoration: "none" }}
                >
                  <Tooltip
                    title={open ? "" : itemText + " " + item.name}
                    arrow
                    placement="right"
                  >
                    <ListItemButton
                      key={i}
                      sx={{
                        height: 40,
                        justifyContent: open ? "initial" : "center",
                        px: open ? 3.5 : 0.25,
                        borderLeft: "6px solid transparent",
                      }}
                    >
                      <ListItem>
                        <ListItemIcon>
                          <item.icon
                            style={{
                              marginTop: "6px",
                              fontSize: 18,
                              color:
                                location.pathname === trimParams(item.to) ||
                                location.pathname.includes(
                                  String(trimParams(item.to))
                                )
                                  ? "#fff"
                                  : theme.palette.mode === "dark"
                                  ? "#fff"
                                  : "#333",
                            }}
                          />
                          <ListItemText
                            sx={
                              open
                                ? { opacity: 1, paddingLeft: 3 }
                                : { opacity: 0, paddingLeft: 0 }
                            }
                          >
                            <Typography
                              sx={{
                                color:
                                  location.pathname === trimParams(item.to) ||
                                  location.pathname.includes(
                                    String(trimParams(item.to))
                                  )
                                    ? "#fff"
                                    : theme.palette.mode === "dark"
                                    ? "#fff"
                                    : "#333",
                                fontSize: 14,
                              }}
                            >
                              {item.name?.includes("-")
                                ? item.name?.split("-").join(" ")
                                : item.name}
                            </Typography>
                          </ListItemText>
                        </ListItemIcon>
                      </ListItem>
                    </ListItemButton>
                  </Tooltip>
                </NavLink>
              ))}
            </List>
          </>
        </Collapse>
      </>
    );
  };

  const GenerateMenuLink: React.FC<MenuLinkProps> = ({
    redirectTo,
    Icon,
    itemText,
    menus,
    itemIndex,
  }) => {
    return menus?.length > 0 ? (
      <Box
        key={itemIndex}
        className={
          redirectTo &&
          (location.pathname === trimParams(redirectTo) ||
            location.pathname.includes(String(trimParams(redirectTo))))
            ? classes.isActive
            : theme.palette.mode === "dark"
            ? classes.darkColorMode
            : classes.isInactive
        }
        style={{ display: "inherit", textDecoration: "none" }}
      >
        <NavItems
          redirectTo={redirectTo}
          Icon={Icon}
          itemText={itemText}
          menus={menus}
          itemIndex={itemIndex}
        />
      </Box>
    ) : redirectTo.includes("https") ? (
      <Tooltip key={itemIndex} title={open ? "" : itemText}>
        <ListItemButton
          sx={{
            height: 50,
            justifyContent: open ? "initial" : "center",
            px: open ? 2.5 : 0,
            borderLeft: "6px solid transparent",
          }}
          onClick={() => window.open(redirectTo, "_blank")}
        >
          <ListItem>
            <ListItemIcon
              className={classes.listIcon}
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <Icon
                style={{
                  color: theme.palette.mode === "dark" ? "#fff" : "#333",
                }}
              />
            </ListItemIcon>
            <ListItemText primary={itemText} sx={{ opacity: open ? 1 : 0 }} />
          </ListItem>
        </ListItemButton>
      </Tooltip>
    ) : (
      <NavLink
        key={itemIndex}
        to={redirectTo}
        className={
          redirectTo &&
          (location.pathname === trimParams(redirectTo) ||
            location.pathname.includes(String(trimParams(redirectTo))))
            ? classes.isActive
            : theme.palette.mode === "dark"
            ? classes.darkColorMode
            : classes.isInactive
        }
        style={{ display: "inherit", textDecoration: "none" }}
      >
        <NavItems
          redirectTo={redirectTo}
          Icon={Icon}
          itemText={itemText}
          menus={menus}
          itemIndex={itemIndex}
        />
      </NavLink>
    );
  };

  useEffect(() => {
    let transformedPermissions: any = [];

    userPermissions?.forEach((item: any) => {
      if (
        transformedPermissions.length > 0 &&
        transformedPermissions.find((x: any) => x.parent === item?.parent)
      ) {
        const foundEl = transformedPermissions.find(
          (x: any) => x.parent === item?.parent
        );
        const updatedChildren = [...foundEl.children, item?.child];

        transformedPermissions = transformedPermissions.filter(
          (x: any) => x.parent !== item?.parent
        );
        transformedPermissions.push({
          sl_no: Number(item?.sl_no),
          parent: item?.parent,
          children: updatedChildren,
        });
      } else {
        transformedPermissions.push({
          sl_no: Number(item?.sl_no),
          parent: item?.parent,
          children: item?.child !== "" ? [item.child] : [],
        });
      }
    });

    transformedPermissions = sortBy(transformedPermissions, ["sl_no"]);

    const sidebarMenu: any = [];

    transformedPermissions?.forEach((menu: any) => {
      for (let item of allSidebarItems) {
        if (item.itemText?.toLowerCase() === menu.parent?.toLowerCase()) {
          const nestedChildren = item.menus.filter((child: any) => {
            return menu.children
              ?.toString()
              .toLowerCase()
              ?.includes(child?.name?.toLowerCase());
          });
          item = { ...item, menus: nestedChildren };
          sidebarMenu.push(item);
        }
      }
    });

    setMenuItems(sidebarMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List sx={{ paddingTop: 0, marginTop: 0 }}>
      {menuItems.length > 0 &&
        menuItems.map((x: any, i: number) => {
          return (
            <GenerateMenuLink
              key={i}
              redirectTo={x.redirectTo}
              Icon={x.Icon}
              itemText={x.itemText}
              menus={x.menus}
              itemIndex={i}
            />
          );
        })}
    </List>
  );
};

export default SidebarMenu;
