import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";

const HelpModal = ({ open, onClose, placeOrder, loading }: any) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          FAQs
        </CustomDialogTitle>
        <DialogContent sx={{ overflowY: "scroll" }}>
          <Typography variant="h5">Credit Limit</Typography>
          <Typography pt={0.5}>
            Pharmacies can get credit up to 2 lakhs, starting two months after
            reviewing the customer's buying behavior.
          </Typography>
          <br />
          <Divider />
          <br />
          <Typography variant="h5">Delivery Details</Typography>
          <Typography pt={0.5}>
            Orders will be delivered within 2 to 3 days to the customer's
            location. All parcels will be shipped via Express Delivery or Speed
            Post.
          </Typography>
          <Box textAlign="center" mt={5}>
            <LoadingButton
              color="primary"
              variant="contained"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={() => {
                onClose();
                placeOrder();
              }}
            >
              Request a call
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default HelpModal;
