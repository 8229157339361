import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import AddressDetail from "../../../CreateOrder/addressDetail";
import PatientDetail from "../../../CreateOrder/patientDetail";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import {
  editCustomerSchemaNonPharma,
  editCustomerSchemaPharma,
} from "../../../../schemas/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";

const EditCustomerModal = ({
  editCustomerModal,
  setEditCustomerModal,
  customerDetail,
  orderType,
  orderId,
  setParentModal,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      order_id: orderId,
      type: "customer-details-update",
      customer_details: {
        address_details: {
          address: values.address
            ? values.address
            : customerDetail?.address_details?.address,
          city: values.city
            ? values.city
            : customerDetail?.address_details?.city,
          coordinates: customerDetail?.address_details?.coordinates,
          landmark: values.landmark
            ? values.landmark
            : customerDetail?.address_details?.landmark,
          pin_code: values.pin
            ? values.pin
            : customerDetail?.address_details?.pin_code,
          state: values.state
            ? values.state
            : customerDetail?.address_details?.state,
        },
        age: values.age ? values.age : customerDetail?.age,
        alternative_mobile: customerDetail?.alternative_mobile,
        email: customerDetail?.email,
        gender: values.gender ? values.gender : customerDetail?.gender,
        mobile: customerDetail?.mobile,
        name: values.full_name ? values.full_name : customerDetail?.name,
      },
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Customer details changed successfully");
        actions.resetForm({});
        setParentModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={editCustomerModal}
      onClose={() => setEditCustomerModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setEditCustomerModal(false)}
      >
        Edit Customer Details
      </CustomDialogTitle>
      <DialogContent>
        <>
          <Formik
            initialValues={{
              full_name: customerDetail?.name,
              gender: customerDetail?.gender,
              age: customerDetail?.age,
              address: customerDetail?.address_details?.address,
              landmark: customerDetail?.address_details?.landmark,
              pin: customerDetail?.address_details?.pin_code,
              city: customerDetail?.address_details?.city,
              state: customerDetail?.address_details?.state,
            }}
            validationSchema={
              orderType === ORDER_TYPE.MEDICINES
                ? editCustomerSchemaPharma
                : editCustomerSchemaNonPharma
            }
            onSubmit={(values: any, actions: any) => {
              setLoading(true);
              actions.setSubmitting(false);
              handleSubmit(values, actions);
            }}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setTouched,
              } = props;
              return (
                <Form>
                  {/* <Typography variant="h4" sx={{ mt: 3 }}>
                    Customer Details:
                  </Typography> */}
                  <PatientDetail
                    createOrderType={orderType}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    editCustomerDetail={true}
                    formData={values}
                  />
                  <Typography variant="h4" sx={{ mt: 3 }}>
                    Address Details:
                  </Typography>
                  <AddressDetail
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    formData={values}
                    setTouched={setTouched}
                  />
                  <Box textAlign="center" sx={{ mt: 3 }}>
                    <LoadingButton
                      sx={{ width: "180px" }}
                      size="medium"
                      color="primary"
                      loading={loading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update
                    </LoadingButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default EditCustomerModal;
