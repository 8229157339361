import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNotifications } from "../../store/actions/useActions";
import { useNavigate } from "react-router-dom";

const Notification: React.FC<{
  showNotif: boolean;
  setShowNotif: any;
  toggleNotification: (
    anchor: string,
    notifFlag: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}> = ({ showNotif, setShowNotif, toggleNotification }) => {
  const allNotifications = useSelector(
    (state: any) => state.notifications.data
  );
  const dispatch = useDispatch();

  const removeNotification = (id: string) => {
    let updatedData = [...allNotifications];
    updatedData = updatedData.filter(
      (notification) => notification.order_id !== id
    );
    dispatch(UpdateNotifications(updatedData));
    localStorage.setItem("user_notif", JSON.stringify(updatedData));
  };

  const navigate = useNavigate();

  const handleNotifCick = (notifType: string) => {
    let navigateTo = "";

    const manageOrderConst = ["medicines", "consultation", "diagnostics"];
    if (manageOrderConst?.includes(notifType)) {
      navigateTo =
        notifType === "medicines" ? "pharmacy/manage" : notifType + "/manage";
    }

    if (notifType?.includes("report")) {
      if (notifType === "billing_report" || notifType === "reco_report") {
        navigateTo = "reports/audit";
      }
      if (notifType === "order_report") {
        navigateTo = "reports/orders";
      }
      if (notifType === "opd_notifications") {
        navigateTo = "reports/cashless";
      }
    }

    if (navigateTo) {
      setShowNotif(false);
      //removeNotification(id);
      navigate(navigateTo);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={showNotif}
      onClose={toggleNotification("right", false)}
      PaperProps={{
        sx: { width: { xs: "60%", sm: "50%", md: "30%", lg: "25%" } },
      }}
    >
      <Box
        sx={{
          marginTop: { xs: "56px", sm: "65px" },
        }}
      >
        {allNotifications && allNotifications.length > 0 ? (
          <Box textAlign="right" px={1.5} py={1}>
            <Button
              variant="text"
              color="error"
              sx={{ fontSize: "12px" }}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure, this will clear all notifications?"
                  )
                ) {
                  dispatch(UpdateNotifications([]));
                  localStorage.setItem("user_notif", JSON.stringify([]));
                }
              }}
            >
              Clear All
            </Button>
          </Box>
        ) : (
          <Typography textAlign="center" mt={1}>
            No new notifications!
          </Typography>
        )}
        {allNotifications &&
          allNotifications.map((notification: any, index: number) => {
            return (
              <Box key={index}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ p: 1.5 }}
                >
                  <Stack
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleNotifCick(notification.notif_type?.toLowerCase())
                    }
                  >
                    <Typography>{notification.title}</Typography>
                    <Typography variant="body2">{notification.body}</Typography>
                    <Typography
                      variant="body2"
                      sx={{ opacity: 0.75, fontSize: "12px !important" }}
                    >
                      {notification.created_at}
                    </Typography>
                  </Stack>
                  <Tooltip title="Clear">
                    <IconButton
                      onClick={() => removeNotification(notification.order_id)}
                      color="error"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
                <Divider />
              </Box>
            );
          })}
      </Box>
    </Drawer>
  );
};

export default Notification;
