import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OverallMetrics from "./OverallMetrics";
import PartnersAutocompleteFromApi from "../../components/Common/partnersACFromApi";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import { USER_ROLE } from "../../enums/common.enum";
import ApiWiseMetrics from "./ApiWiseMetrics";
import UsageStatsMetrics from "./UsageStatsMetrics";
import StatusWiseMetrics from "./StatusWiseMetrics";

const ApiMetrics = () => {
  const [selectedPartner, setSelectedPartner] = useState("");
  const user: AuthenticatedUser = useUserInfo();
  const [selectedMonth, setSelectedMonth] = useState<string | number>(
    new Date().getMonth() + Number(1)
  );

  useEffect(() => {
    if (user.role === USER_ROLE.PARTNER) {
      setSelectedPartner(String(user?.partner));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack mb={3} direction="row" justifyContent="center">
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="end"
          sx={{ mb: { xs: 1.5, md: 0 } }}
        >
          <FormControl sx={{ minWidth: "150px" }} fullWidth size="small">
            <InputLabel id="month-label">Month</InputLabel>
            <Select
              labelId="month-label"
              name="month"
              id="month"
              value={String(selectedMonth)}
              label="Month Name"
              onChange={(e: SelectChangeEvent) => {
                setSelectedMonth(String(e.target.value));
              }}
              sx={{ mr: 1.5 }}
            >
              {[
                { name: "January", number: "1" },
                { name: "February", number: "2" },
                { name: "March", number: "3" },
                { name: "April", number: "4" },
                { name: "May", number: "5" },
                { name: "June", number: "6" },
                { name: "July", number: "7" },
                { name: "August", number: "8" },
                { name: "September", number: "9" },
                { name: "October", number: "10" },
                { name: "November", number: "11" },
                { name: "December", number: "12" },
              ]
                .slice(0, new Date().getMonth() + 1)
                .map((month: any, i: number) => {
                  return (
                    <MenuItem key={i} value={month.number}>
                      {month.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              name="year"
              id="year"
              value="2024"
              label="Year"
              sx={{ mr: 1.5 }}
            >
              <MenuItem value="2024">2024</MenuItem>
            </Select>
          </FormControl>
          {user?.role === USER_ROLE.PARTNER ? null : (
            <PartnersAutocompleteFromApi
              providerId={selectedPartner}
              setProviderId={setSelectedPartner}
              disableField={user.role !== USER_ROLE.SUPER_ADMIN}
            />
          )}
        </Box>
      </Stack>
      {selectedPartner ? (
        <Stack>
          <UsageStatsMetrics
            selectedMonth={selectedMonth}
            selectedPartner={selectedPartner}
          />

          <OverallMetrics
            selectedMonth={selectedMonth}
            selectedPartner={selectedPartner}
            graphTitle="Overall Metrics"
            legendText="Count"
            tooltipText={
              <p>Total number of calls made to MedPay APIs per day</p>
            }
          />

          <StatusWiseMetrics
            selectedMonth={selectedMonth}
            selectedPartner={selectedPartner}
            graphTitle="Status Wise Metrics"
            tooltipText={
              <>
                <p>
                  <strong>Successful calls:</strong> Calls to MedPay APIs that
                  contribute to quota consumption
                </p>
                <p>
                  <strong>Failed calls:</strong> Unauthorised or Restricted
                  calls to MedPay APIs exempt from quota consumption
                </p>
              </>
            }
          />

          <ApiWiseMetrics
            selectedMonth={selectedMonth}
            selectedPartner={selectedPartner}
            graphTitle="API Wise Metrics"
            legendText="Count"
            tooltipText={
              <p>
                Successful MedPay API calls categorised by order type and API
                type
              </p>
            }
          />
        </Stack>
      ) : null}
    </>
  );
};

export default ApiMetrics;
