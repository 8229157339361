import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box } from "@mui/material";

const ConfirmModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  callback,
  message,
}: any) => {
  return (
    <div>
      <Dialog
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          <Box mt={3} textAlign="center">
            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowConfirmationModal(false)}
            >
              NO
            </Button>
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                setShowConfirmationModal(false);
                callback();
              }}
              sx={{ ml: 1.5 }}
              autoFocus
            >
              YES
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmModal;
