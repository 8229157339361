import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { errorToast, handleError } from "../../utils/common";
import QRCode from "qrcode";

const AuthenticatorModal = ({
  authenticatorModal,
  setAuthenticatorModal,
}: any) => {
  const [qrcode, setQrcode] = useState("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();

  useEffect(() => {
    createQrCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createQrCode = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/auth/totp/register/${user?.login_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        generateQRCode(result.data.response.registration_uri);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const generateQRCode = (url: string) => {
    QRCode.toDataURL(url, (err: any, url: any) => {
      if (err) return console.error(err);
      setQrcode(url);
    });
  };

  return (
    <Dialog
      open={authenticatorModal}
      onClose={() => setAuthenticatorModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setAuthenticatorModal(false)}
      >
        QR Code
      </CustomDialogTitle>
      <DialogContent>
        {loading && <CircularProgress size={20} color="primary" />}
        {qrcode && (
          <>
            <Stack justifyContent="center" alignItems="center">
              <Box
                border="1px solid #000"
                justifyContent="center"
                alignItems="center"
              >
                <img src={qrcode} alt="qr_code" />
              </Box>

              <Box>
                <Stack marginTop={10}>
                  <Typography variant="h4">Note :</Typography>
                  <Typography>
                    1. Download <b>Google Authenticator App</b> from Play Store{" "}
                    <b>(Android)</b> or App Store <b>(iOS)</b>.
                  </Typography>
                  <Typography>
                    2. Click on <b>Add Button</b>.
                  </Typography>
                  <Typography>
                    3. Select <b>Scan a QR Code</b> option.
                  </Typography>
                  <Typography>
                    4. Scan above <b>QR Code</b>.
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AuthenticatorModal;
