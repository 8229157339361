import { Box, Typography } from "@mui/material";
import React from "react";
import CommonDataView from "../../../components/ManageOrder/GroupOrders/Assign/commonDataView";
import OrderTests from "./orderTests";

interface IOrderLabProp {
  merchant_details: {
    name: string;
    mobile: string | number;
    formatted_address: string;
  };
  time_slot: string;
  tests: any;
  showTotal: boolean;
  showMerchantInfo: boolean;
  orderType: string;
  time_slot_label: string;
}

const OrderLabInfo = ({
  merchant_details,
  time_slot,
  tests,
  showTotal,
  showMerchantInfo,
  orderType,
  time_slot_label,
}: IOrderLabProp) => {
  return (
    <>
      {showMerchantInfo && (
        <>
          {merchant_details ? (
            <CommonDataView
              merchant_details={merchant_details}
              cols={["NAME", "MOBILE NO", "ADDRESS"]}
              orderType={orderType}
              orderId=""
            />
          ) : null}
          <Box textAlign="center">
            <Typography variant="h5" sx={{ my: 2 }}>
              {time_slot_label}: {time_slot}
            </Typography>
          </Box>
        </>
      )}

      <OrderTests tests={tests} showTotal={showTotal} />
    </>
  );
};

export default OrderLabInfo;
