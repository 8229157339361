import React, { useState } from "react";
import RupeeIcon from "@mui/icons-material/CurrencyRupee";
import { IconButton, Tooltip } from "@mui/material";
import ConfirmPaymentModal from "./confirmPaymentModal";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../../utils/common";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { ORDER_STATUS } from "../../../../enums/order.enum";
import { useIconStyles } from "../../../Helpers/iconStyles";

const ConfirmPayment = ({
  activePartner,
  activePartnerOrderId,
  orderStatus,
}: any) => {
  const [confirmPayModal, setConfirmPayModal] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const iconClasses = useIconStyles();

  const getOrderByPartnerOrderId = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
        setConfirmPayModal(true);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="confirm payment">
        <span>
          <IconButton
            color="info"
            onClick={() => {
              getOrderByPartnerOrderId();
            }}
            disabled={
              orderStatus === ORDER_STATUS.ACCEPTED ||
              orderStatus === ORDER_STATUS.CANCELLED
            }
          >
            <RupeeIcon className={iconClasses.borderedIcon} />
          </IconButton>
        </span>
      </Tooltip>
      {confirmPayModal && (
        <ConfirmPaymentModal
          confirmPayModal={confirmPayModal}
          setConfirmPayModal={setConfirmPayModal}
          orderData={orderData}
          setOrderData={setOrderData}
          setParentModal={null}
        />
      )}
    </>
  );
};

export default ConfirmPayment;
