import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  shouldShowOrderData,
} from "../../utils/common";
import CombinedInfo from "../OrderDetails/combinedInfo";
import OrderData from "../OrderDetails/orderData";
import CustomDialogTitle from "../Common/customDialogTitle";
import OrderPlatform from "../Common/orderPlatform";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import { ORDER_STATUS, ORDER_TYPE } from "../../enums/order.enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import OrderNotesAndConfirmation from "./orderNotesAndConfirmation";
import CancelOrderModal from "../Common/cancelOrderModal";
import PrescriptionData from "../OrderDetails/prescriptionData";
import OrderAudits from "../../pages/Settings/Orders/orderAudits";

const OrderDataModal = ({
  orderDataModal,
  setOrderDataModal,
  activePartner,
  activePartnerOrderId,
  orderType,
  showInvoiceCancel,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [orderData, setOrderData] = useState<any>([]);
  const user: AuthenticatedUser = useUserInfo();
  const [cancelModal, setCancelModal] = useState(false);

  const getOrderByPartnerOrderId = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activePartner && activePartnerOrderId) {
      getOrderByPartnerOrderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={orderDataModal}
      onClose={() => setOrderDataModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setOrderDataModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
            {orderType} order
          </Typography>
          {orderData && orderData.platform && (
            <OrderPlatform platform={orderData.platform} />
          )}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {orderData && Object.keys(orderData).length > 0 && (
              <Box
                sx={{
                  width: "100%",
                  my: 1,
                  padding: { xs: "15px" },
                }}
              >
                <CombinedInfo
                  orderData={orderData}
                  setOrderData={setOrderData}
                  setOrderDataModal={setOrderDataModal}
                  hideStatus={false}
                  hideFirstRow={false}
                />
              </Box>
            )}

            {user.role !== USER_ROLE.PARTNER && (
              <Accordion
                sx={{
                  my: 2,
                  boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                  mx: "15px",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h4">Audits</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <OrderAudits
                    oldAuditLogs={orderData?.audit}
                    orderId={orderData?.order_id}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            {shouldShowOrderData(orderData) && (
              <Box
                sx={{
                  width: "100%",
                  mb: 1.5,
                  padding: { xs: "15px" },
                }}
              >
                <OrderData
                  orderData={orderData}
                  showHeading={true}
                  notAdminPanel={true}
                />
              </Box>
            )}

            {/* diagnostics reports */}
            {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS && (
              <>
                {orderData?.diagnostics_reports_links ? (
                  <PrescriptionData
                    prescription_links={orderData?.diagnostics_reports_links}
                    component_label="Reports"
                  />
                ) : null}
              </>
            )}

            {/* notes and accept/reject changes */}
            <OrderNotesAndConfirmation
              orderData={orderData}
              setOrderDataModal={setOrderDataModal}
            />

            {showInvoiceCancel && (
              <Box display="flex" justifyContent="center">
                {orderData &&
                  orderData.invoice_details &&
                  (orderData.order_status?.toLowerCase() !==
                    ORDER_STATUS.CANCELLED ||
                    orderData.order_status?.toLowerCase() !==
                      ORDER_STATUS.PENDING) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        window.open(
                          orderData?.invoice_details?.invoice_link,
                          "_blank"
                        )
                      }
                    >
                      View Invoice
                    </Button>
                  )}
                {orderData?.order_status === ORDER_STATUS.CANCELLED ||
                orderData?.order_status === ORDER_STATUS.BOOKING_COMPLETED ||
                orderData?.order_status ===
                  ORDER_STATUS.PRESCRIPTION_UPLOADED ||
                orderData?.order_status === ORDER_STATUS.PACKAGE_DELIVERED ||
                orderData?.order_status === ORDER_STATUS.SAMPLE_COLLECTED ||
                orderData?.order_status === ORDER_STATUS.REPORTS_GENERATED ||
                orderData?.order_status ===
                  ORDER_STATUS.PARTNER_PENDING ? null : (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setCancelModal(true)}
                    sx={{ ml: 1 }}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            )}

            {cancelModal && (
              <CancelOrderModal
                cancelModal={cancelModal}
                setCancelModal={setCancelModal}
                orderId={orderData?.order_id}
                orderType={orderData?.order_type}
                closeParentModal={setOrderDataModal}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderDataModal;
