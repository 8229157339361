import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { handleError } from "../../utils/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";
import EditBankDetailsModal from "../Dunzo/Providers/editBankDetailsModal";
import EditAddressModal from "./editAddressModal";
import EditGstModal from "./editGstModal";
import KnowlarityCall from "../../components/Common/knowlarityCall";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const useStyles = makeStyles({
  accordion_collapsed: {
    borderRadius: "4px",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
});

const InfoRowComponent = ({ label, value }: any) => {
  return (
    <>
      {value ? (
        <Typography pt={1} variant="body2">
          <strong>{label}</strong>: {value}
        </Typography>
      ) : null}
    </>
  );
};

const ProviderSettingsInfoModal = ({
  showProviderSettingsInfoModal,
  setShowProviderSettingsInfoModal,
  activeLocationId,
  providersData,
  setProvidersData,
  path,
  targetId,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const privateInstance = useAxiosInstance();
  const [providerInfo, setProviderInfo] = useState<any | null>(null);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [showEditBankDetailsModal, setShowEditBankDetailsModal] =
    useState<boolean>(false);
  const [showEditAddressModal, setShowEditAddressModal] =
    useState<boolean>(false);
  const [showEditGstModal, setShowEditGstModal] = useState<boolean>(false);
  const classes = useStyles();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getProviderInfo = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/provider?location_id=${activeLocationId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProviderInfo(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProviderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={showProviderSettingsInfoModal}
      onClose={() => setShowProviderSettingsInfoModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowProviderSettingsInfoModal(false)}
      >
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography>Provider Info | {providerInfo?.provider_name}</Typography>
          {providerInfo?.static_qr_enabled ? (
            <Tooltip title="MedPay QR Pharmacy">
              <QrCode2Icon fontSize="small" color="primary" />
            </Tooltip>
          ) : null}
        </Stack>
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {/* metadata */}
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                mt: 1.5,
              }}
              className={classes.accordion_collapsed}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: expanded === "panel1" ? "#0058ff" : "#000",
                    }}
                  >
                    Overview
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {providerInfo?.agreement_link ? (
                  <Link target="_blank" href={providerInfo?.agreement_link}>
                    Agreement Link
                  </Link>
                ) : null}
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <InfoRowComponent
                      label="Provider Name"
                      value={providerInfo?.provider_name}
                    />
                    <InfoRowComponent
                      label="Specialties"
                      value={providerInfo?.specialties?.join(", ")}
                    />
                    <InfoRowComponent
                      label="Status"
                      value={providerInfo?.status}
                    />
                    <InfoRowComponent
                      label="Location Id"
                      value={providerInfo?.location_id}
                    />
                    <InfoRowComponent
                      label="GST No"
                      value={providerInfo?.gst_no}
                    />
                    <InfoRowComponent
                      label="PAN No"
                      value={providerInfo?.pan_no}
                    />
                    <InfoRowComponent
                      label="Ambulance Service"
                      value={providerInfo?.ambulance_service}
                    />
                    <InfoRowComponent
                      label="Last App Active"
                      value={providerInfo?.last_app_active_at}
                    />
                  </Stack>
                  <Stack>
                    <InfoRowComponent
                      label="Provider Type"
                      value={providerInfo?.provider_type}
                    />
                    <InfoRowComponent
                      label="Whatsapp Number"
                      value={
                        <KnowlarityCall
                          order={{
                            order_id: targetId,
                            mobile: providerInfo?.whatsapp_number,
                          }}
                          showNumber={true}
                          renderSmallIcon={true}
                          service={path}
                        />
                      }
                    />
                    <InfoRowComponent
                      label="Platform"
                      value={providerInfo?.platform}
                    />
                    <InfoRowComponent
                      label="Provider Id"
                      value={providerInfo?.provider_id}
                    />
                    <InfoRowComponent
                      label="GST Name"
                      value={providerInfo?.gst_name}
                    />
                    <InfoRowComponent
                      label="Orders Fulfilled"
                      value={providerInfo?.orders_fulfilled}
                    />
                    <InfoRowComponent
                      label="Home Service"
                      value={providerInfo?.home_service}
                    />
                  </Stack>
                </Stack>
                {providerInfo?.registration_details?.registered_with ||
                providerInfo?.registration_details?.registration_licence_no ||
                providerInfo?.registration_details?.year_of_registration ? (
                  <>
                    <Typography pt={1} variant="body2" fontWeight="bold">
                      REGISTRATION DETAILS:
                    </Typography>
                    <Box pl={3}>
                      <InfoRowComponent
                        label="Registered With"
                        value={
                          providerInfo?.registration_details?.registered_with
                        }
                      />
                      <InfoRowComponent
                        label="Registration License No"
                        value={
                          providerInfo?.registration_details
                            ?.registration_licence_no
                        }
                      />
                      <InfoRowComponent
                        label="Year of Registration"
                        value={
                          providerInfo?.registration_details
                            ?.year_of_registration
                        }
                      />
                    </Box>
                  </>
                ) : null}
                {providersData ? (
                  <Box pt={1} textAlign="center">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowEditGstModal(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
            {/* address info */}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                mt: 1.5,
              }}
              className={classes.accordion_collapsed}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: expanded === "panel2" ? "#0058ff" : "#000",
                    }}
                  >
                    Address
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <InfoRowComponent
                  label="Address"
                  value={providerInfo?.formatted_address}
                />
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <InfoRowComponent label="Area" value={providerInfo?.area} />
                    <InfoRowComponent label="City" value={providerInfo?.city} />
                    <InfoRowComponent
                      label="Pin Code"
                      value={providerInfo?.pin_code}
                    />
                  </Stack>
                  <Stack>
                    <InfoRowComponent
                      label="Locality"
                      value={providerInfo?.locality}
                    />

                    <InfoRowComponent
                      label="State"
                      value={providerInfo?.state}
                    />

                    <InfoRowComponent
                      label="Coordinates"
                      value={
                        providerInfo?.latitude
                          ? `${providerInfo?.latitude}, ${providerInfo?.longitude}`
                          : ""
                      }
                    />
                  </Stack>
                </Stack>
                {providersData ? (
                  <Box pt={1} textAlign="center">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowEditAddressModal(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
            {/* account info */}
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                mt: 1.5,
              }}
              className={classes.accordion_collapsed}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel1bh-header"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: expanded === "panel3" ? "#0058ff" : "#000",
                    }}
                  >
                    Account
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <InfoRowComponent
                      label="Settlement Mode"
                      value={providerInfo?.settlement_mode}
                    />
                    <InfoRowComponent
                      label="Account No"
                      value={providerInfo?.account_no}
                    />
                    <InfoRowComponent
                      label="Account Name"
                      value={providerInfo?.account_name}
                    />
                    <InfoRowComponent
                      label="IFSC Code"
                      value={providerInfo?.ifsc_code}
                    />
                    <InfoRowComponent
                      label="UPI ID"
                      value={providerInfo?.upi_id}
                    />
                  </Stack>
                  <Stack>
                    {providerInfo?.static_qr_enabled ? (
                      <InfoRowComponent
                        label="Static QR Settlement Mode"
                        value={providerInfo?.static_qr_settlement_mode}
                      />
                    ) : null}
                    <InfoRowComponent
                      label="Account Type"
                      value={providerInfo?.account_type}
                    />
                    <InfoRowComponent
                      label="Bank Name"
                      value={providerInfo?.bank_name}
                    />
                    <InfoRowComponent
                      label="Branch Name"
                      value={providerInfo?.branch_name}
                    />
                    <InfoRowComponent
                      label="UPI Name"
                      value={providerInfo?.upi_name}
                    />
                  </Stack>
                </Stack>

                {providerInfo?.tds_tcs_details ? (
                  <>
                    <Typography pt={1} variant="body2" fontWeight="bold">
                      TDS TCS DETAILS:
                    </Typography>
                    <Box pl={3}>
                      {/* <InfoRowComponent
                        label="Is Enabled"
                        value={providerInfo?.tds_tcs_details?.is_active}
                      /> */}
                      <InfoRowComponent
                        label="TDS"
                        value={`${providerInfo?.tds_tcs_details?.tds_percentage}%`}
                      />
                      <InfoRowComponent
                        label="TCS"
                        value={`${providerInfo?.tds_tcs_details?.tcs_percentage}%`}
                      />
                    </Box>
                  </>
                ) : null}

                {providersData ? (
                  <Box pt={1} textAlign="center">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => setShowEditBankDetailsModal(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
            {/* profile info */}
            {providerInfo?.profiles && providerInfo.profiles.length > 0 ? (
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                sx={{
                  boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                  mt: 1.5,
                }}
                className={classes.accordion_collapsed}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel1bh-header"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: expanded === "panel4" ? "#0058ff" : "#000",
                      }}
                    >
                      Profiles
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  {providerInfo.profiles.map((profile: any, index: number) => {
                    return (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        key={index}
                        sx={{
                          borderBottom: "1px solid #E0E0E0",
                          py: 2,
                        }}
                      >
                        <Box>
                          <InfoRowComponent
                            label="Full Name"
                            value={profile?.full_name}
                          />
                          <InfoRowComponent
                            label="Specialties"
                            value={
                              profile?.specialties
                                ? profile.specialties?.join(", ")
                                : ""
                            }
                          />
                          <InfoRowComponent
                            label="Experience (Yrs.)"
                            value={profile?.experience_in_years}
                          />
                          <InfoRowComponent
                            label="Degrees"
                            value={
                              profile?.degrees
                                ? profile.degrees?.join(", ")
                                : ""
                            }
                          />
                          <InfoRowComponent
                            label="Gender"
                            value={profile?.gender}
                          />
                        </Box>
                        <Box>
                          <InfoRowComponent
                            label="Languages"
                            value={
                              profile?.languages
                                ? profile.languages?.join(", ")
                                : ""
                            }
                          />
                          <InfoRowComponent
                            label="Type"
                            value={profile?.type}
                          />
                          <InfoRowComponent
                            label="Consultation Fees (Rs.)"
                            value={profile?.consultation_fee}
                          />
                          <InfoRowComponent
                            label="Whatsapp No"
                            value={profile?.whatsapp_no}
                          />
                          <InfoRowComponent
                            label="Registration Year"
                            value={profile?.registration_year}
                          />
                          <InfoRowComponent
                            label="Registration Auth"
                            value={profile?.registration_auth}
                          />
                          <InfoRowComponent
                            label="Registration No"
                            value={profile?.registration_no}
                          />
                        </Box>
                      </Stack>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : null}
            {/* dunzo association info */}
            {providerInfo?.dunzo_display_name ? (
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                sx={{
                  boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                  mt: 1.5,
                }}
                className={classes.accordion_collapsed}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel1bh-header"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: expanded === "panel5" ? "#0058ff" : "#000",
                      }}
                    >
                      Dunzo Association
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack>
                      <InfoRowComponent
                        label="Dunzo Display Name"
                        value={providerInfo?.dunzo_display_name}
                      />
                      <InfoRowComponent
                        label="FMCG Discount %"
                        value={providerInfo?.dunzo_discounts?.fmcg}
                      />
                    </Stack>
                    <Stack>
                      <InfoRowComponent
                        label="Dunzo Status"
                        value={providerInfo?.dunzo_status}
                      />
                      <InfoRowComponent
                        label="Medicines Discount %"
                        value={providerInfo?.dunzo_discounts?.medicines}
                      />
                    </Stack>
                    <Stack></Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ) : null}
          </>
        )}
        {showEditBankDetailsModal && (
          <EditBankDetailsModal
            showEditBankDetailsModal={showEditBankDetailsModal}
            setShowEditBankDetailsModal={setShowEditBankDetailsModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={providersData}
            setProvidersData={setProvidersData}
            providerType="non_dunzo"
          />
        )}
        {showEditAddressModal && (
          <EditAddressModal
            showEditAddressModal={showEditAddressModal}
            setShowEditAddressModal={setShowEditAddressModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={providersData}
            setProvidersData={setProvidersData}
          />
        )}
        {showEditGstModal && (
          <EditGstModal
            showEditGstModal={showEditGstModal}
            setShowEditGstModal={setShowEditGstModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProviderSettingsInfoModal;
