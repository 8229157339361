import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../../components/UI/tabPanel";
import { a11yProps } from "../../utils/common";
import CreateLogisticsReqTab from "./createLogisticsReqTab";
import ShowLogisticsReqTab from "./showLogisticsReqTab";

const Logistics = () => {
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const activeTab: any = params.get("tab");
  const [value, setValue] = React.useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Create Task"
            {...a11yProps(0)}
            onClick={() => navigate(`/logistics?tab=1`)}
          />
          <Tab
            label="Show Requests"
            {...a11yProps(1)}
            onClick={() => navigate(`/logistics?tab=2&subTab=1`)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CreateLogisticsReqTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowLogisticsReqTab />
      </TabPanel>
    </Box>
  );
};

export default Logistics;
