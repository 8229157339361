import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  ImageList,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import {
  errorToast,
  getIssueStatusBgColor,
  handleError,
  parseAndFormateDate,
  successToast,
  textCapitalize,
} from "../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import OrderAudits from "../Settings/Orders/orderAudits";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KnowlarityCall from "../../components/Common/knowlarityCall";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ResponseTemplateModal from "./responseTemplateModal";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IssueMediaModal } from "./issueMediaModal";
import IssueMsgView from "./issueMsgView";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import IssueAttachmentsView from "./issueAttachmentsView";
import UtrTxnModal from "./utrTxnModal";
import ClearIcon from "@mui/icons-material/Clear";
import { medpayTeams } from "../../constants";

const typeOfNotes = ["internal", "partner", "techsupport"];

const IssueDetailModal = ({
  open,
  onClose,
  data,
  setData,
  viewMode,
  getIssuesList,
  tableData,
  setTableData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [status, setStatus] = useState(data?.issue_status || "");
  const [issueTypeOptions, setIssueTypeOptions] = useState([]);
  const [issueType, setIssueType] = useState(data?.issue_type);
  const [issueSubType, setIssueSubType] = useState(data?.issue_sub_type || "");
  const [issueTeam, setIssueTeam] = useState(data?.assigned_team || "");
  const [issueFollowUp, setIssueFollowUp] = useState<any>(
    data?.follow_up_date
      ? parse(data?.follow_up_date, "dd-MM-yyyy", new Date())
      : null
  );
  const [editingCategory, setEditingCategory] = useState(false);
  const [editingTeam, setEditingTeam] = useState(false);
  const [noteFor, setNoteFor] = useState("partner");
  const [textContent, setTextContent] = useState("");
  const [template, setTemplate] = useState(false);
  const [media, setMedia] = useState(false);
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const [utrTxnData, setUtrTxnData] = useState(null);
  const [utrTxnModal, setUtrTxnModal] = useState(false);

  const getIssueTypes = async () => {
    try {
      setFetching(true);
      const result = await privateInstance.get(
        `${API_VERSION.V1}/issues/categories`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setIssueTypeOptions(
          result.data?.response?.map((issue: any) => issue?.text)
        );
      }
    } catch (err) {
      handleError(err);
    } finally {
      setFetching(false);
    }
  };

  const updateIssue = async () => {
    let payload: any = {
      issue_status: status,
      response: {
        text: textCapitalize(textContent),
        images: uploadedMedia,
        updated_by: user?.user_id,
        visibility: noteFor,
      },
    };

    setLoading(true);

    try {
      const result = await privateInstance.patch(
        `${API_VERSION.V1}/issues/${data?.issue_id}`,
        payload
      );
      if (result.data?.status === API_RESULT.SUCCESS) {
        successToast("Updated Successfully");
        onClose();
        //update
        getIssuesList();
      } else {
        errorToast(result?.data?.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const updateIssueType = async (key: string, val: string) => {
    const res = await privateInstance.patch(
      `${API_VERSION.V1}/issues/${data?.issue_id}`,
      { [key]: val }
    );
    if (res?.data?.status === API_RESULT.SUCCESS) {
      successToast(
        `Issue ${
          key === "issue_type"
            ? "type"
            : key === "follow_up_date"
            ? "follow up date"
            : key === "assigned_team"
            ? "team"
            : "category"
        } updated successfully`
      );
      setData({ ...data, [key]: val });
      //update table UI Data;
      const itemIndex = tableData.findIndex(
        (p: any) => p.issue_id === data?.issue_id
      );
      tableData.splice(itemIndex, 1, { ...data, [key]: val });
      setTableData([...tableData]);
      //end
    }
  };

  useEffect(() => {
    return () => {
      setIssueTypeOptions([]);
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        <Stack
          mt={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {viewMode === "readOnly" ? "Issue Detail" : "Update Issue"} |{" "}
          {data?.issue_id}
          <Chip
            size="small"
            label={data?.issue_status?.split("_")?.join(" ")}
            sx={{
              mr: 5,
              backgroundColor: getIssueStatusBgColor(data?.issue_status),
              padding: "6px",
              color: ["open", "reopened"].includes(data?.issue_status)
                ? "#fff"
                : "#000",
            }}
          />
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        <Stack flexWrap="wrap" direction="row" justifyContent="space-between">
          <Stack>
            <Stack mb={1} direction="row" gap={1}>
              <Typography variant="body2">
                <strong>Provider Name:</strong>
              </Typography>
              <Link target="_blank" href={`/merchants/${data?.location_id}`}>
                <Typography variant="body2">{data?.provider_name}</Typography>
              </Link>
              {data?.whatsapp_number ? (
                <KnowlarityCall
                  order={{
                    order_id: data?.issue_id,
                    mobile: data?.whatsapp_number,
                  }}
                  showNumber={false}
                  renderSmallIcon={true}
                  service="app-issues"
                />
              ) : null}
            </Stack>
            <Typography variant="body2">
              <strong>Created At:</strong>{" "}
              {parseAndFormateDate(data?.created_at)}
            </Typography>
            {issueTypeOptions?.length > 0 ? (
              <FormControl
                size="small"
                sx={{ mt: 1.5, mb: 1, minWidth: "150px" }}
              >
                <InputLabel id="type-label">Issue Type</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  id="type"
                  label="Issue Type"
                  value={issueType}
                  onChange={(e) => {
                    setIssueType(e.target.value);
                    //update BE and prop data
                    updateIssueType("issue_type", e.target.value);
                  }}
                >
                  {issueTypeOptions.map((x, i) => {
                    return (
                      <MenuItem key={i} value={x}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography
                  mt={viewMode === "readOnly" ? 1 : 0}
                  variant="body2"
                >
                  <strong>Issue Type:</strong> {data.issue_type}
                </Typography>
                {viewMode === "readOnly" ? null : (
                  <>
                    {fetching ? (
                      <Box mt={1}>
                        <CircularProgress size={16} />
                      </Box>
                    ) : (
                      <IconButton
                        color="primary"
                        aria-label="close"
                        onClick={getIssueTypes}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </>
                )}
              </Stack>
            )}

            <Box>
              {editingCategory ? (
                <FormControl size="small" sx={{ my: 1, minWidth: "180px" }}>
                  <InputLabel id="category-label">Category</InputLabel>
                  <Select
                    labelId="category-label"
                    name="category"
                    id="category"
                    label="Category"
                    value={issueSubType}
                    onChange={(e) => {
                      setIssueSubType(e.target.value);
                      //update BE and prop data
                      updateIssueType("issue_sub_type", e.target.value);
                    }}
                  >
                    {[
                      "QR Name Change",
                      "Soundbox Issue",
                      "Notification Delay",
                      "App Performance",
                    ].map((x, i) => {
                      return (
                        <MenuItem key={i} value={x}>
                          {x}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                <Stack direction="row" alignItems="center" gap={1}>
                  {viewMode === "readOnly" && !data?.issue_sub_type ? null : (
                    <Typography
                      mt={viewMode === "readOnly" ? 1 : -1}
                      variant="body2"
                    >
                      <strong>Category:</strong> {data.issue_sub_type}
                    </Typography>
                  )}
                  {viewMode === "readOnly" ? null : (
                    <IconButton
                      sx={{ mt: -1 }}
                      color="primary"
                      aria-label="close"
                      onClick={() => setEditingCategory(true)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  )}
                </Stack>
              )}
            </Box>
          </Stack>
          <Stack>
            {editingTeam ? (
              <FormControl size="small" sx={{ my: 0.75 }}>
                <InputLabel id="category-label">Assigned Team</InputLabel>
                <Select
                  labelId="team-label"
                  name="team"
                  id="team"
                  label="Assigned Team"
                  value={issueTeam}
                  onChange={(e) => {
                    setIssueTeam(e.target.value);
                    //update BE and prop data
                    updateIssueType("assigned_team", e.target.value);
                  }}
                >
                  {medpayTeams.map((x, i) => {
                    return (
                      <MenuItem disabled={i > 2} key={i} value={x.value}>
                        {x.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <Stack direction="row" alignItems="center" mt={0.75} gap={1}>
                {viewMode === "readOnly" && !data?.assigned_team ? null : (
                  <Typography
                    mt={viewMode === "readOnly" ? 1 : -1}
                    variant="body2"
                  >
                    <strong>Assigned Team:</strong>{" "}
                    {
                      medpayTeams.find((x) => x.value === data.assigned_team)
                        ?.label
                    }
                  </Typography>
                )}
                {viewMode === "readOnly" ? null : (
                  <IconButton
                    sx={{ mt: -1 }}
                    color="primary"
                    aria-label="close"
                    onClick={() => setEditingTeam(true)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Stack>
            )}
            {data?.assigned_to ? (
              <Typography mb={1} variant="body2">
                <strong>Assigned To:</strong> {data?.assigned_to}
              </Typography>
            ) : null}
            {data?.agent_assigned_at ? (
              <Typography mb={1} variant="body2">
                <strong>Assigned At:</strong>{" "}
                {parseAndFormateDate(data?.agent_assigned_at)}
              </Typography>
            ) : null}

            {data?.support_language_preferences?.length > 0 ? (
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                gap={0.5}
              >
                <Typography variant="body2">
                  <strong>Language:</strong>{" "}
                </Typography>
                {data.support_language_preferences.map(
                  (item: any, index: number) => (
                    <Chip color="info" key={index} size="small" label={item} />
                  )
                )}
              </Stack>
            ) : null}
            {viewMode === "readOnly" ? null : (
              <Stack direction="row" alignItems="center" gap={0.5} mt={1.25}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Follow Up"
                    inputFormat="dd-MM-yyyy"
                    mask={"__-__-____"}
                    value={issueFollowUp}
                    onChange={(newValue: Date | null) => {
                      if (newValue) {
                        setIssueFollowUp(newValue);
                        //prevent calling api onclose
                        if (
                          format(newValue as Date, "dd-MM-yyyy") ===
                          data?.follow_up_date
                        ) {
                          return false;
                        }
                        //update BE and prop data
                        updateIssueType(
                          "follow_up_date",
                          format(newValue as Date, "dd-MM-yyyy")
                        );
                      }
                    }}
                    minDate={issueFollowUp ? issueFollowUp : new Date()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        name="date"
                        autoComplete="off"
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                {issueFollowUp ? (
                  <IconButton
                    color="error"
                    onClick={() => {
                      if (window.confirm("Are you sure?")) {
                        updateIssueType("follow_up_date", "");
                        setIssueFollowUp(null);
                      }
                    }}
                  >
                    <Tooltip title="Remove Follow Up">
                      <ClearIcon />
                    </Tooltip>
                  </IconButton>
                ) : null}
              </Stack>
            )}
          </Stack>
        </Stack>
        <Box mt={1.5}>
          <Divider />
        </Box>
        <Typography mt={2} variant="h6">
          Description:
        </Typography>
        <Typography variant="body2">
          Message: {data.description?.text}
        </Typography>
        <ImageList cols={3}>
          {data.description?.images?.map((item: string, index: number) => (
            <IssueAttachmentsView
              key={index}
              item={item}
              issueType={data?.issue_type}
              setUtrTxnData={setUtrTxnData}
              setUtrTxnModal={setUtrTxnModal}
            />
          ))}
        </ImageList>
        {utrTxnModal ? (
          <UtrTxnModal
            open={utrTxnModal}
            onClose={() => setUtrTxnModal(false)}
            data={utrTxnData}
          />
        ) : null}
        <Box mb={3}>
          <Accordion
            sx={{
              mt: 2,
              boxShadow: "0px 0.25rem 1rem rgba(0, 0, 0, 0.15)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Audits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OrderAudits oldAuditLogs={[]} orderId={data?.issue_id} />
            </AccordionDetails>
          </Accordion>
        </Box>
        {data.respondent_actions?.length > 0 ? (
          <>
            <Typography mt={2} variant="h6">
              Respondent Action:
            </Typography>
            <Box
              className="hideScrollBar"
              sx={{ maxHeight: "350px", overflowY: "scroll" }}
            >
              {data.respondent_actions.map((x: any, i: number) => {
                return <IssueMsgView key={i} x={x} />;
              })}
            </Box>
          </>
        ) : null}
        {viewMode === "readOnly" ? null : (
          <>
            <Divider />
            <Stack mt={1.5} direction="row">
              <FormControl fullWidth size="small" sx={{ mr: 1.5 }}>
                <InputLabel required id="reason-label">
                  Status
                </InputLabel>
                <Select
                  labelId="status-label"
                  name="status"
                  id="status"
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value="in_process">In-Process</MenuItem>
                  <MenuItem value="on_hold">On-Hold</MenuItem>
                  <MenuItem value="resolved">Resolved</MenuItem>
                  <MenuItem value="reopened">Reopened</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel id="share-with-label">Share With</InputLabel>
                <Select
                  labelId="share-with-label"
                  name="share-with"
                  id="share-with"
                  label="Share With"
                  value={noteFor}
                  onChange={(e) => setNoteFor(e.target.value)}
                  sx={{ textTransform: "capitalize" }}
                >
                  {typeOfNotes.map((x: string) => {
                    return (
                      <MenuItem
                        sx={{ textTransform: "capitalize" }}
                        key={x?.toLowerCase()}
                        value={x?.toLowerCase()}
                      >
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <TextField
              fullWidth
              multiline
              rows={4}
              id="text-content"
              label="Text"
              size="small"
              value={textContent}
              onChange={(e) => setTextContent(e.target.value)}
              InputLabelProps={{
                required: true,
              }}
              sx={{ mt: 1.5 }}
            />
            <Box mt={1.5}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<ContentPasteIcon />}
                onClick={() => setTemplate(true)}
              >
                Template
              </Button>
              <Button
                sx={{ ml: 1.5 }}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<AttachFileIcon />}
                onClick={() => setMedia(true)}
              >
                Attach Files{" "}
                {uploadedMedia?.length > 0 ? `(${uploadedMedia.length})` : null}
              </Button>
              {template ? (
                <ResponseTemplateModal
                  open={template}
                  onClose={() => setTemplate(false)}
                  setTextContent={setTextContent}
                  issueType={issueType}
                />
              ) : null}
              {media ? (
                <IssueMediaModal
                  open={media}
                  onClose={() => setMedia(false)}
                  activeLocationId={data?.location_id}
                  uploadType="issue"
                  uploadedFiles={uploadedMedia}
                  setUploadedFiles={setUploadedMedia}
                  mode="edit"
                />
              ) : null}
            </Box>
            <Box textAlign="center" mt={3}>
              <LoadingButton
                sx={{ minWidth: "180px" }}
                size="medium"
                color="primary"
                loading={loading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                variant="contained"
                type="button"
                onClick={updateIssue}
                disabled={!status || !textContent}
              >
                Update
              </LoadingButton>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default IssueDetailModal;
