import { Box, CardMedia, CircularProgress, Fab } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/CloudUpload";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import {
  errorToast,
  handleError,
  successToast,
} from "../../../../utils/common";

const UploadConsultationBill = ({
  activeOrderId,
  activePartner,
  activePartnerOrderId,
}: any) => {
  const [orderData, setOrderData] = useState<any>(null);
  const privateInstance = useAxiosInstance();
  const [loading, setLoading] = useState(false);

  const getOrderByPartnerOrderId = async () => {
    setOrderData([]);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (activePartner && activePartnerOrderId) {
      getOrderByPartnerOrderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileChange = (e: any) => {
    handleFileUpload(e.target.files);
  };

  const handleFileUpload = async (files: any) => {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("provider_platform", "dashboard");
      setLoading(true);
      try {
        const result = await privateInstance.post(
          `${API_VERSION.V1}/orders/invoice-upload?order_id=${activeOrderId}`,
          formData
        );
        setLoading(false);
        if (result?.data?.status === API_RESULT.SUCCESS) {
          getOrderByPartnerOrderId();
          successToast(result.data.message);
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <label htmlFor="upload-file">
          <input
            style={{ display: "none" }}
            id="upload-file"
            name="upload-file"
            type="file"
            onChange={handleFileChange}
            multiple
          />
          <Fab
            color="primary"
            size="small"
            component="span"
            aria-label="add"
            variant="extended"
            sx={{ mt: 1.5 }}
          >
            <UploadIcon sx={{ mr: 1 }} /> Choose Files
          </Fab>
        </label>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: 3,
        }}
      >
        {orderData?.invoice_urls?.length > 0 &&
          orderData.invoice_urls.map((x: string, i: number) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: 1.5,
                }}
              >
                <CardMedia
                  sx={{
                    height: 120,
                    width: 120,
                    maxHeight: 200,
                    maxWidth: 200,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  alt="prescription"
                  onClick={() => (x ? window.open(x) : null)}
                  src={x}
                  component="img"
                  onError={(e: any) => {
                    e.target.src =
                      "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                  }}
                />
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default UploadConsultationBill;
