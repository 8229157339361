import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";

const SearchProviderModal = ({
  searchProviderModal,
  setSearchProviderModal,
  providersList,
  setProvidersList,
  selectedProviders,
  setSelectedProviders,
}: any) => {
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [providerData, setProviderData] = useState<any>({});
  const [searchBy, setSearchBy] = useState("gst_no");
  const [gstNo, setGstNo] = useState("");

  const getProvider = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/location/search?by=${searchBy}&value=${
          searchBy === "gst_no" ? gstNo : mobile
        }`
      );
      if (Object.keys(result.data.response).length !== 0) {
        setProviderData(result.data.response);
      } else {
        errorToast(result.data.message);
        setProviderData({});
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={searchProviderModal}
      onClose={() => setSearchProviderModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setSearchProviderModal(false)}
      >
        Search Provider
      </CustomDialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <FormControl sx={{ mt: 1.5 }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                setSearchBy(e.target.value);
                setProviderData({});
              }}
            >
              <FormControlLabel
                value="gst_no"
                control={<Radio checked={searchBy === "gst_no"} />}
                label="GST NO"
              />
              <FormControlLabel
                value="whatsapp_number"
                control={<Radio />}
                label="Whatsapp Number"
              />
            </RadioGroup>
          </FormControl>

          {searchBy === "whatsapp_number" ? (
            <>
              <TextField
                id="mobile"
                label="Whatsapp Number"
                size="small"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                helperText={
                  mobile.length > 1 && mobile.length < 10
                    ? "Please enter 10 digit number"
                    : ""
                }
                error={mobile.length > 1 && mobile.length < 10 ? true : false}
                inputProps={{
                  maxLength: 10,
                }}
                sx={{
                  mt: 1.5,
                  mr: { xs: 0, md: 1 },
                  width: { xs: "100%", md: "50%" },
                }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                disabled={mobile.length !== 10}
                sx={{ mt: 2 }}
                onClick={getProvider}
              >
                Search
              </LoadingButton>
            </>
          ) : (
            <>
              <TextField
                id="gst_no"
                label="GST Number"
                size="small"
                value={gstNo}
                onChange={(e) => setGstNo(e.target.value)}
                helperText={
                  gstNo.length > 1 && gstNo.length < 15
                    ? "Please enter 15 digit GST number"
                    : ""
                }
                error={gstNo.length > 1 && gstNo.length < 15 ? true : false}
                inputProps={{
                  maxLength: 15,
                }}
                sx={{
                  mt: 1.5,
                  mr: { xs: 0, md: 1 },
                  width: { xs: "100%", md: "50%" },
                }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                loading={loading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                disabled={gstNo.length !== 15}
                sx={{ mt: 2 }}
                onClick={getProvider}
              >
                Search
              </LoadingButton>
            </>
          )}
          {Object.keys(providerData).length > 0 && (
            <Box
              sx={{
                mt: 3,
                textAlign: "center",
                width: { xs: "100%", md: "50%" },
              }}
            >
              <Card>
                <CardContent sx={{ textAlign: "left" }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="h6">{providerData?.name}</Typography>
                      <Typography variant="body2" color="#718096">
                        Mobile: {providerData?.mobile}
                      </Typography>
                      <Typography variant="body2" color="#718096">
                        Address: {providerData?.formatted_address}
                      </Typography>
                      <Typography variant="body2" color="#718096">
                        Pin Code: {providerData?.pin_code}
                      </Typography>
                      <Typography variant="body2" color="#718096">
                        GST No.: {providerData?.gst_no}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const found = providersList.some(
                          (el: any) => el.mobile === providerData.mobile
                        );
                        if (!found) {
                          setProvidersList([...providersList, providerData]);
                          //this component is used only for medicines orders
                          setSelectedProviders([
                            ...selectedProviders,
                            providerData,
                          ]);
                        } else {
                          errorToast(
                            "Provider with same mobile number already exist. Please select from list."
                          );
                        }
                        setSearchProviderModal(false);
                      }}
                    >
                      Add
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SearchProviderModal;
