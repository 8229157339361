import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../../components/UI/tabPanel";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { a11yProps, errorToast, handleError } from "../../utils/common";
import LogisticsReqTable from "./logisticsReqTable";

const ShowLogisticsReqTab = () => {
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const activeTab: any = params.get("subTab");
  const [value, setValue] = React.useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const privateInstance = useAxiosInstance();
  const [logisticsRequests, setLogisticsRequests] = React.useState<any>([]);

  const getLogisticsHistory = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/logistics/list`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setLogisticsRequests(result.data.response.tasks);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      setLogisticsRequests([]);
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogisticsHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="In-Process"
            {...a11yProps(0)}
            onClick={() => navigate(`/logistics?tab=2&subTab=1`)}
          />
          <Tab
            label="Cancelled"
            {...a11yProps(1)}
            onClick={() => navigate(`/logistics?tab=2&subTab=2`)}
          />
          <Tab
            label="Delivered"
            {...a11yProps(2)}
            onClick={() => navigate(`/logistics?tab=2&subTab=3`)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LogisticsReqTable
          data={logisticsRequests.filter(
            (req: any) => req.state !== "cancelled" && req.state !== "delivered"
          )}
          updateFn={getLogisticsHistory}
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LogisticsReqTable
          data={logisticsRequests.filter(
            (req: any) => req.state === "cancelled"
          )}
          updateFn={getLogisticsHistory}
          isLoading={loading}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LogisticsReqTable
          data={logisticsRequests.filter(
            (req: any) => req.state === "delivered"
          )}
          updateFn={getLogisticsHistory}
          isLoading={loading}
        />
      </TabPanel>
    </Box>
  );
};

export default ShowLogisticsReqTab;
