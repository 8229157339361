import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import PartnersAutocomplete from "../../components/Common/partnersAC";
import ConfirmModal from "../../components/Helpers/confirmModal";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import FindUpiQR from "../../components/ManageOrder/GroupOrders/Common/findUpiByQR";
import VerifyUpi from "../../components/ManageOrder/GroupOrders/Common/verifyUpi";
import { valueLimits } from "../../constants";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { generalPaymentSchema } from "../../schemas/payment";
import {
  errorToast,
  handleError,
  textCapitalize,
  validateFloatNum,
  validateIntNum,
  successToast,
  wordify,
} from "../../utils/common";
import AuthenticatorModal from "./authenticatorModal";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import PaymentDataRow from "../../components/ManageOrder/GroupOrders/Payment/PaymentStatus/paymentDataRow";

const CreateReqTab = () => {
  const user: any = useUserInfo();
  const privateInstance = useAxiosInstance();
  const [beneficiaryList, setBeneficiaryList] = useState<Array<string>>([]);
  const [filterPaymentModes, setFilterPaymentModes] = useState<Array<string>>([
    "NEFT",
    "IMPS",
    "RTGS",
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [providerLoading, setProviderLoading] = useState<boolean>(false);
  const [upiData, setUpiData] = useState<any>({});
  const [authenticatorModal, setAuthenticatorModal] = useState(false);
  const [providerData, setProviderData] = useState<any>({});
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isValidOrder, setIsValidOrder] = useState<boolean | null>(null);
  const [orderData, setOrderData] = useState<any>(null);
  const [loadingOrderdata, setLoadingOrderdata] = useState<boolean>(false);

  const getBeneficiaryList = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/beneficiary-list`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setBeneficiaryList(result.data.response);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getProvider = async (providerNum: number, setFieldValue: any) => {
    setProviderLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/provider-account-details/${providerNum}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProviderData(result.data.response);
        setFieldValue("account_no", result.data.response.account_number);
        setFieldValue("account_name", result.data.response.account_name);
        setFieldValue("ifsc_code", result.data.response.account_ifsc_code);
      } else {
        errorToast(result.data.message);
        setFieldValue("account_no", "");
        setFieldValue("account_name", "");
        setFieldValue("ifsc_code", "");
      }
    } catch (err) {
      handleError(err);
    } finally {
      setProviderLoading(false);
    }
  };

  const handleSubmit = async (values: any, resetForm: any) => {
    // console.log("values", values);
    let payload: any = {
      payment_to: values?.payment_to,
      payment_type: values?.payment_mode,
      amount: values?.amount,
      notes: values?.payment_to ? values.payment_to + "-transfer" : "",
      otp: values?.otp,
      created_by: user?.user_id,
      location: `${latitude},${longitude}`,
    };

    if (
      values?.payment_to?.toLowerCase() === "provider" ||
      values?.payment_to?.toLowerCase() === "patient" ||
      values?.payment_to?.toLowerCase() === "ipd"
    ) {
      payload = {
        ...payload,
        extra_1: values?.partner_order_id,
        extra_2: values?.provider_id?.toLowerCase(),
        extra_3: user?.user_id,
        extra_4: "",
      };

      if (values?.payment_mode === "UPI") {
        payload = {
          ...payload,
          upi_id: upiData?.upi_id,
          account_holder_mobile: values?.mobile_number,
          location_id: "",
          notes:
            values?.provider_id?.toLowerCase() +
            "-" +
            values?.partner_order_id +
            "-" +
            values?.account_holder_name,
        };
      } else {
        payload = {
          ...payload,
          account_number: values?.account_no,
          account_holder_name: values?.account_name,
          account_ifsc_code: values?.ifsc_code,
          account_holder_mobile: values?.whatsapp_number,
          location_id: providerData?.location_id,
          extra_4: providerData?.location_id,
          notes:
            values?.provider_id?.toLowerCase() +
            "-" +
            values?.partner_order_id +
            "-" +
            values?.account_name,
        };
      }
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/create-payment-request`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Request Successful");
        resetForm({});
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  function showPosition(position: any) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  useEffect(() => {
    getBeneficiaryList();
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrderByPartnerOrderId = async (
    partner: string,
    partnerOrderId: string
  ) => {
    if (!partner || !partnerOrderId) {
      return;
    }
    setLoadingOrderdata(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          partner
        )?.toLowerCase()}/${partnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
        setIsValidOrder(true);
      } else {
        setOrderData(null);
        setIsValidOrder(false);
      }
    } catch (err) {
      setOrderData(null);
      setIsValidOrder(null);
      handleError(err);
    } finally {
      setLoadingOrderdata(false);
    }
  };

  return (
    <ErrorBoundary>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Card sx={{ p: 3, width: { xs: "100%", md: "50%" } }}>
          <Formik
            initialValues={{
              payment_to: "",
              payment_mode: "",
              provider_id: "",
              partner_order_id: "",
              account_holder_name: "",
              whatsapp_number: "",
              mobile_number: "",
              amount: "",
              notes: "",
              otp: "",
              account_no: "",
              account_name: "",
              ifsc_code: "",
            }}
            onSubmit={(values: any, actions: any) => {
              actions.setSubmitting(false);
              if (
                values?.payment_to?.toLowerCase() === "provider" ||
                values?.payment_to?.toLowerCase() === "patient" ||
                values?.payment_to?.toLowerCase() === "ipd"
              ) {
                if (!isValidOrder) {
                  //for medpay patient allow invalid POI
                  if (
                    values?.provider_id?.toLowerCase() === "medpay" &&
                    values.payment_to?.toLowerCase() === "patient" &&
                    Number(values.amount) <= 2500
                  ) {
                    setShowConfirmationModal(true);
                    return;
                  } else {
                    errorToast("Please enter valid order details");
                    return;
                  }
                }
              }
              setShowConfirmationModal(true);
            }}
            validationSchema={generalPaymentSchema}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                isSubmitting,
                setFieldValue,
                resetForm,
                setTouched,
              } = props;
              return (
                <Form>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mt: 1.5 }}
                    error={
                      errors.payment_to && touched.payment_to ? true : false
                    }
                  >
                    <InputLabel required id="payment-to-label">
                      Payment To
                    </InputLabel>
                    <Select
                      labelId="payment-to-label"
                      name="payment_to"
                      id="payment_to"
                      label="Payment To"
                      value={values?.payment_to}
                      onChange={(e: SelectChangeEvent) => {
                        resetForm({});
                        setFieldValue("payment_to", e.target.value);
                        if (
                          e?.target?.value?.toLowerCase() === "provider" ||
                          e?.target?.value?.toLowerCase() === "patient" ||
                          values?.payment_to?.toLowerCase() === "ipd"
                        ) {
                          if (
                            filterPaymentModes[
                              filterPaymentModes?.length - 1
                            ] !== "UPI"
                          ) {
                            setFilterPaymentModes([
                              ...filterPaymentModes,
                              "UPI",
                            ]);
                          }
                        } else {
                          setFilterPaymentModes(
                            filterPaymentModes.filter((x) => x !== "UPI")
                          );
                        }
                      }}
                    >
                      {beneficiaryList.map((x: string) => {
                        return (
                          <MenuItem key={x} value={x}>
                            {textCapitalize(x)?.split("_").join(" ")}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.payment_to && touched.payment_to && (
                      <FormHelperText>{errors.payment_to}</FormHelperText>
                    )}
                  </FormControl>

                  {(values?.payment_to?.toLowerCase() === "provider" ||
                    values?.payment_to?.toLowerCase() === "patient" ||
                    values?.payment_to?.toLowerCase() === "ipd") && (
                    <>
                      <PartnersAutocomplete
                        providerId={values?.provider_id || null}
                        validate="true"
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        disableField={false}
                      />
                      <TextField
                        fullWidth
                        id="partner_order_id"
                        label="Partner Order ID"
                        size="small"
                        value={values?.partner_order_id || ""}
                        onChange={(e) =>
                          setFieldValue(
                            "partner_order_id",
                            e.target.value?.trim()
                          )
                        }
                        onBlur={(e) => {
                          handleBlur(e);
                          getOrderByPartnerOrderId(
                            values.provider_id,
                            values.partner_order_id
                          );
                        }}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        helperText={
                          errors.partner_order_id && touched.partner_order_id
                            ? errors.partner_order_id
                            : ""
                        }
                        error={
                          errors.partner_order_id && touched.partner_order_id
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {isValidOrder === null ? (
                                <Tooltip title="Enter valid order details">
                                  <InfoIcon color="info" />
                                </Tooltip>
                              ) : isValidOrder === true ? (
                                <Tooltip title="Valid Order Details">
                                  <CheckIcon color="success" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Invalid Order Details">
                                  <CloseIcon color="error" />
                                </Tooltip>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}

                  <FormControl
                    fullWidth
                    size="small"
                    sx={{ mt: 1.5 }}
                    error={
                      errors.payment_mode && touched.payment_mode ? true : false
                    }
                  >
                    <InputLabel required id="payment-mode-label">
                      Payment Mode
                    </InputLabel>
                    <Select
                      labelId="payment-mode-label"
                      name="payment_mode"
                      id="payment_mode"
                      label="Payment Mode"
                      value={values?.payment_mode}
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("payment_mode", e.target.value);
                        //reset other payment fields
                        setFieldValue("upi_id", "");
                        setUpiData({ ...upiData, upi_id: "" });
                        setFieldValue("account_holder_name", "");
                        setFieldValue("mobile_number", "");
                        setFieldValue("whatsapp_number", "");
                        setFieldValue("account_no", "");
                        setFieldValue("account_name", "");
                        setFieldValue("ifsc_code", "");
                        //set touched to false
                        setTouched({}, false);
                      }}
                    >
                      {filterPaymentModes.map((x: string) => {
                        return (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.payment_mode && touched.payment_mode && (
                      <FormHelperText>{errors.payment_mode}</FormHelperText>
                    )}
                  </FormControl>

                  {(values?.payment_to?.toLowerCase() === "provider" ||
                    values?.payment_to?.toLowerCase() === "patient" ||
                    values?.payment_to?.toLowerCase() === "ipd") && (
                    <>
                      {values?.payment_mode &&
                        values?.payment_mode === "UPI" && (
                          <Box sx={{ mt: 1.5 }}>
                            <FindUpiQR
                              qrData={upiData}
                              setQrData={setUpiData}
                              setFieldValue={setFieldValue}
                            />
                            <VerifyUpi
                              upiData={upiData}
                              setUpiData={setUpiData}
                              setFieldValue={setFieldValue}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                            {values?.account_holder_name && (
                              <>
                                <TextField
                                  fullWidth
                                  id="account_holder_name"
                                  label="UPI Name"
                                  size="small"
                                  value={values?.account_holder_name || ""}
                                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                                  disabled
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.account_holder_name &&
                                    touched.account_holder_name
                                      ? errors.account_holder_name
                                      : ""
                                  }
                                  error={
                                    errors.account_holder_name &&
                                    touched.account_holder_name
                                      ? true
                                      : false
                                  }
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  id="mobile_number"
                                  label="Mobile Number"
                                  size="small"
                                  value={values?.mobile_number || ""}
                                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      "mobile_number",
                                      validateIntNum(
                                        e.target.value,
                                        valueLimits.MOBILE
                                      )
                                    )
                                  }
                                />
                              </>
                            )}
                          </Box>
                        )}

                      {values?.payment_mode &&
                        values?.payment_mode !== "UPI" && (
                          <Box>
                            <Stack direction="row" alignItems="baseline">
                              <TextField
                                fullWidth
                                id="whatsapp_number"
                                label="Whatsapp Number"
                                size="small"
                                value={values?.whatsapp_number || ""}
                                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "whatsapp_number",
                                    validateIntNum(
                                      e.target.value,
                                      valueLimits.MOBILE
                                    )
                                  );
                                  if (values?.account_no) {
                                    setFieldValue("account_no", "");
                                    setFieldValue("account_name", "");
                                    setFieldValue("ifsc_code", "");
                                  }
                                }}
                                onBlur={handleBlur}
                                helperText={
                                  errors.whatsapp_number &&
                                  touched.whatsapp_number
                                    ? errors.whatsapp_number
                                    : ""
                                }
                                error={
                                  errors.whatsapp_number &&
                                  touched.whatsapp_number
                                    ? true
                                    : false
                                }
                                InputLabelProps={{
                                  required: true,
                                }}
                              />
                              {values?.payment_to?.toLowerCase() ===
                                "provider" && (
                                <LoadingButton
                                  type="button"
                                  color="primary"
                                  variant="contained"
                                  loading={providerLoading}
                                  disabled={!values?.whatsapp_number}
                                  loadingIndicator={
                                    <CircularProgress
                                      size={20}
                                      sx={{ color: "#fff" }}
                                    />
                                  }
                                  onClick={() =>
                                    getProvider(
                                      values.whatsapp_number,
                                      setFieldValue
                                    )
                                  }
                                  sx={{ minWidth: "180px" }}
                                >
                                  Get Provider
                                </LoadingButton>
                              )}
                            </Stack>

                            {/* v1: show bank account after account number is fetched --> (values?.payment_to?.toLowerCase() === "provider" && values?.account_no)  */}
                            {(values?.payment_to?.toLowerCase() ===
                              "provider" ||
                              values?.payment_to?.toLowerCase() === "patient" ||
                              values?.payment_to?.toLowerCase() === "ipd") && (
                              <>
                                <TextField
                                  fullWidth
                                  id="account_no"
                                  label="Account Number"
                                  size="small"
                                  value={values?.account_no || ""}
                                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                                  onChange={(e: any) =>
                                    setFieldValue("account_no", e.target.value)
                                  }
                                  disabled={
                                    values?.payment_to?.toLowerCase() ===
                                    "provider"
                                  }
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.account_no && touched.account_no
                                      ? errors.account_no
                                      : ""
                                  }
                                  error={
                                    errors.account_no && touched.account_no
                                      ? true
                                      : false
                                  }
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  id="account_name"
                                  label="Account Name"
                                  size="small"
                                  value={values?.account_name || ""}
                                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                                  onChange={(e: any) =>
                                    setFieldValue(
                                      "account_name",
                                      e.target.value
                                    )
                                  }
                                  disabled={
                                    values?.payment_to?.toLowerCase() ===
                                    "provider"
                                  }
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.account_name && touched.account_name
                                      ? errors.account_name
                                      : ""
                                  }
                                  error={
                                    errors.account_name && touched.account_name
                                      ? true
                                      : false
                                  }
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                />

                                <TextField
                                  fullWidth
                                  id="ifsc_code"
                                  label="IFSC"
                                  size="small"
                                  value={values?.ifsc_code || ""}
                                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                                  onChange={(e: any) =>
                                    setFieldValue("ifsc_code", e.target.value)
                                  }
                                  disabled={
                                    values?.payment_to?.toLowerCase() ===
                                    "provider"
                                  }
                                  onBlur={handleBlur}
                                  helperText={
                                    errors.ifsc_code && touched.ifsc_code
                                      ? errors.ifsc_code
                                      : ""
                                  }
                                  error={
                                    errors.ifsc_code && touched.ifsc_code
                                      ? true
                                      : false
                                  }
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                />
                              </>
                            )}
                          </Box>
                        )}
                    </>
                  )}

                  <TextField
                    fullWidth
                    id="amount"
                    label="Amount"
                    size="small"
                    value={values?.amount || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e: any) =>
                      setFieldValue(
                        "amount",
                        validateFloatNum(
                          e.target.value,
                          valueLimits.PAYMENT_AMOUNT
                        )
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.amount && touched.amount ? errors.amount : ""
                    }
                    error={errors.amount && touched.amount ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />

                  <TextField
                    fullWidth
                    id="otp"
                    label="OTP"
                    size="small"
                    value={values?.otp || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e: any) =>
                      setFieldValue(
                        "otp",
                        validateIntNum(e.target.value, valueLimits.PIN)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={errors.otp && touched.otp ? errors.otp : ""}
                    error={errors.otp && touched.otp ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />

                  <Stack direction="row" justifyContent="center" mt={3}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => setAuthenticatorModal(true)}
                    >
                      Add Authenticator
                    </Button>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={loading}
                      disabled={isSubmitting}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      sx={{ ml: 1 }}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                  {showConfirmationModal && (
                    <ConfirmModal
                      showConfirmationModal={showConfirmationModal}
                      setShowConfirmationModal={setShowConfirmationModal}
                      callback={() => handleSubmit(values, resetForm)}
                      message={`Are you sure you want to transfer Rs.${new Intl.NumberFormat(
                        "en-IN"
                      ).format(values.amount)} (${wordify(
                        values.amount
                      )}) rupees only ?`}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Card>
        {orderData && (
          <Card
            sx={{
              p: 3,
              ml: { xs: 0, md: 2 },
              width: { xs: "100%", md: "50%" },
            }}
          >
            {loadingOrderdata ? (
              <CircularProgress />
            ) : (
              <>
                <PaymentDataRow
                  dataTitle="Bill Amount"
                  value={orderData?.invoice_details?.bill_amount}
                />
                <PaymentDataRow
                  dataTitle="Discount Amount"
                  value={orderData?.invoice_details?.discount}
                />
                <PaymentDataRow
                  dataTitle="Final Payout Amount"
                  value={orderData?.invoice_details?.final_payout_amount}
                />
                {!orderData?.payment_credit_status ||
                orderData?.payment_credit_status?.toLowerCase() ===
                  PAYMENT_CREDIT_STATUS.NA ? (
                  <Typography color="green" variant="body2" mt={2}>
                    {" "}
                    Note: Payment is not initated yet, please continue to pay.{" "}
                  </Typography>
                ) : null}

                {orderData?.payment_credit_status?.toLowerCase() ===
                PAYMENT_CREDIT_STATUS.PAID ? (
                  <Typography color="red" variant="body2" mt={2}>
                    Note: Payment of Rs.
                    {orderData?.invoice_details?.final_payout_amount} was{" "}
                    <strong>paid</strong>, please re-check to avoid any double
                    payment.
                  </Typography>
                ) : null}

                {orderData?.payment_credit_status?.toLowerCase() ===
                PAYMENT_CREDIT_STATUS.FAILED ? (
                  <Typography color="green" variant="body2" mt={2}>
                    Note: Payment of Rs.
                    {orderData?.invoice_details?.final_payout_amount} was{" "}
                    <strong>failed</strong>, please make sure no offline payment
                    been done to avoid any double payment.
                  </Typography>
                ) : null}

                {orderData?.payment_credit_status?.toLowerCase() ===
                  PAYMENT_CREDIT_STATUS.PENDING ||
                orderData?.payment_credit_status?.toLowerCase() ===
                  PAYMENT_CREDIT_STATUS.PROCESSING ? (
                  <Typography color="orange" variant="body2" mt={2}>
                    Note: Payment of Rs.
                    {orderData?.invoice_details?.final_payout_amount} was{" "}
                    <strong>{orderData?.payment_credit_status}</strong>, please
                    wait until its completed to avoid any double payment.
                  </Typography>
                ) : null}
              </>
            )}
          </Card>
        )}
      </Box>
      {authenticatorModal && (
        <AuthenticatorModal
          authenticatorModal={authenticatorModal}
          setAuthenticatorModal={setAuthenticatorModal}
        />
      )}
    </ErrorBoundary>
  );
};

export default CreateReqTab;
