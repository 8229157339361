import { Box, Divider, Stack, Tab, Tabs, TextField } from "@mui/material";
import React, { useState } from "react";
// import AssignmentReassignmentsMetrics from "./AssignmentMetrics";
// import DeliveryMetrics from "./DeliveryMetrics";
import OrderMetrics from "./OrderMetrics";
// import OrdersTAT from "./OrdersTATMetrics";
// import PharmacyAndPinCount from "./PharmacyAndPinCount";
import TilesView from "./TilesView";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { errorToast } from "../../utils/common";
import { addDays, subDays } from "date-fns";
import TabPanel from "../../components/UI/tabPanel";
import ComparisonMetrics from "./ComparisonMetrics";
import SingleUnitMetrics from "./SingleUnitMetrics";
import PartnerConfirmationMetrics from "./PartnerConfirmationMetrics";
import AutoAssignmentMetrics from "./AutoAssignmentMetrics";
import WeeklyMetrics from "./WeeklyMetrics";

const HorizontalRuleWithLabel = ({ val, color }: any) => {
  return (
    <h4
      style={{
        width: "100%",
        textAlign: "center",
        borderBottom: `1px solid ${color}`,
        lineHeight: "0.1em",
        margin: "40px 0",
        textTransform: "uppercase",
      }}
    >
      <span
        style={{
          background: "#fff",
          padding: "0 10px",
          color: color,
        }}
      >
        {val}
      </span>
    </h4>
  );
};

const MetricsIndex = () => {
  const [startDate, setStartDate] = useState<any>(subDays(new Date(), 6));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <Stack mb={3} direction="row" justifyContent="center">
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="end"
          sx={{ mb: { xs: 1.5, md: 0 } }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(newValue: Date | null) => {
                setStartDate(newValue);
              }}
              maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  name="start_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(newValue: Date | null) => {
                if (newValue && startDate) {
                  if (newValue < startDate) {
                    errorToast("End date should be after the start date");
                    return;
                  }
                }
                setEndDate(newValue);
              }}
              minDate={addDays(startDate, 1)}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5, mx: { xs: 0, md: 1.5 } }}
                  name="end_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Stack>

      <TilesView startDate={startDate} endDate={endDate} />
      <Box mt={3}>
        <Divider />
      </Box>

      <Stack mt={3}>
        <Tabs
          value={tabValue}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            setTabValue(newValue);
          }}
          centered
          sx={{ mt: 3 }}
        >
          <Tab label="Overview" />
          <Tab label="Weekly Metrics" />
          <Tab label="Assignment" />
          <Tab label="TAT" />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <OrderMetrics startDate={startDate} endDate={endDate} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <WeeklyMetrics />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <HorizontalRuleWithLabel val="Agent Assignment" color="#58508D" />
          <AutoAssignmentMetrics
            startDate={startDate}
            endDate={endDate}
            metricType="agent_assignment_v2"
            graphTitle="Agent Assigment (%)"
            tooltipText={
              <>
                <p>Auto Assigned: orders auto assigned to agents</p>
                <p>Reassigned: orders where agent re-assigned</p>
              </>
            }
          />
          <HorizontalRuleWithLabel val="Pharmacy Assignment" color="#58508D" />
          <Stack direction="row">
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="pharmacy_assignment_v2"
              responseKey="pharmacy_assignment"
              countOfKey="order_count"
              graphTitle="Delivered After Pharmacy Assigment (#)"
              tooltipText={
                <>
                  <p>Order fulfilled from auto(App) vs manual(Dashboard)</p>
                </>
              }
            />
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="overall_call_metrics_v2"
              responseKey="overall_call_metrics"
              countOfKey="call_order_count"
              graphTitle="Call Initiated Orders (#)"
              tooltipText={
                <>
                  <p>
                    Number of orders Agents calls auto(App) vs manual(Dashboard)
                  </p>
                </>
              }
            />
          </Stack>
          <br />

          <Stack direction="row">
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="pharmacy_reassignment_v2"
              responseKey="pharmacy_reassignment"
              countOfKey="order_count"
              graphTitle="Delivered After Pharmacy Reassigment (#)"
              tooltipText={
                <>
                  <p>Order fulfilled from auto(App) vs manual(Dashboard)</p>
                </>
              }
            />
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="mrp_fill_percentage_v2"
              responseKey="mrp_fill_count"
              countOfKey="order_count"
              graphTitle="MRP Fill (%)"
              tooltipText={
                <>
                  <p>MRP filled from auto(App) vs manual(Dashboard)</p>
                </>
              }
            />
          </Stack>
          <br />

          <Stack direction="row">
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="delivery_otp_validation_percentage_v2"
              responseKey="delivery_otp_validation_count"
              countOfKey="order_count"
              graphTitle="Delivery OTP Validation (%)"
              tooltipText={
                <>
                  <p>OTP validated from auto(App) vs manual(Dashboard)</p>
                </>
              }
            />
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="delivery_in_time_metric_v2"
              responseKey="delivery_in_time_metric"
              countOfKey="on_time_delivery_count"
              graphTitle="Delivery On Time (%)"
              tooltipText={
                <>
                  <p>Delivery on time from auto(App) vs manual(Dashboard)</p>
                </>
              }
            />
          </Stack>
          <br />

          <Stack direction="row">
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="pharmacy_utilization_v2"
              responseKey="pharmacy_utilisation_count"
              countOfKey="pharmacy_count"
              graphTitle="Pharmacy Utilization (#)"
              legendText="Total Unique Pharmacy"
              tooltipText={
                <>
                  <p>
                    Number of unique pharmacies utilised, auto(App) vs
                    manual(Dashboard){" "}
                  </p>
                </>
              }
            />
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="same_pin_code_service_v2"
              responseKey="same_pin_delivery_count"
              countOfKey="order_count"
              graphTitle="Same Pincode Serviceability (#)"
              tooltipText={
                <>
                  <p>
                    Order fulfilled in same pharmacy pin code, auto(App) vs
                    manual(Dashboard)
                  </p>
                </>
              }
            />
          </Stack>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <HorizontalRuleWithLabel val="Overall TAT" color="#58508D" />
          <Box textAlign="center">
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="overall_tat_v2"
              responseKey="overall_tat"
              countOfKey="tat"
              graphTitle="Total TAT(h.m)"
              tooltipText={
                <>
                  <p>
                    Order Creation to Fulfilled TAT from auto(App) vs
                    manual(Dashboard)
                  </p>
                </>
              }
            />
          </Box>
          <HorizontalRuleWithLabel val="MedPay TAT" color="#58508D" />
          <Stack direction="row">
            <SingleUnitMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="pharmacy_assignment_tat_v2"
              responseKey="pharmacy_assignment_tat"
              countOfKey="tat"
              graphTitle="Pharmacy Assignment TAT(h.m)"
              legendText="TAT"
              tooltipText={
                <>
                  <p>
                    Average time for assigning a pharmacy for delivered orders
                  </p>
                </>
              }
            />
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="medpay_tat_v2"
              responseKey="medpay_tat"
              countOfKey="tat"
              graphTitle="MedPay TAT(h.m)"
              tooltipText={
                <>
                  <p>Difference of Total TAT and Partner TAT</p>
                </>
              }
            />
          </Stack>

          <HorizontalRuleWithLabel val="Pharmacy TAT" color="#58508D" />
          <Stack direction="row">
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="pharmacy_mrp_fill_tat_v2"
              responseKey="pharmacy_mrp_fill_tat"
              countOfKey="tat"
              graphTitle="MRP Fill TAT (h.m)"
              tooltipText={
                <>
                  <p>Time taken to fill MRP after re/assignment</p>
                </>
              }
            />
            <ComparisonMetrics
              startDate={startDate}
              endDate={endDate}
              metricType="out_for_delivery_tat_v2"
              responseKey="out_for_delivery_tat"
              countOfKey="tat"
              graphTitle="Out For Delivery TAT(h.m)"
              tooltipText={
                <>
                  <p>
                    Time taken by pharmacy to out for delivery after payment
                    confirmation.
                  </p>
                  <p>
                    Formula: package_picked_at_date_time -
                    payment_confirmed_at_date_time
                  </p>
                </>
              }
            />
          </Stack>

          <HorizontalRuleWithLabel val="Partner TAT" color="#58508D" />
          <PartnerConfirmationMetrics
            startDate={startDate}
            endDate={endDate}
            metricType="partner_confirmation_tat_v2"
            graphTitle="Partner Confirmation TAT (h.m)"
            tooltipText={
              <>
                <p>
                  Modification Confirmation: Average time taken by partner for
                  accepting an order{" "}
                </p>
                <p>
                  Payment Confirmation: Average time taken by partner for
                  payment confirmation
                </p>
              </>
            }
          />
        </TabPanel>

        {/* <HorizontalRuleWithLabel val="Orders TAT" color="#02C382" />
        <OrdersTAT startDate={startDate} endDate={endDate} />
        <HorizontalRuleWithLabel val="Assignment Metrics" color="#FD5E71" />
        <AssignmentReassignmentsMetrics
          startDate={startDate}
          endDate={endDate}
        />
        <HorizontalRuleWithLabel val="Pharmacy and Pin Count" color="#00ABAE" />
        <PharmacyAndPinCount startDate={startDate} endDate={endDate} />
        <HorizontalRuleWithLabel val="Delivery Metrics" color="#FD9366" />
        <DeliveryMetrics startDate={startDate} endDate={endDate} /> */}
      </Stack>
    </>
  );
};

export default MetricsIndex;
