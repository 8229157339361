import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import CustomDialogTitle from "./customDialogTitle";

const ImageModal = ({
  showImageModal,
  setShowImageModal,
  imageUrl,
  title,
}: any) => {
  return (
    <Dialog
      open={showImageModal}
      onClose={() => setShowImageModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowImageModal(false)}
      >
        {title}
      </CustomDialogTitle>
      <DialogContent>
        <img
          src={imageUrl}
          alt={title}
          style={{ height: "100%", width: "100%" }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
