export enum ORDER_TYPE {
  CONSULTATION = "consultation",
  MEDICINES = "medicines",
  DIAGNOSTICS = "diagnostics",
  IPD = "ipd",
}

export enum ORDER_STATUS {
  PENDING = "pending",
  PARTNER_PENDING = "partner_pending",
  ACCEPTED = "accepted",
  INVOICE_GENERATED = "invoice_generated",
  PAYMENT_PENDING = "payment_pending",
  PAYMENT_COLLECTED = "payment_collected",
  BOOKING_CONFIRMED = "booking_confirmed",
  BOOKING_COMPLETED = "booking_completed",
  CLAIM_SUBMITTED = "claim_submitted",
  INVOICE_SUBMITTED = "invoice_submitted",
  SAMPLE_COLLECTED = "sample_collected",
  PACKAGE_PICKED = "package_picked",
  PACKAGE_DELIVERED = "package_delivered",
  PRESCRIPTION_UPLOADED = "prescription_uploaded",
  CANCELLED = "cancelled",
  REPORTS_GENERATED = "reports_generated",
}

export enum ORDER_PLATFORM {
  PARTNER_API = "partner_api",
  ORDER_DASHBOARD = "order_dashboard",
}

export enum LAB_VISIT_TYPE {
  SELF_VIST = "self_visit",
  HOME_VISIT = "home_visit",
}

export enum CONFIRMATION_TYPE {
  COMPLETE = "complete",
  PARTIAL = "partial",
}

export enum CONSULTATION_TYPE {
  ONLINE = "online",
  OFFLINE = "offline",
}

export enum PAYMENT_OPTIONS {
  CARD = "card",
  QR = "qr",
  VOUCHER = "voucher",
  INTERNET_BANKING = "internet banking",
  OPD_WALLET = "opd wallet",
  OTHER = "other",
}

export enum MEDICINE_CHANGE_TYPE {
  NOT_AVAILABLE = "not_available",
  ALTERNATIVE = "alternative",
}

export enum MEDICINE_AVL_STATUS {
  AVAILABLE = "yes",
  ALTERNATIVE = "alt",
  NOT_AVAILABLE = "no",
}
