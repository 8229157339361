import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import { handleError } from "../../../utils/common";
import OrdersCount from "./OrdersCount";

const orderTypes = ["medicines", "consultation", "diagnostics"];

const OrdersMetrics = ({ startDate, endDate }: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [unitType, setUnitType] = useState<string>("order_count");
  const [creationPlatform, setCreationPlatform] = useState<string>("all");
  const [loading, setLoading] = useState<boolean>(false);

  function getPayload(orderType: string) {
    return {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      order_type: orderType,
      metric_type: "collated_type_metric",
    };
  }

  const getMetricsData = async () => {
    let cancelledMetrics: any = [];
    let deliveredMetrics: any = [];
    let createdMetrics: any = [];

    setLoading(true);
    try {
      const allResults = await Promise.all(
        orderTypes.map((val: string) =>
          instance.post(
            `${config.URL}${API_VERSION.V2}/analytics/ops`,
            getPayload(val)
          )
        )
      );

      if (allResults && allResults.length > 0) {
        allResults.forEach((result: any) => {
          cancelledMetrics = [
            ...cancelledMetrics,
            ...result.data.response.cancelled_metric,
          ];
          deliveredMetrics = [
            ...deliveredMetrics,
            ...result.data.response.delivered_metric,
          ];
          createdMetrics = [
            ...createdMetrics,
            ...result.data.response.created_metric,
          ];
        });

        setMetricData({
          cancelledMetrics,
          deliveredMetrics,
          createdMetrics,
        });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        metricData && (
          <>
            <Stack mb={1.5} direction="row" justifyContent="end">
              <FormControl size="small" sx={{ mt: 1.5 }}>
                <InputLabel id="by-label">By</InputLabel>
                <Select
                  value={unitType}
                  onChange={(e: SelectChangeEvent) => {
                    setUnitType(e.target.value);
                  }}
                  label="By"
                >
                  <MenuItem value="order_count">Count</MenuItem>
                  <MenuItem value="order_value">Value (&#8377;)</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                size="small"
                sx={{ mt: 1.5, ml: 1.5, minWidth: "100px" }}
              >
                <InputLabel id="by-label">Platform</InputLabel>
                <Select
                  value={creationPlatform}
                  onChange={(e: SelectChangeEvent) => {
                    setCreationPlatform(e.target.value);
                  }}
                  label="Platform"
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="order_dashboard">Dashboard</MenuItem>
                  <MenuItem value="partner_api">API</MenuItem>
                  <MenuItem value="dunzo">Dunzo</MenuItem>
                  <MenuItem value="non-dunzo">Non Dunzo</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              <OrdersCount
                data={metricData?.createdMetrics}
                title={`Received Orders ${
                  unitType === "order_count" ? "(#)" : "(Rs.)"
                }`}
                unitType={unitType}
                creationPlatform={creationPlatform}
              />
              <OrdersCount
                data={metricData?.deliveredMetrics}
                title={`Fulfilled Orders ${
                  unitType === "order_count" ? "(#)" : "(Rs.)"
                }`}
                unitType={unitType}
                creationPlatform={creationPlatform}
              />
            </Stack>
            <OrdersCount
              data={metricData?.cancelledMetrics}
              title={`Cancelled Orders ${
                unitType === "order_count" ? "(#)" : "(Rs.)"
              }`}
              unitType={unitType}
              creationPlatform={creationPlatform}
            />
          </>
        )
      )}
    </div>
  );
};

export default OrdersMetrics;
