import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import { USER_ROLE } from "../../enums/common.enum";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import CustomDialogTitle from "../Common/customDialogTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const RowView = ({
  dataTitle,
  value,
}: {
  dataTitle: string;
  value: string;
}) => {
  return (
    <>
      {value && (
        <Typography variant="body2">
          {dataTitle}:{" "}
          {dataTitle.includes("Amount") ? <span>&#8377; {value}</span> : value}
        </Typography>
      )}
    </>
  );
};

interface IInvoiceDetailsProp {
  orderData: any;
  showPayableInfo: boolean;
}

const InvoiceInfo = ({ orderData, showPayableInfo }: IInvoiceDetailsProp) => {
  const {
    invoice_details,
    order_partner,
    order_amount,
    payment_credit_status,
    patient_payable,
    patient_payable_details,
    payer_payable,
  } = orderData;
  const {
    transaction_id,
    payable_amount,
    payment_status,
    timestamp,
    link,
    advance_payment,
  } = patient_payable_details || {};
  const {
    transaction_id: adv_transaction_id,
    payable_amount: adv_payable_amount,
    payment_status: adv_payment_status,
    timestamp: adv_timestamp,
    link: adv_link,
  } = advance_payment || {};
  const user: AuthenticatedUser = useUserInfo();
  const [showPatientPayableData, setShowPatientPayableData] =
    React.useState(false);

  return (
    <>
      <Typography variant="h4">Invoice Details</Typography>
      <Typography>Invoice ID: {invoice_details?.invoice_id}</Typography>
      {order_partner === "dunzo" ? (
        <Typography>Order Amount: &#8377; {order_amount}</Typography>
      ) : (
        <>
          <Typography>
            Bill Amount: &#8377; {invoice_details?.bill_amount}
          </Typography>
          {user?.role !== USER_ROLE.PARTNER ? (
            <>
              <Typography>
                Provider Discount: &#8377; {invoice_details?.discount}
              </Typography>
              {invoice_details?.other_charges &&
                Number(invoice_details.other_charges) !== 0 && (
                  <Typography>
                    Other Charges: &#8377; {invoice_details?.other_charges}
                  </Typography>
                )}
              {invoice_details?.settlement_amount && (
                <Typography>
                  Settlement Amount: &#8377;{" "}
                  {invoice_details?.settlement_amount}
                </Typography>
              )}
            </>
          ) : null}
        </>
      )}
      {user?.role !== USER_ROLE.PARTNER ? (
        <>
          {invoice_details?.final_payout_amount && (
            <Typography>
              Final Payout Amount: &#8377;{" "}
              {invoice_details?.final_payout_amount}
            </Typography>
          )}
          {payment_credit_status ? (
            <Typography>Payment Status: {payment_credit_status}</Typography>
          ) : null}
          {showPayableInfo ? (
            <>
              {payer_payable && Number(payer_payable) > 0 ? (
                <Typography> Payer Payable: &#8377; {payer_payable}</Typography>
              ) : null}
              {patient_payable && Number(patient_payable) > 0 ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ marginTop: patient_payable_details ? "-7px" : 0 }}
                >
                  <Typography>
                    Patient Payable: &#8377; {patient_payable}
                  </Typography>
                  {patient_payable_details ? (
                    <IconButton
                      aria-describedby="patient-payable"
                      color="primary"
                      onClick={() => setShowPatientPayableData(true)}
                    >
                      <ExpandCircleDownIcon />
                    </IconButton>
                  ) : null}
                </Stack>
              ) : null}
              {showPatientPayableData ? (
                <Dialog
                  open={showPatientPayableData}
                  onClose={() => setShowPatientPayableData(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <CustomDialogTitle
                    id="customized-dialog-title"
                    onClose={() => setShowPatientPayableData(false)}
                  >
                    {"Patient Payable Details"}
                  </CustomDialogTitle>
                  <DialogContent sx={{ overflow: "hidden" }}>
                    <RowView
                      dataTitle="Transaction ID"
                      value={transaction_id}
                    />
                    <RowView
                      dataTitle="Payable Amount"
                      value={payable_amount}
                    />
                    <RowView dataTitle="Status" value={payment_status} />
                    <RowView
                      dataTitle="Timestamp"
                      value={timestamp?.split(".")?.[0]}
                    />
                    {link ? (
                      <Box display="flex">
                        <Typography variant="body2">Link: &nbsp; </Typography>
                        <Link
                          href={link}
                          target="_blank"
                          sx={{ fontSize: "14px" }}
                        >
                          {link}
                        </Link>
                      </Box>
                    ) : null}

                    {advance_payment ? (
                      <>
                        <Box my={2}>
                          {" "}
                          <Divider />
                        </Box>

                        <Typography mb={1} variant="h6">
                          Advance Payment Details
                        </Typography>

                        <RowView
                          dataTitle="Transaction ID"
                          value={adv_transaction_id}
                        />
                        <RowView
                          dataTitle="Payable Amount"
                          value={adv_payable_amount}
                        />
                        <RowView
                          dataTitle="Status"
                          value={adv_payment_status}
                        />
                        <RowView
                          dataTitle="Timestamp"
                          value={adv_timestamp?.split(".")?.[0]}
                        />
                        {adv_link ? (
                          <Box display="flex">
                            <Typography variant="body2">
                              Link: &nbsp;{" "}
                            </Typography>
                            <Link
                              href={adv_link}
                              target="_blank"
                              sx={{ fontSize: "14px" }}
                            >
                              {adv_link}
                            </Link>
                          </Box>
                        ) : null}
                      </>
                    ) : null}
                  </DialogContent>
                </Dialog>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}
      {orderData.applied_discount && Number(orderData.applied_discount) > 0 ? (
        <Link
          target="_blank"
          href={`${
            process.env.REACT_APP_STAGE !== "production"
              ? "https://dev.medpay.ai"
              : "https://medpay.ai"
          }/receipt?id=${orderData.invoice_details?.invoice_id}`}
          sx={{ textDecoration: "none" }}
        >
          E-receipt
          <OpenInNewIcon
            sx={{
              fontSize: "16px",
              ml: 0.5,
              position: "relative",
              top: "2px",
            }}
          />
        </Link>
      ) : null}
    </>
  );
};

export default InvoiceInfo;
