const pushServerPublicKey =
  "BBMCFD9JIZf5Va40jhzJkJDY8cOKfe-SG99WQC36w3V5jJAS6Bm-_tmwNKh8A-cKbuNp7R3K8zhhpBMipUKtSw4";

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const userConsent =
  typeof Notification === "undefined" ||
  /iPad|iPhone|iPod/.test(navigator.userAgent) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ? null
    : Notification.permission;

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  if (
    typeof Notification === "undefined" ||
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  )
    return null;
  return await Notification.requestPermission();
}
/**
 * shows a notification
 */
function sendNotification() {
  const title = "Thank you for subscribing";
  const options = {
    collapse_key: "order-dashboard",
    body: "We will keep updating you with latest updates.",
    icon: "https://www.medpay.ai/images/MobileCTALOGO.png",
  };
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options);
    const soundButton = document.getElementById("notification-play");
    soundButton.click();
  });
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;
  // subscribe and return the subscription
  const applicationServerKey = urlB64ToUint8Array(pushServerPublicKey);
  let subscription = await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: applicationServerKey,
  });

  return subscription;
}

/**
 * returns the subscription if present or nothing
 */
async function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

export {
  userConsent,
  isPushNotificationSupported,
  askUserPermission,
  sendNotification,
  createNotificationSubscription,
  getUserSubscription,
};
