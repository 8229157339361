import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  TextField,
  Alert,
} from "@mui/material";
import React, { useState } from "react";
import PartnersAutocomplete from "../../components/Common/partnersAC";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import CombinedInfo from "../../components/OrderDetails/combinedInfo";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../utils/common";
import LogisticsReqForm from "./logisticsReqForm";

const CreateLogisticsReqTab = () => {
  const [providerId, setProviderId] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState<any>([]);
  const privateInstance = useAxiosInstance();

  const getOrderByPartnerOrderId = async () => {
    setOrderData([]); //reset order data for sequential search
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          providerId
        )?.toLowerCase()}/${orderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PartnersAutocomplete
          providerId={providerId}
          setProviderId={setProviderId}
          validate="false"
        />
        <TextField
          fullWidth
          id="userId"
          label="Partner Order ID"
          size="small"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        />
        <LoadingButton
          type="button"
          disabled={!providerId || !orderId}
          variant="contained"
          color="primary"
          loading={loading}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          sx={{ minWidth: "180px", mt: 1.5 }}
          onClick={getOrderByPartnerOrderId}
        >
          Get Order
        </LoadingButton>
      </Box>
      {orderData && Object.keys(orderData).length > 0 && (
        <ErrorBoundary>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
              my: 2,
              padding: { xs: "15px" },
            }}
          >
            <CombinedInfo
              orderData={orderData}
              setOrderData={setOrderData}
              setOrderDataModal={null}
              hideStatus={true}
              hideFirstRow={false}
            />
            <Box my={2}>
              <Divider />
            </Box>
            {/* {orderData?.logistics ? (
              <Typography>
                Logistics request is already in-process, task-id:{" "}
                <b>{orderData?.logistics?.task_id}</b>
              </Typography>
            ) : (
              <LogisticsReqForm
                orderData={orderData}
                setOrderData={setOrderData}
                setParentModal={null}
              />
            )} */}
            {orderData.logistics ? (
              <>
                {orderData.logistics.status === "cancelled" ? (
                  <Alert severity="info">
                    Logistics request with task-id:{" "}
                    <b>{orderData?.logistics?.task_id}</b> was cancelled for
                    partner "{orderData?.logistics?.delivery_partner}"
                  </Alert>
                ) : (
                  <Alert severity="info">
                    Logistics request is already in-process, task-id:{" "}
                    <b>{orderData?.logistics?.task_id}</b>
                  </Alert>
                )}
              </>
            ) : null}
            {orderData.delivery_partner === "logistics" ? null : (
              <LogisticsReqForm
                orderData={orderData}
                setOrderData={setOrderData}
                setParentModal={null}
              />
            )}
          </Card>
        </ErrorBoundary>
      )}
    </>
  );
};

export default CreateLogisticsReqTab;
