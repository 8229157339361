import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { TextField } from "@mui/material";
import { medpayTeams, roles, status, valueLimits } from "../../../constants";
import { Formik, Form, FormikProps } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  errorToast,
  handleError,
  successToast,
  textCapitalize,
  validateIntNum,
} from "../../../utils/common";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import useUserInfo from "../../../hooks/useUserInfo";
import { createUserSchema, updateUserSchema } from "../../../schemas/common";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../../enums/common.enum";
import { sortBy } from "lodash";
import { useSelector } from "react-redux";

const CreateUser = ({
  userModal,
  setUserModal,
  activeLoginId,
  setActiveLoginId,
  getAllUsers,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setisEditing] = useState(activeLoginId ? true : false);
  const [formValues, setFormValues] = useState<CreateUser>({
    user_id: "",
    phone_number: "",
    user_type: "medpay_orders_dashboard",
    provider_id: "",
    role: "",
    status: "active",
    password_reset: false,
    username: "",
    additional_roles: [],
    team: "",
  });
  const [allPermissions, setAllPermissions] = useState(null);
  const [basePermissionsData, setBasePermissionsData] = useState(null);
  const [userPermissionsData, setUserPermissionsData] = useState(null);
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [existingAdditionalRoles, setExistingAdditionalRoles] = useState([]);
  const privateInstance = useAxiosInstance();
  let allPartners: Array<string> = [];
  let storedPartners = useSelector((state: any) => state.partners.partnersData);
  storedPartners?.forEach((partner: any) =>
    allPartners.push(partner.partner_id)
  );
  allPartners = sortBy(allPartners);

  interface CreateUser {
    user_id: string;
    phone_number: number | string;
    provider_id: string | null;
    user_type: string;
    role: string;
    status: string;
    password_reset?: boolean;
    username?: string;
    additional_roles?: any;
    team?: string;
  }

  const createUserCall = async (values: CreateUser, actions: any) => {
    let payload: any = {
      user_id: values?.user_id,
      username: "",
      phone_number: values?.phone_number,
      role: values?.role,
      partner: values?.provider_id,
      user_type: values?.user_type,
      additional_roles: values?.additional_roles,
      team: values?.team,
    };

    if (isEditing) {
      payload = {
        ...payload,
        password_reset: values?.password_reset,
        status: values?.status,
      };
    }

    actions.setSubmitting(false);
    setLoading(true);
    try {
      const result = isEditing
        ? await privateInstance.put(`${API_VERSION.V2}/auth/user`, payload)
        : await privateInstance.post(`${API_VERSION.V2}/auth/user`, payload);
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(`User ${isEditing ? "updated" : "created"} successfully.`);
        setUserModal(false);
        getAllUsers();
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const user: AuthenticatedUser = useUserInfo();

  const getRolesForMedpay = (role: string, partner: string | undefined) => {
    let systemRoles = roles;
    if (
      partner?.toString()?.toLowerCase() === "medpay" &&
      role?.toLowerCase() !== USER_ROLE.SUPER_ADMIN
    ) {
      return systemRoles.filter((x) => x !== USER_ROLE.SUPER_ADMIN);
    }
    return systemRoles;
  };

  const getUserByLoginId = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/auth/users/${activeLoginId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setFormValues({
          ...formValues,
          user_id: result.data.response?.user_id,
          phone_number: result.data.response?.phone_number,
          provider_id: result.data.response?.partner,
          role: result.data.response?.role,
          status: result.data.response?.status,
          additional_roles: result.data.response?.additional_roles,
          team: result.data.response?.team,
        });
        setUserPermissionsData(result.data.response?.permissions);
        setExistingAdditionalRoles(result.data.response?.additional_roles);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const getAllPermissions = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/auth/permissions`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setAllPermissions(result.data.response?.permissions);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const getRoleWisePermission = async (role: string) => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/auth/role?role=${role}&partner=medpay`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setBasePermissionsData(result.data.response?.permissions);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  function filterAdditionalPermissions(typeToConsider: string) {
    let otherData: any =
      typeToConsider === "base" ? basePermissionsData : userPermissionsData;
    let newD: any = [];

    (allPermissions as any)?.forEach((el: any) => {
      const res = otherData?.find(
        (x: any) => x.permission_id === el.permission_id
      );
      if (!res) {
        newD.push(el);
      }
    });
    setAdditionalOptions(newD);
  }

  useEffect(() => {
    getAllPermissions();
    if (isEditing) {
      getUserByLoginId();
    }
    return () => {
      setisEditing(false);
      setActiveLoginId("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allPermissions && (basePermissionsData || userPermissionsData)) {
      if (isEditing) {
        if (basePermissionsData) {
          filterAdditionalPermissions("base");
        } else {
          filterAdditionalPermissions("user");
        }
      } else {
        filterAdditionalPermissions("base");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePermissionsData, userPermissionsData, allPermissions]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={userModal}
      onClose={() => setUserModal(!userModal)}
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setUserModal(false)}
      >
        {isEditing ? "Edit User" : "Create User"}
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={isEditing ? updateUserSchema : createUserSchema}
          onSubmit={(values, actions) => createUserCall(values, actions)}
        >
          {(props: FormikProps<CreateUser>) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                  <TextField
                    fullWidth
                    id="user_id"
                    label="User ID"
                    size="small"
                    value={values.user_id}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        user_id: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    sx={{ my: 1.5, mr: { xs: 0, sm: 1 } }}
                    helperText={
                      errors.user_id && touched.user_id ? errors.user_id : ""
                    }
                    error={errors.user_id && touched.user_id ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="phone_number"
                    label="Mobile"
                    size="small"
                    value={values.phone_number}
                    onChange={(e) => {
                      setFormValues({
                        ...formValues,
                        phone_number: validateIntNum(
                          e.target.value,
                          valueLimits.MOBILE
                        ),
                      });
                    }}
                    onBlur={handleBlur}
                    sx={{ my: 1.5, ml: { xs: 0, sm: 1 } }}
                    helperText={
                      errors.phone_number && touched.phone_number
                        ? errors.phone_number
                        : ""
                    }
                    error={
                      errors.phone_number && touched.phone_number ? true : false
                    }
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                  <Autocomplete
                    ListboxProps={{ style: { maxHeight: "14rem" } }}
                    fullWidth
                    size="small"
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    value={values?.provider_id}
                    onChange={(e, val) => {
                      setFieldValue("provider_id", val);
                      setFormValues({
                        ...formValues,
                        provider_id: val,
                        role: val?.toLowerCase() !== "medpay" ? "partner" : "", //handle roles value on partner change
                        additional_roles: [], //reset additional roles value on partner change
                      });
                    }}
                    onBlur={handleBlur}
                    id="provider_id"
                    options={allPartners}
                    renderInput={(params) => (
                      <TextField
                        error={
                          errors?.provider_id && touched?.provider_id
                            ? true
                            : false
                        }
                        helperText={
                          errors?.provider_id && touched?.provider_id
                            ? errors?.provider_id
                            : ""
                        }
                        name="provider_id"
                        label="Partner *"
                        {...params}
                      />
                    )}
                  />
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{
                      my: 1.5,
                      ml: { xs: 0, sm: 1 },
                    }}
                    error={errors.team && touched.team ? true : false}
                    disabled={values?.provider_id?.toLowerCase() !== "medpay"}
                  >
                    <InputLabel required id="team_label">
                      Team
                    </InputLabel>
                    <Select
                      labelId="team_label"
                      name="team"
                      id="team"
                      value={values.team}
                      label="Team"
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          team: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                    >
                      {medpayTeams.map(
                        (
                          item: { label: string; value: string },
                          index: number
                        ) => {
                          return (
                            <MenuItem key={index} value={item?.value}>
                              {item?.label}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    {errors.team && touched.team && (
                      <FormHelperText>{errors.team}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{
                      my: 1.5,
                      mr: { xs: 0, sm: isEditing ? 1 : 0 },
                    }}
                    error={errors.role && touched.role ? true : false}
                  >
                    <InputLabel required id="role-label">
                      Role
                    </InputLabel>
                    <Select
                      labelId="role-label"
                      name="role"
                      id="role"
                      value={values.role}
                      label="Role"
                      onChange={(e) => {
                        if (e.target.value !== "partner") {
                          getRoleWisePermission(e.target.value);
                        }
                        setFormValues({
                          ...formValues,
                          role: e.target.value,
                          additional_roles: existingAdditionalRoles,
                        });
                      }}
                      onBlur={handleBlur}
                    >
                      {values.provider_id && values.provider_id !== "medpay" ? (
                        <MenuItem value="partner">Partner</MenuItem>
                      ) : (
                        getRolesForMedpay(user?.role, user?.partner).map(
                          (x) => (
                            <MenuItem key={x} value={x}>
                              {textCapitalize(x)}
                            </MenuItem>
                          )
                        )
                      )}
                    </Select>
                    {errors.role && touched.role && (
                      <FormHelperText>{errors.role}</FormHelperText>
                    )}
                  </FormControl>
                  {isEditing && (
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ my: 1.5, ml: { xs: 0, sm: 1 } }}
                      error={errors.status && touched.status ? true : false}
                    >
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        labelId="status-label"
                        name="status"
                        id="status"
                        value={values.status}
                        label="Status"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            status: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                      >
                        {status.map((x) => (
                          <MenuItem key={x} value={x}>
                            {textCapitalize(x)}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.status && touched.status && (
                        <FormHelperText>{errors.status}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Box>
                <Autocomplete
                  disabled={
                    values?.role === "partner" || values?.role === "super admin"
                      ? true
                      : false
                  }
                  filterSelectedOptions
                  multiple
                  fullWidth
                  size="small"
                  id="additional_roles"
                  options={additionalOptions}
                  getOptionLabel={(option: any) => option.permission_id}
                  sx={{ my: 1 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Additional Roles"
                      variant="outlined"
                      size="small"
                    />
                  )}
                  value={values?.additional_roles || []}
                  onChange={(e, val: any) => {
                    setFormValues({
                      ...formValues,
                      additional_roles: val,
                    });
                  }}
                />
                <Box>
                  {isEditing && (
                    <FormControlLabel
                      label="Password Reset"
                      control={
                        <Switch
                          id="password_reset"
                          checked={values.password_reset}
                          onChange={(e) =>
                            setFieldValue("password_reset", e.target.checked)
                          }
                        />
                      }
                    />
                  )}
                </Box>
                <Box sx={{ pt: 2, textAlign: "center" }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    sx={{ width: "180px" }}
                  >
                    {isEditing ? "Update" : "Create"}
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUser;
