import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchAutoComplete from "../../../components/CreateOrder/searchAutoComplete";
import DiscountPercent from "../../../components/ManageOrder/GroupOrders/Common/discountPercent";
import MerchantGstValidate from "../../../components/ManageOrder/GroupOrders/Common/merchantGstValidate";
import { deliveryPartnerTypes, valueLimits } from "../../../constants";
import MedicinesTable from "../Create/medicinesTable";
import ClearIcon from "@mui/icons-material/Clear";
import { validateIntNum } from "../../../utils/common";
import KnowlarityCall from "../../../components/Common/knowlarityCall";

const EditMedicinesOrder = ({
  setFieldValue,
  touched,
  errors,
  handleBlur,
  nearbyPharmacyList,
  selectedPharmacy,
  setSelectedPharmacy,
  selectedMedicines,
  setSelectedMedicines,
  selectedMedicinesId,
  setSelectedMedicinesId,
  orderData,
  formData,
  medTableError,
  setMedTableError,
}: any) => {
  const {
    prescribed_by,
    merchant,
    delivery_partner,
    delivery_charges,
    gst_no,
    company,
    discount,
    final_payable,
    merchant_mobile,
  } = formData;
  const [updatedSubTotal, setUpdatedSubTotal] = useState(0);
  const [altMedicineIndex, setAltMedicineIndex] = useState<any>(null);
  const [showCall, setShowCall] = useState(false);
  const [disableGst, setDisableGst] = useState(true);

  //select first provider by default, if only one
  useEffect(() => {
    if (nearbyPharmacyList?.length === 1) {
      const merchantName = nearbyPharmacyList[0].name;
      setFieldValue("merchant", merchantName);
      const selectedMerchant = nearbyPharmacyList.filter(
        (x: any) => x.name === merchantName
      );
      setSelectedPharmacy(selectedMerchant[0]);
      setFieldValue("merchant_mobile", selectedMerchant[0]?.mobile);
      setFieldValue("gst_no", selectedMerchant[0]?.gst_no);
      setFieldValue(
        "company",
        selectedMerchant[0]?.gst_name
          ? selectedMerchant[0].gst_name
          : orderData.order_partner === "medpay"
          ? "NA"
          : ""
      );
      setFieldValue("discount", selectedMerchant[0]?.discount_pc || "");
      if (selectedMerchant[0]?.mobile) {
        setShowCall(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let subT: number = 0;
    selectedMedicines.forEach((element: any) => {
      subT += Number(element.quantity) * Number(element.MRP);
    });
    setUpdatedSubTotal(subT);
  }, [selectedMedicines]);
  return (
    <Box>
      <TextField
        fullWidth
        id="prescribed_by"
        label="Doctor Name"
        size="small"
        value={prescribed_by || ""}
        onChange={(e) => setFieldValue("prescribed_by", e.target.value)}
        onBlur={handleBlur}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        helperText={
          errors.prescribed_by && touched.prescribed_by
            ? errors.prescribed_by
            : ""
        }
        error={errors.prescribed_by && touched.prescribed_by ? true : false}
      />
      <Box sx={{ my: 3 }}>
        <Typography variant="h4">Medicine Details:</Typography>
        {altMedicineIndex != null && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: -3 }}
          >
            <Typography>
              Adding alternative of: {selectedMedicines[altMedicineIndex].item}
            </Typography>
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ ml: 2 }}
              onClick={() => setAltMedicineIndex(null)}
            >
              <ClearIcon />
            </Button>
          </Stack>
        )}
        <SearchAutoComplete
          sx={{ my: 5 }}
          selectedValues={selectedMedicines}
          setSelectedValues={setSelectedMedicines}
          selectedValuesId={selectedMedicinesId}
          setSelectedValuesId={setSelectedMedicinesId}
          autocompleteLabel="Medicines"
          isEditing={true}
          altMedicineIndex={altMedicineIndex}
          setAltMedicineIndex={setAltMedicineIndex}
          orderStatus={orderData?.order_status}
        />
        <Stack direction="row" justifyContent="center" mt={2} mb={3}>
          {Array.isArray(
            orderData?.merchant_updated_meta?.[0]?.items?.merchant_uploads
          )
            ? orderData?.merchant_updated_meta?.[0]?.items?.merchant_uploads?.map(
                (x: any) => {
                  return (
                    <a href={x} target="_blank" rel="noreferrer">
                      <img
                        alt="rough bill"
                        src="https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png"
                        style={{ height: "85px" }}
                      />
                    </a>
                  );
                }
              )
            : null}
        </Stack>
        <MedicinesTable
          data={selectedMedicines}
          setSelectedMedicines={setSelectedMedicines}
          selectedMedicinesId={selectedMedicinesId}
          setSelectedMedicinesId={setSelectedMedicinesId}
          isEditing={true}
          altMedicineIndex={altMedicineIndex}
          setAltMedicineIndex={setAltMedicineIndex}
          orderMerchantMeta={orderData?.merchant_updated_meta}
          orderItems={orderData?.order_details?.items}
          medTableError={medTableError}
          setMedTableError={setMedTableError}
          orderPartner={orderData?.order_partner}
          orderStatus={orderData?.order_status}
        />
      </Box>
      <FormControl
        size="small"
        fullWidth
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        error={errors.merchant && touched.merchant ? true : false}
      >
        <InputLabel required id="merchant-label">
          Merchant
        </InputLabel>
        <Select
          labelId="merchant-label"
          name="merchant"
          id="merchant"
          value={merchant || ""}
          label="Merchant"
          onChange={(e: SelectChangeEvent) => {
            setFieldValue("merchant", e.target.value);
            const selectedItem = nearbyPharmacyList.filter(
              (x: any) => x.name === e.target.value
            );
            setSelectedPharmacy(selectedItem[0]);
            setFieldValue("merchant_mobile", selectedItem[0]?.mobile);
            setFieldValue("gst_no", selectedItem[0]?.gst_no);
            setFieldValue(
              "company",
              selectedItem[0]?.gst_name
                ? selectedItem[0].gst_name
                : orderData.order_partner === "medpay"
                ? "NA"
                : ""
            );
            setFieldValue("discount", selectedItem[0]?.discount_pc || "");
            if (selectedItem[0]?.mobile) {
              setShowCall(true);
            }
          }}
          onBlur={handleBlur}
        >
          {nearbyPharmacyList &&
            nearbyPharmacyList.map((x: any) => (
              <MenuItem key={x.name} value={x.name}>
                {x.name}
              </MenuItem>
            ))}
        </Select>
        {errors.merchant && touched.merchant && (
          <FormHelperText>{errors.merchant}</FormHelperText>
        )}
      </FormControl>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          mr={showCall ? 3 : 0}
        >
          <TextField
            fullWidth
            id="merchant_mobile"
            label="Mobile"
            size="small"
            value={merchant_mobile || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onChange={(e) =>
              setFieldValue(
                "merchant_mobile",
                validateIntNum(e.target.value, valueLimits.MOBILE)
              )
            }
            onBlur={handleBlur}
            helperText={
              errors.merchant_mobile && touched.merchant_mobile
                ? errors.merchant_mobile
                : ""
            }
            error={
              errors.merchant_mobile && touched.merchant_mobile ? true : false
            }
            InputLabelProps={{
              required: true,
            }}
          />
          {showCall && (
            <KnowlarityCall
              order={{ order_id: orderData?.order_id, mobile: merchant_mobile }}
              showNumber={false}
            />
          )}
        </Box>
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          error={
            errors.delivery_partner && touched.delivery_partner ? true : false
          }
        >
          <InputLabel id="delivery-partner-label">Delivery Partner</InputLabel>
          <Select
            labelId="delivery-partner-label"
            name="delivery_partner"
            id="delivery_partner"
            value={delivery_partner}
            label="Delivery Partner"
            onChange={(e: SelectChangeEvent) => {
              setFieldValue("delivery_partner", e.target.value);
            }}
            onBlur={handleBlur}
          >
            {Object.entries(deliveryPartnerTypes).map(([key, value]: any) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          {errors.delivery_partner && touched.delivery_partner && (
            <FormHelperText>{errors.delivery_partner}</FormHelperText>
          )}
        </FormControl>
        <TextField
          fullWidth
          id="delivery_charges"
          label="Delivery Charges"
          size="small"
          value={delivery_charges || ""}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          disabled
        />
      </Stack>
      <Stack>
        <MerchantGstValidate
          gst_no={gst_no}
          company={company}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          disableGst={disableGst}
          setDisableGst={setDisableGst}
        />
        <DiscountPercent
          discount={discount}
          final_payable={final_payable}
          total={updatedSubTotal}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Stack>
    </Box>
  );
};

export default EditMedicinesOrder;
