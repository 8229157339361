import * as React from "react";
import {
  Grid,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Box,
  TextField,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import { Formik, Form, FormikProps } from "formik";
import MedPayLogoBlack from "../../assets/images/medpayLogoBlack.png";
import { instance } from "../../utils/api";
import CoverImg from "../../assets/images/cover.png";
import { useLocation, useNavigate } from "react-router-dom";
import { successToast, handleError, errorToast } from "../../utils/common";
import { useDispatch } from "react-redux";
import { setUser, setUserPermissions } from "../../store/actions/useActions";
import { loginSchema } from "../../schemas/common";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import Cookies from "js-cookie";
import config from "../../config";

interface UserLogin {
  password: string;
  emailUsername: string;
}

const Login = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const from = location?.state?.from
    ? location.state.from.pathname + location.state.from.search
    : "/dashboard";
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const CryptoJS = require("crypto-js");

  // React.useEffect(() => {
  //   if (Cookies.get("user")) {
  //     navigate("/dashboard");
  //   }
  // });

  return (
    <Grid
      item
      xs={12}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Card
        sx={{
          width: { xs: "90%", md: "80%", lg: "60%", xl: "50%" },
          m: 1,
          boxShadow: "0px 4px 46px rgba(0, 0, 0, 0.25)",
          padding: { xs: "20px", md: "0" },
        }}
      >
        <CardContent style={{ display: "flex", padding: 0 }}>
          <Box sx={{ width: "100%" }}>
            <Box textAlign="center">
              <img
                src={MedPayLogoBlack}
                alt="logo"
                style={{ width: "150px", paddingTop: "15px" }}
              />
            </Box>
            <Formik
              initialValues={{
                emailUsername: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={(values: UserLogin, actions) => {
                setLoading(true);
                actions.setSubmitting(false);
                let payload = {
                  user_id: values.emailUsername?.trim(),
                  passcode: values.password,
                  client_name: "orders-dashboard",
                };
                instance
                  .post(`${API_VERSION.V2}/auth/user/login`, payload)
                  .then((response) => {
                    setLoading(false);
                    if (response.data.status === API_RESULT.SUCCESS) {
                      const userData = response.data.response;
                      if (!userData?.permissions) {
                        errorToast(
                          "Login failed due to invalid permissions. Please contact MedPay Tech Support."
                        );
                        return;
                      }
                      //store user authdata and permissions in redux
                      dispatch(
                        setUser({
                          access_token: userData.access_token,
                          login_id: userData.login_id,
                          role: userData.role,
                          user_id: userData.user_id,
                          partner: userData.partner,
                          phone_number: userData.phone_number,
                          provider_payment: userData.provider_payment,
                          package_picked_updation:
                            userData.package_picked_updation || "",
                          package_delivery_updation:
                            userData.package_delivery_updation || "",
                        })
                      );
                      dispatch(setUserPermissions(userData.permissions));
                      // TO PERSIST: store user authdata in cookie
                      Cookies.set(
                        "user",
                        CryptoJS.AES.encrypt(
                          JSON.stringify({
                            access_token: userData.access_token,
                            role: userData.role,
                            login_id: userData.login_id,
                            user_id: userData.user_id,
                            partner: userData.partner,
                            phone_number: userData.phone_number,
                            provider_payment: userData.provider_payment,
                            package_picked_updation:
                              userData.package_picked_updation || "",
                            package_delivery_updation:
                              userData.package_delivery_updation || "",
                          }),
                          config.ENC_KEY
                        ).toString(),
                        {
                          expires: 1,
                          secure: true,
                          sameSite: "Strict",
                          path: "/",
                        }
                      );
                      //TO PERSIST: store user permissions in localstorage, because cookie max limit 4kb
                      localStorage.setItem(
                        "user_permissions",
                        CryptoJS.AES.encrypt(
                          JSON.stringify(userData.permissions),
                          config.ENC_KEY
                        ).toString()
                      );
                      //clarity user id
                      if ((window as any).clarity) {
                        (window as any).clarity("identify", userData?.user_id);
                      }
                      successToast("Logged in successfully");
                      navigate(from, { replace: true });
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    handleError(error);
                  });
              }}
            >
              {(props: FormikProps<UserLogin>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                } = props;
                return (
                  <Form>
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", md: "90%" },
                        marginLeft: { xs: "0", md: "5%" },
                        marginBottom: "15px",
                        marginTop: "45px",
                      }}
                    >
                      <TextField
                        //required
                        label="Email/Username"
                        name="emailUsername"
                        id="emailUsername"
                        style={{ width: "100%" }}
                        variant="standard"
                        type="text"
                        value={values.emailUsername}
                        helperText={
                          errors.emailUsername && touched.emailUsername
                            ? errors.emailUsername
                            : ""
                        }
                        error={
                          errors.emailUsername && touched.emailUsername
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 320,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="user account">
                                <AccountCircleIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        //required
                        label="Password"
                        name="password"
                        id="password"
                        style={{ width: "100%", marginTop: "30px" }}
                        variant="standard"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : ""
                        }
                        error={
                          errors.password && touched.password ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="user password"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Box sx={{ textAlign: "center", marginTop: "60px" }}>
                        <LoadingButton
                          sx={{ width: "180px" }}
                          size="medium"
                          color="primary"
                          loading={loading}
                          loadingIndicator={
                            <CircularProgress
                              size={20}
                              sx={{ color: "#fff" }}
                            />
                          }
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Login
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <CardMedia
              component="img"
              width="100%"
              height="107%"
              image={CoverImg}
              alt="cover"
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Login;
