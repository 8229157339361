import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCart } from "../../store/actions/useActions";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, IconButton, Stack, TextField } from "@mui/material";
import { validateIntNum } from "../../utils/common";
import CheckIcon from "@mui/icons-material/Check";

const DEFAULT_ITEM_QUANTITY = 5;

const AddAndAlterItems = ({ medicine, view }: any) => {
  const dispatch = useDispatch();
  const cartDataFromStore =
    useSelector((state: any) => state.cart.cartData) || [];

  const removeZeroQtyItems = () => {
    dispatch(
      UpdateCart(cartDataFromStore?.filter((item: any) => item.qty !== 0))
    );
  };

  const updateStoredData = (data: any) => {
    dispatch(UpdateCart(data));
    localStorage.setItem("cart_data", JSON.stringify(data));
  };

  const addItem = (item: any) => {
    const addedItemsSKU = cartDataFromStore?.map((med: any) => med.id);
    let updatedData = [...cartDataFromStore];

    if (addedItemsSKU.includes(item.id)) {
      let existingItem = cartDataFromStore.find(
        (medicine: any) => medicine.id === item.id
      );
      updatedData = updatedData.filter(
        (medicine: any) => medicine.id !== item.id
      );
      existingItem = {
        ...existingItem,
        qty: existingItem.qty + DEFAULT_ITEM_QUANTITY,
      };
      updatedData.push(existingItem);
    } else {
      item = { ...item, qty: DEFAULT_ITEM_QUANTITY };
      updatedData.push(item);
    }
    updateStoredData(updatedData);
  };

  const deleteItem = (id: string) => {
    let updatedData = [...cartDataFromStore];
    updatedData = updatedData.filter((medicine) => medicine.id !== id);
    updateStoredData(updatedData);
  };

  const alterQty = (changeType: string, id: string, value?: string) => {
    let updatedData = [];
    for (let i = 0; i < cartDataFromStore.length; i++) {
      let el = cartDataFromStore[i];
      if (el.id === id) {
        if (changeType === "freeText") {
          // if (!value || value === "" || Number(value) === 0) {
          //   deleteItem(el.id);
          //   return;
          // }
          el = {
            ...el,
            qty: Number(value),
          };
        } else if (changeType === "add") {
          el = {
            ...el,
            qty:
              Number(el.qty) > 0
                ? Number(el.qty) < 100
                  ? el.qty + DEFAULT_ITEM_QUANTITY
                  : 100
                : DEFAULT_ITEM_QUANTITY,
          };
        } else {
          if (Number(el.qty) === DEFAULT_ITEM_QUANTITY) {
            deleteItem(el.id);
            return;
          }
          el = {
            ...el,
            qty:
              Number(el.qty) > DEFAULT_ITEM_QUANTITY
                ? el.qty - DEFAULT_ITEM_QUANTITY
                : DEFAULT_ITEM_QUANTITY,
          };
        }
      }
      updatedData.push(el);
    }
    updateStoredData(updatedData);
  };

  return (
    <Box textAlign="center">
      {view === "mainListing" ? (
        <>
          {cartDataFromStore?.find((item: any) => item.id === medicine.id) ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
              sx={{
                border: "2px solid #0058ff",
                borderRadius: 2,
                width: "fit-content",
                margin: "auto",
                p: 0.75,
              }}
            >
              <IconButton
                color="primary"
                onClick={() => alterQty("subtract", medicine.id)}
              >
                <RemoveIcon />
              </IconButton>
              <TextField
                variant="standard"
                type="number"
                id="qty"
                inputProps={{
                  style: { textAlign: "center", fontSize: "18px" },
                }}
                //label="Qty"
                size="small"
                value={
                  cartDataFromStore?.find(
                    (item: any) => item.id === medicine.id
                  )?.qty || 0
                }
                onChange={(e) =>
                  alterQty(
                    "freeText",
                    medicine.id,
                    validateIntNum(e.target.value, 100)
                  )
                }
                onBlur={removeZeroQtyItems}
                sx={{ width: "60px" }}
              />
              <IconButton
                color="primary"
                onClick={() => alterQty("add", medicine.id)}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => addItem(medicine)}
            >
              Add to Cart
            </Button>
          )}
        </>
      ) : view === "cart" ? (
        <TextField
          variant="standard"
          type="number"
          id="qty"
          inputProps={{ style: { textAlign: "center" } }}
          //label="Qty"
          size="small"
          value={
            cartDataFromStore?.find((item: any) => item.id === medicine.id)
              ?.qty || 0
          }
          onChange={(e) =>
            alterQty(
              "freeText",
              medicine.id,
              validateIntNum(e.target.value, 100)
            )
          }
          onBlur={removeZeroQtyItems}
          sx={{ width: "60px" }}
        />
      ) : view === "card" ? (
        <Button
          startIcon={
            cartDataFromStore?.find((item: any) => item.id === medicine.id) ? (
              <CheckIcon />
            ) : (
              <AddIcon />
            )
          }
          size="small"
          disabled={cartDataFromStore?.find(
            (item: any) => item.id === medicine.id
          )}
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            addItem(medicine);
          }}
          sx={{ fontSize: "10px !important" }}
        >
          {cartDataFromStore?.find((item: any) => item.id === medicine.id)
            ? "Added"
            : "Add"}
        </Button>
      ) : null}
    </Box>
  );
};

export default AddAndAlterItems;
