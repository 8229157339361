import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { valueLimits } from "../../constants";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  successToast,
  validateIntNum,
} from "../../utils/common";

const LogisticSupportModal = ({
  showLogisticSupportModal,
  setShowLogisticSupportModal,
  activeOrderId,
  supportHistory,
  updateFn,
}: any) => {
  const [contactNumber, setContactNumber] = useState("");
  const [issueDesc, setIssueDesc] = useState("");
  const [loading, setLoading] = useState(false);

  const privateInstance = useAxiosInstance();

  const handleSupportLogistics = async () => {
    let payload = {
      order_id: activeOrderId,
      contact_number: contactNumber,
      issue_description: issueDesc,
    };

    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V2}/logistics/support`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        updateFn();
        successToast(result.data.message);
        setShowLogisticSupportModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showLogisticSupportModal}
      onClose={() => setShowLogisticSupportModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowLogisticSupportModal(false)}
      >
        {"Logistics - Support"}
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        {supportHistory && supportHistory.length > 0 ? (
          <Box my={2}>
            {/* <Typography variant="h6">Support History</Typography> */}
            {supportHistory.map((x: any, index: number) => {
              return (
                <Stack
                  mt={1}
                  key={index}
                  sx={{
                    borderBottom: "1px solid #eeeeee",
                    paddingBottom: "10px",
                  }}
                >
                  <Typography variant="body2">
                    {x?.issue_description} | {x?.callback_number}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    {parseAndFormateDate(x.raised_at)} | {x?.raised_by}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        ) : null}
        <TextField
          fullWidth
          label="Contact Number"
          size="small"
          value={contactNumber || ""}
          onChange={(e) =>
            setContactNumber(validateIntNum(e.target.value, valueLimits.MOBILE))
          }
          sx={{
            mt: 1.5,
            mr: { xs: 0, md: 1 },
          }}
          helperText={
            contactNumber && contactNumber?.length !== 10
              ? "Contact number should be 10-digit"
              : ""
          }
          error={contactNumber && contactNumber?.length !== 10 ? true : false}
          inputProps={{
            maxLength: 10,
          }}
          InputLabelProps={{
            required: true,
          }}
        />
        <FormControl fullWidth sx={{ mt: 1.5 }}>
          <TextField
            id="outlined-textarea"
            label="Issue Description"
            placeholder="not interested!"
            multiline
            rows={4}
            variant="outlined"
            value={issueDesc}
            onChange={(e) => setIssueDesc(e.target.value)}
            InputLabelProps={{
              required: true,
            }}
          />
        </FormControl>
        <Typography variant="body2" my={1.5}>
          Note: Dunzo support team will call you on above number!
        </Typography>
        <Box textAlign="center" mt={3}>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={loading}
            disabled={!issueDesc || contactNumber.length !== 10}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={() => handleSupportLogistics()}
          >
            Submit
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LogisticSupportModal;
