import React from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import EditorsAutocomplete from "../Common/editorsAC";
import { LoadingButton } from "@mui/lab";
import CustomDialogTitle from "../Common/customDialogTitle";

const EditorModal = ({
  showEditorModal,
  setShowEditorModal,
  editor,
  setEditor,
  submitting,
  handleSubmit,
}: any) => {
  React.useEffect(() => {
    return () => {
      setEditor(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={showEditorModal}
      onClose={() => setShowEditorModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowEditorModal(false)}
      >
        {"Select Editor"}
      </CustomDialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <EditorsAutocomplete editor={editor} setEditor={setEditor} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowEditorModal(false)}>Cancel</Button>
        <LoadingButton
          sx={{ width: "180px" }}
          size="medium"
          color="primary"
          loading={submitting}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          variant="contained"
          type="button"
          onClick={handleSubmit}
          disabled={submitting}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditorModal;
