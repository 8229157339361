import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";

const AltFromPharmacyModal = ({
  orgAndAltItem,
  showAltFromPharmacyModal,
  setShowAltFromPharmacyModal,
  altMedicineIndex,
  setAltMedicineIndex,
  selectedValues,
  setSelectedValues,
}: any) => {
  const { org, alt } = orgAndAltItem;

  const acceptSku = () => {
    selectedValues.splice(altMedicineIndex, 1, {
      sku_id: alt.sku_id,
      item: alt.item,
      details: alt.pack_qty_label
        ? alt.pack_qty_label
        : alt.details
        ? alt.details
        : "-",
      quantity: alt.quantity,
      MRP: String(alt.MRP),
      manufacturer: alt.mfr,
      composition: alt.composition,
      drug_form: alt.drug_form,
      pack_form: alt.pack_form,
      pack_quantity: alt.pack_quantity,
      change_type: "alternative",
      requested_item: selectedValues[altMedicineIndex],
      med_from_db: "true",
      db_mrp: String(alt.MRP),
      alt_from_pharmacy: true,
    });
    const updatedData = [...selectedValues];
    setSelectedValues(updatedData);
    setAltMedicineIndex(null);
    setShowAltFromPharmacyModal(false);
  };
  return (
    <>
      <Dialog
        open={showAltFromPharmacyModal}
        onClose={() => {
          setShowAltFromPharmacyModal(false);
          setAltMedicineIndex(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setShowAltFromPharmacyModal(false);
            setAltMedicineIndex(null);
          }}
        >
          Accept Alternate Medicine
        </CustomDialogTitle>
        <DialogContent sx={{ px: 0 }}>
          <Stack direction={{ xs: "column", md: "row" }}>
            <Box p={2} width={{ xs: "100%", md: "50%" }}>
              <Typography pb={2} variant="h5">
                Original Item
              </Typography>
              <Typography variant="body2">
                <strong>Item:</strong> {org.item}
              </Typography>
              {org.composition ? (
                <Typography variant="body2">
                  <strong>Composition:</strong> {org.composition}
                </Typography>
              ) : null}
              <Typography variant="body2">
                <strong>Pack Form:</strong> {org.pack_form}
              </Typography>
              <Typography variant="body2">
                <strong>Pack Qty:</strong> {org.pack_quantity}
              </Typography>
              <Typography variant="body2">
                <strong>Qty:</strong> {org.quantity ?? 1}
              </Typography>
              <Typography variant="body2">
                <strong>Price:</strong> {org.MRP}
              </Typography>
              <Typography variant="body2">
                <strong>Amount:</strong>
                {Number(Number(org.quantity) * Number(org.MRP)).toFixed(2)}
              </Typography>
            </Box>
            <Box p={2} width={{ xs: "100%", md: "50%" }}>
              <Typography pb={2} variant="h5">
                Alternate Item
              </Typography>
              <Typography variant="body2">
                <strong>Item:</strong> {alt.item}
              </Typography>
              {alt.composition ? (
                <Typography variant="body2">
                  <strong>Composition:</strong> {alt.composition}
                </Typography>
              ) : null}
              <Typography variant="body2">
                <strong>Pack Form:</strong> {alt.pack_form}
              </Typography>
              <Typography variant="body2">
                <strong>Pack Qty:</strong> {alt.pack_quantity}
              </Typography>
              <Typography variant="body2">
                <strong>Qty:</strong> {alt.quantity ?? 1}
              </Typography>
              <Typography variant="body2">
                <strong>Price:</strong> {alt.MRP}
              </Typography>
              <Typography variant="body2">
                <strong>Amount:</strong>{" "}
                {Number(Number(alt.quantity) * Number(alt.MRP)).toFixed(2)}
              </Typography>
            </Box>
          </Stack>
          <Box
            mt={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" color="primary" onClick={acceptSku}>
              Accept
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="error"
              onClick={() => {
                setShowAltFromPharmacyModal(false);
                setAltMedicineIndex(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AltFromPharmacyModal;
