import {
  setAuditEmailTime,
  setCashlessEmailTime,
  setCustomerCartEmailTime,
  setOrderEmailTime,
  setUser,
  setUserPermissions,
  UpdateCart,
  UpdateNotifications,
} from "../store/actions/useActions";
import { useDispatch } from "react-redux";
import { AuthenticatedUser } from "../interfaces/user.model";
import Cookies from "js-cookie";
import config from "../config";
import useUserPermissions from "./useUserPermissions";

const useRefresh = () => {
  const dispatch = useDispatch();
  const CryptoJS = require("crypto-js");
  const userPermissions = useUserPermissions();

  let cookieData: AuthenticatedUser;
  let ls_orderReportTime: any;
  let ls_cashlessReportTime: any;
  let ls_auditReportTime: any;
  let ls_userNotifications: any;
  let ls_cartReportTime: any;
  let ls_cartData: any;

  const refresh = () => {
    //update user data in store
    if (Cookies.get("user")) {
      let bytes = CryptoJS.AES.decrypt(Cookies.get("user"), config.ENC_KEY);
      cookieData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      //clarity user id
      if ((window as any).clarity) {
        (window as any).clarity("identify", cookieData?.user_id);
      }

      dispatch(
        setUser({
          access_token: cookieData?.access_token,
          role: cookieData?.role,
          login_id: cookieData?.login_id,
          user_id: cookieData?.user_id,
          partner: cookieData?.partner,
          phone_number: cookieData?.phone_number,
          provider_payment: cookieData?.provider_payment,
          package_picked_updation: cookieData?.package_picked_updation,
          package_delivery_updation: cookieData?.package_delivery_updation,
        })
      );
    }
    //update report countdown time in store
    if (localStorage.getItem("orderReportTime")) {
      ls_orderReportTime = JSON.parse(
        String(localStorage.getItem("orderReportTime"))
      );
      dispatch(setOrderEmailTime(ls_orderReportTime));
    }
    if (localStorage.getItem("cashlessReportTime")) {
      ls_cashlessReportTime = JSON.parse(
        String(localStorage.getItem("cashlessReportTime"))
      );
      dispatch(setCashlessEmailTime(ls_cashlessReportTime));
    }
    if (localStorage.getItem("auditReportTime")) {
      ls_auditReportTime = JSON.parse(
        String(localStorage.getItem("auditReportTime"))
      );
      dispatch(setAuditEmailTime(ls_auditReportTime));
    }
    if (localStorage.getItem("customerCartReportTime")) {
      ls_cartReportTime = JSON.parse(
        String(localStorage.getItem("customerCartReportTime"))
      );
      dispatch(setCustomerCartEmailTime(ls_cartReportTime));
    }
    //update cart data
    if (localStorage.getItem("cart_data")) {
      ls_cartData = JSON.parse(String(localStorage.getItem("cart_data")));
      dispatch(UpdateCart(ls_cartData));
    }
    //update notifications data
    if (localStorage.getItem("user_notif")) {
      ls_userNotifications = JSON.parse(
        String(localStorage.getItem("user_notif"))
      );
      dispatch(UpdateNotifications(ls_userNotifications));
    }
    //update user permissions
    if (userPermissions) {
      dispatch(setUserPermissions(userPermissions));
    }
  };
  return refresh;
};

export default useRefresh;
