import * as yup from "yup";

export const createIpdOrderSchema = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
  partner_order_id: yup
    .string()
    .matches(
      "^[a-zA-Z0-9_-]+$" as any,
      "Only alphanumeric, underscore(_) , hyphen(-) allowed"
    )
    .required("Partner Order ID is required"),
  order_type: yup.string().required("Order Type is required"),
  full_name: yup.string().required("Full name is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  alt_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000"),
  email: yup.string().email("Must be valid email"),
  merchant_type: yup.string().required("Merchant type is required"),
  pan_no: yup.string().required("Pan No. is required"),
  merchant_name: yup.string().required("Merchant name is required"),
  merchant_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Merchant mobile is required"),
  merchant_pin: yup.string().required("Merchant Pin code is required"),
  account_no: yup.string().required("Account Number is required"),
  account_name: yup.string().required("Account Name is required"),
  bank_name: yup.string().required("Bank Name is required"),
  ifsc_code: yup
    .string()
    .min(11, "Please enter 11-digit IFSC code")
    .max(11, "Please enter 11-digit IFSC code")
    .required("IFSC code is required"),
  mrp: yup
    .number()
    .typeError("must be a number")
    .min(0, "bill amount cannot be less than 0")
    .required("Bill amount is required"),
  discount: yup
    .number()
    .typeError("must be a number")
    .min(0, "discount cannot be less than 0")
    .required("Discount amount is required"),
  patient_payable: yup
    .number()
    .typeError("must be a number")
    .min(0, "patient payable cannot be less than 0")
    .required("Patient payable is required"),
});
