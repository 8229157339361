import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import useUserInfo from "../../../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import {
  errorToast,
  handleError,
  successToast,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";

const WhitelistUpiModal = ({
  whitelistUpiModal,
  setWhitelistUpiModal,
  orderData,
  upiToWhitelist,
}: any) => {
  const [upiName, setUpiName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleVerifyUpi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();

  const handleVerifyUpi = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/verify-upi?upi_id=${upiToWhitelist}`
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setUpiName(result.data.response.verify_upi_id_holder);
      } else {
        setUpiName("NA");
      }
    } catch (err) {
      setUpiName("NA");
      handleError(err);
    }
  };

  //TODO: convert to form and use values
  const handleRequestWhitelist = async () => {
    let payload = {
      upi_id: upiToWhitelist,
      name: upiName,
      order_partner: orderData?.order_partner,
      partner_order_id: orderData?.partner_order_id,
      order_id: orderData?.order_id,
      provider_name: orderData?.merchant_details?.name,
      provider_mobile: orderData?.merchant_details?.mobile,
      gst_no: orderData?.merchant_details?.gst_no || "",
      final_payout_amount: Number(
        orderData?.invoice_details?.bill_amount -
          orderData?.invoice_details?.discount
      ).toFixed(2),
      status: "pending",
      requested_by: user?.user_id,
      formatted_address: orderData?.merchant_details?.formatted_address,
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/upi-whitelisting`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Request successfully sent");
        setWhitelistUpiModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={whitelistUpiModal}
      onClose={() => setWhitelistUpiModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setWhitelistUpiModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
            UPI Whitelisting Request
          </Typography>
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        <Stack direction="row" sx={{ mt: 1.5 }}>
          <TextField
            fullWidth
            label="Partner Order ID"
            size="small"
            value={orderData?.partner_order_id || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
          <TextField
            fullWidth
            label="Partner Name"
            size="small"
            value={orderData?.order_partner || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
        </Stack>
        <Stack direction="row" sx={{ mt: 1.5 }}>
          <TextField
            fullWidth
            label="Provider Name"
            size="small"
            value={orderData?.merchant_details?.name || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
          <TextField
            fullWidth
            label="Mobile Number"
            size="small"
            value={orderData?.merchant_details?.mobile || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
        </Stack>
        <Stack sx={{ mt: 1.5 }}>
          <TextField
            fullWidth
            label="Address"
            size="small"
            value={orderData?.merchant_details?.formatted_address || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
            multiline
            minRows={3}
          />
        </Stack>
        <Stack direction="row" sx={{ mt: 1.5 }}>
          <TextField
            fullWidth
            label="UPI ID"
            size="small"
            value={upiToWhitelist || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
          <TextField
            fullWidth
            label="UPI Name"
            size="small"
            value={upiName || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
        </Stack>
        <Stack direction="row" sx={{ mt: 1.5 }}>
          <TextField
            fullWidth
            label="Total Payable"
            size="small"
            value={
              Number(
                orderData?.invoice_details?.bill_amount -
                  orderData?.invoice_details?.discount
              ).toFixed(2) || ""
            }
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
          <TextField
            fullWidth
            label="GST No."
            size="small"
            value={orderData?.merchant_details?.gst_no || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
        </Stack>
        <Stack marginTop={8} mb={4} display="flex" justifyContent="left">
          <Typography variant="h4">Note :</Typography>
          <Typography variant="body1">
            1. This will generate an automated email to{" "}
            <b>techsupport@medpay.in</b> for whitelisting the UPI.
          </Typography>
          <Typography variant="body1">
            2. Please do not send any additional email from your side.
          </Typography>
        </Stack>
        <Box sx={{ textAlign: "center" }}>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={loading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={() => handleRequestWhitelist()}
            sx={{ mr: 2 }}
          >
            Request for whitelisting
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default WhitelistUpiModal;
