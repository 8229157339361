import React from "react";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import OrderDetail from "../../../components/CreateOrder/orderDetail";
import PatientDetail from "../../../components/CreateOrder/patientDetail";
import AddressDetail from "../../../components/CreateOrder/addressDetail";
import { LoadingButton } from "@mui/lab";
import UploadFiles from "../../../components/Common/uploadFiles";
import DateTimeSelect from "../../../components/Common/dateTimeSelect";
import SearchAutoComplete from "../../../components/CreateOrder/searchAutoComplete";
import TestsTable from "./testsTable";
import PartnerIdChange from "../../../components/CreateOrder/partnerIdChange";
import { format } from "date-fns";
import {
  errorToast,
  handleError,
  removeTrailingSlash,
  successToast,
} from "../../../utils/common";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { ORDER_TYPE } from "../../../enums/order.enum";
import { createDiagnosticSchema } from "../../../schemas/diagnostic";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";

const CreateLabOrder = () => {
  const [loading, setLoading] = React.useState(false);
  const [prescriptionUrls, setPrescriptionUrls] = React.useState([]);
  const [selectedTests, setSelectedTests] = React.useState<Array<any>>([]);
  const [selectedTestsId, setSelectedTestsId] = React.useState<Array<string>>(
    []
  );
  const privateInstance = useAxiosInstance();
  const [testsTableError, setTestsTableError] = React.useState<any>([]);

  const handleSubmit = async (values: any, actions: any) => {
    // if (prescriptionUrls.length === 0) {
    //   alert("Please upload prescription to proceed");
    //   return;
    // }

    if (selectedTests.length === 0) {
      alert("Please add at least one test to proceed");
      return;
    }

    let formErrorTests = false;
    //tests table error alert
    testsTableError.forEach((x: any) => {
      if (x.MRP) {
        formErrorTests = true;
      }
    });

    if (formErrorTests) {
      alert("Please correct all errors to proceed");
      return;
    }

    let payload: any = {
      customer_details: {
        address_details: {
          address: removeTrailingSlash(
            values.address?.trim().replace("\t", "")
          ),
          city: values.city,
          coordinates: "",
          landmark: removeTrailingSlash(
            values.landmark?.trim().replace("\t", "")
          ),
          pin_code: removeTrailingSlash(values.pin?.trim().replace("\t", "")),
          state: values.state,
        },
        age: Number(values.age),
        alternative_mobile: removeTrailingSlash(
          values.alt_mobile?.trim().replace("\t", "")
        ),
        email: removeTrailingSlash(values.email?.trim().replace("\t", "")),
        gender: values.gender,
        mobile: removeTrailingSlash(values.mobile?.trim().replace("\t", "")),
        name: removeTrailingSlash(values.full_name?.trim().replace("\t", "")),
      },
      partner_name: values.provider_id,
      partner_order_id: removeTrailingSlash(
        values.partner_order_id?.trim().replace("\t", "")
      ),
      payment_collection: values.payment_collection,
      sample_collection: values.lab_visit_type,
      time_slot: format(values.time_slot, "dd-MM-yyyy, hh:mm a"),
      order_details: {
        doctor: removeTrailingSlash(values.doctor?.trim().replace("\t", "")),
        items: selectedTests,
        prescription_links: prescriptionUrls,
      },
    };

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/diagnostics/create-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order created successfully");
        actions.resetForm({});
        setSelectedTests([]);
        setPrescriptionUrls([]);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Formik
        initialValues={{
          provider_id: "" || null,
          partner_order_id: "",
          payment_collection: "self",
          lab_visit_type: "",
          full_name: "",
          gender: "",
          age: "",
          mobile: "",
          alt_mobile: "",
          email: "",
          address: "",
          landmark: "",
          pin: "",
          city: "",
          state: "",
          doctor: "",
          time_slot: "" || null,
        }}
        validationSchema={createDiagnosticSchema}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setTouched,
          } = props;
          return (
            <Form>
              <Typography variant="h4">Order Details:</Typography>
              <OrderDetail
                createOrderType={ORDER_TYPE.DIAGNOSTICS}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
              />
              <Typography variant="h4" sx={{ mt: 3 }}>
                Patient Details:
              </Typography>
              <PatientDetail
                createOrderType={ORDER_TYPE.DIAGNOSTICS}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
              />
              <Typography variant="h4" sx={{ mt: 3 }}>
                Address Details:
              </Typography>
              <AddressDetail
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
                setTouched={setTouched}
              />
              <Box sx={{ mt: 3 }}>
                <UploadFiles
                  title="Upload Prescription"
                  uploadedFiles={prescriptionUrls}
                  setUploadedFiles={setPrescriptionUrls}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  fullWidth
                  id="doctor"
                  label="Doctor"
                  size="small"
                  value={values.doctor || ""}
                  onChange={(e) => setFieldValue("doctor", e.target.value)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onBlur={handleBlur}
                />
                <DateTimeSelect
                  time_slot={values.time_slot}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  pickerLabel="Sample Collection Date & Time"
                />
              </Box>

              <Box>
                <SearchAutoComplete
                  sx={{ my: 5 }}
                  selectedValues={selectedTests}
                  setSelectedValues={setSelectedTests}
                  selectedValuesId={selectedTestsId}
                  setSelectedValuesId={setSelectedTestsId}
                  autocompleteLabel="Tests"
                  diagnosticVisitType={values.lab_visit_type}
                />
                <TestsTable
                  data={selectedTests}
                  setSelectedTests={setSelectedTests}
                  selectedTestsId={selectedTestsId}
                  setSelectedTestsId={setSelectedTestsId}
                  isEditing={false}
                  testsTableError={testsTableError}
                  setTestsTableError={setTestsTableError}
                />
              </Box>

              <Box textAlign="center" sx={{ mt: 3 }}>
                <LoadingButton
                  sx={{ width: "180px" }}
                  size="medium"
                  color="primary"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Box>
              <PartnerIdChange setFieldValue={setFieldValue} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreateLabOrder;
