import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import useUserInfo from "../../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  successToast,
} from "../../../utils/common";
import UpdateCertModal from "../../Providers/updateCertModal";

const InfoRowComponent = ({ label, value }: any) => {
  return (
    <>
      {value ? (
        <Typography pt={1} variant="body2">
          <strong>{label}</strong>: {value}
        </Typography>
      ) : null}
    </>
  );
};

const ChangeOnboardingModel = ({
  activeLocationId,
  showChangeOnboarding,
  setShowChangeOnboarding,
}: any) => {
  const [providerInfo, setProviderInfo] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [updating, setUpdating] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const [updateCert, setUpdateCert] = useState(false);
  const [kycLink, setKycLink] = useState("");
  const [businessName, setBusinessName] = useState("");

  const getProviderInfo = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/provider?location_id=${activeLocationId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProviderInfo(result.data.response);
        setBusinessName(result?.data?.response?.provider_name);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProviderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (window.confirm("Are you sure?")) {
      let payload = {
        type: "activate_partner_modal",
        partner: "cashfree",
        provider_id: providerInfo?.provider_id || "",
        location_id: providerInfo?.location_id || "",
        provider_name: providerInfo?.provider_name || "",
        business_name: businessName || "",
        whatsapp_number: providerInfo?.whatsapp_number || "",
        gst_no: providerInfo?.gst_no || "",
        gst_name: providerInfo?.gst_name || "",
        pan_no: providerInfo?.pan_no || "",
        formatted_address: providerInfo?.formatted_address || "",
        city: providerInfo?.city || "",
        state: providerInfo?.state || "",
        pin_code: providerInfo?.pin_code || "",
        created_by: user?.user_id || "",
      };

      try {
        setUpdating(true);
        const result = await privateInstance.post(
          `${API_VERSION.V2}/analytics/digital-payments/providers`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setKycLink(result.data.response?.onboarding_link);
          successToast(result.data.message);
          //setShowChangeOnboarding(false);
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setUpdating(false);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={showChangeOnboarding}
        onClose={() => setShowChangeOnboarding(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowChangeOnboarding(false)}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Change to: Partner Model</Typography>
            <Button
              size="small"
              sx={{ mr: 4 }}
              variant="outlined"
              onClick={() => setUpdateCert(true)}
            >
              View KYC Details
            </Button>
          </Stack>
        </CustomDialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <TextField
                fullWidth
                id="business_name"
                label="Business Name"
                size="small"
                value={businessName}
                sx={{ my: 1 }}
                onChange={(e: any) => setBusinessName(e.target.value)}
                helperText="Note: Please verify the business legal name from the kyc documents"
                error={true}
              />
              <Stack mb={2} direction="row" justifyContent="space-between">
                <Stack>
                  <InfoRowComponent
                    label="Provider Name"
                    value={providerInfo?.provider_name}
                  />
                  <InfoRowComponent
                    label="Whatsapp Number"
                    value={providerInfo?.whatsapp_number}
                  />
                  <InfoRowComponent
                    label="Provider Id"
                    value={providerInfo?.provider_id}
                  />
                  <InfoRowComponent
                    label="Location Id"
                    value={providerInfo?.location_id}
                  />
                </Stack>
                <Stack>
                  <InfoRowComponent
                    label="GST No"
                    value={providerInfo?.gst_no}
                  />

                  <InfoRowComponent
                    label="GST Name"
                    value={providerInfo?.gst_name}
                  />
                  <InfoRowComponent
                    label="PAN No"
                    value={providerInfo?.pan_no}
                  />
                  <InfoRowComponent
                    label="Last App Active"
                    value={parseAndFormateDate(
                      providerInfo?.last_app_active_at
                    )}
                  />
                </Stack>
              </Stack>
              <Divider />
              <Stack mt={1}>
                <InfoRowComponent
                  label="Address"
                  value={providerInfo?.formatted_address}
                />
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    {/* <InfoRowComponent label="Area" value={providerInfo?.area} /> */}
                    <InfoRowComponent label="City" value={providerInfo?.city} />
                    <InfoRowComponent
                      label="Pin Code"
                      value={providerInfo?.pin_code}
                    />
                  </Stack>
                  <Stack>
                    <InfoRowComponent
                      label="Locality"
                      value={providerInfo?.locality}
                    />

                    <InfoRowComponent
                      label="State"
                      value={providerInfo?.state}
                    />
                  </Stack>
                </Stack>
              </Stack>
              <Box textAlign="center" mt={3}>
                {kycLink ? (
                  <Link href={kycLink} target="_blank">
                    KYC LINK
                  </Link>
                ) : (
                  <LoadingButton
                    disabled={updating || !businessName}
                    size="medium"
                    color="primary"
                    loading={updating}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    variant="contained"
                    type="button"
                    onClick={handleSubmit}
                  >
                    CHANGE
                  </LoadingButton>
                )}
              </Box>
            </>
          )}
          {updateCert ? (
            <UpdateCertModal
              updateCert={updateCert}
              setUpdateCert={setUpdateCert}
              activeLocationId={activeLocationId}
              callBack={null}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChangeOnboardingModel;
