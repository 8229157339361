import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ORDER_STATUS } from "../../../../enums/order.enum";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { LoadingButton } from "@mui/lab";
import {
  errorToast,
  handleError,
  successToast,
} from "../../../../utils/common";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import SubmitClaimIcon from "@mui/icons-material/Article";
import UploadFiles from "../../../Common/uploadFiles";
import { useIconStyles } from "../../../Helpers/iconStyles";

const SubmitClaim = ({ order_status, order_id, partner_order_id }: any) => {
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const iconClasses = useIconStyles();

  const handleSubmit = async () => {
    if (
      window.confirm("Are you sure, this will submitted for claim?") === true
    ) {
      let payload: any = {
        partner_order_id: partner_order_id,
        invoice_url: uploadedFiles[0],
      };

      try {
        const result = await privateInstance.post(
          `${API_VERSION.V1}/partner/lombard/consultations/claim`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast("Claim submitted successfully.");
          setShowClaimModal(false);
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Tooltip title="Submit claim">
        <span>
          <IconButton
            color="primary"
            onClick={() => setShowClaimModal(true)}
            disabled={
              order_status === ORDER_STATUS.PAYMENT_COLLECTED ||
              order_status === ORDER_STATUS.BOOKING_CONFIRMED ||
              order_status === ORDER_STATUS.INVOICE_SUBMITTED ||
              order_status === ORDER_STATUS.CLAIM_SUBMITTED ||
              order_status === ORDER_STATUS.PRESCRIPTION_UPLOADED
            }
          >
            <SubmitClaimIcon className={iconClasses.borderedIcon} />
          </IconButton>
        </span>
      </Tooltip>
      {showClaimModal && (
        <Dialog
          open={showClaimModal}
          onClose={() => setShowClaimModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setShowClaimModal(false)}
          >
            Upload Medpay Invoice - {partner_order_id}
          </CustomDialogTitle>
          <DialogContent>
            <UploadFiles
              title=""
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              orderId={order_id}
              uploadType="claim"
            />
            <Typography variant="h4" color="error" mt={2}>
              Please read carefully:
            </Typography>
            <Typography variant="body2" color="error" mt={1}>
              1. Please download the medpay invoice in .pdf format:
            </Typography>
            <Box sx={{ textAlign: "center", mt: 3 }}>
              <LoadingButton
                type="button"
                color="primary"
                variant="contained"
                loading={loading}
                disabled={uploadedFiles.length === 0}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                onClick={() => handleSubmit()}
              >
                Submit for claim
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SubmitClaim;
