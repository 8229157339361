import { useSelector } from "react-redux";
import { AuthenticatedUser, StoredUser } from "../interfaces/user.model";

const useUserInfo = () => {
  const userData: AuthenticatedUser = useSelector(
    (state: StoredUser) => state.user.authData
  );

  return userData;
};

export default useUserInfo;
