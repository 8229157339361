import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { upiOptionsExtended, valueLimits } from "../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { setAllOrderData } from "../../../../store/actions/useActions";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  validateIntNum,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import FindUpiPhone from "../Common/findUpiByPhone";
import FindUpiQR from "../Common/findUpiByQR";

const ConfirmBookingModal = ({
  confirmBookingModal,
  setConfirmBookingModal,
  orderId,
  partnerOrderId,
  orderPartner,
  orderType,
}: any) => {
  const [qrAvl, setQrAvl] = useState("no");
  const [upiPlatform, setUpiPlatform] = useState("");
  const [upiPhone, setUpiPhone] = useState("");
  const [qrData, setQrData] = useState<any>({});
  const [orderData, setOrderData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [paymentTo, setPaymentTo] = useState<any>("");
  const [paymentVia, setPaymentVia] = useState<any>("");
  const [paymentNumber, setPaymentNumber] = useState<any>("");
  const [txnId, setTxnId] = useState<any>("");
  const [lombardOtp, setLombardOtp] = useState("");
  const [providerPayable, setProviderPayable] = useState("");

  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  useEffect(() => {
    const getOrderByPartnerOrderId = async () => {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          orderPartner
        )?.toLowerCase()}/${partnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
      }
    };

    getOrderByPartnerOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderType === ORDER_TYPE.CONSULTATION) {
      if (orderData?.invoice_details?.bill_amount) {
        if (orderData.invoice_details.discount_pct) {
          let providerPayableDiscount =
            Number.parseFloat(orderData.invoice_details.bill_amount) *
            (Number.parseFloat(orderData.invoice_details.discount_pct) / 100);
          let providerPayable =
            Number.parseFloat(orderData.invoice_details.bill_amount) -
            providerPayableDiscount;
          setProviderPayable(providerPayable.toFixed(2));
        } else {
          setProviderPayable(orderData.invoice_details.bill_amount);
        }
      }
    } else {
      setProviderPayable(orderData?.invoice_details?.net_amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  const handleConfirmBooking = async () => {
    let payload: any = {
      order_id: orderId,
      type: `${orderType}-booking-confirmed`,
      order_status: "booking_confirmed",
    };

    let paymentMode;
    if (qrData?.upi_id != null) {
      paymentMode = "postpaid";
    } else {
      paymentMode = "prepaid";
    }

    let provider_payment_details: any;
    if (paymentMode === "prepaid") {
      if (paymentTo == null || paymentTo.length === 0) {
        alert("Please select to whom payment is to be done");
        return;
      } else if (paymentNumber?.length === 0) {
        alert("Please enter number on which payment is to be done");
        return;
      } else if (paymentNumber?.length <= 9) {
        alert("Number should not be less than 10 digit");
        return;
      } else if (paymentNumber?.length > 10) {
        alert("Number should not be greater than 10 digit");
        return;
      } else if (paymentVia == null || paymentVia.length === 0) {
        alert("Please select payment method");
        return;
      } else if (txnId?.length === 0) {
        alert("Please enter transaction number");
        return;
      } else {
        provider_payment_details = {
          provider_payable:
            orderData?.order_type === ORDER_TYPE.CONSULTATION
              ? providerPayable
              : orderData?.invoice_details?.net_amount,
          upi_id: "",
          transaction_id: txnId,
          status: "success",
          patient_captured_qr: "",
          payment_mode: paymentMode,
          prepaid_details: {
            payment_to: paymentTo,
            payment_number: paymentNumber,
            payment_via: paymentVia,
            transaction_id: txnId,
          },
        };
      }
    } else {
      provider_payment_details = {
        provider_payable:
          orderData?.order_type === ORDER_TYPE.CONSULTATION
            ? providerPayable
            : orderData?.invoice_details?.net_amount,
        upi_id: qrData?.upi_id,
        transaction_id: "",
        status: "pending",
        patient_captured_qr: "",
        payment_mode: paymentMode,
        verify_upi_id_holder: qrData?.account_holder_name,
      };
    }

    payload = {
      ...payload,
      provider_payment_details: provider_payment_details,
    };

    //PARTNER SPECIFIC: LOMBARD
    if (orderData?.order_partner === "lombard") {
      payload = {
        ...payload,
        otp: lombardOtp,
      };
      if (providerPayable == null || providerPayable.length === 0) {
        alert("Please enter provider payable amount");
        return;
      } else if (providerPayable === "0") {
        alert("Please enter correct provider payable amount");
        return;
      } else if (lombardOtp.length === 0) {
        alert("Please enter OTP");
        return;
      } else if (lombardOtp.length > 6) {
        alert("Please enter valid OTP");
        return;
      }
    }

    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order status updated as booking confirmed");
        setConfirmBookingModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={confirmBookingModal}
      onClose={() => setConfirmBookingModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setConfirmBookingModal(false)}
      >
        Partner Order ID: {partnerOrderId}
      </CustomDialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="baseline">
          <Box sx={{ width: "35%" }}>
            <Typography variant="h6">QR Code / UPI Available :</Typography>
          </Box>
          <FormControl disabled size="small" sx={{ mt: 1.5, minWidth: "65%" }}>
            <Select
              labelId="confirmation-type-label"
              name="confirmation_type"
              id="confirmation_type"
              value={qrAvl}
              onChange={(e: SelectChangeEvent) => {
                setQrAvl(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {qrAvl === "yes" && (
          <>
            {qrData?.upi_id ? (
              <Stack>
                <Stack direction="row" alignItems="baseline">
                  <Box sx={{ width: "35%" }}>
                    <Typography variant="h6">UPI ID :</Typography>
                  </Box>
                  <Box sx={{ mt: 1.5, width: "65%" }}>
                    <TextField
                      fullWidth
                      id="upi_id"
                      size="small"
                      value={qrData?.upi_id}
                      disabled
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="baseline">
                  <Box sx={{ width: "35%" }}>
                    <Typography variant="h6">UPI Name :</Typography>
                  </Box>
                  <Box sx={{ mt: 1.5, width: "65%" }}>
                    <TextField
                      fullWidth
                      id="upi_name"
                      size="small"
                      value={qrData?.account_holder_name}
                      disabled
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="baseline">
                  <Box sx={{ width: "35%" }}>
                    <Typography variant="h6">Payment Mode :</Typography>
                  </Box>
                  <Box sx={{ mt: 1.5, width: "65%" }}>
                    <TextField
                      fullWidth
                      id="payment_mode"
                      size="small"
                      value="Postpaid"
                      disabled
                    />
                  </Box>
                </Stack>
                <Stack direction="row" alignItems="baseline">
                  <Box sx={{ width: "35%" }}>
                    <Typography variant="h6">Provider Payable :</Typography>
                  </Box>
                  <Box sx={{ mt: 1.5, width: "65%" }}>
                    <TextField
                      fullWidth
                      id="provider_payable"
                      size="small"
                      value={providerPayable}
                      disabled={orderData?.order_partner !== "lombard"}
                      onChange={(e: any) => setProviderPayable(e.target.value)}
                    />
                  </Box>
                </Stack>
              </Stack>
            ) : (
              <Stack>
                <Stack direction="row" alignItems="baseline">
                  <Box sx={{ width: "35%" }}>
                    <Typography variant="h6">
                      1. In case of QR Code :
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1.5, width: "65%" }}>
                    <FindUpiQR
                      qrData={qrData}
                      setQrData={setQrData}
                      setFieldValue={undefined}
                    />
                  </Box>
                </Stack>
                <Typography variant="h4" textAlign="center" sx={{ mt: 1.5 }}>
                  OR
                </Typography>
                <Stack direction="row" alignItems="baseline">
                  <Box sx={{ width: "35%" }}>
                    <Typography variant="h6">
                      2. In case of UPI No. :
                    </Typography>
                  </Box>
                  <Box sx={{ width: "65%" }}>
                    <FindUpiPhone
                      upiSearchData={qrData}
                      upiPhone={upiPhone}
                      upiPlatform={upiPlatform}
                      setUpiPhone={setUpiPhone}
                      setUpiPlatform={setUpiPlatform}
                      setUpiSearchData={setQrData}
                    />
                  </Box>
                </Stack>
              </Stack>
            )}
          </>
        )}

        {qrAvl === "no" && (
          <Stack>
            <Stack direction="row" alignItems="baseline">
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">Payment Mode :</Typography>
              </Box>
              <Box sx={{ mt: 1.5, width: "65%" }}>
                <TextField
                  fullWidth
                  id="payment_mode"
                  size="small"
                  value="Prepaid"
                  disabled
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">Provider Payable :</Typography>
              </Box>
              <Box sx={{ mt: 1.5, width: "65%" }}>
                <TextField
                  fullWidth
                  id="provider_payable"
                  size="small"
                  value={providerPayable}
                  disabled={orderData?.order_partner !== "lombard"}
                  onChange={(e: any) => setProviderPayable(e.target.value)}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">Payment To :</Typography>
              </Box>
              <Box sx={{ width: "65%" }}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                >
                  <Select
                    labelId="payment-to-label"
                    name="payment_to"
                    id="payment_to"
                    value={paymentTo}
                    onChange={(e: SelectChangeEvent) => {
                      setPaymentTo(e.target.value);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="provider">Provider</MenuItem>
                    <MenuItem value="customer">Customer</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">Payment Number :</Typography>
              </Box>
              <Box sx={{ mt: 1.5, width: "65%" }}>
                <TextField
                  fullWidth
                  id="payment_number"
                  size="small"
                  value={paymentNumber}
                  onChange={(e) => setPaymentNumber(e.target.value)}
                />
              </Box>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">Payment Via :</Typography>
              </Box>
              <Box sx={{ width: "65%" }}>
                <FormControl
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                >
                  <Select
                    name="payment_via"
                    id="payment_via"
                    value={paymentVia}
                    onChange={(e: SelectChangeEvent) => {
                      setPaymentVia(e.target.value);
                    }}
                  >
                    {Object.entries(upiOptionsExtended).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <Stack direction="row" alignItems="baseline">
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">Transaction ID :</Typography>
              </Box>
              <Box sx={{ mt: 1.5, width: "65%" }}>
                <TextField
                  fullWidth
                  id="txn_id"
                  size="small"
                  value={txnId}
                  onChange={(e) => setTxnId(e.target.value)}
                />
              </Box>
            </Stack>
          </Stack>
        )}

        {/* PARTNER SPECIFIC: LOMBARD */}
        {orderData?.order_partner === "lombard" ? (
          <Stack>
            <Stack
              direction="row"
              mt={3}
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ width: "35%" }}>
                <Typography variant="h6">OTP :</Typography>
              </Box>
              <TextField
                fullWidth
                id="lombard_otp"
                size="small"
                value={lombardOtp}
                onKeyDown={(evt: any) =>
                  (evt.key === "e" || evt.key === "E" || evt.key === ".") &&
                  evt.preventDefault()
                }
                onChange={(e: any) =>
                  setLombardOtp(validateIntNum(e.target.value, valueLimits.PIN))
                }
                sx={{ width: "65%" }}
              />
            </Stack>
            <Box mt={5}>
              <Typography variant="body1" color="error">
                Please read carefully :-{" "}
              </Typography>
              <Box mt={2}>
                <ol type="1" style={{ paddingLeft: "1rem" }}>
                  <li color="error" style={{ color: "#d32f2f" }}>
                    Verification code has been sent over email from IHealthCare
                    with subject - <b>Verification code for claim approval</b>
                  </li>
                </ol>
              </Box>
            </Box>
          </Stack>
        ) : null}

        <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={loading}
            disabled={
              !qrAvl ||
              (qrAvl === "yes"
                ? qrData?.account_holder_name
                  ? false
                  : true
                : false)
            }
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={handleConfirmBooking}
            sx={{ maxWidth: "200px" }}
          >
            Confirm Booking
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmBookingModal;
