import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import KnowlarityCall from "../../../../components/Common/knowlarityCall";
import EditIcon from "@mui/icons-material/Edit";
import EditBankDetailsModal from "../../../Dunzo/Providers/editBankDetailsModal";
import EditAddressModal from "../../../Providers/editAddressModal";
import EditGstModal from "../../../Providers/editGstModal";
import { useIconStyles } from "../../../../components/Helpers/iconStyles";
import { parseAndFormateDate } from "../../../../utils/common";
import useUserPermissions from "../../../../hooks/useUserPermissions";

const useStyles = makeStyles({
  accordion_collapsed: {
    borderRadius: "4px",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
});

const InfoRowComponent = ({ label, value, color }: any) => {
  return (
    <>
      {value ? (
        <Typography color={color} pt={1} variant="body2">
          <strong>{label}</strong>: {value}
        </Typography>
      ) : null}
    </>
  );
};

const Summary = ({ providerInfo, setProviderInfo }: any) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [showEditBankDetailsModal, setShowEditBankDetailsModal] =
    useState<boolean>(false);
  const [showEditAddressModal, setShowEditAddressModal] =
    useState<boolean>(false);
  const [showEditGstModal, setShowEditGstModal] = useState<boolean>(false);
  const classes = useStyles();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const iconClasses = useIconStyles();
  const userPermissions = useUserPermissions();
  const moduleScope = useMemo(
    () =>
      userPermissions?.find((item: any) => item.permission_id === "merchants")
        ?.scope,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <>
        {/* metadata */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
            mt: 1.5,
          }}
          className={classes.accordion_collapsed}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              background: "#f6f6f6",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: expanded === "panel1" ? "#0058ff" : "#000",
                }}
              >
                Overview
              </Typography>
              {moduleScope?.includes("edit") ? (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEditGstModal(true);
                  }}
                >
                  <EditIcon className={iconClasses.borderedIcon} />
                </IconButton>
              ) : null}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {providerInfo?.agreement_link ? (
              <Link target="_blank" href={providerInfo?.agreement_link}>
                Agreement Link
              </Link>
            ) : null}
            <Stack direction="row" justifyContent="space-between">
              <Stack width={{ xs: "100%", md: "50%" }}>
                <InfoRowComponent
                  label="Provider Name"
                  value={providerInfo?.provider_name}
                />
                <InfoRowComponent
                  label="Specialties"
                  value={providerInfo?.specialties?.join(", ")}
                />
                <InfoRowComponent label="Status" value={providerInfo?.status} />
                <InfoRowComponent
                  label="Provider Id"
                  value={providerInfo?.provider_id}
                />
                <InfoRowComponent
                  label="Location Id"
                  value={providerInfo?.location_id}
                />
                <InfoRowComponent label="GST No" value={providerInfo?.gst_no} />
                <InfoRowComponent label="PAN No" value={providerInfo?.pan_no} />
                <InfoRowComponent
                  label="Ambulance Service"
                  value={providerInfo?.ambulance_service}
                />
              </Stack>
              <Stack>
                <InfoRowComponent
                  label="Provider Type"
                  value={providerInfo?.provider_type}
                />
                <InfoRowComponent
                  label="Whatsapp Number"
                  value={
                    <KnowlarityCall
                      order={{
                        order_id: providerInfo?.location_id,
                        mobile: providerInfo?.whatsapp_number,
                      }}
                      showNumber={true}
                      renderSmallIcon={true}
                      service="merchants"
                    />
                  }
                />
                {providerInfo?.app_version ? (
                  <>
                    <InfoRowComponent
                      label="Pharmacy App"
                      value={providerInfo?.app_version}
                      color="primary"
                    />
                    <InfoRowComponent
                      label="Last App Active"
                      value={parseAndFormateDate(
                        providerInfo?.last_app_active_at
                      )}
                    />
                  </>
                ) : null}
                <InfoRowComponent
                  label="GST Name"
                  value={providerInfo?.gst_name}
                />
                <InfoRowComponent
                  label="Orders Fulfilled"
                  value={providerInfo?.orders_fulfilled}
                />
                <InfoRowComponent
                  label="Home Service"
                  value={providerInfo?.home_service}
                />
              </Stack>
            </Stack>
            {providerInfo?.registration_details?.registered_with ||
            providerInfo?.registration_details?.registration_licence_no ||
            providerInfo?.registration_details?.year_of_registration ? (
              <>
                <Typography pt={1} variant="body2" fontWeight="bold">
                  REGISTRATION DETAILS:
                </Typography>
                <Box pl={3}>
                  <InfoRowComponent
                    label="Registered With"
                    value={providerInfo?.registration_details?.registered_with}
                  />
                  <InfoRowComponent
                    label="Registration License No"
                    value={
                      providerInfo?.registration_details
                        ?.registration_licence_no
                    }
                  />
                  <InfoRowComponent
                    label="Year of Registration"
                    value={
                      providerInfo?.registration_details?.year_of_registration
                    }
                  />
                </Box>
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>
        {/* address info */}
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
            mt: 1.5,
          }}
          className={classes.accordion_collapsed}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel1bh-header"
            sx={{
              background: "#f6f6f6",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: expanded === "panel2" ? "#0058ff" : "#000",
                }}
              >
                Address
              </Typography>
              {moduleScope?.includes("edit") ? (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEditAddressModal(true);
                  }}
                >
                  <EditIcon className={iconClasses.borderedIcon} />
                </IconButton>
              ) : null}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <InfoRowComponent
              label="Address"
              value={providerInfo?.formatted_address}
            />
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <InfoRowComponent label="Area" value={providerInfo?.area} />
                <InfoRowComponent label="City" value={providerInfo?.city} />
                <InfoRowComponent
                  label="Pin Code"
                  value={providerInfo?.pin_code}
                />
              </Stack>
              <Stack>
                <InfoRowComponent
                  label="Locality"
                  value={providerInfo?.locality}
                />

                <InfoRowComponent label="State" value={providerInfo?.state} />

                <InfoRowComponent
                  label="Coordinates"
                  value={
                    providerInfo?.latitude
                      ? `${providerInfo?.latitude}, ${providerInfo?.longitude}`
                      : ""
                  }
                />
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        {/* account info */}
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
            mt: 1.5,
          }}
          className={classes.accordion_collapsed}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel1bh-header"
            sx={{
              background: "#f6f6f6",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: expanded === "panel3" ? "#0058ff" : "#000",
                }}
              >
                Account
              </Typography>
              {moduleScope?.includes("edit") ? (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowEditBankDetailsModal(true);
                  }}
                >
                  <EditIcon className={iconClasses.borderedIcon} />
                </IconButton>
              ) : null}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <InfoRowComponent
                  label="Settlement Mode"
                  value={providerInfo?.settlement_mode}
                />
                <InfoRowComponent
                  label="Account No"
                  value={providerInfo?.account_no}
                />
                <InfoRowComponent
                  label="Account Name"
                  value={providerInfo?.account_name}
                />
                <InfoRowComponent
                  label="IFSC Code"
                  value={providerInfo?.ifsc_code}
                />
                <InfoRowComponent label="UPI ID" value={providerInfo?.upi_id} />
              </Stack>
              <Stack>
                {providerInfo?.static_qr_enabled ? (
                  <InfoRowComponent
                    label="Static QR Settlement Mode"
                    value={providerInfo?.static_qr_settlement_mode}
                  />
                ) : null}
                <InfoRowComponent
                  label="Account Type"
                  value={providerInfo?.account_type}
                />
                <InfoRowComponent
                  label="Bank Name"
                  value={providerInfo?.bank_name}
                />
                <InfoRowComponent
                  label="Branch Name"
                  value={providerInfo?.branch_name}
                />
                <InfoRowComponent
                  label="UPI Name"
                  value={providerInfo?.upi_name}
                />
              </Stack>
            </Stack>

            {providerInfo?.tds_tcs_details ? (
              <>
                <Typography pt={1} variant="body2" fontWeight="bold">
                  TDS TCS DETAILS:
                </Typography>
                <Box pl={3}>
                  {/* <InfoRowComponent
                        label="Is Enabled"
                        value={providerInfo?.tds_tcs_details?.is_active}
                      /> */}
                  <InfoRowComponent
                    label="TDS"
                    value={`${providerInfo?.tds_tcs_details?.tds_percentage}%`}
                  />
                  <InfoRowComponent
                    label="TCS"
                    value={`${providerInfo?.tds_tcs_details?.tcs_percentage}%`}
                  />
                </Box>
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>
        {showEditBankDetailsModal && (
          <EditBankDetailsModal
            showEditBankDetailsModal={showEditBankDetailsModal}
            setShowEditBankDetailsModal={setShowEditBankDetailsModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={null}
            setProvidersData={null}
            providerType="non_dunzo"
          />
        )}
        {showEditAddressModal && (
          <EditAddressModal
            showEditAddressModal={showEditAddressModal}
            setShowEditAddressModal={setShowEditAddressModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={null}
            setProvidersData={null}
          />
        )}
        {showEditGstModal && (
          <EditGstModal
            showEditGstModal={showEditGstModal}
            setShowEditGstModal={setShowEditGstModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
          />
        )}
      </>
    </div>
  );
};

export default Summary;
