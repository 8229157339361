import { Box, Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { errorToast, handleError, successToast } from "../../utils/common";
import TableSkeleton from "../UI/tableSkeleton";
import EditorModal from "./editorModal";
import { times } from "lodash";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { commonTableOptions } from "../../constants";
import ErrorBoundary from "../Helpers/ErrorBoundary";

interface IPropAssignView {
  ordersToAssign: Array<any>;
  loading: boolean;
  columns: any;
  getOrders: () => void;
}

const AssignView = ({
  ordersToAssign,
  loading,
  columns,
  getOrders,
}: IPropAssignView) => {
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<Array<number>>(
    []
  );
  const [selectedOrderIds, setSelectedOrderIds] = React.useState<Array<string>>(
    []
  );
  const [showEditorModal, setShowEditorModal] = React.useState(false);
  const [editor, setEditor] = React.useState<string | null>(null);
  const [submitting, setSubmitting] = React.useState(false);
  const privateInstance = useAxiosInstance();

  React.useEffect(() => {
    let t = [];
    for (const item of selectedRowIndex) {
      t.push(ordersToAssign[item]?.order_id);
    }
    setSelectedOrderIds(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIndex]);

  const options: any = {
    rowsSelected: selectedRowIndex,
    onRowSelectionChange: (
      rowsSelectedData: any,
      allRows: any,
      rowsSelected: number[]
    ) => {
      setSelectedRowIndex(rowsSelected);
    },
    textLabels: {
      body: {
        noMatch: loading
          ? times(10, (i) => <TableSkeleton key={i} />)
          : "Sorry, there is no matching data to display",
      },
    },
    ...commonTableOptions,
    selectableRows: "multiple",
    selectableRowsOnClick: true,
    customToolbarSelect: () => {},
    searchPlaceholder: "Name/Partner Order ID",
  };

  const user: AuthenticatedUser = useUserInfo();

  const handleSubmit = async () => {
    if (!editor) {
      errorToast("Please select an editor to proceed");
      return;
    }
    setSubmitting(true);
    let payload = {
      orders: selectedOrderIds,
      assigned_to: editor,
      assigned_by: user?.user_id,
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/bulk-update`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        getOrders();
        successToast(
          `Successfully assigned ${selectedOrderIds.length} order(s)`
        );
        setShowEditorModal(false);
        setSelectedRowIndex([]);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ErrorBoundary>
      <Box sx={{ mt: 1.5 }}>
        <MUIDataTable
          title=""
          data={ordersToAssign}
          columns={columns}
          options={options}
        />
        {!loading && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedOrderIds.length > 0 ? false : true}
              onClick={() => setShowEditorModal(true)}
              sx={{ mt: 3 }}
            >
              Choose Editor
            </Button>
          </Box>
        )}
        {showEditorModal && (
          <EditorModal
            showEditorModal={showEditorModal}
            setShowEditorModal={setShowEditorModal}
            editor={editor}
            setEditor={setEditor}
            submitting={submitting}
            handleSubmit={handleSubmit}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default AssignView;
