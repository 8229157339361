import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import LogisticsReqForm from "../../../../pages/Logistics/logisticsReqForm";
import { errorToast, handleError } from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import CombinedInfo from "../../../OrderDetails/combinedInfo";

const BookLogisticsModal = ({
  activePartner,
  activePartnerOrderId,
  showBookLogisticsModal,
  setShowBookLogisticsModal,
  setParentModal,
}: any) => {
  const [orderData, setOrderData] = useState<any>({});
  const privateInstance = useAxiosInstance();

  const getOrderByPartnerOrderId = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${activePartner}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getOrderByPartnerOrderId();

    return () => {
      setOrderData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={showBookLogisticsModal}
      onClose={() => setShowBookLogisticsModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowBookLogisticsModal(false)}
      >
        {"Book Logistics"}
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        {Object.keys(orderData)?.length > 0 ? (
          <>
            <CombinedInfo
              orderData={orderData}
              setOrderData={setOrderData}
              setOrderDataModal={null}
              hideStatus={true}
              hideFirstRow={true}
            />
            <Box my={2}>
              <Divider />
            </Box>
            <LogisticsReqForm
              orderData={orderData}
              setOrderData={setOrderData}
              setParentModal={setParentModal}
            />
          </>
        ) : (
          <CircularProgress color="primary" />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BookLogisticsModal;
