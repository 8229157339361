import { Stack, Typography } from "@mui/material";
import React from "react";

const PaymentDataRow: React.FC<{
  dataTitle: string;
  value: string | number;
  smallFont?: boolean;
}> = ({ dataTitle, value, smallFont }) => {
  return (
    <>
      {value && (
        <Stack direction="row" justifyContent="space-between" pt={1}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ fontSize: smallFont ? "12px !important" : "" }}
          >
            {dataTitle} :
          </Typography>
          <Typography
            color={
              dataTitle.includes("TCS") || dataTitle.includes("TDS")
                ? "error"
                : ""
            }
            variant="body1"
            sx={{ fontSize: smallFont ? "12px !important" : "" }}
          >
            {dataTitle.includes("Amount") ||
            dataTitle.includes("Other") ||
            dataTitle.includes("Fees") ? (
              <span>&#8377; {value}</span>
            ) : (
              value
            )}
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default PaymentDataRow;
