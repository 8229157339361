import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import {
  errorToast,
  handleError,
  textCapitalize,
  validateIntNum,
} from "../../../../utils/common";
import { valueLimits } from "../../../../constants";
import AddressDetail from "../../../CreateOrder/addressDetail";
import { Box } from "@mui/system";
import MerchantGstValidate from "./merchantGstValidate";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import {
  addConsLabProviderSchema,
  addPharmProviderSchema,
} from "../../../../schemas/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";

const AddProviderModal = ({
  addProviderModal,
  setAddProviderModal,
  orderType,
  orderPartner,
  providersList,
  setProvidersList,
  selectedProviders,
  setSelectedProviders,
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const privateInstance = useAxiosInstance();

  const checkIfProviderExists = async (val: string, searchType: string) => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/location/search?by=${searchType}&value=${val}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (Object.keys(result.data.response)?.length > 0) {
          errorToast("Provider already exists, adding the same provider!");
          const found = providersList?.some(
            (el: any) =>
              el.mobile === result.data.response.mobile ||
              el.gst_no === result.data.response.gst_no
          );
          if (!found) {
            setProvidersList([...providersList, result.data.response]);
          }
          //update selected providers only for meds
          if (orderType === ORDER_TYPE.MEDICINES) {
            const found = selectedProviders?.some(
              (el: any) =>
                el.mobile === result.data.response.mobile ||
                el.gst_no === result.data.response.gst_no
            );
            if (!found) {
              setSelectedProviders([
                ...selectedProviders,
                result.data.response,
              ]);
            }
          }
          setAddProviderModal(false);
          return true;
        }
        return false;
      }
      return false;
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (values: any, actions: any) => {
    if (await checkIfProviderExists(values.mobile, "whatsapp_number")) {
      return;
    }
    if (
      orderType === ORDER_TYPE.MEDICINES &&
      !values?.provider_name?.toLowerCase()?.includes("apollo pharmacy") &&
      values.gst_no?.trim()?.toLowerCase() !== "na"
    ) {
      if (await checkIfProviderExists(values.gst_no, "gst_no")) {
        return;
      }
    }
    let payload: any = {
      name: values?.provider_name,
      merchant_type: values?.provider_type,
      home_service: values?.home_service === true ? "yes" : "no",
      mobile: values?.mobile,
      whatsapp_number: values?.whatsapp,
      address: `${values?.address}, ${values.landmark}, ${values.city}, ${values.state} - ${values.pin}`,
      formatted_address: `${values?.address}, ${values.landmark}, ${values.city}, ${values.state} - ${values.pin}`,
      location_id: "added_manually",
      provider_id: "added_manually",
      pin_code: values?.pin,
    };
    if (orderType === ORDER_TYPE.MEDICINES) {
      payload = {
        ...payload,
        gst_no: values?.gst_no,
        onboarded: "no",
        from_search: "no",
      };
    }
    const found = providersList?.some(
      (el: any) => el.mobile === payload.mobile
    );
    if (!found) {
      setProvidersList([...providersList, payload]);
    }
    setAddProviderModal(false);
  };

  return (
    <Dialog
      open={addProviderModal}
      onClose={() => setAddProviderModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setAddProviderModal(false)}
      >
        Add Provider -{" "}
        {textCapitalize(
          orderType === ORDER_TYPE.MEDICINES ? "pharmacy" : orderType
        )}
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            provider_id: orderPartner,
            provider_name: "",
            provider_type:
              orderType === ORDER_TYPE.CONSULTATION
                ? "clinic"
                : orderType === ORDER_TYPE.DIAGNOSTICS
                ? "diagnostic center"
                : "pharmacy",
            home_service: false,
            mobile: "",
            whatsapp: "",
            address: "",
            landmark: "",
            pin: "",
            city: "",
            state: "",
            gst_no: "",
            company: "",
          }}
          validationSchema={
            orderType === ORDER_TYPE.MEDICINES
              ? addPharmProviderSchema
              : addConsLabProviderSchema
          }
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
              setTouched,
            } = props;
            return (
              <Form>
                <Typography variant="h4" sx={{ mt: 3 }}>
                  Provider Details:
                </Typography>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  alignItems="baseline"
                >
                  <TextField
                    fullWidth
                    id="provider_name"
                    label="Provider Name"
                    size="small"
                    value={values.provider_name || ""}
                    onChange={(e) =>
                      setFieldValue("provider_name", e.target.value)
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.provider_name && touched.provider_name
                        ? errors.provider_name
                        : ""
                    }
                    error={
                      errors.provider_name && touched.provider_name
                        ? true
                        : false
                    }
                    inputProps={{
                      maxLength: valueLimits.NAME_LEN,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="provider_type"
                    label="Provider Type"
                    size="small"
                    value={values.provider_type || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    disabled
                  />
                  <FormControlLabel
                    label="Home Service"
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 }, minWidth: "200px" }}
                    control={
                      <Switch
                        id="home_service"
                        checked={values.home_service}
                        onChange={(e) =>
                          setFieldValue("home_service", e.target.checked)
                        }
                      />
                    }
                  />
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }}>
                  <TextField
                    fullWidth
                    id="mobile"
                    label="Mobile"
                    size="small"
                    value={values.mobile || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "mobile",
                        validateIntNum(e.target.value, valueLimits.MOBILE)
                      )
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.mobile && touched.mobile ? errors.mobile : ""
                    }
                    error={errors.mobile && touched.mobile ? true : false}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="whatsapp"
                    label="Whatsapp"
                    size="small"
                    value={values.whatsapp || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "whatsapp",
                        validateIntNum(e.target.value, valueLimits.MOBILE)
                      )
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.whatsapp && touched.whatsapp ? errors.whatsapp : ""
                    }
                    error={errors.whatsapp && touched.whatsapp ? true : false}
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Stack>
                {orderType === ORDER_TYPE.MEDICINES && (
                  <MerchantGstValidate
                    gst_no={values.gst_no}
                    company={values.company}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    allowWhiteSpace={false}
                  />
                )}
                <Typography variant="h4" sx={{ mt: 3 }}>
                  Address Details:
                </Typography>
                <AddressDetail
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  formData={values}
                  setTouched={setTouched}
                />
                <Box textAlign="center" sx={{ mt: 3 }}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={loading}
                    disabled={isSubmitting}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddProviderModal;
