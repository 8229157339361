import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { addDays, differenceInDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../../components/Common/customDialogTitle";
import {
  errorToast,
  handleError,
  successToast,
} from "../../../../utils/common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportsTable from "../../../../pages/Reports/reportsTable";

const EmailReportModalHeader = ({
  date,
  email,
  showEmailModalHeader,
  setShowEmailModalHeader,
}: any) => {
  const [startDate, setStartDate] = useState<any>(new Date(date));
  const [endDate, setEndDate] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [incPayout, setIncPayout] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let payload = {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        include_payout_details: incPayout,
        email: email,
      };
      const result = await privateInstance.post(
        `${API_VERSION.V2}/analytics/digital-payments/transactions`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Email sent successfully!");
        setShowEmailModalHeader(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err: any) {
      if (err?.response?.status === 500) {
        successToast("Email sent successfully!");
        setShowEmailModalHeader(false);
      } else {
        handleError(err);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEndDate(new Date(date));
  }, [date]);

  return (
    <div>
      <Dialog
        open={showEmailModalHeader}
        onClose={() => setShowEmailModalHeader(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowEmailModalHeader(false)}
        >
          Transactions Report
        </CustomDialogTitle>
        <DialogContent>
          <Accordion
            sx={{
              border: "1px solid #eeeeee",
              borderRadius: "4px",
              boxShadow: "0px 0.25rem 0.5rem rgba(0, 0, 0, 0.05)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="primary" variant="h6">
                Generate New Report
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    inputFormat="dd/MM/yyyy"
                    value={startDate}
                    onChange={(newValue: Date | null) => {
                      setStartDate(newValue);
                      setEndDate(null);
                    }}
                    minDate={new Date("March 01, 2024")}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ mt: 1.5, mr: 1.5 }}
                        name="start_date"
                        InputLabelProps={{
                          required: true,
                        }}
                        autoComplete="off"
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                      />
                    )}
                  />
                  <DatePicker
                    label="End Date"
                    inputFormat="dd/MM/yyyy"
                    value={endDate}
                    onChange={(newValue: Date | null) => {
                      setEndDate(newValue);
                    }}
                    minDate={addDays(startDate, 0)}
                    maxDate={
                      differenceInDays(new Date(), startDate) < 1
                        ? new Date()
                        : addDays(startDate, 30)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ mt: 1.5 }}
                        name="end_date"
                        InputLabelProps={{
                          required: true,
                        }}
                        autoComplete="off"
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px !important" }}
                >
                  Reports will be emailed on: {email}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "10px !important" }}
                >
                  1 Month
                </Typography>
              </Box>
              <FormControlLabel
                sx={{ width: "100%", mt: 0.5 }}
                control={
                  <Checkbox
                    checked={incPayout}
                    onChange={(e: any) => {
                      setIncPayout(e.target.checked);
                    }}
                  />
                }
                label="Include Payout/Coins Details"
              />
              <Box textAlign="center" mt={3}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={loading}
                  disabled={!startDate || !endDate}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  onClick={handleSubmit}
                >
                  Submit
                </LoadingButton>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Box sx={{ mt: 3 }}>
            <ReportsTable reportType="omnistores_transaction_report" />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailReportModalHeader;
