import React, { useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { validateFloatNum } from "../../../utils/common";
import { valueLimits } from "../../../constants";

const TestsTable = ({
  data,
  setSelectedTests,
  selectedTestsId,
  setSelectedTestsId,
  isEditing,
  testsTableError,
  setTestsTableError,
}: any) => {
  const updateData = (oldIndex: number, item: any) => {
    data.splice(oldIndex, 1, item);
    const updatedData = [...data];
    setSelectedTests(updatedData);
  };

  const deleteItem = (index: number, testId: number | string) => {
    let allTests = [...data];
    allTests.splice(index, 1);
    setSelectedTests([...allTests]);

    let allTestsId = [...selectedTestsId];
    allTestsId.splice(index, 1);
    setSelectedTestsId([...allTestsId]);
  };

  let subTotal: number = 0;
  data.forEach((item: any) => {
    subTotal += Number(item.MRP);
  });

  useEffect(() => {
    let errors: any = [];
    data?.forEach((test: any, index: number) => {
      let MRP: boolean = false;
      if (Number(test.MRP) < 1) {
        MRP = true;
      }
      errors.push({
        MRP: MRP,
      });
    });
    setTestsTableError([...errors]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {data.map((x: any, index: number) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: 1.5,
            }}
            key={index}
          >
            <TextField
              fullWidth
              id="item-name"
              size="small"
              label="Item Name"
              value={x.item}
              disabled
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              //   onChange={(event) => {
              //     const item = {
              //       ...x,
              //       name: event.target.value,
              //     };
              //     updateData(index, item);
              //   }}
            />
            <TextField
              fullWidth
              id="item-detail"
              size="small"
              label="Item Detail"
              value={x.details}
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              onChange={(event) => {
                const item = {
                  ...x,
                  details: event.target.value,
                };
                updateData(index, item);
              }}
              disabled={isEditing}
            />
            <TextField
              fullWidth
              id="item-price"
              size="small"
              label="Price"
              value={x.MRP}
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              onChange={(event) => {
                const item = {
                  ...x,
                  MRP: validateFloatNum(event.target.value, valueLimits.MRP),
                };
                updateData(index, item);
              }}
              error={testsTableError ? testsTableError[index]?.MRP : false}
              //helperText={x.MRP === "" ? "Price cannot be empty" : ""}
            />
            {!isEditing && (
              <DeleteIcon
                sx={{ alignSelf: "center", cursor: "pointer" }}
                onClick={() => deleteItem(index, x.test_id)}
              />
            )}
          </Box>
        );
      })}
      {subTotal > 0 && (
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            justifyContent: "end",
            marginRight: 20,
            mt: 1.5,
          }}
        >
          Total: {subTotal.toFixed(2)}
        </Typography>
      )}
    </>
  );
};

export default TestsTable;
