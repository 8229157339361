import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { valueLimits } from "../../constants";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  updateInvoiceSchema,
  updateInvoiceSchemaRestricted,
} from "../../schemas/common";
import { setAllOrderData } from "../../store/actions/useActions";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  validateFloatNum,
} from "../../utils/common";
import CustomDialogTitle from "../Common/customDialogTitle";

const UpdateInvoiceModal = ({
  updateInvoiceModal,
  setUpdateInvoiceModal,
  setParentModal,
  orderData,
  setOrderData,
  role,
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const updateInvoice = async (values: any) => {
    let payload = {
      order_id: orderData?.order_id,
      revised_bill_amount: values?.updated_bill_amount,
      type: "update-invoice",
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Invoice updated successfully");
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
        //close parent modal and invoice update modal
        if (setParentModal) {
          setParentModal(false);
        }
        setUpdateInvoiceModal(false);
        //update orderdata
        setOrderData(result.data.response);
      } else {
        //close invoice update modal
        setUpdateInvoiceModal(false);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={updateInvoiceModal}
      onClose={() => setUpdateInvoiceModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setUpdateInvoiceModal(false)}
      >
        Update Invoice
      </CustomDialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Formik
          initialValues={{
            bill_amount: orderData?.invoice_details?.bill_amount
              ? orderData?.invoice_details?.bill_amount
              : "0",
            updated_bill_amount: "",
          }}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            updateInvoice(values);
          }}
          validationSchema={
            [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(role)
              ? updateInvoiceSchema
              : () =>
                  updateInvoiceSchemaRestricted(
                    Number(orderData?.invoice_details?.bill_amount) * 1.5
                  )
          }
        >
          {(props: any) => {
            const { values, errors, touched, setFieldValue, handleBlur } =
              props;
            return (
              <Form>
                <Stack mb={3}>
                  <TextField
                    fullWidth
                    id="bill_amount"
                    label="Bill Amount"
                    size="small"
                    value={values?.bill_amount || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    disabled
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="updated_bill_amount"
                    label="Updated Bill Amount"
                    size="small"
                    value={values?.updated_bill_amount || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e) =>
                      setFieldValue(
                        "updated_bill_amount",
                        validateFloatNum(e.target.value, valueLimits.MRP)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.updated_bill_amount && touched.updated_bill_amount
                        ? errors.updated_bill_amount
                        : ""
                    }
                    error={
                      errors.updated_bill_amount && touched.updated_bill_amount
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Stack>
                <Box textAlign="center">
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateInvoiceModal;
