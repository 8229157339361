import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import {
  getMuiTheme,
  parseAndFormateDate,
  textCapitalize,
} from "../../../../utils/common";
import AcceptOrderModal from "./acceptOrdarModal";
import { UpdateNotesIcon } from "../../../../assets/icons";
import NotesModal from "../../notesModal";
import KnowlarityCall from "../../../Common/knowlarityCall";
import { commonTableOptions } from "../../../../constants";
import OrderBadges from "../../orderBadges";
import { ThemeProvider } from "@mui/styles";
import ErrorBoundary from "../../../Helpers/ErrorBoundary";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import StatusTimer from "../../../Helpers/statusTimer";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const AssignedOrders: React.FC<{
  data: any;
  orderType: string;
  getOrders: any;
  dataLoading: boolean;
}> = ({ data, orderType, getOrders, dataLoading }) => {
  const [acceptOrderModal, setAcceptOrderModal] = useState(false);
  const [activeOrderId, setActiveOrderId] = useState("");
  const [activePartner, setActivePartner] = useState("");
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [notesModal, setNotesModal] = useState(false);

  const manageAssignedColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.name",
      label: "CUSTOMER NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.mobile",
      label: "CUSTOMER MOBILE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "consultation_type",
      label: "CONSULTATION DETAILS",
      options: {
        filter: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        display: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        searchable: false,
      },
    },
    {
      name: "sample_collection",
      label: "DIAGNOSTIC DETAILS",
      options: {
        filter: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        display: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        searchable: false,
      },
    },
    {
      name: "time_slot",
      label: "CONSULTATION TIME",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_partner",
      label: "ORDER PARTNER",
      options: {
        filter: true,
        //filterType: "textField" as FilterType,
        searchable: false,
      },
    },
    {
      name: "order_status",
      label: "ORDER STATUS",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "nearby_merchants",
      label: "PHARMACY INFO",
      options: {
        filter: false,
        display: orderType === ORDER_TYPE.MEDICINES ? true : false,
        searchable: false,
      },
    },
    {
      name: "modified_at",
      label: "UPDATED AT",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "ACTION",
      options: {
        filter: false,
        searchable: true,
      },
    },
    {
      name: "priority",
      label: "PRIORITY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "express_delivery",
      label: "EXPRESS DELIVERY",
      options: {
        filter: orderType === ORDER_TYPE.MEDICINES ? true : false,
        display: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: dataLoading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any) => {
      return <CustomRowComponent key={data[0]} rowData={data} />;
    },
    searchPlaceholder: "Name/Partner Order ID/Order ID",
  };

  function CustomRowComponent({ rowData }: any) {
    const [
      partner_order_id,
      customer_name,
      customer_mobile,
      consultation_type,
      sample_collection,
      time_slot,
      order_partner,
      order_status,
      nearby_merchants,
      created_at,
      order_id,
      priority,
      express_delivery,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell
          sx={{
            maxWidth: { sm: "auto", md: "20vw" },
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              sx={{ color: "#0058ff", cursor: "pointer" }}
              onClick={() => {
                setActivePartner(order_partner);
                setActivePartnerOrderId(partner_order_id);
                setActiveOrderId(order_id);
                setAcceptOrderModal(true);
              }}
            >
              {partner_order_id}
            </Typography>
            <OrderBadges
              priority={priority}
              order_status={order_status}
              partner_order_id={partner_order_id}
              express_delivery={express_delivery}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="column">
            <Stack direction="row" alignItems="center">
              <KnowlarityCall
                order={{ order_id: order_id, mobile: customer_mobile }}
                showNumber={false}
              />
              <Typography variant="body2">{customer_name}</Typography>
            </Stack>
          </Stack>
        </TableCell>
        {(orderType === ORDER_TYPE.CONSULTATION ||
          orderType === ORDER_TYPE.DIAGNOSTICS) && (
          <TableCell>
            <Stack>
              <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                {orderType === ORDER_TYPE.CONSULTATION
                  ? textCapitalize(consultation_type)
                  : textCapitalize(sample_collection?.split("_").join(" "))}
              </Typography>
              <Typography variant="body2" sx={{ color: "#718096" }}>
                {time_slot}
              </Typography>
            </Stack>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">{order_partner}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {textCapitalize(order_status?.split("_").join(" "))}
          </Typography>
        </TableCell>
        {orderType === ORDER_TYPE.MEDICINES ? (
          <TableCell>
            {nearby_merchants && nearby_merchants.length > 0 ? (
              <>
                <Stack direction="row">
                  {nearby_merchants?.[0]?.platform === "manual" ? (
                    <Tooltip title={`Platform: Via WhatsApp`}>
                      <WhatsAppIcon
                        sx={{ mr: 0.25 }}
                        color="success"
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Platform: Via App`}>
                      <PhoneAndroidIcon
                        sx={{ mr: 0.25 }}
                        color="success"
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                  {nearby_merchants?.[0]?.static_qr_enabled ? (
                    <Tooltip title="MedPay QR Pharmacy">
                      <QrCode2Icon
                        sx={{ mr: 0.5 }}
                        fontSize="small"
                        color="primary"
                      />
                    </Tooltip>
                  ) : null}
                  <Typography variant="body2">
                    {nearby_merchants[0].name}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{ ml: 2.8 }}
                  color={
                    nearby_merchants[0].status === "rejected"
                      ? "error"
                      : nearby_merchants[0].status === "accepted"
                      ? "orange"
                      : nearby_merchants[0].status === "mrp_filled"
                      ? "green"
                      : ""
                  }
                >
                  {nearby_merchants[0].status}
                </Typography>
              </>
            ) : null}
          </TableCell>
        ) : null}
        <TableCell>
          <Typography variant="body2">
            {parseAndFormateDate(created_at)}
          </Typography>
          <StatusTimer
            timeStamp={created_at}
            timerType="assigned"
            providerPlatform={nearby_merchants?.[0]?.platform}
            deliverySlot={null}
          />
        </TableCell>
        <TableCell>
          <Stack direction="row">
            <IconButton
              color="primary"
              sx={{ fontSize: "22px" }}
              onClick={() => {
                setActiveOrderId(order_id);
                setNotesModal(true);
              }}
            >
              <UpdateNotesIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <Box>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            data={data}
            columns={manageAssignedColumns}
            options={options}
          />
        </ThemeProvider>
        {acceptOrderModal && (
          <AcceptOrderModal
            acceptOrdarModal={acceptOrderModal}
            setAcceptOrderModal={setAcceptOrderModal}
            activePartner={activePartner}
            activePartnerOrderId={activePartnerOrderId}
            activeOrderId={activeOrderId}
          />
        )}
        {notesModal && (
          <NotesModal
            notesModal={notesModal}
            setNotesModal={setNotesModal}
            orderId={activeOrderId}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default AssignedOrders;
