import { Box, Button, Link, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  generateFullAddress,
  parseAndFormateDate,
  statusToText,
} from "../../../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import EditCustomerModal from "../Pending/editCustomerModal";
import EditOrderModal from "../Assign/editOrderModal";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import KnowlarityCall from "../../../Common/knowlarityCall";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import useUserInfo from "../../../../hooks/useUserInfo";
import StoreVisitIcon from "@mui/icons-material/Storefront";
import HomeDeliveryIcon from "@mui/icons-material/DeliveryDining";
import config from "../../../../config";
import LaunchIcon from "@mui/icons-material/Launch";
import PaymentLinkModal from "./paymentLinkModal";

const OrderCustomerInfo = ({
  orderData,
  setOrderData,
  setParentModal,
  disableEdit = false,
}: any) => {
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [customerDetail, setCustomerDetail] = useState<any>(
    orderData?.customer_details
  );
  const [editOrderModal, setEditOrderModal] = useState(false);
  const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const customerAddress = generateFullAddress(
    orderData?.customer_details?.address_details
  );
  const customerCoord =
    orderData?.customer_details?.address_details?.coordinates;

  useEffect(() => {
    return () => {
      setCustomerDetail({});
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <Stack direction="row">
            <Typography variant="h5" sx={{ mb: 1 }}>
              Order Details
            </Typography>
            {orderData?.nearby_merchants &&
              orderData?.nearby_merchants[0] &&
              !disableEdit && (
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<EditIcon />}
                  sx={{ ml: 1, mb: 1 }}
                  onClick={() => setEditOrderModal(true)}
                  disabled={
                    orderData?.order_status === ORDER_STATUS.PARTNER_PENDING
                      ? true
                      : false
                  }
                >
                  Edit
                </Button>
              )}
          </Stack>
          <Box sx={{ display: "flex" }}>
            <Stack>
              <Typography variant="h6">Partner Order id:</Typography>
              <Typography variant="h6">Order type:</Typography>
              {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                <Typography variant="h6">Consultation type:</Typography>
              )}
              {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS && (
                <Typography variant="h6">Diagnostic type:</Typography>
              )}
              {orderData?.order_type === ORDER_TYPE.MEDICINES &&
                orderData.delivery_partner && (
                  <Typography variant="h6">Delivery Type:</Typography>
                )}
              <Typography variant="h6">Partner:</Typography>
              {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                <Typography variant="h6">Speciality:</Typography>
              )}
              <Typography variant="h6">Received at:</Typography>
            </Stack>
            <Stack sx={{ ml: 3 }}>
              <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                {orderData?.partner_order_id}
              </Typography>
              <Typography variant="body1">{orderData?.order_type}</Typography>
              {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                <Typography variant="body1">
                  {orderData?.consultation_type}
                </Typography>
              )}
              {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS && (
                <Typography variant="body1">
                  {statusToText(orderData?.sample_collection)}
                </Typography>
              )}
              {orderData?.order_type === ORDER_TYPE.MEDICINES && (
                <>
                  {orderData.delivery_partner === "medpay" ? (
                    <Stack direction="row" alignItems="center">
                      <Typography pr={0.5} variant="body1">
                        Home Delivery
                      </Typography>
                      <Tooltip title="Pharmacy will deliver the order">
                        <HomeDeliveryIcon fontSize="small" color="primary" />
                      </Tooltip>
                    </Stack>
                  ) : null}
                  {orderData.delivery_partner === "self_pickup" ||
                  orderData.delivery_partner === "logistics" ? (
                    <Stack direction="row" alignItems="center">
                      <Typography pr={0.5} variant="body1">
                        Pick-up Order
                      </Typography>
                      <Tooltip title="Rider will pick-up the order">
                        <StoreVisitIcon fontSize="small" color="primary" />
                      </Tooltip>
                    </Stack>
                  ) : null}
                </>
              )}
              <Typography variant="body1">
                {orderData?.order_partner}
              </Typography>
              {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                <Typography variant="body1">{orderData?.specialty}</Typography>
              )}
              <Typography variant="body1">
                {parseAndFormateDate(orderData?.created_at)}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" }, mt: { xs: 1.5, md: 0 } }}>
          <Stack direction="row">
            <Typography variant="h5" sx={{ mb: 1 }}>
              Customer Details
            </Typography>
            {(!orderData?.nearby_merchants ||
              !orderData?.nearby_merchants[0]) &&
              !disableEdit && (
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<EditIcon />}
                  sx={{ ml: 1, mb: 1 }}
                  onClick={() => setEditCustomerModal(true)}
                >
                  Edit
                </Button>
              )}
          </Stack>
          <Box>
            <Stack direction="row">
              <Typography variant="h6" minWidth="90px">
                Name:
              </Typography>
              <Stack direction="row" alignItems="start">
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {orderData?.customer_details?.name}
                </Typography>
                <Box
                  sx={{
                    mt:
                      user?.partner?.toLowerCase() === "medpay" ? "-6px" : "0",
                  }}
                >
                  <KnowlarityCall
                    order={{
                      order_id: orderData?.order_id,
                      mobile: orderData?.customer_details?.mobile,
                    }}
                    showNumber={false}
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Typography variant="h6" minWidth="90px">
                Mobile:
              </Typography>
              <Typography variant="body1">
                {orderData?.customer_details?.mobile}
              </Typography>
            </Stack>
            {orderData?.customer_details?.email && (
              <Stack direction="row">
                <Typography variant="h6" minWidth="90px">
                  Email:
                </Typography>
                <Typography variant="body1">
                  {orderData?.customer_details?.email}
                </Typography>
              </Stack>
            )}
            {orderData?.order_type !== ORDER_TYPE.MEDICINES && (
              <>
                <Stack direction="row">
                  <Typography variant="h6" minWidth="90px">
                    Age:
                  </Typography>
                  <Typography variant="body1">
                    {orderData?.customer_details?.age}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="h6" minWidth="90px">
                    Gender:
                  </Typography>
                  <Typography variant="body1">
                    {orderData?.customer_details?.gender}
                  </Typography>
                </Stack>
              </>
            )}
            {customerAddress ? (
              <Stack direction="row">
                <Typography variant="h6" minWidth="90px">
                  Address:
                </Typography>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {customerAddress}
                </Typography>
              </Stack>
            ) : null}
            {customerCoord && customerCoord !== "" && customerCoord !== "," ? (
              <Stack direction="row">
                <Typography variant="body1" minWidth="90px">
                  Coordinates:&nbsp;
                </Typography>
                <Link
                  target="_blank"
                  href={`https://www.google.com/maps?q=${customerCoord}`}
                >
                  {customerCoord}
                </Link>
              </Stack>
            ) : null}
          </Box>
        </Box>
      </Box>
      {orderData?.extra1 ? (
        <Typography pt={2}>
          <strong>Partner Instruction: </strong> {orderData.extra1}
        </Typography>
      ) : null}
      {/* PARTNER SPECIFIC: MEDPAY PLUS */}
      {orderData?.order_type === ORDER_TYPE.MEDICINES &&
      orderData?.order_partner === "medpay_plus" &&
      orderData.nearby_merchants?.[0]?.location_id ? (
        <Typography pt={1}>
          <strong>Coupon: </strong>
          <Link
            pt={2}
            style={{ textDecoration: "none" }}
            target="_blank"
            href={`https://${
              config.ENV === "DEV" ? "dev" : "www"
            }.medpay.store/coupon?coupon_id=${orderData.order_id}&loc_id=${
              orderData.nearby_merchants?.[0]?.location_id
            }`}
          >
            View Coupon
          </Link>
        </Typography>
      ) : null}
      {orderData?.order_type === ORDER_TYPE.MEDICINES &&
      orderData?.payment_mode &&
      orderData?.order_status !== ORDER_STATUS.PAYMENT_COLLECTED ? (
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography pt={1}>
            <strong>Payment: </strong>{" "}
            {orderData.payment_mode === "pay_on_delivery"
              ? `Pay on Delivery(Cash/Online)`
              : orderData?.payment_mode === "prepaid"
              ? `Online | Rs.${orderData?.patient_payable_details?.advance_payment?.payable_amount} | ${orderData?.patient_payable_details?.advance_payment?.payment_status}`
              : "--"}
          </Typography>
          {orderData.payment_mode === "pay_on_delivery" ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                mt: 1,
              }}
              onClick={() => setShowPaymentLinkModal(true)}
              endIcon={<LaunchIcon />}
            >
              Link
            </Button>
          ) : null}
        </Stack>
      ) : null}
      {editCustomerModal && (
        <EditCustomerModal
          editCustomerModal={editCustomerModal}
          setEditCustomerModal={setEditCustomerModal}
          customerDetail={customerDetail}
          orderType={orderData?.order_type}
          orderId={orderData?.order_id}
          setParentModal={setParentModal}
        />
      )}
      {editOrderModal && (
        <EditOrderModal
          editOrderModal={editOrderModal}
          setEditOrderModal={setEditOrderModal}
          orderData={orderData}
          setParentModal={setParentModal}
        />
      )}
      {showPaymentLinkModal && (
        <PaymentLinkModal
          show={showPaymentLinkModal}
          setShow={setShowPaymentLinkModal}
          orderData={orderData}
          setOrderData={setOrderData}
        />
      )}
    </>
  );
};

export default OrderCustomerInfo;
