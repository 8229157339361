import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { valueLimits } from "../../../../../constants";
import { mobileUpdateSchema } from "../../../../../schemas/common";
import { validateIntNum } from "../../../../../utils/common";
import CustomDialogTitle from "../../../../Common/customDialogTitle";

const MobileValidateModal = ({
  mobileModal,
  setMobileModal,
  nearbyProviders,
  setNearbyProviders,
  activeProviderIndex,
}: any) => {
  return (
    <Dialog
      open={mobileModal}
      onClose={() => setMobileModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setMobileModal(false)}
      >
        Edit Mobile Number
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            mobile:
              nearbyProviders[activeProviderIndex]?.mobile ||
              nearbyProviders[activeProviderIndex]?.mobile_number ||
              "",
          }}
          validationSchema={mobileUpdateSchema}
          onSubmit={(values: any, actions: any) => {
            actions.resetForm({});
            setMobileModal(false);
            nearbyProviders.splice(activeProviderIndex, 1, {
              ...nearbyProviders[activeProviderIndex],
              mobile: values.mobile,
            });
            const updatedProviders = [...nearbyProviders];
            setNearbyProviders(updatedProviders);
          }}
        >
          {(props: any) => {
            const { values, touched, errors, handleBlur, setFieldValue } =
              props;
            return (
              <Form>
                <TextField
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  size="small"
                  value={values?.mobile || ""}
                  onChange={(e) =>
                    setFieldValue(
                      "mobile",
                      validateIntNum(e.target.value, valueLimits.MOBILE)
                    )
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.mobile && touched.mobile ? errors.mobile : ""
                  }
                  error={errors.mobile && touched.mobile ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                />
                <Box textAlign="center" sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default MobileValidateModal;
