import React from "react";
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Fab,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUpload";
import { errorToast, handleError } from "../../utils/common";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";

const UploadFiles = ({
  title,
  uploadedFiles,
  setUploadedFiles,
  orderId = "",
  uploadType = "",
  allowDel = true,
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const privateInstance = useAxiosInstance();

  const handleFileChange = (e: any) => {
    handleFileUpload(e.target.files);
  };

  const handleFileUpload = async (files: any) => {
    let fileUrls = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (uploadType === "claim") {
        if (file?.type !== "application/pdf") {
          alert("only pdf allowed");
          return;
        }
        if (Number(uploadedFiles.length + i) >= 1) {
          alert("multiple files not allowed");
          return;
        }
      }
      let formData = new FormData();
      formData.append("file", file);
      if (title?.toLowerCase()?.includes("invoice") || uploadType === "claim") {
        formData.append("provider_platform", "dashboard");
      }
      setLoading(true);
      const postUrl =
        title?.toLowerCase()?.includes("invoice") || uploadType === "claim"
          ? `/orders/invoice-upload?order_id=${orderId}`
          : uploadType === "diagnostics_reports"
          ? `/orders/diagnostics/upload-reports`
          : `/orders/rx-upload`;
      try {
        const result = await privateInstance.post(
          `${API_VERSION.V1}${postUrl}`,
          formData
        );
        setLoading(false);
        if (result?.data?.status === API_RESULT.SUCCESS) {
          fileUrls.push(result.data.response?.url);
          setUploadedFiles([...uploadedFiles, ...fileUrls]);
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const deleteFile = (index: number) => {
    let allFiles = [...uploadedFiles];
    allFiles.splice(index, 1);
    setUploadedFiles([...allFiles]);
  };

  return (
    <Box>
      <Typography variant="h4">{title}</Typography>
      {!loading ? (
        <label htmlFor="upload-file">
          <input
            style={{ display: "none" }}
            id="upload-file"
            name="upload-file"
            type="file"
            onChange={handleFileChange}
            multiple
          />
          <Fab
            color="primary"
            size="small"
            component="span"
            aria-label="add"
            variant="extended"
            sx={{ mt: 1.5 }}
          >
            <UploadIcon sx={{ mr: 1 }} /> Upload Files
          </Fab>
        </label>
      ) : (
        <CircularProgress size={24} sx={{ mt: 1.5 }} />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          mt: 3,
        }}
      >
        {uploadedFiles?.length > 0 &&
          uploadedFiles.map((x: string, i: number) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  mr: 1.5,
                }}
              >
                <CardMedia
                  sx={{
                    height: 120,
                    width: 120,
                    maxHeight: 200,
                    maxWidth: 200,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  alt="prescription"
                  onClick={() => (x ? window.open(x) : null)}
                  src={
                    uploadType === "claim"
                      ? "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png"
                      : x
                  }
                  component="img"
                  onError={(e: any) => {
                    e.target.src =
                      "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                  }}
                />
                {allowDel ? (
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    startIcon={<DeleteIcon />}
                    sx={{ mt: 0.5 }}
                    onClick={() => deleteFile(i)}
                  >
                    Delete
                  </Button>
                ) : null}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default UploadFiles;
