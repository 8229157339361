import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../../../components/UI/tabPanel";
import useUserPermissions from "../../../hooks/useUserPermissions";
import { a11yProps } from "../../../utils/common";

import DailyTransactions from "./DailyTransactions";
import SuspiciousTransactions from "./Suspicious";

const DigitalPaymentTxn = () => {
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const activeTab: any = params.get("tab") || 1;
  const [value, setValue] = useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const userPermissions = useUserPermissions();
  const isEditAllowed = userPermissions
    ?.find((item: any) => item.permission_id === "digital_payment_transactions")
    ?.scope?.includes("edit");

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Daily"
            {...a11yProps(0)}
            onClick={() => navigate(`/digital-payment/transactions`)}
          />
          {isEditAllowed ? (
            <Tab
              label="Suspicious"
              {...a11yProps(1)}
              onClick={() => navigate(`/digital-payment/transactions?tab=2`)}
            />
          ) : null}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <DailyTransactions />
      </TabPanel>
      {isEditAllowed ? (
        <TabPanel value={value} index={1}>
          <SuspiciousTransactions />
        </TabPanel>
      ) : null}
    </div>
  );
};

export default DigitalPaymentTxn;
