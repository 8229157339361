import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { ORDER_STATUS } from "../../../enums/order.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { errorToast, handleError, successToast } from "../../../utils/common";

const RollbackModalView = ({
  open,
  onClose,
  orderId,
  updateDataFn,
  orderStatus,
}: any) => {
  const [rollbackType, setRollbackType] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const privateInstance = useAxiosInstance();

  const handleRollback = async () => {
    if (!window.confirm("Are you sure? This cannot be undone!")) {
      return;
    }

    let payload = {
      order_id: orderId,
      type: rollbackType,
    };
    try {
      setLoading(true);
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order rollback successfull!");
        onClose(false);
        updateDataFn();
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => onClose(false)}
      >
        Rollback Order
      </CustomDialogTitle>
      <DialogContent>
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        >
          <InputLabel required id="rollback-type-label">
            Rollback Type
          </InputLabel>
          <Select
            labelId="rollback-type-label"
            name="rollback_type"
            id="rollback_type"
            value={rollbackType}
            label="Rollback Type"
            onChange={(e: SelectChangeEvent) => {
              setRollbackType(e.target.value);
            }}
          >
            {[
              String(ORDER_STATUS.INVOICE_GENERATED),
              String(ORDER_STATUS.PAYMENT_COLLECTED),
            ].includes(orderStatus) ? (
              <MenuItem value="invoice-rollback">Rollback to Pending</MenuItem>
            ) : null}
            {[
              String(ORDER_STATUS.PACKAGE_PICKED),
              String(ORDER_STATUS.PACKAGE_DELIVERED),
            ].includes(orderStatus) ? (
              <MenuItem value="package-picked-rollback">
                Rollback to Payment collected
              </MenuItem>
            ) : null}
          </Select>
        </FormControl>
        <Box mt={4} textAlign="center">
          <LoadingButton
            type="button"
            color="primary"
            variant="contained"
            loading={loading}
            disabled={!rollbackType}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={handleRollback}
          >
            Submit
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RollbackModalView;
