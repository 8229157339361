import { Divider, Skeleton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  skeletonItem: {
    marginLeft: "1%",
    height: 24,
    borderRadius: 2,
  },
  skeletonItemSecond: {
    marginLeft: "1%",
    height: 20,
    borderRadius: 2,
  },
});

const AllOrderTableSkeleton = ({ index }: any) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={index === 0 ? { mt: 0 } : { mt: 1 }}
      >
        <Stack sx={{ width: "18%" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={classes.skeletonItem}
          />
          <Skeleton
            variant="rectangular"
            className={classes.skeletonItemSecond}
            sx={{ mt: 0.5 }}
            animation="wave"
            width="60%"
          />
        </Stack>
        <Stack
          sx={{
            width: location.pathname?.includes("pharmacy") ? "22%" : "18%",
            marginLeft: "1%",
          }}
        >
          <Skeleton
            variant="rectangular"
            className={classes.skeletonItem}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            className={classes.skeletonItemSecond}
            sx={{ mt: 0.5 }}
            animation="wave"
            width="80%"
          />
          <Skeleton
            variant="rectangular"
            className={classes.skeletonItemSecond}
            sx={{ mt: 0.5 }}
            animation="wave"
            width="80%"
          />
        </Stack>
        {location.pathname?.includes("pharmacy") ? null : (
          <Stack sx={{ width: "20%", marginLeft: "1%" }}>
            <Skeleton
              variant="rectangular"
              className={classes.skeletonItem}
              animation="wave"
              width="60%"
            />
            <Skeleton
              variant="rectangular"
              className={classes.skeletonItemSecond}
              sx={{ mt: 0.5 }}
              animation="wave"
              width="80%"
            />
          </Stack>
        )}
        <Skeleton
          variant="rectangular"
          className={classes.skeletonItem}
          animation="wave"
          width={location?.pathname?.includes("pharmacy") ? "19%" : "18%"}
        />
        <Skeleton
          variant="rectangular"
          width={location?.pathname?.includes("pharmacy") ? "19%" : "14%"}
          className={classes.skeletonItem}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width={location?.pathname?.includes("pharmacy") ? "19%" : "10%"}
          className={classes.skeletonItem}
          animation="wave"
        />
      </Stack>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default AllOrderTableSkeleton;
