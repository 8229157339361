import { TableCell, TableRow } from "@mui/material";
import React from "react";

const DunzoSubTotal = ({ orderData }: any) => {
  let totalPharmacyPrice: number = 0;
  // eslint-disable-next-line array-callback-return
  orderData?.order_details?.items?.map((item: any) => {
    if (item.pharmacy_price != null && item.pharmacy_price !== "")
      totalPharmacyPrice +=
        Number(item.quantity) * Number(parseFloat(item.pharmacy_price));
  });

  totalPharmacyPrice = Number(totalPharmacyPrice);

  let subTotal: number = 0;
  // eslint-disable-next-line array-callback-return
  orderData?.order_details?.items?.map((item: any) => {
    if (item.pharmacy_payout != null && item.pharmacy_payout !== "")
      subTotal += parseFloat(item.pharmacy_payout);
  });

  subTotal = parseFloat(String(subTotal));

  let totalDiscount: number = Number(totalPharmacyPrice - subTotal);

  let deliveryCharges: number = 0;

  if (orderData?.invoice_details?.other_charges) {
    deliveryCharges = Number(orderData?.invoice_details?.other_charges);
  } else {
    deliveryCharges = 0.0;
  }

  let grandTotal: number =
    parseFloat(String(deliveryCharges)) + parseFloat(String(subTotal));
  grandTotal = Number(grandTotal);

  return (
    <>
      <TableRow sx={{ fontWeight: "bold" }}>
        <TableCell rowSpan={4} colSpan={4} />
        <TableCell>Total Pharmacy Price (Rs.)</TableCell>
        <TableCell align="right">{totalPharmacyPrice?.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow sx={{ fontWeight: "bold" }}>
        <TableCell>Total Discount (Rs.)</TableCell>
        <TableCell align="right">{totalDiscount?.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow sx={{ fontWeight: "bold" }}>
        <TableCell>Delivery Charges (Rs.)</TableCell>
        <TableCell align="right">{deliveryCharges?.toFixed(2)}</TableCell>
      </TableRow>
      <TableRow sx={{ fontWeight: "bold" }}>
        <TableCell>Grand Total (Rs.)</TableCell>
        <TableCell align="right">{grandTotal?.toFixed(2)}</TableCell>
      </TableRow>
    </>
  );
};

export default DunzoSubTotal;
