import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../../utils/common";

const MerchantGstValidate = ({
  gst_no,
  company,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  disableGst = false,
  setDisableGst = null,
  allowWhiteSpace = true,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const getGstData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/pharmacy/validate-gst?gstin=${gst_no}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setFieldValue("company", result.data.response?.business_name);
        //TODO: only for create ipd order
        setFieldValue("company_address", result.data.response?.address);
        setFieldValue("merchant_name", result.data.response?.business_name);
        //
      } else {
        errorToast(result.data.message);
        setFieldValue("gst_no", "");
        setFieldValue("company", "");
        //TODO: only for create ipd order
        setFieldValue("company_address", "");
        setFieldValue("merchant_name", "");
        //
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack direction={{ xs: "column", md: "row" }}>
      <Stack
        direction="row"
        alignItems="baseline"
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 }, width: "100%" }}
      >
        <TextField
          fullWidth
          id="gst_no"
          label="GSTIN"
          size="small"
          value={gst_no || ""}
          onChange={(e) => {
            if (allowWhiteSpace) {
              setFieldValue("gst_no", e.target.value);
            } else {
              setFieldValue("gst_no", String(e.target.value)?.trim());
            }
          }}
          onBlur={handleBlur}
          helperText={errors.gst_no && touched.gst_no ? errors.gst_no : ""}
          error={errors.gst_no && touched.gst_no ? true : false}
          inputProps={{
            maxLength: 15,
          }}
          disabled={disableGst}
          InputLabelProps={{
            required: true,
          }}
        />
        {disableGst ? (
          <Button
            sx={{ ml: 1 }}
            variant="contained"
            color="warning"
            onClick={() => setDisableGst(false)}
          >
            Edit
          </Button>
        ) : (
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            loading={loading}
            disabled={gst_no.length === 0}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={() => {
              if (gst_no?.trim()?.toLowerCase() === "na") {
                setFieldValue("company", "NA");
              } else {
                getGstData();
              }
            }}
            sx={{ ml: 1 }}
          >
            Verify
          </LoadingButton>
        )}
      </Stack>
      <TextField
        fullWidth
        id="company"
        label="Company"
        size="small"
        value={company || ""}
        disabled
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        InputLabelProps={{
          required: true,
        }}
        helperText={errors.company && touched.company ? errors.company : ""}
        error={errors.company && touched.company ? true : false}
      />
    </Stack>
  );
};

export default MerchantGstValidate;
