import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { textCapitalize } from "../../../utils/common";

const PermissionsView = ({ data }: any) => {
  const [allParents, setAllParents] = useState<string[]>([]);

  useEffect(() => {
    let d: any = [];

    data.forEach((el: any) => {
      if (!d.includes(el.parent)) {
        d.push(el.parent);
      }
    });

    setAllParents(d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChildren = (parent: string) => {
    return data
      .filter((x: any) => x.parent === parent)
      .map((y: any, i: number) =>
        y.child
          ? i === 0
            ? textCapitalize(y.child?.replace("-", " "))
            : ", " + textCapitalize(y.child?.replace("-", " "))
          : "--"
      );
  };

  return (
    <div>
      <TableContainer sx={{ mt: 2, mr: { xs: 0, md: 1 } }} component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Parent</TableCell>
              <TableCell>Children</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allParents.map((row: string, index: number) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row}</TableCell>
                <TableCell>{getChildren(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PermissionsView;
