import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import {
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
  toHoursAndMinutes,
} from "../../../utils/common";
import LineChart from "../Charts/LineChart";
import InfoIcon from "@mui/icons-material/Info";

const PartnerConfirmationMetrics = ({
  startDate,
  endDate,
  metricType,
  graphTitle,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [selectedPlatform, setSelectedPlatform] = useState<string>("all");

  const getMetricsData = async () => {
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: metricType,
      platform: selectedPlatform === "all" ? "" : selectedPlatform,
    };

    const result: any = await instance.post(
      `${config.URL}${API_VERSION.V2}/analytics/ops`,
      payload
    );

    if (result?.data?.status === API_RESULT.SUCCESS) {
      setMetricData(result.data.response);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedPlatform]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);

    const modificationConfirmationCount: any = [];
    const paymentConfirmationCount: any = [];

    labels.forEach((el: any) => {
      let filteredModificationDataByLabel = filterByKeyValue(
        rawData.modification_confirmation_tat,
        "delivery_date",
        el
      );

      let filteredPaymentDataByLabel = filterByKeyValue(
        rawData.payment_confirmation_tat,
        "delivery_date",
        el
      );

      let modifCount: any = getCountByKey(
        filteredModificationDataByLabel,
        "tat"
      );
      modifCount = toHoursAndMinutes(Number.parseFloat(modifCount));
      modificationConfirmationCount.push(modifCount);

      let payCount: any = getCountByKey(filteredPaymentDataByLabel, "tat");
      payCount = toHoursAndMinutes(Number.parseFloat(payCount));
      paymentConfirmationCount.push(payCount);
    });

    const datasets = [
      {
        label: "Modification Confirmation",
        data: modificationConfirmationCount,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
      {
        label: "Payment Confirmation",
        data: paymentConfirmationCount,
        borderColor: "#0058ff",
        backgroundColor: "#0058ff",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box
      width={{ xs: "100%", md: "60%" }}
      sx={{
        padding: "10px",
        ml: { xs: "0", md: "20%" },
      }}
    >
      <Box textAlign="right">
        <FormControl size="small" sx={{ my: 1.5 }}>
          <InputLabel id="platform-label">Platform</InputLabel>
          <Select
            labelId="platform-label"
            name="platform"
            id="platform"
            value={selectedPlatform}
            label="Platform"
            onChange={(e: SelectChangeEvent) => {
              setSelectedPlatform(e.target.value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="api">API</MenuItem>
            <MenuItem value="dashboard">Dashboard</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
          padding: "10px",
        }}
      >
        <Box textAlign="right">
          <Tooltip title={tooltipText}>
            <InfoIcon color="primary" fontSize="small" />
          </Tooltip>
        </Box>
        {genData && <LineChart title={graphTitle} data={genData} />}
      </Box>
    </Box>
  );
};

export default PartnerConfirmationMetrics;
