import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TabPanel from "../../components/UI/tabPanel";
import { a11yProps } from "../../utils/common";
import CreateReqTab from "./createReqTab";
import ShowReqTab from "./showReqTab";

const Payments = () => {
  const location = useLocation();
  const activeTab: any = location?.search?.split("=")[1];
  const [value, setValue] = React.useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const generatePaymentsRoute = (index: number) => {
    return `/payments?tab=${index}`;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Create Request"
            {...a11yProps(0)}
            onClick={() => navigate(generatePaymentsRoute(1))}
          />
          <Tab
            label="Show Requests"
            {...a11yProps(1)}
            onClick={() => navigate(generatePaymentsRoute(2))}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CreateReqTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ShowReqTab />
      </TabPanel>
    </Box>
  );
};

export default Payments;
