import { CircularProgress, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import config from "../../../config";
import { commonTableOptions } from "../../../constants";
import { instance } from "../../../utils/api";
import { handleError } from "../../../utils/common";

const OndcOrders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const ondcOrderColumns = [
    {
      name: "sr_no",
      label: "Sr. No.",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={50} variant="body2">
              {value}.
            </Typography>
          );
        },
      },
    },
    {
      name: "buyer_np_name",
      label: "Buyer NP Name",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "seller_np_name",
      label: "Seller NP Name",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "order_create_date_and_time",
      label: "Order Create Date & Time",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },

    {
      name: "network_order_id",
      label: "Network Order Id",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "network_transaction_id",
      label: "Network Transaction Id",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "seller_np_order_id",
      label: "Seller NP Order Id",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "item_id",
      label: "Item Id",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "qty",
      label: "Quantity",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "seller_np_type",
      label: "Seller NP Type",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "order_status",
      label: "Order Status",
      options: {
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "name_of_seller",
      label: "Name Of Seller",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "seller_pincode",
      label: "Seller Pincode",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "seller_city",
      label: "Seller City",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "sku_name",
      label: "SKU Name",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "sku_code",
      label: "SKU Code",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "order_category",
      label: "Order Category",
      options: {
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "ready_to_ship_at_date_and_time",
      label: "Ready to Ship At Date & Time",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "shipped_at_date_and_time",
      label: "Shipped At Date & Time",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "delivered_at_date_and_time",
      label: "Delivered At Date & Time",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "delivery_type",
      label: "Delivery Type",
      options: {
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "logistics_seller_np_name",
      label: "Logistics Seller NP Name",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "logistics_network_order_id",
      label: "Logistics Network Order Id",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "logistics_network_transaction_id",
      label: "Logistics Network Transaction Id",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "delivery_city",
      label: "Delivery City",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "delivery_pincode",
      label: "Delivery PinCode",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "cancelled_at_date_and_time",
      label: "Cancelled At Date & Time",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "cancelled_by",
      label: "Cancelled By",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "cancellation_reason_return_reason_error_code",
      label: "Cancellation Reason / Return Reason",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value: any) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "total_shipping_charges",
      label: "Total Shipping Charges",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "total_order_value",
      label: "Total Order Value",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      name: "total_refund_amount",
      label: "Total Refund Amount",
      options: {
        filter: false,
        searchable: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    download: true,
    downloadOptions: {
      filename: "MedPay_ondc_orders_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    },
    // searchPlaceholder: "Name/Partner Order ID",
  };

  const getOndcOrders = async () => {
    setLoading(true);
    try {
      const result = await instance.get(
        `${config.ONDC_API_URL}ecommerce/manage/orders`
      );
      if (result.status === 200) {
        let allOrders = result.data?.orders || [];
        allOrders.forEach((element: any, index: number) => {
          element.sr_no = index + 1;
        });
        setData(allOrders);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOndcOrders();
  }, []);
  return (
    <div>
      <ErrorBoundary>
        <MUIDataTable
          title=""
          data={data}
          columns={ondcOrderColumns}
          options={options}
        />
      </ErrorBoundary>
    </div>
  );
};

export default OndcOrders;
