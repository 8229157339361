import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useMemo, useState } from "react";
import ErrorBoundary from "../../../../components/Helpers/ErrorBoundary";
import { commonTableOptions } from "../../../../constants";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  amountConverter,
} from "../../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { addDays, format } from "date-fns";
import { useParams } from "react-router-dom";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import PaymentDataRow from "../../../../components/ManageOrder/GroupOrders/Payment/PaymentStatus/paymentDataRow";
import { DateRangePicker } from "mui-daterange-picker";
import { dateRangeMenu } from "../../../../utils/dateRangeMenu";
import useUserPermissions from "../../../../hooks/useUserPermissions";

const MerchantSettlements = ({
  dateRangeSettlement,
  setDateRangeSettlement,
  data,
  setData,
  visitedTabs,
  updateVisitedTabs,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [activePayoutId, setPayoutActiveId] = useState("");
  const [openPicker, setOpenPicker] = useState(false);
  const toggle = () => setOpenPicker(!openPicker);
  const params = useParams();
  const userPermissions = useUserPermissions();
  const moduleScope = useMemo(
    () =>
      userPermissions?.find((item: any) => item.permission_id === "merchants")
        ?.scope,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchPayoutsData = async () => {
    //setData([]);
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/analytics/digital-payments/payouts?date=${format(
          dateRangeSettlement?.startDate,
          "dd/MM/yyyy"
        )}&location_id=${params?.locationId}&edate=${format(
          dateRangeSettlement?.endDate,
          "dd/MM/yyyy"
        )}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        updateVisitedTabs(3, "add");
        setData(
          result.data.response?.map((d: any) => {
            return { ...d, extra_5: d.instant_charges ? "instant" : d.extra_5 };
          })
        );
        setOpenPicker(false);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const refreshStatus = async (index: number, payout_id: string) => {
    setRefreshLoader(true);
    setPayoutActiveId(payout_id);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/payment-request/status/${payout_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        const item = {
          ...result.data.response,
          extra_5: result.data.response?.instant_charges
            ? "instant"
            : result.data.response?.extra_5,
        };
        data.splice(index, 1, item);
        const updatedData = [...data];
        setData(updatedData);
      } else {
        errorToast("Sorry, we could not fetch status");
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRefreshLoader(false);
      setPayoutActiveId("");
    }
  };

  useEffect(() => {
    if (!visitedTabs.includes(3)) {
      fetchPayoutsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeSettlement]);

  const cols: any = [
    {
      name: "payout_id",
      label: "PAYOUT ID",
      options: {
        filter: false,
      },
    },
    {
      name: "payment_type",
      label: "Payment Type",
      options: {
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const pgPartner = data[tableMeta.rowIndex]?.pg_partner;
          return (
            <Typography variant="body2" minWidth={120}>
              {value}
              {pgPartner ? ` - ${pgPartner}` : ""}
            </Typography>
          );
        },
      },
    },
    {
      name: "amount",
      label: "AMOUNT (Rs.)",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">{value}</Typography>
              {data[tableMeta.rowIndex]?.wallet_transactions ? (
                <Tooltip title="Wallet Transactions">
                  <Box
                    sx={{
                      border: "1px solid #2F7C31",
                      borderRadius: 2,
                      minWidth: "fit-content",
                      p: 0.5,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.25,
                    }}
                  >
                    <AccountBalanceWallet fontSize="small" color="success" />
                    <Typography sx={{ color: "#2F7C31" }} variant="body2">
                      &#8377;
                      {
                        data[tableMeta.rowIndex]?.wallet_transactions?.[0]
                          ?.amount
                      }
                    </Typography>
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
          );
        },
      },
    },
    {
      name: "payment_status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const error_message = data[tableMeta.rowIndex]?.error_message;
          const payout_id = data[tableMeta.rowIndex]?.payout_id;

          return (
            <Stack direction="row" alignItems="center">
              {value && (
                <Chip
                  size="small"
                  label={value}
                  sx={{
                    backgroundColor:
                      value === "success"
                        ? "#C6F6D5"
                        : value === "failed"
                        ? "#C53030"
                        : "#FBD38D",
                    padding: "6px",
                    color: value === "failed" ? "#fff" : "#000",
                  }}
                />
              )}
              {value === PAYMENT_CREDIT_STATUS.FAILED && error_message ? (
                <Tooltip title={error_message}>
                  <InfoIcon sx={{ ml: 0.5 }} fontSize="small" color="error" />
                </Tooltip>
              ) : null}
              {value === PAYMENT_CREDIT_STATUS.PROCESSING && (
                <>
                  {refreshLoader && activePayoutId === payout_id ? (
                    <CircularProgress
                      sx={{ ml: 0.5 }}
                      size={16}
                      color="warning"
                    />
                  ) : (
                    <Tooltip title="Refresh status">
                      <span>
                        <IconButton
                          onClick={(e: any) => {
                            e.stopPropagation();
                            refreshStatus(tableMeta.rowIndex, payout_id);
                          }}
                        >
                          <AutorenewIcon color="warning" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </Stack>
          );
        },
      },
    },
    {
      name: "bank_reference",
      label: "Bank Ref",
      options: {
        filter: false,
      },
    },
    {
      name: "extra_5",
      label: "Mode",
      options: {},
    },
    {
      name: "extra_4",
      label: "Service",
      options: {},
    },
    {
      name: "payout_initiated_at",
      label: "Initiated At",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2" minWidth={160}>
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "modified_at",
      label: "Updated At",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Typography variant="body2" minWidth={160}>
                {parseAndFormateDate(value)}
              </Typography>
              <Typography variant="body2" minWidth={160}>
                {data[tableMeta.rowIndex]?.created_by}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "order_source",
      label: "Order Source",
      options: {
        searchable: false,
        filter: true,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Payout ID/Bank Ref/Amount",
    rowsPerPageOptions: [10, 50, 100, 200],
    rowsPerPage: 10,
    download: moduleScope.includes("edit"),
    downloadOptions: {
      filename: "Settlements_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const fullRowData = data[rowMeta?.dataIndex];
      return (
        <tr style={{ backgroundColor: "#f6f6f6" }}>
          <td colSpan={rowData?.length}>
            <Stack
              sx={{
                borderBottom: "1px solid #e0e0e0",
              }}
              direction="row"
              gap={2}
            >
              {fullRowData?.beneficiary ? (
                <Stack sx={{ minWidth: "320px" }} p={2}>
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Beneficiary Details
                  </Typography>
                  <PaymentDataRow
                    dataTitle="Name"
                    value={fullRowData?.beneficiary?.name}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Mobile"
                    value={fullRowData?.beneficiary?.mobile}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Email"
                    value={fullRowData?.beneficiary?.email}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="UPI ID"
                    value={fullRowData?.beneficiary?.upi_id}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Account No"
                    value={fullRowData?.beneficiary?.account_number}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="IFSC Code"
                    value={fullRowData?.beneficiary?.ifsc_code}
                    smallFont={true}
                  />
                </Stack>
              ) : null}

              {fullRowData?.penny_drop ? (
                <Stack
                  p={2}
                  sx={{ borderLeft: "1px solid #e0e0e0", minWidth: "280px" }}
                >
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Penny Drop Details
                  </Typography>
                  <PaymentDataRow
                    dataTitle="ACK No"
                    value={fullRowData?.penny_drop?.acknowledgment_number}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Bank Ref"
                    value={fullRowData?.penny_drop?.bank_reference}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Payment Type"
                    value={fullRowData?.penny_drop?.payment_type}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="PG Partner"
                    value={fullRowData?.penny_drop?.pg_partner}
                    smallFont={true}
                  />
                  {fullRowData?.penny_drop?.status ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={1}
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        sx={{ fontSize: "12px !important" }}
                      >
                        Status :
                      </Typography>
                      <Chip
                        size="small"
                        label={fullRowData?.penny_drop?.status}
                        sx={{
                          backgroundColor:
                            fullRowData?.penny_drop?.status === "success"
                              ? "#C6F6D5"
                              : fullRowData?.penny_drop?.status === "failed"
                              ? "#C53030"
                              : "#FBD38D",
                          padding: "6px",
                          color:
                            fullRowData?.penny_drop?.status === "failed"
                              ? "#fff"
                              : "#000",
                        }}
                      />
                    </Stack>
                  ) : null}
                  <PaymentDataRow
                    dataTitle="Created At"
                    value={
                      parseAndFormateDate(
                        fullRowData?.penny_drop?.created_at
                      ) as any
                    }
                    smallFont={true}
                  />
                </Stack>
              ) : null}

              {fullRowData?.refund_details ? (
                <Stack sx={{ minWidth: "240px" }} p={2}>
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Pharmacy Refunds
                  </Typography>
                  <PaymentDataRow
                    dataTitle="Amount"
                    value={fullRowData?.refund_details?.refund_amount}
                    smallFont={true}
                  />
                </Stack>
              ) : null}

              {fullRowData?.instant_charges ? (
                <Stack
                  p={2}
                  sx={{
                    borderLeft: "1px solid #e0e0e0",
                    minWidth: "240px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Charges
                  </Typography>
                  <PaymentDataRow
                    dataTitle=" Amount"
                    value={fullRowData?.actual_amount}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Instant Charges"
                    value={fullRowData?.instant_charges}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Settlement Amount"
                    value={fullRowData?.amount}
                    smallFont={true}
                  />
                </Stack>
              ) : null}
            </Stack>
          </td>
        </tr>
      );
    },
    page: 1,
  };

  return (
    <>
      <Box
        mb={3}
        gap={4}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack>
          <TextField
            sx={{ minWidth: "275px" }}
            fullWidth
            id="date_range"
            label={dateRangeSettlement?.label}
            size="small"
            value={`${format(
              dateRangeSettlement?.startDate as Date,
              "dd/MM/yyyy"
            )} - ${format(dateRangeSettlement?.endDate as Date, "dd/MM/yyyy")}`}
            onFocus={toggle}
          />
          <Box sx={{ position: "absolute", zIndex: 1000 }}>
            <DateRangePicker
              open={openPicker}
              toggle={toggle}
              onChange={(range) => {
                updateVisitedTabs(3, "remove");
                setDateRangeSettlement(range);
              }}
              minDate={"2024-04-01"}
              maxDate={addDays(new Date(), 1)}
              definedRanges={dateRangeMenu}
            />
          </Box>
        </Stack>
        <Box
          width={{ xs: "100%", md: "30%" }}
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
          }}
        >
          <Box sx={{ width: "50%", textAlign: "center" }} pt={1}>
            <Typography
              sx={{
                fontSize: "12px !important",
              }}
            >
              Count
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "20px !important" }}>
              <strong>{data?.length > 0 ? data?.length : 0}</strong>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              borderLeft: "1px solid #e7e7e7",
              textAlign: "center",
            }}
            pt={1}
          >
            <Typography
              sx={{
                fontSize: "12px !important",
              }}
            >
              Amount
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "20px !important" }}>
              &#8377;&nbsp;
              {amountConverter(
                data?.reduce(
                  (acc: number, curr: any) => acc + Number(curr.amount),
                  0
                )
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ErrorBoundary>
        <MUIDataTable title="" data={data} columns={cols} options={options} />
      </ErrorBoundary>
    </>
  );
};

export default MerchantSettlements;
