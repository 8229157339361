import React from "react";
import MUIDataTable, { FilterType, MUIDataTableOptions } from "mui-datatables";
import { Tooltip, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { commonTableOptions } from "../../../constants";
import { useIconStyles } from "../../../components/Helpers/iconStyles";
import { ThemeProvider } from "@mui/styles";
import { getMuiTheme } from "../../../utils/common";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ManageUsers = ({
  data,
  loading,
  setUserModal,
  setActiveLoginId,
  setShowPermissionsModal,
}: any) => {
  const columns = [
    {
      name: "user_id",
      label: "USER ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phone_number",
      label: "MOBILE",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "partner",
      label: "PARTNER NAME",
      options: {
        filter: true,
        sort: false,
        filterType: "dropdown" as FilterType,
        searchable: false,
      },
    },
    {
      name: "role",
      label: "ROLE",
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        searchable: false,
        filter: false,
        download: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
              <Tooltip title="Edit">
                <EditIcon
                  className={iconClasses.borderedIcon}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setUserModal(true);
                    setActiveLoginId(data[tableMeta.rowIndex]?.login_id);
                  }}
                />
              </Tooltip>
              <Tooltip title="View Permissions">
                <VisibilityIcon
                  className={iconClasses.borderedIcon}
                  sx={{ cursor: "pointer", ml: 1 }}
                  onClick={() => {
                    setShowPermissionsModal(true);
                    setActiveLoginId(data[tableMeta.rowIndex]?.login_id);
                  }}
                />
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...(commonTableOptions as any),
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    searchPlaceholder: "User ID/Mobile",
    download: true,
    downloadOptions: {
      filename: "Users_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  const iconClasses = useIconStyles();
  return (
    <ErrorBoundary>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Manage Users"}
          data={data}
          columns={columns}
          options={options}
        />
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default ManageUsers;
