import React from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Divider,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import OrderDetail from "../../../components/CreateOrder/orderDetail";
import PatientDetail from "../../../components/CreateOrder/patientDetail";
import AddressDetail from "../../../components/CreateOrder/addressDetail";
import { LoadingButton } from "@mui/lab";
import DateTimeSelect from "../../../components/Common/dateTimeSelect";
import NearbyClinicsTable from "./nearbyClinicsTable";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  removeTrailingSlash,
  successToast,
} from "../../../utils/common";
import PartnerIdChange from "../../../components/CreateOrder/partnerIdChange";
import { format } from "date-fns";
import { CONSULTATION_TYPE, ORDER_TYPE } from "../../../enums/order.enum";
import { createConsultationSchema } from "../../../schemas/consultation";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../../enums/common.enum";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import useUserInfo from "../../../hooks/useUserInfo";

const CreateConsultationOrder = () => {
  const [loading, setLoading] = React.useState(false);
  const [nearbyList, setNearbyList] = React.useState([]);
  const [selectedClinic, setSelectedClinic] = React.useState<any>(null);
  const privateInstance = useAxiosInstance();
  const [specialities, setSpecialities] = React.useState<any>([]);

  React.useEffect(() => {
    const getSpecialities = async () => {
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/consultations/specialties`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          const responseData = result.data.response;
          let listOfSpecialities: any = [];
          responseData.forEach((item: any) => {
            listOfSpecialities.push(item.specialty);
          });
          setSpecialities(listOfSpecialities);
        }
      } catch (err) {
        handleError(err);
      }
    };

    getSpecialities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNearbyClinics = async (values: any) => {
    let add = values.address;
    add = add.replace("#", "-").replace("&", "-").replace("/", "-");

    let url;
    if (values.provider_id?.toLowerCase() === "loop health") {
      url = `/orders/consultations/doctors/offline/nearby?address=${
        add + " " + values.city + " " + values.pin
      }&specialty=${values.speciality.toLowerCase()}&next_page=9`;
    } else {
      url = `/orders/consultations/doctors/offline/nearby?address=${
        add + " " + values.city + " " + values.pin
      }&specialty=${values.speciality.toLowerCase()}&next_page=1`;
    }

    try {
      const result = await privateInstance.get(`${API_VERSION.V1}${url}`);
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (result.data.response.nearby_list.length === 0) {
          return errorToast(result.data.message);
        }
        setNearbyList(result.data.response.nearby_list);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      consultation_type: values.consultation_type,
      customer_details: {
        address_details: {
          address: removeTrailingSlash(
            values.address?.trim().replace("\t", "")
          ),
          city: values.city,
          coordinates: "",
          landmark: removeTrailingSlash(
            values.landmark?.trim().replace("\t", "")
          ),
          pin_code: removeTrailingSlash(values.pin?.trim().replace("\t", "")),
          state: values.state,
        },
        age: Number(values.age),
        alternative_mobile: removeTrailingSlash(
          values.alt_mobile?.trim().replace("\t", "")
        ),
        email: removeTrailingSlash(values.email?.trim().replace("\t", "")),
        gender: values.gender,
        mobile: removeTrailingSlash(values.mobile?.trim().replace("\t", "")),
        name: removeTrailingSlash(values.full_name?.trim().replace("\t", "")),
      },
      partner_name: values.provider_id,
      partner_order_id: removeTrailingSlash(
        values.partner_order_id?.trim().replace("\t", "")
      ),
      payment_collection: values.payment_collection,
      specialty: values.speciality?.toLowerCase(),
      time_slot: format(values.time_slot, "dd-MM-yyyy, hh:mm a"),
    };

    if (values.consultation_type === CONSULTATION_TYPE.OFFLINE) {
      if (!selectedClinic && values.speciality?.toLowerCase() !== "dietitian") {
        errorToast("Please select a clinic to proceed");
        return;
      }
      if (selectedClinic) {
        payload = selectedClinic.profile_id
          ? { ...payload, clinic_id: selectedClinic.profile_id }
          : { ...payload, clinic_id: selectedClinic.location_id };
      } else {
        payload = { ...payload, clinic_id: "all" };
      }
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${
          API_VERSION.V1
        }/orders/consultations/${values.consultation_type?.toLowerCase()}/create-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order created successfully");
        actions.resetForm({});
        setSelectedClinic(null);
        setNearbyList([]);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const user: AuthenticatedUser = useUserInfo();
  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Formik
        initialValues={{
          provider_id: "" || null,
          partner_order_id: "",
          payment_collection: "self",
          //PARTNER SPECIFIC: LOOP HEALTH AND VHEALTH
          consultation_type:
            user?.role === USER_ROLE.PARTNER &&
            (user?.partner === "loop health" ||
              user?.partner === "vhealth by aetna")
              ? "offline"
              : "",
          full_name: "",
          gender: "",
          age: "",
          mobile: "",
          alt_mobile: "",
          email: "",
          address: "",
          landmark: "",
          pin: "",
          city: "",
          state: "",
          speciality: null,
          time_slot: "" || null,
        }}
        validationSchema={createConsultationSchema}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setTouched,
          } = props;
          return (
            <Form>
              <Typography variant="h4">Order Details:</Typography>
              <OrderDetail
                createOrderType={ORDER_TYPE.CONSULTATION}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                disableField={nearbyList.length === 0 ? false : true}
                formData={values}
              />
              <Typography variant="h4" sx={{ mt: 3 }}>
                Patient Details:
              </Typography>
              <PatientDetail
                createOrderType={ORDER_TYPE.CONSULTATION}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                formData={values}
              />
              <Typography variant="h4" sx={{ mt: 3 }}>
                Address Details:
              </Typography>
              <AddressDetail
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                disableField={nearbyList.length === 0 ? false : true}
                formData={values}
                setTouched={setTouched}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Autocomplete
                  disabled={nearbyList.length === 0 ? false : true}
                  filterSelectedOptions
                  fullWidth
                  size="small"
                  id="speciality"
                  options={specialities}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="speciality *"
                      variant="outlined"
                      size="small"
                      error={
                        errors?.speciality && touched?.speciality ? true : false
                      }
                      helperText={
                        errors?.speciality && touched?.speciality
                          ? errors?.speciality
                          : ""
                      }
                      name="speciality"
                    />
                  )}
                  value={values?.speciality}
                  onChange={(e, val: any) => {
                    setFieldValue("speciality", val);
                  }}
                  onBlur={handleBlur}
                />
                <DateTimeSelect
                  time_slot={values.time_slot}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  pickerLabel="Appointment Date & Time"
                />
              </Box>
              {values.consultation_type === CONSULTATION_TYPE.OFFLINE && (
                <Box sx={{ mt: 3 }}>
                  {selectedClinic ? (
                    <Typography variant="h4" sx={{ mb: 3 }}>
                      Selected Clinic:
                    </Typography>
                  ) : nearbyList.length !== 0 ? (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setNearbyList([])}
                      sx={{ mb: 3 }}
                    >
                      Change Details
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      disabled={
                        !values.provider_id ||
                        !values.address ||
                        !values.pin ||
                        !values.city ||
                        !values.speciality
                      }
                      onClick={() => getNearbyClinics(values)}
                      sx={{ mb: 3 }}
                    >
                      Fetch nearby clinics
                    </Button>
                  )}
                  {selectedClinic ? (
                    <NearbyClinicsTable
                      data={selectedClinic}
                      selectedClinc={selectedClinic}
                      setSelectedClinic={setSelectedClinic}
                      showHeader={true}
                    />
                  ) : (
                    nearbyList.length > 0 &&
                    nearbyList.map((x: any, i: number) => {
                      return (
                        <Box key={i}>
                          <NearbyClinicsTable
                            data={x}
                            selectedClinc={selectedClinic}
                            setSelectedClinic={setSelectedClinic}
                            showHeader={i === 0 ? true : false}
                          />
                          <Divider sx={{ my: 2 }} />
                        </Box>
                      );
                    })
                  )}
                </Box>
              )}
              <Box textAlign="center" sx={{ mt: 3 }}>
                <LoadingButton
                  sx={{ width: "180px" }}
                  size="medium"
                  color="primary"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Box>
              <PartnerIdChange setFieldValue={setFieldValue} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreateConsultationOrder;
