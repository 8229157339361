import {
  Box,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  Fab,
  IconButton,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../utils/common";
import UploadIcon from "@mui/icons-material/CloudUpload";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";

const allowedFiles = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

export const IssueMediaModal = ({
  open,
  onClose,
  activeLocationId,
  uploadType,
  uploadedFiles,
  setUploadedFiles,
  mode,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const deleteFile = (index: number) => {
    let allFiles = [...uploadedFiles];
    allFiles.splice(index, 1);
    setUploadedFiles([...allFiles]);
  };

  const handleFileChange = (e: any) => {
    handleFileUpload(e.target.files);
  };

  const handleFileUpload = async (files: any) => {
    let fileUrls = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!allowedFiles.includes(file.type)) {
        errorToast("this file type is not allowed");
        return;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", uploadType);
      setLoading(true);
      try {
        const result = await privateInstance.post(
          `${API_VERSION.V1}/manage/locations/${activeLocationId}/files`,
          formData
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          fileUrls.push(result.data.response);
          setUploadedFiles([...uploadedFiles, ...fileUrls]);
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          Attachments
        </CustomDialogTitle>
        <DialogContent>
          <Box>
            <Stack pb={2} direction="row" justifyContent="space-between">
              <Box>
                {mode === "edit" ? (
                  <>
                    {!loading ? (
                      <>
                        <label htmlFor={`upload-file-${uploadType}`}>
                          <input
                            style={{ display: "none" }}
                            id={`upload-file-${uploadType}`}
                            name={`upload-file-${uploadType}`}
                            type="file"
                            onChange={handleFileChange}
                            multiple
                            accept="image/png, image/gif, image/jpeg, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          />
                          <Fab
                            color="primary"
                            size="small"
                            component="span"
                            aria-label="add"
                            variant="extended"
                            sx={{ mt: 1.5 }}
                          >
                            <UploadIcon sx={{ mr: 1 }} /> Upload Files
                          </Fab>
                        </label>
                      </>
                    ) : (
                      <CircularProgress size={24} sx={{ mt: 1.5 }} />
                    )}
                  </>
                ) : null}

                <Box
                  sx={{
                    mt: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {uploadedFiles?.length > 0 &&
                    uploadedFiles.map((x: string, i: number) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            mr: 1.5,
                            position: "relative",
                          }}
                        >
                          <CardMedia
                            id={`${uploadType}_${i}`}
                            data-url={x}
                            sx={{
                              height: 150,
                              width: 150,
                              objectFit: "contain",
                              cursor: "pointer",
                              border: "2px solid #eeeeee",
                              borderRadius: 2,
                              p: 1,
                            }}
                            alt="file"
                            onClick={() => (x ? window.open(x) : null)}
                            src={x}
                            component="img"
                            onError={(e: any) => {
                              e.target.src =
                                "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                            }}
                          />
                          {mode === "edit" ? (
                            <IconButton
                              sx={{
                                position: "absolute",
                                right: "0",
                                top: "0",
                              }}
                              size="small"
                              color="error"
                              aria-label="close"
                              onClick={() => deleteFile(i)}
                            >
                              <CancelIcon />
                            </IconButton>
                          ) : null}
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
