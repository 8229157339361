import React, { useEffect, useState } from "react";
import { Alert, Box, Card, Grid, Link, Typography } from "@mui/material";
import CashlessIcon from "@mui/icons-material/PhoneIphone";
import ConsultationIcon from "@mui/icons-material/Vaccines";
import DiagnosticsIcon from "@mui/icons-material/Science";
import PharmacyIcon from "@mui/icons-material/Medication";
import ReportIcon from "@mui/icons-material/Assessment";
import DunzoIcon from "@mui/icons-material/DeliveryDining";
import InsightsIcon from "@mui/icons-material/Insights";
import TableChartIcon from "@mui/icons-material/TableChart";
import PaymentIcon from "@mui/icons-material/Payment";
import OndcIcon from "@mui/icons-material/JoinInner";

import { makeStyles } from "@mui/styles";
import useUserPermissions from "../../hooks/useUserPermissions";

const useStyles = makeStyles({
  card: {
    scale: 1,
    transition: "scale ease-in-out 0.2s",
    "&:hover": {
      scale: 1.08,
      transition: "scale ease-in-out 0.2s",
    },
  },
});

// import PieChart from "./pieChart";
// import OrdersGraph from "./ordersGraph";
// import TableView from "../../components/Common/tableView";

//piechart data start
// const consultationData = {
//   labels: ["Pending", "Assigned", "Accepted", "Booking"],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [12, 7, 10, 11],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(153, 102, 255, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

// const diagnosticsData = {
//   labels: ["Pending", "Assigned", "Accepted", "Confirmed"],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [11, 6, 4, 16],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(153, 102, 255, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

// const pharmacyData = {
//   labels: ["Pending", "Assigned", "Accepted", "Out for delivery"],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [3, 10, 16, 22],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(54, 162, 235, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "rgba(153, 102, 255, 0.2)",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(153, 102, 255, 1)",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };

// const cashlessData = {
//   labels: ["Pending", "Accepted", "Rejected"],
//   datasets: [
//     {
//       label: "# of Votes",
//       data: [12, 17, 10],
//       backgroundColor: [
//         "rgba(255, 99, 132, 0.2)",
//         "rgba(255, 206, 86, 0.2)",
//         "#e41111de",
//       ],
//       borderColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(255, 206, 86, 1)",
//         "#e41111de",
//       ],
//       borderWidth: 1,
//     },
//   ],
// };
//pie chart data end

//cashless opd table data start
// interface CashlessColumns {
//   id: "name" | "corporate" | "phone" | "amount";
//   label: string;
//   minWidth?: number;
//   align?: "right";
//   format?: (value: number) => string;
// }

// const cashlessCols: readonly CashlessColumns[] = [
//   { id: "name", label: "Name", minWidth: 170 },
//   { id: "corporate", label: "Corporate", minWidth: 100 },
//   {
//     id: "phone",
//     label: "Phone",
//     minWidth: 170,
//     align: "right",
//     format: (value: number) => value.toLocaleString("en-US"),
//   },
//   {
//     id: "amount",
//     label: "Amount",
//     minWidth: 170,
//     align: "right",
//     format: (value: number) => value.toFixed(2),
//   },
// ];

// const cashlessUsersData = [
//   {
//     name: "Tarun Yadav",
//     corporate: "medpay",
//     phone: "9022879660",
//     amount: "504",
//   },
//   {
//     name: "John Doe",
//     corporate: "medpay",
//     phone: "8938453524",
//     amount: "3422",
//   },
//   {
//     name: "Jane Doe",
//     corporate: "medpay",
//     phone: "9099094246",
//     amount: "124",
//   },
// ];
//cashless opd table data end

//partners table data start
// interface PartnersColumns {
//   id: "name" | "onboardDate" | "orderPlaced" | "orderProcessed" | "amount";
//   label: string;
//   minWidth?: number;
//   align?: "right";
//   format?: (value: number) => string;
// }

// const partnersCols: readonly PartnersColumns[] = [
//   { id: "name", label: "Partner Name", minWidth: 170 },
//   { id: "onboardDate", label: "Onboarded On", minWidth: 100 },
//   {
//     id: "orderPlaced",
//     label: "Orders Placed",
//     minWidth: 170,
//     align: "right",
//     format: (value: number) => value.toLocaleString("en-US"),
//   },
//   {
//     id: "orderProcessed",
//     label: "Orders Processed",
//     minWidth: 170,
//     align: "right",
//     format: (value: number) => value.toFixed(2),
//   },
//   {
//     id: "amount",
//     label: "Total (Rs.)",
//     minWidth: 170,
//     align: "right",
//     format: (value: number) => value.toFixed(2),
//   },
// ];

// const partnersData = [
//   {
//     name: "mfine",
//     onboardDate: "12-09-2020",
//     orderPlaced: "10",
//     orderProcessed: "5",
//     amount: "5643",
//   },
//   {
//     name: "kenko",
//     onboardDate: "16-10-2020",
//     orderPlaced: "353",
//     orderProcessed: "389",
//     amount: "43243",
//   },
//   {
//     name: "icici",
//     onboardDate: "28-09-2021",
//     orderPlaced: "70",
//     orderProcessed: "56",
//     amount: "89743",
//   },
//   {
//     name: "testpartner",
//     onboardDate: "03-10-2021",
//     orderPlaced: "45",
//     orderProcessed: "34",
//     amount: "7543",
//   },
//   {
//     name: "flipkart",
//     onboardDate: "10-01-2022",
//     orderPlaced: "11",
//     orderProcessed: "4",
//     amount: "786",
//   },
//   {
//     name: "medibuddy",
//     onboardDate: "27-03-2022",
//     orderPlaced: "432",
//     orderProcessed: "421",
//     amount: "107956",
//   },
// ];
//partners table data end

//orders graph data start
// const graphOptions = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top" as const,
//     },
//     title: {
//       display: true,
//       text: "Orders Data",
//     },
//   },
// };

// const labels = ["January", "February", "March", "April", "May", "June", "July"];

// const graphData = {
//   labels,
//   datasets: [
//     {
//       label: "Created",
//       data: ["123", "424", "563", "641", "654", "713", "890"],
//       borderColor: "rgb(255, 99, 132)",
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//     {
//       label: "Processed",
//       data: ["112", "342", "424", "501", "535", "634", "764"],
//       borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     },
//   ],
// };
//orders graph data end

const Home = () => {
  const userPermissions = useUserPermissions();
  const classes = useStyles();
  const [allowedPaths, setAllowedPaths] = useState<string[]>([]);

  //DUPLICATION
  useEffect(() => {
    let paths: string[] = [];
    userPermissions?.forEach((menu: any) => {
      if (menu.child !== "") {
        paths.push(
          `/${menu.parent?.split(" ")?.join("-")?.toLowerCase()}/${menu.child
            ?.split(" ")
            ?.join("-")}`
        );
      } else {
        paths.push(`/${menu.parent?.split(" ")?.join("-")?.toLowerCase()}`);
      }
      setAllowedPaths(paths);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Alert severity="info" sx={{ fontWeight: "bold" }}>
          Dashboard Quick Links:
        </Alert>
      </Box>
      <Grid container spacing={2} mt={3}>
        {allowedPaths?.length > 2 ? (
          <>
            {allowedPaths.includes("/cashless-opd/bills") && (
              <Grid item xs={6} md={3}>
                <Link
                  href="/cashless-opd/bills"
                  sx={{ textDecoration: "none" }}
                >
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <CashlessIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Cashless OPD
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/consultation/manage") && (
              <Grid item xs={6} md={3}>
                <Link
                  href="/consultation/manage"
                  sx={{ textDecoration: "none" }}
                >
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <ConsultationIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Consultation
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/diagnostics/manage") && (
              <Grid item xs={6} md={3}>
                <Link
                  href="/diagnostics/manage"
                  sx={{ textDecoration: "none" }}
                >
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <DiagnosticsIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Diagnostics
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/pharmacy/manage") && (
              <Grid item xs={6} md={3}>
                <Link href="/pharmacy/manage" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <PharmacyIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Pharmacy
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/dunzo") && (
              <Grid item xs={6} md={3}>
                <Link href="/dunzo" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <DunzoIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Dunzo
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/reports/orders") && (
              <Grid item xs={6} md={3}>
                <Link href="/reports/orders" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <ReportIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Reports
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/metrics") && (
              <Grid item xs={6} md={3}>
                <Link href="/metrics" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <InsightsIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Metrics
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/ledger") && (
              <Grid item xs={6} md={3}>
                <Link href="/ledger" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <TableChartIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Ledger
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/payments") && (
              <Grid item xs={6} md={3}>
                <Link href="/payments?tab=1" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <PaymentIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      Payments
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
            {allowedPaths.includes("/ondc/orders") && (
              <Grid item xs={6} md={3}>
                <Link href="/ondc/orders" sx={{ textDecoration: "none" }}>
                  <Card
                    className={classes.card}
                    sx={{
                      my: 2,
                      mx: 1,
                      py: 3,
                      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                      textAlign: "center",
                    }}
                  >
                    <OndcIcon fontSize="large" color="primary" />
                    <Typography variant="h3" mt={0.5}>
                      ONDC Orders
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            )}
          </>
        ) : (
          <>
            {allowedPaths?.slice(1).map((item: any, index: number) => {
              return (
                <Grid key={index} item xs={6} md={3}>
                  <Link href={item} sx={{ textDecoration: "none" }}>
                    <Card
                      className={classes.card}
                      sx={{
                        my: 2,
                        mx: 1,
                        py: 3,
                        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        variant="h3"
                        mt={0.5}
                      >
                        {item?.split("/")[item.split("/")?.length - 1]}
                      </Typography>
                    </Card>
                  </Link>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    </>
    // <>
    //   <Box
    //     sx={{
    //       display: "flex",
    //       flexDirection: { xs: "column", sm: "row" },
    //       flexWrap: "wrap",
    //     }}
    //   >
    //     <PieChart data={consultationData} title="Consultation" count="23,689" />
    //     <PieChart data={diagnosticsData} title="Diagnostics" count="11,423" />
    //     <PieChart data={pharmacyData} title="Pharmacy" count="42,645" />
    //     <PieChart data={cashlessData} title="Cashless OPD" count="5,232" />
    //   </Box>
    //   <Card sx={{ mx: { xs: 0, sm: "0.5%" }, mt: 3 }}>
    //     <Typography sx={{ p: 2 }} variant="h4">
    //       Partners
    //     </Typography>
    //     <TableView columns={partnersCols} data={partnersData} />
    //   </Card>
    //   <Card sx={{ mx: { xs: 0, sm: "0.5%" }, mt: 3 }}>
    //     <Typography sx={{ p: 2 }} variant="h4">
    //       Cashless OPD
    //     </Typography>
    //     <TableView columns={cashlessCols} data={cashlessUsersData} />
    //   </Card>
    //   <Card sx={{ mx: { xs: 0, sm: "0.5%" }, mt: 3 }}>
    //     <OrdersGraph options={graphOptions} data={graphData} />
    //   </Card>
    // </>
  );
};

export default Home;
