import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Fab,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { errorToast, handleError, successToast } from "../../../utils/common";
import UploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import useUserInfo from "../../../hooks/useUserInfo";

const RecoReportModal = ({ showReco, setShowReco }: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [uploadedFiles, setUploadedFiles] = useState<any>({});
  const [date, setDate] = useState<any>(new Date());
  const user: AuthenticatedUser = useUserInfo();

  const handleSubmit = async () => {
    try {
      let payload = {
        job_type: "omni_reconciled_report",
        email: user?.user_id,
        bank_statement_path: uploadedFiles?.bank_statement,
        icici_bank_statement_path: uploadedFiles?.icici_bank_statement,
        omnistores_statement_path: uploadedFiles?.omnistores_statement,
        report_date: format(date, "dd-MM-yyyy"),
        cashfree_statement_path: uploadedFiles?.cashfree_statement,
      };
      setLoading(true);
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/reports/all`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(`Request Submitted Successfully`);
        // setShowReco(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: any, uploadType: string) => {
    handleFileUpload(e.target.files, uploadType);
  };

  const handleFileUpload = async (files: any, uploadType: string) => {
    let file = files[0];
    if (file.size > 10485760) {
      errorToast(`Max allowed file size is 10 MB`);
      return;
    }
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", uploadType);
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/reports/upload?created_by=${user?.user_id}&report_type=${uploadType}`,
        formData
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        const uploadedFilePath = result.data.response?.url;
        setUploadedFiles({
          ...uploadedFiles,
          [uploadType]: uploadedFilePath?.split("/")?.[3],
        });
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={showReco}
        onClose={() => setShowReco(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowReco(false)}
        >
          Reco Report
        </CustomDialogTitle>
        <DialogContent>
          <Typography variant="h6">Account Statements (T+2 days)</Typography>
          <Stack mt={1} direction="row" justifyContent="space-between">
            <label htmlFor={`upload-file-one`}>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                disabled={uploadedFiles?.bank_statement}
                style={{ display: "none" }}
                id={`upload-file-one`}
                name={`upload-file-one`}
                type="file"
                onChange={(e) => handleFileChange(e, "bank_statement")}
              />
              <Fab
                disabled={uploadedFiles?.bank_statement}
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
                sx={{
                  mt: 1.5,
                }}
              >
                <UploadIcon sx={{ mr: 1 }} /> Yes Bank<sup> *</sup>
              </Fab>
            </label>
            {uploadedFiles.bank_statement ? (
              <>
                <Stack gap={1} alignItems="center" direction="row">
                  <p style={{ fontSize: "12px" }}>
                    {uploadedFiles.bank_statement}
                  </p>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      const obj = { ...uploadedFiles };
                      delete obj.bank_statement;
                      setUploadedFiles(obj);
                    }}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </>
            ) : null}
          </Stack>
          <Stack mt={1} direction="row" justifyContent="space-between">
            <label htmlFor={`upload-file-icici`}>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                disabled={uploadedFiles?.icici_bank_statement}
                style={{ display: "none" }}
                id={`upload-file-icici`}
                name={`upload-file-icici`}
                type="file"
                onChange={(e) => handleFileChange(e, "icici_bank_statement")}
              />
              <Fab
                disabled={uploadedFiles?.icici_bank_statement}
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
                sx={{
                  mt: 1.5,
                }}
              >
                <UploadIcon sx={{ mr: 1 }} /> ICICI Bank<sup> *</sup>
              </Fab>
            </label>
            {uploadedFiles.icici_bank_statement ? (
              <>
                <Stack gap={1} alignItems="center" direction="row">
                  <p style={{ fontSize: "12px" }}>
                    {uploadedFiles.icici_bank_statement}
                  </p>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      const obj = { ...uploadedFiles };
                      delete obj.icici_bank_statement;
                      setUploadedFiles(obj);
                    }}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </>
            ) : null}
          </Stack>
          <Stack mt={2} direction="row" justifyContent="space-between">
            <label htmlFor={`upload-file-two`}>
              <input
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                disabled={uploadedFiles?.cashfree_statement}
                style={{ display: "none" }}
                id={`upload-file-two`}
                name={`upload-file-two`}
                type="file"
                onChange={(e) => handleFileChange(e, "cashfree_statement")}
              />
              <Fab
                disabled={uploadedFiles?.cashfree_statement}
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
                sx={{ mt: 1.5 }}
              >
                <UploadIcon sx={{ mr: 1 }} /> Cashfree<sup> *</sup>
              </Fab>
            </label>
            {uploadedFiles.cashfree_statement ? (
              <>
                <Stack gap={1} alignItems="center" direction="row">
                  <p style={{ fontSize: "12px" }}>
                    {uploadedFiles.cashfree_statement}
                  </p>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      const obj = { ...uploadedFiles };
                      delete obj.cashfree_statement;
                      setUploadedFiles(obj);
                    }}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </>
            ) : null}
          </Stack>
          <Box mt={3}>
            <Divider />
          </Box>
          <Stack mt={1.5} direction="row" justifyContent="space-between">
            <label htmlFor={`upload-file-three`}>
              <input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                disabled={uploadedFiles?.omnistores_statement}
                style={{ display: "none" }}
                id={`upload-file-three`}
                name={`upload-file-three`}
                type="file"
                onChange={(e) => handleFileChange(e, "omnistores_statement")}
              />
              <Fab
                disabled={uploadedFiles?.omnistores_statement}
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
                sx={{ mt: 1.5 }}
              >
                <UploadIcon sx={{ mr: 1 }} /> Txn Report<sup> *</sup>
              </Fab>
            </label>
            {uploadedFiles.omnistores_statement ? (
              <>
                <Stack gap={1} alignItems="center" direction="row">
                  <p style={{ fontSize: "12px" }}>
                    {uploadedFiles.omnistores_statement}
                  </p>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      const obj = { ...uploadedFiles };
                      delete obj.omnistores_statement;
                      setUploadedFiles(obj);
                    }}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </>
            ) : null}
          </Stack>
          <Stack direction="row" justifyContent="center" mt={5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Reco Date(Transaction Date)"
                maxDate={new Date()}
                inputFormat="dd/MM/yyyy"
                value={date}
                onChange={(newValue: Date | null) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "240px" }}
                    size="small"
                    name="date"
                    InputLabelProps={{
                      required: true,
                    }}
                    autoComplete="off"
                    onKeyDown={(e: any) => {
                      e.preventDefault();
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Stack alignItems="center" mt={4} gap={1}>
            <LoadingButton
              disabled={Object.keys(uploadedFiles).length !== 4}
              size="medium"
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={handleSubmit}
            >
              Email Report
            </LoadingButton>
            <Typography variant="body2" sx={{ fontSize: "10px !important" }}>
              Reco Report will be emailed on: {user?.user_id}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RecoReportModal;
