import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TableCell,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import KnowlarityCall from "../../components/Common/knowlarityCall";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import { commonTableOptions } from "../../constants";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  getMuiTheme,
  handleError,
  parseAndFormateDate,
  successToast,
} from "../../utils/common";
import ProviderSettingsInfoModal from "./providerSettingsInfoModal";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AddNewLocationModal from "./addNewLocationModal";
import UpdateCertModal from "./updateCertModal";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const ProvidersSettings = () => {
  const [searchBy, setSearchBy] = useState<string>("gst_no");
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [providersData, setProvidersData] = useState<any>(null);
  const privateInstance = useAxiosInstance();
  const [showProviderSettingsInfoModal, setShowProviderSettingsInfoModal] =
    useState(false);
  const [activeLocationId, setActiveLocationId] = useState<string>("");
  const [addNewLocation, setAddNewLocation] = useState(false);
  const [updateCert, setUpdateCert] = useState(false);

  const getProviderData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/providers?filter=${searchBy}&value=${inputValue}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProvidersData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleStatus = async (
    locId: string,
    providerId: string,
    currStatus: string
  ) => {
    if (window.confirm("Are you sure?")) {
      let payload = {
        location_id: locId,
        provider_id: providerId,
        update_type: "provider_status",
        status: currStatus === "active" ? "inactive" : "active",
      };

      try {
        const result = await privateInstance.put(
          `${API_VERSION.V1}/orders/provider`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast("Status updated successfully");
          //update
          let newData: any = [];
          providersData.forEach((el: any) => {
            if (el.location_id === locId) {
              el.status = payload.status === "active" ? "active" : "blocked";
            }
            newData.push(el);
          });
          setProvidersData(newData);
          //end
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      }
    }
  };

  const providersCols = [
    {
      name: "provider_name",
      label: "PROVIDER NAME",
    },
    {
      name: "location_id",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "formatted_address",
      label: "ADDRESS",
    },
    {
      name: "whatsapp_number",
      label: "WHATSAPP NUMBER",
    },
    {
      name: "provider_type",
      label: "PROVIDER TYPE",
    },
    {
      name: "platform",
      label: "PLATFORM",
      options: {
        display: false,
      },
    },
    {
      name: "orders_fulfilled",
      label: "ORDERS FULFILLED",
    },
    {
      name: "last_order_processed_at",
      label: "LAST ORDER FULFILLED AT",
    },
    {
      name: "static_qr_enabled",
      label: "STATIC QR",
      options: {
        display: false,
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        customBodyRender: (value: string, tableMeta: any) => {
          return (
            <FormControlLabel
              label={value}
              sx={{ mt: 1.5, color: value === "active" ? "#2F7C31" : "" }}
              control={
                <Switch
                  id="provider_status"
                  checked={value === "active" ? true : false}
                  color="success"
                  onChange={() => {
                    handleStatus(
                      providersData[tableMeta.rowIndex]?.location_id,
                      providersData[tableMeta.rowIndex]?.provider_id,
                      value
                    );
                  }}
                />
              }
            />
          );
        },
      },
    },
    {
      name: "",
      label: "ACTION",
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAddNewLocation(true)}
            sx={{ color: "#fff !important" }}
          >
            Add New
          </Button>
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any, dataIndex: number) => {
      return (
        <CustomRowComponent key={data[0]} rowData={data} index={dataIndex} />
      );
    },
    searchPlaceholder: "WhatsApp Number",
    filter: false,
  };

  function CustomRowComponent({ rowData, index }: any) {
    const [
      provider_name,
      location_id,
      formatted_address,
      whatsapp_number,
      provider_type,
      platform,
      orders_fulfilled,
      last_order_processed_at,
      static_qr_enabled,
      status,
    ] = rowData;
    return (
      <TableRow key={index} hover>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="body2"
              color="#0058ff"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setShowProviderSettingsInfoModal(true);
                setActiveLocationId(location_id);
              }}
            >
              {provider_name}
            </Typography>
            {platform === "manual" ? (
              <Tooltip title={`Platform: Via WhatsApp`}>
                <WhatsAppIcon
                  sx={{ ml: 0.25 }}
                  color="success"
                  fontSize="small"
                />
              </Tooltip>
            ) : (
              <Tooltip title={`Platform: Via App`}>
                <PhoneAndroidIcon
                  sx={{ ml: 0.25 }}
                  color="success"
                  fontSize="small"
                />
              </Tooltip>
            )}
            {static_qr_enabled ? (
              <Tooltip title="MedPay QR Pharmacy">
                <QrCode2Icon
                  sx={{ mx: 0.5 }}
                  fontSize="small"
                  color="primary"
                />
              </Tooltip>
            ) : null}
          </Stack>
        </TableCell>
        <TableCell sx={{ width: { sm: "auto", md: "28%" } }}>
          <Typography variant="body2">{formatted_address}</Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <KnowlarityCall
              order={{
                order_id: location_id,
                mobile: whatsapp_number,
              }}
              showNumber={true}
            />
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <Typography variant="body2">{provider_type}</Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{orders_fulfilled}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {last_order_processed_at?.includes(":")
              ? parseAndFormateDate(last_order_processed_at)
              : "NA"}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{status}</Typography>
        </TableCell>
        <TableCell sx={{ verticalAlign: "bottom" }}>
          <Tooltip title="Update">
            <Stack
              sx={{ cursor: "pointer" }}
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                setActiveLocationId(location_id);
                setUpdateCert(true);
              }}
            >
              <PermIdentityIcon />
              <Typography
                sx={{ fontSize: "10px !important", fontWeight: "600" }}
              >
                KYC
              </Typography>
            </Stack>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <Card
        sx={{
          p: 3,
          mb: 3,
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Typography variant="h6">Search Provider</Typography>
        <Stack mt={2} direction="row">
          <FormControl size="small" fullWidth sx={{ mt: 1.5 }}>
            <InputLabel id="search-by-label">Search By</InputLabel>
            <Select
              labelId="search-by-label"
              name="search_by"
              id="search_by"
              value={searchBy}
              label="Search By"
              onChange={(e: SelectChangeEvent) => {
                setSearchBy(e.target.value);
              }}
            >
              <MenuItem value="gst_no">Gst Number</MenuItem>
              <MenuItem value="location_id">Location Id</MenuItem>
              <MenuItem value="whatsapp_number">Whatsapp Number</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            id="value"
            label="Value"
            size="small"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            sx={{ mt: 1.5, ml: { xs: 0, md: 1 } }}
            InputLabelProps={{
              required: true,
            }}
          />
          <LoadingButton
            size="medium"
            color="primary"
            loading={loading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            variant="contained"
            type="button"
            onClick={getProviderData}
            disabled={!inputValue}
            sx={{ minWidth: "180px", mt: 1.5, ml: { xs: 0, md: 1 } }}
          >
            Search
          </LoadingButton>
        </Stack>
      </Card>
      {providersData && (
        <ErrorBoundary>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title=""
              data={providersData}
              columns={providersCols}
              options={options}
            />
          </ThemeProvider>
          {showProviderSettingsInfoModal && (
            <ProviderSettingsInfoModal
              showProviderSettingsInfoModal={showProviderSettingsInfoModal}
              setShowProviderSettingsInfoModal={
                setShowProviderSettingsInfoModal
              }
              activeLocationId={activeLocationId}
              providersData={providersData}
              setProvidersData={setProvidersData}
              path="providers"
              targetId={activeLocationId}
            />
          )}
        </ErrorBoundary>
      )}
      {addNewLocation ? (
        <AddNewLocationModal
          addNewLocation={addNewLocation}
          setAddNewLocation={setAddNewLocation}
          providersData={providersData}
          setProvidersData={setProvidersData}
        />
      ) : null}
      {updateCert ? (
        <UpdateCertModal
          updateCert={updateCert}
          setUpdateCert={setUpdateCert}
          activeLocationId={activeLocationId}
          callBack={null}
        />
      ) : null}
    </div>
  );
};

export default ProvidersSettings;
