import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { textCapitalize } from "../../utils/common";
import CustomDialogTitle from "../../components/Common/customDialogTitle";

interface IQuoteModalProp {
  data: {
    distance: number;
    estimated_price: number;
    eta: {
      pickup: number;
      dropoff: number;
    };
  };
  deliveryPartner: string;
  showQuoteModal: boolean;
  setShowQuoteModal: Dispatch<SetStateAction<boolean>>;
}

const QuoteModal = ({
  data,
  deliveryPartner,
  showQuoteModal,
  setShowQuoteModal,
}: IQuoteModalProp) => {
  return (
    <Dialog
      open={showQuoteModal}
      onClose={() => setShowQuoteModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowQuoteModal(false)}
      >
        {textCapitalize(deliveryPartner) + " Quote"}
      </CustomDialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Box>
          <Typography>Distance: {data.distance} km</Typography>
          <Typography>Estimated Price: Rs. {data.estimated_price}</Typography>
          <Typography>Pick-up in: {data.eta.pickup} min</Typography>
          <Typography>Drop-off in: {data.eta.dropoff} min</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default QuoteModal;
