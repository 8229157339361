import { Box, Button, Tab, Tabs, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { ORDER_STATUS, ORDER_TYPE } from "../../enums/order.enum";
import {
  a11yProps,
  errorToast,
  getDurationAgo,
  handleError,
  sortOrders,
  transformDurationInMin,
} from "../../utils/common";
import TabPanel from "../UI/tabPanel";
import AcceptedOrders from "./GroupOrders/Accepted/acceptedOrders";
import AssignedOrders from "./GroupOrders/Assign/assignedOrders";
import OrdersLastStage from "./GroupOrders/LastStage/ordersLastStage";
import PendingOrders from "./GroupOrders/Pending/pendingOrders";
import {
  API_RESULT,
  API_VERSION,
  ERROR_LIMIT_MINUES,
  USER_ROLE,
} from "../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData, setSearchText } from "../../store/actions/useActions";
import { parse } from "date-fns";
import ErrorIcon from "@mui/icons-material/Error";
import SearchIcon from "@mui/icons-material/Search";
import SearchOrderModal from "./GroupOrders/Common/searchOrderModal";

const ManageGrouped = () => {
  const location = useLocation();
  const orderType: string = location?.pathname?.split("/")[1]?.split("/")[0];
  const activeTab: any = location?.search?.split("=")[1];

  const [value, setValue] = useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const generateManageOrderRoute = (orderType: string, index: number) => {
    return `/${orderType}/manage-order?tab=${index}`;
  };

  const user: AuthenticatedUser = useUserInfo();
  let urlToFetchOrders: string;
  const transformedOrderType =
    orderType === "pharmacy" ? ORDER_TYPE.MEDICINES : orderType;
  if (user?.role === USER_ROLE.EDITOR) {
    urlToFetchOrders = `/orders/all/${user?.partner}?ver=v3&order_type=${transformedOrderType}&editor=${user.user_id}`;
  } else {
    urlToFetchOrders = `/orders/all/${user.partner}?ver=v3&order_type=${transformedOrderType}`;
  }
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [showLabelErr, setShowLabelErr] = useState(false);
  const [showOfdLabelErr, setShowOfdLabelErr] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const getOrders = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}${urlToFetchOrders}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        dispatch(setAllOrderData(sortOrders(result.data.response)));
        //check if there's any order rejected by merchant
        if (transformedOrderType === ORDER_TYPE.MEDICINES) {
          const d = result.data.response;
          //asigned red dot at top
          for (let i = 0; i < d.length; i++) {
            if (
              d[i]?.order_status === ORDER_STATUS.PENDING ||
              d[i]?.order_status === ORDER_STATUS.PARTNER_PENDING
            ) {
              if (
                d[i]?.nearby_merchants?.[0]?.status === "rejected" ||
                d[i]?.nearby_merchants?.[0]?.status === "mrp_filled" ||
                d[i]?.nearby_merchants?.[0]?.status === "accepted"
              ) {
                setShowLabelErr(true);
                break;
              }
            }
          }
          //out for delivery red dot at top
          for (let j = 0; j < d.length; j++) {
            if (
              d[j]?.order_status === ORDER_STATUS.PAYMENT_COLLECTED ||
              d[j]?.order_status === ORDER_STATUS.PACKAGE_PICKED
            ) {
              if (d[j]?.order_status === ORDER_STATUS.PAYMENT_COLLECTED) {
                if (
                  transformDurationInMin(
                    getDurationAgo(d[j]?.payment_confirmed_at, 0)
                  ) > ERROR_LIMIT_MINUES.COMMON
                ) {
                  setShowOfdLabelErr(true);
                  break;
                }
              }
              if (d[j]?.order_status === ORDER_STATUS.PACKAGE_PICKED) {
                if (
                  transformDurationInMin(
                    getDurationAgo(d[j]?.package_picked_at, 0)
                  ) > ERROR_LIMIT_MINUES.PACKAGE_PICKED
                ) {
                  setShowOfdLabelErr(true);
                  break;
                }
              }
            }
          }
          //end
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      dispatch(setAllOrderData([]));
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //reset searched text on page load
    dispatch(setSearchText(""));

    getOrders();

    return () => {
      dispatch(setAllOrderData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterAndSort = (ord_type: string, order_stage: string) => {
    let filteredOrders: any = [];

    if (order_stage === "assigned") {
      filteredOrders = ordersToManage?.filter(
        (x: any) =>
          (x.order_status === ORDER_STATUS.PENDING ||
            x.order_status === ORDER_STATUS.PARTNER_PENDING) &&
          x.nearby_merchants &&
          x.nearby_merchants.length !== 0
      );
    }
    if (order_stage === "accepted") {
      filteredOrders = ordersToManage?.filter(
        (x: any) =>
          x.order_status === ORDER_STATUS.ACCEPTED ||
          x.order_status === ORDER_STATUS.INVOICE_GENERATED ||
          x.order_status === ORDER_STATUS.PAYMENT_PENDING
      );
    }
    if (order_stage === "last") {
      if (ord_type === ORDER_TYPE.CONSULTATION) {
        filteredOrders = ordersToManage?.filter(
          (x: any) =>
            (x.order_status === ORDER_STATUS.PAYMENT_COLLECTED ||
              x.order_status === ORDER_STATUS.BOOKING_CONFIRMED ||
              x.order_status === ORDER_STATUS.BOOKING_COMPLETED ||
              x.order_status === ORDER_STATUS.CLAIM_SUBMITTED ||
              x.order_status === ORDER_STATUS.PRESCRIPTION_UPLOADED ||
              x.order_status === ORDER_STATUS.INVOICE_SUBMITTED) &&
            !x.fulfilled
        );
      }
      if (ord_type === ORDER_TYPE.DIAGNOSTICS) {
        filteredOrders = ordersToManage?.filter(
          (x: any) =>
            (x.order_status === ORDER_STATUS.PAYMENT_COLLECTED ||
              x.order_status === ORDER_STATUS.SAMPLE_COLLECTED ||
              x.order_status === ORDER_STATUS.BOOKING_COMPLETED ||
              x.order_status === ORDER_STATUS.BOOKING_CONFIRMED ||
              x.order_status === ORDER_STATUS.REPORTS_GENERATED) &&
            !x.fulfilled
        );
      }
      if (ord_type === ORDER_TYPE.MEDICINES) {
        filteredOrders = ordersToManage?.filter(
          (x: any) =>
            (x.order_status === ORDER_STATUS.PACKAGE_PICKED ||
              x.order_status === ORDER_STATUS.PACKAGE_DELIVERED ||
              x.order_status === ORDER_STATUS.PAYMENT_COLLECTED) &&
            !x.fulfilled
        );
      }
    }

    let filteredAndSortedOrders: any = [];

    if (ord_type === ORDER_TYPE.CONSULTATION) {
      filteredAndSortedOrders = filteredOrders?.sort((a: any, b: any) => {
        a = parse(a.time_slot, "dd-MM-yyyy, hh:mm aa", new Date());
        b = parse(b.time_slot, "dd-MM-yyyy, hh:mm aa", new Date());
        return a - b;
      });
    }
    if (ord_type === ORDER_TYPE.DIAGNOSTICS) {
      filteredAndSortedOrders = filteredOrders?.sort((a: any, b: any) => {
        a = parse(
          a.modified_at?.split(".")[0],
          "dd/MM/yyyy HH:mm:ss",
          new Date()
        );
        b = parse(
          b.modified_at?.split(".")[0],
          "dd/MM/yyyy HH:mm:ss",
          new Date()
        );
        return b - a;
      });
    }
    if (ord_type === ORDER_TYPE.MEDICINES) {
      filteredAndSortedOrders = filteredOrders?.sort((a: any, b: any) => {
        a = parse(
          a.modified_at?.split(".")[0],
          "dd/MM/yyyy HH:mm:ss",
          new Date()
        );
        b = parse(
          b.modified_at?.split(".")[0],
          "dd/MM/yyyy HH:mm:ss",
          new Date()
        );
        return a - b;
      });

      //push rejected orders at top
      const rejectedOrders =
        filteredAndSortedOrders?.filter(
          (x: any) => x?.nearby_merchants?.[0]?.status === "rejected"
        ) || [];
      const nonRejectedOrders =
        filteredAndSortedOrders?.filter(
          (x: any) => x?.nearby_merchants?.[0]?.status !== "rejected"
        ) || [];
      filteredAndSortedOrders = [...rejectedOrders, ...nonRejectedOrders];
      //end
    }

    return filteredAndSortedOrders;
  };

  const filterAndSortConsPendingOrders = () => {
    let filteredOrders: any = ordersToManage?.filter(
      (x: any) =>
        x.order_status === ORDER_STATUS.PENDING &&
        (!x.nearby_merchants || x.nearby_merchants.length === 0)
    );
    let filteredAndSortedOrders: any = filteredOrders?.sort(
      (a: any, b: any) => {
        a = parse(a.time_slot, "dd-MM-yyyy, hh:mm aa", new Date());
        b = parse(b.time_slot, "dd-MM-yyyy, hh:mm aa", new Date());
        return a - b;
      }
    );
    return filteredAndSortedOrders;
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: { xs: "block", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="basic tabs example"
          >
            <Tab
              label="Pending"
              {...a11yProps(0)}
              onClick={() => navigate(generateManageOrderRoute(orderType, 1))}
            />
            <Tab
              icon={
                showLabelErr ? (
                  <Tooltip title="Action Required">
                    <ErrorIcon color="error" fontSize="small" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
              iconPosition="end"
              label="Assigned"
              {...a11yProps(1)}
              onClick={() => navigate(generateManageOrderRoute(orderType, 2))}
            />
            <Tab
              label="Accepted"
              {...a11yProps(2)}
              onClick={() => navigate(generateManageOrderRoute(orderType, 3))}
            />
            <Tab
              icon={
                showOfdLabelErr ? (
                  <Tooltip title="Action Required">
                    <ErrorIcon color="error" fontSize="small" />
                  </Tooltip>
                ) : (
                  <></>
                )
              }
              iconPosition="end"
              label={
                orderType === ORDER_TYPE.CONSULTATION
                  ? "Booking"
                  : orderType === ORDER_TYPE.DIAGNOSTICS
                  ? "Confirmed"
                  : "Out for delivery"
              }
              {...a11yProps(3)}
              onClick={() => navigate(generateManageOrderRoute(orderType, 4))}
            />
          </Tabs>
          {orderType !== "pharmacy" && value === 3 ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setShowSearch(true)}
              startIcon={<SearchIcon />}
            >
              Find Order
            </Button>
          ) : null}
        </Box>
        <TabPanel value={value} index={0}>
          <PendingOrders
            data={
              orderType === ORDER_TYPE.CONSULTATION
                ? filterAndSortConsPendingOrders()
                : ordersToManage?.filter(
                    (x: any) =>
                      x.order_status === ORDER_STATUS.PENDING &&
                      (!x.nearby_merchants || x.nearby_merchants.length === 0)
                  )
            }
            orderType={transformedOrderType}
            getOrders={getOrders}
            dataLoading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AssignedOrders
            data={filterAndSort(transformedOrderType, "assigned")}
            orderType={transformedOrderType}
            getOrders={getOrders}
            dataLoading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AcceptedOrders
            data={filterAndSort(transformedOrderType, "accepted")}
            orderType={transformedOrderType}
            getOrders={getOrders}
            dataLoading={loading}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <OrdersLastStage
            data={filterAndSort(transformedOrderType, "last")}
            orderType={transformedOrderType}
            getOrders={getOrders}
            dataLoading={loading}
          />
        </TabPanel>
      </Box>
      {showSearch ? (
        <SearchOrderModal
          open={showSearch}
          onClose={() => setShowSearch(false)}
        />
      ) : null}
    </>
  );
};

export default ManageGrouped;
