const dev = {
  ENV: "DEV",
  API_KEY: "7uwLpggw7g89MAxfI4qHO76jTuYRI3LY4yK377aZ",
  URL: "https://api.dev.medpay.in/",
  ENC_KEY: "B96lsYHgMucoH7GUf8XXymrJlEzClmi5",
  REACT_APP_SENTRY_DSN:
    "https://ba42c8ad0f154fb3abf6791495e1457c@o4504218649886720.ingest.sentry.io/4504218651131904",
  ONDC_API_URL: "https://ondc.uat.medpay.in/",
};

const prod = {
  ENV: "PROD",
  API_KEY: "IwqYmi3pfn9NNjrVeaSRZ2KNMKSuoLMD6mQbGIDi",
  URL: "https://apis.medpay.in/",
  ENC_KEY: "saUd3ruTIHLvcZIGXUciGDonGzNJ4em4",
  REACT_APP_SENTRY_DSN:
    "https://319c8a34d8514e6f93d55947d7898805@o4504218649886720.ingest.sentry.io/4504293026299904",
  ONDC_API_URL: "https://ondc.medpay.in/",
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default config;
