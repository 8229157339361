import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import KnowlarityCall from "../Common/knowlarityCall";
import { ORDER_TYPE } from "../../enums/order.enum";
import { USER_ROLE } from "../../enums/common.enum";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";

interface ICustomerDetailsProp {
  orderId: string;
  customer_details: {
    name: string;
    mobile: string | number;
    email: string;
    address_details: {
      address: string;
      landmark: string;
      city: string;
      state: string;
      pin_code: string;
      coordinates?: string;
    };
  };
  order_type: string;
  patient_response: string;
}

const CustomerInfo = ({
  orderId,
  customer_details,
  order_type,
  patient_response,
}: ICustomerDetailsProp) => {
  const user: AuthenticatedUser = useUserInfo();
  const customerCoord = customer_details?.address_details?.coordinates;
  return (
    <>
      <Typography variant="h4">
        {order_type === ORDER_TYPE.CONSULTATION ? "Patient" : "Customer"}{" "}
        Details
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography>Name: {customer_details.name}</Typography>
        <KnowlarityCall
          order={{
            order_id: orderId,
            mobile: customer_details?.mobile,
          }}
          showNumber={false}
        />
      </Stack>
      {customer_details?.email && (
        <Typography>Email: {customer_details.email}</Typography>
      )}
      {user?.role === USER_ROLE.SUPER_ADMIN ||
      user?.role === USER_ROLE.ADMIN ||
      user?.role === USER_ROLE.MANAGER ? (
        <Typography>Mobile: {customer_details?.mobile}</Typography>
      ) : null}
      <Typography>
        Address:
        {` ${customer_details?.address_details?.address}, ${customer_details?.address_details?.landmark}, ${customer_details?.address_details?.city}, ${customer_details?.address_details?.state} - ${customer_details?.address_details?.pin_code}`}
      </Typography>
      {customerCoord && customerCoord !== "" && customerCoord !== "," ? (
        <Stack direction="row">
          <Typography variant="body1" minWidth="90px">
            Coordinates:&nbsp;
          </Typography>
          <Link
            target="_blank"
            href={`https://www.google.com/maps?q=${customerCoord}`}
          >
            {customerCoord}
          </Link>
        </Stack>
      ) : null}
      {user?.role !== USER_ROLE.PARTNER &&
      patient_response &&
      patient_response?.trim() !== "" &&
      patient_response?.toLowerCase() !== "na" ? (
        <Stack direction="row" mt={1}>
          <Typography variant="h6" minWidth="90px">
            Feedback:
          </Typography>
          <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
            {patient_response}
          </Typography>
        </Stack>
      ) : null}
    </>
  );
};

export default CustomerInfo;
