import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import CancelIcon from "@mui/icons-material/Cancel";

const TEMPLATE_DATA = [
  {
    id: 1,
    label: "App Performance",
    desc: "Thank you for reporting this issue. We have notified our concerned team to look into this issue. We will contact you if any additional information is required.",
    category: "App",
  },
  {
    id: 2,
    label: "Notification Delay",
    desc: "Thank you for reporting this issue. We have notified our concerned team to look into this issue. We will contact you if any additional information is required.",
    category: "Soundbox",
  },
  {
    id: 3,
    label: "Payment Got Delayed",
    desc: "This transaction is pending at the beneficiary bank for credit and has to be reconciled manually by the beneficiary bank. The standard TAT for such cases given by NPCI is a maximum of T+3 working days. We will keep this ticket open to keep you updated on the status of this transaction.",
    category: "Payout",
  },
  {
    id: 4,
    label: "Payout Did Not Happen",
    desc: "Thank you for reporting this issue. We have notified our concerned team to look into this issue. We will contact you if any additional information is required.",
    category: "Payout",
  },
  {
    id: 5,
    label: "Payout Got Delayed",
    desc: "Thank you for reporting this issue. We have notified our concerned team to look into this issue. We will contact you if any additional information is required.",
    category: "Payout",
  },
  {
    id: 6,
    label: "QR Name Change(docs provided)",
    desc: "We have forwarded your request to our KYC verification team. They will shortly be getting in touch with you and complete the KYC process.",
    category: "QR code",
  },
  {
    id: 7,
    label: "QR Name Change(no attachments)",
    desc: "We have received your request to display the name of your shop at the QR, however, the supporting documents required have not been provided. Kindly create a new ticket and attach the documents along with it.",
    category: "QR code",
  },
  {
    id: 8,
    label: "Soundbox",
    desc: "Thank you for registering your interest. Due to high demand, the deliveries of soundboxes has been delayed a bit. We have kept you in the waitlist and will be contacted shortly.",
    category: "Soundbox",
  },
  {
    id: 9,
    label: "Statement",
    desc: "Thank you for reporting this issue. We have notified our concerned team to look into this issue. We will contact you if any additional information is required.",
    category: "Statement",
  },
  {
    id: 10,
    label: "Ticket Closure Emails",
    desc: "We hope that the reported issue has been resolved. If you're not happy with the resolution, kindly feel free to let us know by sending an email at hello@medpay.in.",
    category: "General",
  },
];

const ResponseTemplateModal = ({
  open,
  onClose,
  setTextContent,
  issueType,
}: any) => {
  const [msgTemplate, setMsgTemplate] = useState<any>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [filterBy, setFilterBy] = React.useState<string>(issueType);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            if (msgTemplate) {
              setTextContent(msgTemplate);
            }
            onClose();
          }}
        >
          <Box display="flex" gap={1}>
            <Typography>Template</Typography>
            <Chip
              size="small"
              label={issueType}
              onClick={() => setFilterBy(issueType)}
              onDelete={() => setFilterBy("all")}
              deleteIcon={
                filterBy === issueType ? (
                  <CancelIcon fontSize="small" />
                ) : (
                  <p></p>
                )
              }
              variant={filterBy === issueType ? "filled" : "outlined"}
              color="primary"
            />
          </Box>
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                size="small"
                value={
                  TEMPLATE_DATA.find((x: any) => x.desc === msgTemplate)
                    ?.label as any
                }
                onChange={(event: any, newValue: any) => {
                  setMsgTemplate(newValue?.desc);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="select-template"
                options={
                  filterBy === "all"
                    ? TEMPLATE_DATA
                    : TEMPLATE_DATA?.filter(
                        (x) =>
                          x.category === "General" || x.category === issueType
                      )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Template" />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                {msgTemplate ? (
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    id="text-content"
                    label="Text"
                    value={msgTemplate}
                    InputLabelProps={{
                      required: true,
                    }}
                    disabled
                  />
                ) : (
                  "No template selected."
                )}
              </Box>
            </Grid>
          </Grid>
          <Box textAlign="center" mt={3}>
            <Button
              onClick={() => {
                if (msgTemplate) {
                  setTextContent(msgTemplate);
                }
                onClose();
              }}
              variant="contained"
              color="primary"
              size="small"
              disabled={!msgTemplate}
            >
              Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ResponseTemplateModal;
