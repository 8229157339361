import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateCart } from "../../store/actions/useActions";
import AddAndAlterItems from "./addAndAlterItems";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const MedicineCard = ({ medicine, setValue }: any) => {
  const cartDataFromStore =
    useSelector((state: any) => state.cart.cartData) || [];
  const dispatch = useDispatch();

  const deleteItem = (id: string) => {
    let updatedData = [...cartDataFromStore];
    updatedData = updatedData.filter((medicine) => medicine.id !== id);
    dispatch(UpdateCart(updatedData));
    localStorage.setItem("cart_data", JSON.stringify(updatedData));
  };

  return (
    <div>
      <Card
        onClick={() => {
          setValue(medicine);
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
          boxShadow: "0px 4px 8px #e4e4e4",
        }}
      >
        {cartDataFromStore?.find((item: any) => item.id === medicine.id) ? (
          <Stack sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                deleteItem(medicine.id);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Stack>
        ) : null}
        <Box pt={1} textAlign="center">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 128 128"
            width="55px"
            height="55px"
            fill="#0058ff"
          >
            <path d="M61.748 70.68h-4.914v-4.914a1.749 1.749 0 0 0-1.75-1.75h-8.649a1.75 1.75 0 0 0-1.75 1.75v4.914h-4.913a1.75 1.75 0 0 0-1.75 1.75v8.648a1.75 1.75 0 0 0 1.75 1.75h4.913v4.913a1.75 1.75 0 0 0 1.75 1.75h8.649a1.749 1.749 0 0 0 1.75-1.75v-4.913h4.914a1.75 1.75 0 0 0 1.75-1.75V72.43a1.75 1.75 0 0 0-1.75-1.75zM60 79.328h-4.916a1.75 1.75 0 0 0-1.75 1.75v4.913h-5.149v-4.913a1.75 1.75 0 0 0-1.75-1.75h-4.913V74.18h4.913a1.751 1.751 0 0 0 1.75-1.75v-4.914h5.149v4.914a1.751 1.751 0 0 0 1.75 1.75H60z" />
            <path d="M97.694 93.58H82.223V45.986a8.531 8.531 0 0 0-6.712-8.3l-1.93-.414a5.014 5.014 0 0 1-3.945-4.872v-4.633h3.975a1.75 1.75 0 0 0 1.75-1.75V14.146a1.75 1.75 0 0 0-1.75-1.75h-45.7a1.751 1.751 0 0 0-1.75 1.75v11.871a1.751 1.751 0 0 0 1.75 1.75h3.975V32.4a5.013 5.013 0 0 1-3.944 4.877l-1.931.414a8.53 8.53 0 0 0-6.712 8.3v61.129a8.5 8.5 0 0 0 8.488 8.489h45.948a8.483 8.483 0 0 0 3.379-.712 10.919 10.919 0 0 0 3.847.708h16.733a11.01 11.01 0 0 0 0-22.02zM22.8 59.924h55.923v33.66H22.8zM29.658 15.9h42.2v8.371h-42.2zm-2.917 25.208 1.93-.413a8.529 8.529 0 0 0 6.712-8.3v-4.628h30.753V32.4a8.53 8.53 0 0 0 6.713 8.3l1.929.413a5.016 5.016 0 0 1 3.945 4.878v10.433H22.8V45.986a5.015 5.015 0 0 1 3.941-4.878zM22.8 107.115V97.084h50.134a10.942 10.942 0 0 0 0 15.02h-45.15a4.994 4.994 0 0 1-4.984-4.989zm50.655-2.525a7.528 7.528 0 0 1 7.51-7.51h6.622v15.02h-6.626a7.519 7.519 0 0 1-7.51-7.51zm29.563 5.311a7.5 7.5 0 0 1-5.32 2.2h-6.615V97.08h6.611a7.517 7.517 0 0 1 5.32 12.821z" />
          </svg>
        </Box>
        <CardContent>
          <Typography noWrap variant="h5">
            {medicine.name}
          </Typography>
          <Typography noWrap my={0.5} variant="body1">
            <strong>{medicine.packaging}</strong> &nbsp;|&nbsp;{" "}
            {medicine.manufacturer}
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={1.5}
          >
            <Stack>
              <Typography fontSize={14} variant="body1">
                MRP&nbsp;: &#8377; {medicine.mrp}
              </Typography>
              <Typography mt={0.5} fontSize={14} variant="body1">
                PTR&nbsp;&nbsp;:&nbsp;
                <strong>
                  <span style={{ color: "#0058ff" }}>
                    &#8377; {Number(medicine.ptr)?.toFixed(2)}
                  </span>
                </strong>
              </Typography>
            </Stack>
            <AddAndAlterItems medicine={medicine} view="card" />
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default MedicineCard;
