import * as yup from "yup";

export const createLedgerTxnSchema = yup.object().shape({
  txn_date: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("Txn Date is required"),
  txn_type: yup.string().required("Txn type is required"),
  reference_id: yup.string().required("Reference ID is required"),
  description: yup.string().required("Description is required"),
  amount: yup
    .number()
    .nullable()
    .min(1, "cannot be less than 1")
    .required("Amount is required"),
});

export const editLedgerInfoSchema = yup.object().shape({
  start_date: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("Start Date is required"),
  delivery_type: yup.string().required("Delivery Type is required"),
  company_gst: yup.string().required("Company GST is required"),
  company_name: yup.string().required("Company Name is required"),
  company_address: yup.string().required("Company Address is required"),
  company_state_code: yup.string().required("Company State code is required"),
  fixed_delivery_amt: yup
    .number()
    .nullable()
    .when("delivery_type", {
      is: (delivery_type: any) => delivery_type === "fixed",
      then: yup.number().nullable().required("Amount is required"),
    }),
  invoice_cycle: yup.string().required("Invoice cycle is required"),
  low_balance: yup
    .number()
    .nullable()
    .max(0, "should be negative value")
    .required("Low balance is required"),
  credit_limit: yup
    .number()
    .nullable()
    .min(0, "should be positive value")
    .required("Credit limit is required"),
  processing_fee: yup.string().required("Processing fee is required"),
  service_offering: yup.string().required("Service offering is required"),
  fmcg: yup.string().when("value_based", {
    is: (value_based: any) => value_based === false,
    then: yup.string().required("FMCG % is required"),
  }),
  medicine: yup.string().when("value_based", {
    is: (value_based: any) => value_based === false,
    then: yup.string().required("Medicines % is required"),
  }),
  minimum_order_value: yup.string().when("value_based", {
    is: (value_based: any) => value_based === true,
    then: yup.string().required("Min. Order Value is required"),
  }),
});
