import { makeStyles } from "@mui/styles";

export const useIconStyles = makeStyles({
  borderedIcon: {
    border: "2px solid",
    padding: 2,
    borderRadius: 4,
    fontSize: 24,
  },
  borderedIconSmall: {
    border: "1.5px solid",
    padding: 1.5,
    borderRadius: 3,
    fontSize: 16,
  },
});
