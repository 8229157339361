import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import MultiSelectPartners from "./multiSelectPartners";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { differenceInCalendarDays } from "date-fns";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { useDispatch } from "react-redux";
import { reportsEmailTimeInterval } from "../../constants";
import { errorToast, handleError, successToast } from "../../utils/common";
import { format } from "date-fns";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  setAuditEmailTime,
  setCashlessEmailTime,
  setCustomerCartEmailTime,
  setOrderEmailTime,
} from "../../store/actions/useActions";
import useUserInfo from "../../hooks/useUserInfo";
import {
  API_RESULT,
  API_VERSION,
  REPORT_TYPE,
  USER_ROLE,
} from "../../enums/common.enum";
import { Form, Formik } from "formik";
import {
  mailReportSchema,
  mailReportSchemaAudit,
  mailReportSchemaOrder,
} from "../../schemas/common";

const EmailModal = ({
  reportModal,
  setReportModal,
  setTimeRemaining,
  reportType,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [disableEmail, setDisableEmail] = useState<boolean>(false);
  const [showEmailList, setShowEmailList] = useState<boolean>(false);
  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  const privateInstnace = useAxiosInstance();
  const dispatch = useDispatch();
  const [reportEmails, setReportEmails] = useState<string[]>([
    "ravi@medpay.in",
    "drsahil@medpay.in",
    "arun@medpay.in",
    "manoj.pradhan@medpay.in",
    "accounts@medpay.in",
    "anuj@medpay.in",
  ]);

  const user: AuthenticatedUser = useUserInfo();
  interface emailReport {
    start_date?: Date | string | null;
    end_date?: Date | string | null;
    order_partner?: string | null | undefined;
    email?: string | null | undefined;
    filter_by?: string | null;
    job_type?: string | null;
    email_id?: string | null | undefined;
    corporate_id?: string | null | undefined;
    version?: string;
    order_type?: string;
    report_options?: any;
  }

  const emailOrderReports = async (values: any, actions: any) => {
    let payload: emailReport = {
      start_date: values?.start_date
        ? format(values?.start_date, "dd/MM/yyyy")
        : null,
      end_date: values?.end_date
        ? format(values?.end_date, "dd/MM/yyyy")
        : null,
      version: "v2",
      email: values?.email,
    };

    if (reportType === REPORT_TYPE.ORDER) {
      if (user?.partner?.toLowerCase() === "medpay") {
        payload = {
          ...payload,
          order_partner: user?.partner,
          filter_by: values?.provider_id?.toLowerCase(),
          order_type: values.order_type,
          report_options: {
            order_items: values.order_items,
            include_static_qr: values.include_static_qr,
          },
        };
      } else {
        payload = {
          ...payload,
          order_partner: values?.provider_id?.toLowerCase(),
          filter_by: "all",
        };
      }
    }
    if (reportType === REPORT_TYPE.CASHLESS) {
      // TODO: discuss if we can make payload same
      payload = {
        start_date: values?.start_date
          ? format(values?.start_date, "dd/MM/yyyy")
          : null,
        end_date: values?.end_date
          ? format(values?.end_date, "dd/MM/yyyy")
          : null,
        email_id: values?.email,
        corporate_id: values?.provider_id,
      };
    }
    if (reportType === REPORT_TYPE.AUDIT) {
      payload = {
        ...payload,
        order_partner: values?.provider_id?.toLowerCase(),
        job_type: values?.report_type,
      };
    }
    if (reportType === REPORT_TYPE.CUSTOMER_CART) {
      payload = {
        email: values?.email,
        job_type: reportType,
      };
    }
    //console.log("pl", payload);

    setTimeRemaining(reportsEmailTimeInterval);
    //setReportModal(false);
    setLoading(true);
    try {
      // TODO: discuss if we can make endpoint same
      const reportApiUrl =
        reportType === REPORT_TYPE.CASHLESS
          ? "/opd/reports"
          : "/orders/reports/all";
      const result = await privateInstnace.post(
        `${API_VERSION.V1}${reportApiUrl}`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (reportType === REPORT_TYPE.CASHLESS) {
          successToast(`Reports will be mailed to ${payload?.email_id}!`);
        } else {
          successToast(`Reports will be mailed to ${payload?.email}!`);
        }
        if (reportType === REPORT_TYPE.ORDER) {
          localStorage.setItem(
            "orderReportTime",
            JSON.stringify(new Date().toISOString())
          );
          dispatch(setOrderEmailTime(new Date().toISOString()));
        }
        if (reportType === REPORT_TYPE.CASHLESS) {
          localStorage.setItem(
            "cashlessReportTime",
            JSON.stringify(new Date().toISOString())
          );
          dispatch(setCashlessEmailTime(new Date().toISOString()));
        }
        if (reportType === REPORT_TYPE.AUDIT) {
          localStorage.setItem(
            "auditReportTime",
            JSON.stringify(new Date().toISOString())
          );
          dispatch(setAuditEmailTime(new Date().toISOString()));
        }
        if (reportType === REPORT_TYPE.CUSTOMER_CART) {
          localStorage.setItem(
            "customerCartReportTime",
            JSON.stringify(new Date().toISOString())
          );
          dispatch(setCustomerCartEmailTime(new Date().toISOString()));
        }
        setTimeRemaining(reportsEmailTimeInterval);
        setReportModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      user?.role === USER_ROLE.SUPER_ADMIN ||
      user?.role === USER_ROLE.ADMIN
    ) {
      setDisableEmail(true);
    }
    if (user?.role === USER_ROLE.MANAGER || user?.user_id === "mp00123789") {
      const updatedEmailIdList = [...reportEmails, String(user?.user_id)];
      setReportEmails([...(new Set(updatedEmailIdList) as any)]);
      setShowEmailList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReportModalClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setReportModal(!reportModal);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={reportModal}
      onClose={handleReportModalClose}
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setReportModal(false)}
      >
        Mail Report
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            provider_id:
              reportType === REPORT_TYPE.ORDER &&
              user?.role === USER_ROLE.PARTNER
                ? user?.partner
                : null,
            start_date: null,
            end_date: null,
            email:
              user?.role === USER_ROLE.SUPER_ADMIN ||
              user?.role === USER_ROLE.ADMIN ||
              reportType === REPORT_TYPE.CUSTOMER_CART
                ? user?.user_id
                : "",
            report_type: "",
            order_type: "",
            order_items: true,
            include_static_qr: false,
          }}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            emailOrderReports(values, actions);
          }}
          validationSchema={
            reportType === REPORT_TYPE.CUSTOMER_CART
              ? null
              : reportType === REPORT_TYPE.AUDIT
              ? mailReportSchemaAudit
              : reportType === REPORT_TYPE.ORDER &&
                user?.role !== USER_ROLE.PARTNER
              ? mailReportSchemaOrder
              : mailReportSchema
          }
        >
          {(props: any) => {
            const {
              values,
              errors,
              touched,
              handleBlur,
              setFieldValue,
              isSubmitting,
            } = props;
            return (
              <Form>
                {reportType === REPORT_TYPE.CUSTOMER_CART ? (
                  <Typography
                    variant="body1"
                    color="#4A5568"
                    textAlign="center"
                  >
                    The report will be mailed to <b>{values.email}</b>
                  </Typography>
                ) : (
                  <>
                    {reportType === REPORT_TYPE.AUDIT && (
                      <>
                        <FormControl
                          size="small"
                          fullWidth
                          sx={{ mt: 1.5 }}
                          error={
                            errors.report_type && touched.report_type
                              ? true
                              : false
                          }
                        >
                          <InputLabel required id="report-type-label">
                            Report Type
                          </InputLabel>
                          <Select
                            labelId="report-type-label"
                            name="report_type"
                            id="report_type"
                            value={values?.report_type}
                            label="Report Type"
                            onChange={(e: SelectChangeEvent) => {
                              setFieldValue("report_type", e.target.value);
                              if (e.target.value === "billing_report") {
                                setFieldValue("provider_id", "phablecare");
                              }
                              if (e.target.value === "reco_report") {
                                setFieldValue("provider_id", "medpay");
                              }
                            }}
                            onBlur={handleBlur}
                          >
                            <MenuItem key="Billing" value="billing_report">
                              Billing
                            </MenuItem>
                            <MenuItem key="Reconciliation" value="reco_report">
                              Reconciliation
                            </MenuItem>
                          </Select>
                          {errors.report_type && touched.report_type && (
                            <FormHelperText>
                              {errors.report_type}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <TextField
                          fullWidth
                          id="provider_id"
                          name="provider_id"
                          label="Partner"
                          size="small"
                          value={values?.provider_id || ""}
                          sx={{ mt: 1.5 }}
                          disabled={true}
                          InputLabelProps={{
                            required: true,
                          }}
                        />
                      </>
                    )}
                    {reportType === REPORT_TYPE.CASHLESS && (
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ mt: 1.5 }}
                        error={
                          errors.provider_id && touched.provider_id
                            ? true
                            : false
                        }
                      >
                        <InputLabel required id="provider-id-label">
                          Corporate
                        </InputLabel>
                        <Select
                          labelId="provider-id-label"
                          name="provider_id"
                          id="provider_id"
                          value={values?.provider_id}
                          label="Corporate"
                          onChange={(e: SelectChangeEvent) =>
                            setFieldValue("provider_id", e.target.value)
                          }
                          onBlur={handleBlur}
                        >
                          <MenuItem key="All" value="All">
                            All
                          </MenuItem>
                        </Select>
                        {errors.provider_id && touched.provider_id && (
                          <FormHelperText>{errors.provider_id}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                    {reportType === REPORT_TYPE.ORDER &&
                      user?.role !== USER_ROLE.PARTNER && (
                        <>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{ mt: 1.5 }}
                            error={errors.order_type && touched.order_type}
                          >
                            <InputLabel required id="order-type-label">
                              Order Type
                            </InputLabel>
                            <Select
                              labelId="order-type-label"
                              name="order-type"
                              id="order-type"
                              value={values.order_type}
                              label="Order Type"
                              onChange={(e: SelectChangeEvent) => {
                                setFieldValue("order_type", e.target.value);
                              }}
                              onBlur={handleBlur}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {[
                                "all",
                                "medicines",
                                "consultations",
                                "diagnostics",
                              ].map((item: string, index: number) => {
                                return (
                                  <MenuItem
                                    sx={{ textTransform: "capitalize" }}
                                    key={index}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.order_type && touched.order_type && (
                              <FormHelperText>
                                {errors.order_type}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <MultiSelectPartners
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                          />
                        </>
                      )}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        open={openStartDate}
                        onOpen={() => setOpenStartDate(true)}
                        onClose={() => setOpenStartDate(false)}
                        label="Start Date"
                        inputFormat="dd/MM/yyyy"
                        value={values?.start_date}
                        onChange={(newValue: Date | null) => {
                          if (newValue && values?.end_date) {
                            if (newValue > values?.end_date) {
                              errorToast(
                                "Start date cannot be greater than end date"
                              );
                              return;
                            }
                            if (
                              differenceInCalendarDays(
                                values?.end_date,
                                newValue
                              ) > 31
                            ) {
                              errorToast(
                                "Difference between two dates cannot be more than 31 days"
                              );
                              return;
                            }
                          }
                          setFieldValue("start_date", newValue);
                        }}
                        maxDate={
                          new Date(new Date().setDate(new Date().getDate() - 1))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            sx={{ mt: 1.5 }}
                            name="start_date"
                            helperText={
                              errors.start_date && touched.start_date
                                ? errors.start_date
                                : ""
                            }
                            error={
                              errors.start_date && touched.start_date
                                ? true
                                : false
                            }
                            onBlur={handleBlur}
                            InputLabelProps={{
                              required: true,
                            }}
                            autoComplete="off"
                            onKeyDown={(e: any) => {
                              e.preventDefault();
                            }}
                            onClick={(e) => setOpenStartDate(true)}
                          />
                        )}
                      />
                      <DatePicker
                        open={openEndDate}
                        onOpen={() => setOpenEndDate(true)}
                        onClose={() => setOpenEndDate(false)}
                        label="End Date"
                        inputFormat="dd/MM/yyyy"
                        value={values?.end_date}
                        onChange={(newValue: Date | null) => {
                          if (newValue && values?.start_date) {
                            if (newValue < values?.start_date) {
                              errorToast(
                                "End date cannot be smaller than start date"
                              );
                              return;
                            }
                            if (
                              differenceInCalendarDays(
                                newValue,
                                values?.start_date
                              ) > 31
                            ) {
                              errorToast(
                                "Difference between two dates cannot be more than 31 days"
                              );
                              return;
                            }
                          }
                          setFieldValue("end_date", newValue);
                        }}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            sx={{ mt: 1.5 }}
                            name="end_date"
                            helperText={
                              errors.end_date && touched.end_date
                                ? errors.end_date
                                : ""
                            }
                            error={
                              errors.end_date && touched.end_date ? true : false
                            }
                            onBlur={handleBlur}
                            InputLabelProps={{
                              required: true,
                            }}
                            autoComplete="off"
                            onKeyDown={(e: any) => {
                              e.preventDefault();
                            }}
                            onClick={(e) => setOpenEndDate(true)}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {reportType === REPORT_TYPE.ORDER &&
                      user?.role !== USER_ROLE.PARTNER && (
                        <Stack
                          mt={0.5}
                          direction="row"
                          justifyContent="space-between"
                        >
                          <FormControlLabel
                            sx={{ width: "100%" }}
                            control={
                              <Checkbox
                                checked={values.order_items}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "order_items",
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                            label="Order Items"
                          />
                          <FormControlLabel
                            sx={{ width: "100%" }}
                            control={
                              <Checkbox
                                checked={values.include_static_qr}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "include_static_qr",
                                    e.target.checked
                                  );
                                }}
                              />
                            }
                            label="Include Static QR"
                          />
                        </Stack>
                      )}
                    {showEmailList ? (
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ mt: 1.5 }}
                        error={errors.email && touched.email ? true : false}
                      >
                        <InputLabel required id="email-label">
                          Email
                        </InputLabel>
                        <Select
                          labelId="email-label"
                          name="email"
                          id="email"
                          value={values?.email}
                          label="Email"
                          onChange={(e: SelectChangeEvent) =>
                            setFieldValue("email", e.target.value)
                          }
                          onBlur={handleBlur}
                        >
                          {reportEmails.map((x) => (
                            <MenuItem key={x} value={x}>
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.email && touched.email && (
                          <FormHelperText>{errors.email}</FormHelperText>
                        )}
                      </FormControl>
                    ) : (
                      <TextField
                        fullWidth
                        id="email"
                        label="Email"
                        size="small"
                        value={values?.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        disabled={disableEmail}
                        InputLabelProps={{
                          required: true,
                        }}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : ""
                        }
                      />
                    )}
                  </>
                )}
                <Box sx={{ textAlign: "center", mt: 1.5 }}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    sx={{ width: "160px" }}
                  >
                    Send
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EmailModal;
