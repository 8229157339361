import {
  Box,
  IconButton,
  Link,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import { parseAndFormateDate } from "../../../../utils/common";
import KnowlarityCall from "../../../Common/knowlarityCall";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import { useIconStyles } from "../../../Helpers/iconStyles";
import UpdateStatusModal from "./updateStatusModal";

const TableRowDataView = ({
  merchant_details,
  orderType,
  orderId,
  customerDetails,
}: any) => {
  const iconClasses = useIconStyles();
  const [updateStatusModal, setUpdateStatusModal] = useState(false);

  return (
    <>
      <TableRow
        key={merchant_details?.name}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell scope="row">
          <Stack direction="row" alignItems="center">
            <Box>
              <Typography variant="body2">{merchant_details?.name}</Typography>
              <Typography variant="body2">
                {merchant_details?.profile_details?.full_name}
              </Typography>
              {orderType === ORDER_TYPE.DIAGNOSTICS && (
                <Rating
                  name="half-rating-read"
                  value={Number(merchant_details?.rating)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              )}
            </Box>
            {orderId !== "" && (
              <KnowlarityCall
                order={{
                  order_id: orderId,
                  mobile: merchant_details?.mobile,
                }}
                showNumber={false}
              />
            )}
            {orderType === ORDER_TYPE.MEDICINES ? (
              <Tooltip title="Edit">
                <IconButton
                  color="warning"
                  onClick={() => {
                    setUpdateStatusModal(true);
                  }}
                >
                  <EditIcon className={iconClasses.borderedIcon} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Stack>
        </TableCell>
        <TableCell>{merchant_details?.mobile}</TableCell>
        {orderType === ORDER_TYPE.MEDICINES && (
          <TableCell
            sx={{
              color: merchant_details?.qc_verified === "yes" ? "green" : "red",
            }}
          >
            {merchant_details?.qc_verified === "yes" ? "YES" : "NO"}
          </TableCell>
        )}
        <TableCell>
          <Stack>
            <Typography variant="body2">
              {merchant_details?.formatted_address}
            </Typography>
            {orderType === ORDER_TYPE.CONSULTATION &&
              merchant_details?.distance && (
                <Typography
                  variant="body2"
                  sx={{ fontStyle: "italic", fontWeight: "bold" }}
                >
                  Distance - {merchant_details?.distance} km
                </Typography>
              )}
          </Stack>
        </TableCell>
        {orderType === ORDER_TYPE.MEDICINES && (
          <>
            <TableCell>
              <Link
                href={`http://maps.google.com/maps?saddr=${merchant_details?.coordinates}&daddr=${customerDetails?.address_details?.coordinates}`}
                target="_blank"
              >
                Direction
              </Link>
            </TableCell>
            <TableCell>
              {parseAndFormateDate(merchant_details?.order_assigned_at)}
            </TableCell>
            <TableCell>
              <Box display="flex">
                <Typography variant="body2">
                  {merchant_details?.status}
                </Typography>
                {merchant_details?.reject_reason ? (
                  <Tooltip title={merchant_details.reject_reason}>
                    <InfoIcon sx={{ ml: 0.25 }} color="info" fontSize="small" />
                  </Tooltip>
                ) : null}
              </Box>
            </TableCell>
          </>
        )}
      </TableRow>
      {updateStatusModal ? (
        <UpdateStatusModal
          updateStatusModal={updateStatusModal}
          setUpdateStatusModal={setUpdateStatusModal}
          orderId={orderId}
        />
      ) : null}
    </>
  );
};

const CommonDataView = ({
  merchant_details,
  cols,
  orderType,
  orderId,
  customerDetails = null,
}: any) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {cols.map((x: string) => {
              return <TableCell key={x}>{x}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {orderType === ORDER_TYPE.MEDICINES ? (
            <>
              {merchant_details &&
                merchant_details.map((merchant: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRowDataView
                        merchant_details={merchant}
                        orderType={orderType}
                        orderId={orderId}
                        customerDetails={customerDetails}
                      />
                    </React.Fragment>
                  );
                })}
            </>
          ) : (
            <TableRowDataView
              merchant_details={merchant_details}
              orderType={orderType}
              orderId={orderId}
              customerDetails={customerDetails}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CommonDataView;
