import { Box, Button, Rating, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import PublicIcon from "@mui/icons-material/Public";
import ShieldIcon from "@mui/icons-material/Shield";

function stringToColor(string: string) {
  if (!string) return;
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (!name) return;
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name.includes(" ")
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : name[0],
  };
}

const NearbyClinicsTable = ({
  data,
  selectedClinc,
  setSelectedClinic,
  showHeader,
}: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "40%" } }}>
        {showHeader && (
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              mb: 3,
              display: { xs: "none", md: "block" },
            }}
          >
            Clinic/Doctor
          </Typography>
        )}

        <Stack direction="column" alignItems="flex-start">
          {selectedClinc && <Avatar {...stringAvatar(data.name)} />}
          <Stack direction="row" alignItems="start">
            {data?.onboarded?.toLowerCase() === "yes" ||
            data?.onboarded?.toLowerCase() === "y" ? (
              <Tooltip title="Private">
                <ShieldIcon
                  fontSize="small"
                  color="primary"
                  sx={{ mr: 0.5, mt: 0.25 }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Public">
                <PublicIcon
                  fontSize="small"
                  color="primary"
                  sx={{ mr: 0.5, mt: 0.25 }}
                />
              </Tooltip>
            )}
            <Stack>
              <Typography variant="body1">{data?.clinic_name}</Typography>
              <Typography variant="body1">{data?.name}</Typography>
            </Stack>
          </Stack>
          {data?.rating ? (
            <Rating
              name="disabled"
              value={Number(data.rating)}
              precision={0.1}
              readOnly
              size="small"
              sx={{ ml: 2.5 }}
            />
          ) : null}
        </Stack>
      </Box>
      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
        {showHeader && (
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              mb: 3,
              display: { xs: "none", md: "block" },
            }}
          >
            Address
          </Typography>
        )}
        <Typography variant="body1">
          {data.area || data.formatted_address}
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "10%" },
          textAlign: "center",
        }}
      >
        {showHeader && (
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              mb: 3,
              display: { xs: "none", md: "block" },
            }}
          >
            Action
          </Typography>
        )}
        <Button
          variant="contained"
          color={selectedClinc ? "error" : "primary"}
          sx={{ mt: { xs: 1.5, md: 0 } }}
          onClick={() => {
            selectedClinc ? setSelectedClinic(null) : setSelectedClinic(data);
          }}
        >
          {selectedClinc ? "Change" : "Book"}
        </Button>
      </Box>
    </Box>
  );
};

export default NearbyClinicsTable;
