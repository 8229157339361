import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { parseAndFormateDate } from "../../utils/common";
import { ORDER_TYPE } from "../../enums/order.enum";
import { USER_ROLE } from "../../enums/common.enum";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QrCode2Icon from "@mui/icons-material/QrCode2";

interface IMerchantDetailsProp {
  merchant_details: {
    name: string;
    formatted_address: string | number;
    order_assigned_at: string;
    association_display_name?: string;
    mobile?: string;
    platform?: string;
    static_qr_enabled?: boolean;
  };
  createdAt: string;
  orderType: string;
  orderPartner: string;
}

const MerchantInfo = ({
  merchant_details,
  createdAt,
  orderType,
  orderPartner,
}: IMerchantDetailsProp) => {
  const user: AuthenticatedUser = useUserInfo();
  return (
    <>
      {(user?.role === USER_ROLE.SUPER_ADMIN ||
        user?.role === USER_ROLE.ADMIN ||
        user?.role === USER_ROLE.MANAGER) &&
      merchant_details?.platform ? (
        <Stack direction="row" alignItems="center">
          {merchant_details.platform === "manual" ? (
            <Tooltip title={`Platform: Via WhatsApp`}>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h4"
              >
                Merchant Details :{" "}
                <WhatsAppIcon sx={{ ml: 1 }} color="success" fontSize="small" />
              </Typography>
            </Tooltip>
          ) : null}
          {merchant_details.platform === "oms_app" ? (
            <Tooltip title={`Platform: Via App`}>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                variant="h4"
              >
                Merchant Details :{" "}
                <PhoneAndroidIcon
                  sx={{ ml: 1 }}
                  color="success"
                  fontSize="small"
                />
              </Typography>
            </Tooltip>
          ) : null}
          {merchant_details?.static_qr_enabled ? (
            <Tooltip title="MedPay QR Pharmacy">
              <QrCode2Icon sx={{ mx: 0.5 }} fontSize="small" color="primary" />
            </Tooltip>
          ) : null}
        </Stack>
      ) : (
        <Typography variant="h4">Merchant Details</Typography>
      )}
      <Typography>
        Accepted By:{" "}
        {orderPartner === "dunzo"
          ? merchant_details?.association_display_name
          : merchant_details?.name}
      </Typography>
      {user?.role === USER_ROLE.SUPER_ADMIN ||
      user?.role === USER_ROLE.ADMIN ||
      user?.role === USER_ROLE.MANAGER ? (
        <Typography>Mobile: {merchant_details?.mobile}</Typography>
      ) : null}
      {orderPartner === "dunzo" ||
      (orderType === ORDER_TYPE.MEDICINES &&
        user?.role === USER_ROLE.PARTNER) ? null : (
        <Typography>Address: {merchant_details?.formatted_address}</Typography>
      )}
      {orderType === ORDER_TYPE.CONSULTATION ? (
        <Typography>
          Accepted at:{" "}
          {parseAndFormateDate(merchant_details?.order_assigned_at)}
        </Typography>
      ) : (
        <Typography>Accepted at: {parseAndFormateDate(createdAt)}</Typography>
      )}
    </>
  );
};

export default MerchantInfo;
