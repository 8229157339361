import { Chip } from "@mui/material";
import React from "react";
import { getIssueStatusBgColor } from "../../utils/common";

const IssueStatus = ({ value }: { value: string }) => {
  return (
    <Chip
      size="small"
      label={value?.split("_")?.join(" ")}
      sx={{
        backgroundColor: getIssueStatusBgColor(value),
        padding: { md: "6px" },
        color: ["open", "reopened"].includes(value) ? "#fff" : "#000",
        textTransform: "capitalize",
      }}
    />
  );
};

export default IssueStatus;
