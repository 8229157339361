import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { instance } from "../utils/api";
import { setUser, UpdateNotifications } from "../store/actions/useActions";
import { AuthenticatedUser } from "../interfaces/user.model";
import useUserInfo from "./useUserInfo";
import { API_RESULT, API_VERSION } from "../enums/common.enum";
import Cookies from "js-cookie";

const useLogout = () => {
  const user: AuthenticatedUser = useUserInfo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = async () => {
    try {
      const res = await instance.post(`${API_VERSION.V2}/auth/user/logout`, {
        login_id: user?.login_id,
      });
      if (res.data.status === API_RESULT.SUCCESS) {
        dispatch(setUser({}));
        dispatch(UpdateNotifications([]));
        localStorage.clear();
        Cookies.remove("user", { path: "/" });
        Cookies.remove("openIndex", { path: "/" });
        navigate("/", { replace: true });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return logout;
};

export default useLogout;
