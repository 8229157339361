import React from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  BarElement,
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SideStackedBarGraph = ({ title, data, dataLabelPos }: any) => {
  const graphOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
        padding: {
          bottom: 30,
        },
      },
      datalabels: {
        anchor: dataLabelPos,
        align: dataLabelPos,
        color: dataLabelPos === "end" ? "#000" : "#fff",
        font: {
          size: dataLabelPos === "end" ? 9 : 12,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            if (
              context.dataset.data !== null &&
              context.dataset.data.length !== 0
            ) {
              if (title.includes("%") || title.includes("h.m")) {
                label +=
                  ", Average:" +
                  Number(
                    context.dataset.data.reduce(
                      (acc: any, curr: any) => Number(acc) + Number(curr),
                      0
                    ) / context.dataset.data.length
                  ).toFixed(2);
              } else {
                label +=
                  ", Total:" +
                  context.dataset.data.reduce(
                    (acc: any, curr: any) => Number(acc) + Number(curr),
                    0
                  );
              }
            }
            return label;
          },
        },
      },
    },
  };

  return <Bar options={graphOptions} data={data} />;
};

export default SideStackedBarGraph;
