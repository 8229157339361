import React from "react";
import { Outlet } from "react-router-dom";
//import { Offline, Online } from "react-detect-offline";
//import NoInternet from "./Helpers/noInternet";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import PoppinsTTF from "../assets/fonts/PoppinsRegular.ttf";
import { Button } from "@mui/material";

const Layout = () => {
  const pxtorem = (value: number) => {
    return `${Number(value) / 16}rem`;
  };
  let theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: "#0058ff",
            light: "#0058ff",
            dark: "#0058ff",
            contrastText: "#fff",
          },
          // text: {
          //   primary: "#000",
          // },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 1024,
            xl: 1200,
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              "@font-face": {
                fontFamily: "Poppins",
                src: `url(${PoppinsTTF}) format("truetype")`,
              },
            },
          },
        },
      }),
    []
  );

  theme.typography.body1 = {
    fontSize: pxtorem(12),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(14),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(16),
    },
  };

  theme.typography.body2 = {
    fontSize: pxtorem(10),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(12),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(14),
    },
  };

  theme.typography.h1 = {
    fontSize: pxtorem(32),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(36),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(42),
    },
  };

  theme.typography.h2 = {
    fontSize: pxtorem(26),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(30),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(32),
    },
  };

  theme.typography.h3 = {
    fontSize: pxtorem(18),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(21),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(24),
    },
  };

  theme.typography.h4 = {
    fontSize: pxtorem(16),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(18),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(20),
    },
  };

  theme.typography.h5 = {
    fontSize: pxtorem(14),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(16),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(18),
    },
  };

  theme.typography.h6 = {
    fontSize: pxtorem(12),
    fontFamily: "Poppins",
    [theme.breakpoints.up("sm")]: {
      fontSize: pxtorem(14),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: pxtorem(16),
    },
  };

  return (
    <main className="App">
      {/* <Online> */}
      <ThemeProvider theme={theme}>
        <Button
          sx={{ display: "none" }}
          id="notification-play"
          onClick={() => {
            const music = new Audio(
              "https://agreement-api-static-files.s3.ap-south-1.amazonaws.com/notification.mp3"
            );
            music.play();
          }}
        ></Button>
        <CssBaseline />
        <Outlet />
      </ThemeProvider>
      {/* </Online> */}
      {/* <Offline>
        <NoInternet />
      </Offline> */}
    </main>
  );
};

export default Layout;
