import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  Divider,
  TextField,
  Box,
} from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { CONSULTATION_TYPE, ORDER_TYPE } from "../../../../enums/order.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { createConsultationInvoiceSchema } from "../../../../schemas/consultation";
import { createDiagnosticsInvoiceSchema } from "../../../../schemas/diagnostic";
import { createPharmacyInvoiceSchema } from "../../../../schemas/pharmacy";
import { setAllOrderData } from "../../../../store/actions/useActions";
import {
  errorToast,
  handleError,
  isArrayEqual,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import OrderPlatform from "../../../Common/orderPlatform";
import OrderCustomerInfo from "../Common/orderCustomerInfo";
import ConsultationInvoice from "./InvoiceForms/consultationInvoice";
import DiagnosticInvoice from "./InvoiceForms/diagnosticInvoice";
import PharmacyInvoice from "./InvoiceForms/pharmacyInvoice";

const CreateInvoiceModal = ({
  createInvoiceModal,
  setCreateInvoiceModal,
  activePartnerOrderId,
  activePartner,
}: any) => {
  const [loadingUnassign, setLoadingUnassign] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [changeType, setChangeType] = useState("");
  const [medTableError, setMedTableError] = useState<any>([]);
  const [testsTableError, setTestsTableError] = useState<any>([]);

  const getOrderByPartnerOrderId = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      orderData?.order_type !== ORDER_TYPE.CONSULTATION &&
      orderData?.order_details?.items?.length > 0
    ) {
      setSelectedItems([...orderData?.order_details?.items]);
    }
  }, [orderData]);

  useEffect(() => {
    if (activePartner && activePartnerOrderId) {
      getOrderByPartnerOrderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnaceeptingOrder = async (orderId: string) => {
    let payload = {
      order_id: orderId,
      type: "accepted-rollback",
    };

    setLoadingUnassign(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order status changed to assigned.");
        setCreateInvoiceModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingUnassign(false);
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    if (orderData?.order_type === ORDER_TYPE.CONSULTATION) {
      if (!values?.pan_no || values?.pan_no?.trim() === "") {
        if (!window.confirm("PAN No is required. Do you want to ignore it?")) {
          return;
        }
      }

      let payload: any = {
        order_id: orderData?.order_id,
        invoice_no: "",
        invoice_url: "",
        discount_percentage: values?.discount?.toString(),
        provider_payable: values.final_payable,
        consultation_type: orderData?.consultation_type,
        merchant_details: {
          ...orderData?.merchant_details,
          order_availability: "complete",
          gst_no: "",
          pan_no: values.pan_no ? values.pan_no : "",
        },
        order_details: {
          items: [
            {
              item: "Consultation",
              details: orderData.consultation_type,
              MRP: values?.doctor_charges?.toString(),
            },
          ],
          doctor: orderData?.merchant_details?.profile_details?.full_name,
        },
      };

      if (orderData?.consultation_type === CONSULTATION_TYPE.ONLINE) {
        payload = {
          ...payload,
          partner_consultation_charges: values?.partner_charges,
        };
      }

      generateInvoice(payload);
    }

    if (orderData?.order_type === ORDER_TYPE.DIAGNOSTICS) {
      let formErrorTests = false;
      //tests table error alert
      testsTableError.forEach((x: any) => {
        if (x.MRP) {
          formErrorTests = true;
        }
      });

      if (formErrorTests) {
        alert("Please correct all errors to proceed");
        return;
      }

      if (!values.gst_no || values?.gst_no?.trim() === "") {
        if (!window.confirm("GST No is required. Do you want to ignore it?")) {
          return;
        }
      }

      let payload = {
        order_id: orderData?.order_id,
        invoice_no: "",
        invoice_url: "",
        discount_percentage: values.discount?.toString(),
        merchant_details: {
          ...orderData?.merchant_details,
          provider_id: orderData?.merchant_details?.provider_id,
          location_id: orderData?.merchant_details?.location_id,
          merchant_type: orderData?.merchant_details?.merchant_type,
          name: orderData?.merchant_details?.name,
          formatted_address: orderData?.merchant_details?.formatted_address,
          mobile: orderData?.merchant_details?.mobile,
          gst_no: values.gst_no ? values.gst_no : "",
          pan_no: "",
          order_availability: "complete",
        },
        order_details: {
          items: selectedItems,
          doctor: values.doctor_name,
        },
      };

      generateInvoice(payload);
    }

    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      if (!values?.gst_no || values?.gst_no?.trim() === "") {
        if (!window.confirm("GST No is required. Do you want to ignore it?")) {
          return;
        }
      }

      let formError = false;
      //meds table error alert
      medTableError.forEach((x: any) => {
        if (x.mrp || x.pack_quantity || x.quantity) {
          formError = true;
        }
      });

      if (formError) {
        alert("Please correct all errors to proceed");
        return;
      }

      let payload: any = {
        order_id: orderData?.order_id,
        invoice_no: "",
        invoice_url: "",
        discount_percentage: values.discount_percentage?.toString(),
        provider_delivery_charges:
          values.delivery_partner === "provider_delivery"
            ? values.delivery_charges
            : "",
        merchant_details: {
          ...orderData?.merchant_details,
          mobile: orderData?.merchant_details?.mobile,
          gst_no: values.gst_no ? values?.gst_no : "",
          pan_no: "",
          order_availability: "complete",
          delivery_partner: values.delivery_partner,
        },
        order_details: {
          items: selectedItems,
          doctor: values.doctor_name,
        },
      };

      if (changeType === "") {
        generateInvoice(payload);
      } else {
        submitForConfirmation(payload);
      }
    }
  };

  const submitForConfirmation = async (payload: any) => {
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        generateInvoice(payload);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const generateInvoice = async (payload: any) => {
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/invoices/create-invoice`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Invoice creation successful");
        setCreateInvoiceModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const FormObserver: React.FC = () => {
    const { values }: any = useFormikContext();
    useEffect(() => {
      //console.log("FormValues", values);
      if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
        setChangeType("");
        let org = cloneDeep(orderData?.order_details?.items);
        let sel = cloneDeep(selectedItems);

        org = org.map((x: any) => {
          delete x.amount;
          delete x.med_from_db;
          delete x.alt_from_pharmacy;
          delete x.requested_item;
          delete x.change_type;
          return x;
        });

        sel = sel.map((x: any) => {
          delete x.amount;
          delete x.med_from_db;
          delete x.alt_from_pharmacy;
          delete x.requested_item;
          delete x.change_type;
          return x;
        });

        if (!isArrayEqual(org, sel)) {
          setChangeType("medicine_changed");
        }
      }
    }, [values]);
    return null;
  };

  return (
    <Dialog
      open={createInvoiceModal}
      onClose={() => setCreateInvoiceModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setCreateInvoiceModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Typography variant="h3">Create Invoice</Typography>
            <LoadingButton
              color="primary"
              variant="contained"
              size="small"
              loading={loadingUnassign}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={() => handleUnaceeptingOrder(orderData?.order_id)}
              sx={{ ml: 1 }}
            >
              Send back to assign
            </LoadingButton>
          </Stack>
          {orderData && orderData.platform && (
            <OrderPlatform platform={orderData.platform} />
          )}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <OrderCustomerInfo
              orderData={orderData}
              setOrderData={setOrderData}
              setParentModal={null}
              disableEdit={true}
            />
            <Divider sx={{ my: 1 }} />
            <Stack mt={2}>
              <Typography variant="h4">Merchant Details:</Typography>
              <Stack mt={2} direction="row">
                <TextField
                  fullWidth
                  id="name"
                  label="Name"
                  size="small"
                  value={orderData?.merchant_details?.name}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="mobile_number"
                  label="Mobile"
                  size="small"
                  value={orderData?.merchant_details?.mobile}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="address"
                  label="Address"
                  size="small"
                  value={orderData?.merchant_details?.formatted_address}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
              </Stack>
            </Stack>
            <Formik
              initialValues={{
                doctor_charges:
                  orderData?.merchant_details?.profile_details
                    ?.consultation_fees,
                partner_charges: orderData?.partner_consultation_charges,
                discount: orderData?.invoice_details?.discount_pct || 0,
                final_payable: "",
                pan_no: orderData?.merchant_details?.pan_no,
                consultationType: orderData?.consultation_type,
                doctor_name: orderData?.order_details?.doctor,
                gst_no: orderData?.merchant_details?.gst_no,
                delivery_partner: orderData?.delivery_partner,
                partner_delivery_charges: orderData?.partner_delivery_charges,
              }}
              onSubmit={(values: any, actions: any) => {
                actions.setSubmitting(false);
                handleSubmit(values, actions);
              }}
              validationSchema={
                orderData?.order_type === ORDER_TYPE.CONSULTATION
                  ? createConsultationInvoiceSchema
                  : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                  ? createDiagnosticsInvoiceSchema
                  : createPharmacyInvoiceSchema
              }
            >
              {(props: any) => {
                const {
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  isSubmitting,
                } = props;
                return (
                  <Form>
                    <FormObserver />
                    {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                      <ConsultationInvoice
                        formData={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS &&
                      selectedItems && (
                        <DiagnosticInvoice
                          selectedTests={selectedItems}
                          setSelectedTests={setSelectedItems}
                          formData={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          testsTableError={testsTableError}
                          setTestsTableError={setTestsTableError}
                        />
                      )}
                    {orderData?.order_type === ORDER_TYPE.MEDICINES &&
                      selectedItems && (
                        <PharmacyInvoice
                          selectedMeds={selectedItems}
                          setSelectedMeds={setSelectedItems}
                          formData={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          orderData={orderData}
                          medTableError={medTableError}
                          setMedTableError={setMedTableError}
                        />
                      )}
                    <Box sx={{ mt: 3, textAlign: "center" }}>
                      <LoadingButton
                        size="medium"
                        color="primary"
                        loading={loading}
                        loadingIndicator={
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        }
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {changeType !== ""
                          ? "Change order and generate invoice"
                          : "Generate Invoice"}
                      </LoadingButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateInvoiceModal;
