import React, { useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  validateIntNum,
} from "../../../../utils/common";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { upiOptions, valueLimits } from "../../../../constants";
import { LoadingButton } from "@mui/lab";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";

const FindUpiPhone = ({
  upiSearchData,
  upiPhone,
  upiPlatform,
  setUpiPhone,
  setUpiPlatform,
  setUpiSearchData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [upiResultMessage, setUpiResultMessgae] = useState("");
  const privateInstance = useAxiosInstance();

  const handleCheckUpi = async () => {
    const payload = {
      payment_partner: upiPlatform,
      payment_phone_number: upiPhone,
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/find-upi-id`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        // console.log("resss", result.data.response);
        setUpiSearchData(result.data.response);
        setUpiResultMessgae("Successfully retrieved UPI");
      } else {
        setUpiResultMessgae("Unable to retrieve UPI");
        errorToast(result.data.message);
        setUpiSearchData({
          ...upiSearchData,
          upi_id: "",
          account_holder_name: "",
        });
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <TextField
          fullWidth
          id="upi_phone"
          size="small"
          value={upiPhone}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          variant="outlined"
          onChange={(e) =>
            setUpiPhone(validateIntNum(e.target.value, valueLimits.MOBILE))
          }
          label="UPI Phone Number"
        />
        <FormControl
          fullWidth
          size="small"
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        >
          <InputLabel id="delivery-partner-label">Select Option</InputLabel>
          <Select
            labelId="upi-option-label"
            name="upi_option"
            id="upi_option"
            label="Select option"
            value={upiPlatform}
            onChange={(e: SelectChangeEvent) => {
              setUpiPlatform(e.target.value);
            }}
          >
            {Object.entries(upiOptions).map(([key, value]) => {
              return (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          onClick={() => handleCheckUpi()}
          disabled={!upiPhone || !upiPlatform}
        >
          Check
        </LoadingButton>
      </Stack>
      <Typography variant="h6" sx={{ mt: 1.5 }}>
        {upiResultMessage}
      </Typography>
    </Stack>
  );
};

export default FindUpiPhone;
