import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import { handleError, parseAndFormateDate } from "../../utils/common";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import { parse } from "date-fns";

const ReportsTable = ({ reportType }: any) => {
  const [auditFetchType, setAuditFetchType] = useState("billing_report");
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  function sortFunc(dateA: any, dateB: any) {
    dateA = parse(
      dateA.created_at?.split(".")[0],
      "dd/MM/yyyy HH:mm:ss",
      new Date()
    );
    dateB = parse(
      dateB.created_at?.split(".")[0],
      "dd/MM/yyyy HH:mm:ss",
      new Date()
    );
    return dateB - dateA;
  }
  useEffect(() => {
    const getReports = async () => {
      setLoading(true);
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/reports/history?user_id=${
            user?.user_id
          }&report_type=${
            reportType === "audit_report" ? auditFetchType : reportType
          }`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setData(result.data.response?.sort(sortFunc));
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, auditFetchType]);

  const columns = [
    {
      name: "file_name",
      label: "File Name",
      options: {
        customBodyRender: (value: string) => {
          return (
            <Tooltip title={value}>
              <Typography variant="body2">
                {value?.length > 80 ? value?.substr(0, 80) + "..." : value}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "created_by",
      label: "Created By",
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        searchable: false,
        customBodyRender: (value: string) => {
          return (
            <Typography variant="body2">
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "file_link",
      label: "ACTION",
      options: {
        searchable: false,
        customBodyRender: (value: string) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.open(value)}
            >
              <CloudDownloadIcon sx={{ mr: 1 }} />
              <Typography variant="body2">Download</Typography>
            </Button>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    selectableRows: "none",
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    search: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Filename/Created By",
    rowsPerPageOptions: [10, 25, 50],
    responsive: "simple",
  };

  return (
    <ErrorBoundary>
      {reportType === "audit_report" && (
        <FormControl sx={{ mb: 1.5 }}>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Report Type:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => setAuditFetchType(e.target.value)}
          >
            <FormControlLabel
              value="billing_report"
              control={<Radio checked={auditFetchType === "billing_report"} />}
              label="Billing"
            />
            <FormControlLabel
              value="reco_report"
              control={<Radio />}
              label="Reconciliation"
            />
          </RadioGroup>
        </FormControl>
      )}

      <MUIDataTable
        title="History"
        data={data}
        columns={columns}
        options={options}
      />
    </ErrorBoundary>
  );
};

export default ReportsTable;
