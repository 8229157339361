import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PartnersAutocomplete from "../../../components/Common/partnersAC";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  shouldShowOrderData,
} from "../../../utils/common";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import CancelOrderModal from "../../../components/Common/cancelOrderModal";
import CombinedInfo from "../../../components/OrderDetails/combinedInfo";
import OrderData from "../../../components/OrderDetails/orderData";
import { ORDER_STATUS, ORDER_TYPE } from "../../../enums/order.enum";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../../enums/common.enum";
import PharmacyBills from "../../../components/OrderDetails/pharmacyBills";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import RollbackModalView from "./rollbackModalView";
import OrderSpeedDialActions from "./orderSpeedDialActions";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import useUserInfo from "../../../hooks/useUserInfo";
import OrderAudits from "./orderAudits";
import RefundStatus from "./refundStatus";

const Order = () => {
  const [providerId, setProviderId] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [getDataFromParams, setGetDataFromParams] = useState(false);
  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [orderData, setOrderData] = useState<any>([]);
  const [rollbackModal, setRollbackModal] = useState<boolean>(false);
  const user: AuthenticatedUser = useUserInfo();

  const getOrderByPartnerOrderId = async () => {
    setOrderData([]); //reset order data for sequential search
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          providerId
        )?.toLowerCase()}/${orderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        //console.log(result.data.response);
        setOrderData(result.data.response);
        navigate(`/settings/admin-panel/${providerId}/${orderId}`, {
          replace: true,
        });
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const params = useParams();

  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      setProviderId(String(params.provider));
      setOrderId(String(params.providerOrderId));
      setGetDataFromParams(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setOrderData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getDataFromParams) {
      getOrderByPartnerOrderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataFromParams]);

  return (
    <>
      <Box
        display="flex"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PartnersAutocomplete
          providerId={providerId}
          setProviderId={setProviderId}
          validate="false"
          disableField={false}
        />
        <TextField
          fullWidth
          id="userId"
          label="Partner Order ID"
          size="small"
          value={orderId}
          onChange={(e) => setOrderId(e.target.value)}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        />
        <LoadingButton
          type="button"
          disabled={!providerId || !orderId}
          variant="contained"
          color="primary"
          loading={loading}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          sx={{ minWidth: "180px", mt: 1.5 }}
          onClick={getOrderByPartnerOrderId}
        >
          Get Order
        </LoadingButton>
      </Box>
      {orderData && Object.keys(orderData).length > 0 && (
        <ErrorBoundary>
          <Card
            sx={{
              width: "100%",
              boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
              my: 1,
              padding: { xs: "15px" },
            }}
          >
            <CombinedInfo
              orderData={orderData}
              setOrderData={setOrderData}
              setOrderDataModal={null}
              hideStatus={false}
              hideFirstRow={false}
            />
          </Card>
          {["medpay", "medpay_store"].includes(orderData.order_partner) &&
          ["cancelled", "package_delivered"].includes(orderData.order_status) &&
          (orderData?.patient_payable_details?.advance_payment
            ?.payment_status === "paid" ||
            orderData?.patient_payable_details?.payment_status === "paid") ? (
            <Card
              sx={{
                boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                mb: 1,
                mt: 2,
                padding: { xs: "15px" },
              }}
            >
              <RefundStatus
                orderId={orderData.order_id}
                patientPayableDetails={
                  orderData.patient_payable_details || null
                }
                updateDataFn={getOrderByPartnerOrderId}
              />
            </Card>
          ) : null}
          <Accordion
            sx={{ mt: 2, boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h4">Audits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OrderAudits
                oldAuditLogs={orderData?.audit}
                orderId={orderData?.order_id}
              />
            </AccordionDetails>
          </Accordion>
          {shouldShowOrderData(orderData) && (
            <Card
              sx={{
                width: "100%",
                boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                my: 2,
                padding: { xs: "15px" },
              }}
            >
              <OrderData
                orderData={orderData}
                showHeading={true}
                notAdminPanel={false}
              />
            </Card>
          )}
          <Box display="flex" justifyContent="center" alignItems="center">
            {orderData?.order_type === ORDER_TYPE.MEDICINES && (
              <PharmacyBills orderData={orderData} />
            )}
            {orderData.invoice_details?.invoice_link && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    window.open(orderData.invoice_details?.invoice_link)
                  }
                >
                  View Invoice
                </Button>
                {[
                  String(ORDER_STATUS.INVOICE_GENERATED),
                  String(ORDER_STATUS.PAYMENT_COLLECTED),
                  String(ORDER_STATUS.PACKAGE_PICKED),
                  String(ORDER_STATUS.PACKAGE_DELIVERED),
                ].includes(orderData.order_status) ? (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => setRollbackModal(true)}
                    sx={{ ml: 1 }}
                  >
                    Rollback
                  </Button>
                ) : null}
              </>
            )}
            {orderData?.order_status !== ORDER_STATUS.CANCELLED && (
              <>
                {user?.role === USER_ROLE.MANAGER &&
                orderData.order_status ===
                  ORDER_STATUS.PAYMENT_COLLECTED ? null : (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setCancelModal(true)}
                    sx={{ ml: 1 }}
                  >
                    Cancel
                  </Button>
                )}
              </>
            )}
          </Box>
        </ErrorBoundary>
      )}
      {cancelModal && (
        <CancelOrderModal
          cancelModal={cancelModal}
          setCancelModal={setCancelModal}
          orderId={orderData?.order_id}
          orderType={orderData?.order_type}
          updateDataFn={getOrderByPartnerOrderId}
        />
      )}
      {rollbackModal && (
        <RollbackModalView
          open={rollbackModal}
          onClose={setRollbackModal}
          orderId={orderData?.order_id}
          updateDataFn={getOrderByPartnerOrderId}
          orderStatus={orderData?.order_status}
        />
      )}
      {Object.keys(orderData)?.length > 0 ? (
        <OrderSpeedDialActions
          orderData={orderData}
          setOrderData={setOrderData}
        />
      ) : null}
    </>
  );
};

export default Order;
