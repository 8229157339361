import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { ORDER_TYPE } from "../../../../../enums/order.enum";
import { API_RESULT, API_VERSION } from "../../../../../enums/common.enum";
import KnowlarityCall from "../../../../Common/knowlarityCall";
import MobileUpdateModal from "./mobileUpdateModal";
import GstValidateModal from "./gstValidateModal";
import { LoadingButton } from "@mui/lab";
import { useIconStyles } from "../../../../Helpers/iconStyles";
import PublicIcon from "@mui/icons-material/Public";
import ShieldIcon from "@mui/icons-material/Shield";
import ClosedIcon from "@mui/icons-material/NotInterested";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { parseAndFormateDate } from "../../../../../utils/common";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const NearbyProviderRow = ({
  orderData,
  index,
  item,
  setActiveProviderIndex,
  nearbyProviders,
  setNearbyProviders,
  selectedProviders,
  setSelectedProviders,
  activeProviderIndex,
  assignLoading,
}: any) => {
  const privateInstance = useAxiosInstance();
  const [mobileModal, setMobileModal] = useState(false);
  const [gstModal, setGstModal] = useState(false);
  const iconClasses = useIconStyles();

  const getMobileNumber = async (placeId: string) => {
    const result = await privateInstance.get(
      `${API_VERSION.V1}/orders/nearby/place?place_id=${placeId}`
    );
    if (result?.data?.status === API_RESULT.SUCCESS) {
      nearbyProviders.splice(index, 1, {
        ...nearbyProviders[index],
        mobile: result.data.response,
        from_search: "no",
      });
      const updatedProviders = [...nearbyProviders];
      setNearbyProviders(updatedProviders);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          flexDirection: { xs: "column", md: "row" },
          backgroundColor: item.static_qr_enabled ? "#E6EEFF" : "#fff",
          pt: index === 0 ? 0 : 2,
          pb: 2,
        }}
      >
        {orderData?.order_type === ORDER_TYPE.MEDICINES && (
          <Box width={{ xs: "100%", md: "20%" }}>
            {index === 0 && (
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  mb: 3,
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#fff",
                }}
              >
                Select
              </Typography>
            )}
            <Checkbox
              checked={
                selectedProviders.some((el: any) => el.mobile === item.mobile)
                  ? true
                  : false
              }
              disabled={!item.mobile || !item.gst_no || item.gst_no === "0"}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedProviders([
                    ...selectedProviders,
                    nearbyProviders[index],
                  ]);
                } else {
                  const updatedSelectedProviders = selectedProviders.filter(
                    (provider: any) => provider.mobile !== item.mobile
                  );
                  setSelectedProviders(updatedSelectedProviders);
                }
              }}
            />
          </Box>
        )}
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          {index === 0 && (
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                mb: 3,
                display: { xs: "none", md: "block" },
                backgroundColor: "#fff",
              }}
            >
              {orderData?.order_type === ORDER_TYPE.CONSULTATION
                ? "CLINIC/DOCTOR"
                : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                ? "LAB NAME"
                : "PHARMACY"}
            </Typography>
          )}

          <Stack direction="column" alignItems="flex-start">
            <Stack
              direction="row"
              alignItems="start"
              sx={{ mr: { xs: 0, md: 2 } }}
            >
              {/* pharmacy name flag to show if it is onbaorded */}
              {orderData?.order_type === ORDER_TYPE.MEDICINES && (
                <>
                  {(item?.hasOwnProperty("open_now") && item.open_now) ||
                  !item.hasOwnProperty("open_now") ? (
                    <Tooltip title="Open Now">
                      {item.device_platform &&
                      item.device_platform === "android" ? (
                        <PhoneAndroidIcon
                          fontSize="small"
                          color="success"
                          sx={{ mr: 0.5, mt: 0.25 }}
                        />
                      ) : (
                        <WhatsAppIcon
                          fontSize="small"
                          color="success"
                          sx={{ mr: 0.5, mt: 0.25 }}
                        />
                      )}
                    </Tooltip>
                  ) : (
                    <Tooltip title="Closed">
                      <ClosedIcon
                        fontSize="small"
                        color="error"
                        sx={{ mr: 0.5, mt: 0.25 }}
                      />
                    </Tooltip>
                  )}
                  {item?.static_qr_enabled ? (
                    <Tooltip title="MedPay QR Pharmacy">
                      <QrCode2Icon
                        sx={{ mr: 0.5, mt: 0.25 }}
                        fontSize="small"
                        color="primary"
                      />
                    </Tooltip>
                  ) : null}
                </>
              )}
              {/* consultation flag to show if it is public or private */}
              {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
                <>
                  {item?.onboarded?.toLowerCase() === "yes" ||
                  item?.onboarded?.toLowerCase() === "y" ? (
                    <Tooltip title="Private">
                      <ShieldIcon
                        fontSize="small"
                        color="primary"
                        sx={{ mr: 0.5, mt: 0.25 }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Public">
                      <PublicIcon
                        fontSize="small"
                        color="primary"
                        sx={{ mr: 0.5, mt: 0.25 }}
                      />
                    </Tooltip>
                  )}
                </>
              )}
              <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
                {item.name}
              </Typography>
            </Stack>
            {orderData?.order_type === ORDER_TYPE.MEDICINES ? (
              <>
                {item?.device_platform === "android" &&
                item.last_app_active_at ? (
                  <Typography
                    mt={0.5}
                    variant="body2"
                    sx={{ color: "#00000099" }}
                  >
                    Last Active: {parseAndFormateDate(item.last_app_active_at)}
                  </Typography>
                ) : null}
                {item.matching_skus && item.sku_match_percent > 0 ? (
                  <Tooltip
                    title={`${item.matching_skus?.split("/")[0]} out of ${
                      item.matching_skus?.split("/")[1]
                    } items delivered in the past`}
                  >
                    <Typography
                      mt={0.5}
                      variant="body2"
                      sx={{
                        color:
                          item.sku_match_percent > 0.25
                            ? item.sku_match_percent > 0.6
                              ? "#2e7d32"
                              : "#ed6c02"
                            : "#e74c3c",
                      }}
                    >
                      {item.matching_skus} items available
                    </Typography>
                  </Tooltip>
                ) : null}
              </>
            ) : (
              <Rating
                name="disabled"
                value={Number(item.rating)}
                precision={0.1}
                readOnly
                size="small"
              />
            )}
          </Stack>
        </Box>
        {orderData?.order_type !== ORDER_TYPE.CONSULTATION && (
          <Box sx={{ width: { xs: "100%", md: "35%" } }}>
            {index === 0 && (
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  mb: 3,
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#fff",
                }}
              >
                MOBILE NUMBER
              </Typography>
            )}
            <Stack>
              {orderData?.order_type === ORDER_TYPE.MEDICINES ? (
                item.from_search === "yes" ? (
                  <Button onClick={() => getMobileNumber(item.location_id)}>
                    Click Here
                  </Button>
                ) : (
                  <>
                    {item.mobile_number || item.mobile}
                    <Stack direction="row">
                      <KnowlarityCall
                        order={{
                          order_id: orderData?.order_id,
                          mobile: item.mobile_number
                            ? item.mobile_number
                            : item.mobile,
                        }}
                        showNumber={false}
                      />
                      <Tooltip title="Edit">
                        <IconButton
                          color="warning"
                          onClick={() => {
                            setMobileModal(true);
                            setActiveProviderIndex(index);
                          }}
                        >
                          <EditIcon className={iconClasses.borderedIcon} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </>
                )
              ) : (
                item.mobile_number || item.mobile
              )}
            </Stack>
          </Box>
        )}
        {orderData?.order_type === ORDER_TYPE.MEDICINES && (
          <Box sx={{ width: { xs: "100%", md: "30%" } }}>
            {index === 0 && (
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  mb: 3,
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#fff",
                }}
              >
                Fulfilled
              </Typography>
            )}
            <Typography variant="body1">
              {item.orders_fulfilled || "0"}
            </Typography>
          </Box>
        )}
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          {index === 0 && (
            <Typography
              variant="h5"
              sx={{
                textTransform: "uppercase",
                mb: 3,
                display: { xs: "none", md: "block" },
                backgroundColor: "#fff",
              }}
            >
              Address
            </Typography>
          )}
          <Typography variant="body1">
            {item.address || item.formatted_address}
          </Typography>
          {Number(item.distance) > 0 && (
            <Typography
              variant="body2"
              sx={{ fontStyle: "italic", fontWeight: "bold" }}
            >
              Distance - {item?.distance} km
            </Typography>
          )}
        </Box>
        {orderData?.order_type !== ORDER_TYPE.MEDICINES && (
          <Box
            sx={{
              width: { xs: "100%", md: "10%" },
              textAlign: "center",
            }}
          >
            {index === 0 && (
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  mb: 3,
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#fff",
                }}
              >
                Action
              </Typography>
            )}
            <LoadingButton
              variant="contained"
              color="primary"
              loading={assignLoading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={() => {
                setSelectedProviders([
                  ...selectedProviders,
                  nearbyProviders[index],
                ]);
              }}
              sx={{ ml: 1 }}
            >
              Assign
            </LoadingButton>
          </Box>
        )}
        {orderData?.order_type === ORDER_TYPE.MEDICINES && (
          <Box sx={{ width: { xs: "100%", md: "50%" }, textAlign: "center" }}>
            {index === 0 && (
              <Typography
                variant="h5"
                sx={{
                  textTransform: "uppercase",
                  mb: 3,
                  display: { xs: "none", md: "block" },
                  backgroundColor: "#fff",
                }}
              >
                GST Number
              </Typography>
            )}
            {item.gst_no && item.gst_no !== "0" && (
              <Typography variant="body2">{item.gst_no}</Typography>
            )}
            <Tooltip title="Edit">
              <IconButton
                color="warning"
                onClick={() => {
                  setGstModal(true);
                  setActiveProviderIndex(index);
                }}
              >
                <EditIcon className={iconClasses.borderedIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {mobileModal && (
        <MobileUpdateModal
          mobileModal={mobileModal}
          setMobileModal={setMobileModal}
          nearbyProviders={nearbyProviders}
          setNearbyProviders={setNearbyProviders}
          activeProviderIndex={activeProviderIndex}
        />
      )}
      {gstModal && (
        <GstValidateModal
          gstModal={gstModal}
          setGstModal={setGstModal}
          nearbyProviders={nearbyProviders}
          setNearbyProviders={setNearbyProviders}
          activeProviderIndex={activeProviderIndex}
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
          gstForProviderId={nearbyProviders[activeProviderIndex]?.provider_id}
          orderPartner={orderData?.order_partner}
        />
      )}
    </>
  );
};

export default NearbyProviderRow;
