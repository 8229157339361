import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { getDurationAgo, transformDurationInMin } from "../../utils/common";
import ErrorIcon from "@mui/icons-material/Error";
import { ERROR_LIMIT_MINUES } from "../../enums/common.enum";

const StatusTimer = ({
  timeStamp,
  timerType,
  providerPlatform,
  deliverySlot,
}: any) => {
  const errorColorDuration =
    timerType === "package_picked"
      ? ERROR_LIMIT_MINUES.PACKAGE_PICKED
      : ERROR_LIMIT_MINUES.COMMON;

  const data = getDurationAgo(timeStamp, 0);

  return (
    <Box display="flex" alignItems="center">
      <Typography
        color={transformDurationInMin(data) > errorColorDuration ? "error" : ""}
        sx={{ fontSize: "12px !important" }}
      >
        TAT: {data}
      </Typography>
      {transformDurationInMin(data) > errorColorDuration ? (
        <Tooltip
          title={
            timerType === "package_picked"
              ? "Delivery Slot: " + deliverySlot + ""
              : providerPlatform === "oms_app"
              ? `Reminder sent on Pharmacy App: ${getDurationAgo(
                  timeStamp,
                  ERROR_LIMIT_MINUES.COMMON * 60
                )} ago`
              : "Action Required"
          }
        >
          <ErrorIcon sx={{ ml: 0.25 }} color="error" fontSize="small" />
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default StatusTimer;
