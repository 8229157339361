import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { addDays, differenceInDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../../components/Common/customDialogTitle";
import {
  errorToast,
  handleError,
  successToast,
} from "../../../../utils/common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useUserInfo from "../../../../hooks/useUserInfo";

const EmailReportModal = ({
  date,
  selected,
  showEmailModal,
  setShowEmailModal,
}: any) => {
  const [startDate, setStartDate] = useState<any>(new Date(date));
  const [endDate, setEndDate] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const user: any = useUserInfo();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let payload = {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        version: "v2",
        email: user?.user_id,
        location_id: selected?.location_id,
        job_type: "transactions_report",
      };
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/reports/all`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Email sent successfully!");
        setShowEmailModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEndDate(new Date(date));
  }, [date]);

  return (
    <div>
      <Dialog
        open={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowEmailModal(false)}
        >
          {selected?.provider_name || selected?.beneficiary?.name} | Weekly
          Report
        </CustomDialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(newValue: Date | null) => {
                setStartDate(newValue);
                setEndDate(null);
              }}
              minDate={new Date("March 01, 2024")}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  name="start_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(newValue: Date | null) => {
                setEndDate(newValue);
              }}
              minDate={addDays(startDate, 0)}
              maxDate={
                differenceInDays(new Date(), startDate) < 1
                  ? new Date()
                  : addDays(startDate, 6)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  name="end_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" sx={{ fontSize: "10px !important" }}>
              Reports will be emailed on: {user?.user_id}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "10px !important" }}>
              1 Week
            </Typography>
          </Box>
          <Box textAlign="center" mt={3}>
            <LoadingButton
              color="primary"
              variant="contained"
              loading={loading}
              disabled={!startDate || !endDate}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailReportModal;
