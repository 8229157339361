import {
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  textCapitalize,
} from "../../utils/common";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../enums/common.enum";
import PayoutStatusModal from "./payoutStatusModal";
import { commonTableOptions } from "../../constants";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";

const ShowReqTab = () => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [paymentRequests, setPaymentRequests] = useState<any>([]);
  const user: AuthenticatedUser = useUserInfo();
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [payoutStatusModal, setPayoutStatusModal] = useState(false);
  const [filterType, setFilterType] = useState("user");
  const [filterByUserValue, setFilterByUserValue] = useState(user.user_id);
  const [filterByTypeValue, setFilterByTypeValue] = useState("");

  const getPaymentHistory = async (val: string | undefined) => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/payment-history/${val}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setPaymentRequests(result.data.response);
      } else {
        setPaymentRequests([]);
      }
    } catch (err) {
      setPaymentRequests([]);
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const refreshStatus = async (index: number, payout_id: string) => {
    setRefreshLoader(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/payment-request/status/${payout_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        const item = result.data.response;
        paymentRequests.splice(index, 1, item);
        const updatedData = [...paymentRequests];
        setPaymentRequests(updatedData);
      } else {
        errorToast("Sorry, we could not fetch status");
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentHistory(user.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentReqCols = [
    {
      name: "payout_id",
      label: "PAYOUT ID",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "extra_1",
      label: "ORDER INFO",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "extra_2",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "payment_type",
      label: "PAYMENT INFO",
      options: {
        searchable: false,
      },
    },
    {
      name: "pg_partner",
      label: "",
      options: {
        searchable: false,
        display: false,
      },
    },
    {
      name: "amount",
      label: "AMOUNT (Rs.)",
      options: {
        filter: false,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "bank_reference",
      label: "BANK REF",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "payment_status",
      label: "STATUS",
      options: {
        filter: false,
        filterType: "dropdown" as FilterType,
      },
    },
    {
      name: "error_message",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "created_at",
      label: "PAYOUT DATE",
      options: {
        filter: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    search: false,
    searchAlwaysOpen: false,
    customRowRender: (data: any, dataIndex: any) => {
      return (
        <CustomRowComponent key={data[0]} rowData={data} index={dataIndex} />
      );
    },
  };

  function CustomRowComponent({ rowData, index }: any) {
    const [
      payout_id,
      extra_1,
      extra_2,
      payment_type,
      pg_partner,
      amount,
      bank_reference,
      payment_status,
      error_message,
      payout_date,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell>
          <Stack>
            {payout_date && (
              <>
                {extra_1 && extra_2 ? (
                  <Typography
                    variant="body1"
                    sx={{ color: "#0058ff", cursor: "pointer", mr: 0.25 }}
                    onClick={() => {
                      setActivePartnerOrderId(extra_1);
                      setPayoutStatusModal(true);
                    }}
                  >
                    {payout_id}
                  </Typography>
                ) : (
                  <Typography variant="body1" sx={{ mr: 0.25 }}>
                    {payout_id}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack sx={{ maxWidth: "220px", wordBreak: "break-all" }}>
            {extra_1 && <Typography variant="body2">{extra_1}</Typography>}
            {extra_2 && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  {extra_2}
                </Typography>
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            {payment_type && (
              <Typography variant="body2">{payment_type}</Typography>
            )}
            {pg_partner && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  {pg_partner}
                </Typography>
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          {amount && <Typography variant="body2">{amount}</Typography>}
        </TableCell>
        <TableCell>
          {bank_reference && (
            <Typography variant="body2">
              {textCapitalize(bank_reference)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center">
            {payment_status && (
              <Typography
                variant="body2"
                color={
                  payment_status === "success"
                    ? "green"
                    : payment_status === "failed"
                    ? "red"
                    : "#ed6c02"
                }
              >
                {textCapitalize(payment_status)}
              </Typography>
            )}
            {payment_status === PAYMENT_CREDIT_STATUS.FAILED &&
            error_message ? (
              <Tooltip title={error_message}>
                <InfoIcon sx={{ ml: 0.5 }} fontSize="small" color="error" />
              </Tooltip>
            ) : null}
            {(payment_status === PAYMENT_CREDIT_STATUS.PENDING ||
              payment_status === PAYMENT_CREDIT_STATUS.PROCESSING) && (
              <>
                {refreshLoader ? (
                  <CircularProgress size={16} color="warning" />
                ) : (
                  <Tooltip title="Refresh status">
                    <span>
                      <IconButton
                        onClick={() => refreshStatus(index, payout_id)}
                      >
                        <AutorenewIcon color="warning" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          {payout_date && (
            <Typography variant="body2">
              {parseAndFormateDate(payout_date)}
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <Stack mb={3} direction="row" alignItems="center">
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        >
          <InputLabel id="filter-by-label">Filter By</InputLabel>
          <Select
            labelId="filter-by-label"
            value={filterType}
            label="Filter By"
            onChange={(e: SelectChangeEvent) => {
              setFilterType(e.target.value);
            }}
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="type">Type</MenuItem>
          </Select>
        </FormControl>
        {filterType === "user" ? (
          <TextField
            fullWidth
            label="User ID"
            size="small"
            value={filterByUserValue}
            onChange={(e) => setFilterByUserValue(e.target.value)}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            InputLabelProps={{
              required: true,
            }}
          />
        ) : filterType === "type" ? (
          <FormControl
            size="small"
            fullWidth
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          >
            <InputLabel id="filter-type-label">Filter Type</InputLabel>
            <Select
              labelId="filter-type-label"
              value={filterByTypeValue}
              label="Filter Type"
              onChange={(e: SelectChangeEvent) => {
                setFilterByTypeValue(e.target.value);
              }}
            >
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="direct">Direct</MenuItem>
              <MenuItem value="orders-service">Orders Service</MenuItem>
              <MenuItem value="opd-service">OPD Service</MenuItem>
            </Select>
          </FormControl>
        ) : null}
        <LoadingButton
          sx={{ width: "180px", mt: 1.5 }}
          size="small"
          color="primary"
          loading={loading}
          loadingIndicator={
            <CircularProgress size={20} sx={{ color: "#fff" }} />
          }
          variant="contained"
          type="button"
          onClick={() =>
            getPaymentHistory(
              filterType === "user" ? filterByUserValue : filterByTypeValue
            )
          }
          disabled={
            filterType === "user" && !filterByUserValue
              ? true
              : filterType === "type" && !filterByTypeValue
              ? true
              : false
          }
        >
          Filter
        </LoadingButton>
      </Stack>
      <MUIDataTable
        title=""
        data={paymentRequests}
        columns={paymentReqCols}
        options={options}
      />
      {payoutStatusModal && (
        <PayoutStatusModal
          payoutStatusModal={payoutStatusModal}
          setPayoutStatusModal={setPayoutStatusModal}
          data={paymentRequests.find(
            (x: any) => x.extra_1 === activePartnerOrderId
          )}
        />
      )}
    </ErrorBoundary>
  );
};

export default ShowReqTab;
