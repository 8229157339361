import {
  Box,
  CircularProgress,
  IconButton,
  ImageListItem,
} from "@mui/material";
import React, { useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../utils/common";
import OcrIcon from "../../assets/images/ocr.svg";

const IssueAttachmentsView = ({
  item,
  issueType,
  setUtrTxnData,
  setUtrTxnModal,
}: any) => {
  const [fetchingUtr, setFetchingUtr] = useState(false);
  const privateInstance = useAxiosInstance();

  const getUtrAndTxn = async (imgUrl: string) => {
    const payload = {
      type: "image_to_utr",
      image_url: imgUrl,
    };

    try {
      setFetchingUtr(true);
      const result = await privateInstance.post(
        `${API_VERSION.V3}/openai`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setUtrTxnData(result.data.response);
        setUtrTxnModal(true);
      } else {
        errorToast(result?.data?.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setFetchingUtr(false);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <ImageListItem>
        <img
          src={`${item}?w=140&h=140&fit=crop&auto=format`}
          srcSet={`${item}?w=140&h=140&fit=crop&auto=format&dpr=2 2x`}
          alt="img"
          loading="lazy"
          style={{
            maxHeight: 180,
            borderRadius: 10,
            cursor: "pointer",
          }}
          onClick={() => window.open(item, "_blank")}
        />
      </ImageListItem>
      {issueType === "Payout" ? (
        <>
          {fetchingUtr ? (
            <CircularProgress
              size={28}
              sx={{
                position: "absolute",
                top: "calc(50% - 12px)",
                left: "calc(50% - 12px)",
              }}
            />
          ) : null}
          <IconButton
            disabled={fetchingUtr}
            onClick={(e) => {
              e.stopPropagation();
              getUtrAndTxn(item);
            }}
            size="large"
            sx={{
              position: "absolute",
              bottom: "6px",
              right: "6px",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                boxShadow: "0 0 12px #0058ff",
              },
            }}
          >
            <img
              src={OcrIcon}
              alt="ocr icon"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
        </>
      ) : null}
    </Box>
  );
};

export default IssueAttachmentsView;
