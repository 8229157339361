import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
  Tooltip,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useMemo, useState } from "react";
import { differenceInDays } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { handleError } from "../../utils/common";
import { commonTableOptions } from "../../constants";
import useUserPermissions from "../../hooks/useUserPermissions";

const Merchants = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const userPermissions = useUserPermissions();
  const moduleScope = useMemo(
    () =>
      userPermissions?.find((item: any) => item.permission_id === "merchants")
        ?.scope,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const getData = async () => {
      let apiUrl = "analytics/digital-payments/providers";

      setLoading(true);
      try {
        const result = await privateInstance.get(`${API_VERSION.V2}/${apiUrl}`);
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setData(result?.data?.response?.qr_providers);
          setFilterBy("all");
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterBy === "blacklisted") {
      setFilteredData(data.filter((x: any) => x.qr_status !== "active"));
    } else if (filterBy === "old") {
      setFilteredData(
        data.filter(
          (x: any) =>
            differenceInDays(new Date(), new Date(String(x.created_at))) > 30
        )
      );
    } else if (filterBy === "new") {
      setFilteredData(
        data.filter(
          (x: any) =>
            differenceInDays(new Date(), new Date(String(x.created_at))) < 14
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [filterBy, data]);

  const cols = [
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filter: false,
      },
    },
    {
      name: "pharmacy_name",
      label: "Pharmacy Name",
      options: {
        filter: false,
      },
    },
    {
      name: "terminal_phone_no",
      label: "Phone No",
      options: {
        filter: false,
      },
    },
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "qr_partner",
      label: "QR Partner",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "qr_status",
      label: "QR Status",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value === "pending" ? (
                <Chip
                  size="small"
                  label="PENDING"
                  sx={{ backgroundColor: "#FBD38D", padding: "6px" }}
                />
              ) : value === "active" ? (
                <Chip
                  size="small"
                  label="ACTIVE"
                  sx={{ backgroundColor: "#C6F6D5", padding: "6px" }}
                />
              ) : (
                <Chip
                  size="small"
                  label="INACTIVE"
                  sx={{
                    backgroundColor: "#C53030",
                    color: "#FFF",
                    padding: "6px",
                  }}
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: "terminal_id",
      label: "TERMINAL ID",
      options: {
        filter: false,
      },
    },
    {
      name: "soundbox_provider",
      label: "Soundbox",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        download: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Button
              onClick={() =>
                window.open(
                  `/merchants/${data[tableMeta?.rowIndex]?.location_id}`,
                  "_blank"
                )
              }
              variant="contained"
              color="primary"
              size="small"
            >
              Manage
            </Button>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Location ID/Terminal ID/Name/Phone No",
    download: moduleScope.includes("edit"),
    downloadOptions: {
      filename: "Merchants_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <Box>
      <Stack mb={1.5} direction="row" justifyContent="end">
        <Stack direction="row" spacing={1.5}>
          {[
            { key: "new", label: "New Pharmacy", title: "In Last 2 Weeks" },
            { key: "old", label: "Old Pharmacy", title: "1 Month Old" },
            { key: "blacklisted", label: "Blacklisted", title: "" },
          ].map(
            (
              item: { key: string; label: string; title: string },
              index: number
            ) => {
              return (
                <Tooltip key={index} title={item.title}>
                  <Chip
                    label={item.label}
                    onClick={() => setFilterBy(item.key)}
                    onDelete={() => setFilterBy("all")}
                    deleteIcon={
                      filterBy === item.key ? (
                        <CancelIcon fontSize="small" />
                      ) : (
                        <p></p>
                      )
                    }
                    variant={filterBy === item.key ? "filled" : "outlined"}
                    disabled={data?.length === 0}
                    color="primary"
                  />
                </Tooltip>
              );
            }
          )}
        </Stack>
      </Stack>
      <MUIDataTable
        title=""
        data={filteredData}
        columns={cols}
        options={options}
      />
    </Box>
  );
};

export default Merchants;
