import { Chip, Stack } from "@mui/material";
import React from "react";
import { ORDER_PLATFORM } from "../../enums/order.enum";

const OrderPlatform = ({ platform }: string | any) => {
  return (
    <Stack sx={{ mr: 5 }}>
      {platform === ORDER_PLATFORM.PARTNER_API ? (
        <Chip label="APP GENERATED" sx={{ backgroundColor: "#c6f6d5" }} />
      ) : (
        <Chip label="DASHBOARD GENERATED" sx={{ backgroundColor: "#FBD38D" }} />
      )}
    </Stack>
  );
};

export default OrderPlatform;
