import {
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import AddAndAlterItems from "./addAndAlterItems";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { UpdateCart } from "../../store/actions/useActions";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SuccessModal from "./successModal";
import { errorToast, handleError } from "../../utils/common";
import { API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import HelpModal from "./helpModal";

const OFFER_DATA = [
  { start: 1000, end: 3000, discount: 5 },
  { start: 3001, end: 5000, discount: 6 },
  { start: 5001, end: 10000, discount: 7 },
  { start: 10001, end: 15000, discount: 8 },
  { start: 15001, end: 20000, discount: 9 },
  { start: 20001, end: 100000, discount: 10 },
];

const getCurrIndex = (totalAmt: number) => {
  return OFFER_DATA.findIndex((x) => totalAmt >= x.start && totalAmt <= x.end);
};

const getOfferMsg = (cartD: any) => {
  const totalAmt = Number(getTotalCartValue(cartD, false));
  const currentMilestoneIndex = getCurrIndex(totalAmt);

  if (currentMilestoneIndex === 5) {
    return "";
  }

  return `Add more items worth Rs.${Number(
    OFFER_DATA[currentMilestoneIndex + 1]?.start - totalAmt
  )?.toFixed(2)} to avail ${
    OFFER_DATA[currentMilestoneIndex + 1]?.discount
  }% discount on PTR`;
};

const getDiscount = (totalAmt: number) => {
  const currentMilestoneIndex = getCurrIndex(totalAmt);
  return OFFER_DATA[currentMilestoneIndex]
    ? Number(
        totalAmt * (OFFER_DATA[currentMilestoneIndex]?.discount / 100)
      )?.toFixed(2)
    : 0;
};

export function getTotalCartValue(cartD: any, applyDiscount: boolean) {
  let total: number = 0;

  if (cartD.length > 0) {
    for (let i = 0; i < cartD.length; i++) {
      total += Number(cartD[i].qty) * Number(cartD[i].ptr);
    }
  }

  if (applyDiscount) {
    total = total - Number(getDiscount(total));
  }

  return Number(total).toFixed(2);
}

const CartView = () => {
  const cartDataFromStore =
    useSelector((state: any) => state.cart.cartData) || [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  let qData: any = new URLSearchParams(location.search).get("data");
  let parsedData: any = "";
  if (qData) {
    parsedData = JSON.parse(window.atob(qData as string));
  }
  const privateInstance = useAxiosInstance();
  const [loading, setLoading] = useState(false);
  const [highlightCard, setHighlightCard] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const cardRef = useRef<any>(null);

  const deleteItem = (id: string) => {
    let updatedData = [...cartDataFromStore];
    updatedData = updatedData.filter((medicine) => medicine.id !== id);
    dispatch(UpdateCart(updatedData));
    localStorage.setItem("cart_data", JSON.stringify(updatedData));
  };

  const placeOrder = async (note: string) => {
    setLoading(true);
    try {
      let payload: any = {
        order_details: cartDataFromStore,
        location_id: parsedData?.loc,
        type: "distributor",
      };
      if (note) {
        payload = {
          ...payload,
          note: note,
        };
      }

      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/notify`,
        payload
      );
      if (result?.data?.status) {
        let existingIds = [];
        if (localStorage.getItem("purchase_data")) {
          existingIds = JSON.parse(
            String(localStorage.getItem("purchase_data"))
          );
        }
        const updatedData = [
          ...(new Set([
            ...cartDataFromStore.map((x: any) => x.id),
            ...existingIds,
          ]) as any),
        ];
        localStorage.setItem("purchase_data", JSON.stringify(updatedData));
        dispatch(UpdateCart([]));
        localStorage.removeItem("cart_data");
        setOpen(true);
      } else {
        errorToast(result.data.message);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Stack width={{ xs: "100%", md: "660px", margin: "auto" }}>
        <Stack
          sx={{ backgroundColor: "#0058ff" }}
          p={2}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <ArrowBackIcon
              fontSize="small"
              onClick={() =>
                navigate(qData ? `/search?data=${qData}` : `/search`)
              }
              sx={{ color: "#fff" }}
            />
            <Typography color="white" variant="h4">
              {parsedData?.name || ""}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <Button
              onClick={() => setShowHelpModal(true)}
              sx={{ color: "#fff" }}
            >
              Help
            </Button>
            <Badge badgeContent={cartDataFromStore?.length} color="warning">
              <ShoppingCartIcon sx={{ color: "#fff" }} />
            </Badge>
          </Stack>
        </Stack>
        <Divider />
        {cartDataFromStore?.length > 0 ? (
          <>
            <Box sx={{ width: { xs: "95%", md: "100%" }, margin: "auto" }}>
              {cartDataFromStore.map((medicine: any, index: number) => {
                return (
                  <Stack key={index}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      p={2}
                    >
                      <Stack>
                        <Typography variant="h5">{medicine.name}</Typography>
                        <Typography my={0.5} variant="body1">
                          <strong>{medicine.packaging}</strong> |{" "}
                          {medicine.manufacturer}
                        </Typography>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Typography my={0.5} variant="body1">
                            PTR : <strong> &#8377; {medicine.ptr}</strong>{" "}
                            &nbsp;
                          </Typography>
                          <Typography variant="h6">X</Typography>
                          <AddAndAlterItems medicine={medicine} view="cart" />
                        </Stack>
                      </Stack>
                      <Stack alignItems="center" gap={2}>
                        <IconButton
                          color="error"
                          onClick={() => deleteItem(medicine.id)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                        <Typography variant="h6">
                          &#8377;
                          {Number(medicine.ptr * medicine.qty)?.toFixed(2)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />
                  </Stack>
                );
              })}
            </Box>
            <Card
              ref={cardRef}
              sx={{
                width: { xs: "95%", md: "100%" },
                margin: "auto",
                mt: 2,
                mb: 10,
                backgroundColor: highlightCard ? "#E6EEFF" : "#fff",
                boxShadow: "0px 4px 8px #e4e4e4",
              }}
            >
              <CardContent>
                <Typography pb={1.5} variant="h5">
                  Bill Summary
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography>Item Total</Typography>
                  <Typography>
                    &#8377;{getTotalCartValue(cartDataFromStore, false)}
                  </Typography>
                </Stack>
                <Stack
                  mt={0.5}
                  mb={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography color="green">
                    Discount{" "}
                    {getCurrIndex(
                      Number(getTotalCartValue(cartDataFromStore, false))
                    ) !== -1
                      ? `(${
                          OFFER_DATA[
                            getCurrIndex(
                              Number(
                                getTotalCartValue(cartDataFromStore, false)
                              )
                            )
                          ]?.discount
                        }%)`
                      : null}
                  </Typography>
                  <Typography color="green">
                    - &#8377;
                    {getDiscount(
                      Number(getTotalCartValue(cartDataFromStore, false))
                    )}
                  </Typography>
                </Stack>
                <Divider />
                <Stack mt={1} direction="row" justifyContent="space-between">
                  <Typography variant="h6">To be paid</Typography>
                  <Typography variant="h6">
                    &#8377;{getTotalCartValue(cartDataFromStore, true)}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                borderTop: "1px solid #eeeeee",
                width: "100%",
                maxWidth: "660px",
                left: "50%",
                transform: "translate(-50%, 0)",
                backgroundColor: "#fff",
              }}
            >
              {getOfferMsg(cartDataFromStore) ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  p={0.5}
                  sx={{ backgroundColor: "#1876D1" }}
                >
                  <LocalOfferIcon sx={{ color: "#fff", fontSize: 14 }} />
                  <Typography variant="body2" sx={{ color: "#fff" }}>
                    {getOfferMsg(cartDataFromStore)}
                  </Typography>
                </Stack>
              ) : null}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Stack>
                  <Typography variant="h5">
                    &#8377; {getTotalCartValue(cartDataFromStore, true)}
                  </Typography>
                  <Typography
                    onClick={() => {
                      cardRef.current.scrollIntoView({ behavior: "smooth" });
                      setHighlightCard(true);
                      setTimeout(() => {
                        setHighlightCard(false);
                      }, 2000);
                    }}
                    variant="h6"
                    sx={{ color: "#0058ff" }}
                  >
                    See bill summary
                  </Typography>
                </Stack>
                <Button
                  onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      placeOrder("");
                    }
                  }}
                  variant="contained"
                  color="success"
                  size="small"
                >
                  Submit Order
                </Button>
              </Stack>
            </Box>
          </>
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "85vh" }}
          >
            <Typography>Your cart is empty</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                navigate(qData ? `/search?data=${qData}` : `/search`)
              }
              size="small"
              sx={{ mt: 1 }}
            >
              Add items
            </Button>
          </Stack>
        )}
      </Stack>
      {open ? (
        <SuccessModal open={open} onClose={() => setOpen(false)} />
      ) : null}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <Stack alignItems="center">
          <CircularProgress color="inherit" />
          <Typography pt={1} color="#fff">
            Please Wait...
          </Typography>
        </Stack>
      </Backdrop>
      {showHelpModal ? (
        <HelpModal
          open={showHelpModal}
          onClose={() => setShowHelpModal(false)}
          placeOrder={() => placeOrder("Support Required")}
          loading={loading}
        />
      ) : null}
    </div>
  );
};

export default CartView;
