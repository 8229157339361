import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { paymentOptions, valueLimits } from "../../../../constants";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import useUserInfo from "../../../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import { confirmPaymentSchema } from "../../../../schemas/payment";
import {
  CONFIRMATION_TYPE,
  CONSULTATION_TYPE,
  ORDER_PLATFORM,
  ORDER_STATUS,
  ORDER_TYPE,
  PAYMENT_OPTIONS,
} from "../../../../enums/order.enum";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  validateFloatNum,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import OrderPlatform from "../../../Common/orderPlatform";
import {
  API_RESULT,
  API_VERSION,
  USER_ROLE,
} from "../../../../enums/common.enum";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ConfirmPaymentModal = ({
  confirmPayModal,
  setConfirmPayModal,
  orderData,
  setOrderData,
  setParentModal,
}: any) => {
  const user: AuthenticatedUser = useUserInfo();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const location = useLocation();

  const getOrderByPartnerOrderId = async () => {
    setOrderData([]); //reset order data for sequential search
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${orderData?.order_partner}/${orderData?.partner_order_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const onResendLink = async () => {
    let payload = {
      order_id: orderData?.order_id,
      patient_payable: orderData?.patient_payable,
    };
    setResendLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/resend-payment-link`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Link sent successfully");
        setConfirmPayModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setResendLoading(false);
    }
  };

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      partner_order_id: orderData?.partner_order_id,
      invoice_id: orderData?.invoice_details?.invoice_id,
      partner_name: orderData?.order_partner,
      payment_details: {
        payment_type: values?.payment_type?.toLowerCase(),
        order_amount: orderData?.invoice_details?.bill_amount,
      },
    };

    if (values?.payment_type?.toLowerCase() === PAYMENT_OPTIONS.VOUCHER) {
      payload = {
        ...payload,
        payment_details: {
          ...payload?.payment_details,
          voucher_number: values?.voucher_code,
        },
      };
    }

    if (values?.confirmation_type === CONFIRMATION_TYPE.PARTIAL) {
      let a = parseFloat(values?.pay_by_partner);
      let b = parseFloat(values?.due_on_customer);
      let c = a + b;
      let orderAmount1 = 0;
      let orderAmount2 = 0;
      if (
        orderData?.order_type === ORDER_TYPE.CONSULTATION &&
        orderData?.consultation_type === CONSULTATION_TYPE.ONLINE
      ) {
        orderAmount1 = parseFloat(orderData?.partner_consultation_charges) - 1;
        orderAmount2 = parseFloat(orderData?.partner_consultation_charges) + 1;
      } else {
        orderAmount1 =
          Number(values?.delivery_charges) > 0
            ? parseFloat(orderData?.invoice_details?.bill_amount) +
              parseFloat(values?.delivery_charges) -
              1
            : parseFloat(orderData?.invoice_details?.bill_amount) - 1;
        orderAmount2 =
          Number(values?.delivery_charges) > 0
            ? parseFloat(orderData?.invoice_details?.bill_amount) +
              parseFloat(values?.delivery_charges) +
              1
            : parseFloat(orderData?.invoice_details?.bill_amount) + 1;
      }
      if (c > orderAmount2 || c < orderAmount1) {
        alert(
          "Sum of payer payable and patient should be equal to the order amount"
        );
        return;
      }
      payload = {
        ...payload,
        payer_payable: values?.pay_by_partner,
        patient_payable: values?.due_on_customer,
      };
    }

    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      payload = {
        ...payload,
        payment_details: {
          ...payload.payment_details,
          delivery_fee: isNaN(Number.parseInt(values.delivery_charges))
            ? 0
            : Number.parseInt(values.delivery_charges),
          delivery_partner: values.delivery_partner,
        },
      };
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/invoices/confirm-payment`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Payment confirmed");
        if (setParentModal) {
          setParentModal(false);
        } else {
          setConfirmPayModal(false);
        }
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              orderData?.order_id,
              result.data.response
            )
          )
        );
        //update admin panel data
        if (location?.pathname?.includes("admin")) {
          getOrderByPartnerOrderId();
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={confirmPayModal}
      onClose={() => setConfirmPayModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setConfirmPayModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
            Confirm Payment - {orderData?.customer_details?.name}
          </Typography>
          {orderData && orderData.platform && (
            <OrderPlatform platform={orderData.platform} />
          )}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            confirmation_type: "complete",
            payment_type: "",
            pay_by_partner: "",
            due_on_customer: "",
            voucher_code: "",
            delivery_charges: orderData?.partner_delivery_charges || "",
            delivery_partner: orderData?.delivery_partner || "",
          }}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
          validationSchema={confirmPaymentSchema}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    id="invoice_id"
                    label="Invoice ID"
                    size="small"
                    value={orderData?.invoice_details?.invoice_id || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    disabled
                  />
                  {orderData?.order_type === ORDER_TYPE.CONSULTATION &&
                  orderData?.consultation_type === CONSULTATION_TYPE.ONLINE ? (
                    <TextField
                      fullWidth
                      id="partner_consultation_charges"
                      label="Partner consultation charges"
                      size="small"
                      value={orderData?.partner_consultation_charges || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                  ) : (
                    <TextField
                      fullWidth
                      id="order_amount"
                      label="Order Amount"
                      size="small"
                      value={orderData?.invoice_details?.bill_amount || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                  )}
                </Stack>
                {orderData?.order_type === ORDER_TYPE.MEDICINES && (
                  <Stack direction="row" sx={{ mt: 1.5 }}>
                    <TextField
                      fullWidth
                      id="delivery_charges"
                      label="Delivery Charges"
                      size="small"
                      value={orderData?.partner_delivery_charges || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      id="delivery_partner"
                      label="Delivery Partner"
                      size="small"
                      value={orderData?.delivery_partner || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                  </Stack>
                )}
                {orderData?.order_status === ORDER_STATUS.PAYMENT_PENDING ? (
                  <Stack direction="row" sx={{ mt: 1.5 }}>
                    <TextField
                      fullWidth
                      id="pay_by_partner"
                      label={`Pay by ${orderData?.order_partner}`}
                      size="small"
                      value={orderData?.payer_payable || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      id="due_on_customer"
                      label="Due on customer"
                      size="small"
                      value={orderData?.patient_payable || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled={
                        orderData?.order_partner === "lombard" ? false : true
                      }
                      onChange={(e) =>
                        setOrderData({
                          ...orderData,
                          patient_payable: validateFloatNum(
                            e.target.value,
                            valueLimits.MRP
                          ),
                        })
                      }
                    />
                  </Stack>
                ) : (
                  <Stack direction="row" sx={{ mt: 1.5 }}>
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.confirmation_type && touched.confirmation_type
                          ? true
                          : false
                      }
                    >
                      <InputLabel id="confirmation-type-label">
                        Confirmation Type
                      </InputLabel>
                      <Select
                        labelId="confirmation-type-label"
                        name="confirmation_type"
                        id="confirmation_type"
                        value={values.confirmation_type}
                        label="Confirmation Type"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("confirmation_type", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="complete">Complete</MenuItem>
                        <MenuItem value="partial">Partial</MenuItem>
                      </Select>
                      {errors.confirmation_type &&
                        touched.confirmation_type && (
                          <FormHelperText>
                            {errors.confirmation_type}
                          </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.payment_type && touched.payment_type
                          ? true
                          : false
                      }
                    >
                      <InputLabel id="payment-type-label">
                        Payment Type
                      </InputLabel>
                      <Select
                        labelId="payment-type-label"
                        name="payment_type"
                        id="payment_type"
                        value={values.payment_type}
                        label="Payment Type"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("payment_type", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {paymentOptions.map((x) => {
                          return (
                            <MenuItem key={x} value={x}>
                              {x}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.payment_type && touched.payment_type && (
                        <FormHelperText>{errors.payment_type}</FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                )}
                {values.confirmation_type === CONFIRMATION_TYPE.PARTIAL && (
                  <Stack direction="row" sx={{ mt: 1.5 }}>
                    <TextField
                      fullWidth
                      id="pay_by_partner"
                      label={`Pay by ${orderData?.order_partner}`}
                      size="small"
                      value={values?.pay_by_partner || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue(
                          "pay_by_partner",
                          validateFloatNum(e.target.value, valueLimits.MRP)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.pay_by_partner && touched.pay_by_partner
                          ? errors.pay_by_partner
                          : ""
                      }
                      error={
                        errors.pay_by_partner && touched.pay_by_partner
                          ? true
                          : false
                      }
                    />
                    <TextField
                      fullWidth
                      id="due_on_customer"
                      label="Due on customer"
                      size="small"
                      value={values?.due_on_customer || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) =>
                        setFieldValue(
                          "due_on_customer",
                          validateFloatNum(e.target.value, valueLimits.MRP)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.due_on_customer && touched.due_on_customer
                          ? errors.due_on_customer
                          : ""
                      }
                      error={
                        errors.due_on_customer && touched.due_on_customer
                          ? true
                          : false
                      }
                    />
                  </Stack>
                )}
                {values.payment_type?.toLowerCase() ===
                  PAYMENT_OPTIONS.VOUCHER && (
                  <Stack sx={{ mt: 1.5 }}>
                    <TextField
                      fullWidth
                      id="voucher_code"
                      label="Voucher Code"
                      size="small"
                      value={values?.voucher_code || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      onChange={(e) => {
                        setFieldValue("voucher_code", e.target.value);
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.voucher_code && touched.voucher_code
                          ? errors.voucher_code
                          : ""
                      }
                      error={
                        errors.voucher_code && touched.voucher_code
                          ? true
                          : false
                      }
                    />
                  </Stack>
                )}
                {/* PARTNER SPECIFIC: WatchYourHealth_HDFC, CIPLA, mmhglobal */}
                {(orderData?.platform === ORDER_PLATFORM.ORDER_DASHBOARD ||
                  (["watchyourhealth_hdfc", "cipla", "mmhglobal"].includes(
                    orderData?.order_partner
                  ) &&
                    user?.role !== USER_ROLE.PARTNER)) &&
                orderData?.order_status !== ORDER_STATUS.PAYMENT_PENDING ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={loading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      disabled={isSubmitting}
                    >
                      Confirm Payment
                    </LoadingButton>
                  </Stack>
                ) : null}
                {orderData?.order_status === ORDER_STATUS.PAYMENT_PENDING && (
                  <Stack marginTop={10}>
                    <Typography variant="h3">
                      Please read carefully :
                    </Typography>
                    <Typography variant="body2">
                      1. We have sent a payment collection link (
                      <a
                        href={orderData?.patient_payable_details?.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <b>{orderData?.patient_payable_details?.link}</b>
                      </a>
                      ) on customer mobile number{" "}
                      <b>{orderData?.customer_details?.mobile}</b> for{" "}
                      <b>Rs.{orderData?.patient_payable}</b>.
                    </Typography>
                    <Typography variant="body2">
                      2. Please wait for payment confirmation.
                    </Typography>
                    <Typography variant="body2">
                      3. The moment we received payment from customer, order
                      status will change to <b>Payment collected</b> and order
                      can be processed further.
                    </Typography>
                  </Stack>
                )}
                {orderData?.platform === ORDER_PLATFORM.PARTNER_API &&
                  !["watchyourhealth_hdfc", "cipla", "mmhglobal"].includes(
                    orderData?.order_partner
                  ) && (
                    <Stack marginTop={10}>
                      <Typography variant="h3">Note :</Typography>
                      <Typography variant="body2">
                        1. This is an app generated order and{" "}
                        {user.role === USER_ROLE.PARTNER ? "you" : "we"} are not
                        suppose to confirm the payment manually. Please wait
                        until user confirms from the app.
                      </Typography>
                    </Stack>
                  )}
                {orderData?.platform === ORDER_PLATFORM.ORDER_DASHBOARD && (
                  <>
                    {user.role === USER_ROLE.PARTNER ||
                    user.role === USER_ROLE.SUPER_ADMIN ? null : (
                      <Stack marginTop={10}>
                        <Typography variant="h3">Note :</Typography>
                        <Typography variant="body2">
                          1. Awaiting payment confirmation from{" "}
                          {orderData?.order_partner}. Please wait or write an
                          email on techsupport@medpay.in.
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
                {orderData?.order_status === ORDER_STATUS.PAYMENT_PENDING && (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 3 }}
                  >
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      loading={resendLoading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      onClick={() => onResendLink()}
                    >
                      Resend
                    </LoadingButton>
                  </Stack>
                )}
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmPaymentModal;
