import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { valueLimits } from "../../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../../enums/common.enum";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { refundSchema } from "../../../../../schemas/payment";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
  validateIntNum,
} from "../../../../../utils/common";
import CustomDialogTitle from "../../../../Common/customDialogTitle";

const RefundModal = ({
  showRefundModal,
  setShowRefundModal,
  orderData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      order_id: orderData?.order_id,
      mobile: values?.mobile,
      amount: values.amount.toString(),
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/collection-link`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Link successfully sent to merchant");
        setShowRefundModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showRefundModal}
      onClose={() => setShowRefundModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowRefundModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
            Refund Collection
          </Typography>
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            mobile: orderData?.merchant_details?.mobile,
            amount: Number(
              orderData?.invoice_details?.final_payout_amount
            ).toFixed(2),
          }}
          onSubmit={(values: any, actions: any) => {
            setLoading(true);
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
          validationSchema={() =>
            refundSchema(orderData?.invoice_details?.final_payout_amount)
          }
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    id="amount"
                    label="Refund Amount"
                    size="small"
                    value={values?.amount || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e: any) =>
                      setFieldValue(
                        "amount",
                        validateFloatNum(e.target.value, valueLimits.MRP)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.amount && touched.amount ? errors.amount : ""
                    }
                    error={errors.amount && touched.amount ? true : false}
                  />
                  <TextField
                    fullWidth
                    id="mobile"
                    label="Mobile"
                    size="small"
                    value={values?.mobile || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "mobile",
                        validateIntNum(e.target.value, valueLimits.MOBILE)
                      )
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.mobile && touched.mobile ? errors.mobile : ""
                    }
                    error={errors.mobile && touched.mobile ? true : false}
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Stack>
                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    disabled={isSubmitting}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                  >
                    Send Link
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RefundModal;
