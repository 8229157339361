import {
  Autocomplete,
  Box,
  CircularProgress,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { debounce } from "lodash";
// import { LAB_VISIT_TYPE } from "../../enums/order.enum";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import {
  errorToast,
  handleError,
  isUserAllowedToRevise,
} from "../../utils/common";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import NewItemModal from "../ManageOrder/GroupOrders/Assign/newItemModal";

const SearchAutoComplete = ({
  selectedValues,
  setSelectedValues,
  selectedValuesId,
  setSelectedValuesId,
  autocompleteLabel,
  diagnosticVisitType = "",
  isEditing = false,
  altMedicineIndex = null,
  setAltMedicineIndex = undefined,
  orderStatus = "",
}: any) => {
  const [options, setOptions] = useState([]);
  const privateInstance = useAxiosInstance();
  const [input, setInput] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [autoCompleteSwitch, setAutoCompleteSwitch] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const [showNewItemModal, setShowNewItemModal] = useState(false);

  const getSearchDataUrl = (val: string) => {
    // if (
    //   autocompleteLabel === "Tests" &&
    //   (diagnosticVisitType === "" ||
    //     diagnosticVisitType === LAB_VISIT_TYPE.SELF_VIST)
    // ) {
    //   return `/partner/diagnostics/skus/online/search/${val}?category=all`;
    // }
    // if (
    //   autocompleteLabel === "Tests" &&
    //   diagnosticVisitType === LAB_VISIT_TYPE.HOME_VISIT
    // ) {
    //   return `/partner/diagnostics/skus/online/search/${val}`;
    // }
    if (autocompleteLabel === "Tests") {
      return `/orders/diagnostics/test/search?name=${val}`;
    }
    if (autocompleteLabel === "Medicines") {
      return `/orders/medicine/search?name=${val}&filter=${
        isEditing ? "all" : "approved"
      }`;
    }
  };

  const getSearchData = async (inputValue: string) => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}${String(getSearchDataUrl(inputValue))}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (autocompleteLabel === "Tests") {
          setOptions(result.data.response);
        } else {
          setOptions(result.data.response?.results);
        }
      } else {
        setOptions([]);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getSearchData, 500), [
    diagnosticVisitType,
  ]);

  useEffect(() => {
    if (input) {
      setAutoCompleteSwitch(true);
      debounceFn(input);
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  //reset options on close
  useEffect(() => {
    if (!autoCompleteSwitch) {
      setOptions([]);
    }
  }, [autoCompleteSwitch]);

  const addItemManually = () => {
    if (autocompleteLabel === "Tests") {
      const item = {
        item: input,
        details: input,
        MRP: "",
        quantity: 1,
      };
      setSelectedValues([...selectedValues, item]);
    }
    if (autocompleteLabel === "Medicines") {
      const item = {
        sku_id: `OTH${
          selectedValues.filter((x: any) => x.manual)?.length + 1 || 1
        }`,
        manual: true,
        item: input,
        details: "",
        quantity: 1,
        MRP: "",
        manufacturer: "",
        composition: "",
        drug_form: "",
        pack_form: "Strip",
        pack_quantity: "",
      };
      setSelectedValues([...selectedValues, item]);
    }
    setAutoCompleteSwitch(false);
  };

  const shouldShowManualAdditon = (label: string) => {
    if (label === "Medicines") {
      if (isEditing) return false;
      if (user?.role === USER_ROLE.PARTNER) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const isItemAllowed = (status: string | undefined) => {
    if (!status) {
      return false;
    } else if (!isEditing && status !== "available") {
      // creating order
      return false;
    } else if (
      // editing order
      isEditing &&
      !["pending", "available", "discontinued"].includes(status)
    ) {
      return false;
    }
    return true;
  };

  const PopperSearchResults = function (props: any) {
    return <Popper {...props} placement="bottom-start" />;
  };

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        {!isEditing && <Typography variant="h4">Search for:</Typography>}
        <ClickAwayListener onClickAway={() => setAutoCompleteSwitch(false)}>
          <Autocomplete
            disabled={
              autocompleteLabel === "Medicines" &&
              !isUserAllowedToRevise(orderStatus, user.role)
            }
            open={autoCompleteSwitch}
            ListboxProps={{ style: { maxHeight: "14rem" } }}
            size="small"
            sx={{
              width: { xs: "100%", md: "50%" },
              my: 3,
              ml: 2,
            }}
            value={selectedValue}
            onChange={(e, val: any) => {
              setSelectedValue(val);
              //update list of tests
              let allTests = [...selectedValues];
              if (
                autocompleteLabel === "Medicines" &&
                altMedicineIndex != null
              ) {
                if (isItemAllowed(val?.status)) {
                  selectedValues.splice(altMedicineIndex, 1, {
                    sku_id: val.sku_id,
                    item: val.name,
                    details: val.pack_qty_label,
                    quantity: 1,
                    MRP: String(val.mrp),
                    manufacturer: val.mfr,
                    composition: val.composition,
                    drug_form: val.drug_form,
                    pack_form: val.pack_form,
                    pack_quantity: val.pack_quantity,
                    change_type: "alternative",
                    requested_item: selectedValues[altMedicineIndex],
                    med_from_db: "true",
                    db_mrp: val?.mrp,
                    status: val.status,
                  });
                  const updatedData = [...selectedValues];
                  setSelectedValues(updatedData);
                  setAltMedicineIndex(null);
                  return;
                } else {
                  errorToast(
                    `${val.status} item cannot be added as an alternative.`
                  );
                  return;
                }
              }
              if (autocompleteLabel === "Tests") {
                allTests.push({
                  MRP: String(val.mrp),
                  details: val.label,
                  item: val.name,
                  quantity: 1,
                  test_id: val.test_id,
                });
              }
              if (autocompleteLabel === "Medicines") {
                if (isItemAllowed(val?.status)) {
                  allTests.push({
                    sku_id: val.sku_id,
                    item: val.name,
                    details: val.pack_qty_label,
                    quantity: 1,
                    MRP: String(val.mrp),
                    manufacturer: val.mfr,
                    composition: val.composition,
                    drug_form: val.drug_form,
                    pack_form: val.pack_form,
                    pack_quantity: val.pack_quantity,
                    db_mrp: val?.mrp,
                    change_type: "new",
                    status: val.status,
                  });
                } else {
                  errorToast(`${val.status} item cannot be added.`);
                  return;
                }
              }
              setSelectedValues(allTests);

              //update list of test_id
              let allTestsId = [...selectedValuesId];
              autocompleteLabel === "Tests"
                ? allTestsId.push(val.test_id)
                : allTestsId.push(val.sku_id);
              setSelectedValuesId(allTestsId);
            }}
            onInputChange={(e: any) => setInput(e?.target?.value)}
            onSelect={(e: any) => setSelectedValue(null)}
            id="provider_id"
            //filter already selected tests
            options={
              autocompleteLabel === "Tests"
                ? options.filter(
                    (x: any) => !selectedValuesId.includes(x.test_id)
                  )
                : options.filter(
                    (x: any) => !selectedValuesId.includes(x.sku_id)
                  )
            }
            //show all received in respopnse
            filterOptions={(x) => x}
            //postion result options
            PopperComponent={PopperSearchResults}
            loading={loading}
            renderInput={(params) => (
              <TextField
                name="lab-test-input"
                placeholder={
                  autocompleteLabel === "Medicines"
                    ? "Medicines and Health Products*"
                    : "Lab Tests*"
                }
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onPaste={(e: any) => {
                  e.preventDefault();
                }}
                onCopy={(e: any) => {
                  e.preventDefault();
                }}
              />
            )}
            renderOption={(props: any, option: any) => {
              return (
                <>
                  <li {...props}>
                    <Box sx={{ py: 0.5, width: "100%" }}>
                      {autocompleteLabel === "Tests" && (
                        <>
                          <Typography variant="body1">{option.name}</Typography>
                          {/* <Typography variant="body2">{option.label}</Typography> */}
                          <Typography variant="body2">₹{option.mrp}</Typography>
                        </>
                      )}
                      {autocompleteLabel === "Medicines" && (
                        <Stack direction="row" justifyContent="space-between">
                          <Box>
                            <Typography variant="body1">
                              {option.name}
                            </Typography>
                            <Typography variant="body2">
                              {option.mfr}
                            </Typography>
                            <Typography variant="body2">
                              {option.pack_qty_label} - ₹{option.mrp}
                            </Typography>
                          </Box>
                          <Box textAlign="center">
                            {option.rx_required === "yes" ? (
                              <Tooltip title="Prescription Required">
                                <svg
                                  fill="#0058ff"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 32.00 32.00"
                                  width="20px"
                                  height="20px"
                                  stroke="#0058ff"
                                  strokeWidth="1"
                                >
                                  <g id="SVGRepo_iconCarrier">
                                    {" "}
                                    <path d="M28,31.36H4c-0.199,0-0.36-0.161-0.36-0.36V1c0-0.199,0.161-0.36,0.36-0.36h18 c0.096,0,0.188,0.038,0.255,0.105l6,6C28.322,6.813,28.36,6.904,28.36,7v24C28.36,31.199,28.199,31.36,28,31.36z M4.36,30.64h23.28 V7.36H22c-0.199,0-0.36-0.161-0.36-0.36V1.36H4.36V30.64z M22.36,6.64h4.771L22.36,1.869V6.64z M20,27.36H8v-0.72h12V27.36z M24,23.36H8v-0.72h16V23.36z M24,19.36H8v-0.72h16V19.36z M16.254,9.254l-0.509-0.509L13,11.491l-2.252-2.252 C11.684,8.925,12.36,8.04,12.36,7c0-1.301-1.059-2.36-2.36-2.36H7.64V13h0.72V9.36h1.491l2.64,2.64l-2.746,2.746l0.509,0.509 L13,12.509l2.746,2.746l0.509-0.509L13.509,12L16.254,9.254z M8.36,8.64V5.36H10c0.904,0,1.64,0.736,1.64,1.64S10.904,8.64,10,8.64 H8.36z"></path>{" "}
                                  </g>
                                </svg>
                              </Tooltip>
                            ) : null}
                            <Typography
                              style={{
                                fontSize: "12px",
                                color:
                                  option.status === "available"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {option.status}
                            </Typography>
                          </Box>
                        </Stack>
                      )}
                    </Box>
                  </li>
                  {props.id ===
                  `provider_id-option-${
                    options?.filter(
                      (x: any) =>
                        !selectedValuesId.includes(
                          autocompleteLabel === "Tests" ? x.test_id : x.sku_id
                        )
                    )?.length - 1
                  }` ? (
                    <>
                      {shouldShowManualAdditon(autocompleteLabel) ? (
                        <Box
                          key={props.id}
                          onClick={addItemManually}
                          sx={{ padding: "6px 16px", cursor: "pointer" }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {input}
                          </Typography>
                          <Typography variant="body2">
                            Add this item manually
                          </Typography>
                        </Box>
                      ) : null}
                      {isEditing ? (
                        <Box
                          sx={{ padding: "6px 16px", cursor: "pointer" }}
                          pt={1}
                          onClick={() => setShowNewItemModal(true)}
                        >
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {input}
                          </Typography>
                          <Typography variant="body2">Add new item</Typography>
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.name === value.name
            }
            noOptionsText={
              <>
                {shouldShowManualAdditon(autocompleteLabel) ? (
                  <Box onClick={addItemManually}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {input}
                    </Typography>
                    <Typography variant="body2">
                      Add this item manually
                    </Typography>
                  </Box>
                ) : (
                  "No data found"
                )}
                {isEditing ? (
                  <Box
                    style={{ cursor: "pointer" }}
                    pt={1}
                    onClick={() => setShowNewItemModal(true)}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {input}
                    </Typography>
                    <Typography variant="body2">Add new item</Typography>
                  </Box>
                ) : null}
              </>
            }
            getOptionLabel={(option) => option.name || ""}
          />
        </ClickAwayListener>
      </Box>
      {showNewItemModal ? (
        <NewItemModal
          showNewItemModal={showNewItemModal}
          setShowNewItemModal={setShowNewItemModal}
          medicineName={input}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          selectedValuesId={selectedValuesId}
          setSelectedValuesId={setSelectedValuesId}
          altMedicineIndex={altMedicineIndex}
          setAltMedicineIndex={setAltMedicineIndex}
        />
      ) : null}
    </>
  );
};

export default SearchAutoComplete;
