import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import PaymentDataRow from "../../components/ManageOrder/GroupOrders/Payment/PaymentStatus/paymentDataRow";
import { parseAndFormateDate } from "../../utils/common";

const PayoutStatusModal = ({
  payoutStatusModal,
  setPayoutStatusModal,
  data,
}: any) => {
  return (
    <div>
      <ErrorBoundary>
        <Dialog
          open={payoutStatusModal}
          onClose={() => setPayoutStatusModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setPayoutStatusModal(false)}
          >
            Payout Details
          </CustomDialogTitle>
          <DialogContent>
            {data ? (
              <>
                <PaymentDataRow dataTitle="Payout ID" value={data?.payout_id} />
                <PaymentDataRow
                  dataTitle="Order Partner"
                  value={data?.extra_2}
                />
                <PaymentDataRow
                  dataTitle="Partner Order ID"
                  value={data?.extra_1}
                />
                <PaymentDataRow dataTitle="Order Type" value={data?.extra_4} />
                <PaymentDataRow
                  dataTitle="Payment Type"
                  value={data?.payment_type}
                />
                <PaymentDataRow
                  dataTitle="PG Partner"
                  value={data?.pg_partner}
                />
                <PaymentDataRow dataTitle="Amount" value={data?.amount} />
                <PaymentDataRow
                  dataTitle="Payment Status"
                  value={data?.payment_status}
                />
                <PaymentDataRow
                  dataTitle="Bank Reference"
                  value={data?.bank_reference}
                />
                <PaymentDataRow
                  dataTitle="Created At"
                  value={parseAndFormateDate(data?.created_at) as any}
                />
                <PaymentDataRow
                  dataTitle="Acknowledgement Number"
                  value={data?.acknowledgement_number}
                />
                <PaymentDataRow
                  dataTitle="Error Code"
                  value={data?.error_code}
                />{" "}
                <PaymentDataRow
                  dataTitle="Error Message"
                  value={data?.error_message}
                />
                <PaymentDataRow
                  dataTitle="Payment Type"
                  value={data?.extra_5}
                />
                <PaymentDataRow
                  dataTitle="Created By"
                  value={data?.created_by}
                />
              </>
            ) : null}
          </DialogContent>
        </Dialog>
      </ErrorBoundary>
    </div>
  );
};

export default PayoutStatusModal;
