import { LoadingButton } from "@mui/lab";
import { CircularProgress, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../../utils/common";

const VerifyUpi = ({
  upiData,
  setUpiData,
  setFieldValue = undefined,
  handleBlur = undefined,
  touched = null,
  errors = null,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleVerifyUpi = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/verify-upi?upi_id=${upiData?.upi_id}`
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        //check verification status
        if (
          result.data.response?.upi_verfication_status === "vpa_unavailable"
        ) {
          errorToast(
            `${upiData.upi_id} is unavailable, please use another vpa.`
          );
          return;
        }
        //
        setUpiData({
          ...upiData,
          account_holder_name: result.data.response.verify_upi_id_holder,
        });
        if (setFieldValue) {
          setFieldValue(
            "account_holder_name",
            result.data.response.verify_upi_id_holder
          );
        }
      } else {
        errorToast(result.data.message);
        setUpiData({
          ...upiData,
          account_holder_name: "",
        });
        if (setFieldValue) {
          setFieldValue("account_holder_name", "");
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack direction="row" alignItems="baseline">
      <TextField
        fullWidth
        id="upi_id"
        label="UPI ID"
        size="small"
        value={upiData?.upi_id || ""}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        onChange={(e: any) =>
          setUpiData({
            ...upiData,
            upi_id: e.target.value,
          })
        }
        onBlur={handleBlur}
        helperText={
          errors?.account_holder_name && touched?.account_holder_name
            ? errors?.account_holder_name
            : ""
        }
        error={
          errors?.account_holder_name && touched?.account_holder_name
            ? true
            : false
        }
      />
      <LoadingButton
        type="button"
        color="primary"
        variant="contained"
        loading={loading}
        disabled={!upiData?.upi_id}
        loadingIndicator={<CircularProgress size={20} sx={{ color: "#fff" }} />}
        onClick={() => handleVerifyUpi()}
      >
        Verify
      </LoadingButton>
    </Stack>
  );
};

export default VerifyUpi;
