import React, { useState } from "react";
import { Box, Button, Divider, Stack, Typography, Link } from "@mui/material";
import CustomerInfo from "./customerInfo";
import InvoiceInfo from "./invoiceInfo";
import MerchantInfo from "./merchantInfo";
import OrderInfo from "./orderInfo";
import { getStatusMessage, parseAndFormateDate } from "../../utils/common";
import ConfirmPaymentModal from "../ManageOrder/GroupOrders/Accepted/confirmPaymentModal";
import {
  CONSULTATION_TYPE,
  ORDER_STATUS,
  ORDER_TYPE,
} from "../../enums/order.enum";
import { JsonToTable } from "react-json-to-table";
import OrderPlatform from "../Common/orderPlatform";
import { useLocation } from "react-router-dom";
import UpdateInvoiceModal from "../ManageOrder/updateInvoiceModal";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { USER_ROLE } from "../../enums/common.enum";
import config from "../../config";

const CombinedInfo = ({
  orderData,
  setOrderData,
  setOrderDataModal,
  hideStatus,
  hideFirstRow,
}: any) => {
  const [confirmPayModal, setConfirmPayModal] = useState(false);
  const [updateInvoiceModal, setUpdateInvoiceModal] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const location = useLocation();

  return (
    <>
      {hideStatus ? null : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            pb: 2,
            mb: 2,
            borderBottom: "2px solid #0058ff",
          }}
        >
          <Stack direction="column">
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
              }}
            >
              {orderData?.order_status === ORDER_STATUS.CANCELLED
                ? getStatusMessage(orderData?.order_status) +
                  ` ${orderData?.cancelled_reason}`
                : orderData?.order_status === ORDER_STATUS.INVOICE_GENERATED
                ? orderData?.order_partner === "lombard"
                  ? getStatusMessage(orderData?.order_status)
                  : getStatusMessage(orderData?.order_status) +
                    `, please pay to proceed`
                : getStatusMessage(orderData?.order_status)}
            </Typography>
            {!setOrderDataModal ? (
              <>
                {orderData?.order_status === ORDER_STATUS.PACKAGE_PICKED ||
                orderData?.order_status === ORDER_STATUS.PACKAGE_DELIVERED ? (
                  <Box my={1}>
                    <Divider />
                  </Box>
                ) : null}
                <Box>
                  {orderData?.order_status === ORDER_STATUS.PACKAGE_PICKED && (
                    <span>Estimated delivery: {orderData?.delivery_slot}</span>
                  )}
                  {orderData?.order_status ===
                    ORDER_STATUS.PACKAGE_DELIVERED && (
                    <>
                      {orderData?.order_partner !== "dunzo" ? (
                        <span>
                          Estimated delivery: {orderData?.delivery_slot}{" "}
                          &nbsp;&nbsp;|
                        </span>
                      ) : null}
                      <span>
                        &nbsp; Delivered at:{" "}
                        {orderData?.order_partner === "dunzo"
                          ? parseAndFormateDate(orderData?.package_delivered_at)
                          : orderData?.delivered_at}
                      </span>
                    </>
                  )}
                </Box>
              </>
            ) : null}
          </Stack>
          {orderData?.order_status === ORDER_STATUS.INVOICE_GENERATED &&
            orderData?.order_partner !== "lombard" && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 5 }}
                  onClick={() => setConfirmPayModal(true)}
                >
                  Confirm Payment
                </Button>

                {confirmPayModal && (
                  <ConfirmPaymentModal
                    confirmPayModal={confirmPayModal}
                    setConfirmPayModal={setConfirmPayModal}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    setParentModal={setOrderDataModal}
                  />
                )}
              </>
            )}
        </Box>
      )}
      {hideFirstRow ? null : (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box width={{ xs: "100%", md: "50%" }}>
            <OrderInfo
              orderData={orderData}
              setOrderDataModal={setOrderDataModal}
            />
          </Box>
          <Box width={{ xs: "100%", md: "50%" }} sx={{ mt: { xs: 1, md: 0 } }}>
            <Stack
              direction="row"
              justifyContent={
                orderData?.invoice_details ? "space-between" : "end"
              }
              alignItems="start"
            >
              {orderData?.invoice_details ? (
                <Stack>
                  <InvoiceInfo
                    orderData={orderData}
                    showPayableInfo={
                      !setOrderDataModal && !hideStatus ? true : false //only show in admin panel
                    }
                  />
                </Stack>
              ) : null}
              <Stack>
                {/* show order platform in admin panel */}
                {orderData?.platform &&
                location.pathname?.toLowerCase()?.includes("settings") ? (
                  <OrderPlatform platform={orderData.platform} />
                ) : null}
                {/* show invoice update button */}
                {user?.role !== USER_ROLE.PARTNER &&
                orderData?.order_type === ORDER_TYPE.CONSULTATION &&
                orderData?.consultation_type === CONSULTATION_TYPE.OFFLINE &&
                (orderData?.order_status === ORDER_STATUS.BOOKING_COMPLETED ||
                  orderData?.order_status ===
                    ORDER_STATUS.BOOKING_CONFIRMED) ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 1 }}
                      onClick={() => setUpdateInvoiceModal(true)}
                    >
                      Update Invoice
                    </Button>

                    {updateInvoiceModal && (
                      <UpdateInvoiceModal
                        updateInvoiceModal={updateInvoiceModal}
                        setUpdateInvoiceModal={setUpdateInvoiceModal}
                        setParentModal={setOrderDataModal}
                        orderData={orderData}
                        setOrderData={setOrderData}
                        role={user?.role}
                      />
                    )}
                  </>
                ) : null}
              </Stack>
            </Stack>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: {
            xs: "column",
            md: hideStatus ? "row-reverse" : "row",
          },
          flexWrap: "wrap",
        }}
      >
        {orderData.customer_details && (
          <Box
            width={{ xs: "100%", md: "50%" }}
            sx={{ wordBreak: "break-word" }}
          >
            <CustomerInfo
              orderId={orderData?.order_id}
              customer_details={orderData?.customer_details}
              order_type={orderData?.order_type}
              patient_response={orderData?.patient_response}
            />
          </Box>
        )}
        {orderData.merchant_details && (
          <Box
            width={{ xs: "100%", md: "50%" }}
            sx={{ mt: { xs: 1, md: 0 }, wordBreak: "break-word" }}
          >
            <MerchantInfo
              merchant_details={orderData?.merchant_details}
              createdAt={orderData?.created_at}
              orderType={orderData?.order_type}
              orderPartner={orderData?.order_partner}
            />
          </Box>
        )}

        {/* PARTNER SPECIFIC: LOMBARD */}
        {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
          <>
            {orderData?.order_partner === "lombard" &&
            orderData?.otp_authentication ? (
              <Stack>
                <Typography variant="h5" pt={1}>
                  OTP Authentication:
                </Typography>
                <JsonToTable json={orderData?.otp_authentication} />
              </Stack>
            ) : null}

            {orderData?.order_partner === "lombard" &&
            orderData?.claim_submission_response ? (
              <Stack>
                <Typography variant="h5" pt={1}>
                  Claim Submission Status:
                </Typography>
                <JsonToTable json={orderData?.claim_submission_response} />
              </Stack>
            ) : null}

            {orderData?.order_partner === "lombard" &&
            orderData?.invoice_submission_response ? (
              <Stack>
                <Typography variant="h5" pt={1}>
                  Invoice Submission Status:
                </Typography>
                <JsonToTable json={orderData?.invoice_submission_response} />
              </Stack>
            ) : null}
          </>
        )}
      </Box>
      {orderData?.extra1 ? (
        <Typography pt={2}>
          <strong>Partner Instruction: </strong> {orderData.extra1}
        </Typography>
      ) : null}
      {/* PARTNER SPECIFIC: MEDPAY PLUS */}
      {orderData?.order_type === ORDER_TYPE.MEDICINES &&
      orderData?.order_partner === "medpay_plus" &&
      orderData.nearby_merchants?.[0]?.location_id ? (
        <Typography pt={2}>
          <strong>Coupon: </strong>
          <Link
            pt={2}
            style={{ textDecoration: "none" }}
            target="_blank"
            href={`https://${
              config.ENV === "DEV" ? "dev" : "www"
            }.medpay.store/coupon?coupon_id=${orderData.order_id}&loc_id=${
              orderData.nearby_merchants?.[0]?.location_id
            }`}
          >
            View Coupon
          </Link>
        </Typography>
      ) : null}
    </>
  );
};

export default CombinedInfo;
