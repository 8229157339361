import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  a11yProps,
  errorToast,
  handleError,
  parseAndFormateDate,
} from "../../utils/common";
import TabPanel from "../UI/tabPanel";
import AssignView from "../AssignOrder/assignView";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import ReassignView from "../AssignOrder/reassignView";
import { Stack, Typography } from "@mui/material";
import { staticAssignCols } from "../AssignOrder/assignTableSettings";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import { ORDER_STATUS } from "../../enums/order.enum";
import { useLocation, useNavigate } from "react-router-dom";

interface OrdersToAssign {
  assigned_to: string;
  created_at: string;
  order_status: string;
}

const AssignOrder: React.FC<{ orderType: string }> = ({ orderType }) => {
  const location = useLocation();
  const activeTab: any = location?.search?.split("=")[1];
  const [value, setValue] = React.useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const generateAssignRoute = (index: number) => {
    return `/${
      orderType === "medicines" ? "pharmacy" : orderType
    }/assign?tab=${index}`;
  };
  const [ordersToAssign, setOrdersToAssign] = React.useState<
    Array<OrdersToAssign>
  >([]);
  const [loading, setLoading] = React.useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const privateInstance = useAxiosInstance();
  const [ordersToReassign, setOrdersToReassign] = React.useState<
    OrdersToAssign[]
  >([]);

  let urlToFetchOrders: string;

  if (user?.role === USER_ROLE.EDITOR) {
    urlToFetchOrders = `/orders/all/${user?.partner}?ver=v3&order_type=${orderType}&editor=${user.user_id}`;
  } else {
    urlToFetchOrders = `/orders/all/${user.partner}?ver=v3&order_type=${orderType}`;
  }

  const getOrders = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}${urlToFetchOrders}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrdersToReassign(result.data.response);
        let toBeAssigned = result.data.response?.filter(
          (order: OrdersToAssign) =>
            !order.assigned_to && order.order_status === ORDER_STATUS.PENDING
        );
        setOrdersToAssign(toBeAssigned);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type MUIDataTableMeta = {
    rowData: Array<unknown>;
    rowIndex: number;
  };

  const assignColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
        customBodyRender: (
          value: string | number,
          tableMeta: MUIDataTableMeta
        ) => {
          return (
            <Stack>
              <Typography variant="body2">{!value ? "" : value}</Typography>
              <Typography variant="body2" color="#718096">
                {parseAndFormateDate(
                  ordersToAssign[tableMeta.rowIndex]?.created_at
                )}
              </Typography>
            </Stack>
          );
        },
      },
    },
    ...staticAssignCols,
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Assign"
              {...a11yProps(0)}
              onClick={() => navigate(generateAssignRoute(1))}
            />
            <Tab
              label="Re-assign"
              {...a11yProps(1)}
              onClick={() => navigate(generateAssignRoute(2))}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <AssignView
            ordersToAssign={ordersToAssign}
            loading={loading}
            columns={assignColumns}
            getOrders={getOrders}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ReassignView
            ordersToAssign={ordersToReassign}
            loading={loading}
            getOrders={getOrders}
          />
        </TabPanel>
      </Box>
    </>
  );
};

export default AssignOrder;
