import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const OrderTests = ({ tests, showTotal }: any) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  let subTotalTests: number = 0;
  if (tests && tests.length > 0) {
    tests?.forEach((row: any) => {
      subTotalTests += Number(row.MRP);
    });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  interface Column {
    id: number;
    label: string;
    minWidth?: number;
    align?: "right" | "left";
  }

  const testsColumns: readonly Column[] = [
    {
      id: 1,
      minWidth: 170,
      label: "Item",
      align: "left",
    },
    {
      id: 2,
      minWidth: 170,
      label: "Details",
      align: "left",
    },
    {
      id: 3,
      minWidth: 170,
      label: "Price(Rs.)",
      align: "right",
    },
    {
      id: 4,
      minWidth: 170,
      label: "Total(Rs.)",
      align: "right",
    },
  ];

  return (
    <Paper
      sx={{
        width: { xs: "72vw", sm: "79vw", md: "84vw", lg: "87vw", xl: "100%" },
        overflow: "hidden",
      }}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {testsColumns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tests &&
              tests.length > 0 &&
              tests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.test_id}
                    >
                      <TableCell align={testsColumns[0].align}>
                        {row.item}
                      </TableCell>
                      <TableCell align={testsColumns[1].align}>
                        {row.details}
                      </TableCell>
                      <TableCell align={testsColumns[2].align}>
                        {row.MRP}
                      </TableCell>
                      <TableCell align={testsColumns[3].align}>
                        {row.MRP}
                      </TableCell>
                    </TableRow>
                  );
                })}
            {showTotal && (
              <>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell colSpan={2} />
                  <TableCell>Sub Total (Rs.)</TableCell>
                  <TableCell align="right">
                    {Number(subTotalTests).toFixed(2)}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {tests && tests.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={tests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default OrderTests;
