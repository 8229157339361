import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { parse } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
  SETTLEMENT_MODE,
} from "../../../../enums/common.enum";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import SelfOptNotes from "../../../../pages/Pharmacy/Edit/selfOptNotes";
import { errorToast, handleError } from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import OrderPlatform from "../../../Common/orderPlatform";
import UploadFiles from "../../../Common/uploadFiles";
import TabPanel from "../../../UI/tabPanel";
import InstantPayment from "./instantPayment";
import IpdPayment from "./ipdPayment";
import OfflinePayment from "./offlinePayment";
import ThirdPartyPaymentButton from "./thirdPartyPaymentButton";
import UpiPayment from "./upiPayment";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const showInstantPaymentTab = (paymentCreditStatus: any, mode: any) => {
  return (
    paymentCreditStatus?.toLowerCase() === PAYMENT_CREDIT_STATUS.FAILED ||
    mode?.toLowerCase() !== SETTLEMENT_MODE.UPI
  );
};

const InvoicePayoutModal = ({
  invoicePayoutModal,
  setInvoicePayoutModal,
  showPaymentForm,
  setShowPaymentForm,
  orderData,
  setOrderData,
  mode,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [instantUpi, setInstantUpi] = useState<any>({});
  const [instantBank, setInstantBank] = useState<any>({});
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [settlementMode, setSettlementMode] = useState("");
  const [upiDetails, setUpiDetails] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const [providerInvoiceUpload, setProviderInvoiceUpload] = useState<any>(null);
  const [thirdPartyModal, setThirdPartyModal] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [qrActive, setQrActive] = useState(false);

  function showPosition(position: any) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  useEffect(() => {
    getLocation();
    if (
      orderData?.merchant_details &&
      orderData.merchant_details.process_mode?.upload_invoice?.toLowerCase() ===
        "y"
    ) {
      if (orderData.payment_confirmed_at) {
        let paymentConfirmed = parse(
          orderData.payment_confirmed_at.split(".")[0],
          "dd/MM/yyyy HH:mm:ss",
          new Date()
        );
        let date = new Date();
        var diff = (date.getTime() - paymentConfirmed.getTime()) / 1000;
        diff /= 60;
        diff = Math.abs(Math.round(diff));
        if (diff > 15) {
          setProviderInvoiceUpload("false");
        } else {
          showPaymentForm
            ? setProviderInvoiceUpload("false")
            : setProviderInvoiceUpload("true");
        }
      } else {
        setProviderInvoiceUpload("false");
      }
    } else {
      setProviderInvoiceUpload("false");
    }

    if (orderData?.order_type === ORDER_TYPE.IPD) {
      setSettlementMode(SETTLEMENT_MODE.INSTANT);
    } else {
      checkInstantPayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //effect to handle default active tab on retry
  useEffect(() => {
    if (settlementMode) {
      if (settlementMode?.toLowerCase() === SETTLEMENT_MODE.UPI) {
        setValue(0);
      } else {
        setValue(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementMode]);

  const checkInstantPayment = async () => {
    let location_id;
    if (orderData?.merchant_details?.location_id === "") {
      location_id = "added_manually";
    } else {
      location_id = orderData?.merchant_details?.location_id;
    }

    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/instant/check-availability/${location_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        const resultResonse = result.data.response;
        if (resultResonse?.qr_active) {
          setQrActive(true);
        }
        //setShowInstantPayout(response.data.response.instant_payment);
        if (orderData.settlement_period) {
          setSettlementMode(orderData.settlement_period);
        } else {
          setSettlementMode(resultResonse?.settlement_mode);
        }

        //check if result data response settlement mode is UPI
        setUpiDetails(resultResonse?.upi_details);
        setInstantUpi({
          ...instantUpi,
          payment_no: resultResonse?.payment_no,
          upi_type: resultResonse?.upi_type,
        });
        //else
        setInstantBank({
          ...instantBank,
          account_name: resultResonse?.account_name,
          bank_name: resultResonse?.bank_name,
          account_no: resultResonse?.account_no,
          ifsc_code: resultResonse?.ifsc_code,
        });
        //end if block
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  function generateTabLabel(val: string) {
    if (val) {
      let tabLabel = "";
      if (val === SETTLEMENT_MODE.INSTANT || SETTLEMENT_MODE.UPI) {
        tabLabel = "Instant payment";
      }
      if (val === SETTLEMENT_MODE.WEEKLY) {
        tabLabel = "Weekly payment";
      }
      if (val === SETTLEMENT_MODE.MONTHLY) {
        tabLabel = "Monthly payment";
      }
      if (val === SETTLEMENT_MODE.DAILY) {
        tabLabel = "Daily payment";
      }
      return tabLabel;
    }
    return null;
  }

  return (
    <Dialog
      open={invoicePayoutModal}
      onClose={() => setInvoicePayoutModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setInvoicePayoutModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
              Payout -{" "}
              {orderData?.order_partner === "dunzo"
                ? orderData?.merchant_details?.association_display_name
                : orderData?.merchant_details?.name}
            </Typography>
            {qrActive ? (
              <Tooltip title="MedPay QR Pharmacy">
                <QrCode2Icon
                  sx={{ mx: 0.5 }}
                  fontSize="small"
                  color="primary"
                />
              </Tooltip>
            ) : null}
          </Stack>
          {orderData && orderData.platform && (
            <OrderPlatform platform={orderData.platform} />
          )}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {providerInvoiceUpload && providerInvoiceUpload === "true" ? (
          <SelfOptNotes
            flag={providerInvoiceUpload}
            type="invoice"
            orgTime={parse(
              orderData.payment_confirmed_at?.split(".")[0],
              "dd/MM/yyyy HH:mm:ss",
              new Date()
            )}
            actionTaken={
              orderData.invoice_urls && orderData.invoice_urls.length > 0
            }
          />
        ) : orderData?.upi_whitelisting_status &&
          orderData?.upi_whitelisting_status === "pending" ? (
          <Typography variant="body1" sx={{ mt: 3 }}>
            UPI whitelisting is in pending state. Please contact{" "}
            <b>techsupport@medpay.in</b>.
          </Typography>
        ) : (
          <>
            {showPaymentForm ? (
              <>
                <Tabs value={value} onChange={handleChange} centered>
                  {settlementMode ? (
                    <Tab className="payment-tab" label="UPI Payment" />
                  ) : null}

                  {showInstantPaymentTab(
                    orderData.payment_credit_status,
                    settlementMode
                  ) ? (
                    <Tab
                      className="payment-tab"
                      label={generateTabLabel(settlementMode?.toLowerCase())}
                    />
                  ) : null}
                  <Tab className="payment-tab" label="Paid Offline" />
                </Tabs>
                {orderData?.order_type === ORDER_TYPE.IPD ? (
                  <TabPanel value={value} index={0}>
                    <IpdPayment
                      orderData={orderData}
                      lat={latitude}
                      long={longitude}
                      setInvoicePayoutModal={setInvoicePayoutModal}
                    />
                  </TabPanel>
                ) : (
                  <>
                    {settlementMode ? (
                      <TabPanel value={value} index={0}>
                        <UpiPayment
                          orderData={orderData}
                          lat={latitude}
                          long={longitude}
                          uploadedFiles={uploadedFiles}
                          setInvoicePayoutModal={setInvoicePayoutModal}
                          upiDetails={upiDetails}
                          qrActive={qrActive}
                        />
                      </TabPanel>
                    ) : null}
                    {showInstantPaymentTab(
                      orderData.payment_credit_status,
                      settlementMode
                    ) ? (
                      <TabPanel value={value} index={1}>
                        <InstantPayment
                          orderData={orderData}
                          instantBankDetails={instantBank}
                          lat={latitude}
                          long={longitude}
                          uploadedFiles={uploadedFiles}
                          settlementMode={settlementMode}
                          setInvoicePayoutModal={setInvoicePayoutModal}
                          mode={mode}
                        />
                      </TabPanel>
                    ) : null}
                    <TabPanel
                      value={value}
                      index={
                        showInstantPaymentTab(
                          orderData.payment_credit_status,
                          settlementMode
                        )
                          ? 2
                          : 1
                      }
                    >
                      <OfflinePayment
                        orderData={orderData}
                        lat={latitude}
                        long={longitude}
                        settlementMode={settlementMode}
                        setInvoicePayoutModal={setInvoicePayoutModal}
                      />
                    </TabPanel>
                  </>
                )}
              </>
            ) : (
              <>
                <UploadFiles
                  title="Upload Invoice"
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  orderId={orderData?.order_id}
                />
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mt: 3 }}
                >
                  <LoadingButton
                    type="button"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    disabled={uploadedFiles.length === 0}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    onClick={() => {
                      setShowPaymentForm(true);
                    }}
                    sx={{ mr: 2 }}
                  >
                    Submit
                  </LoadingButton>
                  <Typography
                    color="primary"
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setShowPaymentForm(true)}
                  >
                    Skip
                  </Typography>
                </Box>
              </>
            )}
            {/* hide: for ipd --OR--> last tab which is offline payment */}
            {orderData?.order_type === ORDER_TYPE.IPD ||
            value ===
              document.querySelectorAll(".payment-tab")?.length - 1 ? null : (
              <ThirdPartyPaymentButton
                orderData={orderData}
                setOrderData={setOrderData}
                thirdPartyModal={thirdPartyModal}
                setThirdPartyModal={setThirdPartyModal}
                setInvoicePayoutModal={setInvoicePayoutModal}
                paymentStatusModal={paymentStatusModal}
                setPaymentStatusModal={setPaymentStatusModal}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InvoicePayoutModal;
