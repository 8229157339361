import React from "react";
import { Box, Typography } from "@mui/material";
import OrderDoctorInfo from "../../pages/Settings/Orders/orderDoctorInfo";
import OrderLabInfo from "../../pages/Settings/Orders/orderLabInfo";
import OrderMedicines from "../../pages/Settings/Orders/orderMedicines";
import PrescriptionData from "./prescriptionData";
import { ORDER_STATUS, ORDER_TYPE } from "../../enums/order.enum";
import Divider from "@mui/material/Divider";
import OrderMedicinesInvalid from "../../pages/Settings/Orders/orderMedicinesInvalid";
import DunzoMedicinesView from "../../pages/Dunzo/dunzoMedicinesView";
import IpdItemView from "../../pages/IPD/ipdItemView";

const OrderData = ({ orderData, showHeading, notAdminPanel }: any) => {
  const getRequestedItems = (items: any) => {
    let requestedItem = [];

    if (!items || items.length === 0) {
      return [];
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i]?.requested_item) {
          requestedItem.push(items[i].requested_item);
        } else {
          requestedItem.push(items[i]);
        }
      }
      return requestedItem;
    }
  };

  return (
    <>
      {showHeading && (
        <Typography variant="h4">
          {orderData?.order_type === ORDER_TYPE.CONSULTATION
            ? "Clinic Details:"
            : "Order Details:"}
        </Typography>
      )}
      {orderData?.order_type === ORDER_TYPE.MEDICINES &&
      orderData?.order_partner === "dunzo" ? (
        <DunzoMedicinesView dunzoActiveOrder={orderData} />
      ) : (
        <>
          {orderData?.order_details?.doctor ? (
            <Typography pt={2}>
              <strong>Doctor: </strong>
              {orderData.order_details.doctor}
            </Typography>
          ) : null}

          {orderData?.order_type !== ORDER_TYPE.CONSULTATION && (
            <PrescriptionData
              prescription_links={
                orderData?.order_type === ORDER_TYPE.IPD
                  ? orderData.order_details?.reports_links
                  : orderData?.order_details?.prescription_links
              }
              component_label={
                orderData?.order_type === ORDER_TYPE.IPD
                  ? "Bills & Reports"
                  : "Prescriptions"
              }
            />
          )}

          {orderData.order_type === ORDER_TYPE.DIAGNOSTICS && (
            <Box sx={{ my: 3 }}>
              {orderData?.order_status === ORDER_STATUS.PARTNER_PENDING ||
              !notAdminPanel ? (
                <>
                  {orderData.old_order_details &&
                  orderData.old_order_details.length > 0 ? (
                    <>
                      <Typography variant="h5" textAlign="center" mt={3}>
                        Initial Order Details:
                      </Typography>
                      <OrderLabInfo
                        merchant_details={orderData?.requested_merchant_details}
                        time_slot={
                          orderData?.requested_merchant_details?.time_slot
                        }
                        tests={orderData?.old_order_details?.[0]?.items}
                        showTotal={notAdminPanel}
                        showMerchantInfo={showHeading}
                        orderType={orderData?.order_type}
                        time_slot_label="Requested sample collection time"
                      />
                    </>
                  ) : null}
                  {orderData.order_details ? (
                    <>
                      {orderData.old_order_details &&
                      orderData.old_order_details.length > 0 ? (
                        <>
                          <Box mb={3} mt={3}>
                            <Divider />
                          </Box>
                          <Typography variant="h5" textAlign="center" mt={3}>
                            Final Order Details:
                          </Typography>
                        </>
                      ) : null}
                      <OrderLabInfo
                        merchant_details={orderData?.merchant_details}
                        time_slot={orderData?.time_slot}
                        tests={orderData?.order_details?.items}
                        showTotal={notAdminPanel}
                        showMerchantInfo={showHeading}
                        orderType={orderData?.order_type}
                        time_slot_label="Sample collection time"
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <OrderLabInfo
                  merchant_details={orderData?.merchant_details}
                  time_slot={orderData?.time_slot}
                  tests={orderData?.order_details?.items}
                  showTotal={notAdminPanel}
                  showMerchantInfo={false}
                  orderType={orderData?.order_type}
                  time_slot_label="Requested sample collection time"
                />
              )}
            </Box>
          )}

          {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
            <>
              {orderData?.order_status === ORDER_STATUS.PARTNER_PENDING ||
              !notAdminPanel ? (
                <>
                  {orderData.merchant_details?.change_type ? (
                    <>
                      <Typography variant="h5" textAlign="center" mt={3}>
                        Requested Doctor Details:
                      </Typography>
                      <OrderDoctorInfo
                        doctor={orderData.requested_merchant_details}
                        time_slot={
                          orderData?.requested_merchant_details?.time_slot
                        }
                        time_slot_label="Requested appointment time"
                        showLink={true}
                      />
                    </>
                  ) : null}

                  {orderData.merchant_details ? (
                    <>
                      {orderData.merchant_details?.change_type ? (
                        <>
                          <Box mb={3} mt={3}>
                            <Divider />
                          </Box>

                          <Typography variant="h5" textAlign="center" mt={3}>
                            Available Doctor Details:
                          </Typography>
                        </>
                      ) : null}
                      <OrderDoctorInfo
                        doctor={orderData.merchant_details}
                        time_slot={orderData?.time_slot}
                        time_slot_label="Available time slot"
                        showLink={true}
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <OrderDoctorInfo
                  doctor={orderData.merchant_details}
                  time_slot={orderData?.time_slot}
                  time_slot_label="Requested appointment time"
                  showLink={true}
                />
              )}
            </>
          )}

          {orderData?.order_type === ORDER_TYPE.MEDICINES && (
            <>
              {orderData?.order_status === ORDER_STATUS.PARTNER_PENDING ||
              !notAdminPanel ? (
                <>
                  {orderData.old_order_details &&
                  orderData.old_order_details.length > 0 ? (
                    <>
                      <Typography variant="h5" textAlign="center" mt={3}>
                        Initial Order Details:
                      </Typography>
                      <OrderMedicines
                        medicines={getRequestedItems(
                          orderData?.old_order_details?.[0]?.items
                        )}
                        pharmacyUpdated={
                          !notAdminPanel //to show only in admin panel
                            ? orderData?.merchant_updated_meta?.[0]?.items ||
                              null
                            : null
                        }
                        orderType={orderData.order_type}
                        showTotal={false}
                        deliveryCharge={
                          //PARTNER SPECIFIC: medpay x3
                          ["medpay", "medpay_store"].includes(
                            orderData?.order_partner
                          )
                            ? orderData?.delivery_charges
                            : orderData?.partner_delivery_charges
                        }
                      />
                    </>
                  ) : null}
                  {orderData.order_details ? (
                    <>
                      {orderData.old_order_details &&
                      orderData.old_order_details.length > 0 ? (
                        <>
                          <Box mb={3} mt={3}>
                            <Divider />
                          </Box>
                          <Typography variant="h5" textAlign="center" mt={3}>
                            Final Order Details:
                          </Typography>
                        </>
                      ) : null}
                      <OrderMedicines
                        medicines={orderData?.order_details?.items}
                        pharmacyUpdated={
                          !notAdminPanel
                            ? orderData?.merchant_updated_meta?.[0]?.items ||
                              null
                            : null
                        }
                        orderType={orderData.order_type}
                        showTotal={false}
                        deliveryCharge={
                          ["medpay", "medpay_store"].includes(
                            orderData?.order_partner
                          )
                            ? orderData?.delivery_charges
                            : orderData?.partner_delivery_charges
                        }
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <OrderMedicines
                  medicines={orderData?.order_details?.items}
                  pharmacyUpdated={
                    !notAdminPanel
                      ? orderData?.merchant_updated_meta?.[0]?.items || null
                      : null
                  }
                  orderType={orderData.order_type}
                  showTotal={notAdminPanel}
                  deliveryCharge={
                    ["medpay", "medpay_store"].includes(
                      orderData?.order_partner
                    )
                      ? orderData?.delivery_charges
                      : orderData?.partner_delivery_charges
                  }
                />
              )}
              {/* PARTNER SPECIFIC: LOMBARD */}
              {orderData?.order_partner === "lombard" &&
              orderData.order_details?.invalid_items &&
              orderData.order_details.invalid_items.length !== 0 ? (
                <OrderMedicinesInvalid
                  medicines={orderData.order_details.invalid_items}
                />
              ) : null}
            </>
          )}

          {orderData?.order_type === ORDER_TYPE.IPD && (
            <IpdItemView
              invoiceDetails={orderData?.invoice_details}
              patientPayable={orderData?.patient_payable}
            />
          )}
        </>
      )}
    </>
  );
};

export default OrderData;
