import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../utils/common";
import PermissionsView from "../Roles/permissionsView";

const PermissionsModal = ({
  showPermissionsModal,
  setShowPermissionsModal,
  activeLoginId,
  setActiveLoginId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [permissionsData, setPermissionsData] = useState<any>(null);
  const privateInstance = useAxiosInstance();

  const getPermissionsByLoginId = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/auth/users/${activeLoginId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setPermissionsData(result.data.response?.permissions);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPermissionsByLoginId();
    return () => {
      setActiveLoginId("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog
        open={showPermissionsModal}
        onClose={() => setShowPermissionsModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowPermissionsModal(false)}
        >
          {"User Permissions"} | {activeLoginId}
        </CustomDialogTitle>
        <DialogContent sx={{ overflowY: "scroll" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>{permissionsData && <PermissionsView data={permissionsData} />}</>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PermissionsModal;
