import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { addDays, differenceInMonths, format, subDays } from "date-fns";
import React, { useState } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { errorToast, handleError, successToast } from "../../utils/common";
import CustomDialogTitle from "../../components/Common/customDialogTitle";

const EmailReportModalAppIssues = ({ show, setShow }: any) => {
  const [startDate, setStartDate] = useState<any>(subDays(new Date(), 6));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [locId, setLocId] = useState("");
  const [loading, setLoading] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const privateInstance = useAxiosInstance();

  const sendAppIssuesReport = async () => {
    if (differenceInMonths(endDate, startDate) > 4) {
      errorToast("Difference between two dates cannot be more than 4 months");
      return;
    }
    try {
      setLoading(true);
      let payload: any = {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        email: user?.user_id,
        job_type: "app_issues_report",
      };

      if (locId) {
        payload = {
          ...payload,
          location_id: locId,
        };
      }

      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/reports/all`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Email sent successfully!");
        setShow(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShow(false)}
        >
          App Issues Report
        </CustomDialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              inputFormat="dd/MM/yyyy"
              value={startDate}
              onChange={(newValue: Date | null) => {
                setStartDate(newValue);
                setEndDate(null);
              }}
              minDate={new Date("March 01, 2024")}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  name="start_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              inputFormat="dd/MM/yyyy"
              value={endDate}
              onChange={(newValue: Date | null) => {
                setEndDate(newValue);
              }}
              minDate={addDays(startDate, 0)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5 }}
                  name="end_date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" sx={{ fontSize: "10px !important" }}>
              Reports will be emailed on: {user?.user_id}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "10px !important" }}>
              1 Month
            </Typography>
          </Box>
          <TextField
            fullWidth
            id="loc_id"
            label="Location ID(optional)"
            size="small"
            value={locId}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onChange={(e) => setLocId(e.target.value)}
          />
          <Box textAlign="center" mt={3}>
            <LoadingButton
              color="primary"
              variant="contained"
              loading={loading}
              disabled={!startDate || !endDate}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={sendAppIssuesReport}
            >
              Submit
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailReportModalAppIssues;
