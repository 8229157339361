import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  ImageList,
  InputAdornment,
  Link,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import {
  errorToast,
  getIssueStatusBgColor,
  handleError,
  successToast,
  textCapitalize,
} from "../../utils/common";
import OrderAudits from "../Settings/Orders/orderAudits";
import KnowlarityCall from "../../components/Common/knowlarityCall";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ResponseTemplateModal from "./responseTemplateModal";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IssueMediaModal } from "./issueMediaModal";
import IssueMsgView from "./issueMsgView";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import IssueAttachmentsView from "./issueAttachmentsView";
import UtrTxnModal from "./utrTxnModal";
import ClearIcon from "@mui/icons-material/Clear";
import { medpayTeams } from "../../constants";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import UndoIcon from "@mui/icons-material/Undo";
import IssueTypeCategoryView from "./issueTypeCategoryView";
import BugReportIcon from "@mui/icons-material/BugReport";
import GroupIcon from "@mui/icons-material/Group";
import HistoryIcon from "@mui/icons-material/History";
import TranslateIcon from "@mui/icons-material/Translate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useIconStyles } from "../../components/Helpers/iconStyles";

const StatusRadio = ({ status, setStatus, setAnchorEl }: any) => {
  return (
    <RadioGroup
      row
      aria-labelledby="rx-label"
      name="rx-group"
      value={status}
      onChange={(e) => {
        setStatus(e.target.value);
        if (setAnchorEl) {
          setAnchorEl(false);
        }
      }}
    >
      <FormControlLabel
        value="in_process"
        control={<Radio size="small" />}
        label="In-Process"
        checked={status === "in_process"}
      />
      <FormControlLabel
        value="on_hold"
        control={<Radio size="small" />}
        label="On-Hold"
        checked={status === "on_hold"}
      />
      <FormControlLabel
        value="resolved"
        control={<Radio size="small" />}
        label="Resolved"
        checked={status === "resolved"}
      />
      <FormControlLabel
        value="reopened"
        control={<Radio size="small" />}
        label="Reopened"
        checked={status === "reopened"}
      />
    </RadioGroup>
  );
};

const ShareRadio = ({ noteFor, setNoteFor, setAnchorEl2 }: any) => {
  return (
    <RadioGroup
      row
      aria-labelledby="rx-label"
      name="rx-group"
      value={noteFor}
      onChange={(e) => {
        setNoteFor(e.target.value);
        if (setAnchorEl2) {
          setAnchorEl2(false);
        }
      }}
    >
      {typeOfNotes.map((x: string) => {
        return (
          <FormControlLabel
            key={x?.toLowerCase()}
            value={x?.toLowerCase()}
            control={<Radio size="small" />}
            label={
              <span
                style={{
                  borderLeft: `4px solid ${
                    x === "internal"
                      ? "#FFA411"
                      : x === "partner"
                      ? "#208A86"
                      : "#0058ff"
                  }`,
                  paddingLeft: "4px",
                }}
              >
                {x}
              </span>
            }
            checked={noteFor === x}
            sx={{ textTransform: "capitalize" }}
          />
        );
      })}
    </RadioGroup>
  );
};

const ModalTitleView = ({
  data,
  onClose,
  innerW,
  theme,
  showAuditView,
  setShowAuditView,
}: any) => {
  return (
    <>
      {data ? (
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          {innerW > theme.breakpoints.values.md ? (
            <Stack
              mr={5}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" gap={1.5}>
                <Typography variant="body1">{data?.issue_id}</Typography> |
                <Stack direction="row" alignItems="center" gap={1}>
                  <Link
                    target="_blank"
                    href={`/merchants/${data?.location_id}`}
                  >
                    <Typography variant="body1">
                      {data?.provider_name}
                    </Typography>
                  </Link>
                  {data?.whatsapp_number ? (
                    <KnowlarityCall
                      order={{
                        order_id: data?.issue_id,
                        mobile: data?.whatsapp_number,
                      }}
                      showNumber={false}
                      renderSmallIcon={true}
                      service="app-issues"
                    />
                  ) : null}
                </Stack>
                <Chip
                  size="small"
                  label={data?.issue_status?.split("_")?.join(" ")}
                  sx={{
                    backgroundColor: getIssueStatusBgColor(data?.issue_status),
                    padding: "6px",
                    color: ["open", "reopened"].includes(data?.issue_status)
                      ? "#fff"
                      : "#000",
                  }}
                />
              </Stack>
              <Button
                size="small"
                variant="outlined"
                endIcon={
                  showAuditView ? <UndoIcon /> : <KeyboardArrowRightIcon />
                }
                onClick={() => setShowAuditView(!showAuditView)}
              >
                {showAuditView ? "Go Back" : "Audit"}
              </Button>
            </Stack>
          ) : (
            <>
              <Stack
                gap={1.5}
                mr={4}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ fontSize: "14px" }} variant="body1">
                  {data?.issue_id}
                </Typography>
                <Chip
                  size="small"
                  label={data?.issue_status?.split("_")?.join(" ")}
                  sx={{
                    backgroundColor: getIssueStatusBgColor(data?.issue_status),
                    padding: "6px",
                    color: ["open", "reopened"].includes(data?.issue_status)
                      ? "#fff"
                      : "#000",
                  }}
                />
              </Stack>
              <Stack
                gap={1.5}
                mt={1.5}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  <Link
                    target="_blank"
                    href={`/merchants/${data?.location_id}`}
                  >
                    <Typography sx={{ fontSize: "14px" }} variant="body1">
                      {data?.provider_name}
                    </Typography>
                  </Link>
                  {data?.whatsapp_number ? (
                    <KnowlarityCall
                      order={{
                        order_id: data?.issue_id,
                        mobile: data?.whatsapp_number,
                      }}
                      showNumber={false}
                      renderSmallIcon={true}
                      service="app-issues"
                    />
                  ) : null}
                </Stack>
                <Button
                  sx={{ minWidth: "fit-content" }}
                  size="small"
                  variant="outlined"
                  endIcon={
                    showAuditView ? <UndoIcon /> : <KeyboardArrowRightIcon />
                  }
                  onClick={() => setShowAuditView(!showAuditView)}
                >
                  {showAuditView ? "Go Back" : "Audit"}
                </Button>
              </Stack>
            </>
          )}
        </CustomDialogTitle>
      ) : null}
    </>
  );
};

const DescriptionActionView = ({ data }: any) => {
  const [utrTxnData, setUtrTxnData] = useState(null);
  const [utrTxnModal, setUtrTxnModal] = useState(false);

  return (
    <Stack px={{ xs: 0, sm: 3 }}>
      <Typography variant="body1">
        <span style={{ color: "#33451C" }}> Issue Description:</span>{" "}
        {data?.description?.text}
      </Typography>
      <Box pt={2}>
        <Divider />
      </Box>
      {data?.description?.images?.length ? (
        <ImageList cols={3}>
          {data.description?.images?.map((item: string, index: number) => (
            <IssueAttachmentsView
              key={index}
              item={item}
              issueType={data?.issue_type}
              setUtrTxnData={setUtrTxnData}
              setUtrTxnModal={setUtrTxnModal}
            />
          ))}
        </ImageList>
      ) : null}
      {utrTxnModal ? (
        <UtrTxnModal
          open={utrTxnModal}
          onClose={() => setUtrTxnModal(false)}
          data={utrTxnData}
        />
      ) : null}
      {data?.respondent_actions?.length > 0 ? (
        <>
          <Typography mt={2} variant="h6">
            Respondent Action:
          </Typography>
          <Box
            className="hideScrollBar"
            sx={{ maxHeight: "350px", overflowY: "scroll" }}
          >
            {data.respondent_actions.map((x: any, i: number) => {
              return <IssueMsgView key={i} x={x} />;
            })}
          </Box>
        </>
      ) : (
        <Box sx={{ minHeight: "150px", my: 3 }}>
          <Alert severity="info">No Action Taken</Alert>
        </Box>
      )}
    </Stack>
  );
};

const typeOfNotes = ["internal", "partner", "techsupport"];

const IssueDetailModal2 = ({
  open,
  onClose,
  data,
  activeIssueId,
  getIssuesList,
  updateIssuesData,
  setShowIssueHistoryModal,
}: any) => {
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(data?.issue_status || "");
  const [issueTeam, setIssueTeam] = useState(data?.assigned_team || "");
  const [issueFollowUp, setIssueFollowUp] = useState<any>(
    data?.follow_up_date
      ? parse(data?.follow_up_date, "dd-MM-yyyy", new Date())
      : null
  );
  const [noteFor, setNoteFor] = useState("");
  const [textContent, setTextContent] = useState("");
  const [template, setTemplate] = useState(false);
  const [media, setMedia] = useState(false);
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const [showAuditView, setShowAuditView] = useState(false);
  const [showFirstCol, setShowFirstCol] = useState(false);
  const innerW = window.innerWidth;
  const theme = useTheme();
  const iconClasses = useIconStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLButtonElement | null>(null);
  const [dataById, setDataById] = useState<any>(null);

  const getIssueByIssueId = async () => {
    let url = `${API_VERSION.V1}/issues/${activeIssueId}`;
    setFetching(true);
    try {
      const result = await privateInstance.get(url);
      if (result.data?.status === API_RESULT.SUCCESS) {
        const resultData = result.data.response;
        //add to main list, update local states
        setDataById(resultData);
        setIssueTeam(resultData?.assigned_team);
        setStatus(resultData?.issue_status);
        setIssueFollowUp(
          resultData?.follow_up_date
            ? parse(resultData?.follow_up_date, "dd-MM-yyyy", new Date())
            : null
        );
      }
    } catch (err) {
      handleError(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!data) {
      getIssueByIssueId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (innerW > theme.breakpoints.values.md) {
      setShowFirstCol(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerW]);

  const updateIssue = async () => {
    let payload: any = {
      issue_status: status,
      response: {
        text: textCapitalize(textContent),
        images: uploadedMedia,
        updated_by: user?.user_id,
        visibility: noteFor,
      },
    };

    setLoading(true);

    try {
      const result = await privateInstance.patch(
        `${API_VERSION.V1}/issues/${data?.issue_id}`,
        payload
      );
      if (result.data?.status === API_RESULT.SUCCESS) {
        successToast("Updated Successfully");
        onClose();
        if (setShowIssueHistoryModal) setShowIssueHistoryModal(false);
        //update
        getIssuesList();
      } else {
        errorToast(result?.data?.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <ModalTitleView
        data={data || dataById}
        onClose={onClose}
        innerW={innerW}
        theme={theme}
        showAuditView={showAuditView}
        setShowAuditView={setShowAuditView}
      />
      <DialogContent>
        {fetching ? (
          <CircularProgress />
        ) : (
          <>
            {showAuditView ? (
              <OrderAudits
                oldAuditLogs={[]}
                orderId={data?.issue_id || dataById?.issue_id}
              />
            ) : (
              <>
                <Grid mt={3} container spacing={1}>
                  {showFirstCol ? (
                    <Grid
                      item
                      xs={12}
                      md={3.5}
                      sx={{
                        padding: { sm: "0px !important" },
                      }}
                    >
                      <Stack px={{ xs: 0, sm: 3 }}>
                        {/* issue type and category */}
                        <Stack pb={0.5} gap={0.5} direction="row">
                          <BugReportIcon
                            sx={{ color: "#667185" }}
                            fontSize="small"
                          />
                          <Typography sx={{ color: "#667185" }} variant="body2">
                            Issue Type
                          </Typography>
                        </Stack>
                        <IssueTypeCategoryView
                          issueId={data?.issue_id}
                          issueCategory={data?.issue_sub_type || ""}
                          updateIssuesData={updateIssuesData}
                          index={0}
                          importedOn="modal"
                          isDisabled={data?.issue_status === "resolved"}
                        />
                        {/* assigned team */}
                        <Stack mt={3} pb={0.5} gap={0.5} direction="row">
                          <GroupIcon
                            sx={{ color: "#667185" }}
                            fontSize="small"
                          />
                          <Typography sx={{ color: "#667185" }} variant="body2">
                            Assigned Team
                          </Typography>
                        </Stack>
                        <FormControl
                          disabled={data?.issue_status === "resolved"}
                          size="small"
                        >
                          <Select
                            displayEmpty
                            labelId="team-label"
                            name="team"
                            id="team"
                            value={issueTeam}
                            onChange={(e) => {
                              setIssueTeam(e.target.value);
                              //update BE and prop data
                              updateIssuesData(data?.issue_id, {
                                assigned_team: e.target.value,
                              });
                            }}
                            sx={{ fontSize: "12px !important" }}
                          >
                            {medpayTeams.map((x, i) => {
                              return (
                                <MenuItem
                                  disabled={i > 2}
                                  key={i}
                                  value={x.value}
                                >
                                  {x.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {/* follow up */}
                        <Stack mt={3} pb={0.5} gap={0.5} direction="row">
                          <HistoryIcon
                            sx={{ color: "#667185" }}
                            fontSize="small"
                          />
                          <Typography sx={{ color: "#667185" }} variant="body2">
                            Follow Up
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disabled={data?.issue_status === "resolved"}
                              inputFormat="dd-MM-yyyy"
                              mask={"__-__-____"}
                              value={issueFollowUp}
                              onChange={(newValue: Date | null) => {
                                if (newValue) {
                                  setIssueFollowUp(newValue);
                                  //prevent calling api onclose
                                  if (
                                    format(newValue as Date, "dd-MM-yyyy") ===
                                    data?.follow_up_date
                                  ) {
                                    return false;
                                  }
                                  //update BE and prop data
                                  updateIssuesData(data?.issue_id, {
                                    follow_up_date: format(
                                      newValue as Date,
                                      "dd-MM-yyyy"
                                    ),
                                  });
                                }
                              }}
                              minDate={
                                issueFollowUp ? issueFollowUp : new Date()
                              }
                              InputProps={{
                                sx: {
                                  fontSize: "12px !important",
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  size="small"
                                  name="date"
                                  autoComplete="off"
                                  onKeyDown={(e: any) => {
                                    e.preventDefault();
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          {issueFollowUp ? (
                            <IconButton
                              color="error"
                              onClick={() => {
                                if (window.confirm("Are you sure?")) {
                                  updateIssuesData(data?.issue_id, {
                                    follow_up_date: "",
                                  });
                                  setIssueFollowUp(null);
                                }
                              }}
                            >
                              <Tooltip title="Remove Follow Up">
                                <ClearIcon />
                              </Tooltip>
                            </IconButton>
                          ) : null}
                        </Stack>
                        {/* language */}
                        {data?.support_language_preferences?.length > 0 ? (
                          <>
                            <Stack mt={3} pb={0.5} gap={0.5} direction="row">
                              <TranslateIcon
                                sx={{ color: "#667185" }}
                                fontSize="small"
                              />
                              <Typography
                                sx={{ color: "#667185" }}
                                variant="body2"
                              >
                                Language
                              </Typography>
                            </Stack>
                            <Stack
                              mt={0.5}
                              direction="row"
                              flexWrap="wrap"
                              gap={0.75}
                            >
                              {data.support_language_preferences.map(
                                (item: any, index: number) => (
                                  <Chip
                                    variant="outlined"
                                    key={index}
                                    label={item}
                                    sx={{
                                      width: "fit-content",
                                      borderColor: "#EAEBEE !important",
                                      color: "#001233",
                                    }}
                                  />
                                )
                              )}
                            </Stack>
                          </>
                        ) : null}
                      </Stack>
                    </Grid>
                  ) : null}
                  <Grid
                    sx={{
                      borderLeft: { sm: "2px solid #EAEBEE" },
                      padding: { sm: "0px !important" },
                    }}
                    item
                    xs={12}
                    md={8.5}
                  >
                    {innerW > theme.breakpoints.values.md ? null : (
                      <>
                        {showFirstCol ? (
                          <>
                            <IconButton
                              className={iconClasses.borderedIconSmall}
                              sx={{ ml: 1.5, mb: -1.5, background: "#fff" }}
                              onClick={() => setShowFirstCol(false)}
                            >
                              <ExpandLessIcon />
                            </IconButton>
                            <Divider />
                          </>
                        ) : (
                          <>
                            <Divider />

                            <IconButton
                              className={iconClasses.borderedIconSmall}
                              sx={{ ml: 1.5, mt: -3, background: "#fff" }}
                              onClick={() => setShowFirstCol(true)}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </>
                        )}
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        mt: { xs: showFirstCol ? 3 : 0, md: 0 },
                      }}
                    >
                      <DescriptionActionView data={data || dataById} />
                      <>
                        {data?.issue_status === "resolved" ? null : (
                          <>
                            {innerW > theme.breakpoints.values.md ? (
                              <Stack
                                py={1.5}
                                px={3}
                                sx={{ backgroundColor: "#F0F1F5" }}
                              >
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 1.5,
                                  }}
                                >
                                  <FormLabel
                                    style={{ color: "#7C7C7C" }}
                                    id="rx-label"
                                  >
                                    Status:
                                  </FormLabel>
                                  <StatusRadio
                                    status={status}
                                    setStatus={setStatus}
                                    setAnchorEl={null}
                                  />
                                </FormControl>
                                <FormControl
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 1.5,
                                  }}
                                >
                                  <FormLabel
                                    style={{ color: "#7C7C7C" }}
                                    id="rx-label"
                                  >
                                    Share:&nbsp;
                                  </FormLabel>
                                  <ShareRadio
                                    noteFor={noteFor}
                                    setNoteFor={setNoteFor}
                                    setAnchorEl2={null}
                                  />
                                </FormControl>
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={2}
                                  id="text-content"
                                  label="Text"
                                  size="small"
                                  value={textContent}
                                  onChange={(e) =>
                                    setTextContent(e.target.value)
                                  }
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                  sx={{ mt: 1.5 }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          color="primary"
                                          size="small"
                                          onClick={() => setTemplate(true)}
                                        >
                                          <ContentPasteIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          sx={{ ml: 1.5 }}
                                          color="primary"
                                          size="small"
                                          onClick={() => setMedia(true)}
                                        >
                                          <AttachFileIcon fontSize="small" />
                                        </IconButton>
                                        {uploadedMedia?.length > 0
                                          ? `(${uploadedMedia.length})`
                                          : null}
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <LoadingButton
                                          size="medium"
                                          color="primary"
                                          loading={loading}
                                          loadingIndicator={
                                            <CircularProgress
                                              size={20}
                                              sx={{ color: "#fff" }}
                                            />
                                          }
                                          variant="contained"
                                          type="button"
                                          onClick={updateIssue}
                                          disabled={
                                            !status ||
                                            !noteFor ||
                                            !textContent?.trim()
                                          }
                                        >
                                          Update
                                        </LoadingButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Stack>
                            ) : (
                              <Stack
                                py={1.5}
                                px={2}
                                sx={{
                                  backgroundColor: "#F0F1F5",
                                  position: "absolute",
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                }}
                              >
                                <Stack
                                  mb={0.5}
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  <Stack direction="row" gap={1}>
                                    <Button
                                      size="small"
                                      aria-describedby="id-status"
                                      variant={
                                        anchorEl ? "contained" : "outlined"
                                      }
                                      onClick={(e) =>
                                        setAnchorEl(e.currentTarget)
                                      }
                                    >
                                      Status
                                    </Button>
                                    <Popover
                                      id="id-status"
                                      open={Boolean(anchorEl)}
                                      anchorEl={anchorEl}
                                      onClose={() => setAnchorEl(null)}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Box p={1}>
                                        <StatusRadio
                                          status={status}
                                          setStatus={setStatus}
                                          setAnchorEl={setAnchorEl}
                                        />
                                      </Box>
                                    </Popover>
                                    <Button
                                      size="small"
                                      aria-describedby="id-share"
                                      variant={
                                        anchorEl2 ? "contained" : "outlined"
                                      }
                                      onClick={(e) =>
                                        setAnchorEl2(e.currentTarget)
                                      }
                                    >
                                      Share
                                    </Button>
                                    <Popover
                                      id="id-share"
                                      open={Boolean(anchorEl2)}
                                      anchorEl={anchorEl2}
                                      onClose={() => setAnchorEl2(null)}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}
                                    >
                                      <Box p={1}>
                                        <ShareRadio
                                          noteFor={noteFor}
                                          setNoteFor={setNoteFor}
                                          setAnchorEl2={setAnchorEl2}
                                        />
                                      </Box>
                                    </Popover>
                                  </Stack>
                                  <LoadingButton
                                    size="medium"
                                    color="primary"
                                    loading={loading}
                                    loadingIndicator={
                                      <CircularProgress
                                        size={20}
                                        sx={{ color: "#fff" }}
                                      />
                                    }
                                    variant="contained"
                                    type="button"
                                    onClick={updateIssue}
                                    disabled={!status || !textContent}
                                  >
                                    Update
                                  </LoadingButton>
                                </Stack>
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={2}
                                  id="text-content"
                                  label="Text"
                                  size="small"
                                  value={textContent}
                                  onChange={(e) =>
                                    setTextContent(e.target.value)
                                  }
                                  InputLabelProps={{
                                    required: true,
                                  }}
                                  sx={{ mt: 1.5 }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          color="primary"
                                          size="small"
                                          onClick={() => setTemplate(true)}
                                        >
                                          <ContentPasteIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          sx={{ ml: 1.5 }}
                                          color="primary"
                                          size="small"
                                          onClick={() => setMedia(true)}
                                        >
                                          <AttachFileIcon fontSize="small" />
                                        </IconButton>
                                        {uploadedMedia?.length > 0
                                          ? `(${uploadedMedia.length})`
                                          : null}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Stack>
                            )}
                          </>
                        )}
                        {template ? (
                          <ResponseTemplateModal
                            open={template}
                            onClose={() => setTemplate(false)}
                            setTextContent={setTextContent}
                            issueType={data?.issue_type}
                          />
                        ) : null}
                        {media ? (
                          <IssueMediaModal
                            open={media}
                            onClose={() => setMedia(false)}
                            activeLocationId={data?.location_id}
                            uploadType="issue"
                            uploadedFiles={uploadedMedia}
                            setUploadedFiles={setUploadedMedia}
                            mode="edit"
                          />
                        ) : null}
                      </>
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default IssueDetailModal2;
