import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "80vh" }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="body1">Page Not Found</Typography>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="primary"
        onClick={() => navigate("/", { replace: true })}
      >
        Back to home
      </Button>
    </Stack>
  );
}

export default NotFound;
