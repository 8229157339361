import { Autocomplete, Stack, TextField } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { debounce } from "lodash";
import axios from "axios";
import config from "../../config";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { handleError } from "../../utils/common";

const SearchLocality = ({
  autocompleteLabel,
  orderData,
  setFieldValue,
}: any) => {
  const [options, setOptions] = useState([]);
  const privateInstance = useAxiosInstance();
  const [input, setInput] = useState<string | null>(null);
  const [selectedValueFromSearch, setSelectedValueFromSearch] =
    useState<any>(null);
  const [autoCompleteSwitch, setAutoCompleteSwitch] = useState<boolean>(false);

  const getSearchData = async (inputValue: string) => {
    const result = await privateInstance.get(
      `${API_VERSION.V1}/gmap/autocomplete?location=${orderData?.customer_details?.address_details?.coordinates}&input=${inputValue}&components=country:in`
    );
    if (result?.data?.status === API_RESULT.OK) {
      setOptions(result.data.predictions);
    } else {
      setOptions([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getSearchData, 500), []);

  useEffect(() => {
    if (input) {
      setAutoCompleteSwitch(true);
      debounceFn(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const loadData = async (placeId: string) => {
    try {
      const result: any = await axios.get(
        `${config.URL}${API_VERSION.V1}/gmap/details?place_id=${placeId}`
      );
      if (result.data?.status) {
        const placeCoord = result.data?.response?.location;
        if (autocompleteLabel?.toLowerCase()?.includes("merchant")) {
          setFieldValue("merchant_lat", placeCoord?.lat);
          setFieldValue("merchant_long", placeCoord?.lon);
        } else {
          setFieldValue("customer_lat", placeCoord?.lat);
          setFieldValue("customer_long", placeCoord?.lon);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <ClickAwayListener onClickAway={() => setAutoCompleteSwitch(false)}>
          <Autocomplete
            open={autoCompleteSwitch}
            ListboxProps={{ style: { maxHeight: "14rem" } }}
            size="small"
            sx={{
              width: "100%",
              mt: 1.5,
            }}
            value={selectedValueFromSearch}
            onChange={(e, val: any) => {
              loadData(val.place_id);
              setSelectedValueFromSearch(val);
              //set locality values
              setFieldValue(
                autocompleteLabel?.toLowerCase()?.includes("merchant")
                  ? "merchant_locality"
                  : "customer_locality",
                val?.description
              );
            }}
            onInputChange={(e: any) => setInput(e?.target?.value)}
            //onSelect={(e: any) => setSelectedValueFromSearch(null)}
            id="nearby"
            options={options}
            renderInput={(params) => (
              <TextField
                name="locality-input"
                label={autocompleteLabel}
                {...params}
                onPaste={(e: any) => {
                  e.preventDefault();
                }}
                onCopy={(e: any) => {
                  e.preventDefault();
                }}
              />
            )}
            noOptionsText={"No data found"}
            getOptionLabel={(option) => option.description}
          />
        </ClickAwayListener>
      </Stack>
    </>
  );
};

export default SearchLocality;
