import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import PriorityIcon from "@mui/icons-material/Stars";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { infoToast, pendingStates } from "../../utils/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ORDER_STATUS } from "../../enums/order.enum";
import PendingIcon from "@mui/icons-material/Pending";
import { formatDistance, parse } from "date-fns";
import SwipeRightAltIcon from "@mui/icons-material/SwipeRightAlt";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import ExpressDelIcon from "@mui/icons-material/OfflineBolt";

const OrderBadges = ({
  priority,
  order_status,
  partner_order_id,
  created_at = null,
  express_delivery,
}: any) => {
  const isOlderThan2Days = () => {
    let days = formatDistance(
      new Date(),
      parse(created_at?.split(".")[0], "dd/MM/yyyy HH:mm:ss", new Date())
    );
    if (days.includes("day") || days.includes("days")) {
      let number = Number.parseInt(days);
      if (number >= 2 && pendingStates.includes(order_status)) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {created_at && isOlderThan2Days() ? (
        <Tooltip title="Older than 2 or more days">
          <StopCircleIcon sx={{ ml: 0.25 }} color="info" fontSize="small" />
        </Tooltip>
      ) : null}
      {order_status?.toLowerCase() === ORDER_STATUS.PACKAGE_PICKED && (
        <Tooltip title="Package Picked">
          <SwipeRightAltIcon sx={{ ml: 0.25 }} color="primary" />
        </Tooltip>
      )}
      {priority?.toLowerCase() === "high" && (
        <Tooltip title="Priority Order">
          <PriorityIcon sx={{ ml: 0.25 }} color="success" fontSize="small" />
        </Tooltip>
      )}
      {order_status?.toLowerCase() === ORDER_STATUS.PARTNER_PENDING && (
        <Tooltip title="Partner Confirmation Pending">
          <PendingIcon sx={{ ml: 0.25 }} color="warning" fontSize="small" />
        </Tooltip>
      )}
      {express_delivery && express_delivery === "y" ? (
        <Tooltip title="Express Delivery">
          <ExpressDelIcon sx={{ ml: 0.25 }} color="primary" fontSize="small" />
        </Tooltip>
      ) : null}
      <CopyToClipboard
        text={partner_order_id}
        onCopy={() => infoToast("Copied to clipboard")}
      >
        <Tooltip title="Copy to clipboard">
          <IconButton size="small" sx={{ ml: 0.25 }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    </Box>
  );
};

export default OrderBadges;
