import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import { NestedMenuItem } from "mui-nested-menu";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";

const data = [
  {
    type: "Soundbox",
    categories: [
      "Require Soundbox",
      "Soundbox Issue",
      "Notification Delays",
      "Battery/ Charging Issues",
      "Poor Sound Quality",
      "Notification Received On Failed Payment",
      "Connectivity Issues",
      "Others",
    ],
  },
  {
    type: "Payout",
    categories: [
      "Settlement Not Done",
      "Delayed Settlement",
      "Missing Transaction",
      "Failed Payment",
      "General Payment Inquiry",
      "Partial Payout",
      "Online Order Payment",
      "Credit Card Payments",
    ],
  },
  {
    type: "QR code",
    categories: ["QR Name Change", "QR Not Scanning", "QR Damaged"],
  },
  {
    type: "App",
    categories: [
      "No App Notifications",
      "Notification Delays",
      "Notification Sound Issues",
      "Transaction History",
      "App Crashes",
      "Network Connectivity",
      "Slow Payment Processing",
    ],
  },
  {
    type: "Account",
    categories: [
      "Account Deactivation",
      "Bank Account Update",
      "KYC Verification",
    ],
  },
  {
    type: "Order",
    categories: [
      "Not Getting Leads",
      "Payment Not Processed",
      "Book Rider Feature Related",
    ],
  },
];

const IssueTypeCategoryView = ({
  issueId,
  issueCategory,
  updateIssuesData,
  index,
  importedOn,
  isDisabled,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [value, setValue] = React.useState(issueCategory);
  const selectLabel =
    `${
      data?.find((x) => x.categories.includes(issueCategory))?.type
    } | ${issueCategory}` || "Issue Type";

  const handleClick = (event: any) => {
    return setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const setSelectValue = (typeVal: string, categoryVal: string) => {
    setValue(categoryVal);
    handleClose();
    updateIssuesData(issueId, {
      issue_type: typeVal,
      issue_sub_type: categoryVal,
    });
  };

  return (
    <FormControl
      disabled={isDisabled}
      sx={{
        minWidth: importedOn === "listing" ? "140px" : "auto",
        maxWidth: importedOn === "listing" ? "260px" : "auto",
        width: importedOn === "listing" ? "fit-content" : "100%",
      }}
      size="small"
    >
      <Select
        IconComponent={() => (
          <EditIcon fontSize="small" sx={{ mr: 1, fontSize: "16px" }} />
        )}
        labelId={`type-label-${index}`}
        name={`type-label-${index}`}
        id={`type-label-${index}`}
        displayEmpty
        value={value}
        open={open}
        onOpen={handleClick}
        onClose={handleClose}
        //   onChange={(e) => {
        //     setValue(e.target.value);
        //   }}
        sx={{
          borderRadius: importedOn === "listing" ? "25px" : "4px",
          fontSize: "12px !important",
          "& .MuiSelect-select": {
            paddingRight: "14px !important",
          },
        }}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <span style={{ color: "#667185" }}>Issue Type</span>;
          }
          return selectLabel;
        }}
      >
        {data
          .map((l) => l.categories)
          ?.flat()
          ?.map((d, i) => (
            <MenuItem sx={{ display: "none" }} value={d} key={i}>
              {d}
            </MenuItem>
          ))}

        {data.map((item: any) => {
          return (
            <Stack key={item.type}>
              <NestedMenuItem
                sx={{
                  backgroundColor: selectLabel.includes(item.type)
                    ? "#E6EEFF"
                    : "",
                }}
                label={item.type}
                parentMenuOpen={open}
              >
                {item.categories.map((subItem: string) => {
                  return (
                    <MenuItem
                      key={subItem}
                      data-value={subItem}
                      onClick={(e) => {
                        setSelectValue(
                          item.type,
                          String(e.currentTarget.dataset.value)
                        );
                      }}
                    >
                      {subItem}
                    </MenuItem>
                  );
                })}
              </NestedMenuItem>
            </Stack>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default IssueTypeCategoryView;
