import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { deliveryPartnerTypes } from "../../../../../constants";
import MedicinesTable from "../../../../../pages/Pharmacy/Create/medicinesTable";
import SearchAutoComplete from "../../../../CreateOrder/searchAutoComplete";
import DiscountPercent from "../../Common/discountPercent";
import ClearIcon from "@mui/icons-material/Clear";

const PharmacyInvoice = ({
  selectedMeds,
  setSelectedMeds,
  formData,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  orderData,
  medTableError,
  setMedTableError,
}: any) => {
  const [selectedMedicinesId, setSelectedMedicinesId] = useState<any>([]);
  const [altMedicineIndex, setAltMedicineIndex] = useState<any>(null);
  const {
    discount,
    final_payable,
    doctor_name,
    delivery_partner,
    partner_delivery_charges,
    gst_no,
  } = formData;

  const [updatedSubTotal, setUpdatedSubTotal] = useState(0);

  useEffect(() => {
    let subT: number = 0;
    selectedMeds.forEach((element: any) => {
      subT += Number(element.quantity) * Number(element.MRP);
    });
    setUpdatedSubTotal(subT);
  }, [selectedMeds]);

  return (
    <>
      <Stack direction="row" mt={2}>
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          error={
            errors.delivery_partner && touched.delivery_partner ? true : false
          }
        >
          <InputLabel id="delivery-partner-label">Delivery Partner</InputLabel>
          <Select
            labelId="delivery-partner-label"
            name="delivery_partner"
            id="delivery_partner"
            value={delivery_partner}
            label="Delivery Partner"
            onChange={(e: SelectChangeEvent) => {
              setFieldValue("delivery_partner", e.target.value);
            }}
            onBlur={handleBlur}
          >
            {Object.entries(deliveryPartnerTypes).map(([key, value]: any) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          {errors.delivery_partner && touched.delivery_partner && (
            <FormHelperText>{errors.delivery_partner}</FormHelperText>
          )}
        </FormControl>
        <TextField
          fullWidth
          id="delivery_charges"
          label="Delivery charges"
          size="small"
          value={partner_delivery_charges}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          disabled
        />
      </Stack>
      <Stack direction="row" mt={2}>
        <Box width="33%">
          <TextField
            fullWidth
            id="gst_no"
            label="GST NO"
            size="small"
            value={gst_no}
            onChange={(e) => setFieldValue("gst_no", e.target.value)}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          />
        </Box>
        <Box width="67%" sx={{ ml: { xs: 0, md: 1 } }}>
          <DiscountPercent
            discount={discount}
            final_payable={final_payable}
            total={updatedSubTotal}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Stack>
      <TextField
        fullWidth
        id="doctor_name"
        label="Doctor"
        size="small"
        value={doctor_name || ""}
        onChange={(e) => setFieldValue("doctor_name", e.target.value)}
        sx={{ mt: 2, mr: { xs: 0, md: 1, width: "99.2%" } }}
      />
      {selectedMeds && (
        <>
          <Typography variant="h4">Medicine Details:</Typography>
          {altMedicineIndex != null && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mb: -3 }}
            >
              <Typography>
                Adding alternative of: {selectedMeds[altMedicineIndex].item}
              </Typography>
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{ ml: 2 }}
                onClick={() => setAltMedicineIndex(null)}
              >
                <ClearIcon />
              </Button>
            </Stack>
          )}
          <SearchAutoComplete
            sx={{ my: 5 }}
            selectedValues={selectedMeds}
            setSelectedValues={setSelectedMeds}
            selectedValuesId={selectedMedicinesId}
            setSelectedValuesId={setSelectedMedicinesId}
            autocompleteLabel="Medicines"
            isEditing={true}
            altMedicineIndex={altMedicineIndex}
            setAltMedicineIndex={setAltMedicineIndex}
          />
          <MedicinesTable
            data={selectedMeds}
            setSelectedMedicines={setSelectedMeds}
            selectedMedicinesId={selectedMedicinesId}
            setSelectedMedicinesId={setSelectedMedicinesId}
            isEditing={true}
            altMedicineIndex={altMedicineIndex}
            setAltMedicineIndex={setAltMedicineIndex}
            orderMerchantMeta={orderData?.merchant_updated_meta}
            orderItems={orderData?.order_details?.items}
            medTableError={medTableError}
            setMedTableError={setMedTableError}
            orderPartner={orderData?.order_partner}
            orderStatus={orderData?.order_status}
          />
        </>
      )}
    </>
  );
};

export default PharmacyInvoice;
