import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { QrUpiIdAndNameView } from "../../../components/ManageOrder/GroupOrders/Payment/upiPayment";
import {
  API_RESULT,
  API_VERSION,
  SETTLEMENT_MODE,
} from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  updateOtherPaymentDetailsSchema,
  updateUPIPaymentDetailsSchema,
} from "../../../schemas/dunzoproviders.schema";
import { errorToast, handleError } from "../../../utils/common";
import BankDetailsView from "./bankDetailsView";

const EditBankDetailsModal = ({
  showEditBankDetailsModal,
  setShowEditBankDetailsModal,
  providerInfo,
  setProviderInfo,
  providersData,
  setProvidersData,
  providerType,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [qrData, setQrData] = useState<any>(null);
  const [settlementMode, setSettlementMode] = useState<string>(
    providerInfo?.settlement_mode || ""
  );
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      location_id: providerInfo?.location_id,
      update_type: "account_details",
      settlement_mode: settlementMode.toUpperCase(),
    };

    if (settlementMode?.toLowerCase() === SETTLEMENT_MODE.UPI) {
      payload = {
        ...payload,
        upi_id: values?.upi_id,
        upi_name: values?.upi_name,
      };
    } else {
      payload = {
        ...payload,
        account_name: values?.account_name,
        account_no: values?.account_no,
        bank_name: values?.bank_name,
        branch_name: values?.branch_name,
        ifsc_code: values?.ifsc_code,
      };
    }

    setLoading(true);

    try {
      const result = await privateInstance.put(
        `${API_VERSION.V1}/orders${
          providerType === "dunzo" ? "/dunzo/" : "/"
        }provider`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setShowEditBankDetailsModal(false);
        const resultResponse = result.data.response;

        //update provider info in modal
        if (settlementMode?.toLowerCase() === SETTLEMENT_MODE.UPI) {
          setProviderInfo({
            ...providerInfo,
            upi_id: values?.upi_id,
            upi_name: values?.upi_name,
          });
        } else {
          setProviderInfo({
            ...providerInfo,
            account_name: resultResponse?.account_name,
            account_no: resultResponse?.account_no,
            bank_name: resultResponse?.bank_name,
            branch_name: resultResponse?.branch_name,
            ifsc_code: resultResponse?.ifsc_code,
          });
        }
        //end

        //update settlement mode in main list, for dunzo providers only
        if (providerType === "dunzo" && providersData) {
          let oldData = [...providersData];
          let newData: any = [];

          oldData.forEach((el) => {
            if (el.location_id === providerInfo?.location_id) {
              el.settlement_mode = settlementMode?.toUpperCase();
            }
            newData.push(el);
          });

          setProvidersData(newData);
        }
        //end
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showEditBankDetailsModal}
      onClose={() => setShowEditBankDetailsModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowEditBankDetailsModal(false)}
      >
        Edit Payment Details
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        <Formik
          initialValues={{
            account_name: providerInfo?.account_name || "",
            account_no: providerInfo?.account_no || "",
            bank_name: providerInfo?.bank_name || "",
            branch_name: providerInfo?.branch_name || "",
            ifsc_code: providerInfo?.ifsc_code || "",
            upi_id: providerInfo?.upi_id || "",
            upi_name: "",
          }}
          validationSchema={
            settlementMode?.toLowerCase() === SETTLEMENT_MODE.UPI
              ? updateUPIPaymentDetailsSchema
              : updateOtherPaymentDetailsSchema
          }
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
        >
          {(props) => {
            const { values, setFieldValue, handleBlur, errors, touched } =
              props;
            return (
              <Form>
                <FormControl
                  size="small"
                  fullWidth
                  sx={{ my: 1.5, mr: { xs: 0, md: 1 } }}
                >
                  <InputLabel id="settlement-mode-label">
                    Settlement Mode
                  </InputLabel>
                  <Select
                    labelId="settlement-mode-label"
                    name="settlement_mode"
                    id="settlement_mode"
                    value={settlementMode}
                    label="Settlement Mode"
                    onChange={(e: SelectChangeEvent) => {
                      setSettlementMode(e.target.value);
                    }}
                  >
                    {Object.keys(SETTLEMENT_MODE).map((x: any, index: any) => {
                      return (
                        <MenuItem key={index} value={x}>
                          {x}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {settlementMode && (
                  <>
                    {settlementMode.toLowerCase() === SETTLEMENT_MODE.UPI ? (
                      <QrUpiIdAndNameView
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        values={values}
                        qrData={qrData}
                        setQrData={setQrData}
                      />
                    ) : (
                      <BankDetailsView
                        values={values}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        mode="edit"
                      />
                    )}
                  </>
                )}

                <Box textAlign="center" mt={3}>
                  <LoadingButton
                    size="medium"
                    color="primary"
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditBankDetailsModal;
