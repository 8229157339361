import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addMonths } from "date-fns";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const DateTimeSelect = ({
  time_slot,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  pickerLabel,
}: any) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={pickerLabel}
        value={time_slot}
        onChange={(newValue) => setFieldValue("time_slot", newValue)}
        shouldDisableTime={(timeValue: number, clockType: string) => {
          if (clockType === "hours") {
            if (new Date(time_slot) > new Date()) {
              return false;
            } else {
              return (
                timeValue <=
                new Date().getHours() +
                  (pickerLabel?.toLowerCase()?.includes("sample") ? 1 : 2)
              );
            }
          }
          return false;
        }}
        ampm={false}
        minDate={new Date()}
        maxDate={addMonths(new Date(), 1)}
        minTime={
          pickerLabel?.toLowerCase()?.includes("sample")
            ? new Date(0, 0, 0, 7)
            : new Date(0, 0, 0, 9)
        }
        maxTime={new Date(0, 0, 0, 21)}
        inputFormat="dd/MM/yyyy hh:mm a"
        disableMaskedInput
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            name="time_slot"
            size="small"
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            helperText={
              errors.time_slot && touched.time_slot ? errors.time_slot : ""
            }
            error={errors.time_slot && touched.time_slot ? true : false}
            onBlur={handleBlur}
            InputLabelProps={{
              required: true,
            }}
            onClick={(e) => setOpen(true)}
            autoComplete="off"
            onKeyDown={(e: any) => {
              e.preventDefault();
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateTimeSelect;
