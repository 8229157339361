import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import PartnersAutocomplete from "../Common/partnersAC";
import {
  paymentCollectionTypes,
  deliveryPartnerTypes,
  labVisitTypes,
} from "../../constants";
import { ORDER_TYPE } from "../../enums/order.enum";
import { USER_ROLE } from "../../enums/common.enum";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";

const OrderDetail = ({
  createOrderType,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  disableField = false,
  formData,
}: any) => {
  const {
    provider_id,
    partner_order_id,
    order_type,
    payment_collection,
    consultation_type,
    delivery_partner,
    lab_visit_type,
  } = formData;
  const user: AuthenticatedUser = useUserInfo();
  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
      <PartnersAutocomplete
        providerId={provider_id || null}
        validate="true"
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        disableField={disableField}
        showOnlyActivePartners={true}
      />
      <TextField
        fullWidth
        id="partner_order_id"
        label="Partner Order ID"
        size="small"
        value={partner_order_id || ""}
        onChange={(e) => setFieldValue("partner_order_id", e.target.value)}
        onBlur={handleBlur}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        helperText={
          errors.partner_order_id && touched.partner_order_id
            ? errors.partner_order_id
            : ""
        }
        error={
          errors.partner_order_id && touched.partner_order_id ? true : false
        }
        disabled={disableField ? true : false}
        InputLabelProps={{
          required: true,
        }}
      />
      {createOrderType === ORDER_TYPE.MEDICINES && (
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          error={errors.order_type && touched.order_type ? true : false}
          disabled={disableField}
        >
          <InputLabel id="order-type-label">Order Type</InputLabel>
          <Select
            labelId="order-type-label"
            name="order_type"
            id="order_type"
            value={order_type}
            label="Order Type"
            onChange={(e: SelectChangeEvent) => {
              setFieldValue("order_type", e.target.value);
            }}
            onBlur={handleBlur}
          >
            <MenuItem key={ORDER_TYPE.MEDICINES} value={ORDER_TYPE.MEDICINES}>
              Medicines
            </MenuItem>
          </Select>
          {errors.order_type && touched.order_type && (
            <FormHelperText>{errors.order_type}</FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl
        size="small"
        fullWidth
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        error={
          errors.payment_collection && touched.payment_collection ? true : false
        }
        // PARTNER SPECIFIC: LOOP HEALTH, VHEALTH
        disabled={
          disableField
            ? true
            : createOrderType === ORDER_TYPE.CONSULTATION &&
              user?.role === USER_ROLE.PARTNER &&
              (user?.partner === "loop health" ||
                user.partner === "vhealth by aetna")
            ? true
            : false
        }
      >
        <InputLabel id="payment-collection-label">
          Payment Collection
        </InputLabel>
        <Select
          labelId="payment-collection-label"
          name="payment_collection"
          id="payment_collection"
          value={payment_collection}
          label="Payment Collection"
          onChange={(e: SelectChangeEvent) => {
            setFieldValue("payment_collection", e.target.value);
          }}
          onBlur={handleBlur}
        >
          {Object.entries(paymentCollectionTypes).map(([key, value]: any) => {
            return (
              <MenuItem key={key} value={key}>
                {key === "self" && createOrderType !== ORDER_TYPE.MEDICINES
                  ? "Self (By Partner)"
                  : value}
              </MenuItem>
            );
          })}
        </Select>
        {errors.payment_collection && touched.payment_collection && (
          <FormHelperText>{errors.payment_collection}</FormHelperText>
        )}
      </FormControl>
      {createOrderType === ORDER_TYPE.CONSULTATION && (
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          error={
            errors.consultation_type && touched.consultation_type ? true : false
          }
          // PARTNER SPECIFIC: LOOP HEALTH, VHEALTH
          disabled={
            disableField
              ? true
              : user?.role === USER_ROLE.PARTNER &&
                (user?.partner === "loop health" ||
                  user.partner === "vhealth by aetna")
              ? true
              : false
          }
        >
          <InputLabel required id="consultation-type-label">
            Consultation Type
          </InputLabel>
          <Select
            labelId="consultation-type-label"
            name="consultation_type"
            id="consultation_type"
            value={consultation_type}
            label="Consultation Type"
            onChange={(e: SelectChangeEvent) => {
              setFieldValue("consultation_type", e.target.value);
            }}
            onBlur={handleBlur}
          >
            <MenuItem value="offline">Offline</MenuItem>
            {/* PARTNER SPECIFIC: LOOP HEALTH
            {provider_id === "loop health" ? (
              <MenuItem value="offline">Offline</MenuItem>
            ) : null}

            {provider_id !== "loop health"
              ? Object.entries(consultationTypes).map(([key, value]: any) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  );
                })
              : null} */}
          </Select>
          {errors.consultation_type && touched.consultation_type && (
            <FormHelperText>{errors.consultation_type}</FormHelperText>
          )}
        </FormControl>
      )}
      {createOrderType === ORDER_TYPE.MEDICINES && !disableField && (
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          error={
            errors.delivery_partner && touched.delivery_partner ? true : false
          }
        >
          <InputLabel id="delivery-partner-label">Delivery Partner</InputLabel>
          <Select
            labelId="delivery-partner-label"
            name="delivery_partner"
            id="delivery_partner"
            value={delivery_partner}
            label="Delivery Partner"
            onChange={(e: SelectChangeEvent) => {
              setFieldValue("delivery_partner", e.target.value);
            }}
            onBlur={handleBlur}
          >
            {Object.entries(deliveryPartnerTypes).map(([key, value]: any) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          {errors.delivery_partner && touched.delivery_partner && (
            <FormHelperText>{errors.delivery_partner}</FormHelperText>
          )}
        </FormControl>
      )}
      {createOrderType === ORDER_TYPE.DIAGNOSTICS && (
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          error={errors.lab_visit_type && touched.lab_visit_type ? true : false}
          disabled={disableField}
        >
          <InputLabel required id="lab-visit-type-label">
            Sample Collection
          </InputLabel>
          <Select
            labelId="lab-visit-type-label"
            name="lab_visit_type"
            id="lab_visit_type"
            value={lab_visit_type}
            label="Sample Collection"
            onChange={(e: SelectChangeEvent) => {
              setFieldValue("lab_visit_type", e.target.value);
            }}
            onBlur={handleBlur}
          >
            {Object.entries(labVisitTypes).map(([key, value]: any) => {
              return (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          {errors.lab_visit_type && touched.lab_visit_type && (
            <FormHelperText>{errors.lab_visit_type}</FormHelperText>
          )}
        </FormControl>
      )}
    </Box>
  );
};

export default OrderDetail;
