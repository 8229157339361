import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  removeTrailingSlash,
  successToast,
  textCapitalize,
  transformAllOrder,
} from "../../utils/common";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sortBy } from "lodash";
import DunzoMedicinesTable from "./dunzoMedicinesTable";
import { LoadingButton } from "@mui/lab";
import { setAllOrderData } from "../../store/actions/useActions";

const dunzoOrderSchema = yup.object().shape({
  dunzo_id: yup.string().required("Dunzo id is required"),
  delivery_partner: yup.string().required("Delivery Partner is required"),
  processed_by: yup.string().nullable().required("Processed by is required"),
  order_status: yup.string().required("Order Status is required"),
  cancellation_reason: yup.string().when("order_status", {
    is: (order_status: string) => order_status?.toLowerCase() === "cancelled",
    then: yup.string().required("cancellation reason is required"),
  }),
  dunzo_cancellation_reason: yup.string().when("order_status", {
    is: (order_status: string) => order_status?.toLowerCase() === "cancelled",
    then: yup.string().required("dunzo cancellation reason is required"),
  }),
  provider: yup.object().nullable().required("Provider is required"),
  name_number: yup.string().required("Delivery details is required"),
});

const dunzoDeliveryPartners = ["medpay", "self_pickup", "dunzo", "logistics"];
const dunzoOrderStatus = ["pending", "package_delivered", "cancelled"];
const dunzoCancellationReasons = [
  "Dunzo Cancelled - Order cancelled by Dunzo within 5 mins",
  "Dunzo Cancelled - OTP issues from Dunzo",
  "Dunzo Cancelled - Partner not assigned for a long time",
  "Dunzo Cancelled - rider mentioned as shop closed but shop was open",
  "MedPay Cancelled - Item not available",
  "MedPay Cancelled - Item quantity not available",
  "MedPay Cancelled - Prescription Invalid",
  "Medpay Cancelled - Rider searched in 3 to 4 pharmacies item not available",
  "Medpay Cancelled - Store closed",
  "User cancelled - partner is not moving",
  "User Cancelled - Partner refused to complete the order",
  "User cancelled - partner taking too much time at store",
  "User cancelled - Raised order by mistake",
  "User Cancelled - Wrong Delivery address",
  "User Cancelled - wrong items selected",
  "User cancelled - wrong pickup/drop location",
];
const dunzoPaymentStatus = ["pending", "on_hold", "paid"];

const CreateDunzoOrderModal = ({
  createDunzoOrderModal,
  setCreateDunzoOrderModal,
  activePartnerOrderId,
  setActivePartnerOrderId,
  dunzoProviders,
}: any) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [dunzoActiveOrder, setDunzoActiveOrder] = useState<any>({});
  const [orderItems, setOrderItems] = useState<any>([]);
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const storedEditors = useSelector((state: any) => state.editors.editorsData);
  let allEditors: Array<string> = [];
  storedEditors?.forEach((editor: any) => {
    if (editor?.user_works_on === "dunzo") {
      allEditors.push(editor.user_id);
    }
  });
  allEditors = sortBy(allEditors);

  const getDunzoOrderById = async () => {
    setFetchingData(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/dunzo/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setDunzoActiveOrder(result.data.response);
        setOrderItems(result.data.response.order_details.items);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setFetchingData(false);
    }
  };

  useEffect(() => {
    if (activePartnerOrderId) {
      getDunzoOrderById();
    }

    return () => {
      setActivePartnerOrderId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addItem = async (val: string) => {
    let updatedItems = [...orderItems];
    updatedItems.push({
      item: val,
      details: "",
      quantity: 1,
      dunzo_price: "",
      discount: "",
      pharmacy_price: "",
      pharmacy_payout: "",
      HSNCode: "",
      gst: "0",
      manufacturer: "",
    });
    setOrderItems(updatedItems);
  };

  const handleSubmit = async (values: any, actions: any) => {
    let total = 0;
    let errorFlag = false;
    let deliveryPersonName = "";
    let deliveryPersonMobile = "";
    let medNameError = false;
    let medQtyError = false;

    if (orderItems.length === 0) {
      alert("Please add medicines");
    } else {
      orderItems.forEach((medicine: any) => {
        if (
          medicine.quantity.toString().trim() === "" ||
          medicine.pharmacy_price.trim() === "" ||
          medicine.dunzo_price.trim() === "" ||
          medicine.discount.toString().trim() === ""
        ) {
          errorFlag = true;
        }

        if (medicine.item?.toString().trim() === "") {
          medNameError = true;
        }
        if (medicine.quantity === "") {
          medQtyError = true;
        }

        total += Number(medicine.quantity) * Number(medicine.dunzo_price);
        medicine.discount = String(medicine.discount);

        let amount =
          Number(medicine.pharmacy_price) * Number(medicine.quantity);
        let pharmacy_payout =
          amount - amount * Number(medicine.discount) * 0.01;
        medicine.pharmacy_payout = String(pharmacy_payout.toFixed(2));
      });

      if (medNameError) {
        alert("Medicine name is required");
        return;
      }
      if (medQtyError) {
        alert("Please enter valid quantity");
        return;
      }

      if (errorFlag) {
        alert("Please fill all the price and discount field.");
      } else {
        try {
          let deliveryDetails = values.name_number;
          if (deliveryDetails?.toLowerCase()?.includes("partner")) {
            deliveryDetails = deliveryDetails.split("Partner:")[1];
          }

          deliveryPersonName = deliveryDetails.split("(")[0].trim();
          deliveryPersonMobile = deliveryDetails
            .split("(")[1]
            .split(")")[0]
            .trim();
          if (deliveryPersonMobile.length > 10) {
            deliveryPersonMobile = deliveryPersonMobile.slice(3);
          }
        } catch (err) {
          console.log(err);
        }

        if (deliveryPersonMobile.length === 0) {
          alert("Please enter mobile number in given format");
          return;
        } else if (deliveryPersonMobile.length < 10) {
          alert("Mobile number should not be less than 10-digit");
          return;
        } else if (deliveryPersonMobile.length > 10) {
          alert("Mobile number should not be more than 10-digit");
          return;
        }

        if (deliveryPersonName.length === 0) {
          alert("Please check name");
          return;
        }

        let payload = {
          order_type: "medicines",
          payment_collection: "dunzo",
          delivery_partner: values.delivery_partner,
          settlement_period: values?.provider
            ? values.provider.settlement_mode?.toLowerCase()
            : null,
          order_partner: "dunzo",
          partner_order_id: removeTrailingSlash(
            values.dunzo_id.trim().replace("\t", "")
          ),
          order_status: values.order_status,
          processed_by: values?.processed_by,
          cancellation_reason: values.cancellation_reason,
          partner_cancellation_reason: values.dunzo_cancellation_reason,
          order_details: {
            items: orderItems,
          },
          merchant_details: {
            ...values?.provider,
          },
          invoice_details: {
            bank_reference: values.bank_reference
              ? removeTrailingSlash(values.bank_reference?.replace("\t", ""))
              : "",
            payment_status: values.payment_status
              ? values.payment_status
              : "pending",
            medpay_invoice: values.medpay_invoice,
          },
          delivery_details: {
            delivery_person_name: removeTrailingSlash(
              deliveryPersonName.replace("\t", "")
            ),
            delivery_person_mobile: removeTrailingSlash(
              deliveryPersonMobile.replace("\t", "")
            ),
          },
          order_amount: total.toString(),
        };

        setLoading(true);

        try {
          let result: any = null;
          if (activePartnerOrderId) {
            result = await privateInstance.put(
              `${API_VERSION.V1}/orders/dunzo/order/${dunzoActiveOrder?.order_id}`,
              payload
            );
          } else {
            result = await privateInstance.post(
              `${API_VERSION.V1}/orders/dunzo/order`,
              payload
            );
          }
          if (result?.data?.status === API_RESULT.SUCCESS) {
            successToast(
              `Order ${
                activePartnerOrderId ? "updated" : "created"
              } successfully`
            );
            setCreateDunzoOrderModal(false);
            if (activePartnerOrderId) {
              //update
              dispatch(
                setAllOrderData(
                  transformAllOrder(
                    ordersToManage,
                    dunzoActiveOrder?.order_id,
                    result.data.response
                  )
                )
              );
            } else {
              //add
              let updated_items = [...ordersToManage, result.data.response];
              dispatch(setAllOrderData(updated_items));
            }
          } else {
            errorToast(result.data.message);
          }
        } catch (err) {
          handleError(err);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <Dialog
      open={createDunzoOrderModal}
      onClose={() => setCreateDunzoOrderModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setCreateDunzoOrderModal(false)}
      >
        Create Dunzo Order
      </CustomDialogTitle>
      <DialogContent>
        {fetchingData ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          <Formik
            initialValues={{
              dunzo_id: activePartnerOrderId
                ? dunzoActiveOrder?.partner_order_id
                : "",
              medpay_invoice: activePartnerOrderId
                ? dunzoActiveOrder?.invoice_details?.medpay_invoice
                : new Date().getTime(),
              delivery_partner: activePartnerOrderId
                ? dunzoActiveOrder?.delivery_partner
                : "dunzo",
              processed_by: activePartnerOrderId
                ? dunzoActiveOrder?.processed_by
                : "" || null,
              order_status: activePartnerOrderId
                ? dunzoActiveOrder?.order_status
                : "",
              cancellation_reason: activePartnerOrderId
                ? dunzoActiveOrder?.cancellation_reason
                : "",
              dunzo_cancellation_reason: activePartnerOrderId
                ? dunzoActiveOrder?.partner_cancellation_reason
                : "",
              provider: activePartnerOrderId
                ? dunzoActiveOrder?.merchant_details
                : "",
              order_input: "",
              name_number: activePartnerOrderId
                ? `${dunzoActiveOrder?.delivery_details?.delivery_person_name}(${dunzoActiveOrder?.delivery_details?.delivery_person_mobile})`
                : "",
              bank_reference: activePartnerOrderId
                ? dunzoActiveOrder?.invoice_details?.bank_reference
                : "",
              settlement_period: dunzoActiveOrder
                ? dunzoActiveOrder?.settlement_period
                : "",
              payment_status: activePartnerOrderId
                ? dunzoActiveOrder?.invoice_details?.payment_status
                : "",
            }}
            onSubmit={(values: any, actions: any) => {
              actions.setSubmitting(false);
              handleSubmit(values, actions);
            }}
            validationSchema={dunzoOrderSchema}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              } = props;
              return (
                <Form>
                  <Stack direction="row">
                    <TextField
                      fullWidth
                      id="dunzo_id"
                      label="Dunzo ID"
                      size="small"
                      value={values?.dunzo_id || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      InputLabelProps={{
                        required: true,
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.dunzo_id && touched.dunzo_id
                          ? errors.dunzo_id
                          : ""
                      }
                      error={errors.dunzo_id && touched.dunzo_id ? true : false}
                      onChange={(e: any) =>
                        setFieldValue("dunzo_id", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      id="medpay-invoice"
                      label="Medpay Invoice"
                      size="small"
                      value={values?.medpay_invoice || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      InputLabelProps={{
                        required: true,
                      }}
                      disabled
                    />
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.delivery_partner && touched.delivery_partner
                          ? true
                          : false
                      }
                    >
                      <InputLabel id="delivery-partner-label">
                        Delivery Partner
                      </InputLabel>
                      <Select
                        labelId="delivery-partner-label"
                        name="delivery_partner"
                        id="delivery_partner"
                        value={values?.delivery_partner}
                        label="Delivery Partner"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("delivery_partner", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {dunzoDeliveryPartners.map((x: string) => {
                          return (
                            <MenuItem key={x} value={x}>
                              {textCapitalize(x)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.delivery_partner && touched.delivery_partner && (
                        <FormHelperText>
                          {errors.delivery_partner}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>

                  <Stack direction="row">
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: "14rem" } }}
                      fullWidth
                      size="small"
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      value={values?.processed_by}
                      onChange={(e, val: any) =>
                        setFieldValue("processed_by", val)
                      }
                      id="processed_by"
                      options={allEditors}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          error={
                            errors?.processed_by && touched?.processed_by
                              ? true
                              : false
                          }
                          helperText={
                            errors?.processed_by && touched?.processed_by
                              ? errors?.processed_by
                              : ""
                          }
                          name="processed_by"
                          label="Processed By *"
                          {...params}
                        />
                      )}
                    />
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.order_status && touched.order_status
                          ? true
                          : false
                      }
                    >
                      <InputLabel required id="order-status-label">
                        Order Status
                      </InputLabel>
                      <Select
                        labelId="order-status-label"
                        name="order_status"
                        id="order_status"
                        value={values?.order_status}
                        label="Order Status"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("order_status", e.target.value);
                          if (values?.cancellation_reason !== "") {
                            setFieldValue("cancellation_reason", "");
                            setFieldValue("dunzo_cancellation_reason", "");
                            setFieldTouched("cancellation_reason", false);
                            setFieldTouched("dunzo_cancellation_reason", false);
                          }
                        }}
                        onBlur={handleBlur}
                      >
                        {dunzoOrderStatus.map((x: string) => {
                          return (
                            <MenuItem key={x} value={x}>
                              {textCapitalize(x)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.order_status && touched.order_status && (
                        <FormHelperText>{errors.order_status}</FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.cancellation_reason &&
                        touched.cancellation_reason
                          ? true
                          : false
                      }
                    >
                      <InputLabel
                        required={values?.order_status === "cancelled"}
                        id="order-status-label"
                      >
                        Cancellation Reason
                      </InputLabel>
                      <Select
                        labelId="cancellation-reason-label"
                        name="cancellation_reason"
                        id="cancellation_reason"
                        value={values?.cancellation_reason}
                        label="Cancellation Reason"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("cancellation_reason", e.target.value);
                        }}
                        onBlur={handleBlur}
                        disabled={values?.order_status !== "cancelled"}
                      >
                        {dunzoCancellationReasons.map((x: string) => {
                          return (
                            <MenuItem key={x} value={x}>
                              {textCapitalize(x)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.cancellation_reason &&
                        touched.cancellation_reason && (
                          <FormHelperText>
                            {errors.cancellation_reason}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Stack>

                  <Stack>
                    <TextField
                      fullWidth
                      id="dunzo_cancellation_reason"
                      label="Dunzo Cancellation reason"
                      size="small"
                      value={values?.dunzo_cancellation_reason || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      InputLabelProps={{
                        required: values?.order_status === "cancelled",
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.dunzo_cancellation_reason &&
                        touched.dunzo_cancellation_reason
                          ? errors.dunzo_cancellation_reason
                          : ""
                      }
                      error={
                        errors.dunzo_cancellation_reason &&
                        touched.dunzo_cancellation_reason
                          ? true
                          : false
                      }
                      onChange={(e: any) =>
                        setFieldValue(
                          "dunzo_cancellation_reason",
                          e.target.value
                        )
                      }
                      disabled={values?.order_status !== "cancelled"}
                    />
                  </Stack>

                  <Stack mt={2}>
                    <Typography variant="h4">Provider Details</Typography>
                    <Autocomplete
                      ListboxProps={{ style: { maxHeight: "14rem" } }}
                      fullWidth
                      size="small"
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      value={values?.provider || null}
                      onChange={(e, val: any) => setFieldValue("provider", val)}
                      id="provider"
                      options={dunzoProviders}
                      onBlur={handleBlur}
                      getOptionLabel={(option) =>
                        option.association_display_name || ""
                      }
                      renderOption={(props, option: any) => {
                        return (
                          <li {...props}>
                            <Box sx={{ py: 0.5 }}>
                              <Typography variant="body1">
                                {option.association_display_name}
                              </Typography>
                              <Typography variant="body2">
                                {option.name}
                              </Typography>
                            </Box>
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={
                            errors?.provider && touched?.provider ? true : false
                          }
                          helperText={
                            errors?.provider && touched?.provider
                              ? errors?.provider
                              : ""
                          }
                          name="provider"
                          label="Provider *"
                          {...params}
                          onPaste={(e: any) => {
                            e.preventDefault();
                          }}
                          onCopy={(e: any) => {
                            e.preventDefault();
                          }}
                        />
                      )}
                    />
                    {values?.provider?.name && (
                      <Box
                        sx={{
                          background: "#0058ff",
                          color: "#fff",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Typography>Discounts:</Typography>
                        <Typography variant="body2">
                          FMCG: {values?.provider?.discounts?.fmcg} %{" "}
                        </Typography>
                        <Typography variant="body2">
                          Medicines: {values?.provider?.discounts?.medicines} %{" "}
                        </Typography>
                      </Box>
                    )}
                  </Stack>

                  <Stack mt={2}>
                    <Typography variant="h4">Order Details *</Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      <TextField
                        fullWidth
                        id="order_input"
                        label="Order Items"
                        size="small"
                        value={values?.order_input || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onChange={(e: any) =>
                          setFieldValue("order_input", e.target.value)
                        }
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ minWidth: "150px" }}
                        disabled={values?.order_input === ""}
                        onClick={() => {
                          addItem(values?.order_input);
                          setFieldValue("order_input", "");
                        }}
                        type="button"
                      >
                        Add Item
                      </Button>
                    </Box>

                    <DunzoMedicinesTable
                      orderItems={orderItems}
                      setOrderItems={setOrderItems}
                    />
                  </Stack>

                  {activePartnerOrderId && (
                    <Stack mt={2}>
                      <Typography variant="h4">Billing</Typography>
                      <Stack direction="row">
                        <TextField
                          fullWidth
                          id="bank_reference"
                          label="Bank reference"
                          size="small"
                          value={values?.bank_reference || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          onChange={(e) =>
                            setFieldValue("bank_reference", e.target.value)
                          }
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                        <TextField
                          fullWidth
                          id="settlement_period"
                          label="Settlement mode"
                          size="small"
                          value={values?.settlement_period || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          disabled
                        />
                        <FormControl
                          size="small"
                          fullWidth
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        >
                          <InputLabel id="payment-status-label">
                            Payment Status
                          </InputLabel>
                          <Select
                            labelId="payemnt-status-label"
                            name="payment_status"
                            id="payment_status"
                            value={values?.payment_status}
                            label="Payment Status"
                            onChange={(e: SelectChangeEvent) => {
                              setFieldValue("payment_status", e.target.value);
                            }}
                          >
                            {dunzoPaymentStatus.map((x: string) => {
                              return (
                                <MenuItem key={x} value={x}>
                                  {textCapitalize(x)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Stack>
                    </Stack>
                  )}

                  <Stack mt={2}>
                    <Typography variant="h4">Delivery Details</Typography>
                    <TextField
                      fullWidth
                      id="name_number"
                      label="Name and number"
                      size="small"
                      value={values?.name_number || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      InputLabelProps={{
                        required: true,
                      }}
                      onBlur={handleBlur}
                      helperText={
                        errors.name_number && touched.name_number
                          ? errors.name_number
                          : ""
                      }
                      error={
                        errors.name_number && touched.name_number ? true : false
                      }
                      onChange={(e: any) =>
                        setFieldValue("name_number", e.target.value)
                      }
                      placeholder='Supported format: "Partner: John Doe(+917878787887)" or "John Doe(+917878787887)" or "John Doe(7878787887)"'
                    />
                  </Stack>

                  <Stack direction="row" justifyContent="center" mt={2}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      loading={loading}
                      disabled={isSubmitting}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      type="submit"
                    >
                      {activePartnerOrderId ? "Update Order" : "Create Order"}
                    </LoadingButton>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateDunzoOrderModal;
