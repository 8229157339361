import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  getIssueStatusBgColor,
  handleError,
  parseAndFormateDate,
} from "../../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { commonTableOptions } from "../../../../constants";
import { parse } from "date-fns";
import IssueDetailModal from "../../../AppIssues2/issueDetailModal";

const MerchantTickets = ({
  issuesData,
  setIssuesData,
  visitedTabs,
  updateVisitedTabs,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [viewMode, setViewMode] = useState("");
  const [showIssueDetailModal, setShowIssueDetailModal] = useState(false);
  const privateInstance = useAxiosInstance();

  const params = useParams();

  const getIssuesList = async () => {
    let url = `${API_VERSION.V1}/issues?location_id=${params?.locationId}`;

    //reset local state on api call
    setIssuesData([]);

    setLoading(true);
    try {
      const result = await privateInstance.get(url);
      if (result.data?.status === API_RESULT.SUCCESS) {
        updateVisitedTabs(4, "add");
        //type open or date range
        setIssuesData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!visitedTabs.includes(4)) {
      getIssuesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "issue_id",
      label: "ISSUE ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "issue_type",
      label: "ISSUE TYPE",
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "issue_sub_type",
      label: "CATEGORY",
      options: {
        filter: true,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "follow_up_date",
      label: "FOLLOW UP",
      options: {
        filter: true,
        sort: false,
        searchable: false,
        display: false,
      },
    },
    {
      name: "description.text",
      label: "DESCRIPTION",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box minWidth="220px">
              <Typography variant="body2">
                {value?.length > 60 ? value.substr(0, 60) + "..." : value}
              </Typography>
              {issuesData[tableMeta.rowIndex]?.description?.images?.length > 0
                ? issuesData[tableMeta.rowIndex]?.description.images.map(
                    (x: string, i: number) => {
                      return (
                        <Link
                          sx={{ pl: i === 0 ? 0 : 1 }}
                          key={i}
                          target="_blank"
                          href={x}
                          onClick={(e: any) => e.stopPropagation()}
                        >
                          Image_{i + 1}
                        </Link>
                      );
                    }
                  )
                : null}
            </Box>
          );
        },
      },
    },
    {
      name: "issue_status",
      label: "STATUS",
      options: {
        filter: true,
        sort: false,
        searchable: false,
        // display: value === 0 ? true : false,
        customBodyRender: (value: any) => {
          return (
            <Chip
              size="small"
              label={value?.split("_")?.join(" ")}
              sx={{
                backgroundColor: getIssueStatusBgColor(value),
                padding: "6px",
                color: ["open", "reopened"].includes(value) ? "#fff" : "#000",
                textTransform: "capitalize",
              }}
            />
          );
        },
      },
    },
    {
      name: "created_at",
      label: "CREATED AT",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "modified_at",
      label: "MODIFIED AT",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "respondent_actions",
      label: "RESPONDENT ACTIONS",
      options: {
        searchable: false,
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value: any) => {
          const d = value[value.length - 1];
          if (!d) return;
          return (
            <Box minWidth="220px">
              <Stack direction="row">
                <Tooltip title={`Updated By: ${d.updated_by}`}>
                  <InfoIcon color="primary" fontSize="small" />
                </Tooltip>
                <Typography variant="body2">
                  {String(d.text).length > 60
                    ? String(d.text).substr(0, 60) + "..."
                    : d.text}
                </Typography>
              </Stack>
            </Box>
          );
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
              {issuesData[tableMeta?.rowIndex]?.issue_status?.toLowerCase() ===
              "resolved" ? (
                <Tooltip title="View More">
                  <IconButton
                    color="primary"
                    aria-label="open"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setShowIssueDetailModal(true);
                      setSelectedRowData(issuesData[tableMeta.rowIndex]);
                      setViewMode("readOnly");
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Update">
                  <IconButton
                    color="primary"
                    aria-label="open"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setShowIssueDetailModal(true);
                      setSelectedRowData(issuesData[tableMeta.rowIndex]);
                      setViewMode("readUpdate");
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...(commonTableOptions as any),
    download: false,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    searchPlaceholder: "Issue ID/Description",
    setRowProps: (row: any[]) => {
      if (
        row[5]?.props?.label !== "resolved" &&
        row[3] &&
        parse(row[3], "dd-MM-yyyy", new Date()) <= new Date()
      ) {
        return {
          style: { background: "#fed7d7" },
        };
      }
      return {};
    },
    onRowClick: (row: any, index: any) => {
      setShowIssueDetailModal(true);
      setSelectedRowData(issuesData[index?.dataIndex]);
      if (
        issuesData[index?.dataIndex]?.issue_status?.toLowerCase() === "resolved"
      ) {
        setViewMode("readOnly");
      } else {
        setViewMode("readUpdate");
      }
    },
  };

  return (
    <div>
      <MUIDataTable
        title=""
        data={issuesData}
        columns={columns}
        options={options}
      />
      {showIssueDetailModal && (
        <IssueDetailModal
          open={showIssueDetailModal}
          onClose={() => setShowIssueDetailModal(false)}
          data={selectedRowData}
          setData={setSelectedRowData}
          viewMode={viewMode}
          getIssuesList={getIssuesList}
          tableData={issuesData}
          setTableData={setIssuesData}
        />
      )}
    </div>
  );
};

export default MerchantTickets;
