import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import * as yup from "yup";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
} from "../../../utils/common";
import { valueLimits } from "../../../constants";
import { instance } from "../../../utils/api";
import config from "../../../config";
import { API_RESULT } from "../../../enums/common.enum";

const editIgmSchema = yup.object().shape({
  response_action: yup.string().required("Response Action is required"),
  // response_short_desc: yup.string().required("Short Desc is required"),
  resolution_short_desc: yup.string().when("response_action", {
    is: (response_action: string) =>
      response_action?.toLowerCase() === "resolved",
    then: yup.string().required("Resolution Short Desc is required"),
  }),
  resolution_long_desc: yup.string().when("response_action", {
    is: (response_action: string) =>
      response_action?.toLowerCase() === "resolved",
    then: yup.string().required("Resolution Long Desc is required"),
  }),
  action_triggered: yup.string().when("response_action", {
    is: (response_action: string) =>
      response_action?.toLowerCase() === "resolved",
    then: yup.string().required("Action Triggered is required"),
  }),
  refund_amount: yup.number().when(["response_action", "action_triggered"], {
    is: (response_action: string, action_triggered: string) =>
      response_action?.toLowerCase() === "resolved" &&
      action_triggered?.toLowerCase() === "refund",
    then: yup
      .number()
      .typeError("must be a number")
      .min(0.1, "minimum value should be greater than 0")
      .required("Refund Amount is required"),
  }),
});

const EditIgmModal = ({
  open,
  onClose,
  selectedRowData,
  getOndcIssues,
}: any) => {
  const [loading, setLoading] = useState(false);
  const updateIgmTicket = async (val: any) => {
    let payload: any = {
      issue_id: val.issue_id,
      response_action: {
        respondent_action: val.response_action,
        short_desc: val.resolution_short_desc,
      },
    };

    if (val?.response_action?.toLowerCase() === "resolved") {
      payload = {
        ...payload,
        resolution: {
          short_desc: val.resolution_short_desc,
          long_desc: val.resolution_long_desc,
          action_triggered: val.action_triggered,
        },
      };

      if (val?.action_triggered?.toLowerCase() === "refund") {
        payload = {
          ...payload,
          resolution: {
            ...payload.resolution,
            refund_amount: String(val.refund_amount),
          },
        };
      }
    }

    setLoading(true);

    try {
      const result = await instance.post(
        `${config.ONDC_API_URL}ecommerce/manage/issue/issue-response`,
        payload
      );
      if (result.data?.status === API_RESULT.SUCCESS) {
        successToast("Updated Successfully");
        onClose();
        //update
        getOndcIssues();
      } else {
        errorToast(result?.data?.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        Update Issue
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            issue_id: selectedRowData?.issue_id || "",
            response_action: "",
            // response_short_desc: "",
            resolution_short_desc: "",
            resolution_long_desc: "",
            action_triggered: "",
            refund_amount: "",
          }}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            updateIgmTicket(values);
          }}
          validationSchema={editIgmSchema}
        >
          {(props: any) => {
            const { values, errors, touched, setFieldValue, handleBlur } =
              props;
            return (
              <Form>
                <>
                  <TextField
                    fullWidth
                    label="Issue ID"
                    size="small"
                    value={values.issue_id || ""}
                    sx={{ mt: 1.5 }}
                    disabled
                  />
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5 }}
                    error={
                      errors.response_action && touched.response_action
                        ? true
                        : false
                    }
                  >
                    <InputLabel required id="order-type-label">
                      Response Action
                    </InputLabel>
                    <Select
                      labelId="response-action-label"
                      name="response_action"
                      id="response_action"
                      value={values.response_action}
                      label="Response Action"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("response_action", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      {[
                        // "PROCESSING",
                        // "CASCADED",
                        "RESOLVED",
                        // "NEED-MORE-INFO",
                      ].map((x: string, i: number) => (
                        <MenuItem key={i} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.response_action && touched.response_action && (
                      <FormHelperText>{errors.response_action}</FormHelperText>
                    )}
                  </FormControl>
                  {/* <TextField
                    fullWidth
                    id="response_short_desc"
                    label="Short Description (Response)"
                    size="small"
                    value={values.response_short_desc}
                    onChange={(e) =>
                      setFieldValue("response_short_desc", e.target.value)
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.response_short_desc && touched.response_short_desc
                        ? errors.response_short_desc
                        : ""
                    }
                    error={
                      errors.response_short_desc && touched.response_short_desc
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5 }}
                  /> */}
                  {values.response_action === "RESOLVED" ? (
                    <>
                      <TextField
                        fullWidth
                        id="resolution_short_desc"
                        label="Short Description (Resolution)"
                        size="small"
                        value={values.resolution_short_desc}
                        onChange={(e) =>
                          setFieldValue("resolution_short_desc", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.resolution_short_desc &&
                          touched.resolution_short_desc
                            ? errors.resolution_short_desc
                            : ""
                        }
                        error={
                          errors.resolution_short_desc &&
                          touched.resolution_short_desc
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                        sx={{ mt: 1.5 }}
                      />
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        id="resolution_long_desc"
                        label="Long Description"
                        size="small"
                        value={values.resolution_long_desc}
                        onChange={(e) =>
                          setFieldValue("resolution_long_desc", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.resolution_long_desc &&
                          touched.resolution_long_desc
                            ? errors.resolution_long_desc
                            : ""
                        }
                        error={
                          errors.resolution_long_desc &&
                          touched.resolution_long_desc
                            ? true
                            : false
                        }
                        InputLabelProps={{
                          required: true,
                        }}
                        sx={{ mt: 1.5 }}
                      />
                      <FormControl
                        size="small"
                        fullWidth
                        sx={{ mt: 1.5 }}
                        error={
                          errors.action_triggered && touched.action_triggered
                            ? true
                            : false
                        }
                      >
                        <InputLabel required id="action-triggered-label">
                          Action Triggered
                        </InputLabel>
                        <Select
                          labelId="action-triggered-label"
                          name="action_triggered"
                          id="action_triggered"
                          value={values.action_triggered}
                          label="Action Triggered"
                          onChange={(e: SelectChangeEvent) => {
                            setFieldValue("action_triggered", e.target.value);
                          }}
                          onBlur={handleBlur}
                        >
                          {[
                            // "REFUND",
                            "REPLACEMENT",
                            "NO-ACTION",
                          ].map((x: string, i: number) => (
                            <MenuItem key={i} value={x}>
                              {x}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.action_triggered &&
                          touched.action_triggered && (
                            <FormHelperText>
                              {errors.action_triggered}
                            </FormHelperText>
                          )}
                      </FormControl>
                      {values.action_triggered === "REFUND" ? (
                        <TextField
                          fullWidth
                          id="refund_amount"
                          label="Refund Amount"
                          size="small"
                          value={values.refund_amount}
                          onChange={(e) =>
                            setFieldValue(
                              "refund_amount",
                              validateFloatNum(e.target.value, valueLimits.MRP)
                            )
                          }
                          onBlur={handleBlur}
                          helperText={
                            errors.refund_amount && touched.refund_amount
                              ? errors.refund_amount
                              : ""
                          }
                          error={
                            errors.refund_amount && touched.refund_amount
                              ? true
                              : false
                          }
                          InputLabelProps={{
                            required: true,
                          }}
                          sx={{ mt: 1.5 }}
                        />
                      ) : null}
                    </>
                  ) : null}
                  <Box textAlign="center" mt={3}>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={loading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      sx={{ mr: 2 }}
                    >
                      Update
                    </LoadingButton>
                  </Box>
                </>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditIgmModal;
