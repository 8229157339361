import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import {
  medicineDrugForms,
  medicinePackForms,
  valueLimits,
} from "../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { addNewMedicineSchema } from "../../../../schemas/pharmacy";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { mfrData } from "../../../../utils/mfr";

const NewItemModal = ({
  showNewItemModal,
  setShowNewItemModal,
  medicineName,
  selectedValues,
  setSelectedValues,
  selectedValuesId,
  setSelectedValuesId,
  altMedicineIndex,
  setAltMedicineIndex,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    const {
      name,
      mrp,
      rx_required,
      type,
      pack_form,
      pack_quantity,
      drug_form,
      composition,
      mfr,
      reference_url,
      remarks,
    } = values || {};
    let payload: any = {
      name: name,
      mrp: mrp,
      rx_required: rx_required,
      type: type,
      pack_form: pack_form?.toLowerCase(),
      pack_quantity: pack_quantity,
      drug_form: drug_form,
      composition: composition,
      mfr: mfr,
      reference_url: reference_url,
      remarks: remarks,
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/medicine/sku`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Item added successfully");
        setShowNewItemModal(false);
        //add item to list
        const val = result.data?.response;
        if (altMedicineIndex !== null) {
          selectedValues.splice(altMedicineIndex, 1, {
            sku_id: val.sku_id,
            item: val.name,
            details: val.pack_qty_label,
            quantity: 1,
            MRP: String(val.mrp),
            manufacturer: val.mfr,
            composition: val.composition,
            drug_form: val.drug_form,
            pack_form: val.pack_form,
            pack_quantity: val.pack_quantity,
            change_type: "alternative",
            requested_item: selectedValues[altMedicineIndex],
            med_from_db: "true",
            db_mrp: val?.mrp,
            status: val.status,
          });
          const updatedData = [...selectedValues];
          setSelectedValues(updatedData);
          setAltMedicineIndex(null);
        } else {
          let allItems = [...selectedValues];
          allItems.push({
            sku_id: val.sku_id,
            item: val.name,
            details: val.pack_qty_label,
            quantity: 1,
            MRP: String(val.mrp),
            manufacturer: val.mfr,
            composition: val.composition,
            drug_form: val.drug_form,
            pack_form: val.pack_form,
            pack_quantity: val.pack_quantity,
            db_mrp: val?.mrp,
            change_type: "new",
            status: val.status,
          });
          setSelectedValues(allItems);
          let allItemsId = [...selectedValuesId];
          allItemsId.push(val.sku_id);
          setSelectedValuesId(allItemsId);
        }
        //end
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showNewItemModal}
      onClose={() => setShowNewItemModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowNewItemModal(false)}
      >
        Add New Medicine
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: medicineName || "",
            mrp: "",
            rx_required: "yes",
            type: "medicine",
            pack_form: "Bottle",
            pack_quantity: "1",
            drug_form: "lotions",
            composition: "",
            mfr: "",
            reference_url: "",
            remarks: "",
          }}
          validationSchema={addNewMedicineSchema}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Stack>
                  <Stack direction={{ xs: "column", md: "row" }}>
                    <FormControl
                      sx={{
                        mt: 1.5,
                        width: { xs: "100%", md: "50%" },
                      }}
                    >
                      <FormLabel style={{ color: "#7C7C7C" }} id="rx-label">
                        Type
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="type-label"
                        name="type-group"
                        value={values.type}
                        onChange={(e) => {
                          setFieldValue("type", e.target.value);
                          if (e.target.value === "fmcg") {
                            setFieldValue("rx_required", "no");
                          } else {
                            setFieldValue("rx_required", "yes");
                          }
                        }}
                      >
                        <FormControlLabel
                          value="medicine"
                          control={<Radio />}
                          label="Medicine"
                        />
                        <FormControlLabel
                          value="fmcg"
                          control={<Radio />}
                          label="FMCG"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl
                      sx={{
                        mt: 1.5,
                        width: { xs: "100%", md: "50%" },
                      }}
                      disabled={values.type === "fmcg"}
                    >
                      <FormLabel style={{ color: "#7C7C7C" }} id="rx-label">
                        Rx Required?
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="rx-label"
                        name="rx-group"
                        value={values.rx_required}
                        onChange={(e) =>
                          setFieldValue("rx_required", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    size="small"
                    value={values.name || ""}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    sx={{ mt: 1.5 }}
                    onBlur={handleBlur}
                    helperText={errors.name && touched.name ? errors.name : ""}
                    error={errors.name && touched.name ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <Autocomplete
                    filterOptions={createFilterOptions({
                      matchFrom: "start",
                    })}
                    freeSolo
                    fullWidth
                    size="small"
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    value={values.mfr || ""}
                    onChange={(e, val) => {
                      setFieldValue("mfr", val);
                    }}
                    onInputChange={(e, val) => {
                      if (val) {
                        setFieldValue("mfr", val);
                      }
                    }}
                    onBlur={handleBlur}
                    id="mfr"
                    options={mfrData}
                    renderInput={(params) => (
                      <TextField
                        error={errors?.mfr && touched?.mfr ? true : false}
                        helperText={
                          errors?.mfr && touched?.mfr ? errors?.mfr : ""
                        }
                        name="mfr"
                        label="MFR *"
                        {...params}
                      />
                    )}
                  />
                  <TextField
                    fullWidth
                    id="composition"
                    size="small"
                    label="Composition"
                    value={values.composition}
                    sx={{
                      mt: 1.5,
                    }}
                    onChange={(event) => {
                      setFieldValue("composition", event.target.value);
                    }}
                    // helperText={
                    //   errors.composition && touched.composition
                    //     ? errors.composition
                    //     : ""
                    // }
                    // error={
                    //   errors.composition && touched.composition ? true : false
                    // }
                    // InputLabelProps={{
                    //   required: true,
                    // }}
                    // onBlur={handleBlur}
                  />

                  <Stack direction={{ xs: "column", md: "row" }}>
                    <TextField
                      fullWidth
                      id="mrp"
                      label="MRP(Rs.)"
                      size="small"
                      value={values.mrp || ""}
                      onChange={(e) =>
                        setFieldValue(
                          "mrp",
                          validateFloatNum(e.target.value, valueLimits.MRP)
                        )
                      }
                      sx={{
                        mt: 1.5,
                        mr: { xs: 0, md: 0.5 },
                        width: { xs: "100%", md: "50%" },
                      }}
                      onBlur={handleBlur}
                      helperText={errors.mrp && touched.mrp ? errors.mrp : ""}
                      error={errors.mrp && touched.mrp ? true : false}
                      InputLabelProps={{
                        required: true,
                      }}
                    />
                    <FormControl
                      size="small"
                      sx={{
                        mt: 1.5,
                        ml: { xs: 0, md: 0.5 },
                        width: { xs: "100%", md: "50%" },
                      }}
                    >
                      <InputLabel id="drug-form-label">Drug Form</InputLabel>
                      <Select
                        labelId="druf-form-label"
                        name="drug_form"
                        id="drug_form"
                        value={values.drug_form || ""}
                        label="Drug Form"
                        onChange={(event) => {
                          setFieldValue("drug_form", event.target.value);
                        }}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {medicineDrugForms.map((x) => {
                          return (
                            <MenuItem
                              sx={{ textTransform: "capitalize" }}
                              key={x}
                              value={x}
                            >
                              {x}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>

                  <Stack direction={{ xs: "column", md: "row" }}>
                    <FormControl
                      size="small"
                      sx={{
                        mt: 1.5,
                        mr: { xs: 0, md: 0.5 },
                        width: { xs: "100%", md: "50%" },
                      }}
                    >
                      <InputLabel id="pack-form-label">Packet Form</InputLabel>
                      <Select
                        labelId="pack-form-label"
                        name="pack_form"
                        id="pack_form"
                        value={values.pack_form || ""}
                        label="Packet Form"
                        onChange={(event) => {
                          setFieldValue("pack_form", event.target.value);
                        }}
                      >
                        {medicinePackForms.map((x) => {
                          return (
                            <MenuItem key={x} value={x}>
                              {x}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <TextField
                      id="pack_quantity"
                      size="small"
                      label="Pack Qty."
                      value={values.pack_quantity}
                      sx={{
                        mt: 1.5,
                        ml: { xs: 0, md: 0.5 },
                        width: { xs: "100%", md: "50%" },
                      }}
                      onChange={(event) => {
                        setFieldValue("pack_quantity", event.target.value);
                      }}
                      helperText={
                        errors.pack_quantity && touched.pack_quantity
                          ? errors.pack_quantity
                          : ""
                      }
                      error={
                        errors.pack_quantity && touched.pack_quantity
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                      placeholder="quantity in a pack"
                      onBlur={handleBlur}
                    />
                  </Stack>

                  <TextField
                    fullWidth
                    id="reference_url"
                    size="small"
                    label="Reference URL"
                    value={values.reference_url}
                    sx={{
                      mt: 4,
                    }}
                    onChange={(event) => {
                      setFieldValue("reference_url", event.target.value);
                    }}
                    placeholder="Paste any online reference link if available"
                  />
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    id="remarks"
                    size="small"
                    label="Remarks"
                    value={values.remarks}
                    sx={{
                      mt: 1.5,
                    }}
                    onChange={(event) => {
                      setFieldValue("remarks", event.target.value);
                    }}
                    placeholder="Any other information"
                  />
                </Stack>
                <Box textAlign="center" sx={{ mt: 3 }}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={loading}
                    disabled={isSubmitting}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                  >
                    Submit
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default NewItemModal;
