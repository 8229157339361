import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import UploadFiles from "../../../components/Common/uploadFiles";
import PartnerIdChange from "../../../components/CreateOrder/partnerIdChange";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  removeTrailingSlash,
  successToast,
  textCapitalize,
  validateIntNum,
} from "../../../utils/common";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import PartnersAutocomplete from "../../../components/Common/partnersAC";
import { valueLimits } from "../../../constants";
import MerchantGstValidate from "../../../components/ManageOrder/GroupOrders/Common/merchantGstValidate";
import { createIpdOrderSchema } from "../../../schemas/ipd.schema";

const CreateIpdOrder = () => {
  const [loading, setLoading] = React.useState(false);
  const [prescriptionUrls, setPrescriptionUrls] = React.useState([]);

  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    if (prescriptionUrls.length === 0) {
      alert("Please upload prescription");
      return;
    }

    let payload: any = {
      customer_details: {
        address_details: {
          address: values?.address
            ? removeTrailingSlash(values.address?.trim().replace("\t", ""))
            : "NA",
          city: values?.city ? values.city : "NA",
          landmark: values?.landmark
            ? removeTrailingSlash(values.landmark?.trim().replace("\t", ""))
            : "NA",
          pin_code: values?.pin
            ? removeTrailingSlash(values.pin?.trim().replace("\t", ""))
            : "111111",
          state: values?.state ? values.state : "NA",
        },
        alternative_mobile: removeTrailingSlash(
          values.alt_mobile?.trim().replace("\t", "")
        ),
        email: removeTrailingSlash(values.email?.trim().replace("\t", "")),
        mobile: removeTrailingSlash(values.mobile?.trim().replace("\t", "")),
        name: removeTrailingSlash(values.full_name?.trim().replace("\t", "")),
      },
      merchant_details: {
        provider_id: "added_manually",
        location_id: "added_manually",
        merchant_type: values?.merchant_type,
        gst_no: values.gst_no ? values.gst_no : "NA",
        pan_no: values?.pan_no,
        gst_name: values.company ? values.company : "NA",
        name: values?.merchant_name,
        formatted_address: values.company_address
          ? values?.company_address
          : "NA",
        mobile: values?.merchant_mobile,
        onboarded: "N",
        pin_code: values?.merchant_pin,
        account_name: values?.account_name,
        bank_name: values?.bank_name,
        account_no: values?.account_no,
        ifsc_code: values?.ifsc_code,
      },
      order_partner: values.provider_id,
      order_type: values.order_type,
      partner_order_id: removeTrailingSlash(
        values.partner_order_id?.trim().replace("\t", "")
      ),
      order_details: {
        doctor: removeTrailingSlash(values.doctor?.trim().replace("\t", "")),
        items: [
          {
            item: "Anywhere Cashless appointment",
            quantity: 1,
            mrp: String(values?.mrp),
          },
        ],
        reports_links: prescriptionUrls,
      },
      discount: values?.discount,
      patient_payable: values?.patient_payable,
    };

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V2}/orders/ipd/create`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order created successfully");
        actions.resetForm({});
        setPrescriptionUrls([]);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const getCityAndState = async (
    setFieldValue: any,
    touched: any,
    setTouched: any,
    pin: any
  ) => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/providers/address/decode-pincode/${pin}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setFieldValue(
          "city",
          textCapitalize(result.data?.response?.city) || ""
        );
        setFieldValue("state", result.data?.response?.state);
        setTouched({ ...touched, city: false, state: false });
      } else {
        setFieldValue("city", "");
        setFieldValue("state", "");
        if (result.data.message?.toLowerCase()?.includes("server error")) {
          errorToast(`Pincode (${pin}) is not serviceable`);
        } else {
          errorToast(result.data.message);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Box
      sx={{
        px: 3,
        py: 2,
        boxShadow:
          "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Formik
        initialValues={{
          provider_id: "" || null,
          partner_order_id: "",
          order_type: "ipd",
          full_name: "",
          mobile: "",
          alt_mobile: "",
          email: "",
          address: "",
          landmark: "",
          pin: "",
          city: "",
          state: "",
          doctor: "",
          gst_no: "",
          company: "",
          company_address: "",
          merchant_type: "",
          pan_no: "",
          merchant_name: "",
          merchant_mobile: "",
          merchant_pin: "",
          account_no: "",
          account_name: "",
          bank_name: "",
          ifsc_code: "",
          discount: "",
          final_payable: "",
          mrp: "",
          patient_payable: "",
        }}
        validationSchema={createIpdOrderSchema}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
            setTouched,
          } = props;
          return (
            <Form>
              <Typography variant="h4">Order Details:</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <PartnersAutocomplete
                  providerId={values?.provider_id || null}
                  validate="true"
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  disableField={false}
                />
                <TextField
                  fullWidth
                  id="partner_order_id"
                  label="Partner Order ID"
                  size="small"
                  value={values?.partner_order_id || ""}
                  onChange={(e) =>
                    setFieldValue("partner_order_id", e.target.value)
                  }
                  onBlur={handleBlur}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  helperText={
                    errors.partner_order_id && touched.partner_order_id
                      ? errors.partner_order_id
                      : ""
                  }
                  error={
                    errors.partner_order_id && touched.partner_order_id
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    required: true,
                  }}
                />
                <FormControl
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  error={errors.order_type && touched.order_type ? true : false}
                >
                  <InputLabel required id="order-type-label">
                    Order Type
                  </InputLabel>
                  <Select
                    labelId="order-type-label"
                    name="order_type"
                    id="order_type"
                    value={values?.order_type}
                    label="Order Type"
                    onChange={(e: SelectChangeEvent) => {
                      setFieldValue("order_type", e.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="ipd">IPD</MenuItem>
                  </Select>
                  {errors.order_type && touched.order_type && (
                    <FormHelperText>{errors.order_type}</FormHelperText>
                  )}
                </FormControl>
              </Box>

              <Typography variant="h4" sx={{ mt: 3 }}>
                Customer Details:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  fullWidth
                  id="full_name"
                  label="Full Name"
                  size="small"
                  value={values?.full_name || ""}
                  onChange={(e) => setFieldValue("full_name", e.target.value)}
                  onBlur={handleBlur}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  helperText={
                    errors.full_name && touched.full_name
                      ? errors.full_name
                      : ""
                  }
                  error={errors.full_name && touched.full_name ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                  inputProps={{
                    maxLength: valueLimits.NAME_LEN,
                  }}
                />
                <TextField
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  size="small"
                  value={values?.mobile || ""}
                  onChange={(e) =>
                    setFieldValue(
                      "mobile",
                      validateIntNum(e.target.value, valueLimits.MOBILE)
                    )
                  }
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onBlur={handleBlur}
                  helperText={
                    errors.mobile && touched.mobile ? errors.mobile : ""
                  }
                  error={errors.mobile && touched.mobile ? true : false}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    required: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="alt_mobile"
                  label="Alternate Mobile"
                  size="small"
                  value={values?.alt_mobile || ""}
                  onChange={(e) =>
                    setFieldValue(
                      "alt_mobile",
                      validateIntNum(e.target.value, valueLimits.MOBILE)
                    )
                  }
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onBlur={handleBlur}
                  helperText={
                    errors.alt_mobile && touched.alt_mobile
                      ? errors.alt_mobile
                      : ""
                  }
                  error={errors.alt_mobile && touched.alt_mobile ? true : false}
                  inputProps={{
                    maxLength: 10,
                  }}
                />
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  size="small"
                  value={values?.email || ""}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onBlur={handleBlur}
                  helperText={errors.email && touched.email ? errors.email : ""}
                  error={errors.email && touched.email ? true : false}
                />
              </Box>

              <Typography variant="h4" sx={{ mt: 3 }}>
                Address Details:
              </Typography>
              <Box sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}>
                <TextField
                  fullWidth
                  id="address"
                  label="Address"
                  size="small"
                  value={values?.address}
                  onChange={(e) => setFieldValue("address", e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  fullWidth
                  id="landmark"
                  label="Landmark"
                  size="small"
                  value={values?.landmark}
                  onChange={(e) => setFieldValue("landmark", e.target.value)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                />
                <TextField
                  fullWidth
                  id="pin"
                  label="Pin Code"
                  size="small"
                  value={values?.pin}
                  onChange={(e) => {
                    setFieldValue(
                      "pin",
                      validateIntNum(e.target.value, valueLimits.PIN)
                    );
                    if (e.target.value?.length === 6) {
                      getCityAndState(
                        setFieldValue,
                        touched,
                        setTouched,
                        e.target.value
                      );
                    }
                  }}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  inputProps={{
                    maxLength: 6,
                  }}
                />
                <TextField
                  fullWidth
                  id="city"
                  label="City"
                  size="small"
                  value={values?.city}
                  onChange={(e) => setFieldValue("city", e.target.value)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                />
                <TextField
                  fullWidth
                  id="state"
                  label="State"
                  size="small"
                  value={values?.state}
                  onChange={(e) => setFieldValue("state", e.target.value)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                />
              </Box>

              <Typography variant="h4" sx={{ mt: 3 }}>
                Merchant Details:
              </Typography>
              <Box>
                <MerchantGstValidate
                  gst_no={values.gst_no}
                  company={values.company}
                  handleBlur={handleBlur}
                  errors={{}}
                  touched={{}}
                  setFieldValue={setFieldValue}
                />
                <Stack
                  sx={{
                    mr: { xs: 0, md: 1 },
                  }}
                >
                  <TextField
                    fullWidth
                    id="company_address"
                    label="Address"
                    size="small"
                    value={values?.company_address || ""}
                    onChange={(e) =>
                      setFieldValue("company_address", e.target.value)
                    }
                    sx={{ mt: 1.5 }}
                  />
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }}>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    error={
                      errors.merchant_type && touched.merchant_type
                        ? true
                        : false
                    }
                  >
                    <InputLabel required id="merchant-type-label">
                      Merchant Type
                    </InputLabel>
                    <Select
                      labelId="merchant-type-label"
                      name="merchant_type"
                      id="merchant_type"
                      value={values?.merchant_type}
                      label="Merchant Type"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("merchant_type", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="hospital">Hospital</MenuItem>
                      <MenuItem value="clinic">Clinic</MenuItem>
                    </Select>
                    {errors.merchant_type && touched.merchant_type && (
                      <FormHelperText>{errors.merchant_type}</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    fullWidth
                    id="pan_no"
                    label="PAN NO"
                    size="small"
                    value={values?.pan_no || ""}
                    onChange={(e) => setFieldValue("pan_no", e.target.value)}
                    onBlur={handleBlur}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    helperText={
                      errors.pan_no && touched.pan_no ? errors.pan_no : ""
                    }
                    error={errors.pan_no && touched.pan_no ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <TextField
                    fullWidth
                    id="merchant_name"
                    label="Merchant Name"
                    size="small"
                    value={values?.merchant_name || ""}
                    onChange={(e) =>
                      setFieldValue("merchant_name", e.target.value)
                    }
                    onBlur={handleBlur}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    helperText={
                      errors.merchant_name && touched.merchant_name
                        ? errors.merchant_name
                        : ""
                    }
                    error={
                      errors.merchant_name && touched.merchant_name
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    inputProps={{
                      maxLength: valueLimits.NAME_LEN,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="merchant_mobile"
                    label="Merchant Mobile"
                    size="small"
                    value={values?.merchant_mobile || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "merchant_mobile",
                        validateIntNum(e.target.value, valueLimits.MOBILE)
                      )
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.merchant_mobile && touched.merchant_mobile
                        ? errors.merchant_mobile
                        : ""
                    }
                    error={
                      errors.merchant_mobile && touched.merchant_mobile
                        ? true
                        : false
                    }
                    inputProps={{
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="merchant_pin"
                    label="Pin Code"
                    size="small"
                    value={values?.merchant_pin || ""}
                    onChange={(e) =>
                      setFieldValue(
                        "merchant_pin",
                        validateIntNum(e.target.value, valueLimits.PIN)
                      )
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.merchant_pin && touched.merchant_pin
                        ? errors.merchant_pin
                        : ""
                    }
                    error={
                      errors.merchant_pin && touched.merchant_pin ? true : false
                    }
                    inputProps={{
                      maxLength: 6,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <TextField
                    fullWidth
                    id="account_no"
                    label="Account No"
                    size="small"
                    value={values?.account_no || ""}
                    onChange={(e) =>
                      setFieldValue("account_no", e.target.value)
                    }
                    onBlur={handleBlur}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    helperText={
                      errors.account_no && touched.account_no
                        ? errors.account_no
                        : ""
                    }
                    error={
                      errors.account_no && touched.account_no ? true : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    inputProps={{
                      maxLength: valueLimits.NAME_LEN,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="account_name"
                    label="Account Name"
                    size="small"
                    value={values?.account_name || ""}
                    onChange={(e) =>
                      setFieldValue("account_name", e.target.value)
                    }
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.account_name && touched.account_name
                        ? errors.account_name
                        : ""
                    }
                    error={
                      errors.account_name && touched.account_name ? true : false
                    }
                    inputProps={{
                      maxLength: valueLimits.NAME_LEN,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="bank_name"
                    label="Bank Name"
                    size="small"
                    value={values?.bank_name || ""}
                    onChange={(e) => setFieldValue("bank_name", e.target.value)}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.bank_name && touched.bank_name
                        ? errors.bank_name
                        : ""
                    }
                    error={errors.bank_name && touched.bank_name ? true : false}
                    inputProps={{
                      maxLength: valueLimits.NAME_LEN,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                  <TextField
                    fullWidth
                    id="ifsc_code"
                    label="IFSC Code"
                    size="small"
                    value={values?.ifsc_code || ""}
                    onChange={(e) => setFieldValue("ifsc_code", e.target.value)}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onBlur={handleBlur}
                    helperText={
                      errors.ifsc_code && touched.ifsc_code
                        ? errors.ifsc_code
                        : ""
                    }
                    error={errors.ifsc_code && touched.ifsc_code ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Box>
              </Box>

              <Typography variant="h4" sx={{ mt: 3 }}>
                Order Details:
              </Typography>

              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                sx={{ mt: 1.5 }}
              >
                <TextField
                  id="doctor"
                  label="Doctor Name"
                  size="small"
                  value={values.doctor || ""}
                  onChange={(e) => setFieldValue("doctor", e.target.value)}
                  sx={{
                    mr: { xs: 0, md: 1 },
                    width: { xs: "100%", md: "30%" },
                  }}
                  onBlur={handleBlur}
                />
                <Box
                  width={{ xs: "100%", md: "70%" }}
                  sx={{ ml: { xs: 0, md: 5 } }}
                >
                  <UploadFiles
                    title="Upload Bills & Reports *"
                    uploadedFiles={prescriptionUrls}
                    setUploadedFiles={setPrescriptionUrls}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  mt: 1,
                }}
              >
                <TextField
                  fullWidth
                  id="item"
                  label="Item"
                  size="small"
                  value="Anywhere Cashless Appointment"
                  sx={{
                    mr: { xs: 0, md: 1 },
                  }}
                  onBlur={handleBlur}
                  disabled
                />
                <TextField
                  type="number"
                  fullWidth
                  id="mrp"
                  label="Bill Amount (Rs.)"
                  size="small"
                  value={values.mrp || ""}
                  sx={{
                    mr: { xs: 0, md: 1 },
                  }}
                  onChange={(e) => {
                    setFieldValue("mrp", e.target.value);
                    setFieldValue(
                      "final_payable",
                      Number(e.target.value) -
                        Number(values?.discount || 0) -
                        Number(values?.patient_payable || 0)
                    );
                  }}
                  onBlur={handleBlur}
                  helperText={errors.mrp && touched.mrp ? errors.mrp : ""}
                  error={errors.mrp && touched.mrp ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <TextField
                  type="number"
                  fullWidth
                  id="patient_payable"
                  label="Patient Payable (Rs.)"
                  size="small"
                  value={values?.patient_payable}
                  onChange={(e) => {
                    setFieldValue("patient_payable", e.target.value);
                    setFieldValue(
                      "final_payable",
                      Number(values?.mrp || 0) -
                        Number(values?.discount || 0) -
                        Number(e.target.value)
                    );
                  }}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onBlur={handleBlur}
                  helperText={
                    errors.patient_payable && touched.patient_payable
                      ? errors.patient_payable
                      : ""
                  }
                  error={
                    errors.patient_payable && touched.patient_payable
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    required: true,
                  }}
                />
                <TextField
                  type="number"
                  fullWidth
                  id="discount"
                  label="Discount (Rs.)"
                  size="small"
                  value={values?.discount}
                  onChange={(e) => {
                    setFieldValue("discount", e.target.value);
                    setFieldValue(
                      "final_payable",
                      Number(values?.mrp || 0) -
                        Number(e.target.value) -
                        Number(values?.patient_payable || 0)
                    );
                  }}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onBlur={handleBlur}
                  helperText={
                    errors.discount && touched.discount ? errors.discount : ""
                  }
                  error={errors.discount && touched.discount ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="final_payable"
                  label="Final Payable (Rs.)"
                  size="small"
                  value={values?.final_payable}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
              </Box>

              <Box textAlign="center" sx={{ mt: 3 }}>
                <LoadingButton
                  sx={{ width: "180px" }}
                  size="medium"
                  color="primary"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Submit
                </LoadingButton>
              </Box>
              <PartnerIdChange setFieldValue={setFieldValue} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreateIpdOrder;
