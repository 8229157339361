import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import MerchantGstValidate from "../../components/ManageOrder/GroupOrders/Common/merchantGstValidate";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { updateGstSchema } from "../../schemas/providers.schema";
import { errorToast, handleError } from "../../utils/common";

const EditGstModal = ({
  showEditGstModal,
  setShowEditGstModal,
  providerInfo,
  setProviderInfo,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      location_id: providerInfo?.location_id,
      provider_id: providerInfo?.provider_id,
      update_type: "gst_no",
      gst_no: values?.gst_no,
      gst_name: values?.company,
    };

    setLoading(true);

    try {
      const result = await privateInstance.put(
        `${API_VERSION.V1}/orders/provider`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setShowEditGstModal(false);
        const resultResponse = result.data.response;
        //update provider info in modal
        setProviderInfo({
          ...providerInfo,
          gst_no: resultResponse?.gst_no,
          gst_name: resultResponse?.gst_name,
        });
        //end
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showEditGstModal}
      onClose={() => setShowEditGstModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowEditGstModal(false)}
      >
        Edit GST Details
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            gst_no: providerInfo?.gst_no || "",
            company: "",
          }}
          validationSchema={updateGstSchema}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
        >
          {(props: any) => {
            const { values, touched, errors, handleBlur, setFieldValue } =
              props;
            return (
              <Form>
                <MerchantGstValidate
                  gst_no={values.gst_no}
                  company={values.company}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
                <Box textAlign="center" mt={3}>
                  <LoadingButton
                    size="medium"
                    color="primary"
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditGstModal;
