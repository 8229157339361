import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parse } from "date-fns";
import { TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import { ORDER_STATUS } from "../../../../enums/order.enum";
import {
  API_RESULT,
  API_VERSION,
  USER_ROLE,
} from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import useUserInfo from "../../../../hooks/useUserInfo";
import SendIcon from "@mui/icons-material/Send";
import LogisticCancelModal from "../../../../pages/Logistics/logisticCancelModal";
import LogisticSupportModal from "../../../../pages/Logistics/logisticSupportModal";
import { LogisticDetailView } from "../../../../pages/Logistics/logisticsDetailModal";
import UploadFiles from "../../../Common/uploadFiles";

const DeliveredTimeModal = ({
  deliveredTimeModal,
  setDeliveredTimeModal,
  activeOrderId,
  activePartner,
  activePartnerOrderId,
}: any) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [showSelfDeliveryView, setShowSelfDeliveryView] = useState(false);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [sendOtpOn, setSendOtpOn] = useState("whatsapp");
  const [resendLoading, setResendLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState(false);
  const [orderData, setOrderData] = useState<any>({});
  const [showLogisticsDetailView, setShowLogisticsDetailView] = useState(false);
  const [showLogisticCancelModal, setShowLogisticCancelModal] = useState(false);
  const [showLogisticSupportModal, setShowLogisticSupportModal] =
    useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleSubmit = async () => {
    let payload = {
      delivered_at: format(selectedDate, "dd-MM-yyyy, hh:mm a"),
      order_status: ORDER_STATUS.PACKAGE_DELIVERED,
      order_id: activeOrderId,
      type: "package-delivery-update",
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order status updated as delivered.");
        setDeliveredTimeModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const minDate = () => {
    if (!orderData?.package_picked_at) {
      return new Date();
    }

    let pickedAt = format(
      parse(
        orderData?.package_picked_at?.split(".")[0],
        "dd/MM/yyyy HH:mm:ss",
        new Date()
      ),
      "dd-MM-yyyy, h:mm aa"
    );
    return parse(pickedAt, "dd-MM-yyyy, h:mm aa", new Date());
  };

  const resendOTP = async () => {
    let payload = {
      order_id: orderData?.order_id,
      channel: sendOtpOn,
    };
    setResendLoading(resendLoading);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/resend-otp`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(result.data.message);
        setDeliveredTimeModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setResendLoading(false);
    }
  };

  const getOrderByPartnerOrderId = async () => {
    setLoadingData(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${activePartner}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
        const orderResponse = result.data.response;
        //show self delivery view
        if (
          orderResponse?.merchant_details?.process_mode?.delivery_otp === "y"
        ) {
          setShowSelfDeliveryView(true);
        }
        //show logistics detail view
        if (orderResponse.logistics) {
          setShowLogisticsDetailView(true);
        }
        //populate existing invoice
        if (orderResponse?.invoice_urls?.length > 0) {
          setUploadedFiles(orderResponse.invoice_urls);
        }
      } else {
        setOrderData([]);
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getOrderByPartnerOrderId();

    return () => {
      setOrderData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={deliveredTimeModal}
      onClose={() => setDeliveredTimeModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={showSelfDeliveryView || showLogisticsDetailView ? "md" : "sm"}
    >
      <CustomDialogTitle
        id="custom-dialog-title"
        onClose={() => setDeliveredTimeModal(false)}
      >
        {showLogisticsDetailView
          ? `Logistics - ${orderData?.order_partner} | ${orderData?.partner_order_id}`
          : "Delivered At"}
      </CustomDialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        {loadingData ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            {showLogisticsDetailView ? (
              <>
                <LogisticDetailView orderLogisticsData={orderData.logistics} />
                {orderData?.logistics?.status === "cancelled" ? (
                  <>
                    {user?.role === USER_ROLE.SUPER_ADMIN ||
                    user?.role === USER_ROLE.ADMIN ||
                    user?.package_delivery_updation === "enabled" ? (
                      <Box display="flex" alignItems="center">
                        <Typography mt={2}>
                          This logistics request was cancelled,
                        </Typography>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={() => setShowLogisticsDetailView(false)}
                          sx={{ mt: 2, ml: 2 }}
                        >
                          Process Manually
                        </Button>
                      </Box>
                    ) : null}
                  </>
                ) : (
                  <Stack
                    mt={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {user?.role === USER_ROLE.SUPER_ADMIN ||
                    user?.role === USER_ROLE.ADMIN ||
                    user?.package_delivery_updation === "enabled" ? (
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => setShowLogisticsDetailView(false)}
                        sx={{ mr: 2 }}
                      >
                        Process Manually
                      </Button>
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedOrderId(orderData?.order_id);
                        setShowLogisticSupportModal(true);
                      }}
                    >
                      Support
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setSelectedOrderId(orderData?.order_id);
                        setShowLogisticCancelModal(true);
                      }}
                      sx={{ ml: 2 }}
                    >
                      Cancel
                    </Button>
                  </Stack>
                )}
              </>
            ) : (
              <>
                {orderData?.delivery_slot && (
                  <Box>
                    <Typography variant="body1" mb={2}>
                      Estimated delivery time: {orderData?.delivery_slot}
                    </Typography>
                  </Box>
                )}

                {showSelfDeliveryView ? (
                  <>
                    <Typography variant="body1">
                      Pharmacy opted for self delivery, Please wait until
                      pharmacy mark it out for delivery. Please wait or write an
                      email on <b>techsupport@medpay.in</b>.
                    </Typography>
                    {(user?.role === USER_ROLE.SUPER_ADMIN ||
                      user?.role === USER_ROLE.ADMIN ||
                      user?.package_delivery_updation === "enabled") && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowSelfDeliveryView(false)}
                        sx={{ mt: 2 }}
                      >
                        Process Manually
                      </Button>
                    )}
                    <Divider sx={{ mt: 5 }} />
                    <Stack mt={5} alignItems="center">
                      <Typography variant="body2">
                        Having issues with delivery OTP? Resend OTP on:
                      </Typography>
                      <FormControl sx={{ mb: 1.5 }}>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          onChange={(e) => setSendOtpOn(e.target.value)}
                        >
                          <FormControlLabel
                            value="whatsapp"
                            control={
                              <Radio checked={sendOtpOn === "whatsapp"} />
                            }
                            label="Whatsapp"
                          />
                          <FormControlLabel
                            value="sms"
                            control={<Radio />}
                            label="SMS"
                          />
                        </RadioGroup>
                      </FormControl>
                      <LoadingButton
                        color="primary"
                        variant="outlined"
                        loading={resendLoading}
                        loadingIndicator={
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        }
                        onClick={() => resendOTP()}
                        endIcon={<SendIcon />}
                      >
                        Resend OTP
                      </LoadingButton>
                    </Stack>
                  </>
                ) : (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        open={open}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        label="Select date & time"
                        value={selectedDate}
                        onChange={(newValue: any) => setSelectedDate(newValue)}
                        shouldDisableTime={(
                          timeValue: number,
                          clockType: string
                        ) => {
                          if (clockType === "hours") {
                            return timeValue < 9 || timeValue > 22;
                          }
                          return false;
                        }}
                        ampm={false}
                        minDateTime={minDate()}
                        maxDateTime={new Date()}
                        inputFormat="dd-MM-yyyy, hh:mm a"
                        disableMaskedInput
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            name="time_slot"
                            size="small"
                            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                            error={false}
                            onClick={(e) => setOpen(true)}
                            autoComplete="off"
                            onKeyDown={(e: any) => {
                              e.preventDefault();
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Stack mt={4}>
                      <UploadFiles
                        title="Upload Invoice"
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        orderId={orderData?.order_id}
                        allowDel={false}
                      />
                    </Stack>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      loading={loading}
                      disabled={
                        !selectedDate ||
                        (user?.role === USER_ROLE.EDITOR &&
                          uploadedFiles.length === 0)
                      }
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      onClick={() => handleSubmit()}
                      sx={{ mt: 3 }}
                    >
                      Submit
                    </LoadingButton>
                    {user?.role === USER_ROLE.EDITOR &&
                    uploadedFiles.length === 0 ? (
                      <Typography color="error" variant="body2" pt={1.5}>
                        Note: Pharmacy invoice is mandatory to close this order.
                      </Typography>
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
        )}

        {showLogisticCancelModal && (
          <LogisticCancelModal
            showLogisticCancelModal={showLogisticCancelModal}
            setShowLogisticCancelModal={setShowLogisticCancelModal}
            activeOrderId={selectedOrderId}
            updateFn={getOrderByPartnerOrderId}
          />
        )}
        {showLogisticSupportModal && (
          <LogisticSupportModal
            showLogisticSupportModal={showLogisticSupportModal}
            setShowLogisticSupportModal={setShowLogisticSupportModal}
            activeOrderId={selectedOrderId}
            supportHistory={[]}
            updateFn={getOrderByPartnerOrderId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeliveredTimeModal;
