import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  shouldShowOrderData,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import OrderCustomerInfo from "../Common/orderCustomerInfo";
import CommonDataView from "./commonDataView";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import OrderPlatform from "../../../Common/orderPlatform";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { useDispatch, useSelector } from "react-redux";
import OrderData from "../../../OrderDetails/orderData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchAndAssign from "../Pending/searchAndAssign";
import CancelOrderModal from "../../../Common/cancelOrderModal";

const AcceptOrderModal = ({
  acceptOrdarModal,
  setAcceptOrderModal,
  activePartner,
  activePartnerOrderId,
  activeOrderId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [loadingUnassign, setLoadingUnassign] = useState(false);
  const privateInstance = useAxiosInstance();
  const [orderData, setOrderData] = useState<any>({});
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [reAssignView, setReAssignView] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  useEffect(() => {
    const getOrderByPartnerOrderId = async () => {
      setLoading(true);
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/partner/${String(
            activePartner
          )?.toLowerCase()}/${activePartnerOrderId}`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setOrderData(result.data.response);
        } else {
          setOrderData([]);
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    getOrderByPartnerOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUnassignOrder = async (order_id: string) => {
    setLoadingUnassign(true);

    let payload = {
      order_id: order_id,
      type: "assigned-rollback",
    };
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order state changed to pending");
        setAcceptOrderModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingUnassign(false);
    }
  };

  const clinicsTableCols = ["NAME", "PHONE NO", "ADDRESS"];
  const diagnosticsTableCols = ["NAME", "MOBILE NO", "ADDRESS"];
  const medicinesTableCols = [
    "PHARMACY NAME",
    "PHONE NO",
    "VERIFIED",
    "ADDRESS",
    "DIRECTION",
    "ASSIGNED AT",
    "STATUS",
  ];

  return (
    <Dialog
      open={acceptOrdarModal}
      onClose={() => setAcceptOrderModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setAcceptOrderModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Typography variant="h3">Accept Order</Typography>
            {Object.keys(orderData).length > 0 ? (
              <>
                {orderData.order_type === ORDER_TYPE.MEDICINES ? null : (
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    size="small"
                    loading={loadingUnassign}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    onClick={() => handleUnassignOrder(activeOrderId)}
                    sx={{ ml: 1 }}
                  >
                    Unassign Order
                  </LoadingButton>
                )}
              </>
            ) : null}
          </Stack>
          {orderData && orderData.platform && (
            <OrderPlatform platform={orderData.platform} />
          )}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <OrderCustomerInfo
              orderData={orderData}
              setOrderData={setOrderData}
              setParentModal={setAcceptOrderModal}
            />
            {orderData?.order_type !== ORDER_TYPE.MEDICINES && (
              <Box sx={{ textAlign: "center", mt: 3 }}>
                <Typography variant="h6">
                  Requested appointment time: {orderData?.time_slot}
                </Typography>
              </Box>
            )}
            <Box sx={{ mt: 3 }}>
              <Typography variant="h3">
                {orderData?.order_type === ORDER_TYPE.CONSULTATION
                  ? "Clinic Details"
                  : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                  ? "Lab Details"
                  : ""}
              </Typography>
              {orderData?.order_type !== ORDER_TYPE.CONSULTATION && (
                <Accordion
                  sx={{
                    my: 2,
                    boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h4">
                      {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                        ? "Diagnostics"
                        : "Medicines"}{" "}
                      details
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {shouldShowOrderData(orderData) && (
                      <OrderData
                        orderData={orderData}
                        showHeading={false}
                        notAdminPanel={true}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              <CommonDataView
                merchant_details={
                  orderData?.order_type === ORDER_TYPE.MEDICINES
                    ? orderData?.nearby_merchants
                    : orderData?.merchant_details
                }
                cols={
                  orderData?.order_type === ORDER_TYPE.CONSULTATION
                    ? clinicsTableCols
                    : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                    ? diagnosticsTableCols
                    : medicinesTableCols
                }
                orderType={orderData?.order_type}
                orderId={orderData?.order_id}
                customerDetails={orderData?.customer_details}
              />
              {orderData?.order_type === ORDER_TYPE.MEDICINES &&
              !reAssignView ? (
                <Box textAlign="center" mt={2.5}>
                  <Button
                    onClick={() => setReAssignView(true)}
                    variant="contained"
                    disabled={
                      orderData?.order_status ===
                        ORDER_STATUS.PARTNER_PENDING ||
                      orderData?.delivery_partner?.toLowerCase() === "logistics"
                        ? true
                        : false
                    }
                  >
                    Re-Assign Provider
                  </Button>
                  {/* PARTNER SPECIFIC: MEDPAY */}
                  {["medpay", "medpay_store"].includes(
                    orderData?.order_partner
                  ) ? (
                    <Button
                      onClick={() => setCancelModal(true)}
                      variant="contained"
                      color="error"
                      sx={{ ml: 1.5 }}
                    >
                      Cancel Order
                    </Button>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </>
        )}
        {reAssignView && (
          <SearchAndAssign
            orderData={orderData}
            assignType="re-assign"
            setAssignProviderModal={setReAssignView}
            setParentOrderData={setOrderData}
          />
        )}
        {cancelModal && (
          <CancelOrderModal
            cancelModal={cancelModal}
            setCancelModal={setCancelModal}
            orderId={orderData?.order_id}
            orderType={orderData?.order_type}
            closeParentModal={setAcceptOrderModal}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AcceptOrderModal;
