import { Box, CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import {
  filterByKeyNotValue,
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
  handleError,
} from "../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import SideStackedBarGraph from "../../Metrics/Charts/SideStackedBarGraph";

const StatusWiseMetrics = ({
  selectedMonth,
  selectedPartner,
  graphTitle,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getMetricsData = async () => {
    let payload = {
      month: Number(selectedMonth),
      year: 2024,
      metric_type: "status_wise_metrics",
      order_type: "medicines",
      partner_id: selectedPartner,
    };
    setLoading(true);
    try {
      const result: any = await instance.post(
        `${config.URL}${API_VERSION.V2}/analytics/partner-apis`,
        payload
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setMetricData(result.data.response["status_wise_metrics"]);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedPartner]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(
      new Date(new Date().getFullYear(), Number(selectedMonth) - 1, 1),
      new Date(new Date().getFullYear(), Number(selectedMonth), 0)
    );

    const total200Count: any = [];
    const totalNon200Count: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(rawData, "date_label", el);

      let successCount: any = getCountByKey(
        filterByKeyValue(filteredDataByLabel, "status", 200),
        "count"
      );

      let failedCount: any = getCountByKey(
        filterByKeyNotValue(filteredDataByLabel, "status", 200),
        "count"
      );

      total200Count.push(successCount);
      totalNon200Count.push(failedCount);
    });

    const datasets = [
      {
        label: "Success",
        data: total200Count,
        borderColor: "#4CAF50",
        backgroundColor: "#4CAF50",
      },
      {
        label: "Failed",
        data: totalNon200Count,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box
      width="100%"
      sx={{
        padding: "20px",
      }}
    >
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
          }}
        >
          <Box textAlign="right">
            <Tooltip title={tooltipText}>
              <InfoIcon color="primary" fontSize="small" />
            </Tooltip>
          </Box>
          {genData && (
            <SideStackedBarGraph
              title={graphTitle}
              data={genData}
              dataLabelPos="end"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default StatusWiseMetrics;
