import React from "react";
import { Box, TextField } from "@mui/material";
import {
  errorToast,
  handleError,
  textCapitalize,
  validateIntNum,
} from "../../utils/common";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { valueLimits } from "../../constants";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";

const AddressDetail = ({
  setFieldValue,
  errors,
  touched,
  handleBlur,
  disableField = false,
  formData,
  setTouched,
  serviceabilityCheck = false,
  showLandmarkField = true,
}: any) => {
  const { address, landmark, pin, city, state } = formData;
  const privateInstance = useAxiosInstance();

  React.useEffect(() => {
    if (pin?.length === 6) {
      getCityAndState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const getCityAndState = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/providers/address/decode-pincode/${pin}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (serviceabilityCheck) {
          if (result.data.response?.serviceable === "Y") {
            setFieldValue("city", textCapitalize(result.data?.response?.city));
            setFieldValue("state", result.data?.response?.state);
            setTouched({ ...touched, city: false, state: false });
          } else {
            setFieldValue("city", "");
            setFieldValue("state", "");
            errorToast(`Pincode(${pin}) is not serviceable`);
          }
        } else {
          setFieldValue("city", textCapitalize(result.data?.response?.city));
          setFieldValue("state", result.data?.response?.state);
          setTouched({ ...touched, city: false, state: false });
        }
      } else {
        setFieldValue("city", "");
        setFieldValue("state", "");
        if (result.data.message?.toLowerCase()?.includes("server error")) {
          errorToast(`Pincode (${pin}) is not serviceable`);
        } else {
          errorToast(result.data.message);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };
  return (
    <>
      <Box sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}>
        <TextField
          fullWidth
          id="address"
          label="Address"
          size="small"
          value={address || ""}
          onChange={(e) => setFieldValue("address", e.target.value)}
          onBlur={handleBlur}
          helperText={errors.address && touched.address ? errors.address : ""}
          error={errors.address && touched.address ? true : false}
          disabled={disableField}
          InputLabelProps={{
            required: true,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {showLandmarkField ? (
          <TextField
            fullWidth
            id="landmark"
            label="Landmark"
            size="small"
            value={landmark || ""}
            onChange={(e) => setFieldValue("landmark", e.target.value)}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onBlur={handleBlur}
            helperText={
              errors.landmark && touched.landmark ? errors.landmark : ""
            }
            error={errors.landmark && touched.landmark ? true : false}
            disabled={disableField}
            InputLabelProps={{
              required: true,
            }}
          />
        ) : null}
        <TextField
          fullWidth
          id="pin"
          label="Pin Code"
          size="small"
          value={pin || ""}
          onChange={(e) =>
            setFieldValue(
              "pin",
              validateIntNum(e.target.value, valueLimits.PIN)
            )
          }
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          onBlur={handleBlur}
          helperText={errors.pin && touched.pin ? errors.pin : ""}
          error={errors.pin && touched.pin ? true : false}
          inputProps={{
            maxLength: 6,
          }}
          disabled={disableField}
          InputLabelProps={{
            required: true,
          }}
        />
        <TextField
          fullWidth
          id="city"
          label="City"
          size="small"
          value={city || ""}
          onChange={(e) => setFieldValue("city", e.target.value)}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          onBlur={handleBlur}
          helperText={errors.city && touched.city ? errors.city : ""}
          error={errors.city && touched.city ? true : false}
          disabled={disableField}
          InputLabelProps={{
            required: true,
          }}
        />
        <TextField
          fullWidth
          id="state"
          label="State"
          size="small"
          value={state || ""}
          onChange={(e) => setFieldValue("state", e.target.value)}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          onBlur={handleBlur}
          helperText={errors.state && touched.state ? errors.state : ""}
          error={errors.state && touched.state ? true : false}
          disabled={disableField}
          InputLabelProps={{
            required: true,
          }}
        />
      </Box>
    </>
  );
};

export default AddressDetail;
