import {
  Badge,
  CircularProgress,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { commonTableOptions, logisticsPartners } from "../../constants";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import LogisticsDetailModal from "./logisticsDetailModal";
import CancelIcon from "@mui/icons-material/Cancel";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogisticCancelModal from "./logisticCancelModal";
import LogisticSupportModal from "./logisticSupportModal";
import { customReplaceAll, getMuiTheme } from "../../utils/common";

const LogisticsReqTable = ({ data, updateFn, isLoading }: any) => {
  const [activePartner, setActivePartner] = useState("");
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [showLogisticsDetailModal, setShowLogisticsDetailModal] =
    useState(false);
  const [activeOrderId, setActiveOrderId] = useState("");
  const [activeDeliveryPartner, setActiveDeliveryPartner] = useState("");
  const [showLogisticCancelModal, setShowLogisticCancelModal] = useState(false);
  const [showLogisticSupportModal, setShowLogisticSupportModal] =
    useState(false);
  const [supportHistory, setSupportHistory] = useState([]);

  const paymentReqCols = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "order_partner",
      label: "ORDER PARTNER",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "order_type",
      label: "ORDER TYPE",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "delivery_partner",
      label: "DELIVERY PARTNER",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "task_id",
      label: "TASK ID",
      options: {
        filter: false,
      },
    },
    {
      name: "state",
      label: "STATE",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "reference_id",
      label: "REF ID",
      options: {
        filter: false,
      },
    },
    {
      name: "support_history",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "",
      label: "ACTION",
      options: {
        filter: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any, dataIndex: any) => {
      return (
        <CustomRowComponent key={data[0]} rowData={data} index={dataIndex} />
      );
    },
    searchPlaceholder: "Partner Order ID/Task ID/REF ID",
  };

  function CustomRowComponent({ rowData, index }: any) {
    const [
      partner_order_id,
      order_partner,
      order_type,
      delivery_partner,
      task_id,
      state,
      reference_id,
      support_history,
    ] = rowData;
    return (
      <TableRow hover key={task_id}>
        <TableCell>
          <Typography
            variant="body1"
            sx={{ color: "#0058ff", cursor: "pointer", mr: 0.25 }}
            onClick={() => {
              setActivePartner(order_partner);
              setActivePartnerOrderId(partner_order_id);
              setShowLogisticsDetailModal(true);
            }}
          >
            {partner_order_id}
          </Typography>
        </TableCell>
        <TableCell>{order_partner}</TableCell>
        <TableCell>{customReplaceAll(order_type, "_", " ")}</TableCell>
        <TableCell>{delivery_partner}</TableCell>
        <TableCell>{task_id}</TableCell>
        <TableCell
          sx={{
            color:
              state === "delivered"
                ? "#0A8043"
                : state === "cancelled"
                ? "#D32F2F"
                : "#F9AA00",
          }}
        >
          {state?.split("_")?.join(" ")}
        </TableCell>
        <TableCell>{reference_id}</TableCell>
        <TableCell>
          <Stack direction="row" justifyContent="space-between">
            {logisticsPartners.find(
              (x) => x.value === delivery_partner?.toLowerCase()
            )?.support ? (
              <Tooltip title="Support">
                <IconButton
                  onClick={() => {
                    setActiveOrderId(reference_id);
                    setShowLogisticSupportModal(true);
                    setSupportHistory(support_history);
                  }}
                >
                  <Badge
                    badgeContent={support_history ? support_history.length : 0}
                    color="error"
                  >
                    <ContactSupportIcon sx={{ color: "#0058ff" }} />
                  </Badge>
                </IconButton>
              </Tooltip>
            ) : null}
            {state?.toLowerCase() === "cancelled" ? null : (
              <Tooltip title="Cancel">
                <IconButton
                  onClick={() => {
                    setActiveOrderId(reference_id);
                    setActiveDeliveryPartner(delivery_partner);
                    setShowLogisticCancelModal(true);
                  }}
                >
                  <CancelIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title=""
          data={data}
          columns={paymentReqCols}
          options={options}
        />
      </ThemeProvider>
      {showLogisticsDetailModal && (
        <LogisticsDetailModal
          activePartner={activePartner}
          activePartnerOrderId={activePartnerOrderId}
          showLogisticsDetailModal={showLogisticsDetailModal}
          setShowLogisticsDetailModal={setShowLogisticsDetailModal}
        />
      )}
      {showLogisticCancelModal && (
        <LogisticCancelModal
          showLogisticCancelModal={showLogisticCancelModal}
          setShowLogisticCancelModal={setShowLogisticCancelModal}
          activeOrderId={activeOrderId}
          activeDeliveryPartner={activeDeliveryPartner}
          updateFn={updateFn}
        />
      )}
      {showLogisticSupportModal && (
        <LogisticSupportModal
          showLogisticSupportModal={showLogisticSupportModal}
          setShowLogisticSupportModal={setShowLogisticSupportModal}
          activeOrderId={activeOrderId}
          supportHistory={supportHistory}
          updateFn={updateFn}
        />
      )}
    </ErrorBoundary>
  );
};

export default LogisticsReqTable;
