/* eslint-disable no-unused-vars */
import React from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({
  title,
  data,
  metricData = [],
  additionalOptions = {},
}: any) => {
  const innerW = window?.innerWidth;
  const graphOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
        padding: {
          bottom: 30,
        },
      },
      datalabels: {
        anchor: "end",
        align: "top",
        display: "auto",
        // formatter: (_, __) => {
        //   return "";
        // },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            if (title.toLowerCase().includes("call")) {
              let tooltipText = "";
              const metricsDataForLabel = metricData.find(
                (x: any) =>
                  x.delivery_date === context.label &&
                  x.fulfilled_channel === context.dataset.label
              );
              if (metricsDataForLabel) {
                tooltipText = `${context.dataset.label} | Calls: ${
                  metricsDataForLabel.total_call_count
                },  Calls Sum: ${metricData.reduce(
                  (acc: number, curr: any) =>
                    acc +
                    (curr.fulfilled_channel === context.dataset.label
                      ? curr.total_call_count
                      : 0),
                  0
                )}`;
              }
              return tooltipText;
            } else {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y;
              }
              if (
                context.dataset.data !== null &&
                context.dataset.data.length !== 0
              ) {
                if (title.includes("%") || title.includes("h.m")) {
                  label +=
                    ", Average:" +
                    Number(
                      context.dataset.data.reduce(
                        (acc: any, curr: any) => Number(acc) + Number(curr),
                        0
                      ) / context.dataset.data.length
                    ).toFixed(2);
                } else {
                  label +=
                    ", Total:" +
                    context.dataset.data.reduce(
                      (acc: any, curr: any) => Number(acc) + Number(curr),
                      0
                    );
                }
              }
              return label;
            }
          },
        },
      },
      ...additionalOptions,
    },
    scales: {
      x: {
        display: innerW > 1024,
      },
    },
  };

  return <Line options={graphOptions} data={data} />;
};

export default LineChart;
