import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { Mail } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { reportsEmailTimeInterval } from "../../constants";
import { differenceInSeconds } from "date-fns";
import CountdownTimer from "../../components/Reports/countdownTimer";
import EmailModal from "./emailModal";
import ReportsTable from "./reportsTable";

const AuditReport = () => {
  const [reportModal, setReportModal] = useState<boolean>(false);
  const [disableMailBtn, setDisableMailBtn] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  const auditEmailTime_Store = useSelector(
    (state: any) => state.reportTime.audit
  );

  useEffect(() => {
    if (auditEmailTime_Store != null && auditEmailTime_Store !== "") {
      const secondsDiff = differenceInSeconds(
        new Date(),
        new Date(auditEmailTime_Store)
      );
      if (secondsDiff > reportsEmailTimeInterval) {
        setDisableMailBtn(false);
      } else {
        setDisableMailBtn(true);
        if (reportsEmailTimeInterval - secondsDiff > 0) {
          setTimeRemaining(reportsEmailTimeInterval - secondsDiff);
        }
      }
    }
  }, [auditEmailTime_Store]);

  return (
    <>
      <Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ float: "right" }}
          startIcon={<Mail />}
          onClick={() => setReportModal(!reportModal)}
          disabled={disableMailBtn}
        >
          Mail Report{" "}
          {disableMailBtn ? (
            <CountdownTimer
              timeRemaining={timeRemaining}
              setDisableMailBtn={setDisableMailBtn}
            />
          ) : (
            ""
          )}
        </Button>
      </Box>
      <Box sx={{ mt: { xs: 6.5, md: 7.5 } }}>
        <ReportsTable reportType="audit_report" />
      </Box>
      <EmailModal
        reportModal={reportModal}
        setReportModal={setReportModal}
        setTimeRemaining={setTimeRemaining}
        reportType="audit"
      />
    </>
  );
};

export default AuditReport;
