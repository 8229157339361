import React, { Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  errorToast,
  getCancellationOptions,
  handleError,
  successToast,
} from "../../utils/common";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomDialogTitle from "./customDialogTitle";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../store/actions/useActions";
import { ORDER_TYPE } from "../../enums/order.enum";
import {
  cancellationReasonsForPharmacyEka,
  cancellationReasonsForPharmacyPartners,
} from "../../constants";

interface IPropsCancelOrderModal {
  cancelModal: boolean;
  setCancelModal: Dispatch<SetStateAction<boolean>>;
  orderId: string;
  orderType: string;
  updateDataFn?: Function;
  closeParentModal?: Dispatch<SetStateAction<boolean>>;
}

const CancelOrderModal = ({
  cancelModal,
  setCancelModal,
  orderId,
  orderType,
  updateDataFn,
  closeParentModal,
}: IPropsCancelOrderModal) => {
  const [showReason, setShowReason] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [loading, setLoading] = useState(false);
  const user: AuthenticatedUser = useUserInfo();

  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  React.useEffect(() => {
    return () => {
      setShowReason(false);
      setCancellationReason("");
    };
  }, []);

  interface CancelOrder {
    order_id: string;
    cancelled_reason: string;
  }

  const handleCancel = async () => {
    let payload: CancelOrder = {
      order_id: orderId,
      cancelled_reason: cancellationReason,
    };

    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/cancel-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(result.data.message);
        setCancelModal(false);
        //update
        if (updateDataFn) {
          updateDataFn();
        } else {
          dispatch(
            setAllOrderData(
              ordersToManage.filter((x: any) => x.order_id !== orderId)
            )
          );
        }
        //close parent modal
        if (closeParentModal) {
          closeParentModal(false);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOPDCancellation = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/opd/payment/reject-bill/${orderId}?modified_by=${user?.user_id}&reject_reason=${cancellationReason}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order rejected successfully");
        setCancelModal(false);
        //update
        if (updateDataFn) {
          updateDataFn();
        } else {
          dispatch(
            setAllOrderData(
              ordersToManage.filter((x: any) => x.transaction_id !== orderId)
            )
          );
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  //PARTNER SPECIFIC: EKACARE
  const cancellationReasonsPartner =
    user?.partner === "ekacare"
      ? cancellationReasonsForPharmacyEka
      : cancellationReasonsForPharmacyPartners;

  return (
    <Dialog
      open={cancelModal}
      onClose={() => setCancelModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setCancelModal(false)}
      >
        {showReason
          ? orderType === "opd"
            ? "Reason for rejecting the bill"
            : "Reason for cancelling the order:"
          : orderType === "opd"
          ? "Are you sure you want to reject this bill?"
          : "Are you sure you want to cancel this order?"}
      </CustomDialogTitle>
      <DialogContent>
        {showReason ? (
          <FormControl fullWidth size="small" sx={{ mt: 1.5 }}>
            <InputLabel id="reason-label">Reason</InputLabel>
            {orderType === ORDER_TYPE.MEDICINES &&
            user?.role === USER_ROLE.PARTNER ? (
              <Select
                labelId="reason-label"
                name="reason"
                id="reason"
                label="Reason"
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
              >
                {cancellationReasonsPartner.sort().map((x) => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                labelId="reason-label"
                name="reason"
                id="reason"
                label="Reason"
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
              >
                {getCancellationOptions(orderType)?.map((x) => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        ) : (
          <Typography variant="body2">
            {orderType === "opd"
              ? "if you reject this bill, the bill will not be processed further."
              : "if you cancel an order, the order will not be processed further."}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCancelModal(false)}>Close</Button>
        {showReason ? (
          <LoadingButton
            color="primary"
            variant="contained"
            loading={loading}
            disabled={!cancellationReason}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={() =>
              orderType === "opd" ? handleOPDCancellation() : handleCancel()
            }
            sx={{ mr: 2 }}
          >
            Submit
          </LoadingButton>
        ) : (
          <Button onClick={() => setShowReason(true)}>Ok</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CancelOrderModal;
