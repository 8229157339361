import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import {
  errorToast,
  handleError,
  successToast,
} from "../../../../utils/common";

const UpdateStatusModal = ({
  updateStatusModal,
  setUpdateStatusModal,
  orderId,
}: any) => {
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const updateStatus = async () => {
    setLoading(true);

    let payload = {
      order_id: orderId,
      type: "store-profile-update",
      status: selectedStatus,
    };
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Status Updated");
        setUpdateStatusModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={updateStatusModal}
      onClose={() => setUpdateStatusModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setUpdateStatusModal(false)}
      >
        Update Status
      </CustomDialogTitle>
      <DialogContent>
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        >
          <InputLabel id="status-label">Select Status</InputLabel>
          <Select
            labelId="status-label"
            name="status"
            id="status"
            value={selectedStatus}
            label="Select Status"
            onChange={(e: SelectChangeEvent) => {
              setSelectedStatus(e.target.value);
            }}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            loading={loading}
            disabled={!selectedStatus}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={updateStatus}
            sx={{ ml: 1 }}
          >
            Update
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateStatusModal;
