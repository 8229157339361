import {
  Box,
  Chip,
  CircularProgress,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  a11yProps,
  handleError,
  parseAndFormateDate,
} from "../../../utils/common";
import TabPanel from "../../../components/UI/tabPanel";
import Summary from "./Summary";
import MerchantTransactions from "./Transactions";
import MerchantSettlements from "./Settlements";
import MerchantTickets from "./Tickets";
import MedpayQr from "./MedpayQr";
import KnowlarityCall from "../../../components/Common/knowlarityCall";
import CallMadeIcon from "@mui/icons-material/CallMade";

const initialDateRange = {
  label: "Today",
  startDate: new Date(),
  endDate: new Date(),
};

const SingleMerchant = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [txnData, setTxnData] = useState<any>([]);
  const [settlementData, setSettlementData] = useState<any>([]);
  const [medpayQrData, setMedpayQrData] = useState([]);
  const [issuesData, setIssuesData] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const params = useParams();
  const activeLocationId = params?.locationId;
  const location = useLocation();
  const activeTab: any = new URLSearchParams(location.search).get("tab") || 1;
  const [value, setValue] = useState(Number(activeTab - 1));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [dateRangeTxn, setDateRangeTxn] = useState<any>(initialDateRange);
  const [dateRangeSettlement, setDateRangeSettlement] =
    useState<any>(initialDateRange);
  const [visitedTabs, setVisitedTabs] = useState<number[]>([]);

  const getProviderInfo = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/provider?location_id=${activeLocationId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProviderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateVisitedTabs = (tab: number, updateType: string) => {
    if (updateType === "add") {
      setVisitedTabs([...(new Set([...visitedTabs, tab]) as any)]);
    } else {
      setVisitedTabs(visitedTabs.filter((t) => t !== tab));
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {data ? (
            <Box>
              <Box
                p={2}
                sx={{ backgroundColor: "#f6f6f6" }}
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Stack>
                  <Box display="flex" alignItems="baseline" gap={0.75}>
                    <Stack>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "20px !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {data?.provider_name}
                      </Typography>
                      {data?.medpay_store_enabled ? (
                        <Link
                          target="_blank"
                          href={data?.store_link}
                          display="flex"
                          alignItems="center"
                          gap={0.25}
                        >
                          <Typography variant="body2">medpay.store</Typography>
                          <CallMadeIcon
                            sx={{ fontSize: "16px" }}
                            color="primary"
                          />
                        </Link>
                      ) : null}
                    </Stack>
                    <Stack direction="row" gap={0.75}>
                      {/* {data.static_qr_enabled ? (
                        <Tooltip title="MedPay QR Pharmacy">
                          <QrCode2Icon fontSize="small" color="primary" />
                        </Tooltip>
                      ) : null} */}
                      <Box sx={{ mt: 0.25 }}>
                        <KnowlarityCall
                          order={{
                            order_id: params?.locationId as string,
                            mobile: data?.whatsapp_number,
                          }}
                          showNumber={false}
                          renderSmallIcon={true}
                          service="merchant"
                        />
                      </Box>
                      <Chip
                        size="small"
                        label={data?.status}
                        sx={{
                          textTransform: "capitalize",
                          backgroundColor:
                            data?.status === "active" ? "#C6F6D5" : "#C53030",
                          padding: "6px",
                          color: data?.status === "active" ? "#000" : "#fff",
                        }}
                      />
                    </Stack>
                  </Box>
                </Stack>
                {/* <Stack>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px !important", color: "#828282" }}
                  >
                    Merchant ID
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {data?.location_id}
                  </Typography>
                </Stack> */}
                <Box sx={{ display: "flex", gap: 5 }}>
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px !important",
                        color: "#828282",
                      }}
                    >
                      Address
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {data?.city}, {data?.state} - {data?.pin_code}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px !important", color: "#828282" }}
                    >
                      Last App Active
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      {parseAndFormateDate(data?.last_app_active_at)}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
              <>
                {/* TODO:refactor tabs into a function */}
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  sx={{
                    backgroundColor: "#f6f6f6",
                  }}
                >
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Summary"
                    {...a11yProps(0)}
                    onClick={() => navigate(`/merchants/${activeLocationId}`)}
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="MedPay QR"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=2`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Transactions"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=3`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Settlements"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=4`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="Tickets"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=5`)
                    }
                  />
                  <Tab
                    sx={{ fontSize: "12px" }}
                    label="MedPay Store"
                    {...a11yProps(1)}
                    onClick={() =>
                      navigate(`/merchants/${activeLocationId}?tab=6`)
                    }
                  />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Summary providerInfo={data} setProviderInfo={setData} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MedpayQr
                    data={medpayQrData}
                    setData={setMedpayQrData}
                    visitedTabs={visitedTabs}
                    updateVisitedTabs={updateVisitedTabs}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <MerchantTransactions
                    providerInfo={data}
                    dateRangeTxn={dateRangeTxn}
                    setDateRangeTxn={setDateRangeTxn}
                    data={txnData}
                    setData={setTxnData}
                    visitedTabs={visitedTabs}
                    updateVisitedTabs={updateVisitedTabs}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <MerchantSettlements
                    dateRangeSettlement={dateRangeSettlement}
                    setDateRangeSettlement={setDateRangeSettlement}
                    data={settlementData}
                    setData={setSettlementData}
                    visitedTabs={visitedTabs}
                    updateVisitedTabs={updateVisitedTabs}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <MerchantTickets
                    issuesData={issuesData}
                    setIssuesData={setIssuesData}
                    visitedTabs={visitedTabs}
                    updateVisitedTabs={updateVisitedTabs}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "60vh",
                    }}
                  >
                    <Typography variant="h5">Coming Soon!</Typography>
                  </Box>
                </TabPanel>
              </>
            </Box>
          ) : null}
        </>
      )}
    </div>
  );
};

export default SingleMerchant;
