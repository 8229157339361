import {
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import { commonTableOptions } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  getMuiTheme,
  handleError,
  successToast,
} from "../../../utils/common";
import ProviderInfoModal from "./providerInfoModal";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KnowlarityCall from "../../../components/Common/knowlarityCall";

const DunzoProviders = () => {
  const [providersData, setProvidersData] = useState<any>([]);
  const privateInstance = useAxiosInstance();
  const [loading, setLoading] = useState(false);
  const [showProviderInfoModal, setShowProviderInfoModal] = useState(false);
  const [activeLocationId, setActiveLocationId] = useState("");

  const getDunzoProviders = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/dunzo/providers`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProvidersData(
          result.data.response?.filter((x: any) => x.status !== "disabled")
        );
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDunzoProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatus = async (locId: string, currStatus: string) => {
    if (window.confirm("Are you sure?")) {
      let payload = {
        location_id: locId,
        update_type: "provider_status",
        status: currStatus === "active" ? "inactive" : "active",
      };

      try {
        const result = await privateInstance.put(
          `${API_VERSION.V1}/orders/dunzo/provider`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast("Status updated successfully");
          //update
          let newData: any = [];
          providersData.forEach((el: any) => {
            if (el.location_id === locId) {
              el.status = result.data.response?.status;
            }
            newData.push(el);
          });
          setProvidersData(newData);
          //end
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      }
    }
  };

  const providersCols = [
    {
      name: "association_display_name",
      label: "DUNZO STORE NAME",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row" alignItems="center">
              <Typography
                onClick={() => {
                  setShowProviderInfoModal(true);
                  setActiveLocationId(
                    providersData[tableMeta.rowIndex]?.location_id
                  );
                }}
                variant="body2"
                color="primary"
                sx={{ cursor: "pointer" }}
              >
                {value}
              </Typography>
              <Tooltip
                title={
                  providersData[tableMeta.rowIndex]?.open_now
                    ? "Open Now"
                    : "Closed"
                }
              >
                <FiberManualRecordIcon
                  fontSize="small"
                  color={
                    providersData[tableMeta.rowIndex]?.open_now
                      ? "success"
                      : "error"
                  }
                  sx={{ ml: 0.5 }}
                />
              </Tooltip>
            </Stack>
          );
        },
      },
    },
    {
      name: "provider_name",
      label: "PROVIDER NAME",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row" alignItems="center">
              <Typography>{value}</Typography>
              <KnowlarityCall
                order={{
                  order_id: providersData[tableMeta.rowIndex]?.location_id,
                  mobile: providersData[tableMeta.rowIndex]?.whatsapp_number,
                }}
                showNumber={false}
              />
            </Stack>
          );
        },
      },
    },
    {
      name: "settlement_mode",
      label: "SETTLEMENT MODE",
      options: {
        filter: true,
        serachable: false,
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: string, tableMeta: any) => {
          return (
            <FormControlLabel
              label={value}
              sx={{ mt: 1.5, color: value === "active" ? "#2F7C31" : "" }}
              control={
                <Switch
                  id="store_status"
                  checked={value === "active" ? true : false}
                  color="success"
                  onChange={() => {
                    handleStatus(
                      providersData[tableMeta.rowIndex]?.location_id,
                      value
                    );
                  }}
                />
              }
            />
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    rowsPerPageOptions: [50, 100, 200, 300],
    rowsPerPage: 300,
    searchPlaceholder: "Dunzo Store Name/Mobile",
  };

  return (
    <ErrorBoundary>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title=""
          data={providersData}
          columns={providersCols}
          options={options}
        />
      </ThemeProvider>
      {showProviderInfoModal && (
        <ProviderInfoModal
          showProviderInfoModal={showProviderInfoModal}
          setShowProviderInfoModal={setShowProviderInfoModal}
          activeLocationId={activeLocationId}
          providersData={providersData}
          setProvidersData={setProvidersData}
        />
      )}
    </ErrorBoundary>
  );
};

export default DunzoProviders;
