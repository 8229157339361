import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import { ORDER_TYPE } from "../../enums/order.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import {
  getOrderByOrderIdSchema,
  getOrderByPartnerOrderIdSchema,
  getOrderByPartnerOrderIdSchemaManage,
} from "../../schemas/common";
import { setAllOrderData } from "../../store/actions/useActions";
import { errorToast, handleError } from "../../utils/common";
import PartnersAutocomplete from "../Common/partnersAC";

const OrderByPartnerId = ({
  from,
  closeSearchModal,
}: {
  from?: string;
  closeSearchModal?: any;
}) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const user: AuthenticatedUser = useUserInfo();
  const location = useLocation();
  const searchText = useSelector((state: any) => state.search.data);
  const [searchBy, setSearchBy] = useState<string>("partner_order_id");

  const getOrderById = async (values: any) => {
    const inputVal = from === "manage" ? values.search_id : searchText;
    const endpointUrl =
      searchBy === "order_id"
        ? `${API_VERSION.V1}/orders/${inputVal}`
        : `${API_VERSION.V1}/orders/partner/${String(
            values?.provider_id
          )?.toLowerCase()}/${inputVal}`;

    setLoading(true);
    try {
      const result = await privateInstance.get(endpointUrl);
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (from === "manage") {
          closeSearchModal();
        }
        //check order type and update
        const fetchedOrderType =
          result.data.response?.order_type === ORDER_TYPE.MEDICINES
            ? "pharmacy"
            : result.data.response?.order_type;

        if (
          location.pathname?.includes(fetchedOrderType) ||
          values?.provider_id === "dunzo"
        ) {
          dispatch(setAllOrderData([...ordersToManage, result.data.response]));
        } else {
          errorToast("order not found.");
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{
        provider_id:
          user?.role === USER_ROLE.PARTNER
            ? user?.partner
            : location.pathname?.toLowerCase()?.includes("dunzo")
            ? "dunzo"
            : "",
        search_id: searchText || "",
      }}
      onSubmit={(values: any, actions: any) => {
        const inputVal = from === "manage" ? values.search_id : searchText;
        if (ordersToManage?.find((x: any) => x.partner_order_id === inputVal)) {
          errorToast("already exists!");
          closeSearchModal();
          return;
        }
        actions.setSubmitting(false);
        getOrderById(values);
      }}
      validationSchema={
        from === "manage"
          ? searchBy === "order_id"
            ? getOrderByOrderIdSchema
            : getOrderByPartnerOrderIdSchemaManage
          : searchBy === "order_id"
          ? null
          : getOrderByPartnerOrderIdSchema
      }
    >
      {(props: any) => {
        const { values, errors, touched, setFieldValue, handleBlur } = props;
        return (
          <Form>
            {from === "manage" ? null : (
              <Typography variant="h5" mb={3}>
                Sorry, there's no matching data to display
              </Typography>
            )}
            {searchText || from === "manage" ? (
              <>
                <Stack
                  direction={from === "manage" ? "column" : "row"}
                  mb={3}
                  px={from === "manage" ? 0 : { xs: 2, md: 5 }}
                >
                  {searchBy === "order_id" ? null : (
                    <PartnersAutocomplete
                      providerId={values?.provider_id || null}
                      validate="true"
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      disableField={
                        user?.role === USER_ROLE.PARTNER ||
                        location.pathname?.toLowerCase()?.includes("dunzo")
                          ? true
                          : false
                      }
                    />
                  )}
                  {user?.role === USER_ROLE.PARTNER ? null : (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ mt: 1.5, mr: 1.5 }}
                    >
                      <InputLabel id="by-label">Search By</InputLabel>

                      <Select
                        style={{ textAlign: "left" }}
                        labelId="by-label"
                        name="by"
                        id="by"
                        label="Search By"
                        value={searchBy}
                        onChange={(e) => setSearchBy(e.target.value)}
                      >
                        <MenuItem value="partner_order_id">
                          Partner Order ID
                        </MenuItem>
                        <MenuItem value="order_id">Order ID</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    fullWidth
                    id="search_id"
                    label={
                      searchBy === "order_id" ? "Order ID" : "Partner Order ID"
                    }
                    size="small"
                    value={
                      from === "manage" ? values.search_id : searchText || ""
                    }
                    onChange={(e) => setFieldValue("search_id", e.target.value)}
                    onBlur={handleBlur}
                    helperText={
                      errors.search_id && touched.search_id
                        ? errors.search_id
                        : ""
                    }
                    error={errors.search_id && touched.search_id ? true : false}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    disabled={from === "manage" ? false : true}
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Stack>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  sx={{ mr: 2 }}
                >
                  Search
                </LoadingButton>
              </>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
};

export default OrderByPartnerId;
