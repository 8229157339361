import { useFormikContext } from "formik";
import React from "react";
import { autogeneratePartnerOrderId } from "../../constants";

const PartnerIdChange = ({ setFieldValue }: any) => {
  const { values }: any = useFormikContext();
  React.useEffect(() => {
    if (autogeneratePartnerOrderId.includes(values?.provider_id)) {
      setFieldValue("partner_order_id", `19${new Date().getTime()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.provider_id]);
  return null;
};

export default PartnerIdChange;
