import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../../utils/common";
import RupeeIcon from "@mui/icons-material/CurrencyRupee";
import InvoicePayoutModal from "./invoicePayoutModal";
import NotAllowedIcon from "@mui/icons-material/Block";
import RepeatIcon from "@mui/icons-material/Autorenew";
import CheckIcon from "@mui/icons-material/Check";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import PaymentStatusModal from "./PaymentStatus/paymentStatusModal";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { useIconStyles } from "../../../Helpers/iconStyles";

const PaymentButton = ({
  activePartner,
  activePartnerOrderId,
  activeOrderId,
  index,
}: any) => {
  const privateInstance = useAxiosInstance();
  // const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [orderData, setOrderData] = useState<any>([]);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [invoicePayoutModal, setInvoicePayoutModal] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const iconClasses = useIconStyles();

  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const orderDataParent = ordersToManage?.find(
    (x: any) => x.order_id === activeOrderId
  );

  const getOrderByPartnerOrderId = async (actionType: string) => {
    //setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOrderData(result.data.response);
        if (actionType === "make_payment") {
          if (
            result.data.response?.order_type === ORDER_TYPE.MEDICINES &&
            activePartner !== "dunzo" &&
            !result.data.response?.invoice_urls
          ) {
            setShowPaymentForm(false);
          } else {
            setShowPaymentForm(true);
          }
          setInvoicePayoutModal(true);
        }
        if (actionType === "payment_detail") {
          setPaymentStatusModal(true);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  const refreshStatus = async (
    orderId: string,
    refreshType: string,
    paymentType: string
  ) => {
    setRefreshLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/status?order_id=${orderId}&payment_type=${paymentType}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        //prevent payment button from disappearing after refresh, update store only if response is not empty object
        if (Object.keys(result.data.response)?.length !== 0) {
          const updated_item = result.data.response;
          ordersToManage.splice(index, 1, updated_item);
          dispatch(setAllOrderData(ordersToManage));
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRefreshLoading(false);
      if (refreshType === "payment_detail") {
        getOrderByPartnerOrderId("payment_detail");
      }
    }
  };

  const dunzoShowStatus = [
    "invoice_generated",
    "payment_collected",
    "package_picked",
    "package_delivered",
  ];

  const consultationShowStatus = [
    "booking_confirmed",
    "booking_completed",
    "prescription_uploaded",
    "invoice_submitted",
  ];

  const diagnosticsShowStatus = [
    "payment_collected",
    "booking_confirmed",
    "booking_completed",
    "sample_collected",
    "reports_generated",
  ];

  const pharmacyShowStatus = [
    "payment_collected",
    "package_picked",
    "package_delivered",
  ];

  const successStatus = ["paid", "processing", "success", "failed", "pending"];

  const showRefreshAndTxnStatusBtn = (val: any) => {
    let res: boolean = false;

    if (
      successStatus.includes(val?.invoice_details?.payment_status) &&
      val?.payment_credit_status &&
      val?.payment_credit_status?.toLowerCase() !== PAYMENT_CREDIT_STATUS.NA
    ) {
      res = true;
    }

    if (val?.order_type === ORDER_TYPE.CONSULTATION && res) {
      res = val?.order_status !== ORDER_STATUS.PAYMENT_COLLECTED ? true : false;
    }

    return res;
  };

  const showMakePaymentBtn = (
    val: string,
    orderType: string,
    orderStatus: string
  ) => {
    let flag: boolean = false;

    if (
      orderType === ORDER_TYPE.CONSULTATION &&
      consultationShowStatus.includes(orderStatus)
    ) {
      flag = true;
    }

    if (
      orderType === ORDER_TYPE.DIAGNOSTICS &&
      diagnosticsShowStatus.includes(orderStatus)
    ) {
      flag = true;
    }

    if (
      orderType === ORDER_TYPE.MEDICINES &&
      pharmacyShowStatus.includes(orderStatus)
    ) {
      flag = true;
    }

    if (val === "dunzo" || ORDER_TYPE.IPD) {
      flag = true;
    }

    return flag;
  };

  const paymentCreditStatus =
    orderDataParent?.payment_credit_status?.toLowerCase();

  return (
    <Box>
      {orderDataParent?.invoice_details ==
      null ? null : showRefreshAndTxnStatusBtn(orderDataParent) ? (
        <Stack direction="row" alignItems="center">
          {refreshLoading ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <Tooltip
              title={
                paymentCreditStatus === PAYMENT_CREDIT_STATUS.PAID &&
                orderDataParent?.invoice_details?.incentives_details &&
                orderDataParent.invoice_details.incentives_details
                  .inc_payment_status !== "paid"
                  ? "retry payment"
                  : "refresh payment"
              }
            >
              <span>
                <IconButton
                  color="primary"
                  onClick={() => {
                    refreshStatus(
                      orderDataParent?.order_id,
                      "refresh",
                      orderDataParent?.settlement_period || ""
                    );
                  }}
                >
                  <RepeatIcon className={iconClasses.borderedIcon} />
                </IconButton>
              </span>
            </Tooltip>
          )}

          <Tooltip
            title={
              paymentCreditStatus === PAYMENT_CREDIT_STATUS.PAID
                ? "payment completed"
                : "payment " + paymentCreditStatus + ""
            }
          >
            <span>
              <IconButton
                color={
                  paymentCreditStatus === PAYMENT_CREDIT_STATUS.FAILED
                    ? "error"
                    : paymentCreditStatus === PAYMENT_CREDIT_STATUS.PENDING ||
                      paymentCreditStatus ===
                        PAYMENT_CREDIT_STATUS.PROCESSING ||
                      (paymentCreditStatus === PAYMENT_CREDIT_STATUS.PAID &&
                        orderDataParent?.invoice_details?.incentives_details &&
                        orderDataParent.invoice_details.incentives_details
                          .inc_payment_status !== "paid")
                    ? "warning"
                    : "success"
                }
                onClick={() => {
                  //refresh for medicnes order only
                  if (
                    orderDataParent?.order_type === ORDER_TYPE.MEDICINES &&
                    activePartner !== "dunzo" &&
                    (paymentCreditStatus === PAYMENT_CREDIT_STATUS.PENDING ||
                      paymentCreditStatus === PAYMENT_CREDIT_STATUS.PROCESSING)
                  ) {
                    refreshStatus(
                      orderDataParent?.order_id,
                      "payment_detail",
                      orderDataParent?.settlement_period || ""
                    );
                  } else {
                    getOrderByPartnerOrderId("payment_detail");
                  }
                }}
              >
                {paymentCreditStatus === PAYMENT_CREDIT_STATUS.FAILED ? (
                  <NotAllowedIcon className={iconClasses.borderedIcon} />
                ) : (
                  <CheckIcon className={iconClasses.borderedIcon} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Stack>
      ) : paymentCreditStatus == null ||
        paymentCreditStatus === PAYMENT_CREDIT_STATUS.NA ? (
        <>
          {showMakePaymentBtn(
            activePartner,
            orderDataParent?.order_type,
            orderDataParent?.order_status
          ) && (
            <Tooltip title="make payment">
              <span>
                <IconButton
                  color="info"
                  onClick={() => {
                    getOrderByPartnerOrderId("make_payment");
                  }}
                  disabled={
                    activePartner === "dunzo"
                      ? !dunzoShowStatus.includes(orderDataParent?.order_status)
                      : false
                  }
                >
                  <RupeeIcon className={iconClasses.borderedIcon} />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          {refreshLoading ? (
            <CircularProgress size={20} color="primary" />
          ) : (
            <Tooltip title="refresh payment">
              <span>
                <IconButton
                  color="primary"
                  onClick={() => {
                    refreshStatus(
                      orderDataParent?.order_id,
                      "refresh",
                      orderDataParent?.settlement_period || ""
                    );
                  }}
                >
                  <RepeatIcon className={iconClasses.borderedIcon} />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      )}

      {invoicePayoutModal && (
        <InvoicePayoutModal
          invoicePayoutModal={invoicePayoutModal}
          setInvoicePayoutModal={setInvoicePayoutModal}
          showPaymentForm={showPaymentForm}
          setShowPaymentForm={setShowPaymentForm}
          orderData={orderData}
          setOrderData={setOrderData}
          mode="create"
        />
      )}
      {paymentStatusModal && (
        <PaymentStatusModal
          paymentStatusModal={paymentStatusModal}
          setPaymentStatusModal={setPaymentStatusModal}
          orderData={orderData}
          setOrderData={setOrderData}
          activePartner={activePartner}
          readOnly={orderData?.order_type === ORDER_TYPE.IPD ? true : false}
        />
      )}
    </Box>
  );
};

export default PaymentButton;
