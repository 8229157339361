import * as yup from "yup";

export const updateAddressSchema = yup.object().shape({
  address: yup.string().required("Address is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  area: yup.string().required("Area is required"),
  locality: yup.string().required("Locality is required"),
  // lat: yup.string().required("Latitude is required"),
  // long: yup.string().required("Longitude is required"),
});

export const updateGstSchema = yup.object().shape({
  gst_no: yup
    .string()
    .min(15, "GST number should be of 15 digit")
    .required("GSTIN number is required"),
  company: yup.string().required("GST Name is required"),
});

export const createLocationSchema = yup.object().shape({
  provider_name: yup.string().required("Provider Name is required"),
  mobile_number: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  address: yup.string().required("Address is required"),
  landmark: yup.string().required("Landmark is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  upi_id: yup.string().when("account_type", {
    is: (account_type: string) => account_type === "upi",
    then: yup.string().required("UPI ID is required"),
  }),
  upi_name: yup.string().when("account_type", {
    is: (account_type: string) => account_type === "upi",
    then: yup.string().required("UPI Name is required"),
  }),
  account_name: yup.string().when("account_type", {
    is: (account_type: string) => account_type === "bank_acc",
    then: yup.string().required("Account name is required"),
  }),
  account_no: yup.string().when("account_type", {
    is: (account_type: string) => account_type === "bank_acc",
    then: yup.string().required("Account no. is required"),
  }),
  bank_name: yup.string().when("account_type", {
    is: (account_type: string) => account_type === "bank_acc",
    then: yup.string().required("Bank name is required"),
  }),
  // branch_name: yup.string().when("account_type", {
  //   is: (account_type: string) => account_type === "bank_acc",
  //   then: yup.string().required("Branch name is required"),
  // }),
  ifsc_code: yup.string().when("account_type", {
    is: (account_type: string) => account_type === "bank_acc",
    then: yup
      .string()
      .min(11, "Please enter 11-digit IFSC code")
      .max(11, "Please enter 11-digit IFSC code")
      .required("IFSC Code is required"),
  }),
});
