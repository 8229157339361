import { ActionTypes } from "../constants/action-types";

export const setUser = (user: any) => {
  return {
    type: ActionTypes.SET_USER,
    payload: user,
  };
};

export const setUserPermissions = (permissions: any) => {
  return {
    type: ActionTypes.SET_USER_PERMISSIONS,
    payload: permissions,
  };
};

export const setPartners = (partners: any) => {
  return {
    type: ActionTypes.SET_PARTNERS,
    payload: partners,
  };
};

export const setEditors = (editors: any) => {
  return {
    type: ActionTypes.SET_EDITORS,
    payload: editors,
  };
};

export const setOrderEmailTime = (reportTime: any) => {
  return {
    type: ActionTypes.SET_ORDEREMAIL_TIME,
    payload: reportTime,
  };
};

export const setCashlessEmailTime = (reportTime: any) => {
  return {
    type: ActionTypes.SET_CASHLESSEMAIL_TIME,
    payload: reportTime,
  };
};

export const setAuditEmailTime = (reportTime: any) => {
  return {
    type: ActionTypes.SET_AUDITEMAIL_TIME,
    payload: reportTime,
  };
};

export const setCustomerCartEmailTime = (reportTime: any) => {
  return {
    type: ActionTypes.SET_CUSTOMER_CART_EMAIL_TIME,
    payload: reportTime,
  };
};

export const setAllOrderData = (data: any) => {
  return {
    type: ActionTypes.SET_ALL_ORDERDATA,
    payload: data,
  };
};

export const setSearchText = (data: any) => {
  return {
    type: ActionTypes.SET_SEARCH_TEXT,
    payload: data,
  };
};

export const UpdateNotifications = (data: any) => {
  return {
    type: ActionTypes.UPDATE_NOTIFICATIONS,
    payload: data,
  };
};

export const UpdateCart = (data: any) => {
  return {
    type: ActionTypes.UPDATE_CART,
    payload: data,
  };
};
