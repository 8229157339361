import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { gstSchema, gstSchemaMedpay } from "../../../../../schemas/common";
import CustomDialogTitle from "../../../../Common/customDialogTitle";
import MerchantGstValidate from "../merchantGstValidate";

const GstValidateModal = ({
  gstModal,
  setGstModal,
  nearbyProviders,
  setNearbyProviders,
  activeProviderIndex,
  selectedProviders,
  setSelectedProviders,
  gstForProviderId,
  orderPartner,
}: any) => {
  return (
    <Dialog
      open={gstModal}
      onClose={() => setGstModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setGstModal(false)}
      >
        Add GST Number
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            gst_no: nearbyProviders[activeProviderIndex]?.gst_no || "",
            company: "",
          }}
          validationSchema={
            orderPartner === "medpay" ? gstSchemaMedpay : gstSchema
          }
          onSubmit={(values: any, actions: any) => {
            actions.resetForm({});
            setGstModal(false);
            nearbyProviders.splice(activeProviderIndex, 1, {
              ...nearbyProviders[activeProviderIndex],
              gst_no: values.gst_no,
              gst_name: values.company,
            });
            const updatedProviders = [...nearbyProviders];
            setNearbyProviders(updatedProviders);

            let updatedSelectedProviders = [];
            for (let i = 0; i < selectedProviders.length; i++) {
              if (selectedProviders[i].provider_id === gstForProviderId) {
                let currItem = selectedProviders[i];
                currItem = {
                  ...selectedProviders[i],
                  gst_no: values?.gst_no,
                  gst_name: values?.company,
                };
                updatedSelectedProviders.push(currItem);
              } else {
                updatedSelectedProviders.push(selectedProviders[i]);
              }
            }
            setSelectedProviders(updatedSelectedProviders);
          }}
        >
          {(props: any) => {
            const { values, touched, errors, handleBlur, setFieldValue } =
              props;
            return (
              <Form>
                <MerchantGstValidate
                  gst_no={values.gst_no}
                  company={values.company}
                  handleBlur={handleBlur}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  allowWhiteSpace={false}
                />
                <Box textAlign="center" sx={{ mt: 3 }}>
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!values.company}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default GstValidateModal;
