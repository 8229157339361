import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Box, Link, Rating, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  doctorHighlights: {
    textTransform: "uppercase",
    backgroundColor: "#b2f5ea",
    padding: "4px 12px",
    fontWeight: "bold",
    borderRadius: "3rem",
  },
});

function stringToColor(string: string) {
  if (!string) return;
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (!name) return;
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name.includes(" ")
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : name[0],
  };
}

const OrderDoctorInfo = ({
  doctor,
  time_slot,
  time_slot_label,
  showLink,
}: any) => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        sx={{ mt: 3, textAlign: { xs: "center", md: "left" } }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{ width: { xs: "100%", md: "50%" } }}
        >
          <Stack direction="column" alignItems="center">
            <Avatar {...stringAvatar(doctor?.name)} />
            <Rating
              name="disabled"
              value={Number(doctor?.rating)}
              precision={0.1}
              readOnly
              size="small"
            />
          </Stack>
          <Stack
            direction="column"
            sx={{ mt: { xs: 2, md: 0 }, ml: { xs: 0, md: 2 } }}
          >
            <Typography variant="body2">{doctor?.name}</Typography>
            {doctor?.profile_details && (
              <>
                {doctor?.profile_details?.full_name && (
                  <Typography variant="body2">
                    {doctor.profile_details.full_name}
                  </Typography>
                )}
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  flexWrap={{ xs: "nowrap", md: "wrap" }}
                  sx={{ alignItems: { xs: "center", md: "left" } }}
                >
                  <Typography
                    variant="body2"
                    className={classes.doctorHighlights}
                    sx={{
                      marginRight: { xs: 0, md: 0.5 },
                      marginTop: { xs: 0.5, md: 1 },
                    }}
                  >
                    {doctor.profile_details.experience_in_years} yrs of
                    experience
                  </Typography>
                  {doctor?.profile_details?.speciality && (
                    <Typography
                      variant="body2"
                      className={classes.doctorHighlights}
                      sx={{
                        marginRight: { xs: 0, md: 0.5 },
                        marginTop: { xs: 0.5, md: 1 },
                      }}
                    >
                      {doctor.profile_details.specialty}
                    </Typography>
                  )}
                  {doctor?.profile_details?.whatsapp_number && (
                    <Typography
                      variant="body2"
                      className={classes.doctorHighlights}
                      sx={{
                        marginRight: { xs: 0, md: 0.5 },
                        marginTop: { xs: 0.5, md: 1 },
                      }}
                    >
                      {doctor.profile_details.whatsapp_number}
                    </Typography>
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        <Stack sx={{ width: { xs: "100%", md: "50%" } }}>
          <Typography
            variant="body2"
            sx={{
              color: "#718096",
              mt: { xs: 1, md: 0 },
            }}
          >
            {doctor?.formatted_address || doctor?.address}
          </Typography>
          {showLink && doctor?.consultation_link ? (
            <Link href={doctor.consultation_link} target="_blank">
              {" "}
              Link: {doctor.consultation_link}
            </Link>
          ) : null}
        </Stack>
      </Box>
      <Box textAlign="center" sx={{ mt: 2 }}>
        <Typography variant="h5">
          {time_slot_label} : {time_slot}
        </Typography>
      </Box>
    </>
  );
};

export default OrderDoctorInfo;
