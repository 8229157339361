import { ActionTypes } from "../constants/action-types";

interface IActionProp {
  type: string;
  payload: any;
}

export const userReducer = (state = {}, { type, payload }: IActionProp) => {
  switch (type) {
    case ActionTypes.SET_USER:
      return { ...state, authData: payload };
    case ActionTypes.SET_USER_PERMISSIONS:
      return { ...state, permissions: payload };
    default:
      return state;
  }
};

export const partnersReducer = (state = {}, { type, payload }: IActionProp) => {
  switch (type) {
    case ActionTypes.SET_PARTNERS:
      return { ...state, partnersData: payload };
    default:
      return state;
  }
};

export const editorsReducer = (state = {}, { type, payload }: IActionProp) => {
  switch (type) {
    case ActionTypes.SET_EDITORS:
      return { ...state, editorsData: payload };
    default:
      return state;
  }
};

export const reportTimeReducer = (
  state = {},
  { type, payload }: IActionProp
) => {
  switch (type) {
    case ActionTypes.SET_ORDEREMAIL_TIME:
      return { ...state, order: payload };
    case ActionTypes.SET_CASHLESSEMAIL_TIME:
      return { ...state, cashless: payload };
    case ActionTypes.SET_AUDITEMAIL_TIME:
      return { ...state, audit: payload };
    case ActionTypes.SET_CUSTOMER_CART_EMAIL_TIME:
      return { ...state, customerCart: payload };
    default:
      return state;
  }
};

export const orderDataReducer = (
  state = {},
  { type, payload }: IActionProp
) => {
  switch (type) {
    case ActionTypes.SET_ALL_ORDERDATA:
      return { ...state, allOrderData: payload };
    default:
      return state;
  }
};

export const searchReducer = (state = {}, { type, payload }: IActionProp) => {
  switch (type) {
    case ActionTypes.SET_SEARCH_TEXT:
      return { ...state, data: payload };
    default:
      return state;
  }
};

export const notificationReducer = (
  state = {},
  { type, payload }: IActionProp
) => {
  switch (type) {
    case ActionTypes.UPDATE_NOTIFICATIONS:
      return { ...state, data: payload };
    default:
      return state;
  }
};

export const cartReducer = (state = {}, { type, payload }: IActionProp) => {
  switch (type) {
    case ActionTypes.UPDATE_CART:
      return { ...state, cartData: payload };
    default:
      return state;
  }
};
