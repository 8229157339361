import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { setAllOrderData } from "../../../../store/actions/useActions";
import {
  errorToast,
  handleError,
  infoToast,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import AddProviderModal from "../Common/addProviderModal";
import SearchProviderModal from "./searchProviderModal";
import SearchIcon from "@mui/icons-material/Search";
import SearchNearby from "../Common/NearbyProviders/searchNearby";

const SearchAndAssign = ({
  orderData,
  assignType,
  setAssignProviderModal,
  setParentOrderData,
}: any) => {
  const [addProviderModal, setAddProviderModal] = useState(false);
  const [searchProviderModal, setSearchProviderModal] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [loadingDataByPinCode, setLoadingDataByPinCode] = useState(false);
  const [searchByPinCode, setSearchByPinCode] = useState(
    orderData?.order_type === ORDER_TYPE.MEDICINES
      ? orderData?.customer_details?.address_details?.pin_code
      : ""
  );
  const [selectedProviders, setSelectedProviders] = useState<any>([]);
  const [allProviders, setAllProviders] = useState<any>([]);

  const handleAssignOrder = async () => {
    let payload = {
      order_id: orderData.order_id,
      type: assignType === "assign" ? "assign-order" : "reassign-order",
      data: selectedProviders,
      time_slot: orderData.time_slot,
    };
    setAssignLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Order assign successful");
        setAssignProviderModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
        //update acceptorder modal data
        if (setParentOrderData) {
          setParentOrderData(result.data.response);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setAssignLoading(false);
    }
  };

  const fetchNearbyProviders = async (orderId: string) => {
    setLoadingDataByPinCode(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/nearby?order_id=${orderId}&start=${searchByPinCode}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setSelectedProviders([]);
        const resultData = result.data;
        if (resultData.response?.nearby_list?.length !== 0) {
          setAllProviders(resultData.response.nearby_list);
        } else {
          infoToast(resultData.message);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoadingDataByPinCode(false);
    }
  };

  useEffect(() => {
    if (
      orderData?.order_type !== ORDER_TYPE.MEDICINES &&
      selectedProviders.length > 0
    ) {
      handleAssignOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProviders]);

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">
            {orderData?.order_type === ORDER_TYPE.CONSULTATION
              ? "Nearby Clinics"
              : orderData?.order_type === ORDER_TYPE.MEDICINES
              ? "Nearby Pharmacies"
              : ""}
          </Typography>
          {orderData?.order_type === ORDER_TYPE.MEDICINES ? (
            <Box display="flex">
              <TextField
                fullWidth
                id="pin"
                label="Search by Pincode"
                size="small"
                value={searchByPinCode}
                onChange={(e) => setSearchByPinCode(e.target.value)}
                InputLabelProps={{
                  required: true,
                }}
                inputProps={{
                  maxLength: 6,
                }}
              />
              <LoadingButton
                size="medium"
                color="primary"
                loading={loadingDataByPinCode}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                variant="contained"
                type="button"
                onClick={() => fetchNearbyProviders(orderData?.order_id)}
                disabled={searchByPinCode?.trim()?.length !== 6}
                sx={{ ml: 1 }}
              >
                <SearchIcon />
              </LoadingButton>
            </Box>
          ) : null}
        </Stack>
        <SearchNearby
          autocompleteLabel={
            orderData?.order_type === ORDER_TYPE.MEDICINES
              ? "Search provider from google"
              : "Search provider"
          }
          nearbyProviders={allProviders}
          setNearbyProviders={setAllProviders}
          orderData={orderData}
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
          assignLoading={assignLoading}
        />
      </Box>
      <Box sx={{ mt: 3, textAlign: "center" }}>
        {orderData?.order_type === ORDER_TYPE.MEDICINES && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSearchProviderModal(true)}
            sx={{ mr: 1 }}
          >
            Search onboarded provider
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddProviderModal(true)}
        >
          Add Provider
        </Button>
        {orderData?.order_type === ORDER_TYPE.MEDICINES && (
          <LoadingButton
            variant="contained"
            color="primary"
            loading={assignLoading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            onClick={() => handleAssignOrder()}
            sx={{ ml: 1 }}
            disabled={selectedProviders.length === 0}
          >
            Assign
          </LoadingButton>
        )}
      </Box>
      {addProviderModal && (
        <AddProviderModal
          addProviderModal={addProviderModal}
          setAddProviderModal={setAddProviderModal}
          orderType={orderData?.order_type}
          orderPartner={orderData?.order_partner}
          providersList={allProviders}
          setProvidersList={setAllProviders}
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
        />
      )}
      {searchProviderModal && (
        <SearchProviderModal
          searchProviderModal={searchProviderModal}
          setSearchProviderModal={setSearchProviderModal}
          providersList={allProviders}
          setProvidersList={setAllProviders}
          selectedProviders={selectedProviders}
          setSelectedProviders={setSelectedProviders}
        />
      )}
    </>
  );
};

export default SearchAndAssign;
