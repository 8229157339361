import { Autocomplete, TextField } from "@mui/material";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";

interface IPartnersACApiProps {
  providerId: string | null;
  setProviderId: any;
  disableField: boolean;
}

const PartnersAutocompleteFromApi = (props: IPartnersACApiProps) => {
  const { providerId, setProviderId, disableField } = props;
  const [allPartnersFromApi, setAllPartnersFromApi] = useState<any>([]);
  const privateInstance = useAxiosInstance();

  const getAllPartners = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/partner/get-all?filter=api_metrics`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        let partnersIdList: Array<string> = [];
        let resultData = result.data.response;
        resultData?.forEach((partner: any) =>
          partnersIdList.push(partner.partner_id)
        );
        setAllPartnersFromApi(sortBy(partnersIdList));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!disableField) {
      getAllPartners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Autocomplete
      disabled={disableField}
      ListboxProps={{ style: { maxHeight: "14rem" } }}
      fullWidth
      size="small"
      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
      value={providerId}
      onChange={(e, val) => {
        setProviderId(val);
      }}
      options={allPartnersFromApi}
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: "250px" }}
          name="provider_id"
          label="Partner *"
          {...params}
        />
      )}
    />
  );
};

export default PartnersAutocompleteFromApi;
