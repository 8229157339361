import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useState } from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  sortOrders,
  textCapitalize,
} from "../../utils/common";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData, setSearchText } from "../../store/actions/useActions";
import { commonTableOptions } from "../../constants";
import { UpdateNotesIcon } from "../../assets/icons";
import { times } from "lodash";
import AllOrderTableSkeleton from "../../components/UI/allOrderTableSkeleton";
import OrderByPartnerId from "../../components/ManageOrder/orderByPartnerId";
import OrderBadges from "../../components/ManageOrder/orderBadges";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";
import NotesModal from "../../components/ManageOrder/notesModal";
import OrderDataModal from "../../components/ManageOrder/orderDataModal";
import { ORDER_TYPE } from "../../enums/order.enum";
import PaymentButton from "../../components/ManageOrder/GroupOrders/Payment/paymentButton";

const ManageIpdOrder = () => {
  const [loading, setLoading] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const privateInstance = useAxiosInstance();
  let urlToFetchOrders: string;
  const [activeOrderId, setActiveOrderId] = useState("");
  const [notesModal, setNotesModal] = useState(false);
  const [activePartner, setActivePartner] = useState("");
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [orderDataModal, setOrderDataModal] = useState(false);
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  if (user?.role === USER_ROLE.EDITOR) {
    urlToFetchOrders = `/orders/all/${user?.partner}?ver=v3&order_type=ipd&editor=${user.user_id}`;
  } else {
    urlToFetchOrders = `/orders/all/${user.partner}?ver=v3&order_type=ipd`;
  }

  const getOrders = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}${urlToFetchOrders}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        dispatch(setAllOrderData(sortOrders(result.data.response)));
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      dispatch(setAllOrderData([]));
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const manageColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "CREATED AT",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "customer_details.name",
      label: "CUSTOMER NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.address_details.city",
      label: "CITY",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "customer_details.address_details.pin_code",
      label: "PIN CODE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_partner",
      label: "ORDER PARTNER",
      options: {
        filter: user?.role === USER_ROLE.PARTNER ? false : true,
        display: user?.role === USER_ROLE.PARTNER ? false : true,
        //filterType: "textField" as FilterType,
        searchable: false,
      },
    },
    {
      name: "order_status",
      label: "ORDER STATUS",
      options: {
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "ACTION",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "assigned_to",
      label: "ASSIGNED TO",
      options: {
        filter: true,
        display: false,
        searchable: false,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "express_delivery",
      label: "EXPRESS DELIVERY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          times(10, (i) => <AllOrderTableSkeleton key={i} />)
        ) : (
          <OrderByPartnerId />
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any, dataIndex: number) => {
      return (
        <CustomRowComponent key={data[0]} rowData={data} index={dataIndex} />
      );
    },
    searchPlaceholder: "Name/Partner Order ID",
    onSearchChange: (val: string) => {
      dispatch(setSearchText(val));
    },
  };

  React.useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(setAllOrderData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomRowComponent({ rowData, index }: any) {
    const [
      partner_order_id,
      created_at,
      customer_name,
      customer_city,
      customer_pin,
      order_partner,
      order_status,
      order_id,
      assigned_to,
      express_delivery,
    ] = rowData;
    return (
      <TableRow key={order_id} hover>
        <TableCell sx={{ width: { sm: "auto", md: "28%" } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              sx={{ color: "#0058ff", cursor: "pointer", mr: 0.25 }}
              onClick={() => {
                setActivePartner(order_partner);
                setActivePartnerOrderId(partner_order_id);
                setOrderDataModal(true);
              }}
            >
              {partner_order_id}
            </Typography>
            <OrderBadges
              priority={undefined}
              order_status={order_status}
              partner_order_id={partner_order_id}
              created_at={created_at}
              express_delivery={express_delivery}
            />
          </Stack>
          <Typography variant="body2" sx={{ color: "#718096" }}>
            {parseAndFormateDate(created_at)}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: { sm: "auto", md: "22%" } }}>
          <Stack direction="column">
            <Typography variant="body2">{customer_name}</Typography>
            {customer_city && (
              <Typography variant="body2" sx={{ color: "#718096" }}>
                City: {customer_city}
              </Typography>
            )}
            {customer_pin && (
              <Typography variant="body2" sx={{ color: "#718096" }}>
                Pin code: {customer_pin}
              </Typography>
            )}
          </Stack>
        </TableCell>
        {user?.role !== USER_ROLE.PARTNER && (
          <TableCell>
            <Typography variant="body2">{order_partner}</Typography>
          </TableCell>
        )}
        <TableCell sx={{ width: { sm: "auto", md: "22%" } }}>
          <Typography variant="body2">
            {textCapitalize(order_status?.split("_").join(" "))}
          </Typography>
          {user?.role !== USER_ROLE.PARTNER && assigned_to ? (
            <Typography variant="body2" color="#718096">
              Assigned to: {assigned_to}
            </Typography>
          ) : null}
        </TableCell>
        <TableCell align="center">
          <Stack direction="row">
            {user?.role === USER_ROLE.PARTNER ||
            user?.provider_payment === "disabled" ? null : (
              <PaymentButton
                activePartner={order_partner}
                activePartnerOrderId={partner_order_id}
                activeOrderId={order_id}
                index={index}
              />
            )}
            <IconButton
              color="primary"
              sx={{ fontSize: "22px" }}
              onClick={() => {
                setActiveOrderId(order_id);
                setNotesModal(true);
              }}
            >
              <UpdateNotesIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <Box>
        <MUIDataTable
          title=""
          data={ordersToManage}
          columns={manageColumns}
          options={options}
        />
      </Box>
      {notesModal && (
        <NotesModal
          notesModal={notesModal}
          setNotesModal={setNotesModal}
          orderId={activeOrderId}
        />
      )}
      {orderDataModal && (
        <OrderDataModal
          orderDataModal={orderDataModal}
          setOrderDataModal={setOrderDataModal}
          activePartner={activePartner}
          activePartnerOrderId={activePartnerOrderId}
          orderType={ORDER_TYPE.IPD}
          showInvoiceCancel={false}
        />
      )}
    </ErrorBoundary>
  );
};

export default ManageIpdOrder;
