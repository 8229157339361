import {
  Box,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
} from "../../../utils/common";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";

const RefundStatus = ({
  orderId,
  patientPayableDetails,
  updateDataFn,
}: {
  orderId: string;
  patientPayableDetails: any;
  updateDataFn: any;
}) => {
  const [refundDetails, setRefundDetails] = useState(
    patientPayableDetails?.refund_details ||
      patientPayableDetails?.advance_payment?.refund_details
  );
  const [loading, setLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState("");
  const privateInstance = useAxiosInstance();

  const { request_id, status, refund_amount, bank_ref_num, msg, timestamp } =
    refundDetails || {};

  const getRefundStatus = async () => {
    setLoading(true);
    try {
      const result = await privateInstance(
        `${API_VERSION.V1}/orders/payments/refund/status?order_id=${orderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Status refresh successful");
        setRefundDetails(result.data.response);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const initiateRefund = async () => {
    let payload = {
      order_id: orderId,
      refund_amount: refundAmount,
    };
    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/refund`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Refund initiated successfully");
        updateDataFn();
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {refundDetails ? (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Refund Details</Typography>
            <LoadingButton
              startIcon={<RefreshIcon />}
              size="small"
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={getRefundStatus}
              disabled={loading}
            >
              Refresh{" "}
            </LoadingButton>
          </Stack>
          <Stack pt={2}>
            {request_id ? (
              <Typography variant="body1">Req ID: {request_id}</Typography>
            ) : null}
            {status ? (
              <Typography variant="body1">
                Status:{" "}
                <span
                  style={{ color: status === "success" ? "green" : "black" }}
                >
                  {status}
                </span>{" "}
              </Typography>
            ) : null}
            {refund_amount ? (
              <Typography variant="body1">
                Amount: <strong>&#8377;{refund_amount}</strong>
              </Typography>
            ) : null}
            {bank_ref_num ? (
              <Typography variant="body1">
                Bank Ref No.: {bank_ref_num}
              </Typography>
            ) : null}
            {msg ? (
              <Typography variant="body1">Message: {msg}</Typography>
            ) : null}
            {timestamp ? (
              <Typography variant="body1">
                Refund Initiated At: {timestamp?.split(".")[0]}
              </Typography>
            ) : null}
          </Stack>
        </>
      ) : (
        <Stack>
          <Typography variant="h4">Initiate Refund</Typography>
          <Stack direction="row" pt={2}>
            <TextField
              id="amount"
              label="Amount"
              size="small"
              value={refundAmount}
              onChange={(e: any) =>
                setRefundAmount(validateFloatNum(e.target.value, 10000))
              }
              sx={{ mt: 1.5, mr: 1 }}
            />
            <LoadingButton
              size="small"
              sx={{ mt: 1.5 }}
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={initiateRefund}
              disabled={loading || !refundAmount}
            >
              Refund{" "}
            </LoadingButton>
          </Stack>
          <Typography pt={3}>
            <strong>Note:</strong> Total amount collected was &#8377;{" "}
            {patientPayableDetails?.total_payment_collected ||
              patientPayableDetails?.advance_payment?.payable_amount}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default RefundStatus;
