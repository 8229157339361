import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const MultiSelectPartners = ({ setFieldValue, errors, touched }: any) => {
  const classes = useStyles();
  const [selected, setSelected] = useState<any>([]);

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === allPartners.length ? [] : allPartners);
      return;
    }
    setSelected(value);
  };

  //getting partners list
  let storedPartners = useSelector((state: any) => state.partners.partnersData);
  let allPartners: Array<string> = [];
  storedPartners?.forEach((partner: any) =>
    allPartners.push(partner.partner_id)
  );
  allPartners = sortBy(allPartners);
  //

  const isAllSelected =
    allPartners.length > 0 && selected.length === allPartners.length;

  useEffect(() => {
    if (selected.length > 0) {
      if (selected.length === allPartners?.length) {
        setFieldValue("provider_id", "all");
      } else {
        setFieldValue("provider_id", selected.join(","));
      }
    } else {
      setFieldValue("provider_id", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <FormControl
      sx={{ mt: 1.5 }}
      size="small"
      fullWidth
      error={errors.provider_id && touched.provider_id ? true : false}
    >
      <InputLabel id="mutiple-select-label">Partner</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        label="Partner"
        renderValue={(selected) => selected.join(", ")}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 230,
            },
          },
        }}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < allPartners.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {allPartners.map((option: any) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
      {errors.provider_id && touched.provider_id && (
        <FormHelperText>{errors.provider_id}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MultiSelectPartners;
