import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { ORDER_TYPE } from "../../../enums/order.enum";
import { instance } from "../../../utils/api";
import SideStackedBarGraph from "../Charts/SideStackedBarGraph";

const CancelledMetricModal = ({
  showCancelledMetricModal,
  setShowCancelledMetricModal,
  startDate,
  endDate,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedOrderType, setSelectedOrderType] = useState<any>(
    ORDER_TYPE.MEDICINES
  );
  const [allPartners, setAllPartners] = useState<any>([]);

  const getMetricsData = async () => {
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: "cancellation_reasons_metric",
      partner: selectedPartner,
      order_type: selectedOrderType,
    };

    const result: any = await instance.post(
      `${config.URL}${API_VERSION.V2}/analytics/ops`,
      payload
    );

    if (result?.data?.status === API_RESULT.SUCCESS) {
      setMetricData(
        orderBy(
          result.data.response["cancellation_reasons"],
          ["order_count"],
          ["desc"]
        )
      );
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = metricData.map(
      (item: any) => item.order_cancellation_reason
    );

    const totalCount: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = metricData.filter(
        (x: any) => x.order_cancellation_reason === el
      );

      let count: any = filteredDataByLabel?.[0]?.order_count;
      totalCount.push(count);
    });

    const datasets = [
      {
        label: "Cancellation Count",
        data: totalCount,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
    ];

    setGenData({ labels, datasets });
  };

  const getPartnersByOrderType = async () => {
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: "cancellation_partners",
      order_type: selectedOrderType,
    };

    const result: any = await instance.post(
      `${config.URL}${API_VERSION.V2}/analytics/ops`,
      payload
    );

    if (result?.data?.status === API_RESULT.SUCCESS) {
      setAllPartners(result.data.response["partners"]);
    }
  };

  useEffect(() => {
    getPartnersByOrderType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderType]);

  return (
    <Dialog
      open={showCancelledMetricModal}
      onClose={() => setShowCancelledMetricModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowCancelledMetricModal(false)}
      >
        Cancellation Metrics | {selectedPartner} | &nbsp;
        {format(startDate, "dd-MM-yyyy")} to {format(endDate, "dd-MM-yyyy")}
      </CustomDialogTitle>
      <DialogContent>
        <Stack direction="row">
          <FormControl size="small" fullWidth sx={{ mt: 1.5 }}>
            <InputLabel id="order-type-label">Order Type</InputLabel>
            <Select
              labelId="order-type-label"
              name="order_type"
              id="order_type"
              value={selectedOrderType}
              label="Order Type"
              onChange={(e: SelectChangeEvent) => {
                setSelectedPartner("");
                setSelectedOrderType(e.target.value);
              }}
            >
              <MenuItem value={ORDER_TYPE.MEDICINES}>Medicines</MenuItem>
              <MenuItem value={ORDER_TYPE.CONSULTATION}>Consultation</MenuItem>
              <MenuItem value={ORDER_TYPE.DIAGNOSTICS}>Diagnostics</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            ListboxProps={{ style: { maxHeight: "14rem" } }}
            fullWidth
            size="small"
            sx={{ mt: 1.5, ml: 1 }}
            value={selectedPartner}
            onChange={(e, val: any) => {
              setSelectedPartner(val);
            }}
            id="provider_id"
            options={allPartners}
            renderInput={(params) => (
              <TextField name="provider_id" label="Partner" {...params} />
            )}
          />
        </Stack>
        {metricData && metricData.length > 0 ? (
          <Box
            sx={{
              boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
              padding: "10px",
            }}
          >
            {genData && (
              <SideStackedBarGraph
                title="Cancellation Reasons"
                data={genData}
                dataLabelPos="center"
              />
            )}
          </Box>
        ) : (
          <Typography textAlign="center" mt={3}>
            {" "}
            No data found!
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CancelledMetricModal;
