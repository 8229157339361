import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { commonTableOptions } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { handleError, parseAndFormateDate } from "../../../utils/common";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import UpdateCertModal from "../../Providers/updateCertModal";
import ProviderSettingsInfoModal from "../../Providers/providerSettingsInfoModal";

const KycDetails = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [updateCert, setUpdateCert] = useState(false);
  const [activeLocationId, setActiveLocationId] = useState<string>("");
  const [showProviderSettingsInfoModal, setShowProviderSettingsInfoModal] =
    useState(false);
  const [filterBy, setFilterBy] = useState("pending");

  useEffect(() => {
    const getData = async () => {
      if (data?.length > 0) {
        setData([]);
      }
      setLoading(true);
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V2}/analytics/digital-payments/providers?filter=${filterBy}`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setData(result?.data?.response?.kyc_providers);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy]);

  const cols = [
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <Typography
              sx={{
                background: "transparent",
                border: "none",
                cursor: "pointer",
                color: "#0058ff",
                textAlign: "left",
                minWidth: "180px",
              }}
              component="button"
              onClick={() => {
                setActiveLocationId(value);
                setShowProviderSettingsInfoModal(true);
              }}
              variant="body2"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "provider_name",
      label: "Pharmacy Name",
      options: {
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "created_by",
      label: "Created By",
    },
    {
      name: "status",
      label: "Status",
      options: {
        searchable: false,
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={120} variant="body2">
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        download: false,
        display: filterBy === "pending",
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box
              textAlign="center"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setActiveLocationId(data[tableMeta.rowIndex]?.location_id);
                setUpdateCert(true);
              }}
            >
              <PermIdentityIcon />
              <Typography
                sx={{
                  fontSize: "10px !important",
                  fontWeight: "600",
                  textTransform: "uppercase",
                }}
              >
                KYC
              </Typography>
            </Box>
          );
        },
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <FormControl size="small">
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          labelId="status-label"
          name="status"
          id="status"
          label="status"
          value={filterBy}
          onChange={(e) => setFilterBy(e.target.value)}
        >
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="rejected">Rejected</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const options: any = {
    customToolbar: () => <CustomToolbar />,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Location ID/Name/Created By",
    filter: false,
  };

  return (
    <Box>
      <MUIDataTable title="" data={data} columns={cols} options={options} />
      {updateCert ? (
        <UpdateCertModal
          updateCert={updateCert}
          setUpdateCert={setUpdateCert}
          activeLocationId={activeLocationId}
          callBack={() =>
            setData(
              data?.filter((item: any) => item.location_id !== activeLocationId)
            )
          }
        />
      ) : null}
      {showProviderSettingsInfoModal && (
        <ProviderSettingsInfoModal
          showProviderSettingsInfoModal={showProviderSettingsInfoModal}
          setShowProviderSettingsInfoModal={setShowProviderSettingsInfoModal}
          activeLocationId={activeLocationId}
          providersData={null}
          setProvidersData={null}
          path="kyc-verify"
          targetId={activeLocationId}
        />
      )}
    </Box>
  );
};

export default KycDetails;
