import { Box, CircularProgress, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { instance } from "../../../../utils/api";
import config from "../../../../config";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import {
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
  handleError,
} from "../../../../utils/common";
import LineChart from "../../../Metrics/Charts/LineChart";

const StoreActivityMetrics = ({
  startDate,
  endDate,
  graphTitle,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getMetricsData = async () => {
    try {
      setLoading(true);
      let payload = {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        metric_type: "store_activity_stats",
      };

      const result: any = await instance.post(
        `${config.URL}${API_VERSION.V2}/analytics/digital-payments/overview`,
        payload
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setMetricData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);

    const churnedActivated: any = [];
    const churnVolume: any = [];
    const contActive: any = [];
    const activeStores: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(rawData, "date", el);

      churnedActivated.push(
        getCountByKey(filteredDataByLabel, "churned_activated")
      );
      churnVolume.push(getCountByKey(filteredDataByLabel, "churn_volume"));
      contActive.push(getCountByKey(filteredDataByLabel, "cont_active"));
      activeStores.push(getCountByKey(filteredDataByLabel, "active_stores"));
    });

    const datasets = [
      {
        label: "Churned Activated",
        data: churnedActivated,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
      {
        label: "Churn Volume",
        data: churnVolume,
        borderColor: "#0058ff",
        backgroundColor: "#0058ff",
      },
      {
        label: "Cont. Active",
        data: contActive,
        borderColor: "#58508d",
        backgroundColor: "#58508d",
      },
      {
        label: "Active",
        data: activeStores,
        borderColor: "#4CAF50",
        backgroundColor: "#4CAF50",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
          }}
        >
          <Box textAlign="right">
            <Tooltip title={tooltipText}>
              <InfoIcon color="primary" fontSize="small" />
            </Tooltip>
          </Box>
          {genData && <LineChart title={graphTitle} data={genData} />}
        </Box>
      )}
    </Box>
  );
};

export default StoreActivityMetrics;
