import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { errorToast, handleError } from "../../utils/common";
import AddTransactionModal from "./addTransactionModal";
import EditLedgerInfoModal from "./editLedgerInfoModal";
import PartnersAutocomplete from "../../components/Common/partnersAC";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import ExcelImg from "../../assets/images/excel_icon.png";
import PdfImg from "../../assets/images/pdf_icon.png";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import BoltIcon from "@mui/icons-material/Bolt";
import { parse } from "date-fns";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  accordion_collapsed: {
    borderRadius: "4px",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
});

const Ledger = () => {
  const privateInstance = useAxiosInstance();
  const [ledgerData, setLedgerData] = useState<any>(null);
  const [showAddTxnModal, setShowAddTxnModal] = useState(false);
  const [showEditLedgerInfo, setShowEditLedgerInfo] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState("");
  const user: AuthenticatedUser = useUserInfo();
  const [showAcDetails, setShowAcDetails] = useState(false);
  const [showActivateNow, setShowActivateNow] = useState(false);
  const classes = useStyles();

  const getLedgerData = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/ledger?partner_id=${selectedPartner}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setLedgerData(result.data.response);
      } else {
        setLedgerData(null);
        errorToast(result.data.message);
        if (user?.role === USER_ROLE.SUPER_ADMIN) {
          setShowActivateNow(true);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    setSelectedPartner(user?.partner ? user.partner : "medpay");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPartner) {
      setLedgerData(null);
      setShowActivateNow(false);
      getLedgerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <PartnersAutocomplete
        providerId={selectedPartner}
        setProviderId={setSelectedPartner}
        validate="false"
        disableField={user?.role === USER_ROLE.PARTNER ? true : false}
      />

      {ledgerData && (
        <>
          <Card
            sx={{
              px: 2,
              py: 1,
              textAlign: "center",
              backgroundColor: "#FEFEFE",
              mt: 1.5,
              display: "flex",
              boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
            }}
          >
            <EmailIcon />
            <Typography variant="body2">
              &nbsp;{ledgerData?.ledger_info?.email_ids?.join(" | ")}
            </Typography>
          </Card>

          <Box
            mt={2}
            mb={4}
            display="flex"
            justifyContent="space-between"
            alignItems="start"
          >
            <Stack direction="row" alignItems="stretch">
              <Card
                sx={{
                  px: 2,
                  py: 1,
                  textAlign: "center",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                }}
              >
                <Typography variant="h6">Total Dues</Typography>
                <Typography
                  variant="h3"
                  color={
                    ledgerData?.ledger_info?.temp_dues > 0 ? "red" : "green"
                  }
                  mt={1.5}
                >
                  &#8377; {ledgerData?.ledger_info?.temp_dues || 0}
                </Typography>
              </Card>
              <Card
                sx={{
                  px: 2,
                  py: 1,
                  ml: 2,
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                }}
              >
                <Stack direction="row" justifyContent="center">
                  <Typography textAlign="center" variant="h6">
                    Medicines:
                  </Typography>
                  <Tooltip title="Note: 18% GST on Delivery Fee and Processing Fee is exculsive">
                    <InfoIcon sx={{ ml: 1 }} fontSize="medium" />
                  </Tooltip>
                </Stack>
                <Box sx={{ pt: 1, pb: 1 }}>
                  <Divider />
                </Box>
                <Box display="flex">
                  <Box>
                    <Typography variant="body2">
                      Type: {ledgerData?.ledger_info?.medicines?.type}
                    </Typography>
                    {ledgerData?.ledger_info?.medicines
                      ?.value_based_commission ? (
                      <>
                        {ledgerData.ledger_info.medicines
                          .minimum_order_value ? (
                          <Typography mt={1} variant="body2">
                            Min order: &#8377;{" "}
                            {
                              ledgerData.ledger_info.medicines
                                .minimum_order_value
                            }
                          </Typography>
                        ) : null}

                        {ledgerData.ledger_info.medicines.value_based_conditions?.map(
                          (condition: any) => {
                            return (
                              <Box mt={1}>
                                <Typography variant="body2">
                                  &#8377;{condition.min} - &#8377;
                                  {condition.max}:
                                </Typography>
                                <Typography variant="body2">
                                  FMCG: {condition.fmcg}%, Medicines:{" "}
                                  {condition.medicines}%
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <>
                        <Typography variant="body2">
                          Medicines:{" "}
                          {ledgerData?.ledger_info?.medicines?.medicines} %
                        </Typography>
                        <Typography variant="body2">
                          FMCG: {ledgerData?.ledger_info?.medicines?.fmcg} %
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{ ml: 1.5, borderLeft: "1.5px solid #eeeeee", pl: 1.5 }}
                  >
                    <Typography variant="body2">
                      Delivery Fee:{" "}
                      {/* {ledgerData?.ledger_info?.medicines?.delivery?.type} */}
                    </Typography>
                    {ledgerData?.ledger_info?.medicines?.delivery?.type ===
                    "fixed" ? (
                      <Typography variant="body2">
                        Amount:{" "}
                        {ledgerData?.ledger_info?.medicines?.delivery?.fixed}
                      </Typography>
                    ) : null}

                    {ledgerData?.ledger_info?.medicines?.delivery?.type ===
                    "conditional" ? (
                      <>
                        {ledgerData?.ledger_info?.medicines?.delivery?.conditional?.map(
                          (item: any, index: number) => {
                            return (
                              <Typography variant="body2">
                                &#8377;{item.fee} upto {item.upto}
                              </Typography>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </Box>
                </Box>
                {ledgerData?.ledger_info?.medicines?.delivery
                  ?.express_delivery ? (
                  <>
                    <Box sx={{ pt: 1, pb: 1 }}>
                      <Divider />
                    </Box>
                    <Stack direction="row">
                      <BoltIcon color="primary" />
                      <Tooltip title="additional charges applicable">
                        <Typography variant="body2">
                          Opted Express Delivery
                        </Typography>
                      </Tooltip>
                    </Stack>
                  </>
                ) : null}
              </Card>
            </Stack>
            <Stack>
              <Box textAlign="center" my={1.5}>
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => setShowAcDetails(true)}
                  startIcon={<InfoIcon />}
                >
                  MedPay A/C Details
                </Button>
              </Box>
              <Stack direction="row">
                {user?.role === USER_ROLE.SUPER_ADMIN ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setShowEditLedgerInfo(true)}
                      startIcon={<EditIcon />}
                    >
                      Edit Info
                    </Button>
                    {parse(
                      ledgerData?.ledger_info?.start_date,
                      "dd/MM/yyyy",
                      new Date()
                    ) <= new Date() ? (
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => setShowAddTxnModal(true)}
                        sx={{ ml: 2 }}
                        startIcon={<AddIcon />}
                      >
                        Add Txn
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </Stack>
            </Stack>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" mb={0.5}>
              Transactions
            </Typography>
          </Box>
          {ledgerData?.month_wise?.length > 0 ? (
            ledgerData.month_wise?.map((item: any, index: number) => {
              return (
                <Accordion
                  expanded={expanded === "panel" + Number(index + 1) + ""}
                  onChange={handleChange("panel" + Number(index + 1) + "")}
                  sx={{
                    boxShadow: "0 0 .25rem rgba(0,0,0,.075)!important",
                    mt: 1.5,
                  }}
                  className={classes.accordion_collapsed}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color:
                            expanded === "panel" + Number(index + 1) + ""
                              ? "white"
                              : "black",
                        }}
                      />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      borderBottomLeftRadius:
                        expanded === "panel" + Number(index + 1) + "" ? 0 : 4,
                      borderBottomRightRadius:
                        expanded === "panel" + Number(index + 1) + "" ? 0 : 4,
                      backgroundColor:
                        expanded === "panel" + Number(index + 1) + ""
                          ? "#0058ff"
                          : "#fff",
                      color:
                        expanded === "panel" + Number(index + 1) + ""
                          ? "#fff"
                          : "#000",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Typography sx={{ width: "33%" }}>
                        <CalendarTodayIcon
                          sx={{ opacity: 0.6, fontSize: "16px" }}
                        />{" "}
                        &nbsp; {item.month} &nbsp; | &nbsp; &#8377;{" "}
                        {item.total_amount}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Txn Date</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Amount&nbsp;(&#8377;)</TableCell>
                          <TableCell>Balance&nbsp;(&#8377;)</TableCell>
                          <TableCell>Links</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.data.map((row: any) => (
                          <TableRow
                            key={row.description}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{row.transaction_date}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                              <Stack direction="row">
                                {row.amount}{" "}
                                <Typography
                                  variant="body2"
                                  sx={{ ml: 1 }}
                                  color={
                                    row.transaction_type === "CREDIT"
                                      ? "green"
                                      : row.transaction_type === "DEBIT"
                                      ? "red"
                                      : ""
                                  }
                                >
                                  {row.transaction_type === "CREDIT"
                                    ? "Cr"
                                    : row.transaction_type === "DEBIT"
                                    ? "Dr"
                                    : ""}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>{row.balance}</TableCell>
                            <TableCell>
                              <Stack direction="row">
                                {row?.links?.report ? (
                                  <Tooltip title="Download Report">
                                    <Link
                                      onClick={(e) => e.stopPropagation()}
                                      href={row.links?.report}
                                    >
                                      <img
                                        src={ExcelImg}
                                        alt="excel icon"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                ) : null}
                                {row?.links?.invoice ? (
                                  <Tooltip title="Download Invoice">
                                    <Link
                                      onClick={(e) => e.stopPropagation()}
                                      href={row.links.invoice}
                                    >
                                      <img
                                        src={PdfImg}
                                        alt="excel icon"
                                        style={{
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                ) : null}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Alert severity="info" sx={{ fontWeight: "bold", mt: 1.5 }}>
              No transactions found.
            </Alert>
          )}
        </>
      )}
      {showActivateNow ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "75vh" }}
        >
          <Typography>
            Ledger service is not active for {selectedPartner}
          </Typography>
          <Button
            sx={{ mt: 1.5 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setShowEditLedgerInfo(true)}
            startIcon={<AddIcon />}
          >
            Activate Now
          </Button>
        </Stack>
      ) : null}
      {showAddTxnModal && (
        <AddTransactionModal
          showAddTxnModal={showAddTxnModal}
          setShowAddTxnModal={setShowAddTxnModal}
          updateFn={getLedgerData}
          selectedPartner={selectedPartner}
        />
      )}
      {showEditLedgerInfo && (
        <EditLedgerInfoModal
          showEditLedgerInfo={showEditLedgerInfo}
          setShowEditLedgerInfo={setShowEditLedgerInfo}
          ledgerInfo={ledgerData?.ledger_info}
          selectedPartner={selectedPartner}
          updateFn={getLedgerData}
        />
      )}
      {showAcDetails && (
        <Dialog
          open={showAcDetails}
          onClose={() => setShowAcDetails(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setShowAcDetails(false)}
          >
            MedPay Account Details
          </CustomDialogTitle>
          <DialogContent sx={{ overflowY: "scroll" }}>
            <Typography>
              <strong>Account No.:</strong> 065863700002001{" "}
            </Typography>
            <Typography>
              <strong>Bank Name:</strong> YES Bank{" "}
            </Typography>
            <Typography>
              <strong>IFSC Code:</strong> YESB0000658{" "}
            </Typography>
            <Typography>
              <strong>Account Name:</strong> Medway Technologies Private Limited{" "}
            </Typography>
            <Typography mt={3} variant="body2">
              <strong>Note:</strong> Please ensure account number on which
              payment is being made is exactly the same as mentioned above and
              do mention your registered business name in the remarks section
              while making the payment.
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Ledger;
