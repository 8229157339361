import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import {
  API_RESULT,
  API_VERSION,
  SETTLEMENT_MODE,
} from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  textCapitalize,
  validateFloatNum,
} from "../../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import EditTimingModal from "./editTimingModal";
import { LoadingButton } from "@mui/lab";
import { Form, Formik } from "formik";
import { valueLimits } from "../../../constants";
import { updateDiscountsSchema } from "../../../schemas/dunzoproviders.schema";
import EditBankDetailsModal from "./editBankDetailsModal";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ProviderInfoModal = ({
  showProviderInfoModal,
  setShowProviderInfoModal,
  activeLocationId,
  providersData,
  setProvidersData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [providerInfo, setProviderInfo] = useState<any>(null);
  const [showEditTimingModal, setShowEditTimingModal] = useState(false);
  const [showEditDiscounts, setShowEditDiscounts] = useState(false);
  const [discountsLoading, setDiscountsLoading] = useState(false);
  const [showEditBankDetailsModal, setShowEditBankDetailsModal] =
    useState(false);

  const getDunzoProviderInfo = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/dunzo/provider?location_id=${activeLocationId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProviderInfo(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDunzoProviderInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function ifAllSlotsAvl() {
  //   let slotAvlForAllDays = true;
  //   for (const element of providerInfo?.hours_of_operation?.day_wise) {
  //     if (element[Object.keys(element)[0]].length === 0) {
  //       slotAvlForAllDays = false;
  //       break;
  //     }
  //   }
  //   return slotAvlForAllDays;
  // }

  const handleDiscountsUpdate = async (values: any, actions: any) => {
    let payload = {
      location_id: providerInfo?.location_id,
      update_type: "discounts",
      discounts: {
        fmcg: values?.fmcg,
        medicines: values?.medicines,
      },
    };

    setDiscountsLoading(true);

    try {
      const result = await privateInstance.put(
        `${API_VERSION.V1}/orders/dunzo/provider`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setProviderInfo({
          ...providerInfo,
          discounts: result.data.response?.discounts,
        });
        setShowEditDiscounts(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setDiscountsLoading(false);
    }
  };

  return (
    <Dialog
      open={showProviderInfoModal}
      onClose={() => setShowProviderInfoModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowProviderInfoModal(false)}
      >
        Provider Info | {providerInfo?.association_display_name}
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="body2">
              Location ID: {providerInfo?.location_id}
            </Typography>
            <Typography variant="body2">
              Provider Name: {providerInfo?.provider_name}
            </Typography>
            {providerInfo?.formatted_address ? (
              <Typography variant="body2">
                Address: {providerInfo?.formatted_address}
              </Typography>
            ) : null}
            {providerInfo?.gst_no ? (
              <Typography variant="body2">
                GST NO: {providerInfo?.gst_no}
              </Typography>
            ) : (
              <Tooltip title="GST number missing">
                <Typography variant="body2">
                  GST NO:{" "}
                  <WarningAmberIcon
                    sx={{ position: "relative", top: "5px" }}
                    color="warning"
                    fontSize="small"
                  />
                </Typography>
              </Tooltip>
            )}
            {providerInfo?.gst_name ? (
              <Typography variant="body2">
                GST Name: {providerInfo?.gst_name}
              </Typography>
            ) : null}
            {/* {providerInfo?.whatsapp_number && (
              <Typography variant="body2">
                Mobile: {providerInfo?.whatsapp_number}
              </Typography>
            )} */}
            <Typography variant="body2">
              Status:&nbsp;
              <span
                style={{
                  color: providerInfo?.status === "active" ? "green" : "red",
                }}
              >
                {providerInfo?.status}
              </span>
            </Typography>

            <Box py={1}>
              <Divider />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Payment Details:</Typography>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="close"
                  onClick={() => setShowEditBankDetailsModal(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Typography variant="body2">
                Settlement Mode: {providerInfo?.settlement_mode}
              </Typography>
              {providerInfo?.settlement_mode?.toLowerCase() ===
              SETTLEMENT_MODE.UPI ? (
                <>
                  <Typography variant="body2">
                    UPI ID: {providerInfo?.upi_id || "NA"}
                  </Typography>
                  <Typography variant="body2">
                    UPI Name: {providerInfo?.upi_name || "NA"}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="body2">
                    Account Name: {providerInfo?.account_name || "NA"}
                  </Typography>
                  <Typography variant="body2">
                    Account NO.: {providerInfo?.account_no || "NA"}
                  </Typography>
                  <Typography variant="body2">
                    Bank Name: {providerInfo?.bank_name || "NA"}
                  </Typography>
                  <Typography variant="body2">
                    Branch Name: {providerInfo?.branch_name || "NA"}
                  </Typography>
                  <Typography variant="body2">
                    IFSC Code: {providerInfo?.ifsc_code || "NA"}
                  </Typography>
                </>
              )}
            </Box>

            <Box py={1}>
              <Divider />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Discounts:</Typography>
              {showEditDiscounts ? null : (
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowEditDiscounts(true)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {showEditDiscounts ? (
              <Formik
                initialValues={{
                  medicines: providerInfo?.discounts?.medicines || 0,
                  fmcg: providerInfo?.discounts?.fmcg || 0,
                }}
                validationSchema={updateDiscountsSchema}
                onSubmit={(values: any, actions: any) => {
                  actions.setSubmitting(false);
                  handleDiscountsUpdate(values, actions);
                }}
              >
                {(props) => {
                  const { values, setFieldValue, handleBlur, errors, touched } =
                    props;
                  return (
                    <Form>
                      <Box display="flex">
                        <TextField
                          fullWidth
                          id="medicines"
                          label="Medicines (%)"
                          size="small"
                          value={values?.medicines}
                          sx={{ mt: 1.5 }}
                          InputLabelProps={{
                            required: true,
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue(
                              "medicines",
                              validateFloatNum(
                                e.target.value,
                                valueLimits.PERCENT
                              )
                            )
                          }
                          onBlur={handleBlur}
                          helperText={
                            errors.medicines && touched.medicines
                              ? errors.medicines
                              : ""
                          }
                          error={
                            errors.medicines && touched.medicines ? true : false
                          }
                        />
                        <TextField
                          fullWidth
                          id="fmcg"
                          label="FMCG (%)"
                          size="small"
                          value={values?.fmcg}
                          sx={{ mt: 1.5, ml: 1.5 }}
                          InputLabelProps={{
                            required: true,
                            shrink: true,
                          }}
                          onChange={(e) =>
                            setFieldValue(
                              "fmcg",
                              validateFloatNum(
                                e.target.value,
                                valueLimits.PERCENT
                              )
                            )
                          }
                          onBlur={handleBlur}
                          helperText={
                            errors.fmcg && touched.fmcg ? errors.fmcg : ""
                          }
                          error={errors.fmcg && touched.fmcg ? true : false}
                        />
                      </Box>
                      <Box my={2} textAlign="center">
                        <LoadingButton
                          size="medium"
                          type="submit"
                          color="primary"
                          loading={discountsLoading}
                          loadingIndicator={
                            <CircularProgress
                              size={20}
                              sx={{ color: "#fff" }}
                            />
                          }
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                        <Button
                          size="medium"
                          color="primary"
                          variant="outlined"
                          type="button"
                          onClick={() => setShowEditDiscounts(false)}
                          sx={{ ml: 1.5 }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Box display="flex">
                {providerInfo?.discounts?.medicines && (
                  <Typography variant="body2">
                    Medicines: {providerInfo?.discounts?.medicines} %
                  </Typography>
                )}
                {providerInfo?.discounts?.fmcg && (
                  <>
                    &nbsp;|&nbsp;
                    <Typography variant="body2">
                      FMCG: {providerInfo?.discounts?.fmcg} %
                    </Typography>
                  </>
                )}
              </Box>
            )}

            <Box py={1}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6">Timings:</Typography>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="close"
                  onClick={() => setShowEditTimingModal(true)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {providerInfo?.status === "active" ? (
              <>
                {providerInfo?.hours_of_operation?.hasOwnProperty(
                  "24hours_availability"
                ) ? (
                  <Typography variant="body2">
                    24H Availability:{" "}
                    {providerInfo?.hours_of_operation["24hours_availability"]}
                  </Typography>
                ) : null}

                {providerInfo?.hours_of_operation?.day_wise &&
                  providerInfo.hours_of_operation.day_wise.map(
                    (x: any, index: number) => {
                      return (
                        <Typography key={index} variant="body2">
                          {textCapitalize(Object.keys(x)[0])} :{" "}
                          {x[Object.keys(x)[0]]?.length === 0
                            ? "NA"
                            : x[Object.keys(x)[0]]?.map(
                                (timeObj: any, i: number) => (
                                  <span key={i}>
                                    {i === 0 ? "" : ", "}
                                    {timeObj.from} - {timeObj.to}{" "}
                                  </span>
                                )
                              )}{" "}
                        </Typography>
                      );
                    }
                  )}
              </>
            ) : null}
          </>
        )}
        {showEditTimingModal && (
          <EditTimingModal
            showEditTimingModal={showEditTimingModal}
            setShowEditTimingModal={setShowEditTimingModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
          />
        )}
        {showEditBankDetailsModal && (
          <EditBankDetailsModal
            showEditBankDetailsModal={showEditBankDetailsModal}
            setShowEditBankDetailsModal={setShowEditBankDetailsModal}
            providerInfo={providerInfo}
            setProviderInfo={setProviderInfo}
            providersData={providersData}
            setProvidersData={setProvidersData}
            providerType="dunzo"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProviderInfoModal;
