import * as yup from "yup";

export const addConsLabProviderSchema = yup.object().shape({
  provider_name: yup.string().required("Provider name is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  whatsapp: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Whatsapp is required"),
  address: yup.string().required("Address is required"),
  landmark: yup.string().required("Landmark is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

export const addPharmProviderSchema = addConsLabProviderSchema.concat(
  yup.object().shape({
    provider_id: yup.string().required("Provider ID is required"),
    gst_no: yup.string().when("provider_id", {
      is: (provider_id: string) => provider_id?.toLowerCase() === "medpay",
      then: yup
        .string()
        .min(2, "GSTIN number should be min 2 characters(eg:NA)")
        .required("GSTIN number is required(eg:NA)"),
      otherwise: yup
        .string()
        .min(15, "GST number should be of 15 digit")
        .required("GSTIN number is required"),
    }),
  })
);

export const gstSchema = yup.object().shape({
  gst_no: yup
    .string()
    .min(15, "GST number should be of 15 digit")
    .required("GSTIN number is required"),
});

export const gstSchemaMedpay = yup.object().shape({
  gst_no: yup
    .string()
    .min(2, "GSTIN number should be min 2 characters(eg:NA)")
    .required("GSTIN number is required(eg:NA)"),
});

export const mobileUpdateSchema = yup.object().shape({
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
});

export const editCustomerSchemaPharma = yup.object().shape({
  full_name: yup.string().required("Full name is required"),
  address: yup.string().required("Address is required"),
  landmark: yup.string().required("Landmark is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

export const editCustomerSchemaNonPharma = editCustomerSchemaPharma.concat(
  yup.object().shape({
    age: yup.string().required("Age is required"),
    gender: yup.string().required("Gender is required"),
  })
);

export const loginSchema = yup.object().shape({
  emailUsername: yup.string().required("Email or username is required"),
  password: yup
    .string()
    .min(6, "should be of minimum 6 characters length")
    .max(100, "cannot exceed 100 characters length")
    .required("Password is required"),
});

export const createUserSchema = yup.object().shape({
  user_id: yup
    .string()
    .email("Please enter valid email")
    .required("User ID is required"),
  phone_number: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  user_type: yup.string().required("User type is required"),
  role: yup.string().required("Role is required"),
  provider_id: yup.string().nullable().required("Partner is required"),
  team: yup.string().when("provider_id", {
    is: (provider_id: string) => provider_id?.toLowerCase() === "medpay",
    then: yup.string().required("Team is required"),
  }),
});

export const updateUserSchema = createUserSchema.concat(
  yup.object().shape({
    status: yup.string().required("Status is required"),
  })
);

export const agentDataSchema = yup.object().shape({
  manager_id: yup.string().required("Manager id is required"),
  availability: yup.string().required("Availability is required"),
  // all_teams: yup
  //   .array()
  //   .of(yup.string().required())
  //   .min(1, "select at least one language"),
});

export const mailReportSchema = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
  start_date: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("Start Date is required"),
  end_date: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("End Date is required"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
});

export const mailReportSchemaAudit = mailReportSchema.concat(
  yup.object().shape({
    report_type: yup.string().required("Report type is required"),
  })
);

export const mailReportSchemaOrder = mailReportSchema.concat(
  yup.object().shape({
    order_type: yup.string().required("Order type is required"),
  })
);

export const getOrderByPartnerOrderIdSchema = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
});

export const getOrderByPartnerOrderIdSchemaManage = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
  search_id: yup.string().required("Partner Order ID is required"),
});

export const getOrderByOrderIdSchema = yup.object().shape({
  search_id: yup.string().required("Order ID is required"),
});

export const updateInvoiceSchema = yup.object().shape({
  updated_bill_amount: yup.string().required("Updated Bill Amount is required"),
});

export const updateInvoiceSchemaRestricted = (maxVal: any) =>
  yup.object().shape({
    updated_bill_amount: yup
      .number()
      .max(maxVal, "Amount cannot be more than " + maxVal + "")
      .required("Updated Bill Amount is required"),
  });
