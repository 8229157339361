import React, { useState } from "react";
import QrScanner from "qr-scanner";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../../../utils/common";
import { CircularProgress, Typography } from "@mui/material";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";

const FindUpiQR = ({ qrData, setQrData, setFieldValue }: any) => {
  const [qrLoading, setQrLoading] = useState(false);
  const [qrResultMessage, setQrResultMessage] = useState("");
  const privateInstance = useAxiosInstance();

  const qr_read = async (files: any) => {
    let error = false;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!error) {
        QrScanner.scanImage(file)
          .then((result: any) => {
            handleCheckUpi(result, error);
          })
          .catch((error) => {
            console.log(error || "No QR code found.");
            setQrResultMessage("Please upload a QR Code");
            error = true;
          });
      }
    }
  };

  const handleCheckUpi = async (qrResult: any, error: any) => {
    const payload = {
      qr_code: qrResult,
    };
    setQrLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/find-upi-id`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        // console.log("resss", result.data.response);
        setQrData(result.data.response);
        if (setFieldValue) {
          setFieldValue("upi_id", result.data.response.upi_id);
          setFieldValue(
            "account_holder_name",
            result.data.response.account_holder_name
          );
        }
        setQrResultMessage("Successfully retrieved UPI");
      } else {
        setQrResultMessage("Unable to retrieve UPI");
        errorToast(result.data?.message);
        setQrData({
          ...qrData,
          upi_id: "",
          account_holder_name: "",
        });
        if (setFieldValue) {
          setFieldValue("upi_id", "");
          setFieldValue("account_holder_name", "");
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      //hack for upi payment qr upload to enforce verification of upi from qr
      if (setFieldValue) {
        setFieldValue("upi_name", "");
      }
      setQrLoading(false);
    }
  };

  return (
    <>
      {qrLoading ? (
        <CircularProgress size={20} />
      ) : (
        <>
          <input
            type="file"
            id="qr-upload"
            onChange={(e: any) => qr_read(e.target.files)}
          />
          <Typography variant="h6" sx={{ mt: 1.5 }}>
            {qrResultMessage}
          </Typography>
        </>
      )}
    </>
  );
};

export default FindUpiQR;
