import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  shouldShowOrderData,
} from "../../../../utils/common";
import OrderCustomerInfo from "../Common/orderCustomerInfo";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import OrderPlatform from "../../../Common/orderPlatform";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OrderData from "../../../OrderDetails/orderData";
import SearchAndAssign from "./searchAndAssign";

const AssignProviderModal = ({
  assignProviderModal,
  setAssignProviderModal,
  activePartner,
  activePartnerOrderId,
  assignType,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [orderData, setOrderData] = useState<any>({});

  useEffect(() => {
    const getOrderByPartnerOrderId = async () => {
      setLoading(true);
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/partner/${String(
            activePartner
          )?.toLowerCase()}/${activePartnerOrderId}`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setOrderData(result.data.response);
        } else {
          setOrderData([]);
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    getOrderByPartnerOrderId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={assignProviderModal}
      onClose={() => setAssignProviderModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setAssignProviderModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h3">
            {assignType === "assign" ? "Assign" : "Re-assign"} Providers
          </Typography>
          {orderData && orderData.platform && (
            <OrderPlatform platform={orderData.platform} />
          )}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <OrderCustomerInfo
              orderData={orderData}
              setOrderData={setOrderData}
              setParentModal={setAssignProviderModal}
            />
            {orderData.order_type !== ORDER_TYPE.MEDICINES && (
              <Box sx={{ textAlign: "center", mt: 3 }}>
                <Typography variant="h6">
                  Requested{" "}
                  {orderData?.order_type === ORDER_TYPE.CONSULTATION
                    ? "appointment"
                    : "sample collection"}{" "}
                  time: {orderData?.time_slot}
                </Typography>
              </Box>
            )}
            {orderData?.order_type === ORDER_TYPE.CONSULTATION &&
            orderData.booking_id ? (
              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                <Typography variant="h6">
                  Requested doctor ID: {orderData.booking_id}
                </Typography>
              </Box>
            ) : null}
            {orderData?.order_type !== ORDER_TYPE.CONSULTATION && (
              <Accordion
                sx={{
                  my: 2,
                  boxShadow: "0px 0.5rem 1rem rgba(0, 0, 0, 0.15)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h4">
                    {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
                      ? "Diagnostics"
                      : "Medicines"}{" "}
                    details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {shouldShowOrderData(orderData) && (
                    <OrderData
                      orderData={orderData}
                      showHeading={false}
                      notAdminPanel={true}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {assignType === "re-assign" && orderData?.merchant_details && (
              <Box sx={{ mt: 3 }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>NAME</TableCell>
                        <TableCell>PHONE NO</TableCell>
                        <TableCell>ADDRESS</TableCell>
                        <TableCell>GST NO</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography variant="body2">
                            {orderData?.merchant_details?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {orderData?.merchant_details?.mobile}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {orderData?.merchant_details?.formatted_address}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {orderData?.merchant_details?.gst_no}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            {orderData?.delivery_partner?.toLowerCase() === "logistics" &&
            orderData?.order_status ===
              ORDER_STATUS.PAYMENT_COLLECTED ? null : (
              <SearchAndAssign
                orderData={orderData}
                assignType={assignType}
                setAssignProviderModal={setAssignProviderModal}
                setParentOrderData={undefined}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AssignProviderModal;
