import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import { handleError } from "../../../utils/common";

const UsageStatsMetrics = ({ selectedMonth, selectedPartner }: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getMetricsData = async () => {
    let payload = {
      month: Number(selectedMonth),
      year: 2024,
      metric_type: "monthly_summary",
      order_type: "medicines",
      partner_id: selectedPartner,
    };
    setLoading(true);
    try {
      const result: any = await instance.post(
        `${config.URL}${API_VERSION.V2}/analytics/partner-apis`,
        payload
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setMetricData(result.data.response["monthly_summary"]);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, selectedPartner]);

  return (
    <Box
      width="100%"
      sx={{
        padding: "20px",
      }}
    >
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
          }}
        >
          <Stack width="100%" direction="row" gap={4}>
            <Stack
              sx={{
                border: "2px solid #0058ff",
                padding: "6px 12px",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px !important",
                  textTransform: "capitalize",
                }}
              >
                {metricData?.plan_type} Plan
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px !important",
                  textTransform: "capitalize",
                  fontWeight: "600",
                }}
              >
                free calls:{" "}
                {new Intl.NumberFormat("en-IN").format(metricData?.quota_limit)}{" "}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  textAlign: "right",
                  marginTop: "4px",
                }}
              >
                Additional Charges @30p/call
              </Typography>
            </Stack>
            <Stack
              sx={{
                border: "2px solid #0058ff",
                padding: "6px 12px",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px !important",
                }}
              >
                Total Usage
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px !important",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                {new Intl.NumberFormat("en-IN").format(metricData?.api_usage)}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  textAlign: "right",
                  marginTop: "4px",
                }}
              >
                Total no. of API calls
              </Typography>
            </Stack>
            <Stack
              sx={{
                border: "2px solid #0058ff",
                padding: "6px 12px",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px !important",
                }}
              >
                Additional Calls
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px !important",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                {new Intl.NumberFormat("en-IN").format(
                  metricData?.over_limit_usage
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  textAlign: "right",
                  marginTop: "4px",
                }}
              >
                Total no. of API calls billed for
              </Typography>
            </Stack>
            <Stack
              sx={{
                border: "2px solid #0058ff",
                padding: "6px 12px",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px !important",
                }}
              >
                Total Charges
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px !important",
                  textTransform: "uppercase",
                  fontWeight: "600",
                }}
              >
                &#8377;{" "}
                {new Intl.NumberFormat("en-IN").format(
                  metricData?.over_limit_fee
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px !important",
                  textAlign: "right",
                  marginTop: "4px",
                }}
              >
                Inclusive of all taxes
              </Typography>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default UsageStatsMetrics;
