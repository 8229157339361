import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import {
  ORDER_PLATFORM,
  ORDER_STATUS,
  ORDER_TYPE,
} from "../../enums/order.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { setAllOrderData } from "../../store/actions/useActions";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../utils/common";

const OrderNotesAndConfirmation = ({ orderData, setOrderDataModal }: any) => {
  const user: AuthenticatedUser = useUserInfo();
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const handleInvoiceGenerate = async () => {
    const medicines = orderData?.order_details.items;
    medicines.forEach((medicine: any) => {
      medicine.gst = isNaN(Number(medicine.gst)) ? 0 : Number(medicine.gst);
    });

    let payload = {
      order_id: orderData?.order_id,
      discount_percentage: orderData?.discount_percentage,
      provider_delivery_charges: orderData?.provider_delivery_charges,
      merchant_details: {
        ...orderData?.merchant_details,
        order_availability: "complete",
      },
      order_details: {
        items: medicines,
        doctor: orderData?.order_details?.doctor,
      },
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/invoices/create-invoice`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(`Invoice creation successfull!`);
        setOrderDataModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptReject = async (action_type: string) => {
    let payload = {
      order_id: orderData?.order_id,
      type: "partner-updated",
      partner_order_status: action_type,
    };

    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/partner-update-modification`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(`Order ${action_type} successfully`);
        if (action_type === "rejected") {
          setOrderDataModal(false);
        } else {
          if (orderData?.order_type !== ORDER_TYPE.MEDICINES) {
            setOrderDataModal(false);
          }
        }
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload?.order_id,
              result.data.response
            )
          )
        );

        //generate invoice only for medicine order after accepting
        if (
          action_type === "accepted" &&
          orderData?.order_type === ORDER_TYPE.MEDICINES
        ) {
          handleInvoiceGenerate();
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      if (action_type === "rejected") {
        setLoading(false);
      } else {
        if (orderData?.order_type !== ORDER_TYPE.MEDICINES) {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      {/* PARTNER SPECIFIC: ekacare, magicpin, ai4rx, watchyourhealth_hdfc, mmhglobal */}
      {orderData?.platform === ORDER_PLATFORM.PARTNER_API &&
        ![
          "ekacare",
          "magicpin",
          "ai4rx",
          "watchyourhealth_hdfc",
          "mmhglobal",
        ].includes(orderData?.order_partner?.toLowerCase()) &&
        user?.role === USER_ROLE.PARTNER &&
        orderData?.order_status === ORDER_STATUS.PARTNER_PENDING && (
          <Box mt={5}>
            <Typography variant="h4">Note : </Typography>
            <ol type="1" style={{ paddingLeft: "1rem" }}>
              <li>
                This is an app generated order and you are not suppose to accept
                the order modification. Please wait until user confirms from the
                app.
              </li>
            </ol>
          </Box>
        )}

      {orderData?.platform === ORDER_PLATFORM.PARTNER_API &&
        user?.role !== USER_ROLE.PARTNER &&
        orderData?.order_status === ORDER_STATUS.PARTNER_PENDING && (
          <Box mt={5}>
            <Typography variant="h4">Note : </Typography>
            <ol type="1" style={{ paddingLeft: "1rem" }}>
              <li>
                Order has been modified and awaiting acceptance from{" "}
                {orderData?.order_partner}. Please wait untill partner accept
                the order modification.
              </li>
            </ol>
          </Box>
        )}

      {orderData?.order_status === ORDER_STATUS.PARTNER_PENDING &&
        (orderData?.platform !== ORDER_PLATFORM.PARTNER_API ||
          [
            "ekacare",
            "magicpin",
            "ai4rx",
            "watchyourhealth_hdfc",
            "mmhglobal",
          ].includes(orderData?.order_partner?.toLowerCase())) && (
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={3}
          >
            <LoadingButton
              sx={{ width: "150px" }}
              size="medium"
              color="success"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={() => handleAcceptReject("accepted")}
            >
              Accept
            </LoadingButton>
            <LoadingButton
              sx={{ width: "150px", ml: 2 }}
              size="medium"
              color="error"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={() => handleAcceptReject("rejected")}
            >
              Reject
            </LoadingButton>
          </Stack>
        )}
    </>
  );
};

export default OrderNotesAndConfirmation;
