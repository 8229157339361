import { Box, CircularProgress, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
} from "../../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import LineChartMulti from "../../../Metrics/Charts/LineChartMulti";

const AovMetrics = ({
  startDate,
  endDate,
  graphTitle,
  tooltipText,
  txnData,
  loading,
  filterBy,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);

  useEffect(() => {
    if (filterBy === "all") {
      setMetricData(txnData);
    } else {
      setMetricData(txnData.filter((x: any) => x.status === filterBy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnData, filterBy]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);

    const avgOrderValue: any = [];
    const avgStoreValue: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(
        rawData,
        "created_at_date",
        el
      );

      let count: any = getCountByKey(filteredDataByLabel, "order_volume");
      let value: any = getCountByKey(filteredDataByLabel, "order_value");
      avgOrderValue.push(Number(value / count)?.toFixed(2));
      avgStoreValue.push(
        Number(
          getCountByKey(filteredDataByLabel, "avg_store_txn_value")
        )?.toFixed(2)
      );
    });

    const datasets = [
      {
        label: "Avg Store Txn Value",
        type: "line",
        data: avgStoreValue,
        borderColor: "#0058FF",
        backgroundColor: "#0058FF",
        yAxisID: "y1",
        order: 1,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        pointStyle: false,
        pointRadius: 0,
      },
      {
        label: "Avg Order Value",
        type: "bar",
        data: avgOrderValue,
        borderColor: "#8AB2FF",
        backgroundColor: "#8AB2FF",
        yAxisID: "y",
        order: 2,
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
          }}
        >
          <Box pb={2} textAlign="right">
            <Tooltip title={tooltipText}>
              <InfoIcon color="primary" fontSize="small" />
            </Tooltip>
          </Box>
          {genData && (
            <LineChartMulti
              title={graphTitle}
              data={genData}
              additionalOptions={{
                leftSize: 200,
                rightSize: 500,
                leftLabel: "Avg Order Value",
                rightLabel: "Avg Store Txn Value",
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default AovMetrics;
