import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/login";
import Layout from "./components/layout";
import RequireAuth from "./components/Helpers/RequireAuth";
import UserLayout from "./components/UserLayout/userLayout";
import Home from "./pages/Dashboard/home";
import PersistLogin from "./components/Helpers/PersistLogin";
import CreateConsultationOrder from "./pages/Consultation/Create";
import Users from "./pages/Settings/Users/users";
import Orders from "./pages/Settings/Orders/orders";
import OrderReport from "./pages/Reports/orderReport";
import CashlessReport from "./pages/Reports/cashlessReport";
import AuditReport from "./pages/Reports/auditReport";
import CreatePharmacyOrder from "./pages/Pharmacy/Create";
import CreateLabOrder from "./pages/Diagnostics/Create";
import AssignConsultationOrder from "./pages/Consultation/assign";
import ManageConsultationOrder from "./pages/Consultation/manage";
import AssignPharmacyOrder from "./pages/Pharmacy/assign";
import ManagePharmacyOrder from "./pages/Pharmacy/manage";
import AssignLabOrder from "./pages/Diagnostics/assign";
import ManageLabOrder from "./pages/Diagnostics/manage";
import Bills from "./pages/Cashless/bills";
import Recovery from "./pages/Cashless/recovery";
import UserProfile from "./pages/Cashless/userProfile";
import ManageGrouped from "./components/ManageOrder/manageGrouped";
import WhitleListing from "./pages/Settings/Whitelisting";
import Payments from "./pages/Payments";
import NotFound from "./components/Helpers/notFound";
import AgentRoaster from "./pages/AgentRoaster";
import Dunzo from "./pages/Dunzo";
import ActivateUser from "./pages/Login/activateUser";
import CheckLogin from "./components/Helpers/checkLogin";
import DummyPayment from "./pages/dummyPayment";
import Logistics from "./pages/Logistics";
import Ledger from "./pages/Ledger";
import DunzoProviders from "./pages/Dunzo/Providers";
import MetricsIndex from "./pages/Metrics";
import CreateIpdOrder from "./pages/IPD/Create";
import ManageIpdOrder from "./pages/IPD/manage";
import ProvidersSettings from "./pages/Providers";
import Roles from "./pages/Settings/Roles";
import OndcIgm from "./pages/Ondc/Igm";
import OndcOrders from "./pages/Ondc/Orders";
import PincodesComponent from "./pages/Settings/Pincodes";
import AppIssues from "./pages/AppIssues2";
import CustomerCartReport from "./pages/Reports/customerCart";
import ApiMetrics from "./pages/ApiMetrics";
import QrTerminals from "./pages/DigitalPayment/QrTerminals";
import DigitalPaymentOverview from "./pages/DigitalPayment/Overview";
import DigitalPayouts from "./pages/DigitalPayment/Payouts";
import DigitalPaymentTxn from "./pages/DigitalPayment/Transactions";
import SearchMed from "./pages/SearchMed";
import KycDetails from "./pages/DigitalPayment/KycDetails";
import Merchants from "./pages/Merchants";
import SingleMerchant from "./pages/Merchants/SingleMerchant";
import CartView from "./pages/SearchMed/cartView";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* to persist distributor cart data */}
          <Route element={<PersistLogin />}>
            <Route path="/search" element={<SearchMed />} />
            <Route path="/search/cart" element={<CartView />} />
          </Route>
          {/* Public Paths */}
          <Route element={<CheckLogin />}>
            <Route path="/" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="/activate" element={<ActivateUser />} />
          </Route>
          {/* Not found */}
          <Route path="*" element={<NotFound />} />

          {/* Protected Paths */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route element={<UserLayout />}>
                <Route path="dashboard" element={<Home />} />
                <Route
                  path="consultation/create"
                  element={<CreateConsultationOrder />}
                />
                <Route
                  path="consultation/assign"
                  element={<AssignConsultationOrder />}
                />
                <Route
                  path="consultation/manage"
                  element={<ManageConsultationOrder />}
                />
                <Route
                  path="consultation/manage-order"
                  element={<ManageGrouped />}
                />
                <Route
                  path="pharmacy/create"
                  element={<CreatePharmacyOrder />}
                />
                <Route
                  path="pharmacy/assign"
                  element={<AssignPharmacyOrder />}
                />
                <Route
                  path="pharmacy/manage"
                  element={<ManagePharmacyOrder />}
                />
                <Route
                  path="pharmacy/manage-order"
                  element={<ManageGrouped />}
                />
                <Route path="diagnostics/create" element={<CreateLabOrder />} />
                <Route path="diagnostics/assign" element={<AssignLabOrder />} />
                <Route path="diagnostics/manage" element={<ManageLabOrder />} />
                <Route
                  path="diagnostics/manage-order"
                  element={<ManageGrouped />}
                />
                <Route path="ondc/orders" element={<OndcOrders />} />
                <Route path="ondc/igm" element={<OndcIgm />} />
                <Route path="ipd/create" element={<CreateIpdOrder />} />
                <Route path="ipd/manage" element={<ManageIpdOrder />} />
                <Route path="dunzo/orders" element={<Dunzo />} />
                <Route path="dunzo/providers" element={<DunzoProviders />} />
                <Route path="cashless-opd/bills" element={<Bills />} />
                <Route path="cashless-opd/recovery" element={<Recovery />} />
                <Route
                  path="cashless-opd/user-profile"
                  element={<UserProfile />}
                />
                <Route path="payments" element={<Payments />} />
                <Route path="logistics" element={<Logistics />} />
                <Route path="settings/users" element={<Users />} />
                <Route path="settings/admin-panel" element={<Orders />} />
                <Route
                  path="settings/whitelisting"
                  element={<WhitleListing />}
                />
                <Route
                  path="settings/admin-panel/:provider/:providerOrderId"
                  element={<Orders />}
                />
                <Route path="settings/roles" element={<Roles />} />
                <Route
                  path="settings/pincodes"
                  element={<PincodesComponent />}
                />
                <Route path="reports/orders" element={<OrderReport />} />
                <Route path="reports/cashless" element={<CashlessReport />} />
                <Route path="reports/cart" element={<CustomerCartReport />} />
                <Route path="reports/audit" element={<AuditReport />} />
                <Route path="agent-roster" element={<AgentRoaster />} />
                <Route path="ledger" element={<Ledger />} />
                <Route path="app-issues" element={<AppIssues />} />
                <Route path="metrics" element={<MetricsIndex />} />
                <Route path="api-metrics" element={<ApiMetrics />} />
                <Route path="providers" element={<ProvidersSettings />} />
                <Route path="merchants" element={<Merchants />} />
                <Route
                  path="merchants/:locationId"
                  element={<SingleMerchant />}
                />
                <Route
                  path="/digital-payment/overview"
                  element={<DigitalPaymentOverview />}
                />
                <Route
                  path="/digital-payment/kyc-details"
                  element={<KycDetails />}
                />
                <Route
                  path="/digital-payment/qr-terminals"
                  element={<QrTerminals />}
                />
                <Route
                  path="/digital-payment/transactions"
                  element={<DigitalPaymentTxn />}
                />
                <Route
                  path="/digital-payment/payouts"
                  element={<DigitalPayouts />}
                />
                <Route path="/payment-gateway" element={<DummyPayment />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
