import * as yup from "yup";

export const editPharmacySchema = yup.object().shape({
  provider_id: yup.string().required("Provider ID is required"),
  delivery_partner: yup.string().required("Delivery Partner is required"),
  gst_no: yup.string().when("provider_id", {
    is: (provider_id: string) => provider_id?.toLowerCase() === "medpay",
    then: yup
      .string()
      .min(2, "GSTIN number should be min 2 characters(eg:NA)")
      .required("GSTIN number is required(eg:NA)"),
    otherwise: yup
      .string()
      .min(15, "GST number should be of 15 digit")
      .required("GSTIN number is required"),
  }),
  discount: yup.string().required("Discount percent is required"),
  merchant: yup.string().required("Merchant is required"),
  merchant_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  company: yup.string().required("GST Company name is required"),
});

export const createPharmacySchema = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
  partner_order_id: yup
    .string()
    .matches(
      "^[a-zA-Z0-9_-]+$" as any,
      "Only alphanumeric, underscore(_) , hyphen(-) allowed"
    )
    .required("Partner Order ID is required"),
  order_type: yup.string().required("Order Type is required"),
  delivery_partner: yup.string().required("Delivery Partner is required"),
  payment_collection: yup.string().required("Payment Collection is required"),
  full_name: yup.string().required("Full name is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  alt_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000"),
  email: yup.string().email("Must be valid email"),
  address: yup.string().required("Address is required"),
  landmark: yup.string().required("Landmark is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
});

export const createPharmacyInvoiceSchema = yup.object().shape({
  delivery_partner: yup.string().required("Delivery partner is required"),
  discount: yup
    .number()
    .required("Discout value is required")
    .min(0, "min 0 is allowed")
    .max(100, "max 100 is allowed"),
});

export const addNewMedicineSchema = yup.object().shape({
  name: yup.string().required("Medicine name is required"),
  mfr: yup.string().nullable().required("MFR is required"),
  mrp: yup
    .number()
    .required("MRP is required")
    .positive("should be greater than 0"),
  pack_quantity: yup.string().required("Pack Qty is required"),
  // pack_quantity: yup
  //   .number()
  //   .required("Pack quantity is required")
  //   .min(1, "min 1 is allowed"),
  // composition: yup.string().required("Composition is required"),
});
