import * as yup from "yup";

export const editDiagnosticSchema = yup.object().shape({
  time_slot: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("DateTime is required"),
  lab_name: yup.string().required("Lab Name is required"),
  lab_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Lab Mobile is required"),
  discount: yup.string().required("Discount percent is required"),
});

export const createDiagnosticSchema = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
  partner_order_id: yup
    .string()
    .matches(
      "^[a-zA-Z0-9_-]+$" as any,
      "Only alphanumeric, underscore(_) , hyphen(-) allowed"
    )
    .required("Partner Order ID is required"),
  payment_collection: yup.string().required("Payment Collection is required"),
  lab_visit_type: yup.string().required("Sample Collection is required"),
  full_name: yup.string().required("Full name is required"),
  age: yup.string().required("Age is required"),
  gender: yup.string().required("Gender is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  alt_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000"),
  email: yup.string().email("Must be valid email"),
  address: yup.string().required("Address is required"),
  landmark: yup.string().required("Landmark is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  time_slot: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("DateTime is required"),
});

export const phlebotomistSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
});

export const createDiagnosticsInvoiceSchema = yup.object().shape({
  discount: yup
    .number()
    .required("Discout value is required")
    .min(0, "min 0 is allowed")
    .max(100, "max 100 is allowed"),
});
