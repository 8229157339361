import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  amountConverter,
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
} from "../../../../utils/common";
import LineChartMulti from "../../../Metrics/Charts/LineChartMulti";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";

const DayWiseMetrics = ({
  startDate,
  endDate,
  graphTitle,
  txnData,
  loading,
  filterBy,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (filterBy === "all") {
      setMetricData(txnData);
    } else {
      setMetricData(txnData.filter((x: any) => x.status === filterBy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnData, filterBy]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);

    const totalCount: any = [];
    const totalValue: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(
        rawData,
        "created_at_date",
        el
      );

      let count: any = getCountByKey(filteredDataByLabel, "order_volume");
      totalCount.push(Number(count));
      let value: any = getCountByKey(filteredDataByLabel, "order_value");
      totalValue.push(Number(value).toFixed(2));
    });

    const datasets = [
      {
        label: "Total Count",
        type: "line",
        data: totalCount,
        borderColor: "#0058FF",
        backgroundColor: "#0058FF",
        yAxisID: "y1",
        order: 1,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        pointStyle: false,
        pointRadius: 0,
      },
      {
        label: "Total Amount",
        type: "bar",
        data: totalValue,
        borderColor: "#8AB2FF",
        backgroundColor: "#8AB2FF",
        yAxisID: "y",
        order: 2,
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <Box
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
          }}
        >
          <Box
            pb={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ fontSize: "12px !important" }}>
              Total Count:&nbsp;
              {amountConverter(
                metricData?.reduce(
                  (acc: number, curr: any) => acc + Number(curr.order_volume),
                  0
                )
              )}
              &emsp;&nbsp; Total Value: &#8377;&nbsp;
              {amountConverter(
                metricData?.reduce(
                  (acc: number, curr: any) => acc + Number(curr.order_value),
                  0
                )
              )}
            </Typography>
            <IconButton
              aria-label="redirect"
              onClick={() => navigate("/digital-payment/transactions")}
              color="primary"
            >
              <OpenInNewIcon color="primary" fontSize="small" />
            </IconButton>
          </Box>
          {genData && (
            <LineChartMulti
              title={graphTitle}
              data={genData}
              additionalOptions={{
                leftSize: 400000,
                rightSize: 500,
                leftLabel: "Amount",
                rightLabel: "Count",
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DayWiseMetrics;
