import {
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { valueLimits } from "../../constants";
import { validateIntNum } from "../../utils/common";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

const DunzoMedicinesTable = ({ orderItems, setOrderItems }: any) => {
  const deleteItem = (index: number) => {
    let allItems = [...orderItems];
    allItems.splice(index, 1);
    setOrderItems([...allItems]);
  };

  const updateItems = (oldIndex: number, item: any) => {
    orderItems.splice(oldIndex, 1, item);
    const updatedData = [...orderItems];
    setOrderItems(updatedData);
  };

  const checkNaN = (value: any) => {
    if (isNaN(Number(value))) {
      return 0;
    } else {
      return Number(value);
    }
  };

  const allowNumbersOnly = (value: any) => {
    if (isNaN(Number(value))) {
      return value.slice(0, -1);
    } else {
      return value;
    }
  };

  const getPharmacyPayout = (value: any, key: any, medicine: any) => {
    let result = 0;

    if (key === "quantity") {
      const amount = checkNaN(value) * checkNaN(medicine.pharmacy_price);
      result = checkNaN(amount - amount * checkNaN(medicine.discount) * 0.01);
    } else if (key === "pharmacy_price") {
      const amount = checkNaN(value) * checkNaN(medicine.quantity);
      result = checkNaN(amount - amount * checkNaN(medicine.discount) * 0.01);
    } else if (key === "discount") {
      const amount =
        checkNaN(medicine.pharmacy_price) * checkNaN(medicine.quantity);
      result = checkNaN(amount - amount * checkNaN(value) * 0.01);
    }

    return result.toFixed(2);
  };

  const getDiscount = (value: any, medicine: any) => {
    const amount =
      checkNaN(medicine.quantity) * checkNaN(medicine.pharmacy_price);

    return checkNaN(100 - (value / amount) * 100).toFixed(2);
  };

  let subTotal = 0;

  orderItems.forEach((medicine: any) => {
    const amount = Number(medicine.pharmacy_price) * Number(medicine.quantity);
    const pharmacy_payout = amount - amount * Number(medicine.discount) * 0.01;
    subTotal += pharmacy_payout;
  });

  return (
    <>
      {orderItems?.map((medicine: any, index: number) => {
        let amount =
          checkNaN(medicine.quantity) * checkNaN(medicine.pharmacy_price);

        return (
          <Stack key={index} direction="row" mt={1}>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Medicine name*
                </Typography>
              )}
              <TextField
                fullWidth
                id="medicine_name"
                size="small"
                value={medicine?.item || ""}
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                onChange={(event) => {
                  const item = {
                    ...medicine,
                    item: event?.target?.value,
                  };
                  updateItems(index, item);
                }}
              />
            </Stack>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Quantity* (Max. {valueLimits.DUNZO_QTY})
                </Typography>
              )}
              <TextField
                fullWidth
                id="medicine_qty"
                size="small"
                value={medicine?.quantity || ""}
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                onChange={(event) => {
                  const item = {
                    ...medicine,
                    quantity: Number(
                      validateIntNum(
                        event?.target?.value,
                        valueLimits.DUNZO_QTY
                      )
                    ),
                    pharmacy_payout: getPharmacyPayout(
                      Number(event.target.value),
                      "quantity",
                      medicine
                    ),
                  };
                  updateItems(index, item);
                }}
              />
            </Stack>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Dunzo price per qty*
                </Typography>
              )}
              <TextField
                fullWidth
                id="medicine_dunzo_price"
                size="small"
                value={medicine?.dunzo_price || ""}
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                onChange={(event) => {
                  const item = {
                    ...medicine,
                    dunzo_price: allowNumbersOnly(event?.target?.value),
                  };
                  updateItems(index, item);
                }}
              />
            </Stack>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Pharmacy price per qty*
                </Typography>
              )}
              <TextField
                fullWidth
                id="medicine_pharmacy_price"
                size="small"
                value={medicine?.pharmacy_price || ""}
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                onChange={(event: any) => {
                  const item = {
                    ...medicine,
                    pharmacy_price: allowNumbersOnly(event.target.value),
                    pharmacy_payout: getPharmacyPayout(
                      allowNumbersOnly(event.target.value),
                      "pharmacy_price",
                      medicine
                    ),
                  };
                  updateItems(index, item);
                }}
              />
            </Stack>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Discount* (%)
                </Typography>
              )}
              <TextField
                fullWidth
                id="medicine_discount"
                size="small"
                value={medicine?.discount || ""}
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                onChange={(event: any) => {
                  const item = {
                    ...medicine,
                    discount: allowNumbersOnly(event.target.value),
                    pharmacy_payout: getPharmacyPayout(
                      allowNumbersOnly(event.target.value),
                      "discount",
                      medicine
                    ),
                  };
                  updateItems(index, item);
                }}
              />
            </Stack>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Pharmacy Payout*
                </Typography>
              )}
              <TextField
                fullWidth
                id="medicine_pharmacy_payout"
                size="small"
                value={medicine?.pharmacy_payout || ""}
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                onChange={(event) => {
                  if (amount >= allowNumbersOnly(event.target.value)) {
                    const item = {
                      ...medicine,
                      pharmacy_payout: allowNumbersOnly(event.target.value),
                      discount: getDiscount(
                        allowNumbersOnly(event.target.value),
                        medicine
                      ),
                    };
                    updateItems(index, item);
                  }
                }}
              />
            </Stack>
            <Stack alignItems="center" justifyContent="end" ml={0.5}>
              {index === 0 && (
                <Typography variant="body2" textAlign="center">
                  Actions
                </Typography>
              )}
              <Stack direction="row" mt={1.5}>
                <Tooltip title="Delete">
                  <span>
                    <IconButton onClick={() => deleteItem(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        );
      })}

      {subTotal > 0 && (
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "end",
            marginRight: 20,
            mt: 1.5,
          }}
        >
          Total Pharmacy Payout: {subTotal.toFixed(2)}
        </Typography>
      )}
    </>
  );
};

export default DunzoMedicinesTable;
