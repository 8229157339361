import {
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import config from "../../../config";
import { commonTableOptions } from "../../../constants";
import { instance } from "../../../utils/api";
import { handleError } from "../../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import EditIgmModal from "./editIgmModal";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import useUserInfo from "../../../hooks/useUserInfo";
import { useLocation } from "react-router-dom";
import IssueActionModal from "./issueActionModal";
import VisibilityIcon from "@mui/icons-material/Visibility";

const OndcIgm = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showEditIgmModal, setShowEditIgmModal] = useState(false);
  const [showIssueActionModal, setShowIssueActionModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const user: AuthenticatedUser = useUserInfo();
  const location = useLocation();

  const ondcIgmColumns = [
    {
      name: "sr_no",
      label: "Sr. No.",
      options: {
        filter: false,
        customBodyRender: (value: number) => {
          return (
            <Typography minWidth={50} variant="body2">
              {value}.
            </Typography>
          );
        },
      },
    },
    {
      name: "issue_id",
      label: "Issue Id",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={220} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "complainant_info",
      label: "Complainant",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Stack sx={{ minWidth: "200px" }}>
              <Typography pr={0.5} variant="body2">
                {value.person?.name}
              </Typography>
              {value.contact?.phone ? (
                <Typography sx={{ fontSize: "12px !important" }}>
                  {value.contact.phone}
                </Typography>
              ) : null}
              {value.contact?.email ? (
                <Typography sx={{ fontSize: "12px !important" }}>
                  {value.contact.email}
                </Typography>
              ) : null}
            </Stack>
          );
        },
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "sub_category",
      label: "Sub-Category",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any, rowIndex: any) => {
          return (
            <Stack sx={{ minWidth: "100px" }}>
              <Typography variant="body2">{value}</Typography>
              <Typography sx={{ fontSize: "12px !important" }}>
                {data[tableMeta?.rowIndex]?.sub_category_description}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "issue_type",
      label: "Issue Type",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "Order ID",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={200} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "source",
      label: "Issue Source",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Stack sx={{ minWidth: "200px" }}>
              <Typography pr={0.5} variant="body2">
                {value.type}
              </Typography>
              <Typography
                sx={{ wordBreak: "break-all", fontSize: "12px !important" }}
              >
                {value.network_participant_id}
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={200} variant="body2">
              {value.short_desc}
            </Typography>
          );
        },
      },
    },
    {
      name: "resolved_by_medpay",
      label: "Resolved By MedPay",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: "resolution",
      label: "Resolution",
      options: {
        display: data.some((x: any) => x.hasOwnProperty("resolution"))
          ? true
          : false,
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value ? (
                <Stack sx={{ minWidth: "180px" }}>
                  <Typography pr={0.5} variant="body2">
                    {value.action_triggered}
                  </Typography>
                  <Typography
                    sx={{ wordBreak: "break-all", fontSize: "12px !important" }}
                  >
                    {value.refund_amount ? `${value.refund_amount} | ` : ""}{" "}
                    {value.short_desc}
                  </Typography>
                </Stack>
              ) : (
                <Typography>--</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2">
              {new Date(value)?.toLocaleString()}
            </Typography>
          );
        },
      },
    },
    {
      name: "updated_at",
      label: "Updated At",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2">
              {new Date(value)?.toLocaleString()}
            </Typography>
          );
        },
      },
    },
    {
      name: "expected_resolution_time",
      label: "Expected Resolution Time",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return <Typography variant="body2">{value.duration}</Typography>;
        },
      },
    },
    {
      name: "issue_actions",
      label: "ISSUE ACTIONS",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Tooltip title="View Actions">
                <IconButton
                  disabled={value?.length === 0}
                  color="primary"
                  aria-label="open"
                  onClick={() => {
                    setShowIssueActionModal(true);
                    setSelectedRowData(data[tableMeta.rowIndex]);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "ACTION",
      options: {
        searchable: false,
        filter: false,
        display: user?.partner?.toLowerCase() !== "ondc" ? true : false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
              <Tooltip title="Update">
                <IconButton
                  disabled={
                    data[tableMeta?.rowIndex]?.status?.toLowerCase() ===
                    "closed"
                  }
                  color="primary"
                  aria-label="open"
                  onClick={() => {
                    setShowEditIgmModal(true);
                    setSelectedRowData(data[tableMeta.rowIndex]);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Issue ID/Order ID",
  };

  const getOndcIssues = async () => {
    const params: any = new URLSearchParams(location.search);
    setLoading(true);
    try {
      const result = await instance.get(
        `${config.ONDC_API_URL}ecommerce/manage/issues`
      );
      if (result.status === 200) {
        let allIsues = result.data?.issues || [];
        allIsues.forEach((element: any, index: number) => {
          element.sr_no = index + 1;
        });
        if (params.get("txnId")) {
          setData(
            allIsues.filter((x: any) => x.issue_id === params.get("txnId"))
          );
        } else {
          setData(allIsues);
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOndcIssues();

    return () => {
      setSelectedRowData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ErrorBoundary>
        <MUIDataTable
          title=""
          data={data}
          columns={ondcIgmColumns}
          options={options}
        />
        {showEditIgmModal && (
          <EditIgmModal
            open={showEditIgmModal}
            onClose={() => setShowEditIgmModal(false)}
            selectedRowData={selectedRowData}
            getOndcIssues={getOndcIssues}
          />
        )}
        {showIssueActionModal && (
          <IssueActionModal
            open={showIssueActionModal}
            onClose={() => setShowIssueActionModal(false)}
            selectedRowData={selectedRowData}
            setShowEditIgmModal={setShowEditIgmModal}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default OndcIgm;
