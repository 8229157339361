import { ImageList, Stack, Typography } from "@mui/material";
import React from "react";
import MedicineCard from "./medicineCard";

const PreviouslyBought = ({ apiData, setValue }: any) => {
  const lsPurchaseData = localStorage.getItem("purchase_data")
    ? JSON.parse(String(localStorage.getItem("purchase_data")))
    : [];

  return (
    <>
      {lsPurchaseData?.length > 0 ? (
        <Stack p={2}>
          <Typography pl={1} variant="h4">
            Previously bought
          </Typography>
          <ImageList
            className="hideScrollBar"
            sx={{
              gridAutoFlow: "column",
              gridTemplateColumns:
                "repeat(auto-fit, minmax(60%,1fr)) !important",
              gridAutoColumns: "minmax(60%, 1fr)",
              gap: "16px !important",
              p: 1,
              m: 0,
            }}
          >
            {apiData
              .filter((x: any) => lsPurchaseData.includes(x.id))
              .map((medicine: any) => (
                <MedicineCard
                  key={medicine.id}
                  medicine={medicine}
                  setValue={setValue}
                />
              ))}
          </ImageList>
        </Stack>
      ) : null}
    </>
  );
};

export default PreviouslyBought;
