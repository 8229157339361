import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { createLedgerTxnSchema } from "../../schemas/ledger.schema";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
} from "../../utils/common";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { format } from "date-fns";

const AddTransactionModal = ({
  showAddTxnModal,
  setShowAddTxnModal,
  updateFn,
  selectedPartner,
}: any) => {
  const [openTxnDate, setOpenTxnDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      partner_id: selectedPartner,
      transaction_date: format(values?.txn_date, "dd/MM/yyyy"),
      transaction_type: values?.txn_type,
      reference_id: values?.reference_id,
      description: values?.description,
      amount: Number(values?.amount),
    };

    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V2}/ledger/transaction`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Transaction added successfully");
        updateFn();
        actions.resetForm({});
        setShowAddTxnModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showAddTxnModal}
      onClose={() => setShowAddTxnModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowAddTxnModal(false)}
      >
        {`Add Transaction | ${selectedPartner}`}
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        <Formik
          initialValues={{
            txn_date: null,
            txn_type: "",
            reference_id: "",
            description: "",
            amount: "",
          }}
          validationSchema={createLedgerTxnSchema}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
        >
          {(props) => {
            const {
              errors,
              touched,
              values,
              handleBlur,
              setFieldValue,
              isSubmitting,
            } = props;
            return (
              <Form>
                <Box mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      open={openTxnDate}
                      onOpen={() => setOpenTxnDate(true)}
                      onClose={() => setOpenTxnDate(false)}
                      label="Txn Date"
                      inputFormat="dd/MM/yyyy"
                      value={values?.txn_date}
                      onChange={(newValue: Date | null) => {
                        setFieldValue("txn_date", newValue);
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          sx={{ mt: 1.5 }}
                          name="txn_date"
                          helperText={
                            errors.txn_date && touched.txn_date
                              ? errors.txn_date
                              : ""
                          }
                          error={
                            errors.txn_date && touched.txn_date ? true : false
                          }
                          onBlur={handleBlur}
                          InputLabelProps={{
                            required: true,
                          }}
                          autoComplete="off"
                          onKeyDown={(e: any) => {
                            e.preventDefault();
                          }}
                          onClick={(e) => setOpenTxnDate(true)}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    error={errors.txn_type && touched.txn_type ? true : false}
                  >
                    <InputLabel id="txn-type-label">Txn Type</InputLabel>
                    <Select
                      labelId="txn-type-label"
                      name="txn_type"
                      id="txn_type"
                      value={values?.txn_type}
                      label="Txn Type"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("txn_type", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="CREDIT">CREDIT</MenuItem>
                    </Select>
                    {errors.txn_type && touched.txn_type && (
                      <FormHelperText>{errors.txn_type}</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    fullWidth
                    id="reference_id"
                    label="Reference ID"
                    placeholder="UTR Number"
                    size="small"
                    value={values.reference_id}
                    onChange={(e) =>
                      setFieldValue("reference_id", e.target.value)
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.reference_id && touched.reference_id
                        ? errors.reference_id
                        : ""
                    }
                    error={
                      errors.reference_id && touched.reference_id ? true : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5 }}
                  />
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    error={
                      errors.description && touched.description ? true : false
                    }
                  >
                    <InputLabel id="description-label">Description</InputLabel>
                    <Select
                      labelId="description-label"
                      name="description"
                      id="description"
                      value={values?.description}
                      label="Description"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("description", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                      <MenuItem value="Carry Forward from Last Month">
                        Carry Forward from Last Month
                      </MenuItem>
                    </Select>
                    {errors.description && touched.description && (
                      <FormHelperText>{errors.description}</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    fullWidth
                    id="amount"
                    label="Amount"
                    size="small"
                    value={values.amount}
                    onChange={(e) =>
                      setFieldValue(
                        "amount",
                        validateFloatNum(e.target.value, 999999)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.amount && touched.amount ? errors.amount : ""
                    }
                    error={errors.amount && touched.amount ? true : false}
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5 }}
                  />
                  <Box textAlign="center">
                    <LoadingButton
                      sx={{ mt: 3 }}
                      size="medium"
                      color="primary"
                      loading={loading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddTransactionModal;
