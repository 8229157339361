import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Fab,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../utils/common";
import UploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const allowedFiles = ["image/png", "image/jpg", "image/jpeg"];
const allowedGstFiles = [...allowedFiles, "application/pdf"];

export const CertificateView = ({
  activeLocationId,
  url,
  title,
  uploadType,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const deleteFile = (index: number) => {
    let allFiles = [...uploadedFiles];
    allFiles.splice(index, 1);
    setUploadedFiles([...allFiles]);
  };

  const handleFileChange = (e: any) => {
    handleFileUpload(e.target.files);
  };

  const handleFileUpload = async (files: any) => {
    let fileUrls = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (uploadType === "store_front") {
        if (!allowedFiles.includes(file.type)) {
          errorToast("only png/jpg/jpeg allowed");
          return;
        }
      }
      if (uploadType === "gst_certificate") {
        if (!allowedGstFiles.includes(file.type)) {
          errorToast("only pdf/png/jpg/jpeg allowed");
          return;
        }
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type", uploadType);
      setLoading(true);
      try {
        const result = await privateInstance.post(
          `${API_VERSION.V1}/manage/locations/${activeLocationId}/certificates`,
          formData
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          fileUrls.push(
            uploadType === "store_front"
              ? result.data.response
              : result.data.response?.url
          );
          setUploadedFiles([...uploadedFiles, ...fileUrls]);
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box>
      <Typography py={1} variant="h5">
        {title}
      </Typography>
      <Stack pb={2} direction="row" justifyContent="space-between">
        {url && url.length !== 0 ? (
          <img
            src={url}
            alt={title}
            style={{
              height: "100px",
              width: "100px",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(url);
            }}
            onError={(e: any) => {
              e.target.src =
                "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
            }}
          />
        ) : (
          <span>--NA--</span>
        )}
        <Box>
          {!loading ? (
            <>
              {uploadedFiles.length === 0 ? (
                <>
                  <label htmlFor={`upload-file-${uploadType}`}>
                    <input
                      style={{ display: "none" }}
                      id={`upload-file-${uploadType}`}
                      name={`upload-file-${uploadType}`}
                      type="file"
                      onChange={handleFileChange}
                      // multiple
                    />
                    <Fab
                      color="primary"
                      size="small"
                      component="span"
                      aria-label="add"
                      variant="extended"
                      sx={{ mt: 1.5 }}
                    >
                      <UploadIcon sx={{ mr: 1 }} /> Upload File
                    </Fab>
                  </label>
                </>
              ) : null}
            </>
          ) : (
            <CircularProgress size={24} sx={{ mt: 1.5 }} />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {uploadedFiles?.length > 0 &&
              uploadedFiles.map((x: string, i: number) => {
                return (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      mr: 1.5,
                    }}
                  >
                    <CardMedia
                      id={`${uploadType}_${i}`}
                      data-url={x}
                      sx={{
                        height: 100,
                        width: 100,
                        maxHeight: 200,
                        maxWidth: 200,
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      alt="file"
                      onClick={() => (x ? window.open(x) : null)}
                      src={x}
                      component="img"
                      onError={(e: any) => {
                        e.target.src =
                          "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                      }}
                    />
                    <Button
                      color="error"
                      variant="contained"
                      size="small"
                      startIcon={<DeleteIcon />}
                      sx={{ mt: 0.5 }}
                      onClick={() => deleteFile(i)}
                    >
                      Change
                    </Button>
                  </Box>
                );
              })}
          </Box>
        </Box>
      </Stack>
      <Divider />
    </Box>
  );
};
