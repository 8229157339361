import { Stack, Typography } from "@mui/material";
import { FilterType } from "mui-datatables";
import React from "react";
import { parseAndFormateDate } from "../../utils/common";
import EditorsAutocomplete from "../Common/editorsAC";
import { staticReassignCols } from "./assignTableSettings";
import AssignView from "./assignView";

const ReassignView = ({ ordersToAssign, loading, getOrders }: any) => {
  const [currentEditor, setCurrentEditor] = React.useState("");
  const [filteredByEditor, setFilteredByEditor] = React.useState<any>([]);

  React.useEffect(() => {
    setFilteredByEditor(
      ordersToAssign.filter((x: any) => x.assigned_to === currentEditor)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEditor, ordersToAssign]);

  const reassignColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filterType: "textField" as FilterType,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack>
              <Typography variant="body2">{!value ? "" : value}</Typography>
              <Typography variant="body2" color="#718096">
                {parseAndFormateDate(
                  ordersToAssign[tableMeta.rowIndex]?.created_at
                )}
              </Typography>
            </Stack>
          );
        },
      },
    },
    ...staticReassignCols,
  ];

  return (
    <>
      <EditorsAutocomplete
        editor={currentEditor}
        setEditor={setCurrentEditor}
      />
      {currentEditor && (
        <AssignView
          ordersToAssign={filteredByEditor}
          loading={loading}
          columns={reassignColumns}
          getOrders={getOrders}
        />
      )}
    </>
  );
};

export default ReassignView;
