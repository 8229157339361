import { Stack, Typography } from "@mui/material";
import { FilterType } from "mui-datatables";

interface IPropAssignTable {
  name: string;
  address_details: {
    city: string;
    pin_code: string;
  };
  payment_status: string;
}

export const staticAssignCols = [
  {
    name: "order_partner",
    label: "ORDER PARTNER",
    options: {
      searchable: false,
    },
  },
  {
    name: "order_status",
    label: "ORDER STATUS",
    options: {
      filter: false,
      searchable: false,
    },
  },
  {
    name: "customer_details.address_details.city",
    label: "CITY",
    options: {
      filter: true,
      display: false,
      filterType: "textField" as FilterType,
      searchable: false,
    },
  },
  {
    name: "customer_details.name",
    label: "CUSTOMER NAME",
    options: {
      filterType: "textField" as FilterType,
    },
  },
  {
    name: "customer_details",
    label: "CITY",
    options: {
      filter: false,
      searchable: false,
      customBodyRender: (value: IPropAssignTable) => {
        return (
          <Stack>
            <Typography variant="body2">
              {value?.address_details?.city}
            </Typography>
            <Typography variant="body2" color="#718096">
              {value?.address_details?.pin_code}
            </Typography>
          </Stack>
        );
      },
    },
  },
  {
    name: "created_by",
    label: "OPERATIONS",
    options: {
      filter: false,
      searchable: false,
      customBodyRender: (value: string) => (
        <Typography variant="body2">Created by: {value}</Typography>
      ),
    },
  },
];

export const staticReassignCols = [
  {
    name: "customer_details.name",
    label: "CUSTOMER NAME",
    options: {
      filterType: "textField" as FilterType,
    },
  },
  {
    name: "ivr_call_status",
    label: "IVR",
    options: {
      filter: false,
      searchable: false,
      customBodyRender: (value: string) => (
        <Typography variant="body2">{!value ? "NA" : value}</Typography>
      ),
    },
  },
  {
    name: "order_partner",
    label: "ORDER PARTNER",
    options: {
      filter: false,
      searchable: false,
    },
  },
  {
    name: "order_status",
    label: "ORDER STATUS",
    options: {
      filter: false,
      searchable: false,
    },
  },
  {
    name: "invoice_details",
    label: "PAYMENT STATUS",
    options: {
      filter: false,
      searchable: false,
      customBodyRender: (value: IPropAssignTable) => (
        <Stack>
          <Typography variant="body2">
            {value == null ? "pending" : value?.payment_status}
          </Typography>
          <Typography variant="body2" color="#718096">
            {value == null ? "invoice not yet generated" : ""}
          </Typography>
        </Stack>
      ),
    },
  },
  {
    name: "customer_details.address_details.city",
    label: "CITY",
    options: {
      filter: true,
      display: false,
      filterType: "textField" as FilterType,
      searchable: false,
    },
  },
  {
    name: "customer_details",
    label: "CITY",
    options: {
      filter: false,
      searchable: false,
      customBodyRender: (value: IPropAssignTable) => {
        return (
          <Stack>
            <Typography variant="body2">
              {value?.address_details?.city}
            </Typography>
            <Typography variant="body2" color="#718096">
              {value?.address_details?.pin_code}
            </Typography>
          </Stack>
        );
      },
    },
  },
];
