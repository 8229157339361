import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PaymentStatusModal from "../../../components/ManageOrder/GroupOrders/Payment/PaymentStatus/paymentStatusModal";
import NotesModal from "../../../components/ManageOrder/notesModal";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import RupeeIcon from "@mui/icons-material/CurrencyRupee";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LogisticsIcon from "@mui/icons-material/ElectricRickshaw";
import LogisticsDetailModal from "../../Logistics/logisticsDetailModal";

let actions = [
  { icon: <SpeakerNotesIcon />, name: "Notes", click_type: "notes" },
  {
    icon: <RupeeIcon />,
    name: "Payment Detail",
    click_type: "payment_detail",
  },
  {
    icon: <LogisticsIcon />,
    name: "Logistics Detail",
    click_type: "logistics",
  },
];

export default function OrderSpeedDialActions({
  orderData,
  setOrderData,
}: any) {
  const [open, setOpen] = React.useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = React.useState(false);
  const [notesModal, setNotesModal] = React.useState(false);
  const [showLogisticsDetailModal, setShowLogisticsDetailModal] =
    React.useState(false);

  const handleClose = (clickType: string) => {
    setOpen(false);
    if (clickType === "payment_detail") {
      setPaymentStatusModal(true);
    }
    if (clickType === "notes") {
      setNotesModal(true);
    }
    if (clickType === "logistics") {
      setShowLogisticsDetailModal(true);
    }
  };

  React.useEffect(() => {
    if (!notesModal || !paymentStatusModal || !showLogisticsDetailModal) {
      setOpen(false);
    }
  }, [notesModal, paymentStatusModal, showLogisticsDetailModal]);

  const isLogisticsDataPresent = orderData?.logistics;

  return (
    <Box>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: "fixed", bottom: 25, right: 25 }}
        icon={<MoreHorizIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
      >
        {actions
          .filter((x) =>
            isLogisticsDataPresent ? x : x.click_type !== "logistics"
          )
          .map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => handleClose(action.click_type)}
            />
          ))}
        {paymentStatusModal && (
          <PaymentStatusModal
            paymentStatusModal={paymentStatusModal}
            setPaymentStatusModal={setPaymentStatusModal}
            orderData={orderData}
            setOrderData={setOrderData}
            activePartner={orderData?.order_partner}
            readOnly={true}
          />
        )}
        {notesModal && (
          <NotesModal
            notesModal={notesModal}
            setNotesModal={setNotesModal}
            orderId={orderData?.order_id}
            readOnly={true}
          />
        )}
        {showLogisticsDetailModal && (
          <LogisticsDetailModal
            activePartner={orderData?.order_partner}
            activePartnerOrderId={orderData?.partner_order_id}
            showLogisticsDetailModal={showLogisticsDetailModal}
            setShowLogisticsDetailModal={setShowLogisticsDetailModal}
          />
        )}
      </SpeedDial>
    </Box>
  );
}
