import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  FormHelperText,
} from "@mui/material";
import { validateIntNum } from "../../utils/common";
import { genders, valueLimits } from "../../constants";
import { ORDER_TYPE } from "../../enums/order.enum";

const PatientDetail = ({
  createOrderType,
  setFieldValue,
  errors,
  touched,
  handleBlur,
  editCustomerDetail = false,
  formData,
}: any) => {
  const { full_name, age, gender, mobile, alt_mobile, email } = formData;
  return (
    <>
      {createOrderType !== ORDER_TYPE.MEDICINES && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <TextField
            fullWidth
            id="full_name"
            label="Full Name"
            size="small"
            value={full_name || ""}
            onChange={(e) => setFieldValue("full_name", e.target.value)}
            onBlur={handleBlur}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            helperText={
              errors.full_name && touched.full_name ? errors.full_name : ""
            }
            error={errors.full_name && touched.full_name ? true : false}
            InputLabelProps={{
              required: true,
            }}
            inputProps={{
              maxLength: valueLimits.NAME_LEN,
            }}
          />
          <TextField
            fullWidth
            id="age"
            label="Age (in yrs)"
            size="small"
            value={age || ""}
            onChange={(e) =>
              setFieldValue(
                "age",
                validateIntNum(e.target.value, valueLimits.AGE)
              )
            }
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onBlur={handleBlur}
            helperText={errors.age && touched.age ? errors.age : ""}
            error={errors.age && touched.age ? true : false}
            InputLabelProps={{
              required: true,
            }}
          />
          <FormControl
            size="small"
            fullWidth
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            error={errors.gender && touched.gender ? true : false}
          >
            <InputLabel required id="gender-label">
              Gender
            </InputLabel>
            <Select
              labelId="gender-label"
              name="gender"
              id="gender"
              value={gender}
              label="gender"
              onChange={(e: SelectChangeEvent) => {
                setFieldValue("gender", e.target.value);
              }}
              onBlur={handleBlur}
            >
              {Object.entries(genders).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.gender && touched.gender && (
              <FormHelperText>{errors.gender}</FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {createOrderType === ORDER_TYPE.MEDICINES && (
          <TextField
            fullWidth
            id="full_name"
            label="Full Name"
            size="small"
            value={full_name || ""}
            onChange={(e) => setFieldValue("full_name", e.target.value)}
            onBlur={handleBlur}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            helperText={
              errors.full_name && touched.full_name ? errors.full_name : ""
            }
            error={errors.full_name && touched.full_name ? true : false}
            InputLabelProps={{
              required: true,
            }}
            inputProps={{
              maxLength: valueLimits.NAME_LEN,
            }}
          />
        )}
        {!editCustomerDetail && (
          <>
            <TextField
              fullWidth
              id="mobile"
              label="Mobile"
              size="small"
              value={mobile || ""}
              onChange={(e) =>
                setFieldValue(
                  "mobile",
                  validateIntNum(e.target.value, valueLimits.MOBILE)
                )
              }
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              onBlur={handleBlur}
              helperText={errors.mobile && touched.mobile ? errors.mobile : ""}
              error={errors.mobile && touched.mobile ? true : false}
              inputProps={{
                maxLength: 10,
              }}
              InputLabelProps={{
                required: true,
              }}
            />
            <TextField
              fullWidth
              id="alt_mobile"
              label="Alternate Mobile"
              size="small"
              value={alt_mobile || ""}
              onChange={(e) =>
                setFieldValue(
                  "alt_mobile",
                  validateIntNum(e.target.value, valueLimits.MOBILE)
                )
              }
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              onBlur={handleBlur}
              helperText={
                errors.alt_mobile && touched.alt_mobile ? errors.alt_mobile : ""
              }
              error={errors.alt_mobile && touched.alt_mobile ? true : false}
              inputProps={{
                maxLength: 10,
              }}
            />
            <TextField
              fullWidth
              id="email"
              label="Email"
              size="small"
              value={email || ""}
              onChange={(e) => setFieldValue("email", e.target.value)}
              sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
              onBlur={handleBlur}
              helperText={errors.email && touched.email ? errors.email : ""}
              error={errors.email && touched.email ? true : false}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default PatientDetail;
