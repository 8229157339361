import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
const currentDate = new Date();
const locale = { code: "enUS" };

export const dateRangeMenu = [
  {
    label: "Today",
    startDate: currentDate,
    endDate: currentDate,
  },
  {
    label: "Yesterday",
    startDate: addDays(currentDate, -1),
    endDate: addDays(currentDate, -1),
  },
  {
    label: "This Week",
    startDate: startOfWeek(currentDate, { locale }),
    endDate: endOfWeek(currentDate, { locale }),
  },
  {
    label: "Last Week",
    startDate: startOfWeek(addWeeks(currentDate, -1), { locale }),
    endDate: endOfWeek(addWeeks(currentDate, -1), { locale }),
  },
  {
    label: "Last 7 Days",
    startDate: addWeeks(currentDate, -1),
    endDate: currentDate,
  },
  {
    label: "This Month",
    startDate: startOfMonth(currentDate),
    endDate: endOfMonth(currentDate),
  },
  {
    label: "Last Month",
    startDate: startOfMonth(addMonths(currentDate, -1)),
    endDate: endOfMonth(addMonths(currentDate, -1)),
  },
];
