import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const IpdItemView = ({ invoiceDetails, patientPayable }: any) => {
  const cols = [
    "Item",
    "Quantity",
    "Bill Amount(Rs.)",
    "Patient Payable(Rs.)",
    "Discount(Rs.)",
    "Net Amount(Rs.)",
  ];
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {cols.map((col: string, index: number) => (
                <TableCell key={index} align="center" style={{ minWidth: 170 }}>
                  {col}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell align="center">
                Anywhere cashless appointment
              </TableCell>
              <TableCell align="center">1</TableCell>
              <TableCell align="center">
                {invoiceDetails?.bill_amount}
              </TableCell>
              <TableCell align="center">{patientPayable}</TableCell>
              <TableCell align="center">{invoiceDetails?.discount}</TableCell>
              <TableCell align="center">{invoiceDetails?.net_amount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default IpdItemView;
