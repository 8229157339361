import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import useUserInfo from "../../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const ActionRowView = ({ actionData, rowData }: any) => {
  const {
    complainant_action,
    respondent_action,
    short_desc,
    updated_at,
    // updated_by,
  } = actionData;
  return (
    <Box
      display="flex"
      justifyContent={respondent_action ? "flex-end" : "flex-start"}
      sx={{ borderBottom: "1px dashed #eeeeee", p: 1, width: "100%" }}
    >
      <Stack>
        <Typography
          sx={{
            color: "#718096",
            fontSize: "12px !important",
          }}
          textAlign={respondent_action ? "right" : "left"}
          variant="body2"
        >
          {new Date(updated_at)?.toLocaleString()}
        </Typography>
        <Typography
          textAlign={respondent_action ? "right" : "left"}
          variant="body2"
        >
          <DoneAllIcon
            sx={{ position: "relative", top: 0.2, fontSize: "14px !important" }}
          />{" "}
          {complainant_action || respondent_action || "--"}
        </Typography>
        <Typography
          textAlign={respondent_action ? "right" : "left"}
          sx={{ fontSize: "12px !important" }}
        >
          {short_desc}{" "}
          {rowData?.resolution?.refund_amount &&
          rowData?.resolution?.action_triggered?.toLowerCase() === "refund" &&
          respondent_action?.toLowerCase() === "resolved"
            ? `| Rs.${rowData?.resolution?.refund_amount}`
            : ""}
        </Typography>
      </Stack>
      {/* <Typography sx={{ fontSize: "12px !important" }}>
        {updated_by.person.name} |&nbsp; {updated_by.org.name}
      </Typography> */}
    </Box>
  );
};

const IssueActionModal = ({
  open,
  onClose,
  selectedRowData,
  setShowEditIgmModal,
}: any) => {
  const user: AuthenticatedUser = useUserInfo();
  const { issue_actions } = selectedRowData;
  const { complainant_actions, respondent_actions } = issue_actions;
  const allActions = sortBy(
    [...complainant_actions, ...respondent_actions],
    "updated_at"
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        Issue Description
      </CustomDialogTitle>
      <DialogContent>
        <Stack>
          <Box pl={1} pb={1}>
            <Typography variant="body2" pb={0.5}>
              <strong>Short Description:</strong>{" "}
              {selectedRowData?.description?.short_desc}
            </Typography>
            <Typography variant="body2" pb={0.5}>
              <strong>Long Description:</strong>{" "}
              {selectedRowData?.description?.long_desc}
            </Typography>
            <Box pt={0.5} display="flex">
              {selectedRowData?.description?.images?.map(
                (x: string, i: number) => {
                  return (
                    <img
                      style={{
                        borderRadius: "10px",
                        cursor: "pointer",
                        paddingLeft: "5px",
                      }}
                      width={100}
                      height={100}
                      key={i}
                      src={x}
                      alt="img"
                      onClick={() => window.open(x, "_blank")}
                    />
                  );
                }
              )}
            </Box>
          </Box>
          <Divider />

          <Box py={1} sx={{ position: "sticky", top: 0, background: "#fff" }}>
            <Typography pb={1} variant="h6">
              Issue Actions
            </Typography>

            <Typography pl={1} sx={{ float: "left" }} variant="h6">
              USER
            </Typography>
            <Typography pl={2} sx={{ float: "right" }} variant="h6">
              MEDPAY
            </Typography>
          </Box>
          <Box
            sx={{ maxHeight: "400px", overflow: "hidden", overflowY: "scroll" }}
          >
            {allActions?.map((x: any, i: number) => {
              return (
                <ActionRowView
                  actionData={x}
                  rowData={selectedRowData}
                  key={i}
                />
              );
            })}
          </Box>
        </Stack>

        {user?.partner?.toLowerCase() !== "ondc" ? (
          <Box textAlign="center" mt={3}>
            <Button
              disabled={selectedRowData?.status?.toLowerCase() === "closed"}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                onClose();
                setShowEditIgmModal(true);
              }}
            >
              Update Action
            </Button>
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default IssueActionModal;
