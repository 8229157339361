import { Box, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import TestsTable from "../../../../../pages/Diagnostics/Create/testsTable";
import DiscountPercent from "../../Common/discountPercent";

const DiagnosticInvoice = ({
  selectedTests,
  setSelectedTests,
  formData,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  testsTableError,
  setTestsTableError,
}: any) => {
  const { doctor_name, discount, final_payable, gst_no } = formData;
  const [updatedSubTotal, setUpdatedSubTotal] = useState(0);

  useEffect(() => {
    let subT: number = 0;
    selectedTests.forEach((element: any) => {
      subT += Number(element.MRP);
    });
    setUpdatedSubTotal(subT);
  }, [selectedTests]);

  return (
    <>
      <TextField
        fullWidth
        id="doctor_name"
        label="Doctor"
        size="small"
        value={doctor_name || ""}
        onChange={(e) => setFieldValue("doctor_name", e.target.value)}
        sx={{ mt: 2, mr: { xs: 0, md: 1, width: "99.2%" } }}
      />
      <TestsTable
        data={selectedTests}
        setSelectedTests={setSelectedTests}
        selectedTestsId={null}
        setSelectedTestsId={null}
        isEditing={true}
        testsTableError={testsTableError}
        setTestsTableError={setTestsTableError}
      />
      <Stack direction="row" mt={2}>
        <Box width="33%">
          <TextField
            fullWidth
            id="gst_no"
            label="GST NO"
            size="small"
            value={gst_no || ""}
            onChange={(e) => setFieldValue("gst_no", e.target.value)}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          />
        </Box>
        <Box width="67%" sx={{ ml: { xs: 0, md: 1 } }}>
          <DiscountPercent
            discount={discount}
            final_payable={final_payable}
            total={updatedSubTotal}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Stack>
    </>
  );
};

export default DiagnosticInvoice;
