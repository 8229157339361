import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import useRefresh from "../../hooks/useRefresh";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefresh();
  const user: AuthenticatedUser = useUserInfo();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        refresh();
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    !user?.access_token ? verifyRefreshToken() : setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.access_token]);

  //   useEffect(() => {
  //     console.log(`isLoading: ${isLoading}`);
  //     console.log(`aT: ${JSON.stringify(user?.access_token)}`);
  //   }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ color: "#0058ff" }} />
          <Typography component="div" variant="h6" sx={{ marginLeft: 2 }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
