import { Box, Button } from "@mui/material";
import React from "react";
import PaymentStatusModal from "./PaymentStatus/paymentStatusModal";
import ThirdPartyModal from "./PaymentStatus/thirdPartyModal";

const ThirdPartyPaymentButton = ({
  orderData,
  setOrderData,
  thirdPartyModal,
  setThirdPartyModal,
  setInvoicePayoutModal,
  paymentStatusModal,
  setPaymentStatusModal,
}: any) => {
  return (
    <>
      <Box textAlign="center" mt={3}>
        {!orderData?.third_party_payment_credit_status ||
        orderData?.third_party_payment_credit_status === "failed" ? (
          <Button
            variant="contained"
            color="success"
            sx={{ ml: 2 }}
            onClick={() => setThirdPartyModal(true)}
          >
            Want to pay to 3rd-party?
          </Button>
        ) : null}
        {(!orderData?.payment_credit_status ||
          orderData?.payment_credit_status?.toLowerCase() === "na") &&
        orderData?.third_party_payment_credit_status ? (
          <Button
            variant="outlined"
            color="success"
            sx={{ ml: 2 }}
            onClick={() => setPaymentStatusModal(true)}
          >
            3rd-party payment status
          </Button>
        ) : null}
      </Box>
      {thirdPartyModal && (
        <ThirdPartyModal
          thirdPartyModal={thirdPartyModal}
          setThirdPartyModal={setInvoicePayoutModal}
          orderData={orderData}
        />
      )}
      {paymentStatusModal && (
        <PaymentStatusModal
          paymentStatusModal={paymentStatusModal}
          setPaymentStatusModal={setPaymentStatusModal}
          orderData={orderData}
          setOrderData={setOrderData}
          activePartner={orderData?.order_partner}
          readOnly={true}
        />
      )}
    </>
  );
};

export default ThirdPartyPaymentButton;
