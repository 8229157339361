import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { errorToast, handleError, successToast } from "../../../utils/common";

const TimingRowComp = ({
  day,
  data,
  dayWiseData,
  setDayWiseData,
  itemIndex,
}: any) => {
  return (
    <Box my={3}>
      <h4
        style={{
          width: "100%",
          textAlign: "center",
          borderBottom: "1px solid #eeeeee",
          lineHeight: "0.1em",
          margin: "20px 0",
          textTransform: "uppercase",
        }}
      >
        <span
          style={{
            background: "#fff",
            padding: "0 10px",
          }}
        >
          {day}
        </span>
      </h4>
      {data?.map((time: any, i: number) => {
        return (
          <Box key={i} display="flex">
            <TextField
              type="time"
              fullWidth
              id="open_time"
              label="Open time"
              size="small"
              value={time?.from || ""}
              sx={{ mt: 1.5 }}
              InputLabelProps={{
                required: true,
                shrink: true,
              }}
              onChange={(e) => {
                let data = [...dayWiseData];
                data[itemIndex][day][i].from = e.target.value;
                setDayWiseData(data);
              }}
            />
            <TextField
              fullWidth
              type="time"
              id="close_time"
              label="Close time"
              size="small"
              value={time?.to || ""}
              sx={{ mt: 1.5, ml: 1.5 }}
              InputLabelProps={{
                required: true,
                shrink: true,
              }}
              onChange={(e) => {
                let data = [...dayWiseData];
                data[itemIndex][day][i].to = e.target.value;
                setDayWiseData(data);
              }}
            />
            <IconButton
              sx={{ mt: 1.5, ml: 0.5 }}
              onClick={() => {
                let data = [...dayWiseData];
                data[itemIndex][day].splice(i, 1);
                setDayWiseData(data);
              }}
              aria-label="delete"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      })}
      <Button
        onClick={() => {
          let exData = [...dayWiseData];
          exData[itemIndex][day].push({ from: "", to: "" });
          setDayWiseData(exData);
        }}
      >
        Add Hours
      </Button>
    </Box>
  );
};

const EditTimingModal = ({
  showEditTimingModal,
  setShowEditTimingModal,
  providerInfo,
  setProviderInfo,
}: any) => {
  const [twentyFourHour, setTwentyFourHour] = useState(
    providerInfo?.hours_of_operation["24hours_availability"] === "yes"
      ? true
      : false
  );
  const [dayWiseData, setDayWiseData] = useState(
    providerInfo?.hours_of_operation?.day_wise
  );
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async () => {
    let fieldErrors = false;

    dayWiseData.forEach((element: any) => {
      element[Object.keys(element)[0]].forEach((el: any) => {
        if (el.from === "" || el.to === "" || !el.from || !el.to) {
          fieldErrors = true;
          return;
        }
      });
    });

    if (fieldErrors) {
      alert("Please fill all the fields");
      return;
    }

    let payload = {
      location_id: providerInfo?.location_id,
      update_type: "store_timings",
      hours_of_operation: {
        "24hours_availability": twentyFourHour ? "yes" : "no",
        day_wise: dayWiseData,
      },
    };

    setLoading(true);

    try {
      const result = await privateInstance.put(
        `${API_VERSION.V1}/orders/dunzo/provider`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setDayWiseData(result.data.response?.hours_of_operation?.day_wise);
        setProviderInfo({
          ...providerInfo,
          hours_of_operation: result.data.response?.hours_of_operation,
        });
        successToast("Timings updated successfully");
        setShowEditTimingModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showEditTimingModal}
      onClose={() => setShowEditTimingModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowEditTimingModal(false)}
      >
        Edit Store Timings
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        <FormControlLabel
          sx={{ width: "100%", mt: 1.5, pl: 1.5 }}
          control={
            <Checkbox
              checked={twentyFourHour}
              onChange={(e) => {
                setTwentyFourHour(e.target.checked);
              }}
            />
          }
          label="Open 24 hours"
        />
        {dayWiseData?.map((x: any, i: number) => {
          return (
            <Box key={i}>
              <TimingRowComp
                day={Object.keys(x)[0]}
                data={x[Object.keys(x)[0]]}
                dayWiseData={dayWiseData}
                setDayWiseData={setDayWiseData}
                itemIndex={i}
              />
            </Box>
          );
        })}
        <Box textAlign="center" mt={3}>
          <LoadingButton
            size="medium"
            color="primary"
            loading={loading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            variant="contained"
            type="button"
            onClick={handleSubmit}
          >
            Update
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditTimingModal;
