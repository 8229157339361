import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { infoToast } from "../../utils/common";
import { format, parse } from "date-fns";

function getDateTime(d: string, t: string) {
  return format(
    parse(`${d} ${t}`, "yyyy-MM-dd HH:mm:ss", new Date()),
    "MMM dd, hh:mm aaaaa'm'"
  );
}

const UtrTxnModal = ({ open, onClose, data }: any) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          Transaction Details
        </CustomDialogTitle>
        <DialogContent>
          {typeof data === "string" ? (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography py={1} variant="body2">
                {data}
              </Typography>
              <CopyToClipboard
                text={data}
                onCopy={() => infoToast("Copied to clipboard")}
              >
                <Tooltip title="Copy Message">
                  <IconButton size="medium">
                    <ContentCopyIcon color="info" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          ) : (
            <>
              {data?.status === "success" ? (
                <Box
                  sx={{
                    backgroundColor: "#EDF7ED",
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                  }}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <Typography py={1} variant="body2">
                    Payment of Rs.{data?.amount} has already been received on
                    your QR with UTR: {data?.bank_reference} on{" "}
                    {getDateTime(data?.created_at_date, data?.created_at_time)}.
                    Same will be settled in your bank account in T+1 days.
                  </Typography>
                  <CopyToClipboard
                    text={`Payment of Rs.${
                      data?.amount
                    } has already been received on your QR with UTR: ${
                      data?.bank_reference
                    } on ${getDateTime(
                      data?.created_at_date,
                      data?.created_at_time
                    )}. Same will be settled in your bank account in T+1 days.`}
                    onCopy={() => infoToast("Copied to clipboard")}
                  >
                    <Tooltip title="Copy Message">
                      <IconButton size="medium">
                        <ContentCopyIcon color="info" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </CopyToClipboard>
                </Box>
              ) : (
                <Alert severity="error">
                  Payment of &#8377;{data?.amount} {data?.status}.
                </Alert>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UtrTxnModal;
