import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import { valueLimits } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  successToast,
  validateIntNum,
} from "../../../utils/common";
import EditIcon from "@mui/icons-material/Edit";

interface PinCodeDataProp {
  services: string[];
  serviceable: string;
  city?: string;
  state?: string;
  language?: string;
}

const PincodesComponent = () => {
  const [pincodeInput, setPincodeInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [pincodeData, setPincodeData] = useState<PinCodeDataProp | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const privateInstance = useAxiosInstance();

  const checkServiceability = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/admin-panel/pincode?pincode=${pincodeInput}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setIsEditing(false);
        setSelectedServices(result.data.response?.services);
        setPincodeData(result.data.response);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const updatePincodeData = async (pincode: string) => {
    let payload = {
      pincode: pincode,
      services: selectedServices,
    };

    setUpdateLoading(true);
    try {
      const result = await privateInstance.put(
        `${API_VERSION.V1}/admin-panel/pincode`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Updated Successfully");
        setPincodeData({
          ...pincodeData,
          services: selectedServices,
          serviceable: selectedServices.length > 0 ? "Y" : "N",
        });
        setIsEditing(false);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <div>
      <ErrorBoundary>
        <Box display="flex" justifyContent="center">
          <Card sx={{ p: 3, width: { xs: "100%", md: "50%" } }}>
            <Stack direction="row">
              <TextField
                fullWidth
                label="Pin Code"
                size="small"
                value={pincodeInput}
                onChange={(e) =>
                  setPincodeInput(
                    validateIntNum(e.target.value, valueLimits.PIN)
                  )
                }
                sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                InputLabelProps={{
                  required: true,
                }}
                inputProps={{
                  maxLength: 6,
                }}
              />
              <LoadingButton
                type="button"
                size="small"
                color="primary"
                variant="contained"
                loading={loading}
                disabled={!pincodeInput || pincodeInput.length !== 6}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                onClick={() => checkServiceability()}
                sx={{ minWidth: "150px", mt: 1.5 }}
              >
                Check
              </LoadingButton>
            </Stack>

            {pincodeData ? (
              <Stack pt={3}>
                <Divider />
                {isEditing ? (
                  <Box pt={2}>
                    <Autocomplete
                      filterSelectedOptions
                      multiple
                      fullWidth
                      size="small"
                      id="all_services"
                      options={["pharmacy", "diagnostics"]}
                      getOptionLabel={(option: any) => option}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Services *"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      value={selectedServices}
                      onChange={(e, val: any) => {
                        setSelectedServices(val);
                      }}
                    />
                    <Box mt={2} textAlign="center">
                      <LoadingButton
                        size="medium"
                        type="button"
                        color="primary"
                        loading={updateLoading}
                        loadingIndicator={
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        }
                        variant="contained"
                        onClick={() => updatePincodeData(pincodeInput)}
                      >
                        Update
                      </LoadingButton>
                      <Button
                        size="medium"
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={() => setIsEditing(false)}
                        sx={{ ml: 1.5 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box pt={2}>
                      {pincodeData.city ? (
                        <Typography>
                          <strong>City:</strong> &nbsp;
                          {pincodeData.city}
                        </Typography>
                      ) : null}
                      {pincodeData.state ? (
                        <Typography>
                          <strong>State:</strong> &nbsp;
                          {pincodeData.state}
                        </Typography>
                      ) : null}
                      {pincodeData.language ? (
                        <Typography>
                          <strong>Language:</strong> &nbsp;
                          {pincodeData.language}
                        </Typography>
                      ) : null}
                      {pincodeData.services?.length !== 0 && (
                        <Typography>
                          <strong>Services:</strong>{" "}
                          {pincodeData?.services?.join(", ")}
                        </Typography>
                      )}
                      <Typography>
                        <strong>Serviceable:</strong> &nbsp;
                        <span
                          style={{
                            color:
                              pincodeData.serviceable?.toLowerCase() === "y"
                                ? "green"
                                : "red",
                          }}
                        >
                          {pincodeData.serviceable?.toLowerCase() === "y"
                            ? "Yes"
                            : "No"}
                        </span>
                      </Typography>
                    </Box>
                    <Box pt={3} textAlign="center">
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<EditIcon />}
                        sx={{ ml: 1, mb: 1 }}
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            ) : null}
          </Card>
        </Box>
      </ErrorBoundary>
    </div>
  );
};

export default PincodesComponent;
