import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";

interface IPropCountdownTimer {
  timeRemaining: number;
  setDisableMailBtn: Dispatch<SetStateAction<boolean>>;
}

const CountdownTimer = ({
  timeRemaining,
  setDisableMailBtn,
}: IPropCountdownTimer) => {
  const Ref: any = useRef(null);
  const [timer, setTimer] = useState("00:00");

  const getTimeRemaining = (e: string) => {
    const total = Date.parse(e) - Date.parse(String(new Date()));
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: string) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(Ref.current);
      setDisableMailBtn(false);
    }
  };

  const clearTimer = (e: string | any) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + timeRemaining);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining]);

  return <span style={{ color: "#e74c3c" }}>&nbsp;({timer})</span>;
};

export default CountdownTimer;
