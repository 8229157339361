import * as yup from "yup";

export const editConsultationSchema = yup.object().shape({
  time_slot: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("DateTime is required"),
  doctor_name: yup.string().required("Doctor Name is required"),
  doctor_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Doctor Mobile is required"),
  doctor_fee: yup.string().required("Doctor Fee is required"),
  discount: yup.string().required("Discount percent is required"),
});

export const createConsultationSchema = yup.object().shape({
  provider_id: yup.string().nullable().required("Partner is required"),
  partner_order_id: yup
    .string()
    .matches(
      "^[a-zA-Z0-9_-]+$" as any,
      "Only alphanumeric, underscore(_) , hyphen(-) allowed"
    )
    .required("Partner Order ID is required"),
  payment_collection: yup.string().required("Payment Collection is required"),
  consultation_type: yup.string().required("Consultation Type is required"),
  full_name: yup.string().required("Full name is required"),
  age: yup.string().required("Age is required"),
  gender: yup.string().required("Gender is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
  alt_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000"),
  email: yup.string().email("Must be valid email"),
  address: yup.string().required("Address is required"),
  landmark: yup.string().required("Landmark is required"),
  pin: yup
    .string()
    .required("Pin Code is required")
    .test("len", "Pin Code must be 6 digit", (val) => val?.length === 6),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  speciality: yup.string().nullable().required("Speciality is required"),
  time_slot: yup
    .date()
    .nullable()
    .typeError("Invalid Date")
    .required("DateTime is required"),
});

export const createConsultationInvoiceSchema = yup.object().shape({
  doctor_charges: yup
    .number()
    .min(1, "Doctor fee should be greater than Rs 0")
    .max(9999, "Too Long!")
    .required("Doctor fees is required"),
  discount: yup
    .number()
    .required("Discout value is required")
    .min(0, "min 0 is allowed")
    .max(100, "max 100 is allowed"),
});
