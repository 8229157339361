import {
  CircularProgress,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  sortCashlessBills,
  successToast,
  textCapitalize,
} from "../../utils/common";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ViewHistoryIcon from "@mui/icons-material/SettingsBackupRestore";
import { AuthenticatedUser } from "../../interfaces/user.model";
import useUserInfo from "../../hooks/useUserInfo";
import CancelOrderModal from "../../components/Common/cancelOrderModal";
import BillHistoryModal from "./billHistoryModal";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../store/actions/useActions";
import { commonTableOptions } from "../../constants";
import { API_VERSION } from "../../enums/common.enum";
import { useIconStyles } from "../../components/Helpers/iconStyles";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";

const Bills = () => {
  const [loading, setLoading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const privateInstance = useAxiosInstance();
  const user: AuthenticatedUser = useUserInfo();
  const [activeTxnId, setActiveTxnId] = useState("");
  const [viewHistoryModal, setViewHistoryModal] = useState(false);
  const iconClasses = useIconStyles();

  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const getCashlessOpdBills = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/opd/payment/bills`
      );
      if (result?.data?.status === "success") {
        const allBills = result.data.response;
        let filteredBills = allBills.filter((x: any) => x.status !== "failed");
        dispatch(setAllOrderData(sortCashlessBills(filteredBills)));
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      dispatch(setAllOrderData([]));
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCashlessOpdBills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(setAllOrderData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cashlessBillCols = [
    {
      name: "transaction_id",
      label: "TRANSACTION DETAILS",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "product_type",
      label: "TRANSACTION DETAILS",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "transaction_bill_status",
      label: "BILL STATUS",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "bill_details.reject_reason",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "bill_details.created_at",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "payment_details.payment_modified_at",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "user_id",
      label: "EMPLOYEE DETAILS",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "employee_id",
      label: "EMPLOYEE ID",
      options: {
        filter: true,
        display: false,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "employee_name",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "corporate_name",
      label: "CORPORATE NAME",
      options: {
        filter: true,
        display: false,
        filterType: "textField" as FilterType,
        searchable: false,
      },
    },
    {
      name: "wallet_mobile_number",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "closing_balance",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "beneficiary_name",
      label: "PAYMENT DETAILS",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "provider_details.provider_type",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "status",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "payment_upi_mode",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "payment_details.bank_reference_number",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "payment_details.acknowledgment_number",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "provider_details.provider_account_name",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "payment_details.payment_amount",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "bill_details.bill_link",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "bill_details_history",
      label: "",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    ...commonTableOptions,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    customRowRender: (data: any) => {
      return <CustomRowComponent key={data[0]} rowData={data} />;
    },
    searchPlaceholder: "Employee ID/Transaction ID",
  };

  function CustomRowComponent({ rowData }: any) {
    const [
      transaction_id,
      product_type,
      bill_status,
      reject_reason,
      bill_created,
      payment_modified_at,
      user_id,
      employee_id,
      employee_name,
      corporate_name,
      wallet_mobile_number,
      closing_balance,
      beneficiary_name,
      provider_type,
      payment_status,
      payment_mode,
      bank_ref_number,
      ack_number,
      provider_acc_name,
      payment_amount,
      bill_link,
      bill_details_history,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell>
          <Stack>
            {transaction_id && (
              <Typography variant="body1">ID: {transaction_id}</Typography>
            )}
            {product_type && (
              <Typography variant="body2">
                {product_type?.split("_").join(" ").toUpperCase()}
              </Typography>
            )}
            {bill_status && (
              <Typography variant="body2" color="#4A5568">
                Bill Status: {textCapitalize(bill_status)}
              </Typography>
            )}
            {bill_status === "rejected" && (
              <Typography variant="body2" color="#4A5568">
                Reason: {textCapitalize(reject_reason)}
              </Typography>
            )}
            {payment_modified_at && (
              <Typography variant="body2" color="#4A5568">
                Transaction time: {parseAndFormateDate(payment_modified_at)}
              </Typography>
            )}
            {bill_created && (
              <Typography variant="body2" color="#4A5568">
                Bill upload time: {parseAndFormateDate(bill_created)}
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            {employee_id && (
              <Typography variant="body1">
                Employee ID: {employee_id}
              </Typography>
            )}
            {user_id && (
              <Typography variant="body1">User ID: {user_id}</Typography>
            )}
            {employee_name && (
              <Typography variant="body2" color="#4A5568">
                Name: {employee_name}
              </Typography>
            )}
            {corporate_name && (
              <Typography variant="body2" color="#4A5568">
                Corporate name: {corporate_name}
              </Typography>
            )}
            {wallet_mobile_number && (
              <Typography variant="body2" color="#4A5568">
                Wallet no.: {wallet_mobile_number}
              </Typography>
            )}
            {closing_balance && (
              <Typography variant="body2" color="#4A5568">
                Cloding bal. (Rs): {closing_balance}
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            {beneficiary_name && (
              <Typography variant="body1">
                Benf name: {beneficiary_name}
              </Typography>
            )}
            {provider_type && (
              <Typography variant="body2" color="#4A5568">
                Type: {provider_type}
              </Typography>
            )}
            {payment_status && (
              <Typography variant="body2" color="#4A5568">
                Payment status: {payment_status}
              </Typography>
            )}
            {payment_mode && (
              <Typography variant="body2" color="#4A5568">
                Payment mode: {payment_mode}
              </Typography>
            )}
            {bank_ref_number && (
              <Typography variant="body2" color="#4A5568">
                Bank ref no.: {bank_ref_number}
              </Typography>
            )}
            {ack_number && (
              <Typography variant="body2" color="#4A5568">
                Ack ref: {ack_number}
              </Typography>
            )}
            {provider_acc_name && (
              <Typography variant="body2" color="#4A5568">
                Account name: {provider_acc_name}
              </Typography>
            )}
            {payment_amount && (
              <Typography variant="body2" color="#4A5568">
                Amount: {payment_amount}
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" justifyContent="space-between">
            {bill_status === "pending" && (
              <Typography variant="body2" color="#4A5568">
                Bill not uploaded
              </Typography>
            )}
            {bill_status === "pending" ? null : (
              <Tooltip title="View Invoice">
                <Link sx={{ mt: 1 }} href={bill_link} target="_blank">
                  <VisibilityIcon
                    color="primary"
                    className={iconClasses.borderedIcon}
                  />
                </Link>
              </Tooltip>
            )}
            {bill_status === "pending" ||
            bill_status === "accepted" ||
            bill_status === "rejected" ? null : (
              <>
                <Tooltip title="Accept">
                  <IconButton onClick={() => handleAcceptBill(transaction_id)}>
                    <CheckIcon
                      color="success"
                      className={iconClasses.borderedIcon}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reject">
                  <IconButton
                    onClick={() => {
                      setActiveTxnId(transaction_id);
                      setCancelModal(true);
                    }}
                  >
                    <CancelIcon
                      color="error"
                      className={iconClasses.borderedIcon}
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {bill_status === "processing" && bill_details_history && (
              <Tooltip title="View History">
                <IconButton
                  onClick={() => {
                    setActiveTxnId(transaction_id);
                    setViewHistoryModal(true);
                  }}
                >
                  <ViewHistoryIcon
                    color="primary"
                    className={iconClasses.borderedIcon}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  const handleAcceptBill = async (transactionId: string) => {
    if (
      window.confirm("Are you sure, this will mark bill as accepted?") === true
    ) {
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/opd/payment/accept-bill/${transactionId}?modified_by=${user?.user_id}`
        );
        if (result?.data?.status === "success") {
          successToast("Bill accepted successfully!");
        } else {
          errorToast(result.data.message);
        }
      } catch (err) {
        handleError(err);
      }
    }
  };

  return (
    <ErrorBoundary>
      <MUIDataTable
        title=""
        data={ordersToManage}
        columns={cashlessBillCols}
        options={options}
      />
      {cancelModal && (
        <CancelOrderModal
          cancelModal={cancelModal}
          setCancelModal={setCancelModal}
          orderId={activeTxnId}
          orderType="opd"
        />
      )}
      {viewHistoryModal && (
        <BillHistoryModal
          viewHistoryModal={viewHistoryModal}
          setViewHistoryModal={setViewHistoryModal}
          orderData={
            ordersToManage?.filter(
              (x: any) => x.transaction_id === activeTxnId
            )[0]
          }
        />
      )}
    </ErrorBoundary>
  );
};

export default Bills;
