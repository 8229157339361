import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Stack,
  Divider,
  IconButton,
  Chip,
  Tooltip,
} from "@mui/material";
import { addDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { instance } from "../../../../utils/api";
import {
  amountConverter,
  getMuiTheme,
  handleError,
} from "../../../../utils/common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import MUIDataTable from "mui-datatables";
import { commonTableOptions } from "../../../../constants";
import PharmacyWiseTxnModal from "./pharmacyWiseTxnModal";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { ThemeProvider } from "@mui/styles";
import EmailReportModal from "./emailReportModal";
import CancelIcon from "@mui/icons-material/Cancel";
import { orderBy, sortBy } from "lodash";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EmailReportModalHeader from "./emailReportModalHeader";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import useUserInfo from "../../../../hooks/useUserInfo";
import config from "../../../../config";
import useUserPermissions from "../../../../hooks/useUserPermissions";

const DailyTransactions = () => {
  const [data, setData] = useState<any>([]);
  const [date, setDate] = useState<any>(new Date());
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [showTxnModal, setShowTxnModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showEmailModalHeader, setShowEmailModalHeader] = useState(false);
  const [filterBy, setFilterBy] = useState("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const user: AuthenticatedUser = useUserInfo();
  const userPermissions = useUserPermissions();
  const isEditAllowed = userPermissions
    ?.find((item: any) => item.permission_id === "digital_payment_transactions")
    ?.scope?.includes("edit");

  useEffect(() => {
    const getTxnData = async () => {
      let payload = {
        date: format(date, "yyyy-MM-dd"),
        metric_type: "daily_stats_summary",
      };
      setData([]); //reset table data on date change
      setLoading(true);
      try {
        const result = await instance.post(
          `${API_VERSION.V2}/analytics/digital-payments/overview`,
          payload
        );
        if (result.data?.status === API_RESULT.SUCCESS) {
          setData(result.data.response?.summary);
          setFilterBy("all");
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    getTxnData();
  }, [date]);

  useEffect(() => {
    if (filterBy === "low") {
      setFilteredData(
        sortBy(
          data.filter((x: any) => x.transaction_value >= 10),
          ["transaction_value", "transaction_count"]
        )
      );
    } else if (filterBy === "high") {
      setFilteredData(orderBy(data, "transaction_value", "desc")?.slice(0, 10));
    } else if (filterBy === "active") {
      setFilteredData(data.filter((x: any) => x.status === "active"));
    } else if (filterBy === "inactive") {
      setFilteredData(data.filter((x: any) => x.status === "inactive"));
    } else {
      setFilteredData(data);
    }
  }, [filterBy, data]);

  const cols = [
    {
      name: "location_id",
      label: "Location ID",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "terminal_id",
      label: "Terminal ID",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "provider_name",
      label: "Provider Name",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    // {
    //   name: "provider_id",
    //   label: "Provider ID",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value: any) => {
    //       return (
    //         <Typography minWidth={160} variant="body2">
    //           {value}
    //         </Typography>
    //       );
    //     },
    //   },
    // },
    {
      name: "terminal_phone_no",
      label: "Phone No",
      options: {
        sort: false,
        filter: false,
      },
    },

    {
      name: "transaction_count",
      label: "Txn Count",
      options: {
        searchable: false,
        filter: false,
      },
    },
    {
      name: "transaction_value",
      label: "Value(Rs.)",
      options: {
        searchable: false,
        filter: false,
        sort: filterBy === "low" || filterBy === "high" ? false : true,
      },
    },
    {
      name: "terminal_id",
      label: "All Transactions",
      options: {
        download: false,
        sort: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row" alignItems="center">
              <Stack
                onClick={() => {
                  setSelected(data?.find((x: any) => x.terminal_id === value));
                  setShowTxnModal(true);
                }}
                direction="row"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                }}
              >
                <DynamicFeedIcon color="primary" />
                <Typography color="primary" sx={{ pl: 0.5 }} variant="body2">
                  View
                </Typography>
              </Stack>
              {isEditAllowed ? (
                <IconButton
                  size="small"
                  sx={{ ml: 1.5 }}
                  color="primary"
                  onClick={() => {
                    setShowEmailModal(true);
                    setSelected(
                      data?.find((x: any) => x.terminal_id === value)
                    );
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="30px"
                    height="30px"
                  >
                    <title>Download Reports</title>
                    <path
                      fill="#169154"
                      d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"
                    />
                    <path
                      fill="#18482a"
                      d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"
                    />
                    <path
                      fill="#0c8045"
                      d="M14 15.003H29V24.005000000000003H14z"
                    />
                    <path fill="#17472a" d="M14 24.005H29V33.055H14z" />
                    <g>
                      <path
                        fill="#29c27f"
                        d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"
                      />
                      <path
                        fill="#27663f"
                        d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"
                      />
                      <path
                        fill="#19ac65"
                        d="M29 15.003H44V24.005000000000003H29z"
                      />
                      <path fill="#129652" d="M29 24.005H44V33.055H29z" />
                    </g>
                    <path
                      fill="#0c7238"
                      d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
                    />
                    <path
                      fill="#fff"
                      d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"
                    />
                  </svg>
                </IconButton>
              ) : null}
            </Stack>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: false,
        searchable: false,
        filter: true,
      },
    },
    {
      name: "location_id",
      label: "Settlements",
      options: {
        download: false,
        sort: false,
        filter: false,
        display: isEditAllowed,
        customBodyRender: (value: any) => {
          return (
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                window.open(
                  `/digital-payment/payouts?id=${value}&date=${format(
                    addDays(date, 1),
                    "dd/MM/yyyy"
                  )}`,
                  "_blank"
                )
              }
            >
              <OpenInNewIcon />
            </IconButton>
          );
        },
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <IconButton
        size="small"
        sx={{ ml: 1.5 }}
        color="primary"
        onClick={() => {
          setShowEmailModalHeader(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          width="30px"
          height="30px"
        >
          <title>Download Reports</title>
          <path
            fill="#169154"
            d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"
          />
          <path
            fill="#18482a"
            d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"
          />
          <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z" />
          <path fill="#17472a" d="M14 24.005H29V33.055H14z" />
          <g>
            <path
              fill="#29c27f"
              d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"
            />
            <path
              fill="#27663f"
              d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"
            />
            <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z" />
            <path fill="#129652" d="M29 24.005H44V33.055H29z" />
          </g>
          <path
            fill="#0c7238"
            d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
          />
          <path
            fill="#fff"
            d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"
          />
        </svg>
      </IconButton>
    );
  };

  const devEmailList = ["jesna.james@medpay.in", "tarun.yadav@medpay.in"];
  const prodEmailList = [
    "ravi@medpay.in",
    "arun@medpay.in",
    "anuj@medpay.in",
    "manoj.pradhan@medpay.in",
    "onboardings@medpay.in",
    "joys.saji@medpay.in",
    "jai.srivastava@medpay.in",
  ];
  const emailList =
    config.ENV === "DEV" ? [...devEmailList, ...prodEmailList] : prodEmailList;

  const options: any = {
    customToolbar: emailList.includes(String(user?.user_id))
      ? () => <CustomToolbar />
      : "",
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Location ID/Terminal ID/Name/Phone No",
    sort: true,
    rowsPerPageOptions: [10, 50, 100, 200],
    rowsPerPage: 10,
    download: isEditAllowed,
    downloadOptions: {
      filename: "DailyTxn_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={(newValue: Date | null) => {
                setDate(newValue);
              }}
              minDate={new Date("March 10, 2024")}
              maxDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{ mb: { xs: 1.5, md: 0 } }}
                  name="date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Stack
            mt={1.5}
            mb={{ xs: 1.5, md: 0 }}
            direction="row"
            justifyContent="end"
            flexWrap="wrap"
            gap={1.5}
          >
            {[
              {
                key: "active",
                label: "Active",
                title: "Min txn value > 10rs/day",
              },
              {
                key: "inactive",
                label: "Inactive",
                title: "Min txn value < 10rs/day",
              },
              {
                key: "high",
                label: "High Value",
                title: "",
              },
              { key: "low", label: "Low Value", title: "" },
            ].map(
              (
                item: { key: string; label: string; title: string },
                index: number
              ) => {
                return (
                  <Tooltip key={index} title={item.title}>
                    <Chip
                      label={item.label}
                      onClick={() => setFilterBy(item.key)}
                      onDelete={() => setFilterBy("all")}
                      deleteIcon={
                        filterBy === item.key ? (
                          <CancelIcon fontSize="small" />
                        ) : (
                          <p></p>
                        )
                      }
                      variant={filterBy === item.key ? "filled" : "outlined"}
                      disabled={data?.length === 0}
                      color="primary"
                    />
                  </Tooltip>
                );
              }
            )}
          </Stack>
        </Stack>
        {data && data.length > 0 ? (
          <>
            <Box
              width={{ xs: "100%", md: "30%" }}
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Box pb={1} display="flex" alignItems="center" gap={1}>
                <Typography>Total Transactions</Typography>
              </Box>
              <Divider />
              <Box pt={1}>
                <Typography
                  sx={{
                    fontSize: "12px !important",
                  }}
                >
                  Amount
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "28px !important" }}>
                  &#8377;&nbsp;
                  {amountConverter(
                    filteredData?.reduce(
                      (acc: number, curr: any) =>
                        acc + Number(curr.transaction_value),
                      0
                    )
                  )}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px !important",
                  }}
                >
                  Count:{" "}
                  <strong>
                    {filteredData?.reduce(
                      (acc: number, curr: any) =>
                        acc + Number(curr.transaction_count),
                      0
                    )}
                  </strong>
                </Typography>
              </Box>
            </Box>
            <Box
              width={{ xs: "100%", md: "30%" }}
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
                padding: "5px",
                borderRadius: "5px",
                mt: { xs: 1.5, md: 0 },
              }}
            >
              <Typography textAlign="center" pb={1}>
                Terminal Usage
              </Typography>
              <Divider />
              <Box pt={1} display="flex" justifyContent="space-between">
                <Box
                  textAlign="center"
                  width="50%"
                  sx={{
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "rgba(76, 175, 81, 1)",
                    }}
                    variant="h6"
                  >
                    Active
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "28px !important" }}>
                    {data.filter((x: any) => x.status === "active")?.length}
                  </Typography>
                </Box>
                <Box textAlign="center" width="50%">
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "rgba(255, 99, 132, 1)",
                    }}
                    variant="h6"
                  >
                    Inactive
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "28px !important" }}>
                    {data.filter((x: any) => x.status === "inactive")?.length}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "10px !important", color: "#4A5568" }}
                  >
                    ZERO Transaction
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ) : null}
      </Box>
      <Box mt={3}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            data={filteredData}
            columns={cols}
            options={options}
          />
        </ThemeProvider>
      </Box>
      {showTxnModal ? (
        <PharmacyWiseTxnModal
          date={date}
          selected={selected}
          showTxnModal={showTxnModal}
          setShowTxnModal={setShowTxnModal}
        />
      ) : null}
      {showEmailModal ? (
        <EmailReportModal
          date={date}
          selected={selected}
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
        />
      ) : null}
      {showEmailModalHeader ? (
        <EmailReportModalHeader
          date={date}
          email={user?.user_id}
          showEmailModalHeader={showEmailModalHeader}
          setShowEmailModalHeader={setShowEmailModalHeader}
        />
      ) : null}
    </div>
  );
};

export default DailyTransactions;
