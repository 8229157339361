import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import DunzoSubTotal from "./dunzoSubTotal";
import InfoIcon from "@mui/icons-material/Info";

const DunzoMedicinesView = ({ dunzoActiveOrder, showOrderDetail }: any) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  interface Column {
    id:
      | "item"
      | "quantity"
      | "dunzo_price"
      | "pharmacy_price"
      | "discount"
      | "pharmacy_payout";
    label: string;
    minWidth?: number;
    align?: "right" | "left" | "center";
    format?: (value: number) => string;
  }

  const columns: readonly Column[] = [
    { id: "item", label: "ITEM", minWidth: 170, align: "left" },
    { id: "quantity", label: "QUANTITY", minWidth: 100, align: "center" },
    {
      id: "dunzo_price",
      label: "DUNZO PRICE(Rs.)",
      minWidth: 170,
      align: "center",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "pharmacy_price",
      label: "PHARMACY PRICE(Rs.)",
      minWidth: 170,
      align: "center",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "discount",
      label: "DISCOUNT(%)",
      minWidth: 100,
      align: "center",
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "pharmacy_payout",
      label: "TOTAL(Rs.)",
      minWidth: 170,
      align: "right",
      format: (value: number) => value.toFixed(2),
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dunzoActiveOrder?.order_details?.items?.length > 0 &&
              dunzoActiveOrder?.order_details?.items
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                            {column.id === "pharmacy_price" && (
                              <>
                                {row["remarks"] && (
                                  <Tooltip title={row["remarks"]}>
                                    <InfoIcon
                                      color="info"
                                      sx={{
                                        verticalAlign: "middle",
                                        fontSize: 20,
                                        ml: 0.5,
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}

            {showOrderDetail && dunzoActiveOrder && (
              <DunzoSubTotal orderData={dunzoActiveOrder} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {dunzoActiveOrder?.order_details?.items?.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={dunzoActiveOrder?.order_details?.items?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default DunzoMedicinesView;
