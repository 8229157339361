/* eslint-disable no-unused-vars */
import React from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartMulti = ({ title, data, additionalOptions }: any) => {
  const innerW = window?.innerWidth;
  const graphOptions: any = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        display: innerW > 1024,
        grid: {
          display: false,
        },
      },
      y: {
        type: "linear" as const,
        display: true,
        position: "left" as const,
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value: number, index: number, ticks: any) {
            const labelText = additionalOptions.leftLabel;
            const leftAxisValue =
              value >= 100000
                ? value / 100000 + "L"
                : value >= 1000
                ? value / 1000 + "K"
                : Number(value).toFixed(2);
            return labelText?.toLowerCase()?.includes("amount") ||
              labelText?.toLowerCase()?.includes("value")
              ? `\u20b9` + leftAxisValue
              : leftAxisValue;
          },
          stepSize: additionalOptions?.leftSize,
        },
        title: {
          display: true,
          text: additionalOptions?.leftLabel,
          font: {
            size: 14,
          },
        },
      },
      y1: {
        type: "linear" as const,
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          callback: function (value: number, index: number, ticks: any) {
            const labelText = additionalOptions.rightLabel;
            const rightAxisValue =
              value >= 100000
                ? value / 100000 + "L"
                : value >= 1000
                ? value / 1000 + "K"
                : Number(value).toFixed(2);
            return labelText?.toLowerCase()?.includes("amount") ||
              labelText?.toLowerCase()?.includes("value")
              ? `\u20b9` + rightAxisValue
              : rightAxisValue;
          },
          stepSize: additionalOptions?.rightSize,
        },
        min: 0,
        title: {
          display: true,
          text: additionalOptions?.rightLabel,
          font: {
            size: 14,
          },
        },
      },
    },
  };

  return <Line options={graphOptions} data={data} />;
};

export default LineChartMulti;
