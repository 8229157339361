import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { instance } from "../../utils/api";
import { handleError, successToast } from "../../utils/common";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { activateUserSchema } from "../../schemas/user.schema";

const ActivateUser = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [initVal, setInitVal] = useState<any>({
    emailUsername: "",
    password: "",
    new_password: "",
  });

  useEffect(() => {
    const params: any = new URLSearchParams(location.search);
    const data = params.get("user_id");
    setInitVal({
      ...initVal,
      emailUsername: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Card
        sx={{
          width: { xs: "90%", md: "80%", lg: "35%" },
          m: 1,
          boxShadow: "0px 4px 46px rgba(0, 0, 0, 0.25)",
          padding: { xs: "20px", md: "0" },
        }}
      >
        <CardContent style={{ display: "flex", padding: 0 }}>
          <Box sx={{ width: "100%" }}>
            <Box textAlign="center">
              <Typography variant="h3" mt={3}>
                Reset your Password
              </Typography>
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={initVal}
              validationSchema={activateUserSchema}
              onSubmit={(values: any, actions) => {
                setLoading(true);
                actions.setSubmitting(false);
                let payload = {
                  user_id: values.emailUsername,
                  current_passcode: values.password,
                  new_passcode: values.new_password,
                };
                instance
                  .post(`${API_VERSION.V2}/auth/user/activate`, payload)
                  .then((response) => {
                    setLoading(false);
                    if (response.data.status === API_RESULT.SUCCESS) {
                      successToast("Activation successfull");
                      navigate("/login");
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    handleError(error);
                  });
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isSubmitting,
                } = props;
                return (
                  <Form>
                    <Grid
                      item
                      sx={{
                        width: { xs: "100%", md: "90%" },
                        marginLeft: { xs: "0", md: "5%" },
                        marginBottom: "15px",
                        marginTop: "30px",
                      }}
                    >
                      <TextField
                        //required
                        label="Email ID"
                        name="emailUsername"
                        id="emailUsername"
                        style={{ width: "100%" }}
                        variant="standard"
                        type="text"
                        value={values.emailUsername}
                        helperText={
                          errors.emailUsername && touched.emailUsername
                            ? errors.emailUsername
                            : ""
                        }
                        error={
                          errors.emailUsername && touched.emailUsername
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 320,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton aria-label="user account">
                                <AccountCircleIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        //required
                        label="Temporary Password"
                        name="password"
                        id="password"
                        style={{ width: "100%", marginTop: "30px" }}
                        variant="standard"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : ""
                        }
                        error={
                          errors.password && touched.password ? true : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="user password"
                                onClick={() => {
                                  setShowPassword(!showPassword);
                                }}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <TextField
                        //required
                        label="New Password"
                        name="new_password"
                        id="new_password"
                        style={{ width: "100%", marginTop: "30px" }}
                        variant="standard"
                        type={showNewPassword ? "text" : "password"}
                        value={values.new_password}
                        helperText={
                          errors.new_password && touched.new_password
                            ? errors.new_password
                            : ""
                        }
                        error={
                          errors.new_password && touched.new_password
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{
                          maxLength: 100,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="user password"
                                onClick={() => {
                                  setShowNewPassword(!showNewPassword);
                                }}
                              >
                                {showNewPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <Box sx={{ textAlign: "center", marginTop: "60px" }}>
                        <LoadingButton
                          sx={{ width: "180px" }}
                          size="medium"
                          color="primary"
                          loading={loading}
                          loadingIndicator={
                            <CircularProgress
                              size={20}
                              sx={{ color: "#fff" }}
                            />
                          }
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Activate
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ActivateUser;
