import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { handleError, parseAndFormateDate } from "../../utils/common";
import DunzoMedicinesView from "./dunzoMedicinesView";
import StoreVisitIcon from "@mui/icons-material/Storefront";
import HomeDeliveryIcon from "@mui/icons-material/DeliveryDining";

const DunzoOrderDetailModal = ({
  showOrderDetail,
  setShowOrderDetail,
  activePartnerOrderId,
  setActivePartnerOrderId,
}: any) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [dunzoActiveOrder, setDunzoActiveOrder] = useState<any>({});
  const privateInstance = useAxiosInstance();

  const getDunzoOrderById = async () => {
    setFetchingData(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/dunzo/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setDunzoActiveOrder(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setFetchingData(false);
    }
  };

  useEffect(() => {
    if (activePartnerOrderId) {
      getDunzoOrderById();
    }

    return () => {
      setActivePartnerOrderId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={showOrderDetail}
      onClose={() => setShowOrderDetail(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowOrderDetail(false)}
      >
        Medicine Order
      </CustomDialogTitle>
      <DialogContent>
        {fetchingData ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          <>
            <Stack direction="row">
              <Box width={{ xs: "100%", md: "50%" }}>
                <Typography variant="h4">Order Details</Typography>
                <Typography variant="body1">
                  Partner Order ID: {dunzoActiveOrder?.partner_order_id}
                </Typography>
                <Typography>
                  Partner: {dunzoActiveOrder?.order_partner}
                </Typography>
                {dunzoActiveOrder.delivery_partner === "medpay" ? (
                  <Stack direction="row" alignItems="center">
                    <Typography>Delivery Type:&nbsp;</Typography>
                    <Typography pr={0.5} variant="body1">
                      Home Delivery
                    </Typography>
                    <Tooltip title="Pharmacy will deliver the order">
                      <HomeDeliveryIcon fontSize="small" color="primary" />
                    </Tooltip>
                  </Stack>
                ) : null}
                {["self_pickup", "logistics", "dunzo"].includes(
                  dunzoActiveOrder.delivery_partner
                ) ? (
                  <Stack direction="row" alignItems="center">
                    <Typography>Delivery Type:&nbsp;</Typography>
                    <Typography pr={0.5} variant="body1">
                      Pick-up Order
                    </Typography>
                    <Tooltip title="Rider will pick-up the order">
                      <StoreVisitIcon fontSize="small" color="primary" />
                    </Tooltip>
                  </Stack>
                ) : null}
                <Typography>
                  Payment collection: {dunzoActiveOrder?.payment_collection}
                </Typography>
              </Box>
              {dunzoActiveOrder?.invoice_details && (
                <Box width={{ xs: "100%", md: "50%" }}>
                  <Typography variant="h4">Invoice Details</Typography>
                  {dunzoActiveOrder?.invoice_details?.invoice_id && (
                    <Typography>
                      Invoice ID:{" "}
                      {dunzoActiveOrder?.invoice_details?.invoice_id}
                    </Typography>
                  )}
                  <Typography variant="body1">
                    Order amount: &#8377;
                    {Number(dunzoActiveOrder?.order_amount).toFixed(2)}
                  </Typography>
                  {dunzoActiveOrder.invoice_details.final_payout_amount && (
                    <Typography>
                      Final Payout Amount: &#8377;{" "}
                      {dunzoActiveOrder?.invoice_details?.final_payout_amount}
                    </Typography>
                  )}
                  <Typography>
                    Payment Status:{" "}
                    {dunzoActiveOrder?.invoice_details?.payment_status}
                  </Typography>
                </Box>
              )}
            </Stack>

            <Stack direction="row" mt={{ xs: 0, md: 3 }}>
              {dunzoActiveOrder?.merchant_details && (
                <Box width={{ xs: "100%", md: "50%" }}>
                  <Typography variant="h4">Merchant Details</Typography>
                  <Typography variant="body1">
                    Accepted by:{" "}
                    {
                      dunzoActiveOrder?.merchant_details
                        ?.association_display_name
                    }
                  </Typography>
                  <Typography>
                    Accepted at:{" "}
                    {parseAndFormateDate(dunzoActiveOrder?.created_at)}
                  </Typography>
                </Box>
              )}
              {dunzoActiveOrder?.delivery_details && (
                <Box width={{ xs: "100%", md: "50%" }}>
                  <Typography variant="h4">Delivery Details</Typography>
                  <Typography variant="body1">
                    Name:{" "}
                    {dunzoActiveOrder?.delivery_details?.delivery_person_name}
                  </Typography>
                  <Typography>
                    Mobile number:{" "}
                    {dunzoActiveOrder?.delivery_details?.delivery_person_mobile}
                  </Typography>
                </Box>
              )}
            </Stack>

            <Stack mt={3}>
              <DunzoMedicinesView
                dunzoActiveOrder={dunzoActiveOrder}
                showOrderDetail={showOrderDetail}
              />
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DunzoOrderDetailModal;
