import {
  Box,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_VERSION } from "../../../enums/common.enum";
import BarChartIcon from "@mui/icons-material/BarChart";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import LaunchIcon from "@mui/icons-material/Launch";
import CancelledMetricModal from "./cancelledMetricModal";

const useStyles = makeStyles({
  tilesTextColor: {
    color: "#fff",
  },
});

const AovPopover = ({ calculateAvgOrderValue }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id: any = open ? "simple-popover" : undefined;

  return (
    <div>
      <Tooltip title="Average Order Value">
        <IconButton onClick={handleClick}>
          <LaunchIcon fontSize="medium" sx={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={2} py={1}>
          <Typography variant="h6" mb={2}>
            Average Order Value
          </Typography>
          <Typography variant="body2">
            Non-Dunzo: &#8377;
            {calculateAvgOrderValue("medicines")}
          </Typography>
          <Typography variant="body2">
            Dunzo: &#8377; {calculateAvgOrderValue("dunzo")}
          </Typography>
          <Typography variant="body2">
            Consultation: &#8377; {calculateAvgOrderValue("consultation")}{" "}
          </Typography>
          <Typography variant="body2">
            Diagnostics: &#8377; {calculateAvgOrderValue("diagnostics")}{" "}
          </Typography>
        </Box>
      </Popover>
    </div>
  );
};

const TilesView = ({ startDate, endDate }: any) => {
  const [metricsData, setMetricsData] = useState<any>(null);
  const [showCancelledMetricModal, setShowCancelledMetricModal] =
    useState(false);

  const getMetricsData = async () => {
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: "tiles_metric",
    };
    const response = await fetch(
      `${config.URL}${API_VERSION.V2}/analytics/ops`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );
    const result = await response.json();
    setMetricsData(result.response);
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  function getTotalCount(key: string, sumOf: string) {
    return metricsData[key]?.reduce(
      (acc: any, curr: any) => acc + curr[sumOf],
      0
    );
  }

  // const getTotalCountOf = (key: string, sumOf: string, allowedTypes: any) => {
  //   let result: any = 0;
  //   allowedTypes.forEach((category: string) => {
  //     result = result + getCategoryWiseCount(key, sumOf, category);
  //   });
  //   return result;
  // };

  //TODO: Refactor
  function getCategoryWiseCount(key: string, sumOf: string, category: string) {
    let filteredData: any = [];
    if (category === "dunzo") {
      filteredData = metricsData[key]?.filter(
        (x: any) => x.order_type === "medicines" && x.partner_name === "dunzo"
      );
    } else {
      filteredData = metricsData[key]?.filter(
        (x: any) => x.order_type === category && x.partner_name !== "dunzo"
      );
    }
    return filteredData?.reduce((acc: any, curr: any) => acc + curr[sumOf], 0);
  }

  const classes = useStyles();

  function calculateGrossValue(category: string) {
    return Number(
      getCategoryWiseCount("delivered_orders", "order_sum", category) +
        getCategoryWiseCount("cancelled_orders", "order_sum", category)
    ).toFixed(2);
  }

  function calculateAvgOrderValue(category: string) {
    return Number(
      Number(
        getCategoryWiseCount("delivered_orders", "order_sum", category) +
          getCategoryWiseCount("cancelled_orders", "order_sum", category)
      ) /
        Number(
          getCategoryWiseCount("delivered_orders", "order_count", category) +
            getCategoryWiseCount("cancelled_orders", "order_count", category)
        )
    ).toFixed(2);
  }

  return (
    <div>
      {metricsData && (
        <Stack direction="row" flexWrap="wrap">
          <Box sx={{ width: { xs: "50%", md: "20%" }, p: 0.5 }}>
            <Box
              style={{
                background: "linear-gradient(to right,#ff8b8a,#ff6361)",
                borderRadius: 5,
                boxShadow: "0 1px 20px 0 rgba(69,90,100,.08)",
              }}
            >
              <Box
                px={2}
                pt={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack>
                  <Typography variant="h3" className={classes.tilesTextColor}>
                    {new Intl.NumberFormat("en-IN").format(
                      getTotalCount("created_orders", "order_count")
                    )}
                  </Typography>
                  <Typography variant="h6" className={classes.tilesTextColor}>
                    Total Orders
                  </Typography>
                </Stack>
                <BarChartIcon fontSize="large" style={{ color: "#fff" }} />
              </Box>
              <Box my={1} style={{ borderTop: "1px solid white" }}></Box>
              <Stack px={2} pb={1}>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Non-dunzo:{" "}
                  {getCategoryWiseCount(
                    "created_orders",
                    "order_count",
                    "medicines"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Dunzo:{" "}
                  {getCategoryWiseCount(
                    "created_orders",
                    "order_count",
                    "dunzo"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Consultation:{" "}
                  {getCategoryWiseCount(
                    "created_orders",
                    "order_count",
                    "consultation"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Diagnostics:{" "}
                  {getCategoryWiseCount(
                    "created_orders",
                    "order_count",
                    "diagnostics"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "50%", md: "20%" }, p: 0.5 }}>
            <Box
              style={{
                background: "linear-gradient(to right,#397dff,#0058ff)",
                borderRadius: 5,
                boxShadow: "0 1px 20px 0 rgba(69,90,100,.08)",
              }}
            >
              <Box
                px={2}
                pt={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack>
                  <Typography variant="h3" className={classes.tilesTextColor}>
                    {new Intl.NumberFormat("en-IN").format(
                      getTotalCount("delivered_orders", "order_count")
                    )}
                  </Typography>
                  <Typography variant="h6" className={classes.tilesTextColor}>
                    Delivered Orders
                  </Typography>
                </Stack>
                <BarChartIcon fontSize="large" style={{ color: "#fff" }} />
              </Box>
              <Box my={1} style={{ borderTop: "1px solid white" }}></Box>
              <Stack px={2} pb={1}>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Non-dunzo:{" "}
                  {getCategoryWiseCount(
                    "delivered_orders",
                    "order_count",
                    "medicines"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Dunzo:{" "}
                  {getCategoryWiseCount(
                    "delivered_orders",
                    "order_count",
                    "dunzo"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Consultation:{" "}
                  {getCategoryWiseCount(
                    "delivered_orders",
                    "order_count",
                    "consultation"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Diagnostics:{" "}
                  {getCategoryWiseCount(
                    "delivered_orders",
                    "order_count",
                    "diagnostics"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "50%", md: "20%" }, p: 0.5 }}>
            <Box
              style={{
                background: "linear-gradient(to right,#7d77a7,#58508d)",
                borderRadius: 5,
                boxShadow: "0 1px 20px 0 rgba(69,90,100,.08)",
              }}
            >
              <Box
                px={2}
                pt={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack>
                  <Typography variant="h3" className={classes.tilesTextColor}>
                    {new Intl.NumberFormat("en-IN").format(
                      getTotalCount("cancelled_orders", "order_count")
                    )}
                  </Typography>
                  <Typography variant="h6" className={classes.tilesTextColor}>
                    Cancelled Orders
                  </Typography>
                </Stack>
                <div>
                  <Tooltip title="Cancellation Details">
                    <IconButton
                      onClick={() => setShowCancelledMetricModal(true)}
                    >
                      <LaunchIcon fontSize="medium" sx={{ color: "#fff" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </Box>
              <Box my={1} style={{ borderTop: "1px solid white" }}></Box>
              <Stack px={2} pb={1}>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Non-dunzo:{" "}
                  {getCategoryWiseCount(
                    "cancelled_orders",
                    "order_count",
                    "medicines"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Dunzo:{" "}
                  {getCategoryWiseCount(
                    "cancelled_orders",
                    "order_count",
                    "dunzo"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Consultation:{" "}
                  {getCategoryWiseCount(
                    "cancelled_orders",
                    "order_count",
                    "consultation"
                  )}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Diagnostics:{" "}
                  {getCategoryWiseCount(
                    "cancelled_orders",
                    "order_count",
                    "diagnostics"
                  )}
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "50%", md: "20%" }, p: 0.5 }}>
            <Box
              style={{
                background: "linear-gradient(to right,#397dff,#0058ff)",
                borderRadius: 5,
                boxShadow: "0 1px 20px 0 rgba(69,90,100,.08)",
              }}
            >
              <Box
                px={2}
                pt={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack>
                  <Typography variant="h3" className={classes.tilesTextColor}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(
                      getTotalCount("delivered_orders", "order_sum") +
                        getTotalCount("cancelled_orders", "order_sum")
                    )}
                  </Typography>
                  <Typography variant="h6" className={classes.tilesTextColor}>
                    Gross GMV
                  </Typography>
                </Stack>
                <AovPopover calculateAvgOrderValue={calculateAvgOrderValue} />
              </Box>
              <Box my={1} style={{ borderTop: "1px solid white" }}></Box>
              <Stack px={2} pb={1}>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Non-dunzo: &#8377; {calculateGrossValue("medicines")}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Dunzo: &#8377; {calculateGrossValue("dunzo")}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Consultation: &#8377; {calculateGrossValue("consultation")}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Diagnostics: &#8377; {calculateGrossValue("diagnostics")}
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "50%", md: "20%" }, p: 0.5 }}>
            <Box
              style={{
                background: "linear-gradient(to right,#ff8b8a,#ff6361)",
                borderRadius: 5,
                boxShadow: "0 1px 20px 0 rgba(69,90,100,.08)",
              }}
            >
              <Box
                px={2}
                pt={1}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack>
                  <Typography variant="h3" className={classes.tilesTextColor}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(getTotalCount("delivered_orders", "order_sum"))}
                  </Typography>
                  <Typography variant="h6" className={classes.tilesTextColor}>
                    Net GMV
                  </Typography>
                </Stack>
                <BarChartIcon fontSize="large" style={{ color: "#fff" }} />
              </Box>
              <Box my={1} style={{ borderTop: "1px solid white" }}></Box>
              <Stack px={2} pb={1}>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Non-dunzo: &#8377;{" "}
                  {Number(
                    getCategoryWiseCount(
                      "delivered_orders",
                      "order_sum",
                      "medicines"
                    )
                  ).toFixed(2)}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Dunzo: &#8377;{" "}
                  {Number(
                    getCategoryWiseCount(
                      "delivered_orders",
                      "order_sum",
                      "dunzo"
                    )
                  ).toFixed(2)}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Consultation: &#8377;{" "}
                  {Number(
                    getCategoryWiseCount(
                      "delivered_orders",
                      "order_sum",
                      "consultation"
                    )
                  ).toFixed(2)}
                </Typography>
                <Typography variant="body2" className={classes.tilesTextColor}>
                  {" "}
                  Diagnostics: &#8377;{" "}
                  {Number(
                    getCategoryWiseCount(
                      "delivered_orders",
                      "order_sum",
                      "diagnostics"
                    )
                  ).toFixed(2)}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Stack>
      )}
      {showCancelledMetricModal && (
        <CancelledMetricModal
          showCancelledMetricModal={showCancelledMetricModal}
          setShowCancelledMetricModal={setShowCancelledMetricModal}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  );
};

export default TilesView;
