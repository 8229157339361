import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import {
  getMuiTheme,
  parseAndFormateDate,
  textCapitalize,
} from "../../../../utils/common";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import { UpdateNotesIcon } from "../../../../assets/icons";
import NotesModal from "../../notesModal";
import ConfirmPayment from "./confirmPayment";
import KnowlarityCall from "../../../Common/knowlarityCall";
import CreateInvoiceModal from "./createInvoiceModal";
import { commonTableOptions } from "../../../../constants";
import OrderBadges from "../../orderBadges";
import { ThemeProvider } from "@mui/styles";
import ErrorBoundary from "../../../Helpers/ErrorBoundary";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const AcceptedOrders: React.FC<{
  data: any;
  orderType: string;
  getOrders: any;
  dataLoading: boolean;
}> = ({ data, orderType, getOrders, dataLoading }) => {
  const [activeOrderId, setActiveOrderId] = useState("");
  const [notesModal, setNotesModal] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [activePartner, setActivePartner] = useState("");

  const manageAcceptedColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.name",
      label: "CUSTOMER NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.mobile",
      label: "CUSTOMER MOBILE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "consultation_type",
      label: "CONSULTATION DETAILS",
      options: {
        filter: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        display: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        searchable: false,
      },
    },
    {
      name: "sample_collection",
      label: "DIAGNOSTIC DETAILS",
      options: {
        filter: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        display: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        searchable: false,
      },
    },
    {
      name: "time_slot",
      label: "CONSULTATION TIME",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "order_partner",
      label: "ORDER PARTNER",
      options: {
        filter: true,
        //filterType: "textField" as FilterType,
        searchable: false,
      },
    },
    {
      name: "order_status",
      label: "ORDER STATUS",
      options: {
        filter: true,
        searchable: false,
      },
    },
    {
      name: "merchant_details.name",
      label: orderType === ORDER_TYPE.MEDICINES ? "PHARMACY INFO" : "LAB INFO",
      options: {
        filter: false,
        display: orderType === ORDER_TYPE.CONSULTATION ? false : true,
        searchable: false,
      },
    },
    {
      name: "merchant_details.mobile",
      label: "MERCHANT MOBILE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "nearby_merchants",
      label: "PHARMACY INFO",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "accepted_at",
      label: "UPDATED AT",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "ACTION",
      options: {
        filter: false,
        searchable: true,
      },
    },
    {
      name: "invoice_details.invoice_link",
      label: "INVOICE LINK",
      options: {
        display: false,
        filter: false,
        searchable: false,
      },
    },
    {
      name: "priority",
      label: "PRIORITY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "express_delivery",
      label: "EXPRESS DELIVERY",
      options: {
        filter: orderType === ORDER_TYPE.MEDICINES ? true : false,
        display: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: dataLoading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any) => {
      return <CustomRowComponent key={data[0]} rowData={data} />;
    },
    searchPlaceholder: "Name/Partner Order ID/Order ID",
  };

  function CustomRowComponent({ rowData }: any) {
    const [
      partner_order_id,
      customer_name,
      customer_mobile,
      consultation_type,
      sample_collection,
      time_slot,
      order_partner,
      order_status,
      merchant_name,
      merchant_mobile,
      nearby_merchants,
      created_at,
      order_id,
      invoice_link,
      priority,
      express_delivery,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell
          sx={{
            maxWidth: { sm: "auto", md: "20vw" },
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          {invoice_link ? (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">{partner_order_id}</Typography>
                <OrderBadges
                  priority={priority}
                  ordere_status={order_status}
                  partner_order_id={partner_order_id}
                  express_delivery={express_delivery}
                />
              </Stack>
              <Tooltip title="view invoice">
                <Link
                  target="_blank"
                  href={invoice_link}
                  sx={{ textDecoration: "none" }}
                >
                  Invoice
                  <OpenInNewIcon
                    sx={{
                      fontSize: "16px",
                      ml: 0.5,
                      position: "relative",
                      top: "2px",
                    }}
                  />
                </Link>
              </Tooltip>
            </>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Tooltip title="create invoice">
                <Typography
                  variant="body2"
                  sx={{ color: "#0058ff", cursor: "pointer" }}
                  onClick={() => {
                    setCreateInvoiceModal(true);
                    setActivePartner(order_partner);
                    setActivePartnerOrderId(partner_order_id);
                  }}
                >
                  {partner_order_id}
                </Typography>
              </Tooltip>
              <OrderBadges
                priority={priority}
                ordere_status={order_status}
                partner_order_id={partner_order_id}
                express_delivery={express_delivery}
              />
            </Stack>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="column">
            <Stack direction="row" alignItems="center">
              <KnowlarityCall
                order={{ order_id: order_id, mobile: customer_mobile }}
                showNumber={false}
              />
              <Typography variant="body2">{customer_name}</Typography>
            </Stack>
          </Stack>
        </TableCell>
        {(orderType === ORDER_TYPE.CONSULTATION ||
          orderType === ORDER_TYPE.DIAGNOSTICS) && (
          <TableCell>
            <Stack>
              <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                {orderType === ORDER_TYPE.CONSULTATION
                  ? textCapitalize(consultation_type)
                  : textCapitalize(sample_collection?.split("_").join(" "))}
              </Typography>
              <Typography variant="body2" sx={{ color: "#718096" }}>
                {time_slot}
              </Typography>
            </Stack>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">{order_partner}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {textCapitalize(order_status?.split("_").join(" "))}
          </Typography>
        </TableCell>
        {orderType !== ORDER_TYPE.CONSULTATION && (
          <TableCell>
            <Stack direction="row">
              {orderType === ORDER_TYPE.MEDICINES ? (
                <>
                  {nearby_merchants?.[0]?.platform === "manual" ? (
                    <Tooltip title={`Platform: Via WhatsApp`}>
                      <WhatsAppIcon
                        sx={{ mr: 0.25 }}
                        color="success"
                        fontSize="small"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Platform: Via App`}>
                      <PhoneAndroidIcon
                        sx={{ mr: 0.25 }}
                        color="success"
                        fontSize="small"
                      />
                    </Tooltip>
                  )}
                  {nearby_merchants?.[0]?.static_qr_enabled ? (
                    <Tooltip title="MedPay QR Pharmacy">
                      <QrCode2Icon
                        sx={{ mr: 0.5 }}
                        fontSize="small"
                        color="primary"
                      />
                    </Tooltip>
                  ) : null}
                </>
              ) : null}
              <Typography variant="body2">{merchant_name}</Typography>
            </Stack>
            <Box display="flex" alignItems="center">
              <KnowlarityCall
                order={{ order_id: order_id, mobile: merchant_mobile }}
                showNumber={true}
                renderSmallIcon={true}
              />
            </Box>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">
            {parseAndFormateDate(created_at)}
          </Typography>
        </TableCell>
        <TableCell>
          <Stack direction="row">
            <IconButton
              color="primary"
              sx={{ fontSize: "22px" }}
              onClick={() => {
                setActiveOrderId(order_id);
                setNotesModal(true);
              }}
            >
              <UpdateNotesIcon />
            </IconButton>
            {/* PARTNER SPECIFIC: LOMBARD */}
            {order_partner === "lombard" ? (
              <>
                {order_status === ORDER_STATUS.PAYMENT_PENDING ? (
                  <ConfirmPayment
                    activePartner={order_partner}
                    activePartnerOrderId={partner_order_id}
                    orderStatus={order_status}
                  />
                ) : null}
              </>
            ) : (
              <ConfirmPayment
                activePartner={order_partner}
                activePartnerOrderId={partner_order_id}
                orderStatus={order_status}
              />
            )}
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <Box>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title=""
            data={data}
            columns={manageAcceptedColumns}
            options={options}
          />
        </ThemeProvider>
        {notesModal && (
          <NotesModal
            notesModal={notesModal}
            setNotesModal={setNotesModal}
            orderId={activeOrderId}
          />
        )}
        {createInvoiceModal && (
          <CreateInvoiceModal
            createInvoiceModal={createInvoiceModal}
            setCreateInvoiceModal={setCreateInvoiceModal}
            activePartner={activePartner}
            activePartnerOrderId={activePartnerOrderId}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default AcceptedOrders;
