import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ManageUsers from "./manageUsers";
import CreateUser from "./createUser";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import { errorToast, handleError } from "../../../utils/common";
import useUserInfo from "../../../hooks/useUserInfo";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../../enums/common.enum";
import PermissionsModal from "./permissionsModal";

const Users = () => {
  const [userModal, setUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [showPermissionsModal, setShowPermissionsModal] =
    useState<boolean>(false);
  const [activeLoginId, setActiveLoginId] = useState<any>("");
  const privateInstance = useAxiosInstance();

  const user: AuthenticatedUser = useUserInfo();

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/auth/users/all`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        let filteredUsers: any = result.data.response;
        if (user?.role !== USER_ROLE.SUPER_ADMIN) {
          filteredUsers = filteredUsers.filter(
            (x: any) => x.role !== USER_ROLE.SUPER_ADMIN
          );
        }
        setAllUsers(filteredUsers);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ float: "right" }}
          startIcon={<AddIcon />}
          onClick={() => setUserModal(!userModal)}
        >
          Create User
        </Button>
      </Box>
      <Box sx={{ mt: { xs: 6.5, md: 7.5 } }}>
        <ManageUsers
          data={allUsers}
          loading={loading}
          setUserModal={setUserModal}
          setActiveLoginId={setActiveLoginId}
          setShowPermissionsModal={setShowPermissionsModal}
        />
      </Box>
      {userModal && (
        <CreateUser
          userModal={userModal}
          setUserModal={setUserModal}
          activeLoginId={activeLoginId}
          setActiveLoginId={setActiveLoginId}
          getAllUsers={getAllUsers}
        />
      )}
      {showPermissionsModal && (
        <PermissionsModal
          showPermissionsModal={showPermissionsModal}
          setShowPermissionsModal={setShowPermissionsModal}
          activeLoginId={activeLoginId}
          setActiveLoginId={setActiveLoginId}
        />
      )}
    </>
  );
};

export default Users;
