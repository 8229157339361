import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import { CONSULTATION_TYPE } from "../../../../../enums/order.enum";
import { validateFloatNum } from "../../../../../utils/common";
import DiscountPercent from "../../Common/discountPercent";

const ConsultationInvoice = ({
  formData,
  errors,
  touched,
  handleBlur,
  setFieldValue,
}: any) => {
  const {
    discount,
    final_payable,
    partner_charges,
    doctor_charges,
    pan_no,
    consultationType,
  } = formData;
  return (
    <>
      <Stack direction="row" mt={2}>
        <TextField
          fullWidth
          id="doctor_charges"
          label="Doctor charges"
          size="small"
          value={doctor_charges || ""}
          onChange={(e) =>
            setFieldValue(
              "doctor_charges",
              validateFloatNum(e.target.value, 9999)
            )
          }
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          InputLabelProps={{
            required: true,
          }}
          onBlur={handleBlur}
          helperText={
            errors.doctor_charges && touched.doctor_charges
              ? errors.doctor_charges
              : ""
          }
          error={errors.doctor_charges && touched.doctor_charges ? true : false}
        />
        {consultationType === CONSULTATION_TYPE.ONLINE && (
          <TextField
            fullWidth
            id="partner_charges"
            label="Partner Charges"
            size="small"
            value={partner_charges}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            disabled
          />
        )}
      </Stack>
      <Stack direction="row" mt={2}>
        <Box width="33%">
          <TextField
            fullWidth
            id="pan_no"
            label="PAN NO"
            size="small"
            value={pan_no}
            onChange={(e) => setFieldValue("pan_no", e.target.value)}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          />
        </Box>
        <Box width="67%" sx={{ ml: { xs: 0, md: 1 } }}>
          <DiscountPercent
            discount={discount}
            final_payable={final_payable}
            total={doctor_charges}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Stack>
    </>
  );
};

export default ConsultationInvoice;
