import { Box, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import {
  calculatePercentage,
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
} from "../../../utils/common";
import LineChart from "../Charts/LineChart";
import InfoIcon from "@mui/icons-material/Info";

const AutoAssignmentMetrics = ({
  startDate,
  endDate,
  metricType,
  graphTitle,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);

  const getMetricsData = async () => {
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: metricType,
    };

    const result: any = await instance.post(
      `${config.URL}${API_VERSION.V2}/analytics/ops`,
      payload
    );

    if (result?.data?.status === API_RESULT.SUCCESS) {
      setMetricData(result.data.response);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);
    // rawData.agent_auto_asignment?.forEach((d: any) => {
    //   if (!labels.includes(d.delivery_date)) {
    //     labels.push(d.delivery_date);
    //   }
    // });

    const assignmentCount: any = [];
    const reassignmentCount: any = [];

    labels.forEach((el: any) => {
      let filteredAssignmentDataByLabel = filterByKeyValue(
        rawData.agent_auto_asignment,
        "delivery_date",
        el
      );

      let filteredReassignmentDataByLabel = filterByKeyValue(
        rawData.agent_reassignment,
        "delivery_date",
        el
      );

      let totalOrderForLabel = getCountByKey(
        filteredAssignmentDataByLabel,
        "order_count"
      );

      let autoAssignedOrderForLabel = filteredAssignmentDataByLabel.filter(
        (x: any) => x.agent_auto_assigned === 1
      );

      let assignPercent: any = calculatePercentage(
        getCountByKey(autoAssignedOrderForLabel, "order_count"),
        totalOrderForLabel
      );

      let ressignPercent: any = calculatePercentage(
        getCountByKey(filteredReassignmentDataByLabel, "total_count"),
        totalOrderForLabel
      );

      assignmentCount.push(assignPercent);
      reassignmentCount.push(ressignPercent);
    });

    const datasets = [
      {
        label: "Auto Assigned",
        data: assignmentCount,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
      {
        label: "Reassigned",
        data: reassignmentCount,
        borderColor: "#0058ff",
        backgroundColor: "#0058ff",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box
      width={{ xs: "100%", md: "60%" }}
      sx={{
        padding: "10px",
        ml: { xs: "0", md: "20%" },
      }}
    >
      <Box
        sx={{
          boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
          padding: "10px",
        }}
      >
        <Box textAlign="right">
          <Tooltip title={tooltipText}>
            <InfoIcon color="primary" fontSize="small" />
          </Tooltip>
        </Box>
        {genData && <LineChart title={graphTitle} data={genData} />}
      </Box>
    </Box>
  );
};

export default AutoAssignmentMetrics;
