import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import OrderByPartnerId from "../../orderByPartnerId";

const SearchOrderModal = ({ open, onClose }: any) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        Search Order
      </CustomDialogTitle>
      <DialogContent>
        <OrderByPartnerId from="manage" closeSearchModal={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default SearchOrderModal;
