import React from "react";
import * as Sentry from "@sentry/browser";
import config from "../../config";
import { Alert, Box } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box my={5}>
              <Alert severity="error">
                There was an issue retrieving data!
              </Alert>
            </Box>
          </Box>
          {config.ENV === "DEV" && (
            <>
              {this.state.error && this.state.error.toString()}
              <details style={{ whiteSpace: "pre-wrap" }}>
                {this.state.errorInfo.componentStack}
              </details>
            </>
          )}
        </>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
