import { instance } from "../utils/api";
import { useEffect } from "react";
import { AuthenticatedUser } from "../interfaces/user.model";
import useUserInfo from "./useUserInfo";
import useLogout from "./useLogout";

const useAxiosInstance = () => {
  const user: AuthenticatedUser = useUserInfo();
  const logout = useLogout();

  useEffect(() => {
    const requestIntercept = instance.interceptors.request.use(
      (config: any) => {
        if (config.method === "post") {
          if (config["url"].includes("/opd/reports")) {
          } else if (
            config["url"].includes("create") ||
            config["url"].includes("dunzo/order") ||
            config["url"].includes("/orders/payments") ||
            config["url"].includes("/auth/users") ||
            config["url"].includes("/notes") ||
            config["url"].includes("/logistics") ||
            config["url"].includes("/ledger/transaction") ||
            config["url"].includes("/manage/locations")
          ) {
            if (
              config["url"].includes("/orders/payments/upi-whitelisting") ||
              config["url"].includes("/orders/payments/refund")
            ) {
              config["data"]["modified_by"] = user.user_id;
            } else {
              if (config["url"].includes("/logistics/cancel")) {
                config["data"]["cancelled_by"] = user.user_id;
              } else {
                config["data"]["created_by"] = user.user_id;
              }
            }
          } else {
            if (
              config["url"].includes("/orders/medicine/sku") ||
              config["url"].includes("/issues")
            ) {
              config["data"]["created_by"] = user?.user_id;
            } else {
              config["data"]["modified_by"] = user?.user_id;
            }
          }
        } else if (config.method === "patch" || config.method === "put") {
          config["data"]["modified_by"] = user?.user_id;
        }

        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${user?.access_token}`;
        }
        //add custom header
        config.headers.common["x-amz-security-token"] = user?.user_id;
        return config;
      },
      (error: any) => Promise.reject(error)
    );

    const responseIntercept = instance.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        const prevRequest = error?.config;
        if (
          error.response?.status === 401 &&
          (prevRequest.url === "/auth/login" ||
            prevRequest.url === "/auth/refresh-token")
        ) {
          return Promise.reject(error);
        }

        if (error?.response?.status === 401 && !prevRequest?._retry) {
          logout();
        }
        return Promise.reject(error);
      }
    );
    return () => {
      instance.interceptors.response.eject(responseIntercept);
      instance.interceptors.request.eject(requestIntercept);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return instance;
};

export default useAxiosInstance;
