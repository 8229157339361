import { Box, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateTimeSelect from "../../../components/Common/dateTimeSelect";
import CommonDataView from "../../../components/ManageOrder/GroupOrders/Assign/commonDataView";
import DiscountPercent from "../../../components/ManageOrder/GroupOrders/Common/discountPercent";
import { valueLimits } from "../../../constants";
import { validateIntNum } from "../../../utils/common";
import TestsTable from "../Create/testsTable";

const EditDiagnosticsOrder = ({
  merchant_details,
  orderType,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  selectedTests,
  setSelectedTests,
  formData,
  testsTableError,
  setTestsTableError,
}: any) => {
  const {
    time_slot,
    time_slot_raw,
    lab_name,
    lab_mobile,
    discount,
    final_payable,
  } = formData;
  const [updatedSubTotal, setUpdatedSubTotal] = useState(0);

  useEffect(() => {
    let subT: number = 0;
    selectedTests.forEach((element: any) => {
      subT += Number(element.MRP);
    });
    setUpdatedSubTotal(subT);
  }, [selectedTests]);

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4">Lab Details:</Typography>
      <CommonDataView
        merchant_details={merchant_details}
        cols={["NAME", "MOBILE NO", "ADDRESS"]}
        orderType={orderType}
        orderId=""
      />
      <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 3 }}>
        <TextField
          fullWidth
          id="lab_name"
          label="Full Name"
          size="small"
          value={lab_name || ""}
          onChange={(e) => setFieldValue("lab_name", e.target.value)}
          onBlur={handleBlur}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          helperText={
            errors.lab_name && touched.lab_name ? errors.lab_name : ""
          }
          error={errors.lab_name && touched.lab_name ? true : false}
          inputProps={{
            maxLength: valueLimits.NAME_LEN,
          }}
          InputLabelProps={{
            required: true,
          }}
        />
        <TextField
          fullWidth
          id="lab_mobile"
          label="Whatsapp No."
          size="small"
          value={lab_mobile || ""}
          onChange={(e) =>
            setFieldValue(
              "lab_mobile",
              validateIntNum(e.target.value, valueLimits.MOBILE)
            )
          }
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          onBlur={handleBlur}
          helperText={
            errors.lab_mobile && touched.lab_mobile ? errors.lab_mobile : ""
          }
          error={errors.lab_mobile && touched.lab_mobile ? true : false}
          inputProps={{
            maxLength: 10,
          }}
          InputLabelProps={{
            required: true,
          }}
        />
      </Stack>
      <Typography my={3} textAlign="center" variant="h5">
        Requested sample collection time: {time_slot_raw}
      </Typography>
      <Box sx={{ mt: 3 }}>
        <DateTimeSelect
          time_slot={time_slot}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          pickerLabel="Sample collection Date & Time"
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4">Test Details:</Typography>
        <TestsTable
          data={selectedTests}
          setSelectedTests={setSelectedTests}
          selectedTestsId={null}
          setSelectedTestsId={null}
          isEditing={true}
          testsTableError={testsTableError}
          setTestsTableError={setTestsTableError}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <DiscountPercent
          discount={discount}
          final_payable={final_payable}
          total={updatedSubTotal}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
        />
      </Box>
    </Box>
  );
};

export default EditDiagnosticsOrder;
