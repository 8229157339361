import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { errorToast, handleError } from "../../../utils/common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { addDays, differenceInDays, format, subDays } from "date-fns";
import DayWiseMetrics from "./DayWiseMetrics";
import { instance } from "../../../utils/api";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import AovMetrics from "./AovMetrics";
import DigitalPaymentSummary from "./Summary";
import ProviderQrMetrics from "./ProviderQrMetrics";
import StoreActivityMetrics from "./StoreActivityMetrics";

const DigitalPaymentOverview = () => {
  const [startDate, setStartDate] = useState<any>(subDays(new Date(), 6));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [unitType, setUnitType] = useState<string>("success");
  const [txnData, setTxnData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getTxnData = async () => {
    if (differenceInDays(endDate, startDate) > 31) {
      errorToast("Difference between two dates cannot be more than 31 days");
      return;
    }
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: "status_wise_transactions",
    };
    setLoading(true);
    try {
      const result: any = await instance.post(
        `${config.URL}${API_VERSION.V2}/analytics/digital-payments/overview`,
        payload
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setTxnData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTxnData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div>
      <DigitalPaymentSummary />
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="end"
        sx={{ mt: 2 }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            inputFormat="dd/MM/yyyy"
            value={startDate}
            onChange={(newValue: Date | null) => {
              setStartDate(newValue);
            }}
            minDate={new Date("March 07, 2024")}
            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                sx={{ mt: 1.5 }}
                name="start_date"
                InputLabelProps={{
                  required: true,
                }}
                autoComplete="off"
                onKeyDown={(e: any) => {
                  e.preventDefault();
                }}
              />
            )}
          />
          <DatePicker
            label="End Date"
            inputFormat="dd/MM/yyyy"
            value={endDate}
            onChange={(newValue: Date | null) => {
              if (newValue && startDate) {
                if (newValue < startDate) {
                  errorToast("End date should be after the start date");
                  return;
                }
              }
              setEndDate(newValue);
            }}
            minDate={addDays(startDate, 1)}
            maxDate={new Date()}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                sx={{ mt: 1.5, mx: { xs: 0, md: 1.5 } }}
                name="end_date"
                InputLabelProps={{
                  required: true,
                }}
                autoComplete="off"
                onKeyDown={(e: any) => {
                  e.preventDefault();
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Stack mt={5} mr={1.5} direction="row" justifyContent="end">
        <FormControl size="small">
          <InputLabel id="by-label">By</InputLabel>
          <Select
            value={unitType}
            onChange={(e: SelectChangeEvent) => {
              setUnitType(e.target.value);
            }}
            label="By"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap={2}
        mt={2}
      >
        <Box width="100%">
          <DayWiseMetrics
            startDate={startDate}
            endDate={endDate}
            // graphTitle="Day Wise Metrics"
            txnData={txnData}
            loading={loading}
            filterBy={unitType}
          />
        </Box>
        <Box width="100%">
          <AovMetrics
            startDate={startDate}
            endDate={endDate}
            // graphTitle="Average Order value"
            tooltipText={
              <>
                <p>
                  <strong>Average order value:</strong> Total value of &nbsp;
                  {unitType} transactions/Number of {unitType} transactions
                </p>
                <p>
                  <strong>Average store transaction value:</strong> Total value
                  of {unitType} transactions/Number of stores with {unitType}
                  &nbsp; transactions
                </p>
              </>
            }
            txnData={txnData}
            loading={loading}
            filterBy={unitType}
          />
        </Box>
      </Stack>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap={2}
        mt={2}
      >
        <Box width="100%">
          <ProviderQrMetrics
            startDate={startDate}
            endDate={endDate}
            graphTitle="Suspicious Summary"
            tooltipText={
              <>
                <p>
                  <strong>Suspicious Txn count:</strong> Number of suspicious
                  transactions
                </p>
                <p>
                  <strong>Suspicious Txn value:</strong> Total value of
                  suspicious transactions
                </p>
                <p>
                  <strong>Suspicious stores:</strong> Number of stores with
                  suspicious transactions
                </p>
              </>
            }
          />
        </Box>
        <Box width="100%">
          <StoreActivityMetrics
            startDate={startDate}
            endDate={endDate}
            graphTitle="Store Stats"
            tooltipText={
              <>
                <p>
                  <strong>churned activated:</strong> no transactions on day
                  T-2, but successful transactions on day T-1
                </p>
                <p>
                  <strong>churn volume:</strong> successful transactions on day
                  T-2, but no transactions on day T-1
                </p>
                <p>
                  <strong>continuously active:</strong> successful transactions
                  on days T-3, T-2 and T-1
                </p>
                <p>
                  <strong>active stores:</strong>successful transactions on day
                  T-1
                </p>
              </>
            }
          />
        </Box>
      </Stack>
    </div>
  );
};

export default DigitalPaymentOverview;
