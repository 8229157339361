import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import LineChart from "../Charts/LineChart";

const getCountByKey = (orders: any, key: any) => {
  let sum = 0;
  orders.forEach((order: any) => {
    sum += order[key];
  });
  return sum;
};

const filterByKeyValue = (orders: any, key: string, value: string) => {
  return orders.filter((x: any) => x[key] === value);
};

const OrdersCount = ({ data, title, unitType, creationPlatform }: any) => {
  const [genData, setGenData] = useState<any>(null);

  useEffect(() => {
    if (data) {
      generateDataset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, unitType, creationPlatform]);

  const generateDataset = (rawData: any) => {
    const labels: any = [];
    rawData.forEach((d: any) => {
      if (!labels.includes(d.date_label)) {
        labels.push(d.date_label);
      }
    });

    const totalMedCount: any = [];
    const totalConsCount: any = [];
    const totalDiagCount: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(rawData, "date_label", el);

      if (creationPlatform !== "all") {
        if (creationPlatform === "non-dunzo") {
          filteredDataByLabel = filteredDataByLabel.filter(
            (x: any) => x.order_creation_platform !== "dunzo"
          );
        } else {
          filteredDataByLabel = filterByKeyValue(
            filteredDataByLabel,
            "order_creation_platform",
            creationPlatform
          );
        }
      }

      let med: any = getCountByKey(
        filterByKeyValue(filteredDataByLabel, "order_type", "medicines"),
        unitType
      );
      if (unitType === "order_value") {
        med = Number(med).toFixed(2);
      }
      totalMedCount.push(med);

      let cons: any = getCountByKey(
        filterByKeyValue(filteredDataByLabel, "order_type", "consultation"),
        unitType
      );
      if (unitType === "order_value") {
        cons = Number(cons).toFixed(2);
      }
      totalConsCount.push(cons);

      let diag: any = getCountByKey(
        filterByKeyValue(filteredDataByLabel, "order_type", "diagnostics"),
        unitType
      );
      if (unitType === "order_value") {
        diag = Number(diag).toFixed(2);
      }
      totalDiagCount.push(diag);
    });

    const datasets = [
      {
        label: "medicines",
        data: totalMedCount,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
      {
        label: "consultation",
        data: totalConsCount,
        borderColor: "#0058FF",
        backgroundColor: "#0058FF",
      },
      {
        label: "diagnostics",
        data: totalDiagCount,
        borderColor: "#58508d",
        backgroundColor: "#58508d",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box
      width={{ xs: "100%", md: "50%" }}
      sx={{
        padding: "10px",
      }}
    >
      <Box
        sx={{
          boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
          padding: "10px",
        }}
      >
        {genData && <LineChart title={title} data={genData} />}
      </Box>
    </Box>
  );
};

export default OrdersCount;
