import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InfoIcon from "@mui/icons-material/Info";
import AlternateIcon from "@mui/icons-material/FormatColorText";
import { Box, Tooltip, Typography } from "@mui/material";
import { MEDICINE_CHANGE_TYPE } from "../../../enums/order.enum";

interface Column {
  id: "item" | "details" | "quantity" | "MRP" | "pharm_price" | "amount";
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "item", label: "Item", minWidth: 170 },
  { id: "details", label: "Details", minWidth: 100 },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "MRP",
    label: "Price(Rs.)",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "pharm_price",
    label: "Pharmacy Price(Rs.)",
    minWidth: 170,
    align: "left",
  },
  {
    id: "amount",
    label: "Total (Rs.)",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
];

const OrderMedicines = ({
  medicines,
  pharmacyUpdated = null,
  orderType,
  showTotal,
  deliveryCharge,
}: any) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  let subTotalMedicines: number = 0;
  if (medicines && medicines.length > 0) {
    medicines.forEach((row: any) => {
      subTotalMedicines += Number(row.quantity) * Number(row.MRP);
    });
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPharmacyPriceOfMedicine = (skuId: string) => {
    const objToArray: any = Object.entries(pharmacyUpdated);

    let updatedItem = null;
    for (let i = 0; i < objToArray.length; i++) {
      if (objToArray[i][1]?.sku_id === skuId) {
        updatedItem = objToArray[i][1];
        break;
      }
    }

    let result = "";
    if (updatedItem?.avl === "no") {
      result = "NA";
    } else if (updatedItem?.avl === "alt") {
      result = "ALT";
    } else {
      result = updatedItem?.MRP;
    }
    return result;
  };

  return (
    <Paper
      sx={{
        width: { xs: "72vw", sm: "79vw", md: "84vw", lg: "87vw", xl: "100%" },
        overflow: "hidden",
      }}
    >
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: any, index: number) => (
                <React.Fragment key={index}>
                  {pharmacyUpdated ? (
                    <TableCell
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ) : (
                    <>
                      {column.id === "pharm_price" ? null : (
                        <TableCell
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      )}
                    </>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {medicines &&
              medicines.length > 0 &&
              medicines
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, rowIndex: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      {columns.map((column: any, index: number) => {
                        const value = row[column.id];
                        return (
                          <React.Fragment key={index}>
                            {column.id === "pharm_price" ? (
                              <>
                                {pharmacyUpdated ? (
                                  <TableCell align={column.align}>
                                    {getPharmacyPriceOfMedicine(row["sku_id"])}
                                  </TableCell>
                                ) : null}
                              </>
                            ) : (
                              <TableCell align={column.align}>
                                <Box display="flex">
                                  {column.id === "item" && (
                                    <>
                                      {row["sku_id"] ? (
                                        <Box sx={{ ml: 2.5 }}></Box>
                                      ) : (
                                        <Tooltip title="Unmapped SKU">
                                          <Box
                                            height="16px"
                                            width="16px"
                                            sx={{
                                              backgroundColor: "red",
                                              borderRadius: "50%",
                                              mr: 0.5,
                                              mt: 0.25,
                                            }}
                                          >
                                            <Typography
                                              textAlign="center"
                                              variant="body2"
                                              sx={{
                                                fontSize: "10px !important",
                                              }}
                                              color="white"
                                            >
                                              M
                                            </Typography>
                                          </Box>
                                        </Tooltip>
                                      )}
                                    </>
                                  )}

                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}

                                  {column.id === "item" && (
                                    <>
                                      {row["change_type"] &&
                                        [
                                          "price",
                                          "quantity",
                                          "quantity_price",
                                          "not_available",
                                          "new",
                                        ].includes(row["change_type"]) && (
                                          <Tooltip title={row["change_type"]}>
                                            <Box
                                              height="20px"
                                              width="20px"
                                              sx={{
                                                backgroundColor: "#0058ff",
                                                borderRadius: "50%",
                                                mx: 1,
                                              }}
                                            >
                                              <Typography
                                                textAlign="center"
                                                variant="body2"
                                                color="white"
                                              >
                                                {row["change_type"] === "new"
                                                  ? "+"
                                                  : row["change_type"]
                                                      ?.slice(0, 1)
                                                      .toUpperCase()}
                                              </Typography>
                                            </Box>
                                          </Tooltip>
                                        )}
                                      {row["change_type"] ===
                                      MEDICINE_CHANGE_TYPE.ALTERNATIVE ? (
                                        <>
                                          <Tooltip
                                            title={
                                              <>
                                                <Typography variant="body2">
                                                  Alternate of:{" "}
                                                  {
                                                    row["requested_item"][
                                                      "item"
                                                    ]
                                                  }
                                                </Typography>
                                              </>
                                            }
                                          >
                                            <InfoIcon
                                              sx={{
                                                color: "red",
                                                verticalAlign: "middle",
                                                fontSize: 20,
                                              }}
                                            />
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              <>
                                                <Typography variant="body2">
                                                  {row["change_type"]}
                                                </Typography>
                                              </>
                                            }
                                          >
                                            <AlternateIcon
                                              sx={{
                                                color: "#0058ff",
                                                verticalAlign: "middle",
                                                fontSize: 20,
                                              }}
                                            />
                                          </Tooltip>
                                          <Tooltip
                                            title={
                                              <>
                                                <Typography variant="body2">
                                                  MFR by: {row["manufacturer"]}
                                                </Typography>
                                              </>
                                            }
                                          >
                                            <InfoIcon
                                              sx={{
                                                color: "#0058ff",
                                                verticalAlign: "middle",
                                                fontSize: 20,
                                              }}
                                            />
                                          </Tooltip>
                                        </>
                                      ) : (
                                        <Tooltip
                                          title={
                                            <>
                                              <Typography variant="body2">
                                                1. MFR by: {row["manufacturer"]}
                                              </Typography>
                                              <Typography variant="body2">
                                                2. Composition:{" "}
                                                {row["composition"]}
                                              </Typography>
                                            </>
                                          }
                                        >
                                          <InfoIcon
                                            sx={{
                                              color: "#0058ff",
                                              verticalAlign: "middle",
                                              fontSize: 20,
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  )}
                                </Box>
                              </TableCell>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
            {showTotal && (
              <>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell rowSpan={3} colSpan={3} />
                  <TableCell>Sub Total (Rs.)</TableCell>
                  <TableCell align="right">
                    {Number(subTotalMedicines).toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell>Delivery (Rs.)</TableCell>
                  <TableCell align="right">
                    {Number(deliveryCharge || 0)}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell>Grand Total (Rs.)</TableCell>
                  <TableCell align="right">
                    {Number(
                      Number(subTotalMedicines) + Number(deliveryCharge || 0)
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {medicines && medicines.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={medicines.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default OrderMedicines;
