import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ORDER_TYPE } from "../../enums/order.enum";
import CustomDialogTitle from "../Common/customDialogTitle";
import ImageModal from "../Common/imageModal";
import UploadFiles from "../Common/uploadFiles";
import { useIconStyles } from "../Helpers/iconStyles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { handleError } from "../../utils/common";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";

const PharmacyBills = ({ page, orderData }: any) => {
  const [showPharmacyBills, setShowPharmacyBills] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [activeImageUrl, setActiveImageUrl] = useState("");
  const roughBills =
    orderData && orderData.merchant_updated_meta
      ? orderData.merchant_updated_meta[0]?.items?.merchant_uploads
      : [];
  const [originalBills, setOriginalBills] = useState(
    orderData?.invoice_urls || []
  );
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const iconClasses = useIconStyles();
  const privateInstance = useAxiosInstance();

  const getOrderByPartnerOrderId = async () => {
    setUploadedFiles([]); //reset local state
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${orderData?.order_partner}/${orderData?.partner_order_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setOriginalBills(result.data?.response?.invoice_urls || []);
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <>
      {orderData?.order_type === ORDER_TYPE.MEDICINES ? (
        <>
          {page === "manage-last-tab" ? (
            <Tooltip title="Upload Invoice">
              <span>
                <IconButton
                  color="warning"
                  onClick={() => {
                    if (page === "manage-last-tab") {
                      getOrderByPartnerOrderId();
                    }
                    setShowPharmacyBills(true);
                  }}
                >
                  <CloudUploadIcon className={iconClasses.borderedIcon} />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Button
              onClick={() => setShowPharmacyBills(true)}
              variant="outlined"
              color="success"
              sx={{ mr: 1 }}
            >
              View Bills
            </Button>
          )}
          {showPharmacyBills && (
            <Dialog
              open={showPharmacyBills}
              onClose={() => setShowPharmacyBills(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="md"
            >
              <CustomDialogTitle
                id="customized-dialog-title"
                onClose={() => setShowPharmacyBills(false)}
              >
                Pharmacy Bills
              </CustomDialogTitle>
              <DialogContent>
                <Stack my={4}>
                  {roughBills && roughBills.length !== 0 && (
                    <Box>
                      <Typography variant="h4" mb={2}>
                        Rough Bills:
                      </Typography>
                      {Array.isArray(roughBills) ? (
                        <>
                          {roughBills.map((imgUrl: string) => {
                            return (
                              <img
                                src={imgUrl}
                                alt="medpay"
                                style={{
                                  height: "120px",
                                  width: "120px",
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  if (imgUrl?.toLowerCase()?.includes("pdf")) {
                                    window.open(imgUrl);
                                  } else {
                                    setActiveImageUrl(imgUrl);
                                    setShowImageModal(true);
                                  }
                                }}
                                onError={(e: any) => {
                                  e.target.src =
                                    "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                                }}
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </Box>
                  )}
                  {originalBills && originalBills.length !== 0 && (
                    <Box mt={4}>
                      <Typography variant="h4" mb={2}>
                        Original Bills:
                      </Typography>
                      {Array.isArray(originalBills) ? (
                        <>
                          {originalBills.map((imgUrl: string) => {
                            return (
                              <img
                                src={imgUrl}
                                alt="medpay"
                                style={{
                                  height: "120px",
                                  width: "120px",
                                  cursor: "pointer",
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  if (imgUrl?.toLowerCase()?.includes("pdf")) {
                                    window.open(imgUrl);
                                  } else {
                                    setActiveImageUrl(imgUrl);
                                    setShowImageModal(true);
                                  }
                                }}
                                onError={(e: any) => {
                                  e.target.src =
                                    "https://cdn2.iconfinder.com/data/icons/file-8/128/file-expand_Pdf-512.png";
                                }}
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </Box>
                  )}
                </Stack>
                <Divider />

                <Stack mt={4}>
                  <UploadFiles
                    title="Upload Invoice"
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    orderId={orderData?.order_id}
                    allowDel={false}
                  />
                </Stack>
                {showImageModal && (
                  <ImageModal
                    showImageModal={showImageModal}
                    setShowImageModal={setShowImageModal}
                    imageUrl={activeImageUrl}
                    title="Invoice"
                  />
                )}
              </DialogContent>
            </Dialog>
          )}
        </>
      ) : null}
    </>
  );
};

export default PharmacyBills;
