import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Divider,
  ImageList,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { instance } from "../../utils/api";
import { errorToast } from "../../utils/common";
import AddAndAlterItems from "./addAndAlterItems";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
import MedicineCard from "./medicineCard";
import PreviouslyBought from "./previouslyBought";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTotalCartValue } from "./cartView";
import MedPayLogo from "../../assets/images/medpay.png";
import SearchOffer from "../../assets/images/search_offer.svg";

const SearchMed = () => {
  const [value, setValue] = React.useState<any | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [data, setData] = useState([]);
  const [fData, setFData] = useState([]);
  const cartDataFromStore =
    useSelector((state: any) => state.cart.cartData) || [];
  const navigate = useNavigate();
  // const peopleBought = useMemo(() => randomNumInRange(200, 700), []);
  const location = useLocation();
  let qData: any = new URLSearchParams(location.search).get("data");
  let parsedData: any = "";
  if (qData) {
    parsedData = JSON.parse(window.atob(qData as string));
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await instance.get(
          `https://medpay-public.s3.ap-south-1.amazonaws.com/distribution_inventory.json`
        );
        setData(result?.data || []);
      } catch (err) {
        errorToast("something went wrong");
      }
    };

    getData();
  }, []);

  // filter and display
  useEffect(() => {
    if (data.length > 0 && inputValue?.trim()?.length >= 2) {
      setFData(
        data.filter((x: any) =>
          x.name?.toLowerCase()?.includes(inputValue?.trim().toLowerCase())
        )
      );
    } else {
      setFData([]);
    }
  }, [data, inputValue]);

  return (
    <div>
      <Stack width={{ xs: "100%", md: "660px", margin: "auto" }}>
        <Stack
          sx={{ backgroundColor: "#0058ff" }}
          p={2}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={1}>
            {parsedData?.name ? (
              <ArrowBackIcon
                fontSize="small"
                onClick={() => window.postMessage("close")}
                sx={{ color: "#fff" }}
              />
            ) : null}
            <Typography color="white" variant="h4">
              {parsedData?.name || ""}
            </Typography>
          </Stack>
          <Badge
            onClick={() => navigate(qData ? `cart?data=${qData}` : `cart`)}
            badgeContent={cartDataFromStore?.length}
            color="warning"
          >
            <ShoppingCartIcon sx={{ color: "#fff" }} />
          </Badge>
        </Stack>
        <Divider />
        <Autocomplete
          ListboxProps={{ style: { maxHeight: 510 } }}
          disablePortal
          value={value}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controlled-states-demo"
          options={fData}
          sx={{ p: 2 }}
          renderOption={(props: any, option: any) => {
            const id = props.id?.split("-")[4];
            return (
              <li {...props} key={option.id}>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                  sx={{ pb: 1, borderBottom: "1px solid #eeeeee" }}
                >
                  <Stack width="60%">
                    {Number(id) === 0 ? (
                      <Typography pb={1} variant="h5">
                        NAME
                      </Typography>
                    ) : null}
                    <Typography variant="h6" color="primary">
                      {option.name}
                    </Typography>
                    <Typography variant="body2">
                      <strong>{option.packaging}</strong> |{" "}
                      {option.manufacturer}
                    </Typography>
                  </Stack>
                  <Stack alignItems="start" width="20%">
                    {Number(id) === 0 ? (
                      <Typography pb={1} variant="h5">
                        MRP &#8377;
                      </Typography>
                    ) : null}
                    <Typography>{option.mrp}</Typography>
                  </Stack>
                  <Stack alignItems="end" width="20%">
                    {Number(id) === 0 ? (
                      <Typography pb={1} variant="h5">
                        PTR &#8377;
                      </Typography>
                    ) : null}
                    <Typography>{option.ptr}</Typography>
                  </Stack>
                </Box>
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                sx: {
                  fontSize: "16px",
                },
              }}
              label="Search Medicine"
            />
          )}
          noOptionsText={
            inputValue.length >= 2 ? "No data found" : "Enter medicine name"
          }
          getOptionLabel={(option: any) => option.name}
        />
        <Divider />
        {value ? (
          <Stack p={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <ArrowBackIcon
                fontSize="small"
                onClick={() => setValue(null)}
                color="primary"
              />
              <Typography fontSize={22} variant="h3">
                {value?.name}
              </Typography>
            </Stack>
            <Stack pt={2} direction="row">
              <Typography
                minWidth="35%"
                fontSize={16}
                color="gray"
                variant="body1"
              >
                Manufacturer
              </Typography>
              <Typography fontSize={16} variant="h6">
                {value.manufacturer}
              </Typography>
            </Stack>
            <Stack pt={1} direction="row">
              <Typography
                minWidth="35%"
                fontSize={16}
                color="gray"
                variant="body1"
              >
                Details
              </Typography>
              <Typography fontSize={16} variant="h6">
                {value.packaging}
              </Typography>
            </Stack>
            <Stack pt={1} direction="row">
              <Typography
                minWidth="35%"
                fontSize={16}
                color="gray"
                variant="body1"
              >
                MRP
              </Typography>
              <Typography fontSize={16} variant="h6">
                &#8377;{value.mrp}
              </Typography>
            </Stack>
            <Stack pt={1} direction="row">
              <Typography
                minWidth="35%"
                fontSize={16}
                color="gray"
                variant="body1"
              >
                PTR
              </Typography>
              <Typography fontSize={16} variant="h6">
                &#8377;{value.ptr}
              </Typography>
            </Stack>
            {/* <Stack pt={2} direction="row" alignItems="center" gap={1}>
              <InsertChartIcon color="primary" />
              <Typography>
                {peopleBought} people bought this recently
              </Typography>
            </Stack> */}
            <Stack mt={6}>
              <AddAndAlterItems medicine={value} view="mainListing" />
            </Stack>
          </Stack>
        ) : (
          <>
            <img src={SearchOffer} alt="banner" width="100%" height="auto" />
            <PreviouslyBought apiData={data} setValue={setValue} />
            <Stack p={2}>
              <Typography pl={1} variant="h4">
                Popular Medicines
              </Typography>
              <ImageList
                className="hideScrollBar"
                sx={{
                  gridAutoFlow: "column",
                  gridTemplateColumns:
                    "repeat(auto-fit, minmax(60%,1fr)) !important",
                  gridAutoColumns: "minmax(60%, 1fr)",
                  gap: "16px !important",
                  p: 1,
                  m: 0,
                }}
              >
                {[
                  {
                    id: 11000034,
                    name: "NEO-MERCAZOLE 20 MG",
                    packaging: "TAB",
                    mrp: 2260.17,
                    ptr: 1614.408,
                    manufacturer: "ABBOTT HEALTHCARE PVT LTD",
                  },
                  {
                    id: 11000222,
                    name: "HOPACE 2.5 MG",
                    packaging: "15 TAB",
                    mrp: 98.02,
                    ptr: 70.016,
                    manufacturer: "INTAS",
                  },
                  {
                    id: 11000282,
                    name: "LOSAR BETA",
                    packaging: "10TAB",
                    mrp: 340.14,
                    ptr: 242.96,
                    manufacturer: "TORRENT",
                  },
                  {
                    id: 11000316,
                    name: "ROXID-150 TAB",
                    packaging: "1*10      ",
                    mrp: 236.77,
                    ptr: 169.12,
                    manufacturer: "ALEMBIC",
                  },
                  {
                    id: 11000729,
                    name: "CALCIROL GRANUELS",
                    packaging: "1GM",
                    mrp: 42.63,
                    ptr: 30.448,
                    manufacturer: "MICRO",
                  },
                  {
                    id: 11000775,
                    name: "LULIFIN CREAM  10 GM",
                    packaging: "10GM",
                    mrp: 188.16,
                    ptr: 134.4,
                    manufacturer: "SUN PHARMA",
                  },
                ].map((medicine: any) => (
                  <MedicineCard
                    key={medicine.id}
                    medicine={medicine}
                    setValue={setValue}
                  />
                ))}
              </ImageList>
            </Stack>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <img src={MedPayLogo} alt="medpay" width={14} height={14} />
              <Typography color="gray">Powered by MedPay</Typography>
            </Stack>
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        {cartDataFromStore?.length > 0 ? (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              borderTop: "1px solid #eeeeee",
              width: "100%",
              maxWidth: "660px",
              p: 1.5,
              left: "50%",
              transform: "translate(-50%, 0)",
              backgroundColor: "white",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack>
                <Typography color="gray" variant="body1">
                  {cartDataFromStore?.length} item(s)
                </Typography>
                <Typography variant="h5">
                  &#8377; {getTotalCartValue(cartDataFromStore, true)}
                </Typography>
              </Stack>
              <Button
                onClick={() => navigate(qData ? `cart?data=${qData}` : `cart`)}
                variant="contained"
                color="success"
                size="small"
              >
                Go to Cart
              </Button>
            </Stack>
          </Box>
        ) : null}
      </Stack>
    </div>
  );
};

export default SearchMed;
