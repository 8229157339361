import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../../../Common/customDialogTitle";
import PaymentDataRow from "./paymentDataRow";

const PayoutBreakupModal = ({
  showPayoutBreakupModal,
  setShowPayoutBreakupModal,
  data,
}: any) => {
  return (
    <div>
      <Dialog
        open={showPayoutBreakupModal}
        onClose={() => setShowPayoutBreakupModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowPayoutBreakupModal(false)}
        >
          Final Payout Amount - Breakup
        </CustomDialogTitle>
        <DialogContent>
          <PaymentDataRow
            dataTitle="Final Amount"
            value={data?.final_payout_amount}
          />
          <PaymentDataRow
            dataTitle="Main Amount"
            value={data?.settlement_amount}
          />
          <PaymentDataRow
            dataTitle="Incentive Amount"
            value={data?.incentives_rs}
          />
          <Box pt={1}>
            <Divider />
          </Box>
          <Stack direction="row" justifyContent="space-between" pt={1}>
            <Typography variant="body1" fontWeight="bold">
              Target Discount{" "}
              <span style={{ fontSize: "12px", color: "green" }}>
                ({data?.target_discount_pct}%)
              </span>
              :
            </Typography>
            <Typography variant="body1">
              &#8377; {data?.target_discount}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" pt={1}>
            <Typography variant="body1" fontWeight="bold">
              Actual Discount{" "}
              <span style={{ fontSize: "12px", color: "green" }}>
                ({data?.discount_pct}%)
              </span>
              :
            </Typography>
            <Typography variant="body1">&#8377; {data?.discount}</Typography>
          </Stack>
          <PaymentDataRow
            dataTitle="Bank Reference"
            value={data?.incentives_details?.inc_bank_reference}
          />
          <PaymentDataRow
            dataTitle="Payout ID"
            value={data?.incentives_details?.inc_payout_id}
          />
          <PaymentDataRow
            dataTitle="Acknowledgement No"
            value={data?.incentives_details?.inc_payout_ack_no}
          />
          <PaymentDataRow
            dataTitle="Initiated At"
            value={
              data?.incentives_details?.inc_payout_updated_at?.split(".")[0]
            }
          />
          <PaymentDataRow
            dataTitle="Incentive Status"
            value={data?.incentives_details?.inc_payment_status}
          />
          {data?.incentives_details?.inc_message ? (
            <Box pt={1}>
              <Divider />
              <Typography
                pt={1}
                sx={{
                  fontSize: "12px !important",
                  color:
                    data?.incentives_details?.inc_payment_status === "failed"
                      ? "red"
                      : "",
                }}
              >
                Message: {data?.incentives_details?.inc_message}
              </Typography>
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PayoutBreakupModal;
