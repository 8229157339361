import { Box, CircularProgress, Tooltip } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { instance } from "../../../../utils/api";
import config from "../../../../config";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import {
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
  handleError,
} from "../../../../utils/common";
import LineChartMulti from "../../../Metrics/Charts/LineChartMulti";

const ProviderQrMetrics = ({
  startDate,
  endDate,
  graphTitle,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const getMetricsData = async () => {
    try {
      setLoading(true);

      let payload = {
        start_date: format(startDate, "yyyy-MM-dd"),
        end_date: format(endDate, "yyyy-MM-dd"),
        metric_type: "suspicious_summary",
      };

      const result: any = await instance.post(
        `${config.URL}${API_VERSION.V2}/analytics/digital-payments/overview`,
        payload
      );

      if (result?.data?.status === API_RESULT.SUCCESS) {
        setMetricData(result.data.response);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);

    const totalCount: any = [];
    const totalValue: any = [];
    const totalStores: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(
        rawData,
        "created_at_date",
        el
      );

      totalCount.push(getCountByKey(filteredDataByLabel, "sus_txn_count"));
      totalValue.push(getCountByKey(filteredDataByLabel, "sus_txn_value"));
      totalStores.push(getCountByKey(filteredDataByLabel, "sus_txn_stores"));
    });

    const datasets = [
      {
        label: "Txn Count",
        data: totalCount,
        borderColor: "#0058FF",
        backgroundColor: "#0058FF",
        type: "bar",
        yAxisID: "y",
        order: 2,
      },
      {
        label: "Txn Value",
        data: totalValue,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
        type: "line",
        yAxisID: "y1",
        order: 1,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
      {
        label: "Suspicious Stores",
        data: totalStores,
        borderColor: "#58508d",
        backgroundColor: "#58508d",
        type: "bar",
        yAxisID: "y",
        order: 2,
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box>
      <>
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <Box
            sx={{
              boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
              padding: "10px",
            }}
          >
            <Box textAlign="right">
              <Tooltip title={tooltipText}>
                <InfoIcon color="primary" fontSize="small" />
              </Tooltip>
            </Box>
            {genData && (
              <LineChartMulti
                title={graphTitle}
                data={genData}
                additionalOptions={{
                  leftSize: 1,
                  rightSize: 10000,
                  rightLabel: "Txn Value",
                  leftLabel: "Txn Count & Suspicious Stores",
                }}
              />
            )}
          </Box>
        )}
      </>
    </Box>
  );
};

export default ProviderQrMetrics;
