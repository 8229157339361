import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import { instance } from "../../../utils/api";
import {
  calculatePercentage,
  filterByKeyValue,
  generateGraphLabels,
  getCountByKey,
  toHoursAndMinutes,
} from "../../../utils/common";
import LineChart from "../Charts/LineChart";
import InfoIcon from "@mui/icons-material/Info";

const ComparisonMetrics = ({
  startDate,
  endDate,
  metricType,
  responseKey,
  countOfKey,
  graphTitle,
  tooltipText,
}: any) => {
  const [metricData, setMetricData] = useState<any>(null);
  const [genData, setGenData] = useState<any>(null);
  const [selectedHour, setSelectedHour] = useState<string>("all");

  const getMetricsData = async () => {
    let payload = {
      start_date: format(startDate, "yyyy-MM-dd"),
      end_date: format(endDate, "yyyy-MM-dd"),
      metric_type: metricType,
      time_period: selectedHour === "all" ? "" : selectedHour,
    };

    const result: any = await instance.post(
      `${config.URL}${API_VERSION.V2}/analytics/ops`,
      payload
    );

    if (result?.data?.status === API_RESULT.SUCCESS) {
      setMetricData(result.data.response[responseKey]);
    }
  };

  useEffect(() => {
    getMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      graphTitle.toLowerCase().includes("total tat") ||
      graphTitle.toLowerCase().includes("medpay tat")
    ) {
      getMetricsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHour]);

  useEffect(() => {
    if (metricData) {
      generateDataset(metricData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricData]);

  const generateDataset = (rawData: any) => {
    const labels: any = generateGraphLabels(startDate, endDate);

    const totalAutoCount: any = [];
    const totalManualCount: any = [];

    labels.forEach((el: any) => {
      let filteredDataByLabel = filterByKeyValue(rawData, "delivery_date", el);

      let auto: any = getCountByKey(
        filterByKeyValue(filteredDataByLabel, "fulfilled_channel", "auto"),
        countOfKey
      );
      if (countOfKey === "tat") {
        auto = toHoursAndMinutes(Number.parseFloat(auto));
      }

      let manual: any = getCountByKey(
        filterByKeyValue(filteredDataByLabel, "fulfilled_channel", "manual"),
        countOfKey
      );
      if (countOfKey === "tat") {
        manual = toHoursAndMinutes(Number.parseFloat(manual));
      }

      if (graphTitle.includes("%")) {
        totalAutoCount.push(calculatePercentage(auto, Number(auto + manual)));
        totalManualCount.push(
          calculatePercentage(manual, Number(auto + manual))
        );
      } else {
        totalAutoCount.push(auto);
        totalManualCount.push(manual);
      }
    });

    const datasets = [
      {
        label: "auto",
        data: totalAutoCount,
        borderColor: "#FF6361",
        backgroundColor: "#FF6361",
      },
      {
        label: "manual",
        data: totalManualCount,
        borderColor: "#0058ff",
        backgroundColor: "#0058ff",
      },
    ];

    setGenData({ labels, datasets });
  };

  return (
    <Box
      width={{
        xs: "100%",
        md: graphTitle?.toLowerCase()?.includes("total tat") ? "60%" : "50%",
      }}
      sx={{
        padding: "10px",
        ml: {
          xs: "0",
          md: graphTitle?.toLowerCase()?.includes("total tat") ? "20%" : "0",
        },
      }}
    >
      {/* show filters only for selected graphs, TODO:refactor, use a param for conditional render */}
      {graphTitle.toLowerCase().includes("total tat") ||
      graphTitle.toLowerCase().includes("medpay tat") ? (
        <Box textAlign="right">
          <FormControl size="small" sx={{ my: 1.5 }}>
            <InputLabel id="selected-hour-label">Hours</InputLabel>
            <Select
              labelId="selected-hour-label"
              name="selected_hour"
              id="selected_hour"
              value={selectedHour}
              label="Hours"
              onChange={(e: SelectChangeEvent) => {
                setSelectedHour(e.target.value);
              }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="on_time">On Hours</MenuItem>
              <MenuItem value="off_time">Off Hours</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : null}
      <Box
        sx={{
          boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
          padding: "10px",
        }}
      >
        <Box textAlign="right">
          <Tooltip title={tooltipText}>
            <InfoIcon color="primary" fontSize="small" />
          </Tooltip>
        </Box>
        {genData && (
          <LineChart
            title={graphTitle}
            data={genData}
            metricData={metricData}
          />
        )}
      </Box>
    </Box>
  );
};

export default ComparisonMetrics;
