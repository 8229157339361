import config from "./../config";
import axios from "axios";
import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
  appendApiKey,
} from "./../interceptors";

export const instance = axios.create({
  baseURL: `${config.URL}`,
});

instance.interceptors.request.use(appendApiKey);

if (config.ENV === "DEV") {
  instance.interceptors.request.use(onRequest, onRequestError);
  instance.interceptors.response.use(onResponse, onResponseError);
}
