import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../../../../enums/common.enum";
import { ORDER_TYPE } from "../../../../../enums/order.enum";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
} from "../../../../../utils/common";
import CustomDialogTitle from "../../../../Common/customDialogTitle";
import ErrorBoundary from "../../../../Helpers/ErrorBoundary";
import InvoicePayoutModal from "../invoicePayoutModal";
import PaymentDataRow from "./paymentDataRow";
import RefundModal from "./refundModal";
import ThirdPartyModal from "./thirdPartyModal";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PayoutBreakupModal from "./payoutBreakupModal";
import RepeatIcon from "@mui/icons-material/Autorenew";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";

const PaymentStatusModal = ({
  paymentStatusModal,
  setPaymentStatusModal,
  orderData,
  setOrderData,
  activePartner,
  readOnly = false,
}: any) => {
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [thirdPartyModal, setThirdPartyModal] = useState(false);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [invoicePayoutModal, setInvoicePayoutModal] = useState(false);
  // const [autoDelivery, setAutoDelivery] = useState(false);
  const [showPayoutBreakupModal, setShowPayoutBreakupModal] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const refreshThirdPartyStatus = async (orderId: string) => {
    setRefreshLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/status?order_id=${orderId}&payment_type=`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (Object.keys(result.data.response)?.length !== 0) {
          setOrderData(result.data.response);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRefreshLoading(false);
    }
  };

  useEffect(() => {
    if (
      orderData?.order_type === ORDER_TYPE.MEDICINES &&
      activePartner !== "dunzo" &&
      !orderData?.invoice_urls
    ) {
      setShowPaymentForm(false);
    } else {
      setShowPaymentForm(true);
    }
    // if (orderData?.merchant_details) {
    //   if (orderData.merchant_details.process_mode) {
    //     if (orderData.merchant_details.process_mode.delivery_otp === "y") {
    //       setAutoDelivery(true);
    //     } else {
    //       setAutoDelivery(false);
    //     }
    //   } else {
    //     setAutoDelivery(false);
    //   }
    // } else {
    //   setAutoDelivery(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <Dialog
        open={paymentStatusModal}
        onClose={() => setPaymentStatusModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setPaymentStatusModal(false)}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
                Payout -
                {activePartner === "dunzo"
                  ? orderData?.merchant_details?.association_display_name
                  : orderData?.merchant_details?.name}
              </Typography>

              {!readOnly && !orderData?.third_party_payment_credit_status && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ ml: 2 }}
                  onClick={() => setThirdPartyModal(true)}
                >
                  Want to pay to 3rd-party?
                </Button>
              )}

              {!readOnly &&
                orderData?.third_party_payment_credit_status &&
                orderData?.third_party_payment_credit_status ===
                  PAYMENT_CREDIT_STATUS.FAILED && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: 2 }}
                    onClick={() => setThirdPartyModal(true)}
                  >
                    Retry - 3rd-party payment
                  </Button>
                )}
            </Stack>
          </Stack>
        </CustomDialogTitle>
        {!orderData?.invoice_details ? (
          <DialogContent sx={{ padding: 2.5 }}>
            <Typography>No data found</Typography>
          </DialogContent>
        ) : (
          <DialogContent sx={{ padding: 2.5 }}>
            <PaymentDataRow
              dataTitle="Invoice ID"
              value={orderData?.invoice_details?.invoice_id}
            />
            <PaymentDataRow
              dataTitle="Bill Amount"
              value={orderData?.invoice_details?.bill_amount}
            />
            <Stack direction="row" justifyContent="space-between" pt={1}>
              <Typography variant="body1" fontWeight="bold">
                Discount Amount{" "}
                <span style={{ fontSize: "12px", color: "green" }}>
                  ({orderData?.invoice_details?.discount_pct}%)
                </span>
                :
              </Typography>
              <Typography variant="body1">
                &#8377; {orderData?.invoice_details?.discount}
              </Typography>
            </Stack>
            {/* <PaymentDataRow
              dataTitle="Delivery Fees"
              value={orderData?.partner_delivery_charges}
            /> */}
            {/* {activePartner !== "dunzo" ? (
              <PaymentDataRow
                dataTitle="Transaction Amount"
                value={
                  Number(
                    Number(orderData?.invoice_details?.settlement_amount) +
                      (Number(orderData?.invoice_details?.other_charges) || 0)
                  ) || "0.00"
                }
              />
            ) : (
              <PaymentDataRow
                dataTitle="Transaction Amount"
                value={Number(
                  orderData.invoice_details?.settlement_amount &&
                    orderData.invoice_details?.other_charges
                    ? Number(orderData.invoice_details.settlement_amount) +
                        Number(orderData.invoice_details.other_charges)
                    : orderData.invoice_details?.settlement_amount &&
                      !orderData.invoice_details?.other_charges
                    ? Number(orderData.invoice_details?.settlement_amount)
                    : !orderData.invoice_details?.settlement_amount &&
                      orderData.invoice_details?.other_charges
                    ? Number(orderData.invoice_details?.other_charges)
                    : "0.00"
                )}
              />
            )} */}
            <PaymentDataRow
              dataTitle="TDS Amount"
              value={orderData?.invoice_details?.tds_amount}
            />
            <PaymentDataRow
              dataTitle="TCS Amount"
              value={orderData?.invoice_details?.tcs_amount}
            />
            <PaymentDataRow
              dataTitle="Other"
              value={orderData?.invoice_details?.other_charges}
            />
            {orderData?.order_type === ORDER_TYPE.IPD ? (
              <PaymentDataRow
                dataTitle="Patient Payable Amount"
                value={orderData?.patient_payable}
              />
            ) : null}
            {orderData?.invoice_details?.incentives_details ? (
              <Stack direction="row" justifyContent="space-between" pt={1}>
                <Typography variant="body1" fontWeight="bold">
                  Final Payout Amount{" "}
                  <OpenInNewIcon
                    onClick={() => setShowPayoutBreakupModal(true)}
                    sx={{ position: "relative", top: "5px", cursor: "pointer" }}
                    color="primary"
                    fontSize="small"
                  />
                  :
                </Typography>
                <Typography variant="body1">
                  &#8377; {orderData?.invoice_details?.final_payout_amount}
                </Typography>
              </Stack>
            ) : (
              <PaymentDataRow
                dataTitle="Final Payout Amount"
                value={orderData?.invoice_details?.final_payout_amount}
              />
            )}
            <PaymentDataRow
              dataTitle="Payment Type"
              value={orderData?.invoice_details?.payment_type}
            />
            <PaymentDataRow
              dataTitle="Settlement Period"
              value={orderData?.settlement_period}
            />
            <PaymentDataRow
              dataTitle="UPI ID"
              value={orderData?.invoice_details?.upi_id}
            />
            <PaymentDataRow
              dataTitle="Payment Ack No"
              value={orderData?.invoice_details?.payout_ack_no}
            />
            <PaymentDataRow
              dataTitle="Bank Reference (UTR)"
              value={orderData?.invoice_details?.bank_reference}
            />
            <PaymentDataRow
              dataTitle="Payout Initiated Date"
              value={orderData?.invoice_details?.payout_date?.split(".")[0]}
            />
            <PaymentDataRow
              dataTitle="Payout Date"
              value={
                orderData?.invoice_details?.payment_updated_at?.split(".")[0]
              }
            />
            <PaymentDataRow
              dataTitle="Payout Status"
              value={orderData?.payment_credit_status}
            />
            {orderData?.payment_credit_status === "failed" ? (
              <Typography variant="body2" pt={1} color="red">
                <strong>Reason:</strong> {orderData?.credit_failure_reason}
              </Typography>
            ) : null}

            {orderData?.invoice_details?.third_party_payments && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h5" color="primary" mb={1}>
                  Third Party Payments
                </Typography>
                <PaymentDataRow
                  dataTitle="UPI ID"
                  value={
                    orderData?.invoice_details?.third_party_payments?.upi_id
                  }
                />
                <PaymentDataRow
                  dataTitle="Final Amount"
                  value={
                    orderData?.invoice_details?.third_party_payments
                      ?.final_payout_amount
                  }
                />
                <PaymentDataRow
                  dataTitle="Payment Type"
                  value={
                    orderData?.invoice_details?.third_party_payments
                      ?.payment_type
                  }
                />
                <PaymentDataRow
                  dataTitle="Payment Ack No"
                  value={
                    orderData?.invoice_details?.third_party_payments
                      ?.payout_ack_no
                  }
                />
                <PaymentDataRow
                  dataTitle="Bank Reference"
                  value={
                    orderData?.invoice_details?.third_party_payments
                      ?.bank_reference
                  }
                />
                <PaymentDataRow
                  dataTitle="Payment To"
                  value={
                    orderData?.invoice_details?.third_party_payments?.payment_to
                  }
                />
                {orderData?.third_party_payment_credit_status && (
                  <Stack direction="row" justifyContent="space-between" pt={1}>
                    <Typography variant="body1" fontWeight="bold">
                      Payment Status :
                    </Typography>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body1">
                        {orderData.third_party_payment_credit_status}
                      </Typography>
                      <>
                        {refreshLoading ? (
                          <CircularProgress
                            style={{
                              marginLeft: 5,
                            }}
                            size={18}
                            color="primary"
                          />
                        ) : (
                          <Tooltip title="refresh payment status">
                            <span>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  refreshThirdPartyStatus(orderData.order_id);
                                }}
                              >
                                <RepeatIcon
                                  style={{
                                    border: "2px solid",
                                    padding: 2,
                                    borderRadius: 4,
                                    fontSize: 20,
                                  }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </>
                    </Stack>
                  </Stack>
                )}
              </>
            )}

            {orderData?.provider_collection_details && (
              <>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h5" color="primary" mb={1}>
                  Provider Refund
                </Typography>
                <PaymentDataRow
                  dataTitle="Transaction ID"
                  value={orderData?.provider_collection_details?.transaction_id}
                />
                <PaymentDataRow
                  dataTitle="Status"
                  value={orderData?.provider_collection_details?.status}
                />
                <PaymentDataRow
                  dataTitle="Amount"
                  value={orderData?.provider_collection_details?.amount}
                />
                <PaymentDataRow
                  dataTitle="Date & Time"
                  value={String(
                    parseAndFormateDate(
                      orderData?.provider_collection_details?.timestamp
                    )
                  )}
                />
                <PaymentDataRow
                  dataTitle="Link"
                  value={orderData?.provider_collection_details?.link}
                />
                {orderData?.provider_collection_details?.status === "failed" ? (
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 6 }}
                      onClick={() => setShowRefundModal(true)}
                    >
                      Resend Payment Collection Link
                    </Button>
                  </Box>
                ) : null}
              </>
            )}

            {orderData?.invoice_details?.payment_type && (
              <Stack mt={3}>
                {orderData?.invoice_details?.payment_type?.toLowerCase() ===
                  "weekly" && (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Payments settle to your bank account WEEKLY on every
                      Friday, 10am.
                    </Typography>
                  </>
                )}
                {orderData?.invoice_details?.payment_type?.toLowerCase() ===
                  "daily" && (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Payments settle to your bank account DAILY between 07:00AM
                      - 09:00AM.
                    </Typography>
                  </>
                )}
                {orderData?.invoice_details?.payment_type?.toLowerCase() ===
                  "monthly" && (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Payments settle to your bank account on every 28th day of
                      month.
                    </Typography>
                  </>
                )}
                {["upi", "instant"].includes(
                  orderData?.invoice_details?.payment_type?.toLowerCase()
                ) &&
                orderData?.payment_credit_status ===
                  PAYMENT_CREDIT_STATUS.PENDING ? (
                  <>
                    <Typography variant="h4">NOTE:</Typography>
                    <Typography variant="body1">
                      Pharmacy opted for receiving payments after delivering the
                      order on successfull OTP validation. Please wait or write
                      an email on <b>techsupport@medpay.in</b>.
                    </Typography>
                  </>
                ) : null}
              </Stack>
            )}

            {readOnly ? null : (
              <Box sx={{ textAlign: "center" }}>
                {orderData?.payment_credit_status?.toLowerCase() ===
                  PAYMENT_CREDIT_STATUS.FAILED &&
                  orderData?.invoice_details?.payment_type &&
                  (orderData?.invoice_details?.payment_type?.toLowerCase() ===
                    "upi" ||
                    orderData?.invoice_details?.payment_type?.toLowerCase() ===
                      "instant") && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3 }}
                      onClick={() => setInvoicePayoutModal(true)}
                    >
                      Retry
                    </Button>
                  )}

                {orderData?.payment_credit_status?.toLowerCase() ===
                  PAYMENT_CREDIT_STATUS.PENDING &&
                  (orderData?.invoice_details?.payment_type?.toLowerCase() ===
                    "weekly" ||
                    orderData?.invoice_details?.payment_type?.toLowerCase() ===
                      "daily" ||
                    orderData?.invoice_details?.payment_type?.toLowerCase() ===
                      "monthly") && (
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{ mt: 3 }}
                      onClick={() => setInvoicePayoutModal(true)}
                    >
                      Edit
                    </Button>
                  )}

                {!orderData?.provider_collection_details &&
                  (orderData?.payment_credit_status?.toLowerCase() ===
                    PAYMENT_CREDIT_STATUS.PAID ||
                    orderData?.payment_credit_status?.toLowerCase() ===
                      "success") && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3 }}
                      onClick={() => setShowRefundModal(true)}
                    >
                      Payment Collection Link
                    </Button>
                  )}
              </Box>
            )}

            {/* IPD RETRY */}
            {orderData?.order_type === ORDER_TYPE.IPD ? (
              <Box sx={{ textAlign: "center" }}>
                {orderData?.payment_credit_status?.toLowerCase() ===
                  PAYMENT_CREDIT_STATUS.FAILED &&
                  ["neft", "imps", "rtgs"].includes(
                    orderData?.invoice_details?.payment_type?.toLowerCase()
                  ) && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 3 }}
                      onClick={() => setInvoicePayoutModal(true)}
                    >
                      Retry
                    </Button>
                  )}
              </Box>
            ) : null}
          </DialogContent>
        )}
      </Dialog>
      {showRefundModal && (
        <RefundModal
          showRefundModal={showRefundModal}
          setShowRefundModal={setPaymentStatusModal} //close parent on closing child modal
          orderData={orderData}
        />
      )}
      {thirdPartyModal && (
        <ThirdPartyModal
          thirdPartyModal={thirdPartyModal}
          setThirdPartyModal={setPaymentStatusModal}
          orderData={orderData}
        />
      )}
      {invoicePayoutModal && (
        <InvoicePayoutModal
          invoicePayoutModal={invoicePayoutModal}
          setInvoicePayoutModal={setPaymentStatusModal}
          showPaymentForm={showPaymentForm}
          setShowPaymentForm={setShowPaymentForm}
          orderData={orderData}
          setOrderData={setOrderData}
          mode="edit"
        />
      )}
      {showPayoutBreakupModal && (
        <PayoutBreakupModal
          showPayoutBreakupModal={showPayoutBreakupModal}
          setShowPayoutBreakupModal={setShowPayoutBreakupModal}
          data={orderData?.invoice_details}
        />
      )}
    </ErrorBoundary>
  );
};

export default PaymentStatusModal;
