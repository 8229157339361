import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import AddressDetail from "../../components/CreateOrder/addressDetail";
import { QrUpiIdAndNameView } from "../../components/ManageOrder/GroupOrders/Payment/upiPayment";
import { valueLimits } from "../../constants";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { createLocationSchema } from "../../schemas/providers.schema";
import {
  errorToast,
  handleError,
  successToast,
  validateIntNum,
} from "../../utils/common";
import BankDetailsView from "../Dunzo/Providers/bankDetailsView";

const AddNewLocationModal = ({
  addNewLocation,
  setAddNewLocation,
  providersData,
  setProvidersData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [qrData, setQrData] = useState<any>({});
  const privateInstance = useAxiosInstance();

  const handleSubmit = async (values: any, actions: any) => {
    const {
      provider_name,
      mobile_number,
      address,
      landmark,
      city,
      state,
      pin,
      account_type,
      upi_id,
      account_name,
      account_no,
      bank_name,
      ifsc_code,
    } = values || {};
    let payload: any = {
      provider_name: provider_name || "",
      mobile_number: mobile_number || "",
      address: {
        address: address || "",
        city: city || "",
        locality: landmark || "",
        state: state || "",
        pin_code: pin || "",
      },
    };

    if (account_type) {
      if (account_type === "upi") {
        payload = {
          ...payload,
          account_details: {
            upi_id: upi_id || "",
          },
        };
      } else {
        payload = {
          ...payload,
          account_details: {
            bank_name: bank_name || "",
            account_no: account_no || "",
            ifsc_code: ifsc_code || "",
            account_name: account_name || "",
          },
        };
      }
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/manage/locations`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Created Successfully");
        let response = result.data.response || {};
        //update providers list
        let updatedData = [...providersData];
        let createdProvider = {
          provider_name: response.provider_name,
          location_id: response.location_id,
          formatted_address: `${response.address?.address}, ${response.address?.locality}, ${response.address?.city}, ${response.address?.state} - ${response.address?.pin_code}`,
          whatsapp_number: payload.mobile_number || "",
          provider_type: "Pharmacy",
          orders_fulfilled: 0,
          last_order_processed_at: "--",
          status: "active",
        };
        updatedData.push(createdProvider);
        setProvidersData(updatedData);
        setAddNewLocation(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={addNewLocation}
      onClose={() => setAddNewLocation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setAddNewLocation(false)}
      >
        Add New Pharmacy
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            provider_name: "",
            mobile_number: "",
            address: "",
            city: "",
            landmark: "",
            state: "",
            pin: "",
            account_type: "",
            upi_id: "",
            upi_name: "",
            account_name: "",
            account_no: "",
            bank_name: "",
            branch_name: "",
            ifsc_code: "",
          }}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
          validationSchema={createLocationSchema}
        >
          {(props: any) => {
            const {
              values,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              setTouched,
            } = props;
            return (
              <Form>
                <>
                  <Stack mb={2}>
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        id="provider_name"
                        label="Provider Name"
                        size="small"
                        value={values.provider_name}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 0.5 } }}
                        InputLabelProps={{
                          required: true,
                        }}
                        onChange={(e) =>
                          setFieldValue("provider_name", e.target.value)
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.provider_name && touched.provider_name
                            ? errors.provider_name
                            : ""
                        }
                        error={
                          errors.provider_name && touched.provider_name
                            ? true
                            : false
                        }
                      />
                      <TextField
                        fullWidth
                        id="mobile_number"
                        label="Mobile No"
                        size="small"
                        value={values.mobile_number || ""}
                        sx={{ mt: 1.5, ml: { xs: 0, md: 0.5 } }}
                        InputLabelProps={{
                          required: true,
                        }}
                        onChange={(e) =>
                          setFieldValue(
                            "mobile_number",
                            validateIntNum(e.target.value, valueLimits.MOBILE)
                          )
                        }
                        onBlur={handleBlur}
                        helperText={
                          errors.mobile_number && touched.mobile_number
                            ? errors.mobile_number
                            : ""
                        }
                        error={
                          errors.mobile_number && touched.mobile_number
                            ? true
                            : false
                        }
                      />
                    </Stack>
                    <AddressDetail
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      formData={values}
                      setTouched={setTouched}
                      serviceabilityCheck={false}
                    />
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5 }}
                      error={
                        errors.account_type && touched.account_type
                          ? true
                          : false
                      }
                    >
                      <InputLabel id="account-type-label">
                        Account Type
                      </InputLabel>
                      <Select
                        labelId="account-type-label"
                        name="account_type"
                        id="account_type"
                        value={values.account_type}
                        label="Account Type"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("account_type", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="upi">UPI</MenuItem>
                        <MenuItem value="bank_acc">BANK ACCOUNT</MenuItem>
                      </Select>
                      {errors.account_type && touched.account_type && (
                        <FormHelperText>{errors.account_type}</FormHelperText>
                      )}
                    </FormControl>
                    {values?.account_type === "upi" ? (
                      <Box mt={1.5}>
                        <QrUpiIdAndNameView
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          values={values}
                          qrData={qrData}
                          setQrData={setQrData}
                        />
                      </Box>
                    ) : null}
                    {values.account_type === "bank_acc" ? (
                      <BankDetailsView
                        values={values}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        mode="add"
                      />
                    ) : null}
                  </Stack>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    sx={{ mr: 2 }}
                  >
                    Submit
                  </LoadingButton>
                </>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewLocationModal;
