import { Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { valueLimits } from "../../../../constants";
import { validateFloatNum } from "../../../../utils/common";

const DiscountPercent = ({
  total,
  discount,
  final_payable,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}: any) => {
  useEffect(() => {
    if (discount > 0) {
      setFieldValue("final_payable", total - (total * discount) / 100);
    } else {
      setFieldValue("final_payable", total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  return (
    <Stack direction={{ xs: "column", md: "row" }}>
      <TextField
        fullWidth
        id="discount"
        label="Discount (%)"
        size="small"
        value={discount}
        onChange={(e: any) => {
          const transformedPercentInput = validateFloatNum(
            e.target.value,
            valueLimits.PERCENT
          );
          setFieldValue("discount", transformedPercentInput);
          setFieldValue(
            "final_payable",
            transformedPercentInput === ""
              ? total
              : total - (total * transformedPercentInput) / 100 <= 0
              ? String(0)
              : total - (total * transformedPercentInput) / 100
          );
        }}
        onBlur={handleBlur}
        helperText={errors.discount && touched.discount ? errors.discount : ""}
        error={errors.discount && touched.discount ? true : false}
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        InputLabelProps={{
          required: true,
        }}
      />
      <TextField
        fullWidth
        id="final_payable"
        label="Final payable amount"
        size="small"
        value={isNaN(final_payable) ? "" : Number(final_payable).toFixed(2)}
        disabled
        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
      />
    </Stack>
  );
};

export default DiscountPercent;
