import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessModal = ({ open, onClose }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  let qData: any = new URLSearchParams(location.search).get("data");

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          navigate(qData ? `/search?data=${qData}` : `/search`);
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box mt={1} textAlign="center">
            <CheckCircleIcon color="success" fontSize="large" />
            <Typography fontSize={14}>
              Thank you for submitting your request, our team will get in touch
              with you shortly.
            </Typography>

            <Button
              sx={{ mt: 3 }}
              onClick={() =>
                navigate(qData ? `/search?data=${qData}` : `/search`)
              }
              variant="contained"
              color="primary"
              size="small"
            >
              Done
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SuccessModal;
