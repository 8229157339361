import * as yup from "yup";
import { CONFIRMATION_TYPE, PAYMENT_OPTIONS } from "../enums/order.enum";

export const transferUPISchema = yup.object().shape({
  upi_id: yup.string().required("UPI ID is required"),
  upi_name: yup.string().required("UPI name is required"),
  discount: yup.string().required("Discount percent is required"),
});

export const transferBankSchema = yup.object().shape({
  discount: yup.string().required("Discount percent is required"),
});

export const ipdPaymentSchema = yup.object().shape({
  otp: yup.number().required("OTP is required"),
  account_name: yup.string().required("Account Name is required"),
  bank_name: yup.string().required("Bank Name is required"),
  account_no: yup.string().required("Account Number is required"),
  ifsc_code: yup
    .string()
    .min(11, "Please enter 11-digit IFSC code")
    .max(11, "Please enter 11-digit IFSC code")
    .required("IFSC Code is required"),
});

export const refundSchema = (maxVal: any) =>
  yup.object().shape({
    amount: yup
      .number()
      .max(maxVal, "Amount cannot be more than " + maxVal + "")
      .required("Amount is required"),
    mobile: yup
      .number()
      .typeError("must be a number")
      .min(6000000000, "must be greater than or equal to 6000000000")
      .required("Mobile is required"),
  });

export const paymentLinkSchema = yup.object().shape({
  amount: yup
    .number()
    .min(0.01, "Amount cannot be 0")
    .required("Amount is required"),
  mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Mobile is required"),
});

export const thirdPartySchema = yup.object().shape({
  payment_to: yup.string().required("Payment to is required"),
  delivery_charges: yup.number().required("Amount is required"),
  service_partner: yup.string().when("payment_to", {
    is: (payment_to: any) => payment_to?.toLowerCase() === "delivery service",
    then: yup.string().required("Service partner is required"),
  }),
});

export const confirmPaymentSchema = yup.object({
  payment_type: yup.string().required("Payment type is required"),
  voucher_code: yup.string().when("payment_type", {
    is: (payment_type: any) =>
      payment_type?.toLowerCase() === PAYMENT_OPTIONS.VOUCHER,
    then: yup.string().required("Voucher number is required"),
  }),
  pay_by_partner: yup.number().when("confirmation_type", {
    is: (confirmation_type: any) =>
      confirmation_type === CONFIRMATION_TYPE.PARTIAL,
    then: yup.number().required("Payer payable is required"),
  }),
  due_on_customer: yup
    .number()
    .min(1, "Cannot be 0")
    .when("confirmation_type", {
      is: (confirmation_type: any) =>
        confirmation_type === CONFIRMATION_TYPE.PARTIAL,
      then: yup.number().required("Patient payable is required"),
    }),
});

export const generalPaymentSchema = yup.object().shape({
  payment_to: yup.string().required("Payment to is required"),
  payment_mode: yup.string().required("Payment mode is required"),
  amount: yup.number().required("Amount is required"),
  otp: yup.number().required("OTP is required"),
  provider_id: yup.string().when("payment_to", {
    is: (payment_to: string) =>
      payment_to?.toLowerCase() === "provider" ||
      payment_to?.toLowerCase() === "patient" ||
      payment_to?.toLowerCase() === "ipd",
    then: yup.string().nullable().required("Partner is required"),
  }),
  partner_order_id: yup.string().when("payment_to", {
    is: (payment_to: string) =>
      payment_to?.toLowerCase() === "provider" ||
      payment_to?.toLowerCase() === "patient" ||
      payment_to?.toLowerCase() === "ipd",
    then: yup
      .string()
      .matches(
        "^[a-zA-Z0-9_.-]+$" as any,
        "Only alphanumeric, underscore(_) , dot(.) , hyphen(-) allowed"
      )
      .required("Partner Order ID is required"),
  }),
  whatsapp_number: yup.string().when(["payment_to", "payment_mode"], {
    is: (payment_to: string, payment_mode: string) =>
      (payment_to?.toLowerCase() === "provider" ||
        payment_to?.toLowerCase() === "patient") &&
      payment_mode !== "UPI",
    then: yup
      .string()
      .min(10, "must be a 10-digit number")
      .required("Mobile is required"),
  }),
  account_no: yup.string().when(["payment_to", "payment_mode"], {
    is: (payment_to: string, payment_mode: string) =>
      (payment_to?.toLowerCase() === "provider" ||
        payment_to?.toLowerCase() === "patient" ||
        payment_to?.toLowerCase() === "ipd") &&
      payment_mode !== "UPI",
    then: yup.string().required("Account No. is required"),
  }),
  account_name: yup.string().when(["payment_to", "payment_mode"], {
    is: (payment_to: string, payment_mode: string) =>
      (payment_to?.toLowerCase() === "provider" ||
        payment_to?.toLowerCase() === "patient" ||
        payment_to?.toLowerCase() === "ipd") &&
      payment_mode !== "UPI",
    then: yup.string().required("Account Name is required"),
  }),
  ifsc_code: yup.string().when(["payment_to", "payment_mode"], {
    is: (payment_to: string, payment_mode: string) =>
      (payment_to?.toLowerCase() === "provider" ||
        payment_to?.toLowerCase() === "patient" ||
        payment_to?.toLowerCase() === "ipd") &&
      payment_mode !== "UPI",
    then: yup
      .string()
      .min(11, "Please enter 11-digit IFSC code")
      .max(11, "Please enter 11-digit IFSC code")
      .required("IFSC code is required"),
  }),
  account_holder_name: yup.string().when(["payment_to", "payment_mode"], {
    is: (payment_to: string, payment_mode: string) =>
      (payment_to?.toLowerCase() === "provider" ||
        payment_to?.toLowerCase() === "patient") &&
      payment_mode === "UPI",
    then: yup.string().required("UPI Verification is required"),
  }),
});
