import * as yup from "yup";

export const updateDiscountsSchema = yup.object().shape({
  medicines: yup.string().nullable().required("Medicine % is required"),
  fmcg: yup.string().nullable().required("FMCG % is required"),
});

export const updateUPIPaymentDetailsSchema = yup.object().shape({
  upi_id: yup.string().required("UPI ID is required"),
  upi_name: yup.string().required("UPI Name is required"),
});

export const updateOtherPaymentDetailsSchema = yup.object().shape({
  account_name: yup.string().required("Account name is required"),
  account_no: yup.string().required("Account no. is required"),
  bank_name: yup.string().required("Bank name is required"),
  branch_name: yup.string().required("Branch name is required"),
  ifsc_code: yup
    .string()
    .min(11, "Please enter 11-digit IFSC code")
    .max(11, "Please enter 11-digit IFSC code")
    .required("IFSC code is required"),
});
