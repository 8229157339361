import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { ORDER_TYPE } from "../../../../enums/order.enum";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import CustomDialogTitle from "../../../Common/customDialogTitle";
import UploadFiles from "../../../Common/uploadFiles";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import TabPanel from "../../../UI/tabPanel";
import UploadConsultationBill from "./uploadConsultationBill";

const UploadRxModal = ({
  uploadRxModal,
  setUploadRxModal,
  activeOrderId,
  activePartnerOrderId,
  activePartner,
  orderType,
}: any) => {
  const [isUploadPrescription, setIsUploadPrescription] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const [tabValue, setTabValue] = useState(0);
  const [reportsPending, setReportsPending] = useState<boolean>(false);

  const handleSubmit = async (submitType: string) => {
    let payload: any = {
      order_id: activeOrderId,
      type:
        submitType === "fulfill"
          ? "fulfilled"
          : `${
              orderType === ORDER_TYPE.CONSULTATION
                ? "consultation-prescription-upload"
                : "diagnostics-report-update"
            }`,
    };

    if (submitType === "link") {
      if (orderType === ORDER_TYPE.DIAGNOSTICS) {
        payload = {
          ...payload,
          diagnostics_reports_links: uploadedFiles,
          reports_pending: reportsPending,
        };
      } else {
        payload = { ...payload, prescription_links: uploadedFiles };
      }
    }

    setLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/update-order`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(result.data.message);
        setUploadRxModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const getOrderByPartnerOrderId = async () => {
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/partner/${String(
          activePartner
        )?.toLowerCase()}/${activePartnerOrderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (
          result.data.response?.diagnostics_reports_links &&
          result.data.response?.diagnostics_reports_links?.length > 0
        ) {
          setUploadedFiles(result.data.response.diagnostics_reports_links);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    }
  };

  // to get the previously uploaded diagnostics reports
  useEffect(() => {
    if (
      activePartner &&
      activePartnerOrderId &&
      orderType === ORDER_TYPE.DIAGNOSTICS
    ) {
      getOrderByPartnerOrderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={uploadRxModal}
      onClose={() => setUploadRxModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="custom-dialog-title"
        onClose={() => setUploadRxModal(false)}
      >
        Partner Id: {activePartnerOrderId}
      </CustomDialogTitle>
      <DialogContent>
        <Stack direction="column" alignItems="center">
          <FormControl sx={{ mb: 1.5 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Do you want to upload{" "}
              {orderType === ORDER_TYPE.CONSULTATION
                ? "prescriptions/bills"
                : "reports"}
              ?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setIsUploadPrescription(e.target.value)}
            >
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
          {isUploadPrescription === "yes" && (
            <>
              <Tabs
                value={tabValue}
                onChange={(event: React.SyntheticEvent, newValue: number) => {
                  setTabValue(newValue);
                }}
                centered
              >
                <Tab label="Upload Rx" />
                {/* PARTNER SPECIFIC: VHEALTH */}
                {orderType === ORDER_TYPE.CONSULTATION &&
                activePartner === "vhealth by aetna" ? (
                  <Tab label="Upload Bills" />
                ) : null}
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Stack alignItems="center">
                  {orderType === ORDER_TYPE.DIAGNOSTICS ? (
                    <FormControlLabel
                      sx={{ width: "100%", mb: 1.5 }}
                      control={
                        <Checkbox
                          checked={reportsPending}
                          onChange={(e) => {
                            setReportsPending(e.target.checked);
                          }}
                        />
                      }
                      label="Reports Pending"
                    />
                  ) : null}
                  <UploadFiles
                    title=""
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    orderId={activeOrderId}
                    uploadType={
                      orderType === ORDER_TYPE.CONSULTATION
                        ? "consultation_rx"
                        : "diagnostics_reports"
                    }
                  />
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    loading={loading}
                    disabled={uploadedFiles.length === 0}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    onClick={() => handleSubmit("link")}
                    sx={{ mt: 3 }}
                  >
                    Link{" "}
                    {orderType === ORDER_TYPE.CONSULTATION
                      ? "prescriptions"
                      : "reports"}
                  </LoadingButton>
                </Stack>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <UploadConsultationBill
                  activeOrderId={activeOrderId}
                  activePartnerOrderId={activePartnerOrderId}
                  activePartner={activePartner}
                />
              </TabPanel>
            </>
          )}

          {isUploadPrescription === "no" && (
            <LoadingButton
              color="error"
              variant="contained"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={() => handleSubmit("fulfill")}
              sx={{ mt: 3 }}
            >
              Mark as Fulfilled
            </LoadingButton>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default UploadRxModal;
