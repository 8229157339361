import {
  Box,
  FormControl,
  Stack,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
} from "../../../../utils/common";
import { Form, Formik } from "formik";
import DiscountPercent from "../Common/discountPercent";
import { LoadingButton } from "@mui/lab";
import { transferBankSchema } from "../../../../schemas/payment";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";

function isInvoiceAndBilAmountDifferent(orderData: any) {
  return (
    orderData?.invoice_details &&
    (orderData?.invoice_details.pharmacy_bill_amount -
      orderData?.invoice_details.pharmacy_bill_amount * 0.01 >
      orderData?.invoice_details.bill_amount ||
      orderData?.invoice_details.bill_amount -
        orderData?.invoice_details.bill_amount * 0.01 >
        orderData?.invoice_details.pharmacy_bill_amount)
  );
}

const OfflinePayment = ({
  orderData,
  lat,
  long,
  settlementMode,
  setInvoicePayoutModal,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      order_id: orderData?.order_id,
      payment_type: settlementMode?.toUpperCase(),
      provider_id: orderData?.merchant_details?.provider_id,
      location_id: orderData?.merchant_details?.location_id,
      discount_pct: values?.discount.toString(),
      amount: values.final_payable.toString(),
      location: `${lat},${long}`,
      notes: values?.notes,
      other_charges: values?.other_charges || "0",
      offline_paid: "y",
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/transfer`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("offline payment updated successfully");
        setInvoicePayoutModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          bill_amount: orderData?.invoice_details?.bill_amount || 0,
          pharmacy_bill_amount:
            orderData?.invoice_details?.pharmacy_bill_amount || 0,
          discount: orderData?.invoice_details?.discount_pct || 0,
          final_payable: 0,
          other_charges: 0,
          notes: "",
        }}
        validationSchema={transferBankSchema}
        onSubmit={(values: any, actions: any) => {
          setLoading(true);
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
          } = props;
          return (
            <Form>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="bill_amount"
                  label="BILL AMOUNT"
                  size="small"
                  value={values.bill_amount}
                  sx={{
                    mt: 1.5,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: isInvoiceAndBilAmountDifferent(
                        orderData
                      )
                        ? "#c53030"
                        : "",
                    },
                    mr: { xs: 0, md: 1 },
                  }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="pharmacy_bill_amount"
                  label="PHARMACY INVOICE AMOUNT"
                  size="small"
                  value={values.pharmacy_bill_amount}
                  sx={{
                    mt: 1.5,
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: isInvoiceAndBilAmountDifferent(
                        orderData
                      )
                        ? "#c53030"
                        : "",
                    },
                    mr: { xs: 0, md: 1 },
                  }}
                  disabled
                />
              </Box>
              {isInvoiceAndBilAmountDifferent(orderData) ? (
                <p
                  style={{
                    fontSize: "12px",
                    color: "red",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                >
                  Note: Invoice amount does not match pharmacy bill amount.
                  Please confirm before making payment.
                </p>
              ) : null}
              <Box sx={{ mt: 1.5 }} display="flex">
                <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                  <DiscountPercent
                    discount={values.discount}
                    final_payable={values.final_payable}
                    total={values.bill_amount}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="discount_value"
                  label="DISCOUNT"
                  size="small"
                  value={Number(
                    values.bill_amount - values.final_payable
                  ).toFixed(2)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
                <Stack width="100%">
                  <TextField
                    fullWidth
                    id="other_charges"
                    label="Other charges"
                    size="small"
                    value={values.other_charges}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e) =>
                      setFieldValue("other_charges", e.target.value)
                    }
                  />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Note: Please don’t use other charges for delivery fee
                  </p>
                </Stack>
              </Box>

              <FormControl fullWidth sx={{ mt: 3 }}>
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  rows={3}
                  variant="outlined"
                  value={values.notes}
                  onChange={(e) => setFieldValue("notes", e.target.value)}
                  fullWidth
                />
              </FormControl>

              <Stack mt={3}>
                <Typography variant="h4">NOTE:</Typography>
                <Typography variant="body1">
                  Please use PAID OFFLINE, if payment has been done offline.
                </Typography>
              </Stack>

              <Box sx={{ mt: 3, textAlign: "center" }}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  disabled={isSubmitting}
                >
                  Confirm payment
                </LoadingButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OfflinePayment;
