import {
  Box,
  Button,
  FormControl,
  Link,
  Stack,
  TextField,
  CircularProgress,
  InputLabel,
  SelectChangeEvent,
  MenuItem,
  Select,
  FormHelperText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  transformMobile,
} from "../../../../utils/common";
import { Form, Formik } from "formik";
import DiscountPercent from "../Common/discountPercent";
import { ORDER_STATUS, ORDER_TYPE } from "../../../../enums/order.enum";
import FindUpiQR from "../Common/findUpiByQR";
import { LoadingButton } from "@mui/lab";
import { transferUPISchema } from "../../../../schemas/payment";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import WhitelistUpiModal from "./whitelistUpiModal";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import PharmacyBills from "../../../OrderDetails/pharmacyBills";

function isInvoiceAndBilAmountDifferent(orderData: any) {
  return (
    orderData?.invoice_details &&
    (orderData?.invoice_details.pharmacy_bill_amount -
      orderData?.invoice_details.pharmacy_bill_amount * 0.01 >
      orderData?.invoice_details.bill_amount ||
      orderData?.invoice_details.bill_amount -
        orderData?.invoice_details.bill_amount * 0.01 >
        orderData?.invoice_details.pharmacy_bill_amount)
  );
}

export const QrUpiIdAndNameView = ({
  setFieldValue,
  errors,
  touched,
  handleBlur,
  values,
  qrData,
  setQrData,
}: any) => {
  const [verifyLoading, setVerifyLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const findUpiByUpiId = async (val: string, setFieldValue: any) => {
    setVerifyLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/verify-upi?upi_id=${val}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        // console.log("resss", result.data.response);
        setQrData(result.data.response);
        setFieldValue("upi_name", result.data.response.account_holder_name);
      } else {
        errorToast(result.data.message);
        setQrData({
          ...qrData,
          account_holder_name: "",
        });
        setFieldValue("upi_name", "");
      }
    } catch (err) {
      handleError(err);
    } finally {
      setVerifyLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <FindUpiQR
          qrData={qrData}
          setQrData={setQrData}
          setFieldValue={setFieldValue}
        />
      </Box>
      <Stack direction="row" alignItems="start" sx={{ mt: 3 }}>
        <Stack
          direction="row"
          alignItems="start"
          justifyContent="space-between"
          sx={{ mr: { xs: 0, md: 1 }, width: "100%" }}
        >
          <TextField
            fullWidth
            id="upi_id"
            label="UPI ID"
            size="small"
            value={values?.upi_id || ""}
            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
            onChange={(e) => {
              setFieldValue("upi_id", e.target.value);
              setFieldValue("upi_name", "");
            }}
            onBlur={handleBlur}
            helperText={errors.upi_id && touched.upi_id ? errors.upi_id : ""}
            error={errors.upi_id && touched.upi_id ? true : false}
            InputLabelProps={{
              required: true,
            }}
          />
          <LoadingButton
            sx={{ width: "180px", mt: 1.5 }}
            size="medium"
            color="primary"
            loading={verifyLoading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
            variant="contained"
            type="button"
            onClick={() => findUpiByUpiId(values?.upi_id, setFieldValue)}
            disabled={!values?.upi_id}
          >
            Verify
          </LoadingButton>
        </Stack>

        <TextField
          fullWidth
          id="upi_name"
          label="UPI NAME"
          size="small"
          value={values?.upi_name}
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
          disabled
          InputLabelProps={{
            required: true,
          }}
          helperText={
            errors.upi_name && touched.upi_name ? errors.upi_name : ""
          }
          error={errors.upi_name && touched.upi_name ? true : false}
        />
      </Stack>
    </>
  );
};

const UpiPayment = ({
  orderData,
  lat,
  long,
  uploadedFiles,
  setInvoicePayoutModal,
  upiDetails,
  qrActive,
}: any) => {
  const [qrData, setQrData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [whitelistUpiModal, setWhitelistUpiModal] = useState(false);
  const [upiToWhitelist, setUpiToWhitelist] = useState("");
  const [showNewUpiView, setShowNewUpiView] = useState(false);
  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);

  const handleSubmit = async (values: any, actions: any) => {
    let payload = {
      order_id: orderData?.order_id,
      payment_type: "UPI",
      provider_id: orderData?.merchant_details?.provider_id,
      location_id: orderData?.merchant_details?.location_id,
      discount_pct: values?.discount.toString(),
      upi_id: values?.upi_id,
      amount: values.final_payable.toString(),
      location: `${lat},${long}`,
      notes: values?.notes,
      other_charges: values?.other_charges || "0",
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/transfer`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Payment initiated");
        setInvoicePayoutModal(false);
        //update
        dispatch(
          setAllOrderData(
            transformAllOrder(
              ordersToManage,
              payload.order_id,
              result.data.response
            )
          )
        );
      } else {
        if (result.data.response.todo) {
          setUpiToWhitelist(values?.upi_id);
          setWhitelistUpiModal(true);
        } else {
          errorToast(result.data.message);
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {qrActive ? (
        <>
          <Typography color="red">
            <strong>Note:</strong> This pharmacy actively uses MedPay QR.
            Payments for home delivery and walk-in transactions are combined and
            credited automatically on the next day.
          </Typography>
          <Typography variant="body2" pt={1}>
            Thus, please initiate the payment through Daily Payment mode.
            Pharmacy may also check the settlement status on the MedPay app.
          </Typography>
        </>
      ) : (
        <>
          <Formik
            initialValues={{
              upi_id:
                qrData?.upi_id || orderData?.invoice_details?.upi_id || "",
              upi_name:
                qrData?.upi_name || orderData?.invoice_details?.upi_name || "",
              bill_amount: orderData?.invoice_details?.bill_amount || 0,
              pharmacy_bill_amount:
                orderData?.invoice_details?.pharmacy_bill_amount || 0,
              discount: orderData?.invoice_details?.discount_pct || 0,
              final_payable: 0,
              other_charges: 0,
              notes: "",
              mobile_number:
                orderData?.order_type === ORDER_TYPE.MEDICINES
                  ? transformMobile(orderData?.merchant_details?.mobile)
                  : "",
              gst_no:
                orderData?.order_type === ORDER_TYPE.MEDICINES
                  ? orderData?.merchant_details?.gst_no &&
                    orderData?.merchant_details?.gst_no !== "0"
                    ? orderData?.merchant_details?.gst_no
                    : ""
                  : "",
            }}
            validationSchema={transferUPISchema}
            onSubmit={(values: any, actions: any) => {
              setLoading(true);
              actions.setSubmitting(false);
              handleSubmit(values, actions);
            }}
          >
            {(props: any) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                isSubmitting,
                setFieldValue,
              } = props;
              return (
                <Form>
                  {upiDetails && upiDetails?.length > 0 ? (
                    <>
                      {showNewUpiView ? (
                        <>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{ my: 1.5, mr: { xs: 0, md: 1 } }}
                          >
                            <InputLabel required id="upi-type-label">
                              UPI Type
                            </InputLabel>
                            <Select
                              labelId="upi-type-label"
                              name="upi_type"
                              id="upi_type"
                              value="new"
                              label="UPI Type"
                              onChange={(e: SelectChangeEvent) => {
                                setFieldValue("upi_id", e.target.value);
                                const upiObject = upiDetails.find((x: any) => {
                                  return x?.upi_id === e.target.value;
                                });
                                if (upiObject?.upi_name) {
                                  setFieldValue(
                                    "upi_name",
                                    upiObject?.upi_name
                                  );
                                } else {
                                  setShowNewUpiView(true);
                                }
                                setShowNewUpiView(false);
                              }}
                            >
                              <MenuItem value="new">New UPI ID</MenuItem>
                              {upiDetails &&
                                upiDetails
                                  .filter(
                                    (upiItem: any) => upiItem?.upi_id !== ""
                                  )
                                  .map((x: any) => {
                                    return (
                                      <MenuItem key={x.upi_id} value={x.upi_id}>
                                        {x.upi_id}
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                          </FormControl>
                          <QrUpiIdAndNameView
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            values={values}
                            qrData={qrData}
                            setQrData={setQrData}
                          />
                        </>
                      ) : (
                        <Stack direction="row">
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                            error={
                              errors.upi_id && touched.upi_id ? true : false
                            }
                          >
                            <InputLabel required id="upi-id-label">
                              UPI ID
                            </InputLabel>
                            <Select
                              labelId="upi-id-label"
                              name="upi_id"
                              id="upi_id"
                              value={values?.upi_id}
                              label="UPI ID"
                              onChange={(e: SelectChangeEvent) => {
                                setFieldValue("upi_id", e.target.value);
                                const upiObject = upiDetails.find((x: any) => {
                                  return x?.upi_id === e.target.value;
                                });
                                if (upiObject?.upi_name) {
                                  setFieldValue(
                                    "upi_name",
                                    upiObject?.upi_name
                                  );
                                } else {
                                  setFieldValue("upi_id", "");
                                  setFieldValue("upi_name", "");
                                  setShowNewUpiView(true);
                                }
                              }}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="new">New UPI ID</MenuItem>
                              {upiDetails &&
                                upiDetails
                                  .filter(
                                    (upiItem: any) => upiItem?.upi_id !== ""
                                  )
                                  .map((x: any) => {
                                    return (
                                      <MenuItem key={x.upi_id} value={x.upi_id}>
                                        {x.upi_id}
                                      </MenuItem>
                                    );
                                  })}
                            </Select>
                            {errors.upi_id && touched.upi_id && (
                              <FormHelperText>{errors.upi_id}</FormHelperText>
                            )}
                          </FormControl>
                          <TextField
                            fullWidth
                            id="upi_name"
                            label="UPI NAME"
                            size="small"
                            value={values?.upi_name}
                            sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                            disabled
                            InputLabelProps={{
                              required: true,
                            }}
                            helperText={
                              errors.upi_name && touched.upi_name
                                ? errors.upi_name
                                : ""
                            }
                            error={
                              errors.upi_name && touched.upi_name ? true : false
                            }
                          />
                        </Stack>
                      )}
                    </>
                  ) : (
                    <QrUpiIdAndNameView
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      values={values}
                      qrData={qrData}
                      setQrData={setQrData}
                    />
                  )}
                  <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      id="bill_amount"
                      label="BILL AMOUNT"
                      size="small"
                      value={values.bill_amount}
                      sx={{
                        mt: 1.5,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: isInvoiceAndBilAmountDifferent(
                            orderData
                          )
                            ? "#c53030"
                            : "",
                        },
                        mr: { xs: 0, md: 1 },
                      }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      id="pharmacy_bill_amount"
                      label="PHARMACY INVOICE AMOUNT"
                      size="small"
                      value={values.pharmacy_bill_amount}
                      sx={{
                        mt: 1.5,
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: isInvoiceAndBilAmountDifferent(
                            orderData
                          )
                            ? "#c53030"
                            : "",
                        },
                        mr: { xs: 0, md: 1 },
                      }}
                      disabled
                    />
                  </Box>
                  {isInvoiceAndBilAmountDifferent(orderData) ? (
                    <p
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      Note: Invoice amount does not match pharmacy bill amount.
                      Please confirm before making payment.
                    </p>
                  ) : null}
                  <Box sx={{ mt: 1.5 }} display="flex">
                    <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                      <DiscountPercent
                        discount={values.discount}
                        final_payable={values.final_payable}
                        total={values.bill_amount}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      id="discount_value"
                      label="DISCOUNT"
                      size="small"
                      value={Number(
                        values.bill_amount - values.final_payable
                      ).toFixed(2)}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                    <Stack width="100%">
                      <TextField
                        fullWidth
                        id="other_charges"
                        label="Other charges"
                        size="small"
                        value={values.other_charges}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        onChange={(e) =>
                          setFieldValue("other_charges", e.target.value)
                        }
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "red",
                          marginTop: 0,
                          marginBottom: 0,
                        }}
                      >
                        Note: Please don’t use other charges for delivery fee
                      </p>
                    </Stack>
                  </Box>
                  {orderData?.order_partner !== "dunzo" &&
                    orderData?.order_type === ORDER_TYPE.MEDICINES && (
                      <Box
                        sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}
                      >
                        <TextField
                          fullWidth
                          id="mobile_number"
                          label="Mobile No"
                          size="small"
                          value={values.mobile_number || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          onChange={(e: any) =>
                            setFieldValue("mobile_number", e.target.value)
                          }
                        />
                        <TextField
                          fullWidth
                          id="gst_no"
                          label="GST No"
                          size="small"
                          value={values.gst_no || ""}
                          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                          disabled
                        />
                      </Box>
                    )}
                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                      id="notes"
                      label="Notes"
                      multiline
                      rows={3}
                      variant="outlined"
                      value={values.notes}
                      onChange={(e) => setFieldValue("notes", e.target.value)}
                      fullWidth
                    />
                  </FormControl>
                  {orderData?.merchant_details?.process_mode?.delivery_otp ===
                    "y" &&
                  orderData?.order_status !== ORDER_STATUS.PACKAGE_DELIVERED ? (
                    <Stack mt={3}>
                      <Typography variant="h4">NOTE:</Typography>
                      <Typography variant="body1">
                        Pharmacy opted for receiving payments after delivering
                        the order on successfull OTP validation. Please wait or
                        write an email on <b>techsupport@medpay.in</b>.
                      </Typography>
                    </Stack>
                  ) : null}

                  <Box sx={{ mt: 3, textAlign: "center" }}>
                    {(orderData?.invoice_details || uploadedFiles[0]) && (
                      <>
                        <PharmacyBills orderData={orderData} />
                        <Link
                          target="_blank"
                          href={
                            orderData.invoice_details
                              ? orderData.invoice_details.invoice_link
                              : uploadedFiles[0]
                          }
                          sx={{ textDecoration: "none" }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mr: 1 }}
                          >
                            View Invoice
                          </Button>
                        </Link>
                      </>
                    )}
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={loading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      disabled={isSubmitting}
                    >
                      Confirm payment
                    </LoadingButton>
                    {/* {TO_DO: Price difference of 10%} */}
                  </Box>
                </Form>
              );
            }}
          </Formik>
          {whitelistUpiModal && (
            <WhitelistUpiModal
              whitelistUpiModal={whitelistUpiModal}
              setWhitelistUpiModal={setWhitelistUpiModal}
              orderData={orderData}
              upiToWhitelist={upiToWhitelist}
            />
          )}
        </>
      )}
    </>
  );
};

export default UpiPayment;
