import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { valueLimits } from "../../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../../enums/common.enum";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import { thirdPartySchema } from "../../../../../schemas/payment";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
} from "../../../../../utils/common";
import CustomDialogTitle from "../../../../Common/customDialogTitle";
import FindUpiPhone from "../../Common/findUpiByPhone";
import VerifyUpi from "../../Common/verifyUpi";

const thirdPartyOptions = ["Delivery Service", "Provider", "Refund"];

const deliveryServiceOptions = [
  "Swiggy Genie",
  "Borzo",
  "Dunzo",
  "Pharmacy",
  "Other",
];

const ThirdPartyModal = ({
  thirdPartyModal,
  setThirdPartyModal,
  orderData,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [upiPlatform, setUpiPlatform] = useState("");
  const [upiPhone, setUpiPhone] = useState("");
  const [upiSearchData, setUpiSearchData] = useState<any>({});
  const privateInstance = useAxiosInstance();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  function showPosition(position: any) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    let payload: any = {
      amount: values?.delivery_charges,
      location: `${latitude},${longitude}`,
      location_id: "",
      notes:
        values?.payment_to?.toLowerCase() === "delivery service"
          ? `Delivery Service by ${values.service_partner}`
          : values.payment_to,
      order_id: orderData?.order_id,
      payment_mode: "third_party_payment",
      payment_type: "UPI",
      provider_id: "",
      upi_id: upiSearchData?.upi_id,
      upi_name: upiSearchData?.account_holder_name,
    };

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/transfer`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Payment Initiated");
        setThirdPartyModal(false);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={thirdPartyModal}
      onClose={() => setThirdPartyModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setThirdPartyModal(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ textTransform: "capitalize" }}>
            Payout - Third Party
          </Typography>
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            payment_to: "",
            upi_type: "upi_number",
            delivery_charges: "",
            service_partner: "",
          }}
          onSubmit={(values: any, actions: any) => {
            setLoading(true);
            actions.setSubmitting(false);
            handleSubmit(values, actions);
          }}
          validationSchema={thirdPartySchema}
        >
          {(props: any) => {
            const {
              values,
              touched,
              errors,
              handleBlur,
              isSubmitting,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" sx={{ minWidth: "150px" }}>
                    Payment To:
                  </Typography>
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    error={
                      errors.payment_to && touched.payment_to ? true : false
                    }
                  >
                    <InputLabel id="payment-to-label">Select Type</InputLabel>
                    <Select
                      labelId="payment-to-label"
                      name="payment_to"
                      id="payment_to"
                      value={values?.payment_to || ""}
                      label="Payment To"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("payment_to", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      {thirdPartyOptions.map((x: string) => (
                        <MenuItem key={x} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.payment_to && touched.payment_to && (
                      <FormHelperText>{errors.payment_to}</FormHelperText>
                    )}
                  </FormControl>
                  {values?.payment_to?.toLowerCase() === "delivery service" && (
                    <FormControl
                      size="small"
                      fullWidth
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      error={
                        errors.service_partner && touched.service_partner
                          ? true
                          : false
                      }
                    >
                      <InputLabel id="service-partner-label">
                        Service Partner
                      </InputLabel>
                      <Select
                        labelId="service-partner-label"
                        name="service_partner"
                        id="service_partner"
                        value={values?.service_partner || ""}
                        label="Service Partner"
                        onChange={(e: SelectChangeEvent) => {
                          setFieldValue("service_partner", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        {deliveryServiceOptions.map((x: string) => (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.service_partner && touched.service_partner && (
                        <FormHelperText>
                          {errors.service_partner}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Stack>
                <Box sx={{ textAlign: "center", mt: 1.5 }}>
                  <FormControl sx={{ mb: 1.5 }}>
                    <RadioGroup
                      id="upi_type"
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => {
                        setFieldValue("upi_type", e.target.value);
                        setUpiSearchData({
                          ...upiSearchData,
                          upi_id: "",
                          account_holder_name: "",
                        });
                      }}
                      defaultValue="upi_number"
                    >
                      <FormControlLabel
                        value="upi_number"
                        control={<Radio />}
                        label="UPI Number"
                      />
                      <FormControlLabel
                        value="upi_id"
                        control={<Radio />}
                        label="UPI ID"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>

                {values?.upi_type === "upi_number" && (
                  <>
                    <FindUpiPhone
                      upiSearchData={upiSearchData}
                      upiPhone={upiPhone}
                      upiPlatform={upiPlatform}
                      setUpiPhone={setUpiPhone}
                      setUpiPlatform={setUpiPlatform}
                      setUpiSearchData={setUpiSearchData}
                    />
                    <Stack direction="row">
                      <TextField
                        fullWidth
                        id="upi_id"
                        label="UPI ID"
                        size="small"
                        value={upiSearchData?.upi_id || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        disabled
                      />
                      <TextField
                        fullWidth
                        id="upi_name"
                        label="UPI NAME"
                        size="small"
                        value={upiSearchData?.account_holder_name || ""}
                        sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                        disabled
                      />
                    </Stack>
                  </>
                )}

                {values?.upi_type === "upi_id" && (
                  <>
                    <VerifyUpi
                      upiData={upiSearchData}
                      setUpiData={setUpiSearchData}
                    />
                    <TextField
                      fullWidth
                      id="upi_name"
                      label="UPI NAME"
                      size="small"
                      value={upiSearchData?.account_holder_name || ""}
                      sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                      disabled
                    />
                  </>
                )}

                <Stack sx={{ mt: 1.5 }}>
                  <TextField
                    fullWidth
                    id="delivery_charges"
                    label="Amount (Rs.)"
                    size="small"
                    value={values?.delivery_charges || ""}
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    onChange={(e: any) => {
                      setFieldValue(
                        "delivery_charges",
                        validateFloatNum(e.target.value, valueLimits.MRP)
                      );
                    }}
                    onBlur={handleBlur}
                    helperText={
                      errors.delivery_charges && touched.delivery_charges
                        ? errors.delivery_charges
                        : ""
                    }
                    error={
                      errors.delivery_charges && touched.delivery_charges
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                  />
                </Stack>

                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={loading}
                    disabled={
                      isSubmitting ||
                      !upiSearchData?.upi_id ||
                      !upiSearchData?.account_holder_name
                    }
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                  >
                    Make payment
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
        <Box mt={5}>
          <Typography variant="h4" color="error">
            Note :{" "}
          </Typography>
          <ol type="1" style={{ paddingLeft: "1rem" }}>
            <li style={{ color: "#d32f2f" }}>
              Use <strong>Delivery Service</strong>: For Any kind of Delivery
              Service Fee.
            </li>
            <li style={{ color: "#d32f2f" }}>
              Use <strong>Refund</strong>: For any kind of customer refunds upto
              &#8377; 500.
            </li>
            <li style={{ color: "#d32f2f" }}>
              Use <strong>Provider</strong>: For extra payouts except delivery
              fee.
            </li>
          </ol>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ThirdPartyModal;
