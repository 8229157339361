import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  isUserAllowedToRevise,
  validateFloatNum,
  validateIntNum,
} from "../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import { medicinePackForms, valueLimits } from "../../../constants";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import AltIcon from "@mui/icons-material/LocalHospital";
import { makeStyles } from "@mui/styles";
import {
  MEDICINE_AVL_STATUS,
  MEDICINE_CHANGE_TYPE,
} from "../../../enums/order.enum";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import useUserInfo from "../../../hooks/useUserInfo";
import { USER_ROLE } from "../../../enums/common.enum";
import AltFromPharmacyModal from "../Edit/altFromPharmacyModal";

const useStyles = makeStyles({
  cssOutlinedInput: {
    "&$notchedOutline": {
      borderColor: "inherit !important",
    },
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "inherit !important",
  },
  yes: {
    borderWidth: "2px",
    borderColor: "#008000 !important",
  },
  no: {
    borderWidth: "2px",
    borderColor: "#c53030 !important",
  },
  alt: {
    borderWidth: "2px",
    borderColor: "#ffc000 !important",
  },
  cssLabel: {
    color: "#00000061 !important",
  },
  greenText: {
    color: "#008000 !important",
  },
  orangeText: {
    color: "#ffc000 !important",
  },
  redText: {
    color: "#c53030 !important",
  },
});

const MedicinesTable = ({
  data,
  setSelectedMedicines,
  selectedMedicinesId,
  setSelectedMedicinesId,
  isEditing,
  altMedicineIndex = null,
  setAltMedicineIndex = undefined,
  orderMerchantMeta,
  orderItems,
  medTableError,
  setMedTableError,
  orderPartner,
  appliedDiscountPercent = 0,
  setAppliedDiscountPercent = undefined,
  orderStatus = "",
}: any) => {
  const [showAltFromPharmacyModal, setShowAltFromPharmacyModal] =
    useState(false);
  const [orgAndAltItem, setOrgAndAltItem] = useState<any>(null);
  const updateData = (oldIndex: number, item: any) => {
    data.splice(oldIndex, 1, item);
    const updatedData = [...data];
    setSelectedMedicines(updatedData);
  };

  const deleteItem = (index: number, testId: number | string) => {
    let allMedicines = [...data];
    allMedicines.splice(index, 1);
    setSelectedMedicines([...allMedicines]);

    let allMedicinesId = [...selectedMedicinesId];
    allMedicinesId.splice(index, 1);
    setSelectedMedicinesId([...allMedicinesId]);
  };

  let subTotal: number = 0;

  const classes = useStyles();

  const user: AuthenticatedUser = useUserInfo();

  useEffect(() => {
    let errors: any = [];

    data?.forEach((medicine: any, index: number) => {
      let mrp: boolean = false;
      let quantity: boolean = false;
      let pack_quantity: boolean = false;

      if (medicine.change_type !== MEDICINE_CHANGE_TYPE.NOT_AVAILABLE) {
        if (Number(medicine.MRP) < 1) {
          mrp = true;
        }

        if (Number(medicine.quantity) < 1) {
          quantity = true;
        }

        if (Number(medicine.pack_quantity) < 1) {
          pack_quantity = true;
        }
      }

      if (
        isEditing &&
        (user?.role === USER_ROLE.EDITOR || user?.role === USER_ROLE.MANAGER)
      ) {
        let a, b, c;
        if (medicine.med_from_db && medicine.change_type !== "alternative") {
          a = Number(medicine.MRP);
          b = Number(orderItems[index]?.MRP);
          c = b + b * (30 / 100);
        } else {
          a = Number(medicine.MRP);
          b = Number(medicine.db_mrp);
          c = b + b * (30 / 100);
        }

        if (Number(a > c)) {
          mrp = true;
        }
      }

      errors.push({
        mrp: mrp,
        quantity: quantity,
        pack_quantity: pack_quantity,
      });
    });

    setMedTableError([...errors]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {data.map((medicine: any, index: number) => {
        subTotal += Number(medicine.quantity) * Number(medicine.MRP);
        let isMedicineAvailable =
          medicine.change_type !== MEDICINE_CHANGE_TYPE.NOT_AVAILABLE;
        let isMedicineAlternative =
          medicine.change_type === MEDICINE_CHANGE_TYPE.ALTERNATIVE;
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: 1.5,
              opacity: altMedicineIndex === index ? 0.6 : 1,
            }}
            key={index}
          >
            <TextField
              fullWidth
              id="item-name"
              size="small"
              label="Item Name"
              value={medicine.item}
              disabled
              sx={{
                mt: 1.5,
                mr: { xs: 0, md: 1 },
              }}
              inputProps={{
                style: {
                  borderLeft: isEditing
                    ? isMedicineAvailable
                      ? "5px solid #2e7d32"
                      : "5px solid #d32f2f"
                    : "none",
                },
              }}
            />
            {isMedicineAlternative && (
              <Tooltip title={`Alternate of: ${medicine.requested_item.item}`}>
                <InfoIcon
                  color="error"
                  sx={{ marginLeft: "-20px", cursor: "pointer" }}
                />
              </Tooltip>
            )}
            {medicine.sku_id ? null : (
              <Tooltip title="Unmapped SKU">
                <InfoIcon
                  color="primary"
                  sx={{ marginLeft: "-20px", cursor: "pointer" }}
                />
              </Tooltip>
            )}
            {medicine.status !== "pending" ? null : (
              <Tooltip title="item status: pending">
                <InfoIcon
                  color="warning"
                  sx={{
                    marginTop: "36px",
                    marginLeft: "-22px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            )}
            {medicine.manual ? (
              <FormControl
                size="small"
                sx={{
                  mt: 1.5,
                  mr: { xs: 0, md: 1 },
                  width: { xs: "100%", md: "50%" },
                }}
              >
                <InputLabel id="pack-form-label">Pack Form</InputLabel>
                <Select
                  labelId="pack-form-label"
                  name="pack_form"
                  id="pack_form"
                  value={medicine.pack_form || ""}
                  label="Pack Form"
                  onChange={(event) => {
                    const item = {
                      ...medicine,
                      pack_form: event.target.value,
                    };
                    updateData(index, item);
                  }}
                >
                  {medicinePackForms.map((x) => {
                    return (
                      <MenuItem key={x} value={x}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : (
              <TextField
                id="item-name"
                size="small"
                label="Pack Form"
                value={medicine.pack_form || ""}
                disabled
                sx={{
                  mt: 1.5,
                  mr: { xs: 0, md: 1 },
                  width: { xs: "100%", md: "50%" },
                }}
              />
            )}
            <TextField
              id="item-detail"
              size="small"
              label="Pack Qty."
              value={medicine.pack_quantity || ""}
              disabled={medicine.manual ? false : true}
              sx={{
                mt: 1.5,
                mr: { xs: 0, md: 1 },
                width: { xs: "100%", md: "40%" },
              }}
              onChange={(event) => {
                const item = {
                  ...medicine,
                  pack_quantity: Number(
                    validateIntNum(event.target.value, valueLimits.PACK_QTY)
                  ),
                };
                updateData(index, item);
              }}
              error={
                medTableError ? medTableError[index]?.pack_quantity : false
              }
            />
            <TextField
              id="item-detail"
              size="small"
              label="Qty."
              value={medicine.quantity}
              sx={{
                mt: 1.5,
                mr: { xs: 0, md: 1 },
                width: { xs: "100%", md: "40%" },
              }}
              disabled={
                (isEditing && !medicine.sku_id) ||
                (orderPartner === "lombard" && medicine.item_code === "")
              } //PARTNER SPECIFIC: LOMMBARD
              onChange={(event) => {
                const item = {
                  ...medicine,
                  quantity: Number(
                    validateIntNum(event.target.value, valueLimits.QTY)
                  ),
                  amount: String(
                    Number(
                      medicine.MRP *
                        validateFloatNum(event.target.value, valueLimits.QTY)
                    ).toFixed(2)
                  ),
                };
                updateData(index, item);
              }}
              error={medTableError ? medTableError[index]?.quantity : false}
            />
            <TextField
              id="item-price"
              size="small"
              label="Price"
              value={medicine.MRP}
              //disabled={medicine.manual || isEditing ? false : true}
              // PARTNER SPECIFIC: LOMBARD
              disabled={
                (isEditing && !medicine.sku_id) ||
                (medicine.manual || isEditing ? false : true) ||
                (orderPartner === "lombard" && medicine.item_code === "")
              }
              sx={{
                mt: 1.5,
                mr: { xs: 0, md: 1 },
                width: { xs: "100%", md: "50%" },
              }}
              onChange={(event) => {
                const item = {
                  ...medicine,
                  MRP: validateFloatNum(event.target.value, valueLimits.MRP),
                  amount: String(
                    Number(
                      medicine.quantity *
                        validateFloatNum(event.target.value, valueLimits.MRP)
                    ).toFixed(2)
                  ),
                };
                updateData(index, item);
              }}
              error={medTableError ? medTableError[index]?.mrp : false}
            />
            {orderMerchantMeta &&
              orderMerchantMeta[0]?.items?.merchant_filled && (
                <TextField
                  size="small"
                  label={
                    medicine.change_type === MEDICINE_CHANGE_TYPE.ALTERNATIVE
                      ? medicine.alt_from_pharmacy
                        ? "Pharmacy Price"
                        : "Amount"
                      : orderMerchantMeta[0]?.items[index]?.avl ===
                        MEDICINE_AVL_STATUS.AVAILABLE
                      ? "Pharmacy Price"
                      : orderMerchantMeta[0]?.items[index]?.avl ===
                        MEDICINE_AVL_STATUS.ALTERNATIVE
                      ? "ALT"
                      : orderMerchantMeta[0]?.items[index]?.avl ===
                        MEDICINE_AVL_STATUS.NOT_AVAILABLE
                      ? "NA"
                      : "Amount"
                  }
                  value={
                    medicine.change_type === MEDICINE_CHANGE_TYPE.ALTERNATIVE
                      ? medicine.alt_from_pharmacy
                        ? Number(medicine.MRP)?.toFixed(2)
                        : ""
                      : orderMerchantMeta[0]?.items[index]?.avl ===
                        MEDICINE_AVL_STATUS.AVAILABLE
                      ? orderMerchantMeta[0]?.items[index]?.MRP
                      : ""
                  }
                  disabled={true}
                  sx={{
                    mt: 1.5,
                    mr: { xs: 0, md: 1 },
                    width: { xs: "100%", md: "50%" },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      disabled:
                        medicine.change_type ===
                        MEDICINE_CHANGE_TYPE.ALTERNATIVE
                          ? medicine.alt_from_pharmacy
                            ? classes.orangeText
                            : classes.cssLabel
                          : orderMerchantMeta[0]?.items[index]?.avl ===
                            MEDICINE_AVL_STATUS.AVAILABLE
                          ? classes.greenText
                          : orderMerchantMeta[0]?.items[index]?.avl ===
                            MEDICINE_AVL_STATUS.ALTERNATIVE
                          ? classes.orangeText
                          : orderMerchantMeta[0]?.items[index]?.avl ===
                            MEDICINE_AVL_STATUS.NOT_AVAILABLE
                          ? classes.redText
                          : classes.cssLabel,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        {!isMedicineAlternative &&
                        orderMerchantMeta[0]?.items[index]?.avl ===
                          MEDICINE_AVL_STATUS.ALTERNATIVE &&
                        orderMerchantMeta[0]?.items[index]?.alt_item ? (
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ mr: -1.5 }}
                              onClick={() => {
                                setAltMedicineIndex(index);
                                setShowAltFromPharmacyModal(true);
                                setOrgAndAltItem({
                                  org: medicine,
                                  alt: orderMerchantMeta[0].items[index]
                                    .alt_item,
                                });
                              }}
                            >
                              <Tooltip title="Choose Alt Medicine From Pharmacy">
                                <AltIcon />
                              </Tooltip>
                            </IconButton>
                          </InputAdornment>
                        ) : null}
                      </>
                    ),
                    classes: {
                      root: classes.cssOutlinedInput,
                      notchedOutline:
                        medicine.change_type ===
                        MEDICINE_CHANGE_TYPE.ALTERNATIVE
                          ? medicine.alt_from_pharmacy
                            ? classes.alt
                            : classes.notchedOutline
                          : orderMerchantMeta[0]?.items[index]?.avl ===
                            MEDICINE_AVL_STATUS.AVAILABLE
                          ? classes.yes
                          : orderMerchantMeta[0]?.items[index]?.avl ===
                            MEDICINE_AVL_STATUS.ALTERNATIVE
                          ? classes.alt
                          : orderMerchantMeta[0]?.items[index]?.avl ===
                            MEDICINE_AVL_STATUS.NOT_AVAILABLE
                          ? classes.no
                          : classes.notchedOutline,
                    },
                  }}
                />
              )}
            <TextField
              id="item-price"
              size="small"
              label="Total Amt"
              value={Number(medicine.quantity * medicine.MRP).toFixed(2)}
              sx={{
                mt: 1.5,
                mr: { xs: 0, md: 1 },
                width: { xs: "100%", md: "50%" },
              }}
              disabled
            />
            <Box display="flex" sx={{ mt: 1.5, alignItems: "center" }}>
              {isEditing && (
                <Tooltip
                  title={isMedicineAlternative ? "" : "Add an Alternative"}
                >
                  <span>
                    <IconButton
                      disabled={
                        isMedicineAlternative ||
                        medicine.requested_item ||
                        medicine.status === "pending" ||
                        !isUserAllowedToRevise(orderStatus, user.role)
                      }
                      onClick={() => {
                        setAltMedicineIndex(index);
                      }}
                      color="primary"
                    >
                      <AltIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {!medicine.med_from_db && !isMedicineAlternative && (
                <IconButton
                  onClick={() => deleteItem(index, medicine.sku_id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <Tooltip
                title={
                  <>
                    <Typography variant="body2">
                      MFR by: {medicine.manufacturer}
                    </Typography>
                    <Typography variant="body2">
                      Composition: {medicine.composition}
                    </Typography>
                  </>
                }
              >
                <IconButton color="primary">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
              {isEditing && medicine.med_from_db && (
                <Tooltip
                  title={
                    isMedicineAlternative
                      ? ""
                      : isMedicineAvailable
                      ? "Click to mark as Not Available"
                      : "Click to mark as Available"
                  }
                >
                  <span>
                    <IconButton
                      //disabled={isMedicineAlternative}
                      disabled={!isUserAllowedToRevise(orderStatus, user.role)}
                      onClick={() => {
                        let item = {
                          ...medicine,
                        };
                        if (isMedicineAvailable) {
                          item = { ...item, change_type: "not_available" };
                        } else {
                          item = { ...item, change_type: "available" };
                          //delete item.change_type;
                        }
                        updateData(index, item);
                      }}
                      color={isMedicineAvailable ? "error" : "success"}
                    >
                      {isMedicineAvailable ? <ClearIcon /> : <CheckIcon />}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
        );
      })}

      <Stack alignItems="end" textAlign="right">
        {subTotal > 0 && (
          <Typography
            variant="h4"
            sx={{
              marginRight: 10,
              mt: 1.5,
              minWidth: "200px",
            }}
          >
            Estimated total: {subTotal.toFixed(2)}
          </Typography>
        )}
        {/* PARTENR SPECIFIC: EKACARE */}
        {user?.partner === "ekacare" && data?.length > 0 && !isEditing ? (
          <Stack direction="row" mr={10}>
            <TextField
              size="small"
              label="Applied Discount (%)"
              value={appliedDiscountPercent}
              sx={{
                mt: 2,
                maxWidth: "160px",
              }}
              onChange={(e) => {
                setAppliedDiscountPercent(
                  validateFloatNum(e.target.value, valueLimits.PERCENT)
                );
              }}
            />
            <TextField
              size="small"
              label="Applied Discount (Rs.)"
              value={Number((subTotal * appliedDiscountPercent) / 100).toFixed(
                2
              )}
              sx={{
                mt: 2,
                ml: 2,
                maxWidth: "160px",
              }}
              disabled
            />
          </Stack>
        ) : null}
      </Stack>
      {showAltFromPharmacyModal ? (
        <AltFromPharmacyModal
          orgAndAltItem={orgAndAltItem}
          showAltFromPharmacyModal={showAltFromPharmacyModal}
          setShowAltFromPharmacyModal={setShowAltFromPharmacyModal}
          altMedicineIndex={altMedicineIndex}
          setAltMedicineIndex={setAltMedicineIndex}
          selectedValues={data}
          setSelectedValues={setSelectedMedicines}
        />
      ) : null}
    </>
  );
};

export default MedicinesTable;
