import {
  Box,
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useState } from "react";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  sortOrders,
  textCapitalize,
} from "../../utils/common";
import NotesModal from "./notesModal";
import OrderDataModal from "./orderDataModal";
import { useNavigate } from "react-router-dom";
import { ORDER_TYPE } from "../../enums/order.enum";
import PaymentButton from "./GroupOrders/Payment/paymentButton";
import { API_RESULT, API_VERSION, USER_ROLE } from "../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData, setSearchText } from "../../store/actions/useActions";
import { commonTableOptions } from "../../constants";
import OrderByPartnerId from "./orderByPartnerId";
import { UpdateNotesIcon } from "../../assets/icons";
import OrderBadges from "./orderBadges";
import { parse } from "date-fns";
import ErrorBoundary from "../Helpers/ErrorBoundary";
import { times } from "lodash";
import AllOrderTableSkeleton from "../UI/allOrderTableSkeleton";
import StoreVisitIcon from "@mui/icons-material/Storefront";
import HomeDeliveryIcon from "@mui/icons-material/DeliveryDining";

const ManageOrder: React.FC<{ orderType: string }> = ({ orderType }) => {
  const [loading, setLoading] = useState(false);
  const user: AuthenticatedUser = useUserInfo();
  const privateInstance = useAxiosInstance();
  let urlToFetchOrders: string;
  const [activeOrderId, setActiveOrderId] = useState("");
  const [notesModal, setNotesModal] = useState(false);
  const [activePartner, setActivePartner] = useState("");
  const [activePartnerOrderId, setActivePartnerOrderId] = useState("");
  const [orderDataModal, setOrderDataModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ordersToManage = useSelector((state: any) => state.order.allOrderData);

  if (user?.role === USER_ROLE.EDITOR) {
    urlToFetchOrders = `/orders/all/${user?.partner}?ver=v3&order_type=${orderType}&editor=${user.user_id}`;
  } else {
    urlToFetchOrders = `/orders/all/${user.partner}?ver=v3&order_type=${orderType}`;
  }

  const getOrders = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}${urlToFetchOrders}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        //sort consultation orders by time slot
        if (orderType === ORDER_TYPE.CONSULTATION) {
          let sortedConsOrders = sortOrders(result.data.response);
          sortedConsOrders = sortedConsOrders?.sort((a, b) => {
            a = parse(a.time_slot, "dd-MM-yyyy, hh:mm aa", new Date());
            b = parse(b.time_slot, "dd-MM-yyyy, hh:mm aa", new Date());
            return a - b;
          });
          dispatch(setAllOrderData(sortedConsOrders));
        } else {
          dispatch(setAllOrderData(sortOrders(result.data.response)));
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      dispatch(setAllOrderData([]));
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const manageColumns = [
    {
      name: "partner_order_id",
      label: "PARTNER ORDER ID",
      options: {
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "CREATED AT",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "customer_details.name",
      label: "CUSTOMER NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "customer_details.address_details.city",
      label: "CITY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "customer_details.address_details.state",
      label: "STATE",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "customer_details.address_details.pin_code",
      label: "PIN CODE",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "consultation_type",
      label: "CONSULTATION DETAILS",
      options: {
        filter: false,
        display: orderType === ORDER_TYPE.CONSULTATION ? true : false,
        searchable: false,
      },
    },
    {
      name: "time_slot",
      label: "TIME SLOT",
      options: {
        filter: false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "sample_collection",
      label: "DIAGNOSTICS DETAILS",
      options: {
        filter: false,
        display: orderType === ORDER_TYPE.DIAGNOSTICS ? true : false,
        searchable: false,
      },
    },
    {
      name: "order_partner",
      label: "ORDER PARTNER",
      options: {
        filter: user?.role === USER_ROLE.PARTNER ? false : true,
        display: user?.role === USER_ROLE.PARTNER ? false : true,
        //filterType: "textField" as FilterType,
        searchable: false,
      },
    },
    {
      name: "order_status",
      label: "ORDER STATUS",
      options: {
        searchable: false,
      },
    },
    {
      name: "order_id",
      label: "ACTION",
      options: {
        filter: false,
        searchable: true,
      },
    },
    {
      name: "assigned_to",
      label: "ASSIGNED TO",
      options: {
        filter: true,
        display: false,
        searchable: false,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "priority",
      label: "PRIORITY",
      options: {
        filter: true,
        display: false,
        searchable: false,
      },
    },
    {
      name: "delivery_partner",
      label: "DELIVERY PARTNER",
      options: {
        filter: orderType === ORDER_TYPE.MEDICINES ? true : false,
        display: false,
        searchable: false,
      },
    },
    {
      name: "express_delivery",
      label: "EXPRESS DELIVERY",
      options: {
        filter: orderType === ORDER_TYPE.MEDICINES ? true : false,
        display: false,
        searchable: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          times(10, (i) => <AllOrderTableSkeleton key={i} />)
        ) : (
          <OrderByPartnerId />
        ),
      },
    },
    ...commonTableOptions,
    customRowRender: (data: any, dataIndex: number) => {
      return (
        <CustomRowComponent key={data[0]} rowData={data} index={dataIndex} />
      );
    },
    searchPlaceholder: "Name/Partner Order ID/Order ID",
    onSearchChange: (val: string) => {
      dispatch(setSearchText(val));
    },
  };

  React.useEffect(() => {
    //reset searched text on page load
    dispatch(setSearchText(""));

    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(setAllOrderData([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function CustomRowComponent({ rowData, index }: any) {
    const [
      partner_order_id,
      created_at,
      customer_name,
      customer_city,
      customer_state,
      customer_pin,
      consultation_type,
      time_slot,
      sample_collection,
      order_partner,
      order_status,
      order_id,
      assigned_to,
      priority,
      delivery_partner,
      express_delivery,
    ] = rowData;
    return (
      <TableRow key={order_id} hover>
        <TableCell sx={{ width: { sm: "auto", md: "28%" } }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="body2"
              sx={{ color: "#0058ff", cursor: "pointer", mr: 0.25 }}
              onClick={() => {
                setActivePartner(order_partner);
                setActivePartnerOrderId(partner_order_id);
                setOrderDataModal(true);
              }}
            >
              {partner_order_id}
            </Typography>
            <OrderBadges
              priority={priority}
              order_status={order_status}
              partner_order_id={partner_order_id}
              created_at={created_at}
              express_delivery={express_delivery}
            />
          </Stack>
          <Typography variant="body2" sx={{ color: "#718096" }}>
            {parseAndFormateDate(created_at)}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: { sm: "auto", md: "22%" } }}>
          <Stack direction="column">
            <Typography variant="body2">{customer_name}</Typography>
            {customer_city && (
              <Typography variant="body2" sx={{ color: "#718096" }}>
                City: {customer_city}
              </Typography>
            )}
            {customer_state && (
              <Typography variant="body2" sx={{ color: "#718096" }}>
                State: {customer_state}
              </Typography>
            )}
            {customer_pin && (
              <Typography variant="body2" sx={{ color: "#718096" }}>
                Pin code: {customer_pin}
              </Typography>
            )}
          </Stack>
        </TableCell>
        {(orderType === ORDER_TYPE.CONSULTATION ||
          orderType === ORDER_TYPE.DIAGNOSTICS) && (
          <TableCell>
            <Stack>
              <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
                {textCapitalize(
                  orderType === ORDER_TYPE.CONSULTATION
                    ? consultation_type?.split("_").join(" ")
                    : sample_collection?.split("_").join(" ")
                )}
              </Typography>
              <Typography variant="body2" sx={{ color: "#718096" }}>
                {time_slot}
              </Typography>
            </Stack>
          </TableCell>
        )}
        {user?.role !== USER_ROLE.PARTNER && (
          <TableCell>
            <Typography variant="body2">{order_partner}</Typography>
          </TableCell>
        )}
        <TableCell sx={{ width: { sm: "auto", md: "22%" } }}>
          <Typography variant="body2">
            {textCapitalize(order_status?.split("_").join(" "))}
          </Typography>
          {user?.role !== USER_ROLE.PARTNER && assigned_to ? (
            <Typography variant="body2" color="#718096">
              Assigned to: {assigned_to}
            </Typography>
          ) : null}
          {delivery_partner ? (
            <>
              {delivery_partner === "medpay" ? (
                <Stack direction="row" alignItems="center">
                  <Tooltip title="Pharmacy will deliver the order">
                    <HomeDeliveryIcon
                      fontSize="small"
                      sx={{ color: "#718096" }}
                    />
                  </Tooltip>
                  <Typography pl={0.5} variant="body2" color="#718096">
                    Home Delivery
                  </Typography>
                </Stack>
              ) : null}
              {delivery_partner === "self_pickup" ||
              delivery_partner === "logistics" ? (
                <Stack direction="row" alignItems="center">
                  <Tooltip title="Rider will pick-up the order">
                    <StoreVisitIcon
                      fontSize="small"
                      sx={{ color: "#718096" }}
                    />
                  </Tooltip>
                  <Typography pl={0.5} variant="body2" color="#718096">
                    Pick-up Order
                  </Typography>
                </Stack>
              ) : null}
            </>
          ) : null}
        </TableCell>
        <TableCell align="center">
          <Stack direction="row">
            {user?.role === USER_ROLE.PARTNER ||
            user?.provider_payment === "disabled" ? null : (
              <PaymentButton
                activePartner={order_partner}
                activePartnerOrderId={partner_order_id}
                activeOrderId={order_id}
                index={index}
              />
            )}
            <IconButton
              color="primary"
              sx={{ fontSize: "22px" }}
              onClick={() => {
                setActiveOrderId(order_id);
                setNotesModal(true);
              }}
            >
              <UpdateNotesIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      {user?.partner === "medpay" && (
        <Box sx={{ mb: { xs: 6.5, md: 7.5 } }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ float: "right" }}
            onClick={() =>
              navigate(
                `/${
                  orderType === ORDER_TYPE.MEDICINES ? "pharmacy" : orderType
                }/manage-order?tab=1`
              )
            }
          >
            Manage Orders
          </Button>
        </Box>
      )}
      <Box>
        <MUIDataTable
          title=""
          data={ordersToManage}
          columns={manageColumns}
          options={options}
        />
      </Box>
      {notesModal && (
        <NotesModal
          notesModal={notesModal}
          setNotesModal={setNotesModal}
          orderId={activeOrderId}
        />
      )}
      {orderDataModal && (
        <OrderDataModal
          orderDataModal={orderDataModal}
          setOrderDataModal={setOrderDataModal}
          activePartner={activePartner}
          activePartnerOrderId={activePartnerOrderId}
          orderType={orderType}
          showInvoiceCancel={true}
        />
      )}
    </ErrorBoundary>
  );
};

export default ManageOrder;
