import {
  Box,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  errorToast,
  handleError,
  successToast,
  transformAllOrder,
  validateIntNum,
  wordify,
} from "../../../../utils/common";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { ipdPaymentSchema } from "../../../../schemas/payment";
import {
  API_RESULT,
  API_VERSION,
  USER_ROLE,
} from "../../../../enums/common.enum";
import { useDispatch, useSelector } from "react-redux";
import { setAllOrderData } from "../../../../store/actions/useActions";
import { valueLimits } from "../../../../constants";
import { AuthenticatedUser } from "../../../../interfaces/user.model";
import useUserInfo from "../../../../hooks/useUserInfo";
import ConfirmModal from "../../../Helpers/confirmModal";

const IpdPayment = ({ orderData, lat, long, setInvoicePayoutModal }: any) => {
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  let ordersToManage = useSelector((state: any) => state.order.allOrderData);
  const user: AuthenticatedUser = useUserInfo();

  const handleSubmit = async (values: any) => {
    let payload: any = {
      payment_to: "IPD",
      payment_type: "IMPS",
      amount: values?.final_payable,
      notes: values?.notes + " IPD-transfer",
      otp: values?.otp,
      created_by: user?.user_id,
      location: `${lat},${long}`,
      extra_1: orderData?.partner_order_id,
      extra_2: orderData?.order_partner,
      extra_3: user?.user_id,
      extra_4: orderData?.merchant_details?.location_id,
      account_number: values?.account_no,
      account_holder_name: values?.account_name,
      account_ifsc_code: values?.ifsc_code,
      account_holder_mobile: orderData?.merchant_details?.mobile,
      location_id: orderData?.merchant_details?.location_id,
    };

    setLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/orders/payments/create-payment-request`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Request Successful");
        setInvoicePayoutModal(false);
        //get latest data and update
        try {
          const orderResult = await privateInstance.get(
            `${API_VERSION.V1}/orders/partner/${String(
              orderData.order_partner
            )?.toLowerCase()}/${orderData.partner_order_id}`
          );
          if (orderResult?.data?.status === API_RESULT.SUCCESS) {
            //update
            dispatch(
              setAllOrderData(
                transformAllOrder(
                  ordersToManage,
                  orderData.order_id,
                  orderResult.data.response
                )
              )
            );
          }
        } catch (err) {
          handleError(err);
        }
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          bill_amount: orderData?.invoice_details?.bill_amount,
          discount: orderData?.invoice_details?.discount || 0,
          final_payable: orderData?.invoice_details?.final_payout_amount,
          patient_payable: orderData?.patient_payable,
          otp: "",
          notes: "",
          account_name: orderData?.merchant_details?.account_name || "",
          bank_name: orderData?.merchant_details?.bank_name || "",
          account_no: orderData?.merchant_details?.account_no || "",
          ifsc_code: orderData?.merchant_details?.ifsc_code || "",
        }}
        validationSchema={ipdPaymentSchema}
        onSubmit={(values: any, actions: any) => {
          actions.setSubmitting(false);
          setShowConfirmationModal(true);
        }}
      >
        {(props: any) => {
          const {
            values,
            touched,
            errors,
            handleBlur,
            isSubmitting,
            setFieldValue,
          } = props;
          return (
            <Form>
              <Stack sx={{ mt: 3, flexDirection: { xs: "column", md: "row" } }}>
                <TextField
                  fullWidth
                  id="bill_amount"
                  label="BILL AMOUNT"
                  size="small"
                  value={values.bill_amount}
                  sx={{
                    mt: 1.5,
                    mr: {
                      xs: 0,
                      md: 1,
                    },
                  }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="patient_payable"
                  label="Patient Payable"
                  size="small"
                  value={values.patient_payable}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
              </Stack>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="discount_value"
                  label="DISCOUNT"
                  size="small"
                  value={Number(values.discount).toFixed(2)}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="final_payable"
                  label="Final payable amount"
                  size="small"
                  value={
                    isNaN(values?.final_payable)
                      ? ""
                      : Number(values.final_payable).toFixed(2)
                  }
                  disabled
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                />
              </Box>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="account_name"
                  label="Account Name"
                  size="small"
                  value={values?.account_name}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled={user?.role === USER_ROLE.SUPER_ADMIN ? false : true}
                  onChange={(e: any) =>
                    setFieldValue("account_name", e.target.value)
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.account_name && touched.account_name
                      ? errors.account_name
                      : ""
                  }
                  error={
                    errors.account_name && touched.account_name ? true : false
                  }
                  InputLabelProps={{
                    required: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="account_no"
                  label="Account Number"
                  size="small"
                  value={values?.account_no}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled={user?.role === USER_ROLE.SUPER_ADMIN ? false : true}
                  onChange={(e: any) =>
                    setFieldValue("account_no", e.target.value)
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.account_no && touched.account_no
                      ? errors.account_no
                      : ""
                  }
                  error={errors.account_no && touched.account_no ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </Box>
              <Box sx={{ mt: 1.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="bank_name"
                  label="Bank Name"
                  size="small"
                  value={values?.bank_name}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled={user?.role === USER_ROLE.SUPER_ADMIN ? false : true}
                  onChange={(e: any) =>
                    setFieldValue("bank_name", e.target.value)
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.bank_name && touched.bank_name
                      ? errors.bank_name
                      : ""
                  }
                  error={errors.bank_name && touched.bank_name ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                />
                <TextField
                  fullWidth
                  id="ifsc_code"
                  label="IFSC"
                  size="small"
                  value={values?.ifsc_code}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  disabled={user?.role === USER_ROLE.SUPER_ADMIN ? false : true}
                  onChange={(e: any) =>
                    setFieldValue("ifsc_code", e.target.value)
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.ifsc_code && touched.ifsc_code
                      ? errors.ifsc_code
                      : ""
                  }
                  error={errors.ifsc_code && touched.ifsc_code ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </Box>
              <Box sx={{ mt: 0.5, display: "flex", flexDirection: "row" }}>
                <TextField
                  fullWidth
                  id="otp"
                  label="OTP"
                  size="small"
                  value={values?.otp || ""}
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  onChange={(e: any) =>
                    setFieldValue(
                      "otp",
                      validateIntNum(e.target.value, valueLimits.PIN)
                    )
                  }
                  onBlur={handleBlur}
                  helperText={errors.otp && touched.otp ? errors.otp : ""}
                  error={errors.otp && touched.otp ? true : false}
                  InputLabelProps={{
                    required: true,
                  }}
                />
              </Box>
              <Box sx={{ mt: 0.5, display: "flex", flexDirection: "row" }}>
                <FormControl fullWidth sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}>
                  <TextField
                    id="notes"
                    label="Notes"
                    multiline
                    rows={3}
                    variant="outlined"
                    value={values.notes}
                    onChange={(e) => setFieldValue("notes", e.target.value)}
                    fullWidth
                  />
                </FormControl>
              </Box>

              <Box sx={{ mt: 3, textAlign: "center" }}>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={loading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  disabled={isSubmitting}
                >
                  Confirm payment
                </LoadingButton>
              </Box>

              {showConfirmationModal && (
                <ConfirmModal
                  showConfirmationModal={showConfirmationModal}
                  setShowConfirmationModal={setShowConfirmationModal}
                  callback={() => handleSubmit(values)}
                  message={`Are you sure you want to transfer Rs.${new Intl.NumberFormat(
                    "en-IN"
                  ).format(Number(values.final_payable))} (${wordify(
                    Number(values.final_payable)
                  )}) rupees only ?`}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default IpdPayment;
