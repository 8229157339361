import {
  Box,
  Dialog,
  DialogContent,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BillHistoryModal = ({
  viewHistoryModal,
  setViewHistoryModal,
  orderData,
}: any) => {
  const billDetails: any =
    orderData?.bill_details_history[orderData?.bill_details_history.length - 1];

  return (
    <Dialog
      open={viewHistoryModal}
      onClose={() => setViewHistoryModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setViewHistoryModal(false)}
      >
        Transaction ID: {orderData?.transaction_id}
      </CustomDialogTitle>
      <DialogContent>
        <Stack mt={{ xs: 2, md: 5 }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Typography width="220px" variant="h5">
              Link:
            </Typography>
            <Box display="flex" justifyContent="flex-start" width="100%">
              <TextField
                fullWidth
                size="small"
                value={billDetails?.bill_link}
                disabled
              />
              <Link href={billDetails?.bill_link} target="_blank">
                <Tooltip title="View Rx">
                  <OpenInNewIcon color="primary" sx={{ ml: 1 }} />
                </Tooltip>
              </Link>
            </Box>
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            my={2}
          >
            <Typography width="220px" variant="h5">
              Reject Reason:
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={billDetails?.reject_reason}
              disabled
            />
          </Stack>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Typography width="220px" variant="h5">
              Status:
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={billDetails?.status}
              disabled
            />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BillHistoryModal;
