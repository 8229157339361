import React from "react";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import {
  ORDER_PLATFORM,
  ORDER_STATUS,
  ORDER_TYPE,
} from "../../enums/order.enum";
import { textCapitalize } from "../../utils/common";
import EditIcon from "@mui/icons-material/Edit";
import EditOrderModal from "../ManageOrder/GroupOrders/Assign/editOrderModal";
import StoreVisitIcon from "@mui/icons-material/Storefront";
import HomeDeliveryIcon from "@mui/icons-material/DeliveryDining";

interface IOrderDetailsProp {
  orderData: {
    partner_order_id: string;
    order_status: string;
    order_partner: string;
    delivery_charges: string;
    delivery_partner: string;
    payment_collection: string;
    consultation_type?: string;
    specialty?: any;
    order_type: string;
    time_slot?: string;
    sample_collection?: any;
    platform?: string;
  };
  setOrderDataModal: boolean;
}

const OrderInfo = ({ orderData, setOrderDataModal }: IOrderDetailsProp) => {
  const [editOrderModal, setEditOrderModal] = React.useState(false);
  return (
    <>
      <Stack direction="row">
        <Typography variant="h4" sx={{ mb: 1 }}>
          Order Details
        </Typography>
        {/* REASSIGN CONS: after booking confirmed, PARTNER SPECIFIC, not allowed on admin panel page */}
        {setOrderDataModal &&
          orderData &&
          orderData.order_type === ORDER_TYPE.CONSULTATION &&
          orderData.platform === ORDER_PLATFORM.ORDER_DASHBOARD &&
          orderData.order_status === ORDER_STATUS.BOOKING_CONFIRMED &&
          orderData?.order_partner !== "lombard" &&
          orderData?.order_partner !== "loop health" && (
            <Button
              variant="contained"
              size="small"
              startIcon={<EditIcon />}
              sx={{ ml: 1, mb: 1 }}
              onClick={() => setEditOrderModal(true)}
            >
              Edit
            </Button>
          )}
      </Stack>
      <Typography variant="body1">
        Partner Order ID: {orderData?.partner_order_id}
      </Typography>
      <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
        Order Type: {orderData?.order_type}
      </Typography>
      <Typography variant="body1">
        Order Status:{" "}
        {textCapitalize(orderData?.order_status?.split("_").join(" "))}
      </Typography>
      {orderData?.delivery_charges ? (
        <Typography>
          Delivery Charges: &#8377; {orderData?.delivery_charges}/-
        </Typography>
      ) : null}
      <Typography>Partner: {orderData?.order_partner}</Typography>
      {orderData?.order_type === ORDER_TYPE.CONSULTATION && (
        <>
          <Typography>
            Consultation Type: {orderData?.consultation_type}
          </Typography>
          <Typography>
            Speciality: {textCapitalize(orderData?.specialty)}
          </Typography>
        </>
      )}
      {orderData?.order_type === ORDER_TYPE.DIAGNOSTICS && (
        <>
          <Typography>Time slot: {orderData?.time_slot}</Typography>
          <Typography>
            Sample collection:{" "}
            {textCapitalize(orderData?.sample_collection?.split("_").join(" "))}
          </Typography>
        </>
      )}
      {orderData?.order_type === ORDER_TYPE.MEDICINES && (
        <>
          {orderData.delivery_partner === "medpay" ? (
            <Stack direction="row" alignItems="center">
              <Typography>Delivery Type:&nbsp;</Typography>
              <Typography pr={0.5} variant="body1">
                Home Delivery
              </Typography>
              <Tooltip title="Pharmacy will deliver the order">
                <HomeDeliveryIcon fontSize="small" color="primary" />
              </Tooltip>
            </Stack>
          ) : null}
          {orderData.delivery_partner === "self_pickup" ||
          orderData.delivery_partner === "logistics" ? (
            <Stack direction="row" alignItems="center">
              <Typography>Delivery Type:&nbsp;</Typography>
              <Typography pr={0.5} variant="body1">
                Pick-up Order
              </Typography>
              <Tooltip title="Rider will pick-up the order">
                <StoreVisitIcon fontSize="small" color="primary" />
              </Tooltip>
            </Stack>
          ) : null}
        </>
      )}
      <Typography>
        Payment collection: {orderData?.payment_collection}
      </Typography>
      {editOrderModal && (
        <EditOrderModal
          editOrderModal={editOrderModal}
          setEditOrderModal={setEditOrderModal}
          orderData={orderData}
          setParentModal={setOrderDataModal}
        />
      )}
    </>
  );
};

export default OrderInfo;
