import {
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { handleError, textCapitalize } from "../../../utils/common";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ActionOnRequestModal from "./actionOnRequestModal";
import { commonTableOptions } from "../../../constants";
import { API_VERSION } from "../../../enums/common.enum";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";

function RequestsTab() {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [whitelistRequests, setWhitelistRequests] = useState<any>([]);
  const [activeOrderData, setActiveOrderData] = useState<any>({});
  const [actionOnRequestModal, setActionOnRequestModal] = useState(false);
  const [actionType, setActionType] = useState("");

  const getWhitelistRequests = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/upi-whitelisting`
      );
      if (result?.data?.status === "success") {
        setWhitelistRequests(result.data.response);
      } else {
        setWhitelistRequests([]);
      }
    } catch (err) {
      setWhitelistRequests([]);
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWhitelistRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upiWhitelistCols = [
    {
      name: "upi_id",
      label: "UPI INFO",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "name",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "provider_name",
      label: "PROVIDER INFO",
      options: {
        filter: true,
        filterType: "dropdown" as FilterType,
      },
    },
    {
      name: "provider_mobile",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "gst_no",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "formatted_address",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "partner_order_id",
      label: "PARTNER ORDER INFO",
      options: {
        filter: false,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "order_partner",
      label: "",
      options: {
        filter: true,
        display: false,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "final_payout_amount",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "requested_by",
      label: "REQUESTED BY",
      options: {
        filter: true,
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: false,
        filterType: "dropdown" as FilterType,
      },
    },
    {
      name: "order_id",
      label: "",
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    search: false,
    searchAlwaysOpen: false,
    customRowRender: (data: any) => {
      return <CustomRowComponent key={data[0]} rowData={data} />;
    },
  };

  function CustomRowComponent({ rowData }: any) {
    const [
      upi_id,
      name,
      provider_name,
      provider_mobile,
      gst_no,
      formatted_address,
      partner_order_id,
      order_partner,
      final_payout_amount,
      requested_by,
      status,
      order_id,
    ] = rowData;
    return (
      <TableRow hover>
        <TableCell>
          <Stack>
            {upi_id && <Typography variant="body1">ID: {upi_id}</Typography>}
            {name && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  {name}
                </Typography>
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            {provider_name && (
              <Typography variant="body1">{provider_name}</Typography>
            )}
            {provider_mobile && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  Mobile: {provider_mobile}
                </Typography>
              </>
            )}
            {gst_no && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  GST: {gst_no}
                </Typography>
              </>
            )}
            {formatted_address && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  Address: {formatted_address}
                </Typography>
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack>
            {partner_order_id && (
              <Typography variant="body1">{partner_order_id}</Typography>
            )}
            {order_partner && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  {order_partner}
                </Typography>
              </>
            )}
            {final_payout_amount && (
              <>
                <Divider sx={{ my: 0.5 }} />
                <Typography variant="body2" color="#4A5568">
                  Rs. {final_payout_amount} /-
                </Typography>
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          {requested_by && (
            <Typography variant="body1">{requested_by}</Typography>
          )}
        </TableCell>
        <TableCell>
          {status && (
            <Typography variant="body2">{textCapitalize(status)}</Typography>
          )}
        </TableCell>
        <TableCell>
          <Stack direction="row" justifyContent="space-between">
            <Tooltip title="Activate">
              <IconButton
                onClick={() => {
                  setActionType("activate");
                  setActiveOrderData(
                    whitelistRequests.filter(
                      (x: any) => x.order_id === order_id
                    )[0]
                  );
                  setActionOnRequestModal(true);
                }}
              >
                <CheckIcon color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Deactivate">
              <IconButton
                onClick={() => {
                  setActionType("deactivate");
                  setActiveOrderData(
                    whitelistRequests.filter(
                      (x: any) => x.order_id === order_id
                    )[0]
                  );
                  setActionOnRequestModal(true);
                }}
              >
                <CancelIcon color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ErrorBoundary>
      <MUIDataTable
        title=""
        data={whitelistRequests}
        columns={upiWhitelistCols}
        options={options}
      />
      {actionOnRequestModal && (
        <ActionOnRequestModal
          actionOnRequestModal={actionOnRequestModal}
          setActionOnRequestModal={setActionOnRequestModal}
          orderData={activeOrderData}
          actionType={actionType}
          getWhitelistRequests={getWhitelistRequests}
        />
      )}
    </ErrorBoundary>
  );
}

export default RequestsTab;
