import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { useParams } from "react-router-dom";
import A4Icon from "../../../../assets/images/a4.png";
import useAxiosInstance from "../../../../hooks/useAxiosInstance";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { handleError } from "../../../../utils/common";
import { commonTableOptions } from "../../../../constants";
import UpdateCertModal from "../../../Providers/updateCertModal";
import ChangeOnboardingModel from "../../../DigitalPayment/QrTerminals/changeOnboardingModel";
import ManageDevicesModal from "../../../DigitalPayment/QrTerminals/manageDevicesModal";

const MedpayQr = ({ data, setData, visitedTabs, updateVisitedTabs }: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [updateCert, setUpdateCert] = useState(false);
  const params = useParams();
  const activeLocationId = params?.locationId;
  const [showChangeOnboarding, setShowChangeOnboarding] = useState(false);
  const [showManageDevices, setShowManageDevices] = useState(false);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const getData = async () => {
      let apiUrl = `analytics/digital-payments/providers?location_id=${activeLocationId}`;
      setLoading(true);
      try {
        const result = await privateInstance.get(`${API_VERSION.V2}/${apiUrl}`);
        if (result?.data?.status === API_RESULT.SUCCESS) {
          updateVisitedTabs(1, "add");
          setData(result?.data?.response?.qr_providers);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    if (!visitedTabs.includes(1)) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cols = [
    {
      name: "qr_partner",
      label: "QR Partner",
      options: {
        filter: false,
        searchable: false,
      },
    },
    {
      name: "qr_status",
      label: "QR Status",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value === "pending" ? (
                <Chip
                  size="small"
                  label="PENDING"
                  sx={{ backgroundColor: "#FBD38D", padding: "6px" }}
                />
              ) : value === "active" ? (
                <Chip
                  size="small"
                  label="ACTIVE"
                  sx={{ backgroundColor: "#C6F6D5", padding: "6px" }}
                />
              ) : (
                <Chip
                  size="small"
                  label="INACTIVE"
                  sx={{
                    backgroundColor: "#C53030",
                    color: "#FFF",
                    padding: "6px",
                  }}
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: "terminal_id",
      label: "TERMINAL ID",
      options: {
        filter: false,
      },
    },
    {
      name: "soundbox_provider",
      label: "Soundbox",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Tooltip
              title={data[tableMeta.rowIndex]?.soundbox_id || "Link Soundbox"}
            >
              <Typography
                sx={{ cursor: "pointer" }}
                color="primary"
                onClick={() => {
                  setShowManageDevices(true);
                  setSelected(data[tableMeta.rowIndex]);
                }}
                variant="body2"
              >
                {value || "NA"}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "qr_onboarding_type",
      label: "Onboarding Model",
      options: {
        filter: true,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {data[tableMeta.rowIndex]?.qr_status === "active" &&
              value === "merchant" ? (
                <Typography
                  variant="body2"
                  color="primary"
                  onClick={() => {
                    setShowChangeOnboarding(true);
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {value}
                </Typography>
              ) : (
                <Typography variant="body2">{value}</Typography>
              )}
            </>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Created At",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any) => {
          return (
            <Typography minWidth={100} variant="body2">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "",
      label: "Actions",
      options: {
        download: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row">
              <Box
                textAlign="center"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setUpdateCert(true);
                }}
              >
                <PermIdentityIcon />
                <Typography
                  sx={{
                    fontSize: "10px !important",
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  KYC {data[tableMeta.rowIndex]?.qr_status}
                </Typography>
              </Box>
              <Box pl={1} textAlign="center">
                <IconButton
                  color="primary"
                  disabled={!data[tableMeta.rowIndex]?.static_qr_link}
                  onClick={() => {
                    window.open(
                      data[tableMeta.rowIndex].static_qr_link,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip title="Download QR">
                    <QrCode2Icon />
                  </Tooltip>
                </IconButton>
              </Box>
              <Box pl={1} textAlign="center">
                <IconButton
                  color="primary"
                  disabled={
                    !data[tableMeta.rowIndex]?.static_qr_link_map?.a4_pdf_url
                  }
                  onClick={() => {
                    window.open(
                      data[tableMeta.rowIndex].static_qr_link_map?.a4_pdf_url,
                      "_blank"
                    );
                  }}
                >
                  <Tooltip title="Download A4 PDF">
                    <img
                      alt="a4"
                      style={{
                        width: "24px",
                        opacity: !data[tableMeta.rowIndex]?.static_qr_link_map
                          ?.a4_pdf_url
                          ? 0.5
                          : 1,
                      }}
                      src={A4Icon}
                    />
                  </Tooltip>
                </IconButton>
              </Box>
            </Stack>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Terminal ID",
    download: false,
  };

  return (
    <Box>
      <MUIDataTable title="" data={data} columns={cols} options={options} />
      {updateCert ? (
        <UpdateCertModal
          updateCert={updateCert}
          setUpdateCert={setUpdateCert}
          activeLocationId={activeLocationId}
          callBack={null}
        />
      ) : null}
      {showChangeOnboarding ? (
        <ChangeOnboardingModel
          activeLocationId={activeLocationId}
          showChangeOnboarding={showChangeOnboarding}
          setShowChangeOnboarding={setShowChangeOnboarding}
        />
      ) : null}
      {showManageDevices ? (
        <ManageDevicesModal
          open={showManageDevices}
          onClose={() => setShowManageDevices(false)}
          rowData={selected}
          tableData={data}
          setTableData={setData}
        />
      ) : null}
    </Box>
  );
};

export default MedpayQr;
