import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { Form, Formik } from "formik";
import { editLedgerInfoSchema } from "../../schemas/ledger.schema";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  successToast,
  validateFloatNum,
  validateIntNum,
} from "../../utils/common";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { format, parse } from "date-fns";
import { valueLimits } from "../../constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const EditLedgerInfoModal = ({
  showEditLedgerInfo,
  setShowEditLedgerInfo,
  ledgerInfo,
  selectedPartner,
  updateFn,
}: any) => {
  const [openStartDate, setOpenStartDate] = useState<boolean>(false);
  const [isEditingGst, setIsEditingGst] = useState<boolean>(false);
  const privateInstance = useAxiosInstance();
  const [gstVerifyLoading, setGstVerifyLoading] = useState<boolean>(false);
  const [deliveryFields, setDeliveryFields] = useState<any>(
    ledgerInfo?.medicines?.delivery?.conditional || []
  );
  const [valueBasedFields, setValueBasedFields] = useState<any>(
    ledgerInfo?.medicines?.value_based_conditions || []
  );
  const validEmailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [formSubmissionLoading, setFormSubmissionLoading] = useState(false);
  const [emailIds, setEmailIds] = useState<any>(ledgerInfo?.email_ids || []);
  const [valueBasedType, setValueBasedType] = useState(
    ledgerInfo?.medicines?.monthly_value_based_commission
      ? "monthly_value_based_commission"
      : ledgerInfo?.medicines?.order_value_based_commission
      ? "order_value_based_commission"
      : "monthly_value_based_commission"
  );

  const verifyGst = async (val: string, setFieldValue: any) => {
    try {
      setGstVerifyLoading(true);
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/pharmacy/validate-gst?gstin=${val}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setFieldValue("company_name", result.data.response.business_name);
        setFieldValue("company_address", result.data.response.address);
        setFieldValue("company_state_code", result.data.response.state_code);
        setIsEditingGst(false);
      } else {
        errorToast(result.data.message);
        setFieldValue("company_name", "");
        setFieldValue("company_address", "");
        setFieldValue("company_state_code", "");
      }
    } catch (err) {
      handleError(err);
    } finally {
      setGstVerifyLoading(false);
    }
  };

  const handleDeliveryInputChange = (index: number, event: any) => {
    let data = [...deliveryFields];
    data[index][event.target.id] = event.target.value;
    setDeliveryFields(data);
  };

  const removeDeliveryInput = (index: number) => {
    let data = [...deliveryFields];
    data.splice(index, 1);
    setDeliveryFields(data);
  };

  const handleValueBasedInputChange = (index: number, event: any) => {
    let data = [...valueBasedFields];
    data[index][event.target.id] = event.target.value;
    setValueBasedFields(data);
  };

  const removeValueBasedInput = (index: number) => {
    let data = [...valueBasedFields];
    data.splice(index, 1);
    setValueBasedFields(data);
  };

  const handleSubmit = async (values: any) => {
    if (emailIds?.length === 0) {
      alert("Please enter at least one email");
      return;
    }

    if (
      values?.delivery_type === "conditional" &&
      deliveryFields.length === 0
    ) {
      alert("Please add delivery conditions");
      return;
    }

    let conditionalFieldError = false;
    for (let i = 0; i < deliveryFields.length; i++) {
      if (deliveryFields[i]?.fee === "" || deliveryFields[i]?.upto === "") {
        conditionalFieldError = true;
        break;
      }
    }

    if (values?.value_based && valueBasedFields.length === 0) {
      alert("Please add value based commissions");
      return;
    }

    let valueBasedFieldError = false;
    if (values.value_based) {
      for (let i = 0; i < valueBasedFields.length; i++) {
        if (
          valueBasedFields[i]?.min === "" ||
          valueBasedFields[i]?.max === "" ||
          valueBasedFields[i]?.medicines === "" ||
          valueBasedFields[i]?.fmcg === ""
        ) {
          valueBasedFieldError = true;
          break;
        }
      }
    }

    if (conditionalFieldError || valueBasedFieldError) {
      alert("Please fill all the mandatory fields to continue");
      return;
    }

    if (!window.confirm("Are you sure?")) {
      return;
    }

    let payload: any = {
      partner_id: selectedPartner,
      start_date: format(values?.start_date, "dd/MM/yyyy"),
      email_ids: emailIds,
      medicines: {
        delivery: {
          conditional: deliveryFields,
          type: values?.delivery_type,
          fixed: Number(values?.fixed_delivery_amt),
          express_delivery: Boolean(values?.express_delivery),
        },
        fmcg: Number(values.fmcg),
        medicines: Number(values.medicine),
        type: values?.service_offering,
        processing_fee: Number(values?.processing_fee),
        value_based_commission: values?.value_based,
        value_based_conditions: valueBasedFields,
        minimum_order_value: values?.minimum_order_value,
      },
      invoice_cycle: values?.invoice_cycle,
      low_balance: Number(values?.low_balance),
      credit_limit: Number(values?.credit_limit),
      company_gst: values?.company_gst,
      company_name: values?.company_name,
      company_address: values?.company_address,
      company_state_code: values?.company_state_code,
    };

    if (values?.value_based) {
      if (valueBasedType === "monthly_value_based_commission") {
        payload = {
          ...payload,
          medicines: {
            ...payload.medicines,
            monthly_value_based_commission: true,
          },
        };
      }
      if (valueBasedType === "order_value_based_commission") {
        payload = {
          ...payload,
          medicines: {
            ...payload.medicines,
            order_value_based_commission: true,
          },
        };
      }
    }

    setFormSubmissionLoading(true);
    try {
      const result = await privateInstance.post(
        `${API_VERSION.V2}/ledger/info`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast(result.data.message);
        setShowEditLedgerInfo(false);
        updateFn();
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setFormSubmissionLoading(false);
    }
  };

  return (
    <Dialog
      open={showEditLedgerInfo}
      onClose={() => setShowEditLedgerInfo(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setShowEditLedgerInfo(false)}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">
            {`${ledgerInfo ? "Edit" : "Add"} Ledger Info | ${selectedPartner}`}
          </Typography>
          {!ledgerInfo ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              sx={{ mr: 4 }}
              onClick={() =>
                window.open(
                  "https://docs.google.com/document/d/1f2RVe42IOWDEGyAz3EC4bbA9tSw8nHQjvJxtSx0A_Gg/edit",
                  "_blank"
                )
              }
            >
              <HelpOutlineIcon sx={{ pr: 0.5 }} fontSize="small" />
              Help
            </Button>
          ) : null}
        </Stack>
      </CustomDialogTitle>
      <DialogContent sx={{ overflowY: "scroll" }}>
        <Formik
          initialValues={{
            start_date: ledgerInfo
              ? parse(ledgerInfo?.start_date, "dd/MM/yyyy", new Date())
              : null,
            fmcg: ledgerInfo ? ledgerInfo?.medicines?.fmcg : "",
            medicine: ledgerInfo ? ledgerInfo?.medicines?.medicines : "",
            company_gst: ledgerInfo ? ledgerInfo?.company_gst : "",
            company_name: ledgerInfo ? ledgerInfo?.company_name : "",
            company_address: ledgerInfo ? ledgerInfo?.company_address : "",
            company_state_code: ledgerInfo
              ? ledgerInfo?.company_state_code
              : "",
            service_type: "medicines",
            delivery_type: ledgerInfo
              ? ledgerInfo?.medicines?.delivery?.type
              : "",
            fixed_delivery_amt: ledgerInfo
              ? ledgerInfo?.medicines?.delivery?.fixed
              : null,
            invoice_cycle: ledgerInfo ? ledgerInfo?.invoice_cycle : "",
            low_balance: ledgerInfo ? ledgerInfo?.low_balance : null,
            credit_limit: ledgerInfo ? ledgerInfo?.credit_limit : null,
            processing_fee: ledgerInfo
              ? ledgerInfo?.medicines?.processing_fee
              : "",
            express_delivery:
              ledgerInfo?.medicines?.delivery?.express_delivery || false,
            service_offering: ledgerInfo ? ledgerInfo?.medicines?.type : "",
            value_based: ledgerInfo?.medicines?.value_based_commission || false,
            minimum_order_value:
              ledgerInfo?.medicines?.minimum_order_value || 0,
          }}
          validationSchema={editLedgerInfoSchema}
          onSubmit={(values: any, actions: any) => {
            actions.setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {(props: any) => {
            const { values, setFieldValue, handleBlur, errors, touched } =
              props;

            return (
              <Form>
                {/* company gst details start */}
                <Box display="flex">
                  <Stack width="100%" direction="row" alignItems="center">
                    <TextField
                      fullWidth
                      id="company_gst"
                      label="Company GST"
                      size="small"
                      value={values.company_gst}
                      onChange={(e) =>
                        setFieldValue("company_gst", e.target.value)
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.company_gst && touched.company_gst
                          ? errors.company_gst
                          : ""
                      }
                      error={
                        errors.company_gst && touched.company_gst ? true : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                      sx={{ mt: 1.5 }}
                      disabled={!isEditingGst}
                    />
                    {isEditingGst ? (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={gstVerifyLoading}
                        loadingIndicator={
                          <CircularProgress size={20} sx={{ color: "#fff" }} />
                        }
                        onClick={() =>
                          verifyGst(values.company_gst, setFieldValue)
                        }
                        sx={{ mt: 1.5, ml: 0.5 }}
                      >
                        Verify
                      </LoadingButton>
                    ) : (
                      <Button
                        sx={{ mt: 1.5, ml: 0.5 }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setIsEditingGst(true);
                          setFieldValue("company_name", "");
                          setFieldValue("company_address", "");
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Stack>
                  <TextField
                    fullWidth
                    id="company_name"
                    label="Company Name"
                    size="small"
                    value={values.company_name}
                    onChange={(e) =>
                      setFieldValue("company_name", e.target.value)
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.company_name && touched.company_name
                        ? errors.company_name
                        : ""
                    }
                    error={
                      errors.company_name && touched.company_name ? true : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5, ml: 1.5 }}
                    disabled
                  />
                </Box>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  id="company_address"
                  label="Company Address"
                  size="small"
                  value={values.company_address}
                  onChange={(e) =>
                    setFieldValue("company_address", e.target.value)
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.company_address && touched.company_address
                      ? errors.company_address
                      : ""
                  }
                  error={
                    errors.company_address && touched.company_address
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    required: true,
                  }}
                  sx={{ mt: 1.5 }}
                  disabled
                />
                <TextField
                  fullWidth
                  id="company_state_code"
                  label="Company State Code"
                  size="small"
                  value={values.company_state_code}
                  onChange={(e) =>
                    setFieldValue("company_state_code", e.target.value)
                  }
                  onBlur={handleBlur}
                  helperText={
                    errors.company_state_code && touched.company_state_code
                      ? errors.company_state_code
                      : ""
                  }
                  error={
                    errors.company_state_code && touched.company_state_code
                      ? true
                      : false
                  }
                  InputLabelProps={{
                    required: true,
                  }}
                  sx={{ mt: 1.5 }}
                />
                {/* company gst details end */}
                <Box mt={3} mb={1.5}>
                  <Divider />
                </Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    open={openStartDate}
                    onOpen={() => setOpenStartDate(true)}
                    onClose={() => setOpenStartDate(false)}
                    label="Ledger Start Date"
                    inputFormat="dd/MM/yyyy"
                    value={values?.start_date}
                    onChange={(newValue: Date | null) => {
                      setFieldValue("start_date", newValue);
                    }}
                    // maxDate={
                    //   new Date(new Date().setDate(new Date().getDate() - 1))
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        sx={{ mt: 1.5 }}
                        name="start_date"
                        helperText={
                          errors.start_date && touched.start_date
                            ? errors.start_date
                            : ""
                        }
                        error={
                          errors.start_date && touched.start_date ? true : false
                        }
                        onBlur={handleBlur}
                        InputLabelProps={{
                          required: true,
                        }}
                        autoComplete="off"
                        onKeyDown={(e: any) => {
                          e.preventDefault();
                        }}
                        onClick={(e) => setOpenStartDate(true)}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Autocomplete
                  multiple
                  sx={{ mt: 1.5 }}
                  filterSelectedOptions
                  id="tags-filled"
                  options={emailIds?.map((option: any) => option)}
                  value={emailIds}
                  onChange={(e, val) => {
                    setEmailIds(val);
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      if (!option.match(validEmailRegex)) {
                        return false;
                      }
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Emails"
                      placeholder="enter valid email & hit enter key to add new email..."
                    />
                  )}
                />
                <Box mt={3} mb={1.5}>
                  <Divider />
                </Box>

                {/* invoice cycle start */}
                <Stack direction="row">
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5 }}
                    error={
                      errors.invoice_cycle && touched.invoice_cycle
                        ? true
                        : false
                    }
                  >
                    <InputLabel id="invoice-cycle-label">
                      Invoice Cycle
                    </InputLabel>
                    <Select
                      labelId="invoice-cycle-label"
                      name="invoice_cycle"
                      id="invoice_cycle"
                      value={values?.invoice_cycle}
                      label="Invoice Cycle"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("invoice_cycle", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Weekly">Weekly</MenuItem>
                      <MenuItem value="Fortnightly">Fortnightly</MenuItem>
                    </Select>
                    {errors.invoice_cycle && touched.invoice_cycle && (
                      <FormHelperText>{errors.invoice_cycle}</FormHelperText>
                    )}
                  </FormControl>
                  <TextField
                    fullWidth
                    id="low_balance"
                    label="Low Balance"
                    size="small"
                    value={values.low_balance}
                    onChange={(e) =>
                      setFieldValue("low_balance", e.target.value)
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.low_balance && touched.low_balance
                        ? errors.low_balance
                        : ""
                    }
                    error={
                      errors.low_balance && touched.low_balance ? true : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5, ml: 1.5 }}
                  />
                  <TextField
                    fullWidth
                    id="credit_limit"
                    label="Credit Limit"
                    size="small"
                    value={values.credit_limit}
                    onChange={(e) =>
                      setFieldValue(
                        "credit_limit",
                        validateIntNum(e.target.value, 999999)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.credit_limit && touched.credit_limit
                        ? errors.credit_limit
                        : ""
                    }
                    error={
                      errors.credit_limit && touched.credit_limit ? true : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5, ml: 1.5 }}
                  />
                </Stack>
                {/* invoice cycle end */}
                <Box mt={3} mb={1.5}>
                  <Divider />
                </Box>

                <FormControl
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  error={
                    errors.service_type && touched.service_type ? true : false
                  }
                >
                  <InputLabel id="service-type-label">Service Type</InputLabel>
                  <Select
                    labelId="service-type-label"
                    name="service_type"
                    id="service_type"
                    value={values?.service_type}
                    label="Service Type"
                    onChange={(e: SelectChangeEvent) => {
                      setFieldValue("service_type", e.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="medicines">Medicines</MenuItem>
                  </Select>
                  {errors.service_type && touched.service_type && (
                    <FormHelperText>{errors.service_type}</FormHelperText>
                  )}
                </FormControl>

                <Box display="flex">
                  <FormControl
                    size="small"
                    fullWidth
                    sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                    error={
                      errors.service_offering && touched.service_offering
                        ? true
                        : false
                    }
                  >
                    <InputLabel id="service-offering-label">
                      Service Offering
                    </InputLabel>
                    <Select
                      labelId="service-offering-label"
                      name="service_offering"
                      id="service_offering"
                      value={values?.service_offering}
                      label="Service Offering"
                      onChange={(e: SelectChangeEvent) => {
                        setFieldValue("service_offering", e.target.value);
                      }}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="commission">Commission</MenuItem>
                      <MenuItem value="discount">Discount</MenuItem>
                    </Select>
                    {errors.service_offering && touched.service_offering && (
                      <FormHelperText>{errors.service_offering}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControlLabel
                    sx={{ width: "100%", mt: 1.5, pl: 1.5 }}
                    control={
                      <Checkbox
                        id="value_based"
                        checked={values?.value_based}
                        onChange={(e) => {
                          setFieldValue("value_based", e.target.checked);
                        }}
                      />
                    }
                    label="Value based commission"
                  />
                </Box>

                {!values?.value_based &&
                values?.service_type === "medicines" ? (
                  <Box display="flex">
                    <TextField
                      fullWidth
                      id="medicine"
                      label="Medicine (%)"
                      size="small"
                      value={values.medicine}
                      onChange={(e) =>
                        setFieldValue(
                          "medicine",
                          validateFloatNum(e.target.value, valueLimits.PERCENT)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.medicine && touched.medicine
                          ? errors.medicine
                          : ""
                      }
                      error={errors.medicine && touched.medicine ? true : false}
                      InputLabelProps={{
                        required: true,
                      }}
                      sx={{ mt: 1.5 }}
                    />
                    <TextField
                      fullWidth
                      id="fmcg"
                      label="FMCG (%)"
                      size="small"
                      value={values.fmcg}
                      onChange={(e) =>
                        setFieldValue(
                          "fmcg",
                          validateFloatNum(e.target.value, valueLimits.PERCENT)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.fmcg && touched.fmcg ? errors.fmcg : ""
                      }
                      error={errors.fmcg && touched.fmcg ? true : false}
                      InputLabelProps={{
                        required: true,
                      }}
                      sx={{ mt: 1.5, ml: 1.5 }}
                    />
                  </Box>
                ) : null}

                {values?.value_based && values?.service_type === "medicines" ? (
                  <>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => setValueBasedType(e.target.value)}
                      sx={{ mt: 1 }}
                    >
                      <FormControlLabel
                        value="monthly_value_based_commission"
                        control={
                          <Radio
                            checked={
                              valueBasedType ===
                              "monthly_value_based_commission"
                            }
                          />
                        }
                        label="Monthly Value"
                      />
                      <FormControlLabel
                        value="order_value_based_commission"
                        control={
                          <Radio
                            checked={
                              valueBasedType === "order_value_based_commission"
                            }
                          />
                        }
                        label="Order Value"
                      />
                    </RadioGroup>
                    <TextField
                      fullWidth
                      id="minimum_order_value"
                      label="Minimum Order Value (&#8377;)"
                      size="small"
                      value={values.minimum_order_value}
                      onChange={(e) =>
                        setFieldValue(
                          "minimum_order_value",
                          validateFloatNum(e.target.value, valueLimits.MRP)
                        )
                      }
                      onBlur={handleBlur}
                      helperText={
                        errors.minimum_order_value &&
                        touched.minimum_order_value
                          ? errors.minimum_order_value
                          : ""
                      }
                      error={
                        errors.minimum_order_value &&
                        touched.minimum_order_value
                          ? true
                          : false
                      }
                      InputLabelProps={{
                        required: true,
                      }}
                      sx={{ mt: 1.5 }}
                    />
                    <Typography
                      mb={0.5}
                      variant="body2"
                      sx={{ color: "#00000099" }}
                    >
                      Note: Orders with value less than minimum order value will
                      not be considered for monthly value based commissions
                    </Typography>
                    {valueBasedFields.map((input: any, index: number) => {
                      return (
                        <Box key={index} display="flex">
                          <TextField
                            fullWidth
                            id="min"
                            label="Min (&#8377;)"
                            size="small"
                            value={input.min}
                            InputLabelProps={{
                              required: true,
                            }}
                            sx={{ mt: 1.5 }}
                            onChange={(event) =>
                              handleValueBasedInputChange(index, event)
                            }
                          />
                          <TextField
                            fullWidth
                            id="max"
                            label="Max (&#8377;)"
                            size="small"
                            value={input.max}
                            InputLabelProps={{
                              required: true,
                            }}
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onChange={(event) =>
                              handleValueBasedInputChange(index, event)
                            }
                          />
                          <TextField
                            fullWidth
                            id="medicines"
                            label="Medicines (%)"
                            size="small"
                            value={input.medicines}
                            InputLabelProps={{
                              required: true,
                            }}
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onChange={(event) =>
                              handleValueBasedInputChange(index, event)
                            }
                          />
                          <TextField
                            fullWidth
                            id="fmcg"
                            label="FMCG (%)"
                            size="small"
                            value={input.fmcg}
                            InputLabelProps={{
                              required: true,
                            }}
                            sx={{ mt: 1.5, ml: 1.5 }}
                            onChange={(event) =>
                              handleValueBasedInputChange(index, event)
                            }
                          />
                          <IconButton
                            sx={{ mt: 1.5, ml: 0.5 }}
                            onClick={() => removeValueBasedInput(index)}
                            aria-label="delete"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                    <Button
                      onClick={() => {
                        setValueBasedFields([
                          ...valueBasedFields,
                          { min: "", max: "", medicines: "", fmcg: "" },
                        ]);
                      }}
                    >
                      Add More
                    </Button>
                  </>
                ) : null}

                <Box display="flex">
                  <TextField
                    fullWidth
                    id="processing_fee"
                    label="Processing Fee(%)"
                    size="small"
                    value={values.processing_fee}
                    onChange={(e) =>
                      setFieldValue(
                        "processing_fee",
                        validateFloatNum(e.target.value, valueLimits.PERCENT)
                      )
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.processing_fee && touched.processing_fee
                        ? errors.processing_fee
                        : ""
                    }
                    error={
                      errors.processing_fee && touched.processing_fee
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5 }}
                  />
                  <FormControlLabel
                    sx={{ width: "100%", mt: 1.5, pl: 1.5 }}
                    control={
                      <Checkbox
                        id="express_delivery"
                        checked={values?.express_delivery}
                        onChange={(e) => {
                          setFieldValue("express_delivery", e.target.checked);
                        }}
                      />
                    }
                    label="Actual Delivery Charges*"
                  />
                </Box>

                <FormControl
                  size="small"
                  fullWidth
                  sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
                  error={
                    errors.delivery_type && touched.delivery_type ? true : false
                  }
                >
                  <InputLabel id="delivery-type-label">
                    Delivery Type
                  </InputLabel>
                  <Select
                    labelId="delivery-type-label"
                    name="delivery_type"
                    id="delivery_type"
                    value={values?.delivery_type}
                    label="Delivery Type"
                    onChange={(e: SelectChangeEvent) => {
                      setFieldValue("delivery_type", e.target.value);
                    }}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="conditional">Conditional</MenuItem>
                  </Select>
                  {errors.delivery_type && touched.delivery_type && (
                    <FormHelperText>{errors.delivery_type}</FormHelperText>
                  )}
                </FormControl>

                {values.delivery_type === "fixed" ? (
                  <TextField
                    fullWidth
                    id="fixed_delivery_amt"
                    label="Amount"
                    size="small"
                    value={values.fixed_delivery_amt}
                    onChange={(e) =>
                      setFieldValue("fixed_delivery_amt", e.target.value)
                    }
                    onBlur={handleBlur}
                    helperText={
                      errors.fixed_delivery_amt && touched.fixed_delivery_amt
                        ? errors.fixed_delivery_amt
                        : ""
                    }
                    error={
                      errors.fixed_delivery_amt && touched.fixed_delivery_amt
                        ? true
                        : false
                    }
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5 }}
                  />
                ) : null}
                {values.delivery_type === "conditional" ? (
                  <>
                    {deliveryFields.map((input: any, index: number) => {
                      return (
                        <Box display="flex" alignItems="center">
                          <TextField
                            fullWidth
                            id="upto"
                            label="Upto"
                            size="small"
                            value={input.upto}
                            onChange={(event) =>
                              handleDeliveryInputChange(index, event)
                            }
                            InputLabelProps={{
                              required: true,
                            }}
                            sx={{ mt: 1.5 }}
                          />
                          <TextField
                            fullWidth
                            id="fee"
                            label="Fee"
                            size="small"
                            value={input.fee}
                            onChange={(event) =>
                              handleDeliveryInputChange(index, event)
                            }
                            InputLabelProps={{
                              required: true,
                            }}
                            sx={{ mt: 1.5, ml: 1.5 }}
                          />
                          <IconButton
                            sx={{ mt: 1.5, ml: 0.5 }}
                            onClick={() => removeDeliveryInput(index)}
                            aria-label="delete"
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                    <Button
                      onClick={() => {
                        setDeliveryFields([
                          ...deliveryFields,
                          { upto: "", fee: "" },
                        ]);
                      }}
                    >
                      Add More
                    </Button>
                  </>
                ) : null}

                <Typography color="error" mt={1.5}>
                  Notes:
                </Typography>
                <ol type="1" style={{ paddingLeft: "1rem" }}>
                  <li style={{ fontSize: "14px", color: "#D32F2F" }}>
                    18% GST on Delivery Fee and Processing Fee is exculsive
                  </li>
                  <li style={{ fontSize: "14px", color: "#D32F2F" }}>
                    Delivery fees or actual logistic charges*, whichever is
                    higher, will be considered
                  </li>
                </ol>

                <Box textAlign="center" mt={3}>
                  <LoadingButton
                    size="medium"
                    color="primary"
                    loading={formSubmissionLoading}
                    loadingIndicator={
                      <CircularProgress size={20} sx={{ color: "#fff" }} />
                    }
                    variant="contained"
                    type="submit"
                  >
                    {ledgerInfo ? "Update" : "Activate"}
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EditLedgerInfoModal;
