import * as React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

const CheckLogin = () => {
  const location = useLocation();

  return !Cookies.get("user") ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" state={{ from: location }} replace />
  );
};

export default CheckLogin;
