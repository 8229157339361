import { LoadingButton } from "@mui/lab";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SuccessLogo from "../assets/images/success.png";
import { API_RESULT, API_VERSION } from "../enums/common.enum";
import useAxiosInstance from "../hooks/useAxiosInstance";
import { errorToast, handleError, successToast } from "../utils/common";

function DummyPayment() {
  const [data, setData] = useState<any>("");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const privateInstance = useAxiosInstance();

  useEffect(() => {
    const params: any = new URLSearchParams(location.search);
    try {
      const data = JSON.parse(window.atob(params.get("data")));
      setData(data);
    } catch (err) {
      alert("Please use correct url!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    getOrderPaymentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getOrderPaymentStatus = async () => {
    const orderId = data?.productInfo?.split("inv")[1];
    if (!orderId) return;

    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/${orderId}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        if (
          result.data?.response?.invoice_details?.payment_status ===
            "success" ||
          result.data?.response?.invoice_details?.payment_status === "paid"
        ) {
          setShowSuccess(true);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleSubmit = async () => {
    const {
      amount,
      paymentMode,
      first_name,
      last_name,
      productInfo,
      phone_number,
      email,
      reference_id,
      status,
    } = data;

    let payload = {
      amount,
      paymentMode,
      udf5: "",
      udf3: "",
      split_info: "",
      udf4: "",
      udf1: "",
      udf2: "",
      customerName: first_name + "" + last_name,
      productInfo,
      customerPhone: phone_number,
      additionalCharges: "",
      paymentId: "1234", //
      customerEmail: email,
      merchantTransactionId: "REQ1234", //
      error_Message: "No Error", //
      notificationId: "12345", //
      bankRefNum: reference_id,
      hash: "7dcd148d4b5387bda97c77d30caac944e7d181a30d15cd9f9ac3a1bb8fb827210ac1a4192cca15247db09bb8489c8748104d841a1a6c9731fba2e9b319bdf402", //
      status,
      field4: first_name,
    };

    setIsLoading(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/notifications/callback/payu`,
        payload
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        successToast("Payment Successfull!");
        setShowSuccess(true);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      mt={4}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          borderWidth: 1,
          p: 5,
          borderRadius: 5,
          minWidth: "40%",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.25)",
        }}
      >
        {showSuccess ? (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <img
              src={SuccessLogo}
              alt="success_logo"
              style={{ height: "120px", width: "120px", marginLeft: -5 }}
            />
            <Typography mt={8} fontWeight="bold">
              {" "}
              Rs. {data?.amount}{" "}
            </Typography>
            <Typography> Payment Successful </Typography>
          </Box>
        ) : (
          <Box>
            <Typography fontWeight="bold" textAlign="center" variant="h3">
              {" "}
              Payment Details:
            </Typography>
            <Box mt={8} display="flex" justifyContent="space-between">
              <Typography>Amount:</Typography>
              <Typography>{data?.amount}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Email:</Typography>
              <Typography>{data?.email}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Full name:</Typography>
              <Typography>
                {data?.first_name} {data?.last_name}
              </Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Payment Mode:</Typography>
              <Typography>{data?.paymentMode}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Phone Number:</Typography>
              <Typography>{data?.phone_number}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Product Name:</Typography>
              <Typography>{data?.product_name}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Quantity:</Typography>
              <Typography>{data?.quantity}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Reference ID:</Typography>
              <Typography>{data?.reference_id}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Status:</Typography>
              <Typography>{data?.status}</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Typography>Unit Price:</Typography>
              <Typography>{data?.unit_price}</Typography>
            </Box>
            <Box mt={5} textAlign="center">
              <LoadingButton
                color="primary"
                variant="contained"
                loading={isLoading}
                loadingIndicator={
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                }
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default DummyPayment;
