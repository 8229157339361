import { Divider, Skeleton, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
  skeletonItem: {
    marginLeft: "1%",
    height: 24,
    borderRadius: 2,
  },
  skeletonItemSecond: {
    marginLeft: "1%",
    height: 20,
    borderRadius: 2,
  },
});

const TableSkeleton = ({ index }: any) => {
  const classes = useStyles();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={index === 0 ? { mt: 0 } : { mt: 1 }}
      >
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="2%"
          height={24}
          sx={{ borderRadius: 1 }}
        />
        <Stack sx={{ width: "20%", marginLeft: "1%" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={classes.skeletonItem}
          />
          <Skeleton
            variant="rectangular"
            className={classes.skeletonItemSecond}
            sx={{ mt: 0.5 }}
            animation="wave"
            width="85%"
          />
        </Stack>
        <Skeleton
          variant="rectangular"
          width="17%"
          className={classes.skeletonItem}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width="16%"
          className={classes.skeletonItem}
          animation="wave"
        />
        <Skeleton
          variant="rectangular"
          width="15%"
          className={classes.skeletonItem}
          animation="wave"
        />
        <Stack sx={{ width: "11%", marginLeft: "1%" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            className={classes.skeletonItem}
          />
          <Skeleton
            variant="rectangular"
            className={classes.skeletonItemSecond}
            sx={{ mt: 0.5 }}
            animation="wave"
            width="60%"
          />
        </Stack>
        <Skeleton
          variant="rectangular"
          width="13%"
          className={classes.skeletonItem}
          animation="wave"
        />
      </Stack>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default TableSkeleton;
