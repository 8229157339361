import config from "../config";

const useUserPermissions = () => {
  const CryptoJS = require("crypto-js");

  let userPermissions: any = [];
  if (localStorage.getItem("user_permissions")) {
    const bytes = CryptoJS.AES.decrypt(
      localStorage.getItem("user_permissions"),
      config.ENC_KEY
    );
    userPermissions = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  return userPermissions;
};

export default useUserPermissions;
