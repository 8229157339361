import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { instance } from "../../../../utils/api";
import config from "../../../../config";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { handleError } from "../../../../utils/common";

const useStyles = makeStyles({
  box: {
    background: "#fff",
    border: "none",
    color: "#000",
  },
});

const DigitalPaymentSummary = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      let payload = {
        metric_type: "provider_qr_status",
      };
      setLoading(true);
      try {
        const result: any = await instance.post(
          `${config.URL}${API_VERSION.V2}/analytics/digital-payments/overview`,
          payload
        );

        if (result?.data?.status === API_RESULT.SUCCESS) {
          setData(result.data.response);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Box mb={3} textAlign="center">
          <CircularProgress size={28} />
        </Box>
      ) : (
        <Box
          gap={4}
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          pb={3}
        >
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Typography pb={1}>Terminal Status</Typography>
              <Divider />
              <Box pt={1} display="flex" justifyContent="space-between">
                <Box
                  className={classes.box}
                  component="button"
                  disabled={Number(data?.active) === 0}
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/digital-payment/qr-terminals?filter=active")
                  }
                  textAlign="center"
                  width={"33.33%"}
                >
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "rgba(76, 175, 81, 1)",
                    }}
                    variant="h6"
                  >
                    Active
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "32px !important" }}>
                    {data?.active}
                  </Typography>
                </Box>
                <Box
                  className={classes.box}
                  component="button"
                  disabled={Number(data?.inactive) === 0}
                  onClick={() =>
                    navigate("/digital-payment/qr-terminals?filter=rejected")
                  }
                  textAlign="center"
                  width="33.33%"
                  sx={{
                    cursor: "pointer",
                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px !important",
                      color: "rgba(255, 99, 132, 1)",
                    }}
                    variant="h6"
                  >
                    Inactive
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "32px !important" }}>
                    {data?.inactive}
                  </Typography>
                </Box>
              </Box>
              <Typography sx={{ height: "16px" }} variant="body2"></Typography>
            </Box>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box
              sx={{
                boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box pb={1} display="flex" alignItems="center" gap={1}>
                  <Typography>Suspicious Transactions</Typography>
                  <Tooltip title="A transaction is marked as suspicious when identified as high-risk. After the analysis of these transactions, our support team will contact you for further details.">
                    <InfoIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Box>
                <IconButton
                  aria-label="redirect"
                  onClick={() =>
                    navigate("/digital-payment/transactions?tab=2")
                  }
                  color="primary"
                >
                  <OpenInNewIcon color="primary" fontSize="small" />
                </IconButton>
              </Box>
              <Divider />
              <Box pt={1}>
                <Typography
                  sx={{
                    fontSize: "12px !important",
                    color: "#4A5568",
                  }}
                  variant="h6"
                >
                  Suspicious Amount
                </Typography>
                <Typography variant="h6" sx={{ fontSize: "32px !important" }}>
                  &#8377;
                  {data?.suspicious_summary?.[0]?.sus_txn_value || "0.00"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px !important",
                    color: "#4A5568",
                  }}
                >
                  Count:{" "}
                  <strong>
                    {data?.suspicious_summary?.[0]?.sus_txn_count || "00"}
                  </strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default DigitalPaymentSummary;
