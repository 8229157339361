import { Stack, Typography } from "@mui/material";
import { addMinutes, format } from "date-fns";
import React, { useEffect, useState } from "react";

const SelfOptNotes = ({ flag, type, orgTime, actionTaken }: any) => {
  const [waitUntil, setWaitUntil] = useState<any>(null);

  useEffect(() => {
    setWaitUntil(format(addMinutes(orgTime, 15), "hh:mm a"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {flag === "true" ? (
        <Stack direction="row" marginTop={10}>
          <Typography minWidth="80px" color="error" variant="h6">
            Note :{" "}
          </Typography>
          <Typography color="error" variant="body1">
            Pharmacy opted for{" "}
            {type === "mrp"
              ? "self-filling the MRP"
              : "self uploading the invoice"}
            . Please wait until pharmacy
            {type === "mrp" ? " fills" : " uploads"} it by <b>{waitUntil}</b> or
            write an email on <b>techsupport@medpay.in</b>
          </Typography>
        </Stack>
      ) : null}
    </>
  );
};

export default SelfOptNotes;
