import React, { useEffect } from "react";
import Sidebar from "./sidebar";
import Notification from "./notification";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { useDispatch } from "react-redux";
import { setEditors, setPartners } from "../../store/actions/useActions";
import { AuthenticatedUser } from "../../interfaces/user.model";
import {
  userConsent,
  isPushNotificationSupported,
  askUserPermission,
  sendNotification,
  createNotificationSubscription,
} from "./../../push-notifications";
import useUserInfo from "../../hooks/useUserInfo";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";

const UserLayout = () => {
  const [showNotif, setShowNotif] = React.useState(false);
  const toggleNotification =
    (anchor: string, notifFlag: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setShowNotif(notifFlag);
    };

  const privateInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const user: AuthenticatedUser = useUserInfo();

  useEffect(() => {
    const getAllPartners = async () => {
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/partner/get-all?filter=all`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          console.log(result.data);
          dispatch(setPartners(result.data.response));
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getAllEditors = async () => {
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/order-editors/all`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          console.log(result.data);
          dispatch(setEditors(result.data.response));
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (user?.partner === "medpay") {
      getAllPartners();
      getAllEditors();
    }
  }, [user?.partner, dispatch, privateInstance]);

  const handleNotificationService = async () => {
    if (isPushNotificationSupported() === true) {
      if (userConsent === "granted") {
        getAndUpdateSubscription();
      } else {
        askUserPermission().then((consent) => {
          if (consent === "granted") {
            getAndUpdateSubscription();
            sendNotification();
          } else {
            // TODO: handle not permission
          }
        });
      }
    }
  };

  const getAndUpdateSubscription = async () => {
    let exstingSubscription = await createNotificationSubscription();
    updateSubscription(exstingSubscription);
  };

  const updateSubscription = (subscriptionId: any) => {
    let payload = {
      user_id: user?.user_id,
      service: "order-dashboard",
      subscription_id: JSON.stringify(subscriptionId),
    };

    privateInstance
      .post(`${API_VERSION.V1}/notifications/web-push/subscribe`, payload)
      .then((response: any) => {
        console.log(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const subscriptionChangeListener = () => {
    // console.log('listener added');
    window.addEventListener(
      "pushsubscriptionchange",
      (event) => {
        // console.log(JSON.stringify(event));
        getAndUpdateSubscription();
      },
      false
    );
  };

  useEffect(() => {
    if (user?.access_token && user?.partner === "medpay") {
      handleNotificationService();
    }

    subscriptionChangeListener();

    const hour = 2;
    const interval = setInterval(() => {
      if (user?.access_token && user?.partner === "medpay") {
        if (userConsent === "granted") {
          getAndUpdateSubscription();
        }
      }
    }, hour * 3600 * 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* sidebar and component to display */}
      <Sidebar
        toggleNotification={toggleNotification}
        userId={user?.user_id || ""}
        userRole={user?.role || ""}
      />
      {/* notification drawer */}
      <Notification
        toggleNotification={toggleNotification}
        showNotif={showNotif}
        setShowNotif={setShowNotif}
      />
    </div>
  );
};

export default UserLayout;
