import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { addDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { instance } from "../../../../utils/api";
import {
  amountConverter,
  getMuiTheme,
  handleError,
} from "../../../../utils/common";
import MUIDataTable from "mui-datatables";
import { commonTableOptions } from "../../../../constants";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { ThemeProvider } from "@mui/styles";
import { useParams } from "react-router-dom";
import MerchantWiseTxnModal from "./MerchantWiseTxnModal";
import { DateRangePicker } from "mui-daterange-picker";
import { dateRangeMenu } from "../../../../utils/dateRangeMenu";

const MerchantTransactions = ({
  providerInfo,
  dateRangeTxn,
  setDateRangeTxn,
  data,
  setData,
  visitedTabs,
  updateVisitedTabs,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [showTxnModal, setShowTxnModal] = useState(false);
  const params = useParams();
  const [openPicker, setOpenPicker] = useState(false);

  const toggle = () => setOpenPicker(!openPicker);

  useEffect(() => {
    const getTxnData = async () => {
      let payload = {
        start_date: format(dateRangeTxn?.startDate as Date, "yyyy-MM-dd"),
        end_date: format(dateRangeTxn?.endDate as Date, "yyyy-MM-dd"),
        metric_type: "pharmacy_transactions_group",
        location_id: params?.locationId,
      };
      //setData([]); //reset table data on date change
      setLoading(true);
      try {
        const result = await instance.post(
          `${API_VERSION.V2}/analytics/digital-payments/overview`,
          payload
        );
        if (result.data?.status === API_RESULT.SUCCESS) {
          updateVisitedTabs(2, "add");
          setData(result.data.response?.transactions);
          setOpenPicker(false);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    if (!visitedTabs.includes(2)) {
      getTxnData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeTxn]);

  const cols = [
    {
      name: "date",
      label: "Date",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2">
              {format(new Date(value), "dd/MM/yyyy")}
            </Typography>
          );
        },
      },
    },
    {
      name: "transaction_count",
      label: "Txn Count",
      options: {
        searchable: false,
      },
    },
    {
      name: "transaction_value",
      label: "Value(Rs.)",
      options: {
        searchable: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "All Transactions",
      options: {
        download: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row" alignItems="center">
              <Stack
                onClick={() => {
                  setSelectedDate(new Date(value));
                  setShowTxnModal(true);
                }}
                direction="row"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                }}
              >
                <DynamicFeedIcon color="primary" />
                <Typography color="primary" sx={{ pl: 0.5 }} variant="body2">
                  View
                </Typography>
              </Stack>
            </Stack>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Terminal ID",
    sort: true,
    filter: false,
    rowsPerPageOptions: [10, 50, 100, 200],
    rowsPerPage: 10,
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack>
          <TextField
            sx={{ minWidth: "275px" }}
            fullWidth
            id="date_range"
            label={dateRangeTxn?.label}
            size="small"
            value={`${format(
              dateRangeTxn?.startDate as Date,
              "dd/MM/yyyy"
            )} - ${format(dateRangeTxn?.endDate as Date, "dd/MM/yyyy")}`}
            onFocus={toggle}
          />
          <Box sx={{ position: "absolute", zIndex: 1000 }}>
            <DateRangePicker
              open={openPicker}
              toggle={toggle}
              onChange={(range) => {
                updateVisitedTabs(2, "remove");
                setDateRangeTxn(range);
              }}
              minDate={"2024-04-01"}
              maxDate={addDays(new Date(), 1)}
              definedRanges={dateRangeMenu}
            />
          </Box>
        </Stack>
        <Box
          width={{ xs: "100%", md: "30%" }}
          sx={{
            boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
          }}
        >
          <Box sx={{ width: "50%", textAlign: "center" }} pt={1}>
            <Typography
              sx={{
                fontSize: "12px !important",
              }}
            >
              Count
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "20px !important" }}>
              <strong>
                {data?.length > 0
                  ? data?.reduce(
                      (acc: number, curr: any) =>
                        acc + Number(curr.transaction_count),
                      0
                    )
                  : 0}
              </strong>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",
              borderLeft: "1px solid #e7e7e7",
              textAlign: "center",
            }}
            pt={1}
          >
            <Typography
              sx={{
                fontSize: "12px !important",
              }}
            >
              Amount
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "20px !important" }}>
              &#8377;&nbsp;
              {amountConverter(
                data?.reduce(
                  (acc: number, curr: any) =>
                    acc + Number(curr.transaction_value),
                  0
                )
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={3}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable title="" data={data} columns={cols} options={options} />
        </ThemeProvider>
      </Box>
      {showTxnModal ? (
        <MerchantWiseTxnModal
          date={selectedDate}
          selected={providerInfo}
          showTxnModal={showTxnModal}
          setShowTxnModal={setShowTxnModal}
        />
      ) : null}
    </div>
  );
};

export default MerchantTransactions;
