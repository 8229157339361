import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PartnersAutocomplete from "../../../components/Common/partnersAC";
import { roles } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import useUserInfo from "../../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../../interfaces/user.model";
import { errorToast, handleError } from "../../../utils/common";
import PermissionsView from "./permissionsView";

const Roles = () => {
  const user: AuthenticatedUser = useUserInfo();
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [selectedPartner, setSelectedPartner] = useState<string | undefined>(
    ""
  );
  const [permissionsData, setPermissionsData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const privateInstance = useAxiosInstance();

  useEffect(() => {
    setSelectedPartner(user?.partner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPartner && selectedRole) {
      getRoleWisePermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole, selectedPartner]);

  const getRoleWisePermission = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/auth/role?role=${selectedRole}&partner=${selectedPartner}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setPermissionsData(result.data.response?.permissions);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Stack direction="row">
        <FormControl
          size="small"
          fullWidth
          sx={{ mt: 1.5, mr: { xs: 0, md: 1 } }}
        >
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            name="role"
            id="role"
            value={selectedRole}
            label="Role"
            onChange={(e: SelectChangeEvent) => {
              setSelectedRole(e.target.value);
              if (e.target.value !== "partner") {
                setSelectedPartner(user?.partner);
              }
            }}
          >
            {roles.map((x: any, index: number) => {
              return (
                <MenuItem key={index} value={x}>
                  {x}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <PartnersAutocomplete
          providerId={selectedPartner || null}
          setProviderId={setSelectedPartner}
          validate="false"
          disableField={selectedRole === "partner" ? false : true}
        />
      </Stack>
      {loading ? (
        <CircularProgress sx={{ mt: 2 }} />
      ) : (
        <>{permissionsData && <PermissionsView data={permissionsData} />}</>
      )}
    </div>
  );
};

export default Roles;
