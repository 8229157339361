import * as yup from "yup";

export const createLogisticsOrderSchema = yup.object().shape({
  merchant_name: yup.string().required("Merchant name is required"),
  merchant_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Merchant Mobile is required"),
  merchant_address: yup.string().required("Merchant address is required"),
  merchant_lat: yup.string().required("Merchant latitude is required"),
  merchant_long: yup.string().required("Merchant longitude is required"),
  merchant_special_ins: yup.string().required("Please add pickup instructions"),
  delivery_partner: yup.string().required("Delivery partner is required"),
  customer_name: yup.string().required("Customer name is required"),
  customer_mobile: yup
    .number()
    .typeError("must be a number")
    .min(6000000000, "must be greater than or equal to 6000000000")
    .required("Customer Mobile is required"),
  customer_address: yup.string().required("Customer address is required"),
  customer_lat: yup.string().required("Customer latitude is required"),
  customer_long: yup.string().required("Customer longitude is required"),
  customer_special_ins: yup.string().required("Please add drop instructions"),
  cod_amount: yup.number().when("cod", {
    is: (cod: any) => cod === true,
    then: yup.number().required("COD Amount is required"),
  }),
});
