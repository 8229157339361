import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType, MUIDataTableOptions } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import { errorToast, handleError } from "../../utils/common";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import KnowlarityCall from "../../components/Common/knowlarityCall";
import { commonTableOptions } from "../../constants";
import { useIconStyles } from "../../components/Helpers/iconStyles";
import ErrorBoundary from "../../components/Helpers/ErrorBoundary";

const Recovery = () => {
  const [loading, setLoading] = useState(false);
  const [recoveryData, setRecoveryData] = useState<any>([]);
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [selectedDefaulter, setSelectedDefaulter] = useState<any>({});
  const iconClasses = useIconStyles();

  const privateInstance = useAxiosInstance();

  const getRecoveryData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/opd/order-service/recovery`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setRecoveryData(result.data.response);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecoveryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "user_name",
      label: "NAME",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "corporate_name",
      label: "CORPORATE",
      options: {
        filter: true,
        sort: false,
        filterType: "dropdown" as FilterType,
        searchable: false,
      },
    },
    {
      name: "onboarding_partner_name",
      label: "ONBOARDING PARTNER",
      options: {
        filter: true,
        sort: false,
        filterType: "dropdown" as FilterType,
        searchable: false,
      },
    },
    {
      name: "due_date",
      label: "DUE DATE",
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "invoice_amount",
      label: "AMOUNT",
      options: {
        filter: false,
        sort: false,
        searchable: false,
      },
    },
    {
      name: "phone_number",
      label: "MOBILE",
      options: {
        searchable: false,
        filter: true,
        sort: false,
        filterType: "textField" as FilterType,
        customBodyRender: (value: string) => {
          return (
            <KnowlarityCall
              order={{ order_id: value, mobile: value }}
              showNumber={true}
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Tooltip title="View">
              <IconButton
                color="primary"
                onClick={() => {
                  setSelectedDefaulter(recoveryData[tableMeta?.rowIndex]);
                  setTransactionsModal(true);
                }}
              >
                <VisibilityIcon className={iconClasses.borderedIcon} />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...(commonTableOptions as any),
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    searchPlaceholder: "Name",
  };

  return (
    <ErrorBoundary>
      <MUIDataTable
        title=""
        data={recoveryData}
        columns={columns}
        options={options}
      />

      {transactionsModal && (
        <Dialog
          open={transactionsModal}
          onClose={() => setTransactionsModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >
          <CustomDialogTitle
            id="customized-dialog-title"
            onClose={() => setTransactionsModal(false)}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
                Transactions
              </Typography>
            </Stack>
          </CustomDialogTitle>
          <DialogContent>
            {Object.keys(selectedDefaulter).length > 0 &&
              selectedDefaulter.transactions?.map((t: any, index: number) => {
                return (
                  <Box
                    key={index}
                    mt={2}
                    style={{ borderBottom: "1px solid #e7e7e7" }}
                  >
                    {t.amount && (
                      <Typography sx={{ color: "#4a5568", fontSize: "14px" }}>
                        Amount: {t.amount}
                      </Typography>
                    )}
                    {t.provider_name && (
                      <Typography sx={{ color: "#4a5568", fontSize: "14px" }}>
                        Paid to: {t.provider_name}
                      </Typography>
                    )}
                    {t.created_at && (
                      <Typography
                        mb={2}
                        sx={{ color: "#4a5568", fontSize: "14px" }}
                      >
                        Date: {t.created_at.split(".")[0]}
                      </Typography>
                    )}
                  </Box>
                );
              })}
          </DialogContent>
        </Dialog>
      )}
    </ErrorBoundary>
  );
};

export default Recovery;
