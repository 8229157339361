import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { debounce } from "lodash";
import useAxiosInstance from "../../../../../hooks/useAxiosInstance";
import axios from "axios";
import { getDistance } from "geolib";
import { errorToast, handleError } from "../../../../../utils/common";
import NearbyProviderRow from "./nearbyProviderRow";
import MapIcon from "@mui/icons-material/Map";
import { LoadingButton } from "@mui/lab";
import { ORDER_TYPE } from "../../../../../enums/order.enum";
import { API_RESULT, API_VERSION } from "../../../../../enums/common.enum";
import config from "../../../../../config";

const SearchNearby = ({
  autocompleteLabel,
  nearbyProviders,
  setNearbyProviders,
  orderData,
  selectedProviders,
  setSelectedProviders,
  showFetch = true,
  assignLoading,
}: any) => {
  const [options, setOptions] = useState([]);
  const privateInstance = useAxiosInstance();
  const [input, setInput] = useState<string | null>(null);
  const [selectedValueFromSearch, setSelectedValueFromSearch] =
    useState<any>(null);
  const [autoCompleteSwitch, setAutoCompleteSwitch] = useState<boolean>(false);
  const [activeProviderIndex, setActiveProviderIndex] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(1);

  const getSearchData = async (inputValue: string) => {
    const result = await privateInstance.get(
      `${API_VERSION.V1}/gmap/autocomplete?location=${orderData?.customer_details?.address_details?.coordinates}&input=${inputValue}&components=country:in`
    );
    if (result?.data?.status === API_RESULT.OK) {
      setOptions(result.data.predictions);
    } else {
      setOptions([]);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(debounce(getSearchData, 500), []);

  useEffect(() => {
    if (input) {
      setAutoCompleteSwitch(true);
      debounceFn(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const loadData = async (placeId: string) => {
    try {
      const result: any = await axios.get(
        `${config.URL}${API_VERSION.V1}/gmap/details?place_id=${placeId}&contact_info=true`
      );
      if (result.data?.status) {
        handleAddProvider(result.data?.response, placeId);
      }
    } catch (err) {
      handleError(err);
    }
  };

  const handleAddProvider = (provider: any, placeId: any) => {
    const customer_coordinates =
      orderData?.customer_details?.address_details?.coordinates;

    let startLat = customer_coordinates
      ? customer_coordinates.split(",")[0]
      : null;
    let startLong = customer_coordinates
      ? customer_coordinates.split(",")[1]
      : null;

    let distance =
      startLat && startLong
        ? getDistance(
            { latitude: startLat, longitude: startLong },
            {
              latitude: provider.location?.lat,
              longitude: provider.location?.lon,
            }
          )
        : 0;
    let distance_km = distance / 1000;
    let mobile_number;
    if (provider.formatted_phone_number) {
      mobile_number = provider.formatted_phone_number.replaceAll(" ", "");
    } else {
      mobile_number = "";
    }

    let updatedProviderData: any = {
      provider_id: placeId,
      location_id: "loc" + placeId,
      merchant_type:
        orderData?.order_type === ORDER_TYPE.CONSULTATION
          ? "clinic"
          : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
          ? "diagnostic center"
          : "pharmacy",
      // business_status: provider.business_status,
      name: provider.name,
      address: provider.formatted_address,
      // opening_hours: provider.opening_hours ? provider?.opening_hours : "",
      coordinates: provider.location.lat + "," + provider.location.lon,
      rating: String(provider.rating),
      mobile: mobile_number,
      distance: String(distance_km),
    };

    if (orderData?.order_type === ORDER_TYPE.CONSULTATION) {
      updatedProviderData = {
        ...updatedProviderData,
        specialty: orderData?.specialty,
      };
    }

    if (orderData?.order_type === ORDER_TYPE.MEDICINES) {
      delete updatedProviderData.distance;
      delete updatedProviderData.opening_hours;
      delete updatedProviderData.address;
      updatedProviderData = {
        ...updatedProviderData,
        area: provider.formatted_address,
        formatted_address: provider.formatted_address,
        onboarded: "no",
        home_service: "no",
        from_search: "yes",
      };
    }
    const found = nearbyProviders.some(
      (el: any) => el.provider_id === updatedProviderData?.provider_id
    );
    if (!found) {
      setNearbyProviders([...nearbyProviders, updatedProviderData]);
    }
  };

  const nearbyProviderInMap = () => {
    if (
      orderData?.order_type === ORDER_TYPE.MEDICINES ||
      orderData?.order_type === ORDER_TYPE.CONSULTATION
    ) {
      const searchProviderType =
        orderData?.order_type === ORDER_TYPE.MEDICINES ? "pharmacy" : "clinic";
      window.open(
        `https://www.google.com/maps/search/${searchProviderType}+in+${orderData?.customer_details?.address_details?.city}+${orderData?.customer_details?.address_details?.pin_code}`
      );
    }

    if (orderData?.order_type === ORDER_TYPE.DIAGNOSTICS) {
      window.open(
        `https://www.google.com/maps/search/diagnostic+labs+in+${orderData?.customer_details?.address_details?.city}`
      );
    }
  };

  useEffect(() => {
    if (orderData?.order_type === ORDER_TYPE.MEDICINES || !showFetch) {
      fetchNearbyProviders(orderData?.order_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNearbyProviders = async (orderId: string) => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/nearby?order_id=${orderId}&start=${nextPage}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        let nearbyProvidersId: string[] = [];
        nearbyProviders?.forEach((provider: any) => {
          nearbyProvidersId.push(provider?.provider_id);
        });

        let newProviders: any = [];
        result.data.response?.nearby_list?.forEach((provider: any) => {
          if (!nearbyProvidersId.includes(provider?.provider_id)) {
            newProviders.push(provider);
          }
        });
        //filter duplicate providers above

        let updatedProviders = [...nearbyProviders, ...newProviders];
        setNearbyProviders([...updatedProviders]);
        setNextPage(nextPage + 1);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchNearbyLabs = async (values: any) => {
    setLoading(true);
    let add = values.address;
    add = add.replace("#", "-").replace("&", "-").replace("/", "-");
    let address = `${add}, ${values.landmark}, ${values.city}, ${values.state} - ${values.pin_code}`;

    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/diagnostics/nearby?address=${address}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        let updatedProviders = [...nearbyProviders, ...result.data.response];
        setNearbyProviders([...updatedProviders]);
      } else {
        errorToast(result.data.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <ClickAwayListener onClickAway={() => setAutoCompleteSwitch(false)}>
          <Autocomplete
            open={autoCompleteSwitch}
            ListboxProps={{ style: { maxHeight: "14rem" } }}
            size="small"
            sx={{
              width: { xs: "100%", md: "50%" },
              my: 5,
              ml: { xs: 0, md: "25%" },
            }}
            value={selectedValueFromSearch}
            onChange={(e, val: any) => {
              loadData(val.place_id);
              setSelectedValueFromSearch(val);
            }}
            onInputChange={(e: any) => setInput(e?.target?.value)}
            onSelect={(e: any) => setSelectedValueFromSearch(null)}
            id="nearby"
            options={options}
            renderInput={(params) => (
              <TextField
                name="lab-test-input"
                label={autocompleteLabel}
                {...params}
                onPaste={(e: any) => {
                  e.preventDefault();
                }}
                onCopy={(e: any) => {
                  e.preventDefault();
                }}
              />
            )}
            noOptionsText={"No data found"}
            getOptionLabel={(option) => option.description}
          />
        </ClickAwayListener>

        <Tooltip
          sx={{ mx: 1 }}
          title={
            orderData?.order_type === ORDER_TYPE.CONSULTATION
              ? "nearby clinics"
              : orderData?.order_type === ORDER_TYPE.DIAGNOSTICS
              ? "nearby labs"
              : "nearby pharmacy"
          }
        >
          <IconButton color="primary" onClick={() => nearbyProviderInMap()}>
            <MapIcon />
          </IconButton>
        </Tooltip>

        {showFetch && orderData?.order_type !== ORDER_TYPE.MEDICINES && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={
              orderData?.order_type === ORDER_TYPE.CONSULTATION
                ? () => fetchNearbyProviders(orderData?.order_id)
                : () =>
                    fetchNearbyLabs(
                      orderData?.customer_details?.address_details
                    )
            }
            loading={loading}
            loadingIndicator={
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            }
          >
            {orderData?.order_type === ORDER_TYPE.CONSULTATION
              ? "Fetch Clinics"
              : "Fetch Labs"}
          </LoadingButton>
        )}
      </Stack>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          mb={2}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {nearbyProviders.map((x: any, i: number) => {
            return (
              <Box key={i}>
                <NearbyProviderRow
                  orderData={orderData}
                  index={i}
                  item={x}
                  setActiveProviderIndex={setActiveProviderIndex}
                  nearbyProviders={nearbyProviders}
                  setNearbyProviders={setNearbyProviders}
                  selectedProviders={selectedProviders}
                  setSelectedProviders={setSelectedProviders}
                  activeProviderIndex={activeProviderIndex}
                  assignLoading={assignLoading}
                />
                <Divider />
              </Box>
            );
          })}
        </>
      )}

      {orderData?.order_type !== ORDER_TYPE.DIAGNOSTICS &&
        nearbyProviders.length !== 0 &&
        nextPage > 1 &&
        nextPage < 3 && (
          <Box textAlign="right">
            <Button
              variant="text"
              color="primary"
              onClick={() => fetchNearbyProviders(orderData?.order_id)}
            >
              Load More
            </Button>
          </Box>
        )}
    </>
  );
};

export default SearchNearby;
